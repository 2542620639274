import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { ItemIdModule_fromString, ItemIdModule_toString, ItemIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { itemApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { length, singleton as singleton_2, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Result_Map } from "../fable_modules/fable-library.3.6.1/Choice.js";

export function RemoveItemWithoutLots_Component() {
    let elms_4, elms_3, props_2, props_21;
    const patternInput = useFeliz_React__React_useState_Static_1505(ItemIdModule_empty);
    const itemId = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_1[0];
    const removeItem_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(itemApi().removeToFixError(itemId)))), patternInput_1[1]);
    const elms_5 = singleton_2((elms_4 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Remove item without lots",
    }), (elms_3 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Remove item that has no lots and created due to bug",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_21 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, s_2, elms, props_5, value_24;
        return append(singleton_1((elms_1 = ofArray([(s_2 = (`Item id = ${itemId}`), createElement("label", {
            className: "label",
            children: s_2,
        })), (elms = singleton_2((props_5 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_24 = ItemIdModule_toString(itemId), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_24)) : false) {
                e.value = value_24;
            }
        }]), ["onChange", (ev) => {
            patternInput[1](ItemIdModule_fromString(ev.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_5)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let props_10, props_14;
            return append((data.tag === 1) ? singleton_1(null) : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", data.fields[0].fields[0]]]))))) : singleton_1((props_10 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", {
                children: ["Successfully removed item."],
            })])]), createElement("div", createObj(Helpers_combineClasses("notification", props_10)))))) : ((data.tag === 3) ? singleton_1((props_14 = ofArray([["className", "is-danger"], ["children", data.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_14))))) : singleton_1(null))), delay(() => {
                let props_19, elms_2, props_16;
                return singleton_1((props_19 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_16 = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(data) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Ta bort"]), delay(() => singleton_1(["onClick", (_arg1) => {
                    removeItem_1();
                }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_16))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_19)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_21))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    });
}

export function RemoveItemWithDeletedLot_Component() {
    let elms_2, elms_1, props_2, props_16;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput[0];
    const removeItems = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(itemApi().getItemsWithDeletedLot()), (_arg1) => {
        const itemsToRemove = _arg1;
        return singleton.ReturnFrom(singleton.Bind(Remoting_handleNonAuth(itemApi().removeItems(itemsToRemove)), (arg) => singleton.Return(Result_Map(() => itemsToRemove, arg))));
    })), patternInput[1]);
    const elms_3 = singleton_2((elms_2 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Remove item with only one deleted lot",
    }), (elms_1 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Remove item that has only one deleted lot",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_16 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_5, value_17, props_9;
        return append((data.tag === 1) ? singleton_1(null) : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", data.fields[0].fields[0]]]))))) : singleton_1((props_5 = singleton_2(["children", Interop_reactApi.Children.toArray([(value_17 = (`Successfully removed ${length(data.fields[0].fields[0])} items ${data.fields[0].fields[0]}.`), createElement("div", {
            children: [value_17],
        }))])]), createElement("div", createObj(Helpers_combineClasses("notification", props_5)))))) : ((data.tag === 3) ? singleton_1((props_9 = ofArray([["className", "is-danger"], ["children", data.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_9))))) : singleton_1(null))), delay(() => {
            let props_14, elms, props_11;
            return singleton_1((props_14 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_2((props_11 = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(data) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Remove items"]), delay(() => singleton_1(["onClick", (_arg2) => {
                removeItems();
            }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_11))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_14)))));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    });
}

