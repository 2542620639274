import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Form_getFieldError } from "./Common.js";
import { createElement } from "react";
import * as react from "react";
import { partialApply, equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { map, fold, singleton as singleton_1, cons, empty, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Record, Union, toString } from "./fable_modules/fable-library.3.6.1/Types.js";
import { enum_type, int32_type, record_type, bool_type, class_type, list_type, union_type, unit_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, Deferred$1$reflection, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { singleton as singleton_2 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi } from "./Communication.js";
import { SpecialOfferInCatalogField, Settings_PrioritySettings, Settings_PrioritySettings$reflection, SpecialOfferInCatalogField$reflection, BasicInformationField, Settings_getNameOfWeek, Settings_getValueOfWeek, Settings_InfoSettings, Settings_InfoSettings$reflection, Image$reflection, BasicInformationField$reflection, Settings_TimerSettings, Settings_TimerSettings$reflection } from "./Shared/Shared.js";
import { empty as empty_2 } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { TimerSettings_validate, TimerSettings_validateIf } from "./Validation.js";
import { parse } from "./fable_modules/fable-library.3.6.1/Double.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { getPrimaryLanguage, t } from "./Localization.js";
import { fromDate } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { LocalizedMap_getValueOrEmpty, LocalizedStringModule_getTranslationOrEmpty, LocalizedMap_add } from "./bidflow/src/infrastructure/Localization.js";
import { some } from "./fable_modules/fable-library.3.6.1/Option.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Locale, ClassName, OnChange, TimeTwentyFour, Placeholder, EnableTimePicker, HideCalendar, Value, flatpickr } from "./fable_modules/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { fromDateTimeOffset } from "./fable_modules/fable-library.3.6.1/Date.js";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import { languageSwitcher } from "./Components/ContentLanguageSwitcher.js";
import { dividedBottom } from "./Components/BorderDividedLine.js";
import { EditImages } from "./Components/DownloadBackgroundImage.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { isCurrentUserAdmin } from "./LocalStorage.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";
import { Render as Render_1 } from "./BiddingIntervals.js";

export function renderFiled(label, value, placeholder, isInProgress, errors, formError, onChange) {
    let props_12, props_10;
    const elms_2 = singleton_1((props_12 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(props_10 = toList(delay(() => append(singleton(["className", "has-text-grey-dark"]), delay(() => {
        let elms_1, props_2, elms, props_4, props_7;
        const errors_1 = Form_getFieldError(errors, formError);
        return singleton(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1((props_4 = toList(delay(() => append(singleton(["required", true]), delay(() => append((!equals(errors_1, empty())) ? singleton(["className", "is-danger"]) : empty_1(), delay(() => append(singleton(["disabled", isInProgress]), delay(() => append(singleton(["onChange", (ev) => {
            onChange(ev.target.value);
        }]), delay(() => append(singleton(["placeholder", placeholder]), delay(() => {
            let value_23, copyOfStruct;
            return singleton((value_23 = ((copyOfStruct = value, toString(copyOfStruct))), ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_23)) : false) {
                    e.value = value_23;
                }
            }]));
        })))))))))))), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_7 = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_7))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("field", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))));
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function renderFieldWithoutValidation(label, placeholder, key, value, onChange) {
    let props_10, props_8, elms_1, props_2, elms, props_4, value_17, copyOfStruct;
    const elms_2 = singleton_1((props_10 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1((props_4 = ofArray([["key", key], ["onChange", (ev) => {
        onChange(ev.target.value);
    }], ["placeholder", placeholder], (value_17 = ((copyOfStruct = value, toString(copyOfStruct))), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_17)) : false) {
            e.value = value_17;
        }
    }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))));
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function renderTextArea(label, key, value, onChange) {
    let elms_1, props_2, elms, props_4, value_17;
    const props_8 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1((props_4 = ofArray([["key", key], ["rows", 2], ["onChange", (ev) => {
        onChange(ev.target.value);
    }], (value_17 = value, ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_17)) : false) {
            e.value = value_17;
        }
    }])]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_4))))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_8)));
}

export class AuctionTimer_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeDefaultTimer", "ChangeTimerAfterBid", "CancelEdit", "SaveChanging"];
    }
}

export function AuctionTimer_Msg$reflection() {
    return union_type("Client.SettingsAuction.AuctionTimer.Msg", [], AuctionTimer_Msg, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function AuctionTimer_Cmd_updateTimerSettings(info) {
    return singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(Remoting_handleNonAuth(settingsApi().updateTimerSettings(info)), (_arg1) => singleton_2.Return(new AuctionTimer_Msg(3, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton_2.Return(new AuctionTimer_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export class AuctionTimer_State extends Record {
    constructor(TimerSettings, OriginalSettings, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.TimerSettings = TimerSettings;
        this.OriginalSettings = OriginalSettings;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function AuctionTimer_State$reflection() {
    return record_type("Client.SettingsAuction.AuctionTimer.State", [], AuctionTimer_State, () => [["TimerSettings", Settings_TimerSettings$reflection()], ["OriginalSettings", Settings_TimerSettings$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export function AuctionTimer_init(settings) {
    return [new AuctionTimer_State(settings, settings, empty_2(), false, new Deferred$1(0)), Cmd_none()];
}

export function AuctionTimer_withFormAndErrors(form, errors, state) {
    return new AuctionTimer_State(form, state.OriginalSettings, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function AuctionTimer_update(msg, state) {
    const withValidatedForm = (arg) => {
        const tupledArg = TimerSettings_validateIf(state.NeedToValidate, arg);
        return (state_1) => AuctionTimer_withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 1) {
        return [withValidatedForm(new Settings_TimerSettings(state.TimerSettings.DefaultTimer, parse(msg.fields[0])))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new AuctionTimer_State(state.OriginalSettings, state.OriginalSettings, empty_2(), false, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [new AuctionTimer_State(state.TimerSettings, state.TimerSettings, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.TimerSettings, state.OriginalSettings)) {
            const matchValue = TimerSettings_validate(state.TimerSettings);
            if (matchValue.tag === 1) {
                return [new AuctionTimer_State(state.TimerSettings, state.OriginalSettings, matchValue.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new AuctionTimer_State(state.TimerSettings, state.OriginalSettings, empty_2(), true, new Deferred$1(1)), Cmd_fromAsync(AuctionTimer_Cmd_updateTimerSettings(state.TimerSettings))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [withValidatedForm(new Settings_TimerSettings(parse(msg.fields[0]), state.TimerSettings.AfterBidTimer))(state), Cmd_none()];
    }
}

export function AuctionTimer_Render(settings) {
    let props_9, elms_2, elms, elms_1, props_7, props_3, props_5;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(AuctionTimer_init(settings), (msg, state) => AuctionTimer_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isInProgress = equals(state_1.SaveFormOperation, new Deferred$1(1));
    return react.createElement(react.Fragment, {}, createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_9 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_2 = ofArray([(elms = singleton_1(renderFiled(t("settings.initialCountdown"), state_1.TimerSettings.DefaultTimer, t("settings.initialCountdown"), isInProgress, state_1.FormErrors, "DefaultTimer", (arg) => {
            dispatch(new AuctionTimer_Msg(0, arg));
        })), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton_1(renderFiled(t("settings.countdownAfterBid"), state_1.TimerSettings.AfterBidTimer, t("settings.countdownAfterBid"), isInProgress, state_1.FormErrors, "AfterBidTimer", (arg_1) => {
            dispatch(new AuctionTimer_Msg(1, arg_1));
        })), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (props_7 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x) => {
            x.preventDefault();
            dispatch(new AuctionTimer_Msg(2));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_3)))), (props_5 = toList(delay(() => append(singleton(["className", "is-primary"]), delay(() => append(equals(state_1.TimerSettings, state_1.OriginalSettings) ? singleton(["disabled", true]) : empty_1(), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append(singleton(["children", t("common.save")]), delay(() => singleton(["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new AuctionTimer_Msg(3, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_5))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_7))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_9))))]),
    }));
}

export class DefaultAuctionSettings_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeDay", "ChangeTime", "UpdateField", "ImagesChanged", "CancelEdit", "SaveChanging", "OnContentLanguageChanged"];
    }
}

export function DefaultAuctionSettings_Msg$reflection() {
    return union_type("Client.SettingsAuction.DefaultAuctionSettings.Msg", [], DefaultAuctionSettings_Msg, () => [[["Item", enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]])]], [["Item", class_type("System.DateTime")]], [["Item1", BasicInformationField$reflection()], ["Item2", string_type], ["Item3", string_type]], [["Item", Image$reflection()]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", string_type]]]);
}

export function DefaultAuctionSettings_Cmd_updateAuctionCreateInfo(info) {
    return singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(Remoting_handleNonAuth(settingsApi().updateAuctionCreateInfo(info)), (_arg1) => singleton_2.Return(new DefaultAuctionSettings_Msg(5, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton_2.Return(new DefaultAuctionSettings_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export class DefaultAuctionSettings_State extends Record {
    constructor(AuctionSettings, OriginalSettings, WeekList, SaveFormOperation, ContentLanguage) {
        super();
        this.AuctionSettings = AuctionSettings;
        this.OriginalSettings = OriginalSettings;
        this.WeekList = WeekList;
        this.SaveFormOperation = SaveFormOperation;
        this.ContentLanguage = ContentLanguage;
    }
}

export function DefaultAuctionSettings_State$reflection() {
    return record_type("Client.SettingsAuction.DefaultAuctionSettings.State", [], DefaultAuctionSettings_State, () => [["AuctionSettings", Settings_InfoSettings$reflection()], ["OriginalSettings", Settings_InfoSettings$reflection()], ["WeekList", list_type(enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]]))], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["ContentLanguage", string_type]]);
}

export function DefaultAuctionSettings_init(settings) {
    return [new DefaultAuctionSettings_State(settings, settings, ofArray([1, 2, 3, 4, 5, 6, 0]), new Deferred$1(0), getPrimaryLanguage()), Cmd_none()];
}

export function DefaultAuctionSettings_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord;
    if (msg.tag === 1) {
        return [new DefaultAuctionSettings_State((inputRecord_1 = state.AuctionSettings, new Settings_InfoSettings(inputRecord_1.AuctionStartDay, fromDate(msg.fields[0]), inputRecord_1.BasicInformation, inputRecord_1.Image)), state.OriginalSettings, state.WeekList, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new DefaultAuctionSettings_State((inputRecord_2 = state.AuctionSettings, new Settings_InfoSettings(inputRecord_2.AuctionStartDay, inputRecord_2.AuctionStartTime, LocalizedMap_add(msg.fields[0], msg.fields[1], msg.fields[2], state.AuctionSettings.BasicInformation), inputRecord_2.Image)), state.OriginalSettings, state.WeekList, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 3) {
        console.log(some(msg.fields[0].Version));
        return [new DefaultAuctionSettings_State((inputRecord_3 = state.AuctionSettings, new Settings_InfoSettings(inputRecord_3.AuctionStartDay, inputRecord_3.AuctionStartTime, inputRecord_3.BasicInformation, msg.fields[0])), state.OriginalSettings, state.WeekList, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new DefaultAuctionSettings_State(state.OriginalSettings, state.OriginalSettings, state.WeekList, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            return [new DefaultAuctionSettings_State(state.AuctionSettings, state.AuctionSettings, state.WeekList, new Deferred$1(2, msg.fields[0].fields[0]), state.ContentLanguage), Cmd_none()];
        }
        else if (!equals(state.AuctionSettings, state.OriginalSettings)) {
            return [new DefaultAuctionSettings_State(state.AuctionSettings, state.OriginalSettings, state.WeekList, new Deferred$1(1), state.ContentLanguage), Cmd_fromAsync(DefaultAuctionSettings_Cmd_updateAuctionCreateInfo(state.AuctionSettings))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 6) {
        return [new DefaultAuctionSettings_State(state.AuctionSettings, state.OriginalSettings, state.WeekList, state.SaveFormOperation, msg.fields[0]), Cmd_none()];
    }
    else {
        return [new DefaultAuctionSettings_State((inputRecord = state.AuctionSettings, new Settings_InfoSettings(msg.fields[0], inputRecord.AuctionStartTime, inputRecord.BasicInformation, inputRecord.Image)), state.OriginalSettings, state.WeekList, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
}

export function DefaultAuctionSettings_Render(settings) {
    let children_5, props_37, props_21, props_11, elms_1, props_2, props, elms, patternInput_1, props_19, elms_3, props_15, props_13, elms_2, props_25, elms_4, props_27, props_35, props_33, props_29, props_31;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(DefaultAuctionSettings_init(settings), (msg, state) => DefaultAuctionSettings_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isInProgress = equals(state_1.SaveFormOperation, new Deferred$1(1));
    let isSavedSuccessfully;
    const matchValue = state_1.SaveFormOperation;
    let pattern_matching_result;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            isSavedSuccessfully = true;
            break;
        }
        case 1: {
            isSavedSuccessfully = false;
            break;
        }
    }
    return react.createElement(react.Fragment, {}, (children_5 = singleton_1(createElement("fieldset", {
        disabled: isInProgress,
        children: Interop_reactApi.Children.toArray([(props_37 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_21 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_11 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.liveAuctionWeekDay")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1((patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["onChange", (ev) => {
            dispatch(new DefaultAuctionSettings_Msg(0, Settings_getValueOfWeek(ev.target.value)));
        }], ["value", Settings_getNameOfWeek(state_1.AuctionSettings.AuctionStartDay)], ["children", Interop_reactApi.Children.toArray(Array.from(map((x_1) => createElement("option", {
            children: Settings_getNameOfWeek(x_1),
        }), state_1.WeekList)))]])), createElement("div", {
            className: join(" ", cons("select", patternInput_1[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_11)))), (props_19 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_15 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.liveAuctionTime")]]), createElement("label", createObj(Helpers_combineClasses("label", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_15)))), (elms_2 = singleton_1(flatpickr(ofArray([Value(fromDateTimeOffset(state_1.AuctionSettings.AuctionStartTime, 0)), HideCalendar(true), EnableTimePicker(true), Placeholder(""), TimeTwentyFour(true), OnChange((arg) => {
            dispatch(new DefaultAuctionSettings_Msg(1, arg));
        }), ClassName("input"), Locale(Swedish)]))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_19))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_21)))), languageSwitcher(state_1.ContentLanguage, (arg_1) => {
            dispatch(new DefaultAuctionSettings_Msg(6, arg_1));
        }), (props_25 = ofArray([dividedBottom, ["children", Interop_reactApi.Children.toArray([(elms_4 = singleton_1(createElement("div", {
            className: "py-4",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const value_53 = (field) => LocalizedStringModule_getTranslationOrEmpty(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field, state_1.AuctionSettings.BasicInformation));
                const key_33 = (field_1) => (`${field_1}-${state_1.ContentLanguage}`);
                const update_1 = (field_2, text) => {
                    dispatch(new DefaultAuctionSettings_Msg(2, field_2, state_1.ContentLanguage, text));
                };
                return append(singleton(renderFieldWithoutValidation(t("auction.auctionName"), t("auction.auctionName"), key_33(new BasicInformationField(0)), value_53(new BasicInformationField(0)), partialApply(1, update_1, [new BasicInformationField(0)]))), delay(() => append(singleton(renderFieldWithoutValidation(t("auction.displayTitle"), "", key_33(new BasicInformationField(1)), value_53(new BasicInformationField(1)), partialApply(1, update_1, [new BasicInformationField(1)]))), delay(() => append(singleton(renderFieldWithoutValidation(t("auction.displayTime"), t("auction.displayTimePlaceholderSwedish"), key_33(new BasicInformationField(2)), value_53(new BasicInformationField(2)), partialApply(1, update_1, [new BasicInformationField(2)]))), delay(() => singleton(renderFieldWithoutValidation(t("auction.displayTime"), t("auction.displayTimePlaceholderSwedish2"), key_33(new BasicInformationField(3)), value_53(new BasicInformationField(3)), partialApply(1, update_1, [new BasicInformationField(3)])))))))));
            })))),
        })), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_25)))), (props_27 = ofArray([dividedBottom, ["children", Interop_reactApi.Children.toArray([createElement(EditImages, {
            image: state_1.AuctionSettings.Image,
            onImagesChanged: (arg_2) => {
                dispatch(new DefaultAuctionSettings_Msg(3, arg_2));
            },
            onDeleteImage: (_arg1_3) => {
            },
            needDeleteButton: false,
            flag: true,
            dependencies: [isSavedSuccessfully],
        })])]]), createElement("section", createObj(Helpers_combineClasses("section", props_27)))), (props_35 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_33 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_29 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x_2) => {
            x_2.preventDefault();
            dispatch(new DefaultAuctionSettings_Msg(4));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_29)))), (props_31 = toList(delay(() => append(singleton(["className", "is-primary"]), delay(() => append(equals(state_1.AuctionSettings, state_1.OriginalSettings) ? singleton(["disabled", true]) : empty_1(), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append(singleton(["children", t("common.save")]), delay(() => singleton(["onClick", (x_3) => {
            x_3.preventDefault();
            dispatch(new DefaultAuctionSettings_Msg(5, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_33))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_35))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_37))))]),
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_5)),
    })));
}

export class PriorityAuctionSettings_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateField", "FirstImagesChanged", "CatalogImagesChanged", "LowImagesChanged", "DeleteFirstImage", "DeleteCatalogImage", "DeleteLowImage", "CancelEdit", "SaveChanging", "OnContentLanguageChanged"];
    }
}

export function PriorityAuctionSettings_Msg$reflection() {
    return union_type("Client.SettingsAuction.PriorityAuctionSettings.Msg", [], PriorityAuctionSettings_Msg, () => [[["Item1", SpecialOfferInCatalogField$reflection()], ["Item2", string_type], ["Item3", string_type]], [["Item", Image$reflection()]], [["Item", Image$reflection()]], [["Item", Image$reflection()]], [], [], [], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", string_type]]]);
}

export function PriorityAuctionSettings_Cmd_updateAuctionPriorityCreateInfo(info) {
    return singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(Remoting_handleNonAuth(settingsApi().updateAuctionPriorityCreateInfo(info)), (_arg1) => singleton_2.Return(new PriorityAuctionSettings_Msg(8, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton_2.Return(new PriorityAuctionSettings_Msg(8, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export class PriorityAuctionSettings_State extends Record {
    constructor(AuctionPrioritySettings, OriginalSettings, SaveFormOperation, ContentLanguage) {
        super();
        this.AuctionPrioritySettings = AuctionPrioritySettings;
        this.OriginalSettings = OriginalSettings;
        this.SaveFormOperation = SaveFormOperation;
        this.ContentLanguage = ContentLanguage;
    }
}

export function PriorityAuctionSettings_State$reflection() {
    return record_type("Client.SettingsAuction.PriorityAuctionSettings.State", [], PriorityAuctionSettings_State, () => [["AuctionPrioritySettings", Settings_PrioritySettings$reflection()], ["OriginalSettings", Settings_PrioritySettings$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["ContentLanguage", string_type]]);
}

export function PriorityAuctionSettings_init(settings) {
    return [new PriorityAuctionSettings_State(settings, settings, new Deferred$1(0), getPrimaryLanguage()), Cmd_none()];
}

export function PriorityAuctionSettings_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord;
    if (msg.tag === 1) {
        return [new PriorityAuctionSettings_State((inputRecord_1 = state.AuctionPrioritySettings, new Settings_PrioritySettings(inputRecord_1.SpecialOffersTextInCatalog, msg.fields[0], inputRecord_1.CatalogImage, inputRecord_1.LowImage)), state.OriginalSettings, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new PriorityAuctionSettings_State((inputRecord_2 = state.AuctionPrioritySettings, new Settings_PrioritySettings(inputRecord_2.SpecialOffersTextInCatalog, inputRecord_2.FirstImage, msg.fields[0], inputRecord_2.LowImage)), state.OriginalSettings, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new PriorityAuctionSettings_State((inputRecord_3 = state.AuctionPrioritySettings, new Settings_PrioritySettings(inputRecord_3.SpecialOffersTextInCatalog, inputRecord_3.FirstImage, inputRecord_3.CatalogImage, msg.fields[0])), state.OriginalSettings, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new PriorityAuctionSettings_State((inputRecord_4 = state.AuctionPrioritySettings, new Settings_PrioritySettings(inputRecord_4.SpecialOffersTextInCatalog, void 0, inputRecord_4.CatalogImage, inputRecord_4.LowImage)), state.OriginalSettings, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new PriorityAuctionSettings_State((inputRecord_5 = state.AuctionPrioritySettings, new Settings_PrioritySettings(inputRecord_5.SpecialOffersTextInCatalog, inputRecord_5.FirstImage, void 0, inputRecord_5.LowImage)), state.OriginalSettings, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new PriorityAuctionSettings_State((inputRecord_6 = state.AuctionPrioritySettings, new Settings_PrioritySettings(inputRecord_6.SpecialOffersTextInCatalog, inputRecord_6.FirstImage, inputRecord_6.CatalogImage, void 0)), state.OriginalSettings, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new PriorityAuctionSettings_State(state.OriginalSettings, state.OriginalSettings, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            return [new PriorityAuctionSettings_State(state.AuctionPrioritySettings, state.AuctionPrioritySettings, new Deferred$1(2, msg.fields[0].fields[0]), state.ContentLanguage), Cmd_none()];
        }
        else if (!equals(state.AuctionPrioritySettings, state.OriginalSettings)) {
            return [new PriorityAuctionSettings_State(state.AuctionPrioritySettings, state.OriginalSettings, new Deferred$1(1), state.ContentLanguage), Cmd_fromAsync(PriorityAuctionSettings_Cmd_updateAuctionPriorityCreateInfo(state.AuctionPrioritySettings))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 9) {
        return [new PriorityAuctionSettings_State(state.AuctionPrioritySettings, state.OriginalSettings, state.SaveFormOperation, msg.fields[0]), Cmd_none()];
    }
    else {
        return [new PriorityAuctionSettings_State((inputRecord = state.AuctionPrioritySettings, new Settings_PrioritySettings(LocalizedMap_add(msg.fields[0], msg.fields[1], msg.fields[2], state.AuctionPrioritySettings.SpecialOffersTextInCatalog), inputRecord.FirstImage, inputRecord.CatalogImage, inputRecord.LowImage)), state.OriginalSettings, state.SaveFormOperation, state.ContentLanguage), Cmd_none()];
    }
}

export function PriorityAuctionSettings_Render(settings) {
    let children_3, elms, props_2, props, elms_1, props_7, props_5, elms_2, props_12, props_10, props_21, props_19, props_15, props_17;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(PriorityAuctionSettings_init(settings), (msg, state) => PriorityAuctionSettings_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isInProgress = equals(state_1.SaveFormOperation, new Deferred$1(1));
    let isSavedSuccessfully;
    const matchValue = state_1.SaveFormOperation;
    let pattern_matching_result;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            isSavedSuccessfully = true;
            break;
        }
        case 1: {
            isSavedSuccessfully = false;
            break;
        }
    }
    return react.createElement(react.Fragment, {}, (children_3 = singleton_1(createElement("fieldset", {
        disabled: isInProgress,
        children: Interop_reactApi.Children.toArray([(elms = ofArray([(props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(languageSwitcher(state_1.ContentLanguage, (arg) => {
            dispatch(new PriorityAuctionSettings_Msg(9, arg));
        })), delay(() => {
            const value_4 = (field) => LocalizedStringModule_getTranslationOrEmpty(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field, state_1.AuctionPrioritySettings.SpecialOffersTextInCatalog));
            const key_2 = (field_1) => (`${field_1}-${state_1.ContentLanguage}`);
            const update_1 = (field_2, text) => {
                dispatch(new PriorityAuctionSettings_Msg(0, field_2, state_1.ContentLanguage, text));
            };
            return append(singleton(renderFieldWithoutValidation(t("auction.auctionName"), t("auction.auctionName"), key_2(new SpecialOfferInCatalogField(0)), value_4(new SpecialOfferInCatalogField(0)), partialApply(1, update_1, [new SpecialOfferInCatalogField(0)]))), delay(() => singleton(renderTextArea(t("auction.weeklySpecialsText"), key_2(new SpecialOfferInCatalogField(1)), value_4(new SpecialOfferInCatalogField(1)), partialApply(1, update_1, [new SpecialOfferInCatalogField(1)])))));
        }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_2)))), createElement(EditImages, {
            image: state_1.AuctionPrioritySettings.FirstImage,
            onImagesChanged: (arg_1) => {
                dispatch(new PriorityAuctionSettings_Msg(1, arg_1));
            },
            onDeleteImage: (_arg1) => {
                dispatch(new PriorityAuctionSettings_Msg(4));
            },
            needDeleteButton: true,
            flag: false,
            dependencies: [isSavedSuccessfully],
        })]), createElement("section", {
            className: "section",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = ofArray([(props_7 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(languageSwitcher(state_1.ContentLanguage, (arg_2) => {
            dispatch(new PriorityAuctionSettings_Msg(9, arg_2));
        })), delay(() => {
            const value_12 = (field_3) => LocalizedStringModule_getTranslationOrEmpty(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field_3, state_1.AuctionPrioritySettings.SpecialOffersTextInCatalog));
            const key_8 = (field_4) => (`${field_4}-${state_1.ContentLanguage}`);
            const update_2 = (field_5, text_1) => {
                dispatch(new PriorityAuctionSettings_Msg(0, field_5, state_1.ContentLanguage, text_1));
            };
            return append(singleton(renderFieldWithoutValidation(t("auction.weeklyCatalogTitle"), "", key_8(new SpecialOfferInCatalogField(2)), value_12(new SpecialOfferInCatalogField(2)), partialApply(1, update_2, [new SpecialOfferInCatalogField(2)]))), delay(() => singleton(renderTextArea(t("auction.weeklyCatalogText"), key_8(new SpecialOfferInCatalogField(3)), value_12(new SpecialOfferInCatalogField(3)), partialApply(1, update_2, [new SpecialOfferInCatalogField(3)])))));
        }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_5))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_7)))), createElement(EditImages, {
            image: state_1.AuctionPrioritySettings.CatalogImage,
            onImagesChanged: (arg_3) => {
                dispatch(new PriorityAuctionSettings_Msg(2, arg_3));
            },
            onDeleteImage: (_arg2) => {
                dispatch(new PriorityAuctionSettings_Msg(5));
            },
            needDeleteButton: true,
            flag: false,
            dependencies: [isSavedSuccessfully],
        })]), createElement("section", {
            className: "section",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = ofArray([(props_12 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(languageSwitcher(state_1.ContentLanguage, (arg_4) => {
            dispatch(new PriorityAuctionSettings_Msg(9, arg_4));
        })), delay(() => {
            const value_20 = (field_6) => LocalizedStringModule_getTranslationOrEmpty(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field_6, state_1.AuctionPrioritySettings.SpecialOffersTextInCatalog));
            const key_14 = (field_7) => (`${field_7}-${state_1.ContentLanguage}`);
            const update_3 = (field_8, text_2) => {
                dispatch(new PriorityAuctionSettings_Msg(0, field_8, state_1.ContentLanguage, text_2));
            };
            return append(singleton(renderFieldWithoutValidation(t("auction.weeklyMarketTitle"), "", key_14(new SpecialOfferInCatalogField(4)), value_20(new SpecialOfferInCatalogField(4)), partialApply(1, update_3, [new SpecialOfferInCatalogField(4)]))), delay(() => singleton(renderTextArea(t("auction.weeklyMarketText"), key_14(new SpecialOfferInCatalogField(5)), value_20(new SpecialOfferInCatalogField(5)), partialApply(1, update_3, [new SpecialOfferInCatalogField(5)])))));
        }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_12)))), createElement(EditImages, {
            image: state_1.AuctionPrioritySettings.LowImage,
            onImagesChanged: (arg_5) => {
                dispatch(new PriorityAuctionSettings_Msg(3, arg_5));
            },
            onDeleteImage: (_arg3) => {
                dispatch(new PriorityAuctionSettings_Msg(6));
            },
            needDeleteButton: true,
            flag: false,
            dependencies: [isSavedSuccessfully],
        })]), createElement("section", {
            className: "section",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (props_21 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x) => {
            x.preventDefault();
            dispatch(new PriorityAuctionSettings_Msg(7));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_15)))), (props_17 = toList(delay(() => append(singleton(["className", "is-primary"]), delay(() => append(equals(state_1.AuctionPrioritySettings, state_1.OriginalSettings) ? singleton(["disabled", true]) : empty_1(), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append(singleton(["children", t("common.save")]), delay(() => singleton(["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new PriorityAuctionSettings_Msg(8, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_19))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_21))))]),
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_3)),
    })));
}

export class EditMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Timer", "General", "Priority", "BiddingIntervals"];
    }
}

export function EditMode$reflection() {
    return union_type("Client.SettingsAuction.EditMode", [], EditMode, () => [[], [], [], []]);
}

export function Render() {
    const patternInput = useFeliz_React__React_useState_Static_1505(new EditMode(0));
    const setEditMode = patternInput[1];
    const editMode = patternInput[0];
    if (isCurrentUserAdmin()) {
        const settings = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getAuctionSettings()), [editMode]);
        switch (settings.tag) {
            case 1: {
                return loader("big");
            }
            case 3: {
                return settings.fields[0].message;
            }
            case 2: {
                const settings_1 = settings.fields[0];
                const elms = toList(delay(() => {
                    let props_1, s;
                    return append(singleton((props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("settings.auctionSettings"), createElement("h1", {
                        className: "title",
                        children: s,
                    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1))))), delay(() => {
                        let props_18, props_16, children, props_4, props_7, props_10, props_13;
                        return append(singleton((props_18 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-toggle"], ["className", "is-centered"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_4 = toList(delay(() => append(equals(editMode, new EditMode(0)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("auction.liveAuction"),
                            onClick: (_arg1) => {
                                setEditMode(new EditMode(0));
                            },
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_7 = toList(delay(() => append(equals(editMode, new EditMode(3)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("common.biddingIntervals"),
                            onClick: (_arg2) => {
                                setEditMode(new EditMode(3));
                            },
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7)))), (props_10 = toList(delay(() => append(equals(editMode, new EditMode(1)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("auction.generalSettings"),
                            onClick: (_arg3) => {
                                setEditMode(new EditMode(1));
                            },
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_10)))), (props_13 = toList(delay(() => append(equals(editMode, new EditMode(2)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("auction.prioritySettings"),
                            onClick: (_arg4) => {
                                setEditMode(new EditMode(2));
                            },
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_13))))]), createElement("ul", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_16))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_18))))), delay(() => ((editMode.tag === 3) ? singleton(createElement(Render_1, null)) : ((editMode.tag === 1) ? singleton(createElement(DefaultAuctionSettings_Render, settings_1[0].InfoAuctionSettings)) : ((editMode.tag === 2) ? singleton(createElement(PriorityAuctionSettings_Render, settings_1[1])) : singleton(createElement(AuctionTimer_Render, settings_1[0].TimerSettings)))))));
                    }));
                }));
                return createElement("div", {
                    className: "container",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                });
            }
            default: {
                return loader("big");
            }
        }
    }
    else {
        return null;
    }
}

