import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { list_type, tuple_type, int32_type, record_type, union_type, unit_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Content_Type, Content_HistoryPageInfo, Content_HistoryPageInfo$reflection, Content_PromotionInfo, Content_PromotionInfo$reflection, Content_HomePageInfo, Content_ContentField, Image$reflection, Content_HomePageInfo$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { contentApi } from "./Communication.js";
import { t, getPrimaryLanguage } from "./Localization.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { LocalizedMap_getValueOrEmpty, LocalizedStringModule_getTranslationOrEmpty, LocalizedMap_add } from "./bidflow/src/infrastructure/Localization.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { languageSwitcher } from "./Components/ContentLanguageSwitcher.js";
import { renderTextArea, renderFieldWithoutValidation } from "./SettingsAuction.js";
import { map, singleton as singleton_2, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { EditImages } from "./Components/DownloadBackgroundImage.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";
import { Render as Render_1 } from "./Faq.js";

export class HomePage_State extends Record {
    constructor(ContentLanguage, Info, OriginalInfo, SaveFormOperation) {
        super();
        this.ContentLanguage = ContentLanguage;
        this.Info = Info;
        this.OriginalInfo = OriginalInfo;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function HomePage_State$reflection() {
    return record_type("ContentManagement.HomePage.State", [], HomePage_State, () => [["ContentLanguage", string_type], ["Info", Content_HomePageInfo$reflection()], ["OriginalInfo", Content_HomePageInfo$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, unit_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", unit_type]]]))]]);
}

export class HomePage_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateTitle", "UpdateText", "ImagesChanged", "DeleteImage", "CancelEdit", "SaveChanging", "ContentLanguageChanged"];
    }
}

export function HomePage_Msg$reflection() {
    return union_type("ContentManagement.HomePage.Msg", [], HomePage_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", Image$reflection()]], [], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, unit_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", unit_type]]]))]], [["Item", string_type]]]);
}

export function HomePage_Cmd_updateHomePageInfo(info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(contentApi().saveHomePageInfo(info)), (_arg1) => singleton.Return(new HomePage_Msg(5, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new HomePage_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, void 0))))));
}

export function HomePage_init(settings) {
    return [new HomePage_State(getPrimaryLanguage(), settings, settings, new Deferred$1(0)), Cmd_none()];
}

export function HomePage_update(msg, state) {
    if (msg.tag === 1) {
        return [new HomePage_State(state.ContentLanguage, new Content_HomePageInfo(LocalizedMap_add(new Content_ContentField(1), state.ContentLanguage, msg.fields[0], state.Info.Content), state.Info.Image), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new HomePage_State(state.ContentLanguage, new Content_HomePageInfo(state.Info.Content, msg.fields[0]), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new HomePage_State(state.ContentLanguage, new Content_HomePageInfo(state.Info.Content, void 0), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new HomePage_State(state.ContentLanguage, state.OriginalInfo, state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            return [new HomePage_State(state.ContentLanguage, state.Info, state.Info, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.Info, state.OriginalInfo)) {
            return [new HomePage_State(state.ContentLanguage, state.Info, state.OriginalInfo, new Deferred$1(1)), Cmd_fromAsync(HomePage_Cmd_updateHomePageInfo(state.Info))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 6) {
        return [new HomePage_State(msg.fields[0], state.Info, state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else {
        return [new HomePage_State(state.ContentLanguage, new Content_HomePageInfo(LocalizedMap_add(new Content_ContentField(0), state.ContentLanguage, msg.fields[0], state.Info.Content), state.Info.Image), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
}

export function HomePage_Render(content) {
    let props_7;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(HomePage_init(content), (msg, state) => HomePage_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    let isSavedSuccessfully;
    const matchValue = state_1.SaveFormOperation;
    let pattern_matching_result;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            isSavedSuccessfully = true;
            break;
        }
        case 1: {
            isSavedSuccessfully = false;
            break;
        }
    }
    return react.createElement(react.Fragment, {}, createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_7 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(languageSwitcher(state_1.ContentLanguage, (arg) => {
            dispatch(new HomePage_Msg(6, arg));
        })), delay(() => {
            let children;
            const key = (field) => (`${field}-${state_1.ContentLanguage}`);
            const value = (field_1) => LocalizedStringModule_getTranslationOrEmpty(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field_1, state_1.Info.Content));
            return append(singleton_1((children = ofArray([renderFieldWithoutValidation(t("auction.informationTitle"), t("auction.informationTitle"), key(new Content_ContentField(0)), value(new Content_ContentField(0)), (newTitle) => {
                dispatch(new HomePage_Msg(0, newTitle));
            }), renderTextArea(t("auction.longText"), key(new Content_ContentField(1)), value(new Content_ContentField(1)), (newText) => {
                dispatch(new HomePage_Msg(1, newText));
            })]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), delay(() => append(singleton_1(createElement(EditImages, {
                image: state_1.Info.Image,
                onImagesChanged: (arg_1) => {
                    dispatch(new HomePage_Msg(2, arg_1));
                },
                onDeleteImage: (_arg1) => {
                    dispatch(new HomePage_Msg(3));
                },
                needDeleteButton: true,
                flag: false,
                dependencies: [isSavedSuccessfully],
            })), delay(() => {
                let props_5, props_1, props_3;
                return singleton_1((props_5 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x) => {
                    x.preventDefault();
                    dispatch(new HomePage_Msg(4));
                }]]), createElement("button", createObj(Helpers_combineClasses("button", props_1)))), (props_3 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (x_1) => {
                    x_1.preventDefault();
                    dispatch(new HomePage_Msg(5, new AsyncOperationStatus$1(0)));
                }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_5)))));
            }))));
        }))))))]), createElement("section", createObj(Helpers_combineClasses("section", props_7))))]),
    }));
}

export class Promotions_State extends Record {
    constructor(ContentLanguage, Info, OriginalInfo, SaveFormOperation) {
        super();
        this.ContentLanguage = ContentLanguage;
        this.Info = Info;
        this.OriginalInfo = OriginalInfo;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function Promotions_State$reflection() {
    return record_type("ContentManagement.Promotions.State", [], Promotions_State, () => [["ContentLanguage", string_type], ["Info", list_type(tuple_type(int32_type, Content_PromotionInfo$reflection()))], ["OriginalInfo", list_type(tuple_type(int32_type, Content_PromotionInfo$reflection()))], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, unit_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", unit_type]]]))]]);
}

export class Promotions_NumberPromotion extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["First", "Second", "Third"];
    }
}

export function Promotions_NumberPromotion$reflection() {
    return union_type("ContentManagement.Promotions.NumberPromotion", [], Promotions_NumberPromotion, () => [[], [], []]);
}

export class Promotions_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateTitle", "UpdateText", "UpdateLink", "ImagesChanged", "DeleteImage", "CancelEdit", "SaveChanging", "ContentLanguageChanged"];
    }
}

export function Promotions_Msg$reflection() {
    return union_type("ContentManagement.Promotions.Msg", [], Promotions_Msg, () => [[["Item1", string_type], ["Item2", int32_type]], [["Item1", string_type], ["Item2", int32_type]], [["Item1", string_type], ["Item2", int32_type]], [["Item1", Image$reflection()], ["Item2", int32_type]], [["Item", int32_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, unit_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", unit_type]]]))]], [["Item", string_type]]]);
}

export function Promotions_Cmd_updateHomePageInfo(info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const info_1 = map((tuple) => tuple[1], info);
        return singleton.Bind(Remoting_handleNonAuth(contentApi().savePromotionsInfo(info_1)), (_arg1) => singleton.Return(new Promotions_Msg(6, new AsyncOperationStatus$1(1, _arg1))));
    }), (_arg2) => singleton.Return(new Promotions_Msg(6, new AsyncOperationStatus$1(1, new FSharpResult$2(1, void 0))))));
}

export function Promotions_init(settings) {
    return [new Promotions_State(getPrimaryLanguage(), settings, settings, new Deferred$1(0)), Cmd_none()];
}

export function Promotions_update(msg, state) {
    if (msg.tag === 1) {
        return [new Promotions_State(state.ContentLanguage, map((tupledArg_1) => {
            const i_1 = tupledArg_1[0] | 0;
            const x_2 = tupledArg_1[1];
            if (i_1 === msg.fields[1]) {
                return [i_1, new Content_PromotionInfo(LocalizedMap_add(new Content_ContentField(1), state.ContentLanguage, msg.fields[0], x_2.Content), x_2.Image, x_2.Link)];
            }
            else {
                return [i_1, x_2];
            }
        }, state.Info), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new Promotions_State(state.ContentLanguage, map((tupledArg_2) => {
            const i_2 = tupledArg_2[0] | 0;
            const x_4 = tupledArg_2[1];
            if (i_2 === msg.fields[1]) {
                return [i_2, new Content_PromotionInfo(x_4.Content, x_4.Image, msg.fields[0])];
            }
            else {
                return [i_2, x_4];
            }
        }, state.Info), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new Promotions_State(state.ContentLanguage, map((tupledArg_3) => {
            const i_3 = tupledArg_3[0] | 0;
            const x_5 = tupledArg_3[1];
            if (i_3 === msg.fields[1]) {
                return [i_3, new Content_PromotionInfo(x_5.Content, msg.fields[0], x_5.Link)];
            }
            else {
                return [i_3, x_5];
            }
        }, state.Info), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Promotions_State(state.ContentLanguage, map((tupledArg_4) => {
            const i_4 = tupledArg_4[0] | 0;
            const x_6 = tupledArg_4[1];
            if (i_4 === msg.fields[0]) {
                return [i_4, new Content_PromotionInfo(x_6.Content, void 0, x_6.Link)];
            }
            else {
                return [i_4, x_6];
            }
        }, state.Info), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new Promotions_State(state.ContentLanguage, state.OriginalInfo, state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            return [new Promotions_State(state.ContentLanguage, state.Info, state.Info, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.Info, state.OriginalInfo)) {
            return [new Promotions_State(state.ContentLanguage, state.Info, state.OriginalInfo, new Deferred$1(1)), Cmd_fromAsync(Promotions_Cmd_updateHomePageInfo(state.Info))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 7) {
        return [new Promotions_State(msg.fields[0], state.Info, state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else {
        return [new Promotions_State(state.ContentLanguage, map((tupledArg) => {
            const i = tupledArg[0] | 0;
            const x = tupledArg[1];
            if (i === msg.fields[1]) {
                return [i, new Content_PromotionInfo(LocalizedMap_add(new Content_ContentField(0), state.ContentLanguage, msg.fields[0], x.Content), x.Image, x.Link)];
            }
            else {
                return [i, x];
            }
        }, state.Info), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
}

export function Promotions_renderContentBlock(state, dispatch, isSavedSuccessfully, i, item) {
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const key = (field) => (`${i}-${field}-${state.ContentLanguage}`);
            const value = (field_1) => LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, LocalizedMap_getValueOrEmpty(field_1, item.Content));
            return append(singleton_1(renderFieldWithoutValidation(t("auction.informationTitle"), t("auction.informationTitle"), key(new Content_ContentField(0)), value(new Content_ContentField(0)), (newTitle) => {
                dispatch(new Promotions_Msg(0, newTitle, i));
            })), delay(() => append(singleton_1(renderTextArea(t("auction.longText"), key(new Content_ContentField(1)), value(new Content_ContentField(1)), (newText) => {
                dispatch(new Promotions_Msg(1, newText, i));
            })), delay(() => append(singleton_1(renderFieldWithoutValidation(t("common.link"), t("common.link"), key("Link"), item.Link, (n) => {
                dispatch(new Promotions_Msg(2, n, i));
            })), delay(() => singleton_1(createElement(EditImages, {
                image: item.Image,
                onImagesChanged: (x) => {
                    dispatch(new Promotions_Msg(3, x, i));
                },
                onDeleteImage: (_arg1) => {
                    dispatch(new Promotions_Msg(4, i));
                },
                needDeleteButton: true,
                flag: false,
                dependencies: [isSavedSuccessfully],
            }))))))));
        })))),
    });
}

export function Promotions_Render(promotions_RenderInputProps) {
    let props_7, props_5, props_1, props_3;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(Promotions_init(promotions_RenderInputProps.content), (msg, state) => Promotions_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    let isSavedSuccessfully;
    const matchValue = state_1.SaveFormOperation;
    let pattern_matching_result;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            isSavedSuccessfully = true;
            break;
        }
        case 1: {
            isSavedSuccessfully = false;
            break;
        }
    }
    return react.createElement(react.Fragment, {}, createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_7 = singleton_2(["children", Interop_reactApi.Children.toArray([languageSwitcher(state_1.ContentLanguage, (arg) => {
            dispatch(new Promotions_Msg(7, arg));
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => Promotions_renderContentBlock(state_1, dispatch, isSavedSuccessfully, tupledArg[0], tupledArg[1]), state_1.Info))),
        }), (props_5 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x) => {
            x.preventDefault();
            dispatch(new Promotions_Msg(5));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_1)))), (props_3 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new Promotions_Msg(6, new AsyncOperationStatus$1(0)));
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_5))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_7))))]),
    }));
}

export class HistoryPage_State extends Record {
    constructor(ContentLanguage, Info, OriginalInfo, SaveFormOperation) {
        super();
        this.ContentLanguage = ContentLanguage;
        this.Info = Info;
        this.OriginalInfo = OriginalInfo;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function HistoryPage_State$reflection() {
    return record_type("ContentManagement.HistoryPage.State", [], HistoryPage_State, () => [["ContentLanguage", string_type], ["Info", Content_HistoryPageInfo$reflection()], ["OriginalInfo", Content_HistoryPageInfo$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, unit_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", unit_type]]]))]]);
}

export class HistoryPage_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateTitle", "UpdateText", "CancelEdit", "SaveChanging", "ContentLanguageChanged"];
    }
}

export function HistoryPage_Msg$reflection() {
    return union_type("ContentManagement.HistoryPage.Msg", [], HistoryPage_Msg, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, unit_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", unit_type]]]))]], [["Item", string_type]]]);
}

export function HistoryPage_Cmd_updateHomePageInfo(info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(contentApi().saveHistoryPageInfo(info)), (_arg1) => singleton.Return(new HistoryPage_Msg(3, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new HistoryPage_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, void 0))))));
}

export function HistoryPage_init(settings) {
    return [new HistoryPage_State(getPrimaryLanguage(), settings, settings, new Deferred$1(0)), Cmd_none()];
}

export function HistoryPage_update(msg, state) {
    if (msg.tag === 1) {
        return [new HistoryPage_State(state.ContentLanguage, new Content_HistoryPageInfo(LocalizedMap_add(new Content_ContentField(1), state.ContentLanguage, msg.fields[0], state.Info.Content)), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new HistoryPage_State(state.ContentLanguage, state.OriginalInfo, state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [new HistoryPage_State(state.ContentLanguage, state.Info, state.Info, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.Info, state.OriginalInfo)) {
            return [new HistoryPage_State(state.ContentLanguage, state.Info, state.OriginalInfo, new Deferred$1(1)), Cmd_fromAsync(HistoryPage_Cmd_updateHomePageInfo(state.Info))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 4) {
        return [new HistoryPage_State(msg.fields[0], state.Info, state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
    else {
        return [new HistoryPage_State(state.ContentLanguage, new Content_HistoryPageInfo(LocalizedMap_add(new Content_ContentField(0), state.ContentLanguage, msg.fields[0], state.Info.Content)), state.OriginalInfo, state.SaveFormOperation), Cmd_none()];
    }
}

export function HistoryPage_Render(content) {
    let props_7;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(HistoryPage_init(content), (msg, state) => HistoryPage_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return react.createElement(react.Fragment, {}, createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_7 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(languageSwitcher(state_1.ContentLanguage, (arg) => {
            dispatch(new HistoryPage_Msg(4, arg));
        })), delay(() => {
            let children;
            const key = (field) => (`${field}-${state_1.ContentLanguage}`);
            const value = (field_1) => LocalizedStringModule_getTranslationOrEmpty(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field_1, state_1.Info.Content));
            return append(singleton_1((children = ofArray([renderFieldWithoutValidation(t("auction.informationTitle"), t("auction.informationTitle"), key(new Content_ContentField(0)), value(new Content_ContentField(0)), (newTitle) => {
                dispatch(new HistoryPage_Msg(0, newTitle));
            }), renderTextArea(t("auction.longText"), key(new Content_ContentField(1)), value(new Content_ContentField(1)), (newText) => {
                dispatch(new HistoryPage_Msg(1, newText));
            })]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), delay(() => {
                let props_5, props_1, props_3;
                return singleton_1((props_5 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x) => {
                    x.preventDefault();
                    dispatch(new HistoryPage_Msg(2));
                }]]), createElement("button", createObj(Helpers_combineClasses("button", props_1)))), (props_3 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (x_1) => {
                    x_1.preventDefault();
                    dispatch(new HistoryPage_Msg(3, new AsyncOperationStatus$1(0)));
                }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_5)))));
            }));
        }))))))]), createElement("section", createObj(Helpers_combineClasses("section", props_7))))]),
    }));
}

export function Render() {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Content_Type(0));
    const setEditMode = patternInput[1];
    const editMode = patternInput[0];
    const content = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(contentApi().getAllInfo()), [editMode]);
    switch (content.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            return content.fields[0].message;
        }
        case 2: {
            const res = content.fields[0];
            const elms = toList(delay(() => {
                let props_1, s;
                return append(singleton_1((props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("home.content"), createElement("h1", {
                    className: "title",
                    children: s,
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1))))), delay(() => {
                    let props_18, props_16, children, props_4, props_7, props_10, props_13;
                    return append(singleton_1((props_18 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-toggle"], ["className", "is-centered"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_4 = toList(delay(() => append(equals(editMode, new Content_Type(0)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                        className: "is-normal-padding",
                        children: t("contentManagement.home"),
                        onClick: (_arg1) => {
                            setEditMode(new Content_Type(0));
                        },
                    })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_7 = toList(delay(() => append(equals(editMode, new Content_Type(2)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                        className: "is-normal-padding",
                        children: t("contentManagement.promotions"),
                        onClick: (_arg2) => {
                            setEditMode(new Content_Type(2));
                        },
                    })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7)))), (props_10 = toList(delay(() => append(equals(editMode, new Content_Type(1)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                        className: "is-normal-padding",
                        children: t("contentManagement.auctionHistory"),
                        onClick: (_arg3) => {
                            setEditMode(new Content_Type(1));
                        },
                    })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_10)))), (props_13 = toList(delay(() => append(equals(editMode, new Content_Type(3)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                        className: "is-normal-padding",
                        children: t("contentManagement.faq"),
                        onClick: (_arg4) => {
                            setEditMode(new Content_Type(3));
                        },
                    })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_13))))]), createElement("ul", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_16))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_18))))), delay(() => ((editMode.tag === 2) ? singleton_1(createElement(Promotions_Render, {
                        content: res.Promotions,
                    })) : ((editMode.tag === 1) ? singleton_1(createElement(HistoryPage_Render, res.History)) : ((editMode.tag === 3) ? singleton_1(createElement(Render_1, {
                        content: res.Faq,
                    })) : singleton_1(createElement(HomePage_Render, res.Home)))))));
                }));
            }));
            return createElement("div", {
                className: "container",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            });
        }
        default: {
            return loader("big");
        }
    }
}

