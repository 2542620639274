import { Record, Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { record_type, bool_type, list_type, union_type, unit_type, string_type, int32_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Content_FaqField, Content_FaqInfo, Content_FaqInfo$reflection, Content_FaqField$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, Deferred$1$reflection, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { contentApi } from "./Communication.js";
import { t, getSupportedLanguages, getPrimaryLanguage } from "./Localization.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { LocalizedStringModule_getTranslationOrEmpty, LangModule_toString, LocalizedMap_getValueOrEmpty, LocalizedStringModule_setTranslation, LocalizedMap_empty, LocalizedStringModule_from } from "./bidflow/src/infrastructure/Localization.js";
import { ofArray, cons, indexed, filter, map, append, singleton as singleton_1 } from "./fable_modules/fable-library.3.6.1/List.js";
import { add } from "./fable_modules/fable-library.3.6.1/Map.js";
import { stringHash, createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { collect, empty, singleton as singleton_2, append as append_1, map as map_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { List_distinct } from "./fable_modules/fable-library.3.6.1/Seq2.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateSectionName", "UpdateFields", "OnContentLanguageChanged", "AddSection", "DeleteSection", "AddQuestion", "DeleteQuestion", "SwitchEditMode", "SaveChanging"];
    }
}

export function Msg$reflection() {
    return union_type("Faq.Msg", [], Msg, () => [[["Item1", int32_type], ["Item2", string_type]], [["Item1", int32_type], ["Item2", int32_type], ["Item3", string_type], ["Item4", Content_FaqField$reflection()]], [["Item", string_type]], [], [["Item", int32_type]], [["Item", int32_type]], [["Item1", int32_type], ["Item2", int32_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, unit_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", unit_type]]]))]]]);
}

export function Cmd_updateFaqInfo(info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(contentApi().saveFaqInfo(info)), (_arg1) => singleton.Return(new Msg(8, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Msg(8, new AsyncOperationStatus$1(1, new FSharpResult$2(1, void 0))))));
}

export class State extends Record {
    constructor(ContentLanguage, Info, OriginalInfo, EditMode, SaveFormOperation) {
        super();
        this.ContentLanguage = ContentLanguage;
        this.Info = Info;
        this.OriginalInfo = OriginalInfo;
        this.EditMode = EditMode;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function State$reflection() {
    return record_type("Faq.State", [], State, () => [["ContentLanguage", string_type], ["Info", list_type(Content_FaqInfo$reflection())], ["OriginalInfo", list_type(Content_FaqInfo$reflection())], ["EditMode", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, unit_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", unit_type]]]))]]);
}

export function init(settings) {
    return [new State(getPrimaryLanguage(), settings, settings, false, new Deferred$1(0)), Cmd_none()];
}

export function defaultSection(state) {
    return new Content_FaqInfo(LocalizedStringModule_from(singleton_1([state.ContentLanguage, ""])), singleton_1(LocalizedMap_empty()));
}

export function update(msg, state) {
    if (msg.tag === 7) {
        return [state.EditMode ? (new State(state.ContentLanguage, state.OriginalInfo, state.OriginalInfo, !state.EditMode, state.SaveFormOperation)) : (new State(state.ContentLanguage, state.Info, state.OriginalInfo, !state.EditMode, state.SaveFormOperation)), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new State(state.ContentLanguage, append(state.Info, singleton_1(defaultSection(state))), state.OriginalInfo, state.EditMode, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new State(state.ContentLanguage, map((tuple) => tuple[1], filter((tupledArg) => (tupledArg[0] !== msg.fields[0]), indexed(state.Info))), state.OriginalInfo, state.EditMode, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new State(state.ContentLanguage, map((tuple_1) => tuple_1[1], map((tupledArg_1) => {
            const ind_1 = tupledArg_1[0] | 0;
            const x = tupledArg_1[1];
            if (ind_1 === msg.fields[0]) {
                return [ind_1, new Content_FaqInfo(x.SectionName, append(x.QuestionList, singleton_1(LocalizedMap_empty())))];
            }
            else {
                return [ind_1, x];
            }
        }, indexed(state.Info))), state.OriginalInfo, state.EditMode, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new State(state.ContentLanguage, map((tuple_3) => tuple_3[1], map((tupledArg_2) => {
            const ind_2 = tupledArg_2[0] | 0;
            const x_1 = tupledArg_2[1];
            if (ind_2 === msg.fields[0]) {
                return [ind_2, new Content_FaqInfo(x_1.SectionName, map((tuple_2) => tuple_2[1], filter((tupledArg_3) => (tupledArg_3[0] !== msg.fields[1]), indexed(x_1.QuestionList))))];
            }
            else {
                return [ind_2, x_1];
            }
        }, indexed(state.Info))), state.OriginalInfo, state.EditMode, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new State(state.ContentLanguage, map((tuple_5) => tuple_5[1], map((tupledArg_4) => {
            const ind_3 = tupledArg_4[0] | 0;
            const x_2 = tupledArg_4[1];
            if (ind_3 === msg.fields[0]) {
                return [ind_3, new Content_FaqInfo(x_2.SectionName, map((tuple_4) => tuple_4[1], map((tupledArg_5) => {
                    const i_2 = tupledArg_5[0] | 0;
                    const q = tupledArg_5[1];
                    if (i_2 === msg.fields[1]) {
                        return [i_2, add(msg.fields[3], LocalizedStringModule_setTranslation(state.ContentLanguage, msg.fields[2], LocalizedMap_getValueOrEmpty(msg.fields[3], q)), q)];
                    }
                    else {
                        return [i_2, q];
                    }
                }, indexed(x_2.QuestionList))))];
            }
            else {
                return [ind_3, x_2];
            }
        }, indexed(state.Info))), state.OriginalInfo, state.EditMode, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 0) {
        return [new State(state.ContentLanguage, map((tuple_6) => tuple_6[1], map((tupledArg_6) => {
            const i_3 = tupledArg_6[0] | 0;
            const s = tupledArg_6[1];
            if (msg.fields[0] === i_3) {
                return [i_3, new Content_FaqInfo(LocalizedStringModule_setTranslation(state.ContentLanguage, msg.fields[1], s.SectionName), s.QuestionList)];
            }
            else {
                return [i_3, s];
            }
        }, indexed(state.Info))), state.OriginalInfo, state.EditMode, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.ContentLanguage, state.Info, state.Info, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.Info, state.OriginalInfo)) {
            return [new State(state.ContentLanguage, state.Info, state.OriginalInfo, state.EditMode, new Deferred$1(1)), Cmd_fromAsync(Cmd_updateFaqInfo(state.Info))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new State(msg.fields[0], state.Info, state.OriginalInfo, state.EditMode, state.SaveFormOperation), Cmd_none()];
    }
}

export function renderContentLanguageSwitcher(state, dispatch) {
    let children;
    const props_4 = ofArray([["className", "is-uppercase"], ["className", "is-toggle"], ["className", "pb-0"], ["className", "mb-1"], ["children", Interop_reactApi.Children.toArray([(children = toList(delay(() => map_1((lang) => {
        const props_1 = toList(delay(() => append_1((lang === state.ContentLanguage) ? singleton_2(["className", "is-active"]) : empty(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: "is-small-padding",
            onClick: (_arg1) => {
                dispatch(new Msg(2, lang));
            },
            children: LangModule_toString(lang),
        })])])))));
        return createElement("li", createObj(Helpers_combineClasses("", props_1)));
    }, List_distinct(cons(getPrimaryLanguage(), getSupportedLanguages()), {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })))), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("tabs", props_4)));
}

export function renderSectionName(isEditMode, ind, name, dispatch) {
    let props_18, props_9, elms, props_2, props, props_6, props_4, value_17, props_16, props_14;
    const elms_2 = singleton_1((props_18 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-5"], ["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.sectionName")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["disabled", !isEditMode], ["onChange", (ev) => {
        dispatch(new Msg(0, ind, ev.target.value));
    }], ["placeholder", t("common.sectionName")], (value_17 = name, ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_17)) : false) {
            e.value = value_17;
        }
    }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_6))))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9)))), (props_16 = ofArray([["className", "mt-5"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_14 = toList(delay(() => append_1(singleton_2(["className", "is-danger"]), delay(() => append_1(singleton_2(["className", "is-outlined"]), delay(() => append_1(singleton_2(["disabled", !isEditMode]), delay(() => {
        let elms_1, s;
        return append_1(singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-trash"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (s = t("common.sectionName"), createElement("span", {
            className: "",
            children: s,
        }))])]), delay(() => (isEditMode ? singleton_2(["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new Msg(4, ind));
        }]) : empty())));
    })))))))), createElement("button", createObj(Helpers_combineClasses("button", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_18)))));
    return createElement("div", {
        className: "block",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function row(isEditMode, indexSection, ind, question, dispatch, lang) {
    let props_17, props_9, elms, props_2, props, props_6, props_4, props_15, props_13, elms_2, props_21, props_19, props_25, props_23;
    const elms_3 = ofArray([(props_17 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_9 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.question")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append_1(singleton_2(["disabled", !isEditMode]), delay(() => append_1(singleton_2(["onChange", (ev) => {
        dispatch(new Msg(1, indexSection, ind, ev.target.value, new Content_FaqField(0)));
    }]), delay(() => append_1(singleton_2(["placeholder", t("common.question")]), delay(() => {
        let value_15;
        return singleton_2((value_15 = LocalizedStringModule_getTranslationOrEmpty(lang, LocalizedMap_getValueOrEmpty(new Content_FaqField(0), question)), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_15)) : false) {
                e.value = value_15;
            }
        }]));
    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_6))))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_9)))), (props_15 = ofArray([["className", "mt-5"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_13 = toList(delay(() => append_1(singleton_2(["className", "is-danger"]), delay(() => append_1(singleton_2(["className", "is-outlined"]), delay(() => append_1(singleton_2(["disabled", !isEditMode]), delay(() => {
        let elms_1;
        return append_1(singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-trash"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]), delay(() => (isEditMode ? singleton_2(["onClick", (x_1) => {
            let tupledArg;
            x_1.preventDefault();
            dispatch((tupledArg = [indexSection, ind], new Msg(6, tupledArg[0], tupledArg[1])));
        }]) : empty())));
    })))))))), createElement("button", createObj(Helpers_combineClasses("button", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_17)))), (elms_2 = ofArray([(props_21 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.answer")]]), createElement("label", createObj(Helpers_combineClasses("label", props_19))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_21)))), (props_25 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_23 = toList(delay(() => append_1(singleton_2(["disabled", !isEditMode]), delay(() => append_1(singleton_2(["placeholder", t("common.answer")]), delay(() => {
        let value_58;
        return append_1(singleton_2((value_58 = LocalizedStringModule_getTranslationOrEmpty(lang, LocalizedMap_getValueOrEmpty(new Content_FaqField(1), question)), ["ref", (e_1) => {
            if ((!(e_1 == null)) ? (!equals(e_1.value, value_58)) : false) {
                e_1.value = value_58;
            }
        }])), delay(() => singleton_2(["onChange", (ev_1) => {
            dispatch(new Msg(1, indexSection, ind, ev_1.target.value, new Content_FaqField(1)));
        }])));
    })))))), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_23))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_25))))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]);
    return createElement("div", {
        className: "block",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    });
}

export function Render(renderInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderInputProps.content), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(collect((matchValue) => {
            const section = matchValue[1];
            const ind = matchValue[0] | 0;
            return append_1(singleton_2(renderContentLanguageSwitcher(state_1, dispatch)), delay(() => {
                let props_7;
                return singleton_2((props_7 = ofArray([["className", "mb-6"], ["className", "py-5"], ["className", "px-5"], ["style", {
                    border: (((1 + "px ") + "solid") + " ") + "#D3D3D3",
                    borderRadius: 6,
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(renderSectionName(state_1.EditMode, ind, LocalizedStringModule_getTranslationOrEmpty(state_1.ContentLanguage, section.SectionName), dispatch)), delay(() => append_1(map_1((q) => row(state_1.EditMode, ind, q[0], q[1], dispatch, state_1.ContentLanguage), indexed(section.QuestionList)), delay(() => {
                    let props_5, props_3;
                    return singleton_2((props_5 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append_1(singleton_2(["disabled", !state_1.EditMode]), delay(() => {
                        let elms;
                        return append_1(singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                            className: join(" ", ["fa", "fa-plus"]),
                        })), createElement("span", {
                            className: "icon",
                            children: Interop_reactApi.Children.toArray(Array.from(elms)),
                        })), createElement("span", {
                            className: "",
                            children: "Question",
                        })])]), delay(() => (state_1.EditMode ? singleton_2(["onClick", (x) => {
                            x.preventDefault();
                            dispatch(new Msg(5, ind));
                        }]) : empty())));
                    })))), createElement("button", createObj(Helpers_combineClasses("button", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_5)))));
                }))))))))]]), createElement("div", createObj(Helpers_combineClasses("block", props_7)))));
            }));
        }, indexed(state_1.Info)), delay(() => {
            let props_14, props_12;
            return append_1(singleton_2((props_14 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_12 = toList(delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(singleton_2(["disabled", !state_1.EditMode]), delay(() => {
                let elms_1;
                return append_1(singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1(createElement("i", {
                    className: join(" ", ["fa", "fa-plus"]),
                })), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                })), createElement("span", {
                    className: "",
                    children: "Section",
                })])]), delay(() => (state_1.EditMode ? singleton_2(["onClick", (x_1) => {
                    x_1.preventDefault();
                    dispatch(new Msg(3));
                }]) : empty())));
            })))))), createElement("button", createObj(Helpers_combineClasses("button", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_14))))), delay(() => {
                let props_22;
                return singleton_2((props_22 = ofArray([["className", "pb-6"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_16, props_20;
                    return state_1.EditMode ? append_1(singleton_2((props_16 = ofArray([["className", "is-dark"], ["onClick", (_arg1) => {
                        dispatch(new Msg(7));
                    }], ["children", t("common.cancel")]]), createElement("button", createObj(Helpers_combineClasses("button", props_16))))), delay(() => {
                        let props_18;
                        return singleton_2((props_18 = toList(delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append_1(equals(state_1.Info, state_1.OriginalInfo) ? singleton_2(["disabled", true]) : empty(), delay(() => append_1(singleton_2(["onClick", (_arg2) => {
                            dispatch(new Msg(8, new AsyncOperationStatus$1(0)));
                        }]), delay(() => singleton_2(["children", t("common.save")]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_18)))));
                    })) : singleton_2((props_20 = ofArray([["className", "has-background-grey-lighter"], ["onClick", (_arg3) => {
                        dispatch(new Msg(7));
                    }], ["children", t("common.edit")]]), createElement("button", createObj(Helpers_combineClasses("button", props_20)))));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_22)))));
            }));
        })))))),
    });
}

