import { Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { Orders_CancelOrderDelivery_Request, Orders_SetCustomDelivery_ContactPerson, Orders_SetCustomDelivery_DeliveryAddress, Orders_SetCustomDelivery_Request, Orders_SetCustomDelivery_Delivery, Orders_PickUpOrder$reflection, Orders_SetCustomDelivery_ContactPerson$reflection, Orders_SetCustomDelivery_DeliveryAddress$reflection, User_User$reflection, SettingsFreight_FreightCostBasicSetting$reflection } from "../Shared/Shared.js";
import { lambda_type, unit_type, record_type, bool_type, list_type, option_type, string_type, class_type, union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { ordersApi, usersApi } from "../Communication.js";
import { CurrencyModule_fromString, CurrencyModule_toString, DeliveryCostZoneIdModule_toString, DeliveryCostZoneIdModule_fromString, PickPointIdModule_ArtMove_helsinki, PickPointIdModule_ArtMove_malmo, PickPointIdModule_ArtMove_gothenburg, PickPointIdModule_toString, PickPointIdModule_fromString, PhoneModule_ofString, PhoneModule_toString, EmailModule_ofString, EmailModule_toString, PickPointIdModule_ArtMove_stockholm, PhoneModule_empty, EmailModule_empty, CountryCodeModule_sweden, DeliveryCostZoneIdModule_dbSchenker, DeliveryCostZoneIdModule_artMove } from "../bidflow/src/infrastructure/Auction.js";
import { empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_ofSub, Cmd_batch, Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { CustomDelivery_validateIf, CustomDelivery_DeliveryForm, CustomDelivery_Form, CustomDelivery_PickPointForm } from "../Validation.js";
import { empty as empty_2, head, contains, append as append_1, filter, map, fold, singleton as singleton_2, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import * as react from "react";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Currency_format, Form_getFieldError } from "../Common.js";
import { Localized_formatDateTime, tWithArgs, Localized_translate, t } from "../Localization.js";
import { safeHash, createObj, equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { CountryModule_possibleCountries } from "../bidflow/src/infrastructure/Countries.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { loader } from "../Loader.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 as Deferred$1_1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { Route_AccountDetailsSubmissions_7639071A, toPath } from "../Router.js";

export class Model_Delivery extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ArtMove", "Effecta", "DbSchenker"];
    }
}

export function Model_Delivery$reflection() {
    return union_type("Client.Components.Delivery.Custom.CustomDeliveryComponents.Model.Delivery", [], Model_Delivery, () => [[], [["Item", SettingsFreight_FreightCostBasicSetting$reflection()]], []]);
}

export class Model_State extends Record {
    constructor(OrderId, User, Delivery, Address, ContactPerson, PickPointId, Cost, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.OrderId = OrderId;
        this.User = User;
        this.Delivery = Delivery;
        this.Address = Address;
        this.ContactPerson = ContactPerson;
        this.PickPointId = PickPointId;
        this.Cost = Cost;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function Model_State$reflection() {
    return record_type("Client.Components.Delivery.Custom.CustomDeliveryComponents.Model.State", [], Model_State, () => [["OrderId", class_type("System.Int64")], ["User", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [User_User$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", User_User$reflection()]], [["ErrorValue", string_type]]]))], ["Delivery", string_type], ["Address", Orders_SetCustomDelivery_DeliveryAddress$reflection()], ["ContactPerson", Orders_SetCustomDelivery_ContactPerson$reflection()], ["PickPointId", string_type], ["Cost", option_type(class_type("System.Decimal"))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Orders_PickUpOrder$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Orders_PickUpOrder$reflection()]], [["ErrorValue", string_type]]]))]]);
}

export class Model_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadUser", "OrderIdChanged", "DeliveryChanged", "EmailChanged", "NameChanged", "AddressChanged", "ZipCodeChanged", "CityChanged", "CountryChanged", "PhoneChanged", "ArtMovePickupPointChanged", "CostChanged", "SaveForm"];
    }
}

export function Model_Msg$reflection() {
    return union_type("Client.Components.Delivery.Custom.CustomDeliveryComponents.Model.Msg", [], Model_Msg, () => [[["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [User_User$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", User_User$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.Int64")]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", option_type(class_type("System.Decimal"))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Orders_PickUpOrder$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Orders_PickUpOrder$reflection()]], [["ErrorValue", string_type]]]))]]]);
}

export class Model_ExternalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PickUpOrderChanged", "ChangeIsFormShowingState"];
    }
}

export function Model_ExternalMsg$reflection() {
    return union_type("Client.Components.Delivery.Custom.CustomDeliveryComponents.Model.ExternalMsg", [], Model_ExternalMsg, () => [[["Item", Orders_PickUpOrder$reflection()]], []]);
}

export function Cmd_loadUser(userId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(usersApi().getUser(userId)), (_arg1) => singleton.Return(new Model_Msg(0, userId, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Model_Msg(0, userId, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_changeDelivery(state) {
    return singleton.Delay(() => {
        const delivery = (state.Delivery === DeliveryCostZoneIdModule_artMove) ? (new Orders_SetCustomDelivery_Delivery(0, state.PickPointId, state.ContactPerson)) : ((state.Delivery === DeliveryCostZoneIdModule_dbSchenker) ? (new Orders_SetCustomDelivery_Delivery(2, state.Address, state.ContactPerson)) : (new Orders_SetCustomDelivery_Delivery(1, state.Delivery, state.Address, state.ContactPerson)));
        return singleton.TryWith(singleton.Delay(() => {
            const matchValue = state.Cost;
            if (matchValue != null) {
                const request = new Orders_SetCustomDelivery_Request(state.OrderId, matchValue, delivery);
                return singleton.Bind(Remoting_handleNonAuth(ordersApi().setCustomDelivery(request)), (_arg1) => singleton.Return(new Model_Msg(12, new AsyncOperationStatus$1(1, _arg1))));
            }
            else {
                return singleton.Return(new Model_Msg(12, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "No cost"))));
            }
        }), (_arg2) => singleton.Return(new Model_Msg(12, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message)))));
    });
}

export function State_init(headDeliveryZone, userId, orderId) {
    return [new Model_State(orderId, new Deferred$1(0), headDeliveryZone, new Orders_SetCustomDelivery_DeliveryAddress("", "", "", CountryCodeModule_sweden), new Orders_SetCustomDelivery_ContactPerson(EmailModule_empty, "", PhoneModule_empty), PickPointIdModule_ArtMove_stockholm, void 0, empty(), false, new Deferred$1(0)), Cmd_OfFunc_result(new Model_Msg(0, userId, new AsyncOperationStatus$1(0)))];
}

export function State_toState(user) {
    switch (user.tag) {
        case 3: {
            throw (new Error("Manager user is not expected here"));
        }
        case 1: {
            const info = user.fields[0];
            return [new Orders_SetCustomDelivery_ContactPerson(info.ContactPerson.Email, `${info.ContactPerson.FirstName} ${info.ContactPerson.LastName}`, info.ContactPerson.Phone), new Orders_SetCustomDelivery_DeliveryAddress(info.Address.Address, info.Address.ZipCode, info.Address.City, info.Address.Country)];
        }
        case 0: {
            const info_1 = user.fields[0];
            return [new Orders_SetCustomDelivery_ContactPerson(info_1.Email, `${info_1.PersonalInfo.FirstName} ${info_1.PersonalInfo.LastName}`, info_1.PersonalInfo.Phone), new Orders_SetCustomDelivery_DeliveryAddress(info_1.Address.Address, info_1.Address.ZipCode, info_1.Address.City, info_1.Address.Country)];
        }
        default: {
            throw (new Error("Admin user is not expected here"));
        }
    }
}

export function State_toValidationType(deliveryType, contactPerson, address, cost) {
    if (deliveryType === DeliveryCostZoneIdModule_artMove) {
        return new CustomDelivery_Form(0, new CustomDelivery_PickPointForm(contactPerson, cost));
    }
    else {
        return new CustomDelivery_Form(1, new CustomDelivery_DeliveryForm(address, contactPerson, cost));
    }
}

export function State_withFormAndErrors(contactPerson, address, cost, state, _form, errors) {
    return new Model_State(state.OrderId, state.User, state.Delivery, address, contactPerson, state.PickPointId, cost, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function State_update(updateFreightSetting, msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6;
    const withValidatedForm = (deliveryType_1, contactPerson_1, address_1, cost_1, state_1) => {
        const tupledArg = CustomDelivery_validateIf(state.NeedToValidate, State_toValidationType(deliveryType_1, contactPerson_1, address_1, cost_1));
        return State_withFormAndErrors(contactPerson_1, address_1, cost_1, state_1, tupledArg[0], tupledArg[1]);
    };
    if (msg.tag === 1) {
        return [new Model_State(msg.fields[0], state.User, state.Delivery, state.Address, state.ContactPerson, state.PickPointId, state.Cost, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new Model_State(state.OrderId, state.User, msg.fields[0], state.Address, state.ContactPerson, state.PickPointId, state.Cost, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm(state.Delivery, (inputRecord = state.ContactPerson, new Orders_SetCustomDelivery_ContactPerson(msg.fields[0], inputRecord.Name, inputRecord.Phone)), state.Address, state.Cost, state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [withValidatedForm(state.Delivery, (inputRecord_1 = state.ContactPerson, new Orders_SetCustomDelivery_ContactPerson(inputRecord_1.Email, msg.fields[0], inputRecord_1.Phone)), state.Address, state.Cost, state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withValidatedForm(state.Delivery, (inputRecord_2 = state.ContactPerson, new Orders_SetCustomDelivery_ContactPerson(inputRecord_2.Email, inputRecord_2.Name, msg.fields[0])), state.Address, state.Cost, state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [withValidatedForm(state.Delivery, state.ContactPerson, (inputRecord_3 = state.Address, new Orders_SetCustomDelivery_DeliveryAddress(msg.fields[0], inputRecord_3.ZipCode, inputRecord_3.City, inputRecord_3.Country)), state.Cost, state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [withValidatedForm(state.Delivery, state.ContactPerson, (inputRecord_4 = state.Address, new Orders_SetCustomDelivery_DeliveryAddress(inputRecord_4.Address, msg.fields[0], inputRecord_4.City, inputRecord_4.Country)), state.Cost, state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [withValidatedForm(state.Delivery, state.ContactPerson, (inputRecord_5 = state.Address, new Orders_SetCustomDelivery_DeliveryAddress(inputRecord_5.Address, inputRecord_5.ZipCode, msg.fields[0], inputRecord_5.Country)), state.Cost, state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [withValidatedForm(state.Delivery, state.ContactPerson, (inputRecord_6 = state.Address, new Orders_SetCustomDelivery_DeliveryAddress(inputRecord_6.Address, inputRecord_6.ZipCode, inputRecord_6.City, msg.fields[0])), state.Cost, state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [new Model_State(state.OrderId, state.User, state.Delivery, state.Address, state.ContactPerson, msg.fields[0], state.Cost, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [withValidatedForm(state.Delivery, state.ContactPerson, state.Address, msg.fields[0], state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].tag === 1) {
            return [new Model_State(state.OrderId, state.User, state.Delivery, state.Address, state.ContactPerson, state.PickPointId, state.Cost, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), (msg.fields[0].fields[0].tag === 1) ? Cmd_none() : Cmd_batch(ofArray([Cmd_ofSub((_arg1) => {
                updateFreightSetting(new Model_ExternalMsg(0, msg.fields[0].fields[0].fields[0]));
            }), Cmd_ofSub((_arg2) => {
                updateFreightSetting(new Model_ExternalMsg(1));
            })]))];
        }
        else {
            const errors_1 = CustomDelivery_validateIf(true, State_toValidationType(state.Delivery, state.ContactPerson, state.Address, state.Cost))[1];
            if (errors_1.Equals(empty())) {
                return [new Model_State(state.OrderId, state.User, state.Delivery, state.Address, state.ContactPerson, state.PickPointId, state.Cost, empty(), true, new Deferred$1(1)), Cmd_fromAsync(Cmd_changeDelivery(state))];
            }
            else {
                return [new Model_State(state.OrderId, state.User, state.Delivery, state.Address, state.ContactPerson, state.PickPointId, state.Cost, errors_1, true, new Deferred$1(0)), Cmd_none()];
            }
        }
    }
    else if (msg.fields[1].tag === 1) {
        if (msg.fields[1].fields[0].tag === 1) {
            return [new Model_State(state.OrderId, new Deferred$1(2, new FSharpResult$2(1, msg.fields[1].fields[0].fields[0])), state.Delivery, state.Address, state.ContactPerson, state.PickPointId, state.Cost, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
        }
        else {
            const user_1 = msg.fields[1].fields[0].fields[0];
            const patternInput = State_toState(user_1);
            return [new Model_State(state.OrderId, new Deferred$1(2, new FSharpResult$2(0, user_1)), state.Delivery, patternInput[1], patternInput[0], state.PickPointId, state.Cost, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
        }
    }
    else {
        return [new Model_State(state.OrderId, new Deferred$1(1), state.Delivery, state.Address, state.ContactPerson, state.PickPointId, state.Cost, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_fromAsync(Cmd_loadUser(msg.fields[0]))];
    }
}

function View_contactPerson(contactPerson, formErrors, dispatch) {
    let elms_1, elms_3, elms_5;
    return react.createElement(react.Fragment, {}, (elms_1 = toList(delay(() => {
        let s;
        const errors = Form_getFieldError(formErrors, "Email");
        return append(singleton_1((s = t("common.email"), createElement("label", {
            className: "label",
            children: s,
        }))), delay(() => {
            let elms, props_1, value_7;
            return append(singleton_1((elms = singleton_2((props_1 = ofArray([["required", true], (value_7 = EmailModule_toString(contactPerson.Email), ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_7)) : false) {
                    e.value = value_7;
                }
            }]), ["onChange", (ev) => {
                dispatch(new Model_Msg(3, EmailModule_ofString(ev.target.value)));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_1)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => {
                let props_4;
                return singleton_1((props_4 = ofArray([["className", "is-danger"], ["children", fold((s_2, x) => (`${s_2} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_4)))));
            }));
        }));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = toList(delay(() => {
        let s_3;
        const errors_1 = Form_getFieldError(formErrors, "Name");
        return append(singleton_1((s_3 = t("orders.buyer"), createElement("label", {
            className: "label",
            children: s_3,
        }))), delay(() => {
            let elms_2, props_8, value_30;
            return append(singleton_1((elms_2 = singleton_2((props_8 = ofArray([["required", true], (value_30 = contactPerson.Name, ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals(e_1.value, value_30)) : false) {
                    e_1.value = value_30;
                }
            }]), ["onChange", (ev_1) => {
                dispatch(new Model_Msg(4, ev_1.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_8)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))), delay(() => {
                let props_11;
                return singleton_1((props_11 = ofArray([["className", "is-danger"], ["children", fold((s_4, x_1) => (`${s_4} ${x_1}`), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_11)))));
            }));
        }));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_5 = toList(delay(() => {
        let s_5;
        const errors_2 = Form_getFieldError(formErrors, "Phone");
        return append(singleton_1((s_5 = t("common.phoneNumber"), createElement("label", {
            className: "label",
            children: s_5,
        }))), delay(() => {
            let elms_4, props_15, value_53;
            return append(singleton_1((elms_4 = singleton_2((props_15 = ofArray([["required", true], (value_53 = PhoneModule_toString(contactPerson.Phone), ["ref", (e_2) => {
                if ((!(e_2 == null)) ? (!equals(e_2.value, value_53)) : false) {
                    e_2.value = value_53;
                }
            }]), ["onChange", (ev_2) => {
                dispatch(new Model_Msg(9, PhoneModule_ofString(ev_2.target.value)));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_15)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))), delay(() => {
                let props_18;
                return singleton_1((props_18 = ofArray([["className", "is-danger"], ["children", fold((s_7, x_2) => (`${s_7} ${x_2}`), "", errors_2)]]), createElement("p", createObj(Helpers_combineClasses("help", props_18)))));
            }));
        }));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })));
}

function View_address(address, formErrors, dispatch) {
    let elms_1, elms_3, elms_5, elms_7;
    return react.createElement(react.Fragment, {}, (elms_1 = toList(delay(() => {
        let s;
        const errors = Form_getFieldError(formErrors, "Address");
        return append(singleton_1((s = t("common.address"), createElement("label", {
            className: "label",
            children: s,
        }))), delay(() => {
            let elms, props_1, value_7;
            return append(singleton_1((elms = singleton_2((props_1 = ofArray([["required", true], (value_7 = address.Address, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_7)) : false) {
                    e.value = value_7;
                }
            }]), ["onChange", (ev) => {
                dispatch(new Model_Msg(5, ev.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_1)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), delay(() => {
                let props_4;
                return singleton_1((props_4 = ofArray([["className", "is-danger"], ["children", fold((s_1, x) => (`${s_1} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_4)))));
            }));
        }));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = toList(delay(() => {
        let s_2;
        const errors_1 = Form_getFieldError(formErrors, "ZipCode");
        return append(singleton_1((s_2 = t("users.zipCode"), createElement("label", {
            className: "label",
            children: s_2,
        }))), delay(() => {
            let elms_2, props_8, value_30;
            return append(singleton_1((elms_2 = singleton_2((props_8 = ofArray([["required", true], (value_30 = address.ZipCode, ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals(e_1.value, value_30)) : false) {
                    e_1.value = value_30;
                }
            }]), ["onChange", (ev_1) => {
                dispatch(new Model_Msg(6, ev_1.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_8)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))), delay(() => {
                let props_11;
                return singleton_1((props_11 = ofArray([["className", "is-danger"], ["children", fold((s_3, x_1) => (`${s_3} ${x_1}`), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_11)))));
            }));
        }));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_5 = toList(delay(() => {
        let s_4;
        const errors_2 = Form_getFieldError(formErrors, "City");
        return append(singleton_1((s_4 = t("users.city"), createElement("label", {
            className: "label",
            children: s_4,
        }))), delay(() => {
            let elms_4, props_15, value_53;
            return append(singleton_1((elms_4 = singleton_2((props_15 = ofArray([["required", true], (value_53 = address.City, ["ref", (e_2) => {
                if ((!(e_2 == null)) ? (!equals(e_2.value, value_53)) : false) {
                    e_2.value = value_53;
                }
            }]), ["onChange", (ev_2) => {
                dispatch(new Model_Msg(7, ev_2.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_15)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))), delay(() => {
                let props_18;
                return singleton_1((props_18 = ofArray([["className", "is-danger"], ["children", fold((s_5, x_2) => (`${s_5} ${x_2}`), "", errors_2)]]), createElement("p", createObj(Helpers_combineClasses("help", props_18)))));
            }));
        }));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })), (elms_7 = toList(delay(() => {
        let s_6;
        const errors_3 = Form_getFieldError(formErrors, "Country");
        return append(singleton_1((s_6 = t("users.country"), createElement("label", {
            className: "label",
            children: s_6,
        }))), delay(() => {
            let elms_6;
            return append(singleton_1((elms_6 = toList(delay(() => {
                let patternInput;
                return singleton_1((patternInput = Helpers_extractClasses(ofArray([["onChange", (ev_3) => {
                    dispatch(new Model_Msg(8, ev_3.target.value));
                }], ["value", address.Country], ["children", Interop_reactApi.Children.toArray(Array.from(map((x_3) => createElement("option", {
                    value: x_3.Code,
                    children: x_3.NameSwedish,
                }), CountryModule_possibleCountries)))]])), createElement("div", {
                    className: join(" ", cons("select", patternInput[0])),
                    children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
                })));
            })), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            }))), delay(() => {
                let props_28;
                return singleton_1((props_28 = ofArray([["className", "is-danger"], ["children", fold((s_7, x_4) => (`${s_7} ${x_4}`), "", errors_3)]]), createElement("p", createObj(Helpers_combineClasses("help", props_28)))));
            }));
        }));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    })));
}

function View_artMove(point, dispatch) {
    let elms_1, s, elms;
    return react.createElement(react.Fragment, {}, (elms_1 = ofArray([(s = t("orders.artmovePickUpPoint"), createElement("label", {
        className: "label",
        children: s,
    })), (elms = toList(delay(() => {
        let patternInput;
        return singleton_1((patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
            dispatch(new Model_Msg(10, PickPointIdModule_fromString(ev.target.value)));
        }], ["value", PickPointIdModule_toString(point)], ["children", Interop_reactApi.Children.toArray(Array.from(map((x) => createElement("option", {
            value: PickPointIdModule_toString(x),
            children: PickPointIdModule_toString(x),
        }), ofArray([PickPointIdModule_ArtMove_stockholm, PickPointIdModule_ArtMove_gothenburg, PickPointIdModule_ArtMove_malmo, PickPointIdModule_ArtMove_helsinki]))))]])), createElement("div", {
            className: join(" ", cons("select", patternInput[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
        })));
    })), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })));
}

export function View_Component(view_ComponentInputProps) {
    const showForm = view_ComponentInputProps.showForm;
    const callbackResponse = view_ComponentInputProps.callbackResponse;
    const orderId = view_ComponentInputProps.orderId;
    const userId = view_ComponentInputProps.userId;
    let deliveryZones_1;
    const activeZones = filter((x) => (!x.IsArchived), view_ComponentInputProps.deliveryZones);
    const specialDeliveryZones = filter((f) => {
        if (f.DeliveryCostZoneId === DeliveryCostZoneIdModule_artMove) {
            return true;
        }
        else {
            return f.DeliveryCostZoneId === DeliveryCostZoneIdModule_dbSchenker;
        }
    }, activeZones);
    deliveryZones_1 = append_1(specialDeliveryZones, filter((f_1) => (!contains(f_1, specialDeliveryZones, {
        Equals: (x_1, y) => equals(x_1, y),
        GetHashCode: (x_1) => safeHash(x_1),
    })), activeZones));
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(head(deliveryZones_1).DeliveryCostZoneId, userId, orderId), (msg, state) => State_update(callbackResponse, msg, state), [userId, orderId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const inProgress = equals(state_1.SaveFormOperation, new Deferred$1(1));
    let renderContent;
    const props_38 = toList(delay(() => append(showForm ? singleton_1(["className", "is-active"]) : empty_1(), delay(() => {
        let elms_7, elms, s, props_28, elms_5, props_22, elms_2, s_1, elms_1, patternInput_1, elms_4, props_25, props_35, elms_6, props_30, props_32;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", empty_2()))), (elms_7 = ofArray([(elms = ofArray([(s = t("orders.setCustomFreight"), createElement("p", {
            className: "modal-card-title",
            children: s,
        })), createElement("button", createObj(Helpers_combineClasses("delete", singleton_2(["onClick", (_arg1) => {
            callbackResponse(new Model_ExternalMsg(1));
        }]))))]), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_28 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_5 = ofArray([(props_22 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
            disabled: inProgress,
            children: Interop_reactApi.Children.toArray([(elms_2 = ofArray([(s_1 = t("orders.deliveryType"), createElement("label", {
                className: "label",
                children: s_1,
            })), (elms_1 = singleton_2((patternInput_1 = Helpers_extractClasses(toList(delay(() => append(singleton_1(["required", true]), delay(() => {
                let value_28;
                return append(singleton_1((value_28 = state_1.Delivery, ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_28)) : false) {
                        e.value = value_28;
                    }
                }])), delay(() => append(singleton_1(["onChange", (ev) => {
                    dispatch(new Model_Msg(2, DeliveryCostZoneIdModule_fromString(ev.target.value)));
                }]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((x_2) => createElement("option", {
                    value: DeliveryCostZoneIdModule_toString(x_2.DeliveryCostZoneId),
                    children: Localized_translate(x_2.Name),
                }), deliveryZones_1)))])))));
            }))))), createElement("div", {
                className: join(" ", cons("select", patternInput_1[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
            }))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            })), (elms_4 = toList(delay(() => {
                let s_2;
                const errors = Form_getFieldError(state_1.FormErrors, "Cost");
                return append(singleton_1((s_2 = t("reports.price"), createElement("label", {
                    className: "label",
                    children: s_2,
                }))), delay(() => {
                    let elms_3, props_15, value_51;
                    return append(singleton_1((elms_3 = singleton_2((props_15 = ofArray([["required", true], (value_51 = defaultArg(map_1((x_5) => CurrencyModule_toString(x_5), state_1.Cost), ""), ["ref", (e_1) => {
                        if ((!(e_1 == null)) ? (!equals(e_1.value, value_51)) : false) {
                            e_1.value = value_51;
                        }
                    }]), ["onChange", (ev_1) => {
                        const x_6 = ev_1.target.value;
                        if (x_6 === "") {
                            dispatch(new Model_Msg(11, void 0));
                        }
                        else {
                            dispatch(new Model_Msg(11, CurrencyModule_fromString(x_6)));
                        }
                    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_15)))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                    }))), delay(() => {
                        let props_18;
                        return singleton_1((props_18 = ofArray([["className", "is-danger"], ["children", fold((s_3, x_7) => (`${s_3} ${x_7}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_18)))));
                    }));
                }));
            })), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22)))), (props_25 = ofArray([["className", "is-6"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
            disabled: inProgress,
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((state_1.Delivery === DeliveryCostZoneIdModule_artMove) ? append(singleton_1(View_artMove(state_1.PickPointId, dispatch)), delay(() => singleton_1(View_contactPerson(state_1.ContactPerson, state_1.FormErrors, dispatch)))) : append(singleton_1(View_contactPerson(state_1.ContactPerson, state_1.FormErrors, dispatch)), delay(() => singleton_1(View_address(state_1.Address, state_1.FormErrors, dispatch))))))))),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_25))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        }))])]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_28)))), (props_35 = ofArray([["style", {
            justifyContent: "flex-end",
        }], ["children", Interop_reactApi.Children.toArray([(elms_6 = ofArray([(props_30 = ofArray([["className", "is-dark"], ["disabled", inProgress], ["children", t("common.cancel")], ["onClick", (_arg2) => {
            callbackResponse(new Model_ExternalMsg(1));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_30)))), (props_32 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(inProgress ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (_arg3) => {
            dispatch(new Model_Msg(12, new AsyncOperationStatus$1(0)));
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_32))))]), createElement("div", {
            className: "buttons",
            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
        }))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_35))))]), createElement("div", {
            className: "modal-card",
            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
        }))])]);
    }))));
    renderContent = createElement("div", createObj(Helpers_combineClasses("modal", props_38)));
    const matchValue = state_1.User;
    switch (matchValue.tag) {
        case 1: {
            return loader("big");
        }
        case 2: {
            return renderContent;
        }
        default: {
            return null;
        }
    }
}

export function View_confirmation(title, question, proceedText, cancelText, onProceed, onCancel) {
    let elms_1, elms, props_13, props_8, props_11;
    const props_16 = ofArray([["style", {
        overflow: "visible",
    }], ["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_2(["onClick", (x) => {
        x.preventDefault();
        onCancel();
    }])))), (elms_1 = ofArray([(elms = ofArray([createElement("p", {
        className: "modal-card-title",
        children: title,
    }), createElement("button", createObj(Helpers_combineClasses("delete", singleton_2(["onClick", (x_1) => {
        x_1.preventDefault();
        onCancel();
    }]))))]), createElement("header", {
        className: "modal-card-head",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("section", createObj(Helpers_combineClasses("modal-card-body", singleton_2(["children", question])))), (props_13 = ofArray([["style", {
        justifyContent: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-dark"], ["className", "is-outlined"], ["children", cancelText], ["className", join(" ", ["is-align-items-baseline", "is-justify-content-space-between"])], ["onClick", (x_2) => {
        x_2.preventDefault();
        onCancel();
    }]]), createElement("button", createObj(Helpers_combineClasses("button", props_8)))), (props_11 = ofArray([["onClick", (x_3) => {
        x_3.preventDefault();
        onProceed();
    }], ["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        className: "",
        children: proceedText,
    })])]]), createElement("a", createObj(Helpers_combineClasses("button", props_11))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_13))))]), createElement("div", {
        className: "modal-card",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_16)));
}

export class View_DeliveryInfo extends Record {
    constructor(Delivery, DeliveryName, DeliveryTypeHandling, Timestamp, User, Cost) {
        super();
        this.Delivery = Delivery;
        this.DeliveryName = DeliveryName;
        this.DeliveryTypeHandling = DeliveryTypeHandling;
        this.Timestamp = Timestamp;
        this.User = User;
        this.Cost = Cost;
    }
}

export function View_DeliveryInfo$reflection() {
    return record_type("Client.Components.Delivery.Custom.CustomDeliveryComponents.View.DeliveryInfo", [], View_DeliveryInfo, () => [["Delivery", string_type], ["DeliveryName", string_type], ["DeliveryTypeHandling", string_type], ["Timestamp", class_type("System.DateTimeOffset")], ["User", class_type("System.Int64")], ["Cost", class_type("System.Decimal")]]);
}

export class View_ViewType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["WithCancel", "WithoutCancel"];
    }
}

export function View_ViewType$reflection() {
    return union_type("Client.Components.Delivery.Custom.CustomDeliveryComponents.View.ViewType", [], View_ViewType, () => [[["f", lambda_type(Orders_PickUpOrder$reflection(), unit_type)]], []]);
}

export function View_Delivery(view_DeliveryInputProps) {
    const viewType = view_DeliveryInputProps.viewType;
    const delivery = view_DeliveryInputProps.delivery;
    const orderId = view_DeliveryInputProps.orderId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1_1(0));
    const data = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowConfirmation = patternInput_1[1];
    const cancelRemoveLotFromOrder = singleton.Delay(() => {
        const request = new Orders_CancelOrderDelivery_Request(orderId);
        return singleton.Bind(Remoting_handleNonAuth(ordersApi().cancelDelivery(request)), (_arg1) => {
            if (_arg1.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, _arg1.fields[0]));
            }
            else {
                const response = _arg1.fields[0];
                return singleton.Combine((viewType.tag === 1) ? ((singleton.Zero())) : ((viewType.fields[0](response), singleton.Zero())), singleton.Delay(() => singleton.Return(new FSharpResult$2(0, response))));
            }
        });
    });
    const cancelDelivery = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => cancelRemoveLotFromOrder, patternInput[1]);
    const renderError = (error_1) => {
        let props;
        const props_2 = ofArray([["className", "px-3"], ["className", "mx-0"], ["className", "mb-0"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-text-right"], ["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([error_1])]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("columns", props_2)));
    };
    const patternInput_2 = (data.tag === 1) ? [true, null] : ((data.tag === 3) ? [false, renderError(tWithArgs("errors.fromServer.failedWithArg", {
        error: data.fields[0].message,
    }))] : ((data.tag === 2) ? ((data.fields[0].tag === 0) ? [false, null] : [false, renderError(tWithArgs("errors.fromServer.failedWithArg", {
        error: data.fields[0].fields[0],
    }))]) : [false, null]));
    return react.createElement(react.Fragment, {}, ...toList(delay(() => append(patternInput_1[0] ? singleton_1(View_confirmation(t("orders.removeFreight"), t("orders.removeFreightConfirmation"), t("orders.remove"), t("common.cancel"), () => {
        setShowConfirmation(false);
        cancelDelivery();
    }, () => {
        setShowConfirmation(false);
    })) : empty_1(), delay(() => {
        let props_44;
        return append(singleton_1((props_44 = ofArray([["className", "px-3"], ["className", "mx-0"], ["className", "is-vcentered"], ["className", "has-background-grey-lighter"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_8, props_4;
            return append(singleton_1((props_8 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.action")]]), createElement("p", createObj(Helpers_combineClasses("", props_4)))), createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["children", delivery.DeliveryTypeHandling]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))), delay(() => {
                let props_14, props_10, props_12;
                return append(singleton_1((props_14 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("settings.zone")]]), createElement("p", createObj(Helpers_combineClasses("", props_10)))), (props_12 = ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["children", `${delivery.DeliveryName}`]]), createElement("p", createObj(Helpers_combineClasses("", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))), delay(() => {
                    let props_20, props_16, props_18;
                    return append(singleton_1((props_20 = ofArray([["className", "has-text-right"], ["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.actionDate")]]), createElement("p", createObj(Helpers_combineClasses("", props_16)))), (props_18 = ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["className", "has-text-grey-light"], ["children", Localized_formatDateTime("dd-MM-yyyy", toLocalTime(delivery.Timestamp))]]), createElement("p", createObj(Helpers_combineClasses("", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))), delay(() => {
                        let props_26;
                        return append(singleton_1((props_26 = toList(delay(() => append((viewType.tag === 1) ? singleton_1(["className", "is-3"]) : singleton_1(["className", "is-2"]), delay(() => {
                            let props_22, props_24;
                            return singleton_1(["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.price")]]), createElement("p", createObj(Helpers_combineClasses("", props_22)))), (props_24 = ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["children", Currency_format(delivery.Cost)]]), createElement("p", createObj(Helpers_combineClasses("", props_24))))])]);
                        })))), createElement("div", createObj(Helpers_combineClasses("column", props_26))))), delay(() => {
                            let props_33, props_28, props_31;
                            return append(singleton_1((props_33 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_28 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.createdBy")]]), createElement("p", createObj(Helpers_combineClasses("", props_28)))), (props_31 = ofArray([["className", "pl-0"], ["className", "is-size-5"], ["className", "is-text"], ["className", "has-text-weight-bold"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
                                className: join(" ", ["link", "has-text-grey-light"]),
                                children: `${delivery.User}`,
                                onClick: (_arg1_1) => {
                                    window.open(toPath(Route_AccountDetailsSubmissions_7639071A(delivery.User)));
                                },
                            })])]]), createElement("p", createObj(Helpers_combineClasses("", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_33))))), delay(() => {
                                let props_42, props_40, props_38;
                                return (viewType.tag === 1) ? singleton_1(null) : singleton_1((props_42 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_38 = toList(delay(() => append(singleton_1(["className", "has-background-white-bis"]), delay(() => append(patternInput_2[0] ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["onClick", (x_1) => {
                                    x_1.preventDefault();
                                    setShowConfirmation(true);
                                }]), delay(() => {
                                    let s, elms;
                                    return singleton_1(["children", Interop_reactApi.Children.toArray([(s = t("orders.remove"), createElement("span", {
                                        className: "",
                                        children: s,
                                    })), (elms = singleton_2(createElement("i", {
                                        className: join(" ", ["fa", "fa-times"]),
                                    })), createElement("span", {
                                        className: "icon",
                                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                                    }))])]);
                                })))))))), createElement("button", createObj(Helpers_combineClasses("button", props_38))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_40))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_42)))));
                            }));
                        }));
                    }));
                }));
            }));
        }))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_44))))), delay(() => singleton_1(patternInput_2[1])));
    })))));
}

