import { isCurrentUserAdmin } from "./LocalStorage.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "./Localization.js";
import { createElement } from "react";
import { ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { buttonIcon } from "./Components/IconButton.js";
import { Route, toPath } from "./Router.js";

export const render = isCurrentUserAdmin() ? (() => {
    let props_1, s, props_5, props_3, props_9, props_7;
    const elms = ofArray([(props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("settings.allSettings"), createElement("h1", {
        className: "title",
        children: s,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1)))), (props_5 = ofArray([["className", "pb-2"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([buttonIcon(t("settings.allSettings"), "fa", "fa-tools", toPath(new Route(28)), "homeButton__primary"), buttonIcon(t("reports.payouts"), "fa", "fa-money-check-alt", toPath(new Route(29)), "homeButton__primary"), buttonIcon(t("settings.freightSettings"), "fa", "fa-truck", toPath(new Route(30)), "homeButton__primary"), buttonIcon(t("common.users"), "fa", "fa-user-cog", toPath(new Route(33)), "homeButton__primary")])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_3))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_5)))), (props_9 = ofArray([["className", "pb-2"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([buttonIcon(t("common.submission"), "fa", "fa-inbox", toPath(new Route(32)), "homeButton__primary"), buttonIcon(t("settings.lotSettings"), "fa", "fa-box-open", toPath(new Route(34)), "homeButton__primary"), buttonIcon(t("common.categories"), "fa", "fa-sort-amount-up-alt", toPath(new Route(16)), "homeButton__primary"), buttonIcon(t("common.auctions"), "fas", "fa-gavel", toPath(new Route(35)), "homeButton__primary"), buttonIcon(t("settings.payments"), "fas", "fa-credit-card", toPath(new Route(36)), "homeButton__primary"), buttonIcon(t("settings.emails.title"), "fas", "fa-envelope", toPath(new Route(38)), "homeButton__primary")])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_7))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_9))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
})() : null;

