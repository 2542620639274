import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { PayOuts_PayoutStatus, PayOuts_PayoutFilters, PayOuts_PayoutFilters$reflection, PayOuts_Payout$reflection, PayOuts_PayoutStatus$reflection } from "./Shared/Shared.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingQuery$1$reflection, Common_PagingResult$1$reflection } from "./Shared/Common.js";
import { Deferred$1, Deferred$1$reflection } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { union_type, class_type, record_type, int32_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { payOutApi } from "./Communication.js";
import { t, getLocalizationContext } from "./Localization.js";
import { Cmd_none, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map as map_1, empty, append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { empty as empty_1, tryHead, map, singleton as singleton_2, cons, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { navigate, Route, PayOutArg, toPath } from "./Router.js";
import { PayOutIdModule_toString, AuctionIdModule_first } from "./bidflow/src/infrastructure/Auction.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { toLocalTime } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { sv } from "date-fns/locale";
import { format as format_1 } from "date-fns";
import { defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Currency_format } from "./Common.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "./Loader.js";
import { renderPagination } from "./Components/Pagination.js";

export class State extends Record {
    constructor(PayoutStatus, PayOuts, Auction, CurrentPage, Query, SearchString) {
        super();
        this.PayoutStatus = PayoutStatus;
        this.PayOuts = PayOuts;
        this.Auction = Auction;
        this.CurrentPage = (CurrentPage | 0);
        this.Query = Query;
        this.SearchString = SearchString;
    }
}

export function State$reflection() {
    return record_type("Client.PayOuts.State", [], State, () => [["PayoutStatus", PayOuts_PayoutStatus$reflection()], ["PayOuts", Deferred$1$reflection(Common_PagingResult$1$reflection(PayOuts_Payout$reflection()))], ["Auction", string_type], ["CurrentPage", int32_type], ["Query", Common_PagingQuery$1$reflection(PayOuts_PayoutFilters$reflection())], ["SearchString", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadPayouts", "ChangePayoutStatus", "ChangeAuction", "PageChanged", "OnColumnClicked", "SearchStringChange", "KeyboardEvent", "SearchEnter"];
    }
}

export function Msg$reflection() {
    return union_type("Client.PayOuts.Msg", [], Msg, () => [[["Item1", Common_PagingQuery$1$reflection(PayOuts_PayoutFilters$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(PayOuts_Payout$reflection()), class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(PayOuts_Payout$reflection())]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item", PayOuts_PayoutStatus$reflection()]], [["Item", string_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", Common_PagingQuery$1$reflection(PayOuts_PayoutFilters$reflection())]]]);
}

export function loadPayouts(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const query_1 = new Common_PagingQuery$1(new PayOuts_PayoutFilters(query.Payload.PayoutStatus, query.Payload.FreeTextSearch.trim()), query.Page, query.PageSize, query.SortBy, query.LocalizationContext);
        return singleton.Bind(Remoting_handleNonAuth(payOutApi().getPayOutsInfo(query_1)), (_arg1) => singleton.Return(new Msg(0, query_1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))));
    }), (_arg2) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export function init(page, status) {
    const query = new Common_PagingQuery$1(new PayOuts_PayoutFilters(status, ""), page, 20, void 0, getLocalizationContext());
    return [new State(status, new Deferred$1(0), "", page, query, ""), Cmd_OfFunc_result(new Msg(0, query, new AsyncOperationStatus$1(0)))];
}

export function update(msg, state) {
    let s;
    if (msg.tag === 1) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Common_PagingQuery$1(new PayOuts_PayoutFilters(msg.fields[0], state.Query.Payload.FreeTextSearch), 1, inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(msg.fields[0], state.PayOuts, state.Auction, 1, newQuery, state.SearchString), Cmd_OfFunc_result(new Msg(0, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 2) {
        return [new State(state.PayoutStatus, state.PayOuts, msg.fields[0], state.CurrentPage, state.Query, state.SearchString), Cmd_none()];
    }
    else if (msg.tag === 3) {
        let newQuery_1;
        const inputRecord_2 = state.Query;
        newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, msg.fields[0], inputRecord_2.PageSize, inputRecord_2.SortBy, inputRecord_2.LocalizationContext));
        return [new State(state.PayoutStatus, state.PayOuts, state.Auction, msg.fields[0], newQuery_1, state.SearchString), Cmd_OfFunc_result(new Msg(0, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 4) {
        let newQuery_2;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_3 = state.Query;
                newQuery_2 = (new Common_PagingQuery$1(inputRecord_3.Payload, inputRecord_3.Page, inputRecord_3.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_3.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_4 = state.Query;
                newQuery_2 = (new Common_PagingQuery$1(inputRecord_4.Payload, inputRecord_4.Page, inputRecord_4.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_4.LocalizationContext));
                break;
            }
        }
        return [new State(state.PayoutStatus, state.PayOuts, state.Auction, state.CurrentPage, newQuery_2, state.SearchString), Cmd_OfFunc_result(new Msg(0, newQuery_2, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0] === "") {
            return [new State(state.PayoutStatus, state.PayOuts, state.Auction, state.CurrentPage, state.Query, msg.fields[0]), Cmd_OfFunc_result(new Msg(6, "Enter"))];
        }
        else {
            return [new State(state.PayoutStatus, state.PayOuts, state.Auction, state.CurrentPage, state.Query, msg.fields[0]), Cmd_none()];
        }
    }
    else if (msg.tag === 7) {
        return [new State(state.PayoutStatus, state.PayOuts, state.Auction, 1, state.Query, state.SearchString), Cmd_OfFunc_result(new Msg(0, msg.fields[0], new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 6) {
        if (msg.fields[0] === "Enter") {
            let newQuery_3;
            const query_1 = state.Query;
            newQuery_3 = (new Common_PagingQuery$1(new PayOuts_PayoutFilters(state.Query.Payload.PayoutStatus, state.SearchString.trim().toLowerCase()), 1, query_1.PageSize, query_1.SortBy, query_1.LocalizationContext));
            return [new State(state.PayoutStatus, state.PayOuts, state.Auction, state.CurrentPage, newQuery_3, state.SearchString), Cmd_OfFunc_result(new Msg(7, newQuery_3))];
        }
        else if (msg.fields[0] === "Escape") {
            let newQuery_4;
            const query_2 = state.Query;
            newQuery_4 = (new Common_PagingQuery$1(new PayOuts_PayoutFilters(state.Query.Payload.PayoutStatus, state.SearchString.trim().toLowerCase()), 1, query_2.PageSize, query_2.SortBy, query_2.LocalizationContext));
            return [new State(state.PayoutStatus, state.PayOuts, state.Auction, state.CurrentPage, newQuery_4, state.SearchString), Cmd_OfFunc_result(new Msg(7, newQuery_4))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[1].tag === 1) {
        if (msg.fields[1].fields[0].tag === 1) {
            return [new State(state.PayoutStatus, new Deferred$1(3, msg.fields[1].fields[0].fields[0]), state.Auction, state.CurrentPage, state.Query, state.SearchString), Cmd_none()];
        }
        else {
            return [new State(state.PayoutStatus, new Deferred$1(2, msg.fields[1].fields[0].fields[0]), state.Auction, state.CurrentPage, state.Query, state.SearchString), Cmd_none()];
        }
    }
    else {
        return [new State(state.PayoutStatus, new Deferred$1(1), state.Auction, state.CurrentPage, state.Query, state.SearchString), Cmd_fromAsync(loadPayouts(msg.fields[0]))];
    }
}

export function tableHeader(state, dispatch) {
    let props_18, props_8, props_10, props_12, props_14, props_16;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(4, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_1(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_1(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_1(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    const props_20 = ofArray([["className", "is-fluid"], ["className", "mb-2"], ["className", "pt-4"], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-vcentered"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("reports.payoutId"), "payoutId")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.auctionDate"), "auctionDate")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.lot"), "lot")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.submissions"), "submissions")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.sum"), "sum")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_20)));
}

export function CreatePayout(createPayoutInputProps) {
    let props_8, elms, props_5, props, props_3, props_12, props_10;
    const dispatch = createPayoutInputProps.dispatch;
    const state = createPayoutInputProps.state;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput[1];
    const goToCreatePayoutPage = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading(true);
        return singleton.Bind(payOutApi().getNextPayoutId(), (_arg1) => {
            window.open(toPath(new Route(52, new PayOutArg(1, _arg1, AuctionIdModule_first, new PayOuts_PayoutStatus(1)))));
            setIsLoading(false);
            return singleton.Return();
        });
    }), (_arg2) => {
        setIsLoading(false);
        return singleton.Zero();
    }));
    const elms_1 = ofArray([(props_8 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_2((props_5 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "searchInput"], ["placeholder", t("reports.searchSubmissionAndCustomer")], ["onChange", (ev) => {
        dispatch(new Msg(5, ev.target.value));
    }], ["onKeyUp", (ev_1) => {
        dispatch(new Msg(6, ev_1.key));
    }], ["value", state.SearchString]]), createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", props))))), (props_3 = ofArray([["className", "is-left"], ["style", {
        height: 100 + "%",
    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-search", "fa-lg"]),
    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))])]]), createElement("p", createObj(Helpers_combineClasses("control", props_5))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_12 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_10 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["style", {
        justifyContent: "flex-end",
    }]), delay(() => append(patternInput[0] ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["onClick", (_arg1_1) => {
        startImmediate(goToCreatePayoutPage);
    }]), delay(() => singleton_1(["children", t("reports.createPayout")]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12))))]);
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

export function RenderPayout(renderPayoutInputProps) {
    let props_12, props_2, props, props_4, props_6, props_8, props_10;
    const status = renderPayoutInputProps.status;
    const payout = renderPayoutInputProps.payout;
    const auctionsDates = join(", ", map((a) => {
        const date = toLocalTime(a.AuctionsDate);
        const locale = sv;
        const formatOptions = {};
        formatOptions.locale = locale;
        return format_1(date, "dd MMMM yyyy", formatOptions);
    }, payout.Auctions));
    const props_14 = ofArray([["className", "mb-5"], ["className", "pb-4"], ["className", "is-fluid"], ["className", "has-background-white"], ["style", {
        cursor: "pointer",
    }], ["onClick", (_arg1) => {
        navigate(toPath(new Route(52, new PayOutArg(1, payout.PayoutId, defaultArg(tryHead(map((x) => x.AuctionId, payout.Auctions)), AuctionIdModule_first), status))));
    }], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-vcentered"], ["className", "px-0"], ["className", "is-uppercase"], ["className", "is-size-6"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-text-weight-bold"], ["children", PayOutIdModule_toString(payout.PayoutId)]]), createElement("p", createObj(Helpers_combineClasses("", props))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "is-4"], ["children", `${auctionsDates}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", `${payout.TotalItems}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", `${payout.TotalSubmissions}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Currency_format(payout.TotalPayout)]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_12))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_14)));
}

export function Render(renderInputProps) {
    let props_2, s, s_1, props_11, children, props_5, props_8, props_16;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderInputProps.p, renderInputProps.status), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const elms = ofArray([(props_2 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("reports.payouts"), createElement("h1", {
        className: "title",
        children: s,
    })), (s_1 = t("reports.payoutsSubtitle"), createElement("h2", {
        className: "subtitle",
        children: s_1,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_2)))), (props_11 = ofArray([["className", "is-uppercase"], ["className", "is-centered"], ["className", "is-toggle"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_5 = toList(delay(() => append(equals(state_1.PayoutStatus, new PayOuts_PayoutStatus(1)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
        className: "is-normal-padding",
        children: t("reports.started"),
        onClick: (_arg1) => {
            dispatch(new Msg(1, new PayOuts_PayoutStatus(1)));
        },
    })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_5)))), (props_8 = toList(delay(() => append(equals(state_1.PayoutStatus, new PayOuts_PayoutStatus(0)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
        className: "is-normal-padding",
        children: t("reports.paidOut"),
        onClick: (_arg2) => {
            dispatch(new Msg(1, new PayOuts_PayoutStatus(0)));
        },
    })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_8))))]), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_11)))), (props_16 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement(CreatePayout, {
        state: state_1,
        dispatch: dispatch,
    })), delay(() => append(singleton_1(tableHeader(state_1, dispatch)), delay(() => {
        let value_38, props_14;
        const matchValue = state_1.PayOuts;
        switch (matchValue.tag) {
            case 1: {
                return singleton_1(loader("big"));
            }
            case 3: {
                return singleton_1((value_38 = matchValue.fields[0].message, createElement("div", {
                    children: [value_38],
                })));
            }
            case 2: {
                const payouts = matchValue.fields[0];
                return append(equals(payouts.Result, empty_1()) ? singleton_1((props_14 = ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", t("reports.noPayouts")]]), createElement("p", createObj(Helpers_combineClasses("", props_14))))) : map_1((payout) => createElement(RenderPayout, {
                    payout: payout,
                    status: state_1.PayoutStatus,
                }), payouts.Result), delay(() => singleton_1(renderPagination(payouts.Page, payouts.Total, payouts.PageSize, (arg) => {
                    dispatch(new Msg(3, arg));
                }))));
            }
            default: {
                return singleton_1(null);
            }
        }
    }))))))))]), createElement("section", createObj(Helpers_combineClasses("section", props_16))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

