import { Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { string_type, array_type, union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { ImageInFolder$reflection } from "./Shared.js";

export class Action extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetImages"];
    }
}

export function Action$reflection() {
    return union_type("Shared.Streams.Action", [], Action, () => [[]]);
}

export class Response extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ImagesInFolder"];
    }
}

export function Response$reflection() {
    return union_type("Shared.Streams.Response", [], Response, () => [[["Item", array_type(ImageInFolder$reflection())]]]);
}

export class StreamFrom_Action extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SubscribeImages"];
    }
}

export function StreamFrom_Action$reflection() {
    return union_type("Shared.Streams.StreamFrom.Action", [], StreamFrom_Action, () => [[]]);
}

export class StreamFrom_Response extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ImageAdded", "FolderAdded"];
    }
}

export function StreamFrom_Response$reflection() {
    return union_type("Shared.Streams.StreamFrom.Response", [], StreamFrom_Response, () => [[["Item", ImageInFolder$reflection()]], [["Item", string_type]]]);
}

