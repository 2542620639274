import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "../Localization.js";
import { createElement } from "react";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { buttonIcon } from "../Components/IconButton.js";
import { Route, toPath } from "../Router.js";

export const render = (() => {
    let props_2, s, s_1, props_8, props_4, props_6;
    const elms = ofArray([(props_2 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("reports.auctionReportsTitle"), createElement("h1", {
        className: "title",
        children: s,
    })), (s_1 = t("reports.auctionReportsSubtitle"), createElement("h2", {
        className: "subtitle",
        children: s_1,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_2)))), (props_8 = singleton(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([buttonIcon(t("reports.tenderReportMenuItem"), "fal", "fa-list-ol", toPath(new Route(40)), "homeButton__grey"), buttonIcon(t("reports.finalPriceReportMenuItem"), "fal", "fa-trophy-alt", toPath(new Route(42)), "homeButton__grey"), buttonIcon(t("reports.resaleRightsReportMenuItem"), "fal", "fa-signature", toPath(new Route(49)), "homeButton__grey")])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_4)))), (props_6 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([buttonIcon(t("reports.presaleReportMenuItem"), "fal", "fa-file-invoice", toPath(new Route(51)), "homeButton__grey"), buttonIcon(t("reports.payoutReportMenuItem"), "fal", "fa-file-invoice-dollar", toPath(new Route(50)), "homeButton__grey")])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_6))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_8))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
})();

