import { Record, Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { record_type, class_type, union_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { now, fromDate } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { second, minute, hour, day, month, year, create } from "./fable_modules/fable-library.3.6.1/Date.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { t } from "./Localization.js";
import { singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { View_Render as View_Render_1 } from "./Components/AuctionPrioritySettings.js";
import { View_Render as View_Render_2 } from "./Components/AuctionLocationSettings.js";
import { View_Render as View_Render_3 } from "./Components/AuctionGeneralSettings.js";

export class Types_EditAuctionMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GeneralSettings", "PrioritySettings", "LocationSettings"];
    }
}

export function Types_EditAuctionMode$reflection() {
    return union_type("EditAuction.Types.EditAuctionMode", [], Types_EditAuctionMode, () => [[], [], []]);
}

export class Types_State extends Record {
    constructor(AuctionId, EditMode) {
        super();
        this.AuctionId = AuctionId;
        this.EditMode = EditMode;
    }
}

export function Types_State$reflection() {
    return record_type("EditAuction.Types.State", [], Types_State, () => [["AuctionId", class_type("System.Int64")], ["EditMode", Types_EditAuctionMode$reflection()]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeEditMode"];
    }
}

export function Types_Msg$reflection() {
    return union_type("EditAuction.Types.Msg", [], Types_Msg, () => [[["Item", Types_EditAuctionMode$reflection()]]]);
}

export function Common_toDateTimeOffset(date, time) {
    let copyOfStruct;
    return fromDate(create(year(date), month(date), day(date), hour(time), minute(time), second(time)), (copyOfStruct = now(), copyOfStruct.offset));
}

export function State_init(id) {
    return [new Types_State(id, new Types_EditAuctionMode(0)), Cmd_none()];
}

export function State_update(msg, state) {
    return [new Types_State(state.AuctionId, msg.fields[0]), Cmd_none()];
}

export function View_Render(view_RenderInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(view_RenderInputProps.id), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_16 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_2, props;
        return append(singleton((props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-text-centered"], ["className", "has-text-grey-dark"], ["children", t("auction.editSettings")]]), createElement("h1", createObj(Helpers_combineClasses("title", props))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_2))))), delay(() => {
            let props_14, children, props_5, props_8, props_11;
            return append(singleton((props_14 = ofArray([["className", "is-centered"], ["className", "is-medium"], ["className", "is-toggle"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_5 = toList(delay(() => append(equals(state_1.EditMode, new Types_EditAuctionMode(0)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("auction.generalSettings"),
                onClick: (_arg1) => {
                    dispatch(new Types_Msg(0, new Types_EditAuctionMode(0)));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_5)))), (props_8 = toList(delay(() => append(equals(state_1.EditMode, new Types_EditAuctionMode(1)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("auction.prioritySettings"),
                onClick: (_arg2) => {
                    dispatch(new Types_Msg(0, new Types_EditAuctionMode(1)));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_8)))), (props_11 = toList(delay(() => append(equals(state_1.EditMode, new Types_EditAuctionMode(2)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("auction.locationSettings"),
                onClick: (_arg3) => {
                    dispatch(new Types_Msg(0, new Types_EditAuctionMode(2)));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_11))))]), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_14))))), delay(() => {
                const matchValue = state_1.EditMode;
                switch (matchValue.tag) {
                    case 1: {
                        return singleton(createElement(View_Render_1, {
                            id: state_1.AuctionId,
                        }));
                    }
                    case 2: {
                        return singleton(createElement(View_Render_2, {
                            id: state_1.AuctionId,
                        }));
                    }
                    default: {
                        return singleton(createElement(View_Render_3, {
                            id: state_1.AuctionId,
                        }));
                    }
                }
            }));
        }));
    }))))]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_16)));
}

