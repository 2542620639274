import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { ofArray, singleton } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function backButtonHref(href, title) {
    let elms;
    const props_3 = ofArray([["href", href], ["children", Interop_reactApi.Children.toArray([(elms = singleton(createElement("i", {
        className: join(" ", ["fa", "fa-chevron-left"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("span", {
        className: "",
        children: title,
    })])]]);
    return createElement("a", createObj(Helpers_combineClasses("button", props_3)));
}

