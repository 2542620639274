import { Union, Record } from "../../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, record_type, string_type, class_type } from "../../fable_modules/fable-library.3.6.1/Reflection.js";
import { Agreement_StatusAgreement, Agreement_StatusAgreement$reflection } from "../../Shared/Shared.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection } from "../../Extensions.js";
import { toLocalTime } from "../../fable_modules/fable-library.3.6.1/DateOffset.js";
import { sv } from "date-fns/locale";
import { format as format_2 } from "date-fns";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { agreementApi } from "../../Communication.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, append, singleton as singleton_1, delay, toList } from "../../fable_modules/fable-library.3.6.1/Seq.js";
import { createElement } from "react";
import { equals, createObj } from "../../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { singleton as singleton_2, ofArray } from "../../fable_modules/fable-library.3.6.1/List.js";
import { join } from "../../fable_modules/fable-library.3.6.1/String.js";
import { toString } from "../../fable_modules/fable-library.3.6.1/Long.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { dividedTop } from "../BorderDividedLine.js";

export class State extends Record {
    constructor(AgreementId, SellerSignatureName, EffectaSignatureName, Status) {
        super();
        this.AgreementId = AgreementId;
        this.SellerSignatureName = SellerSignatureName;
        this.EffectaSignatureName = EffectaSignatureName;
        this.Status = Status;
    }
}

export function State$reflection() {
    return record_type("Components.AgreementSignature.State", [], State, () => [["AgreementId", class_type("System.Int64")], ["SellerSignatureName", string_type], ["EffectaSignatureName", string_type], ["Status", Agreement_StatusAgreement$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SignByEffecta", "SignBySeller"];
    }
}

export function Msg$reflection() {
    return union_type("Components.AgreementSignature.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))]]]);
}

export function init(status, sellerSignature, effectaSignature, agreementId) {
    let x, date, locale, formatOptions, x_1, date_1, locale_1, formatOptions_1;
    return [new State(agreementId, (sellerSignature != null) ? ((x = sellerSignature, (x.UserName + ", ") + ((date = toLocalTime(x.Date), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_2(date, "dd MMMM yyyy", formatOptions)))))))) : "Not signed", (effectaSignature != null) ? ((x_1 = effectaSignature, (x_1.UserName + ", ") + ((date_1 = toLocalTime(x_1.Date), (locale_1 = sv, (formatOptions_1 = {}, (formatOptions_1.locale = locale_1, format_2(date_1, "dd MMMM yyyy", formatOptions_1)))))))) : "Not signed", status), Cmd_none()];
}

export function signByEffecta(agreementId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(agreementApi().signByEffecta(agreementId), (_arg1) => {
        let date, locale, formatOptions;
        if (_arg1.tag === 1) {
            return singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Agreement doesn\u0027t exists"))));
        }
        else {
            const x = _arg1.fields[0];
            return singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, (x.UserName + ", ") + ((date = toLocalTime(x.Date), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_2(date, "dd MMMM yyyy", formatOptions))))))))));
        }
    })), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function signBySeller(agreementId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(agreementApi().signBySeller(agreementId), (_arg1) => {
        let date, locale, formatOptions;
        if (_arg1.tag === 1) {
            return singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Agreement doesn\u0027t exists"))));
        }
        else {
            const x = _arg1.fields[0];
            return singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, (x.UserName + ", ") + ((date = toLocalTime(x.Date), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_2(date, "dd MMMM yyyy", formatOptions))))))))));
        }
    })), (_arg2) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function update(msg, state) {
    let matchValue_1, matchValue;
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.AgreementId, msg.fields[0].fields[0].fields[0], state.EffectaSignatureName, (matchValue_1 = state.Status, (matchValue_1.tag === 2) ? (new Agreement_StatusAgreement(2)) : ((matchValue_1.tag === 1) ? (new Agreement_StatusAgreement(3)) : ((matchValue_1.tag === 3) ? (new Agreement_StatusAgreement(3)) : (new Agreement_StatusAgreement(2)))))), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_fromAsync(signBySeller(state.AgreementId))];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [state, Cmd_none()];
        }
        else {
            return [new State(state.AgreementId, state.SellerSignatureName, msg.fields[0].fields[0].fields[0], (matchValue = state.Status, (matchValue.tag === 2) ? (new Agreement_StatusAgreement(3)) : ((matchValue.tag === 1) ? (new Agreement_StatusAgreement(1)) : ((matchValue.tag === 3) ? (new Agreement_StatusAgreement(3)) : (new Agreement_StatusAgreement(1)))))), Cmd_none()];
        }
    }
    else {
        return [state, Cmd_fromAsync(signByEffecta(state.AgreementId))];
    }
}

export function renderStatus(state) {
    const props_32 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_14, props_12, elms_1, props_22, props_20, elms_2, props_30, props_28, elms_3, props_6, props_4, elms;
        const matchValue = state.Status;
        switch (matchValue.tag) {
            case 2: {
                return singleton_1((props_14 = ofArray([["className", "has-addons"], ["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-rounded"], ["className", "is-uppercase"], ["className", "is-danger"], ["children", "To accept"]])))), (props_12 = ofArray([["className", "is-rounded"], ["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_2(createElement("i", {
                    className: join(" ", ["fa", "fa-hourglass-half"]),
                })), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))])]]), createElement("span", createObj(Helpers_combineClasses("tag", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_14)))));
            }
            case 1: {
                return singleton_1((props_22 = ofArray([["className", "has-addons"], ["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-primary"], ["className", "is-rounded"], ["className", "is-uppercase"], ["children", "To sign"]])))), (props_20 = ofArray([["className", "is-primary"], ["className", "is-rounded"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2(createElement("i", {
                    className: join(" ", ["fa", "fa-hourglass-start"]),
                })), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))])]]), createElement("span", createObj(Helpers_combineClasses("tag", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_22)))));
            }
            case 0: {
                return singleton_1((props_30 = ofArray([["className", "has-addons"], ["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-rounded"], ["className", "is-primary"], ["className", "is-uppercase"], ["children", "Draft"]])))), (props_28 = ofArray([["className", "is-rounded"], ["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_2(createElement("i", {
                    className: join(" ", ["fa", "fa-bell"]),
                })), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))])]]), createElement("span", createObj(Helpers_combineClasses("tag", props_28))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_30)))));
            }
            default: {
                return singleton_1((props_6 = ofArray([["className", "has-addons"], ["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-rounded"], ["className", "is-primary"], ["className", "is-uppercase"], ["children", "Signed"]])))), (props_4 = ofArray([["className", "is-rounded"], ["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_2(createElement("i", {
                    className: join(" ", ["fa", "fa-check-double"]),
                })), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))])]]), createElement("span", createObj(Helpers_combineClasses("tag", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_6)))));
            }
        }
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("column", props_32)));
}

export function header(state) {
    let props_19, props_10, props_8, props_2, props_6, props_4, props_17, props_15, elms;
    const props_21 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_19 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_10 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "has-text-grey"], ["className", "is-uppercase"], ["className", "is-size-4"], ["children", "Agreement"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_6 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["className", "is-uppercase"]), delay(() => append(singleton_1(["className", "is-size-4"]), delay(() => singleton_1(["children", "# " + toString(state.AgreementId)]))))))))), createElement("h1", createObj(Helpers_combineClasses("title", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), renderStatus(state)])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_8))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_17 = ofArray([["className", "has-text-right"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_2(createElement("i", {
        className: join(" ", ["fa", "fa-print"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("span", {
        className: "",
        children: "Skriv ut",
    })])]]), createElement("button", createObj(Helpers_combineClasses("button", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_17))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_19))))])]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_21)));
}

export function RenderSignature(renderSignatureInputProps) {
    let elms_4, props_2, elms_3, props_16, props_7, props_14, props_12, props_29, elms_1, props_27, props_25;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderSignatureInputProps.status, renderSignatureInputProps.sellerSignature, renderSignatureInputProps.effectaSignature, renderSignatureInputProps.agreementId), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_33 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_4 = ofArray([(props_2 = ofArray([["className", "is-one-fifth"], ["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-weight-bold"], ["className", "mb-3"], ["children", "Signaturer"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (elms_3 = ofArray([(props_16 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-grey"], ["children", "Försäljarens signatur: "]])))), createElement("span", {
        className: "",
        children: state_1.SellerSignatureName,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7)))), (props_14 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_12 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append((equals(state_1.Status, new Agreement_StatusAgreement(2)) ? true : equals(state_1.Status, new Agreement_StatusAgreement(3))) ? singleton_1(["disabled", true]) : empty(), delay(() => append(singleton_1(["onClick", (_arg1) => {
        dispatch(new Msg(1, new AsyncOperationStatus$1(0)));
    }]), delay(() => {
        let elms;
        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms = singleton_2(createElement("i", {
            className: join(" ", ["fa", "fa-file-upload"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), createElement("span", {
            className: "",
            children: "Signerat",
        })])]);
    })))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_16)))), (props_29 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-grey"], ["children", "Effectas signatur:"]])))), createElement("p", {
        className: "",
        children: state_1.EffectaSignatureName,
    })]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_27 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_25 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append((equals(state_1.Status, new Agreement_StatusAgreement(1)) ? true : equals(state_1.Status, new Agreement_StatusAgreement(3))) ? singleton_1(["disabled", true]) : empty(), delay(() => {
        let elms_2;
        return append(singleton_1(["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2(createElement("i", {
            className: join(" ", ["fa", "fa-check-double"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), createElement("span", {
            className: "",
            children: "Acceptera",
        })])]), delay(() => singleton_1(["onClick", (_arg2) => {
            dispatch(new Msg(0, new AsyncOperationStatus$1(0)));
        }])));
    })))))), createElement("button", createObj(Helpers_combineClasses("button", props_25))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_27))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_29))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))])]]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_33)));
}

