import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "./Extensions.js";
import { commonApi, itemApi, settingsApi, agreementApi, submissionsApi } from "./Communication.js";
import { Route, UserState, Route_AccountDetailsSubmissionsPage, toPath, navigate } from "./Router.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { createElement } from "react";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { ofArray, empty as empty_1, singleton as singleton_2 } from "./fable_modules/fable-library.3.6.1/List.js";
import { t } from "./Localization.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Agreement_AgreementType, Submissions_PageType, Submissions_SubmissionsInfo } from "./Shared/Shared.js";
import { loader } from "./Loader.js";
import { SubmissionPrintButton_footer, SubmissionPrintButton_header } from "./Print/PrintButtons.js";
import { RenderContent } from "./Components/SubmissionItems.js";
import { Render as Render_1 } from "./Components/SubmissionNotes.js";
import { CommonProfileComponent } from "./Components/Agreements/AgreementProfile.js";
import { Terms } from "./Components/Agreements/EditTerms.js";
import { RenderContent as RenderContent_1 } from "./Components/Submission.js";
import { TransportComponent } from "./Components/Agreements/AgreementTransport.js";
import { RenderScan } from "./Components/Agreements/AgreementScan.js";
import { RenderSignature } from "./Components/Agreements/AgreementSignature.js";

export function DeleteButton(deleteButtonInputProps) {
    let props_26, props_5, props_3, props_24;
    const unitVar2 = deleteButtonInputProps.unitVar2;
    const userState = deleteButtonInputProps.userState;
    const submissionId = deleteButtonInputProps.submissionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setResultOfDelete = patternInput[1];
    const resultOfDelete = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const showConfirm = patternInput_1[0];
    const setShowConfirm = patternInput_1[1];
    const startDeleting = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => {
        setResultOfDelete(new Deferred$1(1));
        return singleton.Bind(Remoting_handleNonAuth(submissionsApi().markAsDeleted(submissionId)), (_arg1) => {
            const result = _arg1;
            setShowConfirm(false);
            navigate(toPath(Route_AccountDetailsSubmissionsPage(userState.UserId, userState.Page)));
            setResultOfDelete(new Deferred$1(2, result));
            return singleton.Return(result);
        });
    }), setResultOfDelete);
    const elms_4 = singleton_2((props_26 = ofArray([["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_5 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(equals(resultOfDelete, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["onClick", (_arg1_1) => {
        setShowConfirm(!showConfirm);
    }]), delay(() => {
        let elms, s;
        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms = singleton_2(createElement("i", {
            className: join(" ", ["fa", "fa-trash-alt"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (s = t("common.delete"), createElement("span", {
            className: "",
            children: s,
        }))])]);
    })))))))), createElement("button", createObj(Helpers_combineClasses("button", props_3))))])]), createElement("div", createObj(Helpers_combineClasses("buttons", props_5)))), (props_24 = toList(delay(() => append(showConfirm ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
        let elms_3, elms_1, s_1, elms_2, s_2, props_21, props_19, props_15, props_17;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (elms_3 = ofArray([(elms_1 = ofArray([(s_1 = t("users.deleteSubmission"), createElement("p", {
            className: "modal-card-title",
            children: s_1,
        })), createElement("button", createObj(Helpers_combineClasses("delete", singleton_2(["onClick", (_arg2) => {
            setShowConfirm(false);
        }]))))]), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = singleton_2((s_2 = t("users.deleteSubmissionConfirmation"), createElement("p", {
            className: "",
            children: s_2,
        }))), createElement("section", {
            className: "modal-card-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (props_21 = ofArray([["style", {
            display: "block",
        }], ["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_15 = toList(delay(() => append(equals(resultOfDelete, new Deferred$1(1)) ? singleton_1(["disabled", true]) : empty(), delay(() => append(singleton_1(["children", t("common.cancel")]), delay(() => singleton_1(["onClick", (_arg3) => {
            setShowConfirm(false);
        }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_15)))), (props_17 = toList(delay(() => append(equals(resultOfDelete, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(singleton_1(["children", t("common.delete")]), delay(() => singleton_1(["onClick", (x) => {
            x.preventDefault();
            startDeleting();
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_19))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_21))))]), createElement("div", {
            className: "modal-card",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("modal", props_24))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_26)))));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    });
}

export function Render(renderInputProps) {
    let submissionId_1;
    const pageType = renderInputProps.pageType;
    const submissionId = renderInputProps.submissionId;
    const info = useFeliz_React__React_useDeferred_Static_2344FC52((submissionId_1 = submissionId, singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(submissionsApi().getSubmission(submissionId_1)), (_arg1) => {
        const submission = _arg1;
        return singleton.Bind(Remoting_handleNonAuth(agreementApi().getAgreement(submission.Submission.AgreementId)), (_arg2) => singleton.Bind(settingsApi().getWarehouseCategories(), (_arg3) => singleton.Bind(Remoting_handleNonAuth(itemApi().getIsSubmissionHasItems(submissionId_1)), (_arg4) => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg5) => singleton.Return(new Submissions_SubmissionsInfo(_arg2, submission, _arg3, _arg4, _arg5.ResidentCountry))))));
    }))), [submissionId]);
    switch (info.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            const value_40 = info.fields[0].message;
            return createElement("div", {
                children: [value_40],
            });
        }
        case 2: {
            const content = info.fields[0];
            return createElement("div", {
                className: join(" ", ["visible-page-in-print", "visible-page-in-print_style"]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const userState_1 = new UserState(content.SubmissionInfo.Submission.SellerId, 1);
                    return append(singleton_1(createElement(SubmissionPrintButton_header, {
                        id: submissionId,
                        userState: userState_1,
                    })), delay(() => {
                        let userState, props_10, children, props_1, props_4, props_7;
                        return append(singleton_1((userState = userState_1, (props_10 = ofArray([["className", "is-uppercase"], ["className", "is-centered"], ["className", "is-toggle"], ["className", "my-0"], ["className", "mt-5"], ["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_1 = toList(delay(() => append(equals(pageType, new Submissions_PageType(0)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("users.submission"),
                            href: toPath(new Route(22, userState.UserId, submissionId, new Submissions_PageType(0))),
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_1)))), (props_4 = toList(delay(() => append(equals(pageType, new Submissions_PageType(1)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("common.items"),
                            href: toPath(new Route(22, userState.UserId, submissionId, new Submissions_PageType(1))),
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_7 = toList(delay(() => append(equals(pageType, new Submissions_PageType(2)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("users.notes"),
                            href: toPath(new Route(22, userState.UserId, submissionId, new Submissions_PageType(2))),
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7))))]), createElement("ul", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_10)))))), delay(() => {
                            let matchValue, x_1, props_15, x, props_13;
                            return append((pageType.tag === 1) ? singleton_1(createElement(RenderContent, {
                                id: submissionId,
                            })) : ((pageType.tag === 2) ? singleton_1(createElement(Render_1, {
                                id: submissionId,
                            })) : ((matchValue = content.Agreement, (matchValue.tag === 1) ? ((x_1 = matchValue.fields[0], singleton_1((props_15 = ofArray([["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([createElement(CommonProfileComponent, {
                                info: new Agreement_AgreementType(1, x_1.PersonalInfo),
                                agreementId: x_1.AgreementId,
                            }), createElement(Terms, {
                                terms: x_1.AgreementTerms,
                                agreementId: x_1.AgreementId,
                            }), createElement(RenderContent_1, {
                                id: submissionId,
                                submission: content.SubmissionInfo.Submission,
                                categories: content.Categories,
                            }), createElement(TransportComponent, {
                                submissionId: submissionId,
                                info: content.SubmissionInfo.Transport,
                                residentCountry: content.ResidentCountry,
                            }), createElement(RenderScan, {
                                agreementId: x_1.AgreementId,
                                scan: x_1.Scan,
                            }), createElement(RenderSignature, {
                                status: x_1.Status,
                                sellerSignature: x_1.SellerSignature,
                                effectaSignature: x_1.EffectaSignature,
                                agreementId: x_1.AgreementId,
                            })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_15))))))) : ((x = matchValue.fields[0], singleton_1((props_13 = ofArray([["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([createElement(CommonProfileComponent, {
                                info: new Agreement_AgreementType(0, x.PersonalInfo),
                                agreementId: x.AgreementId,
                            }), createElement(Terms, {
                                terms: x.AgreementTerms,
                                agreementId: x.AgreementId,
                            }), createElement(RenderContent_1, {
                                id: submissionId,
                                submission: content.SubmissionInfo.Submission,
                                categories: content.Categories,
                            }), createElement(TransportComponent, {
                                submissionId: submissionId,
                                info: content.SubmissionInfo.Transport,
                                residentCountry: content.ResidentCountry,
                            }), createElement(RenderScan, {
                                agreementId: x.AgreementId,
                                scan: x.Scan,
                            }), createElement(RenderSignature, {
                                status: x.Status,
                                sellerSignature: x.SellerSignature,
                                effectaSignature: x.EffectaSignature,
                                agreementId: x.AgreementId,
                            })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_13)))))))))), delay(() => {
                                let props_22, elms_2, props_19, elms, elms_1;
                                return singleton_1((props_22 = ofArray([["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_19 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => (((!content.SubmissionInfo.Submission.IsDeleted) ? (!content.HasSubItems) : false) ? singleton_1(createElement(DeleteButton, {
                                    submissionId: submissionId,
                                    userState: userState_1,
                                })) : empty()))), createElement("div", {
                                    className: "column",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                                })), (elms_1 = singleton_2(createElement(SubmissionPrintButton_footer, {
                                    id: submissionId,
                                    userState: userState_1,
                                })), createElement("div", {
                                    className: "column",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                                }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_19))))), createElement("section", {
                                    className: "section",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                                }))])]]), createElement("div", createObj(Helpers_combineClasses("container", props_22)))));
                            }));
                        }));
                    }));
                })))),
            });
        }
        default: {
            return null;
        }
    }
}

