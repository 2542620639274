import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { record_type, list_type, int32_type, union_type, string_type, class_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { AuctionStatus, Category, NextOrderNumber, CategoryCatalog, LotsFilter, Category$reflection, AuctionStatistic$reflection, LotsFilter$reflection, LotSummary$reflection } from "./Shared/Shared.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingQuery$1$reflection, Common_PagingResult$1$reflection } from "./Shared/Common.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { auctionApi, lotApi } from "./Communication.js";
import { Localized_translate, tWithArgs, t, getPrimaryLanguage, getLocalizationContext } from "./Localization.js";
import { CategoryIdModule_toString, CategoryIdModule_fromString, ReservedPriceModule_toInt, OrderNumberModule_toString, ItemIdModule_toString, CurrencyModule_fromInt, CurrencyModule_zero, CategoryIdModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { map as map_2, partition, isEmpty, cons, singleton as singleton_1, ofArray, empty } from "./fable_modules/fable-library.3.6.1/List.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { AuctionArg, navigate, Route, EditLotArg, Route_AccountDetailsSubmissions_7639071A, toPath, AuctionState } from "./Router.js";
import { LocalizedStringModule_from } from "./bidflow/src/infrastructure/Localization.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, map, append, singleton as singleton_2, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { toLocalTime } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { sv } from "date-fns/locale";
import { format as format_1 } from "date-fns";
import { int32ToString, equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Currency_formatNOption, Currency_formatN, Currency_currencySymbol, Currency_format } from "./Common.js";
import { loader, loaderSmall } from "./Loader.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { isCurrentUserAdmin as isCurrentUserAdmin_1 } from "./LocalStorage.js";
import { Image_originalImageUrl, Image_medium, Image_smallContains, Image_imagePlaceholder } from "./Css.js";
import { map as map_1, value as value_231 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { ActionType, CreateOrResellLotComponent } from "./Components/CreateLot.js";
import { ReturnRecycleObject, ReturnOrRecycleComponent } from "./Components/ReturnOrRecycleObject.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { renderPagination } from "./Components/Pagination.js";

export class State extends Record {
    constructor(AuctionId, Lots, CurrentPage, Query, SearchString, LotString, Statistic, Category, Categories) {
        super();
        this.AuctionId = AuctionId;
        this.Lots = Lots;
        this.CurrentPage = (CurrentPage | 0);
        this.Query = Query;
        this.SearchString = SearchString;
        this.LotString = LotString;
        this.Statistic = Statistic;
        this.Category = (Category | 0);
        this.Categories = Categories;
    }
}

export function State$reflection() {
    return record_type("Client.AuctionLots.State", [], State, () => [["AuctionId", class_type("System.Int64")], ["Lots", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(LotSummary$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(LotSummary$reflection())]], [["ErrorValue", string_type]]]))], ["CurrentPage", int32_type], ["Query", Common_PagingQuery$1$reflection(LotsFilter$reflection())], ["SearchString", string_type], ["LotString", string_type], ["Statistic", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionStatistic$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionStatistic$reflection()]], [["ErrorValue", string_type]]]))], ["Category", int32_type], ["Categories", list_type(Category$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadLots", "LoadStatistic", "PageChanged", "UpdatePageState", "OnColumnClicked", "SearchStringChange", "SearchForLotChange", "KeyboardEvent", "SearchEnter", "UnSoldFilterChanged", "IncompleteFilterChanged", "ChangeCategory", "LoadCategories"];
    }
}

export function Msg$reflection() {
    return union_type("Client.AuctionLots.Msg", [], Msg, () => [[["Item1", Common_PagingQuery$1$reflection(LotsFilter$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(LotSummary$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(LotSummary$reflection())]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionStatistic$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionStatistic$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", Common_PagingQuery$1$reflection(LotsFilter$reflection())]], [], [], [["Item", int32_type]], [["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Category$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Category$reflection())]], [["ErrorValue", string_type]]]))]]]);
}

export function loadLots(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let inputRecord;
        const query_1 = new Common_PagingQuery$1((inputRecord = query.Payload, new LotsFilter(inputRecord.AuctionId, inputRecord.LotAndItemSearch, query.Payload.FreeTextSearch.trim(), inputRecord.ByInComplete, inputRecord.ByUnSold, inputRecord.ByCategory)), query.Page, query.PageSize, query.SortBy, query.LocalizationContext);
        return singleton.Bind(Remoting_handleNonAuth(lotApi().getLots(query_1)), (_arg1) => singleton.Return(new Msg(0, query_1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))));
    }), (_arg2) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function loadStatistic(auctionId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotApi().getStatistic(auctionId)), (_arg1) => singleton.Return(new Msg(1, auctionId, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(1, auctionId, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function loadCategories(auctionId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getCategories(auctionId)), (_arg1) => {
        const result = _arg1;
        return singleton.Return(new Msg(12, auctionId, new AsyncOperationStatus$1(1, new FSharpResult$2(0, (new CategoryCatalog(result.AuctionId, result.Categories, result.AuctionName, result.AuctionStartDate)).Categories))));
    })), (_arg2) => singleton.Return(new Msg(12, auctionId, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(auctionId, state) {
    return [new State(auctionId, new Deferred$1(0), state.Page, new Common_PagingQuery$1(new LotsFilter(auctionId, void 0, "", state.IsIncompleteFilterOn, state.IsUnsoldFilterOn, void 0), state.Page, 20, void 0, getLocalizationContext()), "", "", new Deferred$1(0), CategoryIdModule_empty, empty()), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(1, auctionId, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Msg(12, auctionId, new AsyncOperationStatus$1(0)))]))];
}

export function update(msg, state) {
    let s, payload, FreeTextSearch, payload_2, FreeTextSearch_1, inputRecord_3, inputRecord_4, inputRecord_6;
    if (msg.tag === 0) {
        if (msg.fields[1].tag === 1) {
            return [new State(state.AuctionId, new Deferred$1(2, msg.fields[1].fields[0]), state.CurrentPage, state.Query, state.SearchString, state.LotString, state.Statistic, state.Category, state.Categories), Cmd_none()];
        }
        else {
            return [new State(state.AuctionId, new Deferred$1(1), state.CurrentPage, state.Query, state.SearchString, state.LotString, state.Statistic, state.Category, state.Categories), Cmd_fromAsync(loadLots(state.Query))];
        }
    }
    else if (msg.tag === 3) {
        window.open(msg.fields[0]);
        return init(state.AuctionId, new AuctionState(state.Query.Payload.ByUnSold, state.Query.Payload.ByInComplete, state.CurrentPage));
    }
    else if (msg.tag === 2) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Common_PagingQuery$1(inputRecord.Payload, msg.fields[0], inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(state.AuctionId, state.Lots, msg.fields[0], newQuery, state.SearchString, state.LotString, state.Statistic, state.Category, state.Categories), Cmd_OfFunc_result(new Msg(0, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 4) {
        let newQuery_1;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_1 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, inputRecord_1.Page, inputRecord_1.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_1.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_2 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, inputRecord_2.Page, inputRecord_2.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_2.LocalizationContext));
                break;
            }
        }
        return [new State(state.AuctionId, state.Lots, state.CurrentPage, newQuery_1, state.SearchString, state.LotString, state.Statistic, state.Category, state.Categories), Cmd_OfFunc_result(new Msg(0, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 5) {
        return [new State(state.AuctionId, state.Lots, state.CurrentPage, state.Query, msg.fields[0], state.LotString, state.Statistic, state.Category, state.Categories), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new State(state.AuctionId, state.Lots, state.CurrentPage, state.Query, state.SearchString, msg.fields[0], state.Statistic, state.Category, state.Categories), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [new State(state.AuctionId, state.Lots, 1, state.Query, state.SearchString, state.LotString, state.Statistic, state.Category, state.Categories), Cmd_fromAsync(loadLots(msg.fields[0]))];
    }
    else if (msg.tag === 7) {
        if (msg.fields[0] === "Enter") {
            let newQuery_2;
            const query = state.Query;
            newQuery_2 = (new Common_PagingQuery$1((payload = state.Query.Payload, (FreeTextSearch = state.SearchString.trim().toLowerCase(), new LotsFilter(payload.AuctionId, (state.LotString === "") ? (void 0) : state.LotString.trim(), FreeTextSearch, payload.ByInComplete, payload.ByUnSold, payload.ByCategory))), 1, query.PageSize, query.SortBy, query.LocalizationContext));
            return [new State(state.AuctionId, state.Lots, state.CurrentPage, newQuery_2, state.SearchString, state.LotString, state.Statistic, state.Category, state.Categories), Cmd_OfFunc_result(new Msg(8, newQuery_2))];
        }
        else if (msg.fields[0] === "Escape") {
            let newQuery_3;
            const query_1 = state.Query;
            newQuery_3 = (new Common_PagingQuery$1((payload_2 = state.Query.Payload, (FreeTextSearch_1 = state.SearchString.trim().toLowerCase(), new LotsFilter(payload_2.AuctionId, (state.LotString === "") ? (void 0) : state.LotString.trim(), FreeTextSearch_1, payload_2.ByInComplete, payload_2.ByUnSold, payload_2.ByCategory))), 1, query_1.PageSize, query_1.SortBy, query_1.LocalizationContext));
            return [new State(state.AuctionId, state.Lots, state.CurrentPage, newQuery_3, state.SearchString, state.LotString, state.Statistic, state.Category, state.Categories), Cmd_OfFunc_result(new Msg(8, newQuery_3))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 9) {
        let newQuery_4;
        const query_2 = state.Query;
        newQuery_4 = (new Common_PagingQuery$1((inputRecord_3 = state.Query.Payload, new LotsFilter(inputRecord_3.AuctionId, inputRecord_3.LotAndItemSearch, inputRecord_3.FreeTextSearch, inputRecord_3.ByInComplete, state.Query.Payload.ByUnSold ? false : true, inputRecord_3.ByCategory)), 1, query_2.PageSize, query_2.SortBy, query_2.LocalizationContext));
        return [new State(state.AuctionId, state.Lots, state.CurrentPage, newQuery_4, state.SearchString, state.LotString, state.Statistic, state.Category, state.Categories), Cmd_OfFunc_result(new Msg(8, newQuery_4))];
    }
    else if (msg.tag === 10) {
        let newQuery_5;
        const query_3 = state.Query;
        newQuery_5 = (new Common_PagingQuery$1((inputRecord_4 = state.Query.Payload, new LotsFilter(inputRecord_4.AuctionId, inputRecord_4.LotAndItemSearch, inputRecord_4.FreeTextSearch, state.Query.Payload.ByInComplete ? false : true, inputRecord_4.ByUnSold, inputRecord_4.ByCategory)), 1, query_3.PageSize, query_3.SortBy, query_3.LocalizationContext));
        return [new State(state.AuctionId, state.Lots, state.CurrentPage, newQuery_5, state.SearchString, state.LotString, state.Statistic, state.Category, state.Categories), Cmd_OfFunc_result(new Msg(8, newQuery_5))];
    }
    else if (msg.tag === 11) {
        let newQuery_6;
        const inputRecord_5 = state.Query;
        newQuery_6 = (new Common_PagingQuery$1((inputRecord_6 = state.Query.Payload, new LotsFilter(inputRecord_6.AuctionId, inputRecord_6.LotAndItemSearch, inputRecord_6.FreeTextSearch, inputRecord_6.ByInComplete, inputRecord_6.ByUnSold, (msg.fields[0] === CategoryIdModule_empty) ? (void 0) : msg.fields[0])), 1, inputRecord_5.PageSize, inputRecord_5.SortBy, inputRecord_5.LocalizationContext));
        return [new State(state.AuctionId, state.Lots, state.CurrentPage, newQuery_6, state.SearchString, state.LotString, state.Statistic, msg.fields[0], state.Categories), Cmd_OfFunc_result(new Msg(0, newQuery_6, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 12) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.AuctionId, state.Lots, state.CurrentPage, state.Query, state.SearchString, state.LotString, state.Statistic, state.Category, cons(new Category(CategoryIdModule_empty, LocalizedStringModule_from(singleton_1([getPrimaryLanguage(), t("reports.allCategories")])), 0, 0, CurrencyModule_zero, new NextOrderNumber(0)), msg.fields[1].fields[0].fields[0])), Cmd_none()];
            }
        }
        else {
            return [new State(state.AuctionId, state.Lots, state.CurrentPage, state.Query, state.SearchString, state.LotString, state.Statistic, state.Category, empty()), Cmd_fromAsync(loadCategories(msg.fields[0]))];
        }
    }
    else if (msg.fields[1].tag === 1) {
        return [new State(state.AuctionId, state.Lots, state.CurrentPage, state.Query, state.SearchString, state.LotString, new Deferred$1(2, msg.fields[1].fields[0]), state.Category, state.Categories), Cmd_OfFunc_result(new Msg(0, state.Query, new AsyncOperationStatus$1(0)))];
    }
    else {
        return [new State(state.AuctionId, state.Lots, state.CurrentPage, state.Query, state.SearchString, state.LotString, new Deferred$1(1), state.Category, state.Categories), Cmd_fromAsync(loadStatistic(msg.fields[0]))];
    }
}

export function tableHeader(state, dispatch) {
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(4, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_2(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_2(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    return createElement("div", {
        style: {
            marginBottom: 10,
        },
        children: Interop_reactApi.Children.toArray([createElement("tr", {
            className: "tableHeader",
            style: {
                color: "#4a4a4a",
                fontSize: 8 + "px",
            },
            children: Interop_reactApi.Children.toArray([createElement("td", {
                style: {
                    width: 7 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                    minWidth: 90,
                },
            }), createElement("td", {
                style: {
                    width: 5 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "has-text-left",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.itemId"), "itemId")]),
            }), createElement("td", {
                style: {
                    width: 5 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "has-text-left",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.lotNumber"), "id")]),
            }), createElement("td", {
                style: {
                    width: 5 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "ml-1",
                className: "has-text-left",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.priority"), "priority")]),
            }), createElement("td", {
                style: {
                    width: 20 + "%",
                    maxWidth: 20,
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "has-text-left",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.lotTitle"), "title")]),
            }), createElement("td", {
                style: {
                    width: 5 + "%",
                    maxWidth: 20,
                },
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.category"), "category")]),
            }), createElement("td", {
                style: {
                    width: 5 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "has-text-right",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.bidders"), "bidders")]),
            }), createElement("td", {
                style: {
                    width: 5 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "has-text-right",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.bids"), "bids")]),
            }), createElement("td", {
                style: {
                    width: 8 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "has-text-right",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.currentBidColumn"), "currentBid")]),
            }), createElement("td", {
                style: {
                    width: 8 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "has-text-right",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.estimatedPriceColumn"), "estimated")]),
            }), createElement("td", {
                style: {
                    width: 8 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "has-text-right",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.reservedPriceColumn"), "reservedPrice")]),
            }), createElement("td", {
                style: {
                    width: 7 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "has-text-right",
                children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.status"), "status")]),
            }), createElement("td", {
                style: {
                    width: 7 + "%",
                    paddingLeft: 10,
                    paddingRight: 10,
                },
                className: "mx-4",
                className: "has-text-centered",
                children: t("auction.resale"),
            }), createElement("td", {
                style: {
                    width: 4 + "%",
                },
            })]),
        })]),
    });
}

export function BiddingHistory(biddingHistoryInputProps) {
    const reservedPrice = biddingHistoryInputProps.reservedPrice;
    const lotId = biddingHistoryInputProps.lotId;
    const history = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(lotApi().getBidHistory(lotId)), [lotId]);
    const historyLine = (h) => {
        let props, props_3, props_5, props_7, props_9;
        const props_11 = ofArray([["className", "is-size-6"], ["className", "pt-1"], ["className", "pb-2"], ["className", "mb-0"], ["className", "mx-3"], ["className", "has-text-grey"], ["className", "is-gapless"], ["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props = toList(delay(() => append(singleton_2(["className", "is-3"]), delay(() => {
            let date, locale, formatOptions;
            return singleton_2(["children", (date = toLocalTime(h.Timestamp), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_1(date, "dd MMMM yyyy, HH:mm:ss", formatOptions)))))]);
        })))), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_3 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            children: `${h.UserName}`,
            href: toPath(Route_AccountDetailsSubmissions_7639071A(h.UserId)),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_5 = toList(delay(() => append(singleton_2(["className", "is-2"]), delay(() => ((h.Bid === 0) ? singleton_2(["children", "-"]) : singleton_2(["children", Currency_format(CurrencyModule_fromInt(h.Bid))])))))), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_7 = toList(delay(() => append(singleton_2(["className", "is-2"]), delay(() => (h.IsBidAgent ? singleton_2(["children", t("auction.bidAgent")]) : singleton_2(["children", ""])))))), createElement("div", createObj(Helpers_combineClasses("column", props_7)))), (props_9 = toList(delay(() => append(singleton_2(["className", "is-1"]), delay(() => ((h.MaxBid === 0) ? singleton_2(["children", ""]) : singleton_2(["children", Currency_format(CurrencyModule_fromInt(h.MaxBid))])))))), createElement("div", createObj(Helpers_combineClasses("column", props_9))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("columns", props_11)));
    };
    return createElement("div", {
        style: {
            borderTop: (((1 + "px ") + "solid") + " ") + "#808080",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let s, props_24, props_14, props_16, props_18, props_20, props_22;
            switch (history.tag) {
                case 1: {
                    return singleton_2(loaderSmall());
                }
                case 3: {
                    return singleton_2((s = (`Error with ${history.fields[0]}`), createElement("span", {
                        className: "",
                        children: s,
                    })));
                }
                case 2: {
                    const history_1 = history.fields[0];
                    return append(singleton_2((props_24 = ofArray([["className", "pt-1"], ["className", "pb-0"], ["className", "mb-0"], ["className", "mx-3"], ["className", "is-uppercase"], ["className", "is-size-7"], ["className", "has-text-grey-light"], ["className", "is-gapless"], ["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-3"], ["children", t("auction.bidDate")]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-3"], ["children", t("auction.customer")]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_18 = ofArray([["className", "is-2"], ["children", tWithArgs("auction.bidCurrency", {
                        currency: Currency_currencySymbol(),
                    })]]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), (props_20 = ofArray([["className", "is-2"], ["children", t("auction.bidAgent")]]), createElement("div", createObj(Helpers_combineClasses("column", props_20)))), (props_22 = ofArray([["className", "is-1"], ["children", t("auction.maxBid")]]), createElement("div", createObj(Helpers_combineClasses("column", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_24))))), delay(() => {
                        if (isEmpty(history_1)) {
                            return singleton_2(null);
                        }
                        else {
                            const xs_26 = history_1;
                            const patternInput = partition((x_2) => (!x_2.ReservedPriceMet), xs_26);
                            const bidsAboveMinPrice = patternInput[1];
                            return (reservedPrice != null) ? append(map((h_2) => historyLine(h_2), bidsAboveMinPrice), delay(() => {
                                let props_30, props_28;
                                return append((!isEmpty(bidsAboveMinPrice)) ? singleton_2((props_30 = ofArray([["className", "pt-1"], ["className", "pb-0"], ["className", "mb-0"], ["className", "is-size-6"], ["className", "has-text-grey"], ["className", "is-gapless"], ["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-6"])))), (props_28 = ofArray([["className", "is-2"], ["children", t("auction.reservedPrice")]]), createElement("div", createObj(Helpers_combineClasses("column", props_28))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_30))))) : empty_1(), delay(() => map((h_3) => historyLine(h_3), patternInput[0])));
                            })) : map((h_1) => historyLine(h_1), xs_26);
                        }
                    }));
                }
                default: {
                    return singleton_2(loaderSmall());
                }
            }
        })))),
    });
}

export function TableLine(tableLineInputProps) {
    let matchValue;
    const auctionStatus = tableLineInputProps.auctionStatus;
    const updateAuctionPageStateAndOpenNewTab = tableLineInputProps.updateAuctionPageStateAndOpenNewTab;
    const auctionPageFilterState = tableLineInputProps.auctionPageFilterState;
    const auctionId = tableLineInputProps.auctionId;
    const lot = tableLineInputProps.lot;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowImage = patternInput_1[1];
    const isShowImage = patternInput_1[0];
    const isCurrentUserAdmin = isCurrentUserAdmin_1();
    return createElement("div", {
        style: {
            marginBottom: 10,
            borderRadius: 0,
            backgroundColor: "#FFFFFF",
            borderLeft: (((4 + "px ") + "solid") + " ") + ((matchValue = lot.LotStatus, (matchValue.tag === 1) ? "#808080" : ((matchValue.tag === 2) ? "#808080" : ((matchValue.tag === 3) ? "#19bb9c" : ((matchValue.tag === 4) ? "#f17288" : ((matchValue.tag === 5) ? "#000000" : ((matchValue.tag === 6) ? "#f17288" : ((matchValue.tag === 7) ? "#EEB24B" : ((matchValue.tag === 8) ? "#EEB24B" : ((matchValue.tag === 9) ? "#000000" : ((matchValue.tag === 10) ? "#000000" : ((matchValue.tag === 11) ? "#000000" : "#808080")))))))))))),
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let matchValue_1, props_27;
            return append(singleton_2(createElement("tr", {
                className: "auctionTable",
                children: Interop_reactApi.Children.toArray([createElement("td", createObj(toList(delay(() => append((lot.Image != null) ? singleton_2(["onClick", (_arg1) => {
                    setShowImage(!isShowImage);
                }]) : empty_1(), delay(() => append(singleton_2(["style", createObj(toList(delay(() => append(singleton_2(["width", 7 + "%"]), delay(() => append(singleton_2(["minWidth", 10]), delay(() => ((lot.Image != null) ? singleton_2(["cursor", "zoom-in"]) : empty_1()))))))))]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("img", createObj(toList(delay(() => append(singleton_2(["style", {
                    display: "flex",
                    maxHeight: 50,
                }]), delay(() => append(singleton_2(["className", "image-in-auctions-list"]), delay(() => singleton_2(["src", (lot.Image == null) ? Image_imagePlaceholder : Image_smallContains(value_231(lot.Image))])))))))))])]))))))))), createElement("td", {
                    onClick: (_arg2) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    className: "has-text-left",
                    children: ItemIdModule_toString(lot.ItemId),
                }), createElement("td", {
                    onClick: (_arg3) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    className: "has-text-left",
                    children: OrderNumberModule_toString(lot.OrderNumber),
                }), createElement("td", {
                    onClick: (_arg4) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    className: "has-text-left",
                    children: (matchValue_1 = (lot.Priority | 0), (matchValue_1 === 1) ? "2" : ((matchValue_1 === 2) ? "1" : "3")),
                }), createElement("td", createObj(toList(delay(() => append(singleton_2(["onClick", (_arg5) => {
                    setVisible(!isVisible);
                }]), delay(() => append(singleton_2(["style", {
                    width: 20 + "%",
                    maxWidth: 20,
                }]), delay(() => append(singleton_2(["className", "has-text-left"]), delay(() => append(singleton_2(["className", "is-text-overflow-hidden"]), delay(() => {
                    const x_2 = Localized_translate(lot.Title);
                    return singleton_2(["children", Localized_translate(lot.Title)]);
                })))))))))))), createElement("td", {
                    onClick: (_arg6) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                        maxWidth: 20,
                    },
                    children: Localized_translate(lot.Category.Name),
                    className: "is-text-overflow-hidden",
                }), createElement("td", {
                    onClick: (_arg7) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    className: "has-text-right",
                    children: lot.Bidders,
                }), createElement("td", {
                    onClick: (_arg8) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    className: "has-text-right",
                    children: lot.BidsCount,
                }), createElement("td", {
                    onClick: (_arg9) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 8 + "%",
                    },
                    className: "has-text-right",
                    children: Currency_formatN(CurrencyModule_fromInt(lot.CurrentBid)),
                }), createElement("td", {
                    onClick: (_arg10) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 8 + "%",
                    },
                    className: "has-text-right",
                    children: Currency_formatN(CurrencyModule_fromInt(lot.Estimate)),
                }), createElement("td", {
                    onClick: (_arg11) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 8 + "%",
                    },
                    className: "has-text-right",
                    children: Currency_formatNOption("-", map_1((arg) => CurrencyModule_fromInt(ReservedPriceModule_toInt(arg)), lot.ReservedPrice)),
                }), createElement("td", createObj(toList(delay(() => append(singleton_2(["onClick", (_arg12) => {
                    setVisible(!isVisible);
                }]), delay(() => append(singleton_2(["style", {
                    width: 8 + "%",
                    textAlign: "right",
                }]), delay(() => {
                    const matchValue_2 = lot.LotStatus;
                    switch (matchValue_2.tag) {
                        case 1: {
                            return append(singleton_2(["children", t("auction.lotStatus.biddingStarted")]), delay(() => singleton_2(["className", "has-text-grey"])));
                        }
                        case 2: {
                            return append(singleton_2(["children", t("auction.lotStatus.activeBidding")]), delay(() => singleton_2(["className", "has-text-grey"])));
                        }
                        case 3: {
                            return append(singleton_2(["children", t("auction.lotStatus.biddingFinishedSold")]), delay(() => singleton_2(["className", "has-text-primary"])));
                        }
                        case 4: {
                            return append(singleton_2(["children", t("auction.lotStatus.biddingFinishedUnsold")]), delay(() => singleton_2(["className", "has-text-danger"])));
                        }
                        case 5: {
                            return append(singleton_2(["children", t("auction.lotStatus.deleted")]), delay(() => singleton_2(["className", "has-text-black"])));
                        }
                        case 6: {
                            return append(singleton_2(["children", t("auction.lotStatus.withdrawn")]), delay(() => singleton_2(["className", "has-text-danger"])));
                        }
                        case 7: {
                            return append(singleton_2(["children", t("auction.lotStatus.claimed")]), delay(() => singleton_2(["className", "has-text-warning"])));
                        }
                        case 8: {
                            return append(singleton_2(["children", t("auction.lotStatus.refunded")]), delay(() => singleton_2(["className", "has-text-warning"])));
                        }
                        case 9: {
                            return append(singleton_2(["children", t("auction.lotStatus.recycled")]), delay(() => singleton_2(["className", "has-text-black"])));
                        }
                        case 10: {
                            return append(singleton_2(["children", t("auction.lotStatus.returned")]), delay(() => singleton_2(["className", "has-text-black"])));
                        }
                        case 11: {
                            return append(singleton_2(["children", t("auction.lotStatus.returnedToAuctionHouse")]), delay(() => singleton_2(["className", "has-text-black"])));
                        }
                        default: {
                            return append(singleton_2(["children", t("auction.lotStatus.created")]), delay(() => singleton_2(["className", "has-text-grey"])));
                        }
                    }
                })))))))), createElement("td", {
                    style: {
                        width: 7 + "%",
                    },
                    className: "has-text-centered",
                    onClick: (_arg13) => {
                        setVisible(!isVisible);
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let props_14, props_17, props_20, props_23;
                        const matchValue_3 = lot.LotStatus;
                        switch (matchValue_3.tag) {
                            case 1: {
                                return (equals(auctionStatus, new AuctionStatus(2)) ? (lot.BidsCount === 0) : false) ? singleton_2(createElement(CreateOrResellLotComponent, {
                                    itemId: lot.ItemId,
                                    disabled: false,
                                    auctionPageAndFilterState: auctionPageFilterState,
                                    updateAuctionStateAndOpenNewTab: updateAuctionPageStateAndOpenNewTab,
                                    actionType: new ActionType(3, lot.Id, lot.Category, lot.IdAuction),
                                })) : empty_1();
                            }
                            case 2:
                            case 3: {
                                return singleton_2(null);
                            }
                            case 5: {
                                return singleton_2(null);
                            }
                            case 4:
                            case 6:
                            case 7:
                            case 8: {
                                return (lot.ItemReservedForAuction == null) ? append(singleton_2(createElement(CreateOrResellLotComponent, {
                                    itemId: lot.ItemId,
                                    disabled: false,
                                    auctionPageAndFilterState: auctionPageFilterState,
                                    updateAuctionStateAndOpenNewTab: updateAuctionPageStateAndOpenNewTab,
                                    actionType: new ActionType(1, lot.Id, lot.Category),
                                })), delay(() => {
                                    const image_1 = (lot.Image == null) ? Image_imagePlaceholder : Image_medium(value_231(lot.Image));
                                    return singleton_2(createElement(ReturnOrRecycleComponent, {
                                        object: new ReturnRecycleObject(1, lot.ItemId, lot.OrderNumber, Localized_translate(lot.Title), image_1, false, false, auctionId, auctionPageFilterState),
                                    }));
                                })) : singleton_2((props_14 = ofArray([["onClick", (_arg14) => {
                                    setVisible(!isVisible);
                                }], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                                    className: join(" ", ["fa", "fa-check"]),
                                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_14)))));
                            }
                            case 9: {
                                return singleton_2((props_17 = ofArray([["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                                    className: join(" ", ["fa", "fa-times"]),
                                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_17)))));
                            }
                            case 10: {
                                return singleton_2((props_20 = ofArray([["className", "has-text-black"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                                    className: join(" ", ["fas", "fa-user-friends"]),
                                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_20)))));
                            }
                            case 11: {
                                return singleton_2((props_23 = ofArray([["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                                    className: join(" ", ["fa", "fa-times"]),
                                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_23)))));
                            }
                            default: {
                                return (equals(auctionStatus, new AuctionStatus(2)) ? (lot.BidsCount === 0) : false) ? singleton_2(createElement(CreateOrResellLotComponent, {
                                    itemId: lot.ItemId,
                                    disabled: false,
                                    auctionPageAndFilterState: auctionPageFilterState,
                                    updateAuctionStateAndOpenNewTab: updateAuctionPageStateAndOpenNewTab,
                                    actionType: new ActionType(3, lot.Id, lot.Category, lot.IdAuction),
                                })) : empty_1();
                            }
                        }
                    })))),
                }), createElement("td", {
                    style: {
                        width: 4 + "%",
                    },
                    className: "has-background-grey-lighter",
                    children: Interop_reactApi.Children.toArray([createElement("a", {
                        className: "edit_button_href_in_auction",
                        href: (auctionPageFilterState.tag === 1) ? toPath(new Route(20, new EditLotArg(1, auctionId, lot.Id, auctionPageFilterState.fields[1]))) : toPath(new Route(20, new EditLotArg(0, auctionId, lot.Id, auctionPageFilterState.fields[1]))),
                        children: Interop_reactApi.Children.toArray([(props_27 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fas", "fa-sign-in-alt"]),
                        })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_27))))]),
                    })]),
                })]),
            })), delay(() => append((((lot.BidsCount > 0) ? isVisible : false) ? isCurrentUserAdmin : false) ? singleton_2(createElement(BiddingHistory, {
                lotId: lot.Id,
                reservedPrice: lot.ReservedPrice,
            })) : empty_1(), delay(() => {
                let props_37;
                return singleton_2((props_37 = toList(delay(() => append(isShowImage ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => {
                    let elms_1, elms;
                    return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg15) => {
                        setShowImage(!isShowImage);
                    }])))), (elms_1 = singleton_1((elms = singleton_1(createElement("img", createObj(toList(delay(() => singleton_2(["src", (lot.Image == null) ? Image_imagePlaceholder : Image_originalImageUrl(value_231(lot.Image))])))))), createElement("div", {
                        className: "card-image",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))), createElement("div", {
                        className: "modal-content",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    }))])]);
                })))), createElement("div", createObj(Helpers_combineClasses("modal", props_37)))));
            }))));
        })))),
    });
}

export function Render(renderInputProps) {
    const auction = renderInputProps.auction;
    const auctionId = renderInputProps.auctionId;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(auctionId, renderInputProps.auctionState), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_63 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_56, props_8, elms, props_5, props, props_3, props_18, elms_1, props_15, props_10, props_13, props_28, elms_2, props_25, patternInput_1, props_37, props_35, props_46, props_44, props_54, props_52, props_49, s;
        return append(singleton_2((props_56 = ofArray([["className", "is-variable"], ["className", join(" ", ["is-2"])], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_5 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "searchInput"], ["placeholder", t("common.lotNumber")], ["onChange", (ev) => {
            dispatch(new Msg(6, ev.target.value));
        }], ["onKeyUp", (ev_1) => {
            dispatch(new Msg(7, ev_1.key));
        }], ["onInput", (_arg1) => {
            dispatch(new Msg(6, ""));
            dispatch(new Msg(7, "Escape"));
        }]]), createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", props))))), (props_3 = ofArray([["className", "is-left"], ["style", {
            height: 100 + "%",
        }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-search", "fa-lg"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))])]]), createElement("p", createObj(Helpers_combineClasses("control", props_5))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_18 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((props_15 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "searchInput"], ["placeholder", t("auction.searchTitle")], ["onChange", (ev_2) => {
            dispatch(new Msg(5, ev_2.target.value));
        }], ["onKeyUp", (ev_3) => {
            dispatch(new Msg(7, ev_3.key));
        }], ["onInput", (_arg2) => {
            dispatch(new Msg(5, ""));
            dispatch(new Msg(7, "Escape"));
        }]]), createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", props_10))))), (props_13 = ofArray([["className", "is-left"], ["style", {
            height: 100 + "%",
        }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-search", "fa-lg"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_13))))])]]), createElement("p", createObj(Helpers_combineClasses("control", props_15))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), (props_28 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_1((props_25 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "is-size-6"], ["className", "has-text-grey"], ["onChange", (ev_4) => {
            dispatch(new Msg(11, CategoryIdModule_fromString(ev_4.target.value)));
        }], ["value", CategoryIdModule_toString(state_1.Category)], ["children", Interop_reactApi.Children.toArray(Array.from(map_2((x_2) => createElement("option", {
            value: CategoryIdModule_toString(x_2.Id),
            children: Localized_translate(x_2.Name),
        }), state_1.Categories)))]])), createElement("div", {
            className: join(" ", cons("select", patternInput_1[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_25))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_28)))), (props_37 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_35 = toList(delay(() => {
            const matchValue = state_1.Statistic;
            let pattern_matching_result, st;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 2;
                    st = matchValue.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(["className", "is-loading"]);
                }
                case 1: {
                    return singleton_2(["children", "error"]);
                }
                case 2: {
                    const disable = st.InComplete === 0;
                    return append(singleton_2(["disabled", disable]), delay(() => append(singleton_2(["className", "is-danger"]), delay(() => append((!state_1.Query.Payload.ByInComplete) ? singleton_2(["className", "is-light"]) : empty_1(), delay(() => append((!disable) ? singleton_2(["onClick", (_arg3) => {
                        dispatch(new Msg(10));
                    }]) : empty_1(), delay(() => {
                        let props_31, props_33;
                        return singleton_2(["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "is-medium"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-lock"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_31)))), (props_33 = singleton_1(["children", ((t("auction.requiresCompletion") + " ( ") + int32ToString(st.InComplete)) + " )"]), createElement("span", createObj(Helpers_combineClasses("", props_33))))])]);
                    }))))))));
                }
            }
        })), createElement("a", createObj(Helpers_combineClasses("button", props_35))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_37)))), (props_46 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_44 = toList(delay(() => {
            const matchValue_1 = state_1.Statistic;
            let pattern_matching_result_1, st_1;
            if (matchValue_1.tag === 1) {
                pattern_matching_result_1 = 0;
            }
            else if (matchValue_1.tag === 2) {
                if (matchValue_1.fields[0].tag === 0) {
                    pattern_matching_result_1 = 2;
                    st_1 = matchValue_1.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 0;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return singleton_2(["className", "is-loading"]);
                }
                case 1: {
                    return singleton_2(["children", "error"]);
                }
                case 2: {
                    const disable_1 = !equals(auction.Status, new AuctionStatus(0));
                    return append(singleton_2(["disabled", disable_1]), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append((!state_1.Query.Payload.ByUnSold) ? singleton_2(["className", "is-light"]) : empty_1(), delay(() => append((!disable_1) ? singleton_2(["onClick", (_arg4) => {
                        dispatch(new Msg(9));
                    }]) : empty_1(), delay(() => {
                        let props_40, props_42;
                        return singleton_2(["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["className", "is-medium"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-dollar-sign"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_40)))), (props_42 = toList(delay(() => singleton_2(["children", ((t("auction.resale") + " ( ") + int32ToString(st_1.UnSold)) + " )"]))), createElement("span", createObj(Helpers_combineClasses("", props_42))))])]);
                    }))))))));
                }
            }
        })), createElement("a", createObj(Helpers_combineClasses("button", props_44))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_46)))), (props_54 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_52 = ofArray([["className", "is-primary"], ["disabled", equals(auction.Status, new AuctionStatus(0))], ["onClick", (_arg5) => {
            navigate(toPath(new Route(5, state_1.AuctionId, void 0, void 0, void 0)));
        }], ["children", Interop_reactApi.Children.toArray([(props_49 = ofArray([["className", "has-text-white"], ["className", "is-medium"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-plus"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_49)))), (s = t("auction.addLot"), createElement("span", {
            className: "",
            children: s,
        }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_52))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_54))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_56))))), delay(() => append(singleton_2(tableHeader(state_1, dispatch)), delay(() => {
            let props_59;
            const matchValue_2 = state_1.Lots;
            let pattern_matching_result_2, ex, lots;
            if (matchValue_2.tag === 1) {
                pattern_matching_result_2 = 0;
            }
            else if (matchValue_2.tag === 2) {
                if (matchValue_2.fields[0].tag === 0) {
                    pattern_matching_result_2 = 2;
                    lots = matchValue_2.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_2 = 1;
                    ex = matchValue_2.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result_2 = 0;
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    return singleton_2(loader("small"));
                }
                case 1: {
                    return singleton_2(createElement("h1", {
                        children: [ex],
                    }));
                }
                case 2: {
                    return append(isEmpty(lots.Result) ? singleton_2((props_59 = ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", t("auction.addLot")]]), createElement("p", createObj(Helpers_combineClasses("", props_59))))) : empty_1(), delay(() => {
                        let props_61;
                        return append(singleton_2((props_61 = ofArray([["className", "is-fullwidth"], ["className", "has-background-light"], ["style", {
                            tableLayout: "fixed",
                        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map((lot) => createElement(TableLine, {
                            lot: lot,
                            auctionId: state_1.AuctionId,
                            auctionPageFilterState: new AuctionArg(0, auctionId, new AuctionState(state_1.Query.Payload.ByUnSold, state_1.Query.Payload.ByInComplete, state_1.CurrentPage)),
                            updateAuctionPageStateAndOpenNewTab: (url) => {
                                dispatch(new Msg(3, url));
                            },
                            auctionStatus: auction.Status,
                        }), lots.Result)))))]]), createElement("table", createObj(Helpers_combineClasses("table", props_61))))), delay(() => singleton_2(renderPagination(lots.Page, lots.Total, lots.PageSize, (arg_4) => {
                            dispatch(new Msg(2, arg_4));
                        }))));
                    }));
                }
            }
        }))));
    }))))]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_63)));
}

