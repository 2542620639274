import { Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, class_type, union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { PayOuts_PayoutPageView, PayOuts_PayoutPageView$reflection, PayOuts_PayoutStatus$reflection } from "../Shared/Shared.js";
import { Route, navigateTo, PayOutArg, PayOutArg$reflection } from "../Router.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "../Localization.js";
import { createElement } from "react";
import { ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { View_View as View_View_1 } from "./Payout.js";
import { View_ViewWithAuctions } from "./SelectSubmissionsV1.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { auctionApi } from "../Communication.js";
import { loaderBig } from "../Loader.js";

export class Types_Tab extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Auction", "Payout"];
    }
}

export function Types_Tab$reflection() {
    return union_type("Client.Payout.PayoutAndSelectSubmission.Types.Tab", [], Types_Tab, () => [[], []]);
}

export class Types_State extends Record {
    constructor(PayoutId, AuctionId, Status, PayoutState, PageView, Tab) {
        super();
        this.PayoutId = PayoutId;
        this.AuctionId = AuctionId;
        this.Status = Status;
        this.PayoutState = PayoutState;
        this.PageView = PageView;
        this.Tab = Tab;
    }
}

export function Types_State$reflection() {
    return record_type("Client.Payout.PayoutAndSelectSubmission.Types.State", [], Types_State, () => [["PayoutId", class_type("System.Int64")], ["AuctionId", class_type("System.Int64")], ["Status", PayOuts_PayoutStatus$reflection()], ["PayoutState", PayOutArg$reflection()], ["PageView", PayOuts_PayoutPageView$reflection()], ["Tab", Types_Tab$reflection()]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TabChanged", "AuctionIdChanged"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.Payout.PayoutAndSelectSubmission.Types.Msg", [], Types_Msg, () => [[["Item", Types_Tab$reflection()]], [["Item", class_type("System.Int64")]]]);
}

export function State_init(payoutState) {
    return [new Types_State((payoutState.tag === 1) ? payoutState.fields[0] : payoutState.fields[0], (payoutState.tag === 1) ? payoutState.fields[1] : payoutState.fields[1], (payoutState.tag === 1) ? payoutState.fields[2] : payoutState.fields[2], payoutState, (payoutState.tag === 1) ? (new PayOuts_PayoutPageView(0)) : payoutState.fields[3], new Types_Tab(0)), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        return [new Types_State(state.PayoutId, msg.fields[0], state.Status, state.PayoutState, state.PageView, state.Tab), Cmd_none()];
    }
    else {
        const tab = msg.fields[0];
        const payoutState = (tab.tag === 1) ? (new PayOutArg(1, state.PayoutId, state.AuctionId, state.Status)) : (new PayOutArg(0, state.PayoutId, state.AuctionId, state.Status, state.PageView));
        return [new Types_State(state.PayoutId, state.AuctionId, state.Status, payoutState, state.PageView, tab), navigateTo(new Route(52, payoutState))];
    }
}

export function View_View(view_ViewInputProps) {
    const auctions = view_ViewInputProps.auctions;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(view_ViewInputProps.payoutState), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const elms = toList(delay(() => {
        let props_1, s;
        return append(singleton((props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("reports.payout"), createElement("h1", {
            className: "title",
            children: s,
        }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1))))), delay(() => {
            let props_10, children, props_4, props_7;
            return append(singleton((props_10 = ofArray([["className", "is-centered"], ["className", "is-toggle"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_4 = toList(delay(() => append(equals(state_1.Tab, new Types_Tab(0)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("reports.notPaidOut"),
                onClick: (_arg1) => {
                    dispatch(new Types_Msg(0, new Types_Tab(0)));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_7 = toList(delay(() => append(equals(state_1.Tab, new Types_Tab(1)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("reports.payout"),
                onClick: (_arg2) => {
                    dispatch(new Types_Msg(0, new Types_Tab(1)));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7))))]), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_10))))), delay(() => ((state_1.Tab.tag === 1) ? singleton(createElement(View_View_1, {
                payoutId: state_1.PayoutId,
                payoutStatus: state_1.Status,
            })) : singleton(createElement(View_ViewWithAuctions, {
                payoutId: state_1.PayoutId,
                auctionId: state_1.AuctionId,
                pageView: state_1.PageView,
                auctions: auctions,
                changeAuctionId: (x) => {
                    dispatch(new Types_Msg(1, x));
                },
            })))));
        }));
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function View_RenderView(view_RenderViewInputProps) {
    const payoutState = view_RenderViewInputProps.payoutState;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(auctionApi().getAllAuctions()), []);
    const status = (payoutState.tag === 1) ? payoutState.fields[2] : payoutState.fields[2];
    const id_2 = (payoutState.tag === 1) ? payoutState.fields[0] : payoutState.fields[0];
    if (status.tag === 1) {
        switch (data.tag) {
            case 1: {
                return loaderBig();
            }
            case 3: {
                const value = data.fields[0].message;
                return createElement("div", {
                    children: [value],
                });
            }
            case 2: {
                return createElement(View_View, {
                    payoutState: payoutState,
                    auctions: data.fields[0],
                });
            }
            default: {
                return loaderBig();
            }
        }
    }
    else {
        return createElement(View_View_1, {
            payoutId: id_2,
            payoutStatus: status,
        });
    }
}

