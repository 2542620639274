import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { User_AccountType, User_AccountType$reflection } from "./Shared/Shared.js";
import { union_type, record_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { isCurrentUserAdmin as isCurrentUserAdmin_1 } from "./LocalStorage.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { createElement } from "react";
import { t } from "./Localization.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Render } from "./Users/RegisterCompany.js";
import { Render as Render_1 } from "./Users/RegisterAdmin.js";
import { Render as Render_2 } from "./Users/RegisterManager.js";
import { render } from "./Users/RegisterPerson.js";

export class State extends Record {
    constructor(Form) {
        super();
        this.Form = Form;
    }
}

export function State$reflection() {
    return record_type("SingUp.State", [], State, () => [["Form", User_AccountType$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeTypeAccount"];
    }
}

export function Msg$reflection() {
    return union_type("SingUp.Msg", [], Msg, () => [[["Item", User_AccountType$reflection()]]]);
}

export function init() {
    return [new State(new User_AccountType(0)), Cmd_none()];
}

export function update(msg, state) {
    return [new State(msg.fields[0]), Cmd_none()];
}

export function SignUpComponent() {
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(init, (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isCurrentUserAdmin = isCurrentUserAdmin_1();
    const props_15 = ofArray([["className", "pt-6"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_13, children;
        return append(singleton((props_13 = ofArray([["className", "is-uppercase"], ["className", "is-toggle"], ["className", "is-centered"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([(children = toList(delay(() => {
            let props_1;
            return append(singleton((props_1 = toList(delay(() => append(equals(state_1.Form, new User_AccountType(0)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("users.personAccount"),
                onClick: (_arg1) => {
                    dispatch(new Msg(0, new User_AccountType(0)));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_1))))), delay(() => {
                let props_4;
                return append(singleton((props_4 = toList(delay(() => append(equals(state_1.Form, new User_AccountType(1)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "is-normal-padding",
                    children: t("users.companyAccount"),
                    onClick: (_arg2) => {
                        dispatch(new Msg(0, new User_AccountType(1)));
                    },
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4))))), delay(() => {
                    let props_7;
                    return isCurrentUserAdmin ? append(singleton((props_7 = toList(delay(() => append(equals(state_1.Form, new User_AccountType(2)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                        className: "is-normal-padding",
                        children: t("users.adminAccount"),
                        onClick: (_arg3) => {
                            dispatch(new Msg(0, new User_AccountType(2)));
                        },
                    })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7))))), delay(() => {
                        let props_10;
                        return singleton((props_10 = toList(delay(() => append(equals(state_1.Form, new User_AccountType(3)) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("users.employeeAccount"),
                            onClick: (_arg4) => {
                                dispatch(new Msg(0, new User_AccountType(3)));
                            },
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_10)))));
                    })) : empty();
                }));
            }));
        })), createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_13))))), delay(() => {
            const matchValue = state_1.Form;
            switch (matchValue.tag) {
                case 1: {
                    return singleton(createElement(Render, null));
                }
                case 2: {
                    return singleton(createElement(Render_1, null));
                }
                case 3: {
                    return singleton(createElement(Render_2, null));
                }
                default: {
                    return singleton(render());
                }
            }
        }));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_15)));
}

