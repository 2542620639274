import { FSharpRef, Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { int32_type, record_type, union_type, unit_type, bool_type, list_type, string_type, class_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Category_get_Empty, Category, SaveCategories, Category$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52, Deferred$1, Deferred$1$reflection } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { auctionApi } from "./Communication.js";
import { getSupportedLanguages, Localized_translateTo, t, getPrimaryLanguage } from "./Localization.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { scan, zip, sumBy, cons, ofArray, singleton as singleton_1, append, findIndex, splitAt, max, filter, tryFindIndex, map } from "./fable_modules/fable-library.3.6.1/List.js";
import { LangModule_toString, LocalizedStringModule_setTranslation } from "./bidflow/src/infrastructure/Localization.js";
import { toArray, map as map_1, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { List_swapToRight, List_swapToLeft } from "./bidflow/src/infrastructure/Extensions.js";
import { AuctionIdModule_zero, CategoryIdModule_next } from "./bidflow/src/infrastructure/Auction.js";
import { stringHash, equals, createObj, comparePrimitives } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { tryParse } from "./fable_modules/fable-library.3.6.1/Int32.js";
import { collect, map as map_2, iterate, empty, singleton as singleton_2, append as append_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { List_distinct } from "./fable_modules/fable-library.3.6.1/Seq2.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { equals as equals_1 } from "./fable_modules/fable-library.3.6.1/Long.js";
import { toLocalTime } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { sv } from "date-fns/locale";
import { format as format_2 } from "date-fns";
import { AuctionArg, AuctionState, Route, toPath } from "./Router.js";
import { backButtonHref } from "./Components/BackButton.js";
import { isCurrentUserAdmin } from "./LocalStorage.js";
import { loaderBig } from "./Loader.js";

export class State extends Record {
    constructor(AuctionId, ContentLanguage, Categories, OriginalCategories, IsEditMode, SaveOperation) {
        super();
        this.AuctionId = AuctionId;
        this.ContentLanguage = ContentLanguage;
        this.Categories = Categories;
        this.OriginalCategories = OriginalCategories;
        this.IsEditMode = IsEditMode;
        this.SaveOperation = SaveOperation;
    }
}

export function State$reflection() {
    return record_type("GlobalCategories.State", [], State, () => [["AuctionId", class_type("System.Int64")], ["ContentLanguage", string_type], ["Categories", list_type(Category$reflection())], ["OriginalCategories", list_type(Category$reflection())], ["IsEditMode", bool_type], ["SaveOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnContentLanguageChanged", "OnNameChanged", "OnCapacityChanged", "OnCategoryMovedUp", "OnCategoryMovedDown", "OnCategoryDeleted", "OnCategoryAddAbove", "SwitchToEditMode", "CancelEditMode", "SaveCategories"];
    }
}

export function Msg$reflection() {
    return union_type("GlobalCategories.Msg", [], Msg, () => [[["Item", string_type]], [["Item1", int32_type], ["Item2", string_type], ["Item3", string_type]], [["Item1", int32_type], ["Item2", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function saveCategories(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const args = new SaveCategories(state.AuctionId, state.Categories);
        return singleton.Bind(Remoting_handleNonAuth(auctionApi().saveCategories(args)), () => singleton.Return(new Msg(9, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))));
    }), (_arg2) => singleton.Return(new Msg(9, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(auctionId, categories) {
    return [new State(auctionId, getPrimaryLanguage(), categories, categories, false, new Deferred$1(0)), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [state.IsEditMode ? (new State(state.AuctionId, state.ContentLanguage, map((category) => {
            if (category.Id === msg.fields[0]) {
                return new Category(category.Id, LocalizedStringModule_setTranslation(msg.fields[2], msg.fields[1], category.Name), category.Capacity, category.Used, category.WarehouseFee, category.NextOrderNumber);
            }
            else {
                return category;
            }
        }, state.Categories), state.OriginalCategories, state.IsEditMode, state.SaveOperation)) : state, Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [state.IsEditMode ? (new State(state.AuctionId, state.ContentLanguage, map((x) => {
            if (x.Id === msg.fields[0]) {
                return new Category(x.Id, x.Name, msg.fields[1], x.Used, x.WarehouseFee, x.NextOrderNumber);
            }
            else {
                return x;
            }
        }, state.Categories), state.OriginalCategories, state.IsEditMode, state.SaveOperation)) : state, Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [state.IsEditMode ? defaultArg(map_1((idx) => (new State(state.AuctionId, state.ContentLanguage, List_swapToLeft(idx)(state.Categories), state.OriginalCategories, state.IsEditMode, state.SaveOperation)), tryFindIndex((x_1) => (x_1.Id === msg.fields[0]), state.Categories)), state) : state, Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [state.IsEditMode ? defaultArg(map_1((idx_1) => (new State(state.AuctionId, state.ContentLanguage, List_swapToRight(idx_1)(state.Categories), state.OriginalCategories, state.IsEditMode, state.SaveOperation)), tryFindIndex((x_2) => (x_2.Id === msg.fields[0]), state.Categories)), state) : state, Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [state.IsEditMode ? (new State(state.AuctionId, state.ContentLanguage, filter((x_3) => (x_3.Id !== msg.fields[0]), state.Categories), state.OriginalCategories, state.IsEditMode, state.SaveOperation)) : state, Cmd_none()];
    }
    else if (msg.tag === 6) {
        const nextAvailableCategoryId = CategoryIdModule_next(max(map((x_4) => x_4.Id, state.Categories), {
            Compare: (x_5, y) => comparePrimitives(x_5, y),
        })) | 0;
        let newCategory;
        const inputRecord = Category_get_Empty();
        newCategory = (new Category(nextAvailableCategoryId, inputRecord.Name, 100, inputRecord.Used, inputRecord.WarehouseFee, inputRecord.NextOrderNumber));
        const patternInput = splitAt(findIndex((x_7) => (x_7.Id === msg.fields[0]), state.Categories), state.Categories);
        return [new State(state.AuctionId, state.ContentLanguage, append(patternInput[0], append(singleton_1(newCategory), patternInput[1])), state.OriginalCategories, state.IsEditMode, state.SaveOperation), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new State(state.AuctionId, state.ContentLanguage, state.OriginalCategories, state.OriginalCategories, true, state.SaveOperation), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [new State(state.AuctionId, state.ContentLanguage, state.OriginalCategories, state.OriginalCategories, false, new Deferred$1(0)), Cmd_none()];
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.AuctionId, state.ContentLanguage, state.Categories, state.OriginalCategories, state.IsEditMode, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new State(state.AuctionId, state.ContentLanguage, state.Categories, state.Categories, false, new Deferred$1(2, new FSharpResult$2(0, void 0))), Cmd_none()];
            }
        }
        else {
            return [new State(state.AuctionId, state.ContentLanguage, state.Categories, state.OriginalCategories, state.IsEditMode, new Deferred$1(1)), Cmd_fromAsync(saveCategories(state))];
        }
    }
    else {
        return [new State(state.AuctionId, msg.fields[0], state.Categories, state.OriginalCategories, state.IsEditMode, state.SaveOperation), Cmd_none()];
    }
}

export const tableHeader = (() => {
    let props_10, props, props_2, props_4, props_6;
    const props_12 = ofArray([["className", "my-4"], ["className", "is-fluid"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-vcentered"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-1"], ["className", "has-text-left"], ["children", t("auction.startNumber")]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-2"], ["children", t("auction.capacity")], ["className", "has-text-left"]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "is-5"], ["className", "has-text-left"], ["children", t("common.title")]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-1"], ["className", "px-0"], ["children", t("auction.warehouseFee")]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-3"], ["className", "px-0"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_12)));
})();

export function Int_tryParse(s) {
    let patternInput;
    let outArg = 0;
    patternInput = [tryParse(s, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (patternInput[0]) {
        return patternInput[1];
    }
    else {
        return void 0;
    }
}

export function renderCategory(startNumber, category, state, dispatch) {
    let props_42, props_2, elms, props_7, elms_1, props_4, props_12, elms_2, props_9, props_16, elms_3, value_58, props_40;
    const disabledInput = ["style", {
        borderStyle: "none",
        paddingLeft: 0,
        backgroundColor: "#FFFFFF",
    }];
    const isNotEdit = !state.IsEditMode;
    const props_44 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
        borderRadius: 4,
    }], ["className", "is-size-6"], ["className", "is-fluid"], ["className", "has-background-white"], ["children", Interop_reactApi.Children.toArray([(props_42 = ofArray([["className", "is-mobile"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("div", {
        children: [startNumber],
    })), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_7 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((props_4 = toList(delay(() => append_1(isNotEdit ? singleton_2(disabledInput) : empty(), delay(() => append_1(singleton_2(["disabled", isNotEdit]), delay(() => {
        let value_32;
        return append_1(singleton_2((value_32 = (category.Capacity | 0), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_32)) : false) {
                e.value = (value_32 | 0);
            }
        }])), delay(() => singleton_2(["onChange", (ev) => {
            iterate((x) => {
                dispatch(new Msg(2, category.Id, x));
            }, toArray(Int_tryParse(ev.target.value)));
        }])));
    })))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7)))), (props_12 = ofArray([["className", "is-5"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_1((props_9 = toList(delay(() => append_1(isNotEdit ? singleton_2(disabledInput) : empty(), delay(() => append_1(singleton_2(["disabled", isNotEdit]), delay(() => {
        let value_46;
        return append_1(singleton_2((value_46 = Localized_translateTo(state.ContentLanguage, category.Name), ["ref", (e_1) => {
            if ((!(e_1 == null)) ? (!equals(e_1.value, value_46)) : false) {
                e_1.value = value_46;
            }
        }])), delay(() => singleton_2(["onChange", (ev_1) => {
            dispatch(new Msg(1, category.Id, ev_1.target.value, state.ContentLanguage));
        }])));
    })))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_9)))))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_16 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_1((value_58 = (`${category.WarehouseFee}`), createElement("div", {
        children: [value_58],
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_40 = ofArray([["className", "is-3"], ["className", "has-text-right"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_38, props_21, props_19, props_26, props_24, props_31, props_29, props_36, props_34;
        return state.IsEditMode ? singleton_2((props_38 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "is-rounded"], ["onClick", (_arg1) => {
            dispatch(new Msg(6, category.Id));
        }], ["children", Interop_reactApi.Children.toArray([(props_19 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fas", "fa-plus"]),
        })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_19))))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_21)))), (props_26 = ofArray([["className", "is-rounded"], ["className", "is-danger"], ["onClick", (_arg2) => {
            dispatch(new Msg(5, category.Id));
        }], ["children", Interop_reactApi.Children.toArray([(props_24 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fas", "fa-times"]),
        })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_24))))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_26)))), (props_31 = ofArray([["className", "has-text-primary"], ["style", {
            borderStyle: "none",
            padding: ((5 + "px ") + 15) + "px",
        }], ["onClick", (_arg3) => {
            dispatch(new Msg(3, category.Id));
        }], ["children", Interop_reactApi.Children.toArray([(props_29 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-arrow-up"]),
        })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_29))))])]]), createElement("a", createObj(Helpers_combineClasses("button", props_31)))), (props_36 = ofArray([["className", "has-text-primary"], ["style", {
            borderStyle: "none",
            padding: 5,
        }], ["onClick", (_arg4) => {
            dispatch(new Msg(4, category.Id));
        }], ["children", Interop_reactApi.Children.toArray([(props_34 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-arrow-down"]),
        })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_34))))])]]), createElement("a", createObj(Helpers_combineClasses("button", props_36))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_38))))) : empty();
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_40))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_42))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_44)));
}

export function renderContentLanguageSwitcher(state, dispatch) {
    let children;
    const props_4 = ofArray([["className", "is-uppercase"], ["className", "is-toggle"], ["children", Interop_reactApi.Children.toArray([(children = toList(delay(() => map_2((lang) => {
        const props_1 = toList(delay(() => append_1((lang === state.ContentLanguage) ? singleton_2(["className", "is-active"]) : empty(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: "is-small-padding",
            onClick: (_arg1) => {
                dispatch(new Msg(0, lang));
            },
            children: LangModule_toString(lang),
        })])])))));
        return createElement("li", createObj(Helpers_combineClasses("", props_1)));
    }, List_distinct(cons(getPrimaryLanguage(), getSupportedLanguages()), {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })))), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("tabs", props_4)));
}

export function EditCategories(editCategoriesInputProps) {
    let props_2, props, props_33, props_31, props_13, props_15, props_29, props_27, props_38;
    const auctionDate = editCategoriesInputProps.auctionDate;
    const auctionId = editCategoriesInputProps.auctionId;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(auctionId, editCategoriesInputProps.categories), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isDisabledToEditCatalog = sumBy((x) => x.Used, state_1.Categories, {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) > 0;
    const props_40 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(props = toList(delay(() => singleton_2(["children", equals_1(auctionId, AuctionIdModule_zero) ? t("auction.categoriesSettings") : t("common.categories")]))), createElement("h1", createObj(Helpers_combineClasses("title", props))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_2)))), (props_33 = ofArray([["className", "pt-0"], ["className", "pb-2"], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-5"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_11, props_4, props_7, props_9;
        return equals_1(auctionId, AuctionIdModule_zero) ? singleton_2(null) : singleton_2((props_11 = ofArray([["className", "is-size-4"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => {
            let date, locale, formatOptions;
            return singleton_2(["children", (date = toLocalTime(auctionDate), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_2(date, "dd MMMM yyyy", formatOptions)))))]);
        })), createElement("span", createObj(Helpers_combineClasses("", props_4)))), (props_7 = ofArray([["className", "has-text-grey-light"], ["className", "is-size-4"], ["className", "mx-4"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["far", "fa-clock"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_7)))), (props_9 = toList(delay(() => {
            let date_2, locale_1, formatOptions_1;
            return singleton_2(["children", (date_2 = toLocalTime(auctionDate), (locale_1 = sv, (formatOptions_1 = {}, (formatOptions_1.locale = locale_1, format_2(date_2, "HH:mm", formatOptions_1)))))]);
        })), createElement("span", createObj(Helpers_combineClasses("", props_9))))])]]), createElement("h1", createObj(Helpers_combineClasses("title", props_11)))));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_15 = singleton_1(["children", Interop_reactApi.Children.toArray([renderContentLanguageSwitcher(state_1, dispatch)])]), createElement("div", createObj(Helpers_combineClasses("column", props_15)))), (props_29 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_27 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_17, props_21;
        return state_1.IsEditMode ? append_1(singleton_2((props_17 = ofArray([["children", t("common.cancel")], ["onClick", (_arg1) => {
            dispatch(new Msg(8));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_17))))), delay(() => {
            let props_19;
            return singleton_2((props_19 = ofArray([["children", t("common.save")], ["className", "is-primary"], ["onClick", (_arg2) => {
                dispatch(new Msg(9, new AsyncOperationStatus$1(0)));
            }]]), createElement("button", createObj(Helpers_combineClasses("button", props_19)))));
        })) : append_1(singleton_2((props_21 = ofArray([["children", t("auction.editWarehouseFee")], ["className", "has-background-grey-lighter"], ["href", toPath(new Route(18, state_1.AuctionId))]]), createElement("a", createObj(Helpers_combineClasses("button", props_21))))), delay(() => {
            let props_25;
            return append_1(singleton_2((props_25 = toList(delay(() => append_1(isDisabledToEditCatalog ? append_1(singleton_2(["data-tooltip", t("auction.editCategoriesWarning")]), delay(() => append_1(singleton_2(["className", "has-tooltip-warning"]), delay(() => singleton_2(["className", "has-tooltip-info"]))))) : empty(), delay(() => {
                let props_23;
                return singleton_2(["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["children", t("common.edit")], ["className", "has-background-grey-lighter"], ["disabled", isDisabledToEditCatalog], ["onClick", (_arg3) => {
                    dispatch(new Msg(7));
                }]]), createElement("button", createObj(Helpers_combineClasses("button", props_23))))])]);
            })))), createElement("div", createObj(Helpers_combineClasses("control", props_25))))), delay(() => ((!equals_1(auctionId, AuctionIdModule_zero)) ? singleton_2(backButtonHref(toPath(new Route(2, new AuctionArg(0, state_1.AuctionId, new AuctionState(false, false, 1)))), t("auction.backToAuction"))) : empty())));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_27))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_29))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_31))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_33)))), (props_38 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let error;
        const matchValue = state_1.SaveOperation;
        let pattern_matching_result, x_2, x_3;
        if (matchValue.tag === 1) {
            pattern_matching_result = 0;
        }
        else if (matchValue.tag === 2) {
            if (matchValue.fields[0].tag === 1) {
                pattern_matching_result = 2;
                x_3 = matchValue.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 0;
            }
        }
        else if (matchValue.tag === 3) {
            pattern_matching_result = 1;
            x_2 = matchValue.fields[0];
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                error = "";
                break;
            }
            case 1: {
                error = x_2.message;
                break;
            }
            case 2: {
                error = x_3;
                break;
            }
        }
        return append_1(singleton_2(createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "is-danger"], ["className", "has-text-right"], ["children", error]]))))), delay(() => append_1(singleton_2(tableHeader), delay(() => {
            let children;
            const categoriesWithStart = zip(scan((idx, x_4) => (x_4.Capacity + idx), 1, state_1.Categories), state_1.Categories);
            return singleton_2((children = toList(delay(() => collect((matchValue_1) => singleton_2(renderCategory(matchValue_1[0], matchValue_1[1], state_1, dispatch)), categoriesWithStart))), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })));
        }))));
    }))))]]), createElement("section", createObj(Helpers_combineClasses("section", props_38))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_40)));
}

export function Render(renderInputProps) {
    const auctionId = renderInputProps.auctionId;
    if (isCurrentUserAdmin()) {
        const data = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(auctionApi().getCategories(auctionId)), []);
        switch (data.tag) {
            case 1: {
                return loaderBig();
            }
            case 3: {
                const value = data.fields[0].message;
                return createElement("div", {
                    children: [value],
                });
            }
            case 2: {
                const data_1 = data.fields[0];
                return createElement(EditCategories, {
                    auctionId: auctionId,
                    auctionDate: data_1.AuctionStartDate,
                    categories: data_1.Categories,
                });
            }
            default: {
                return null;
            }
        }
    }
    else {
        return null;
    }
}

export function renderSettings() {
    return createElement(Render, {
        auctionId: AuctionIdModule_zero,
    });
}

