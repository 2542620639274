import { toString, Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { class_type, unit_type, union_type, list_type, bool_type, option_type, record_type, string_type, int32_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { tWithArgs, Localized_formatDateTime, Localized_translate, getLocalizationContext, t } from "../Localization.js";
import { cons, sortBy as sortBy_2, isEmpty, tryFind, singleton as singleton_1, sortByDescending, map, append, head, empty, filter, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { Presales_Presale, SortAuction, Presales_PresaleReportFilter, AuctionStatus, AuctionsEntity$reflection, Presales_Presale$reflection, Presales_PresaleReportFilter$reflection, SortAuction$reflection, Presales_PresaleReportStatus, Presales_PresaleReportStatus$reflection } from "../Shared/Shared.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingResult$1, Common_PagingQuery$1, Common_PagingResult$1$reflection, Common_PagingQuery$1$reflection } from "../Shared/Common.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Deferred$1, Deferred$1$reflection } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { presaleApi, auctionApi } from "../Communication.js";
import { comparePrimitives, createObj, equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { SubmissionIdModule_toString, UserIdModule_toString, CurrencyModule_zero, ItemIdModule_toString, OrderNumberModule_toString, AuctionIdModule_fromString, AuctionIdModule_toString, AuctionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { compare } from "../fable_modules/fable-library.3.6.1/Date.js";
import { defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, map as map_1, append as append_1, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { equals as equals_2 } from "../fable_modules/fable-library.3.6.1/Decimal.js";
import { Currency_format } from "../Common.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loaderBig } from "../Loader.js";
import { renderPagination } from "../Components/Pagination.js";

export class SentByPostStatus extends Record {
    constructor(Id, NameStatus) {
        super();
        this.Id = (Id | 0);
        this.NameStatus = NameStatus;
    }
}

export function SentByPostStatus$reflection() {
    return record_type("PresaleReport.SentByPostStatus", [], SentByPostStatus, () => [["Id", int32_type], ["NameStatus", string_type]]);
}

export const listChangeStatus = ofArray([new SentByPostStatus(1, t("reports.postSent")), new SentByPostStatus(2, t("reports.unsent"))]);

export class ToggleStatus extends Record {
    constructor(Status, IsCheck) {
        super();
        this.Status = Status;
        this.IsCheck = IsCheck;
    }
}

export function ToggleStatus$reflection() {
    return record_type("PresaleReport.ToggleStatus", [], ToggleStatus, () => [["Status", option_type(Presales_PresaleReportStatus$reflection())], ["IsCheck", bool_type]]);
}

export const toggleStatuses = ofArray([new ToggleStatus(void 0, true), new ToggleStatus(new Presales_PresaleReportStatus(1), false), new ToggleStatus(new Presales_PresaleReportStatus(2), false), new ToggleStatus(new Presales_PresaleReportStatus(0), false)]);

export class State extends Record {
    constructor(Auction, AllAuctions, Query, Presales, CurrentPage, FilterStatuses, Processing, ListChangeStatus, ChoosingStatus, SearchString) {
        super();
        this.Auction = Auction;
        this.AllAuctions = AllAuctions;
        this.Query = Query;
        this.Presales = Presales;
        this.CurrentPage = (CurrentPage | 0);
        this.FilterStatuses = FilterStatuses;
        this.Processing = Processing;
        this.ListChangeStatus = ListChangeStatus;
        this.ChoosingStatus = ChoosingStatus;
        this.SearchString = SearchString;
    }
}

export function State$reflection() {
    return record_type("PresaleReport.State", [], State, () => [["Auction", SortAuction$reflection()], ["AllAuctions", list_type(SortAuction$reflection())], ["Query", Common_PagingQuery$1$reflection(Presales_PresaleReportFilter$reflection())], ["Presales", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(Presales_Presale$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(Presales_Presale$reflection())]], [["ErrorValue", string_type]]]))], ["CurrentPage", int32_type], ["FilterStatuses", list_type(ToggleStatus$reflection())], ["Processing", Deferred$1$reflection(unit_type)], ["ListChangeStatus", list_type(SentByPostStatus$reflection())], ["ChoosingStatus", SentByPostStatus$reflection()], ["SearchString", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeAuction", "LoadAuctions", "LoadPresales", "PageChanged", "StatusChange", "MarkAsSentByPost", "UnMarkAsSentByPost", "ChangeStatusPost", "OnColumnClicked", "SearchStringChange", "KeyboardEvent"];
    }
}

export function Msg$reflection() {
    return union_type("PresaleReport.Msg", [], Msg, () => [[["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AuctionsEntity$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(AuctionsEntity$reflection())]], [["ErrorValue", string_type]]]))]], [["Item1", Common_PagingQuery$1$reflection(Presales_PresaleReportFilter$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(Presales_Presale$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(Presales_Presale$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]], [["Item", ToggleStatus$reflection()]], [["Item1", class_type("System.Guid")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item1", class_type("System.Guid")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export const Cmd_loadAuctions = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAllAuctions()), (_arg1) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, filter((x) => {
    if (equals(x.Status, new AuctionStatus(0)) ? true : equals(x.Status, new AuctionStatus(2))) {
        return true;
    }
    else {
        return equals(x.Status, new AuctionStatus(1));
    }
}, _arg1))))))), (_arg2) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_loadPresales(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let inputRecord;
        const query_1 = new Common_PagingQuery$1((inputRecord = query.Payload, new Presales_PresaleReportFilter(inputRecord.AuctionId, inputRecord.ByStatus, query.Payload.FreeTextSearch.trim())), query.Page, query.PageSize, query.SortBy, query.LocalizationContext);
        return singleton.Bind(Remoting_handleNonAuth(presaleApi().getPresales(query_1)), (_arg1) => singleton.Return(new Msg(2, query_1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))));
    }), (_arg2) => singleton.Return(new Msg(2, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_markAsSentByPost(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(presaleApi().markPresaleAsSentByPost(id)), () => singleton.Return(new Msg(5, id, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))), (_arg2) => singleton.Return(new Msg(5, id, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export function Cmd_unMarkPresaleAsSentByPost(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(presaleApi().unmarkPresaleAsSentPost(id)), () => singleton.Return(new Msg(6, id, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))), (_arg2) => singleton.Return(new Msg(6, id, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export function init() {
    return [new State(new SortAuction(AuctionIdModule_empty, ""), empty(), new Common_PagingQuery$1(new Presales_PresaleReportFilter(AuctionIdModule_empty, empty(), ""), 1, 20, void 0, getLocalizationContext()), new Deferred$1(0), 1, toggleStatuses, new Deferred$1(0), listChangeStatus, head(listChangeStatus), ""), Cmd_OfFunc_result(new Msg(1, new AsyncOperationStatus$1(0)))];
}

export function update(msg, state) {
    let matchValue, inputRecord_3, inputRecord_2, matchValue_3, x_10, matchValue_5, x_12, s_1, inputRecord_6, inputRecord_7, inputRecord;
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                const newList = append(state.AllAuctions, map((x_5) => (new SortAuction(x_5.Id, (Localized_translate(x_5.Name) + ", ") + Localized_formatDateTime("dd MMMM yyyy", x_5.Date))), sortByDescending((x_3) => x_3.Date, filter((x_2) => {
                    if (equals(x_2.Status, new AuctionStatus(2)) ? true : equals(x_2.Status, new AuctionStatus(0))) {
                        return true;
                    }
                    else {
                        return equals(x_2.Status, new AuctionStatus(1));
                    }
                }, msg.fields[0].fields[0].fields[0]), {
                    Compare: (x_4, y) => compare(x_4, y),
                })));
                return [new State(head(newList), newList, state.Query, state.Presales, state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_OfFunc_result(new Msg(0, AuctionIdModule_toString(head(newList).Id)))];
            }
        }
        else {
            return [new State(state.Auction, empty(), state.Query, state.Presales, state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_fromAsync(Cmd_loadAuctions)];
        }
    }
    else if (msg.tag === 2) {
        if (msg.fields[1].tag === 1) {
            if (!equals(msg.fields[0], state.Query)) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.Auction, state.AllAuctions, state.Query, new Deferred$1(2, msg.fields[1].fields[0]), state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_none()];
            }
        }
        else {
            return [new State(state.Auction, state.AllAuctions, state.Query, new Deferred$1(1), state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_fromAsync(Cmd_loadPresales(msg.fields[0]))];
        }
    }
    else if (msg.tag === 3) {
        let newQuery_1;
        const inputRecord_1 = state.Query;
        newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, msg.fields[0], inputRecord_1.PageSize, inputRecord_1.SortBy, inputRecord_1.LocalizationContext));
        return [new State(state.Auction, state.AllAuctions, newQuery_1, state.Presales, msg.fields[0], state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_OfFunc_result(new Msg(2, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 4) {
        let newQuery_2;
        const query_3 = state.Query;
        newQuery_2 = (new Common_PagingQuery$1((matchValue = msg.fields[0].Status, (matchValue != null) ? ((inputRecord_3 = state.Query.Payload, new Presales_PresaleReportFilter(inputRecord_3.AuctionId, singleton_1(matchValue), inputRecord_3.FreeTextSearch))) : ((inputRecord_2 = state.Query.Payload, new Presales_PresaleReportFilter(inputRecord_2.AuctionId, empty(), inputRecord_2.FreeTextSearch)))), 1, query_3.PageSize, query_3.SortBy, query_3.LocalizationContext));
        if (msg.fields[0].Status != null) {
            return [new State(state.Auction, state.AllAuctions, newQuery_2, state.Presales, state.CurrentPage, map((x_8) => {
                if (equals(x_8.Status, msg.fields[0].Status)) {
                    return new ToggleStatus(msg.fields[0].Status, !msg.fields[0].IsCheck);
                }
                else if (x_8.Status == null) {
                    return new ToggleStatus(void 0, false);
                }
                else if (!equals(x_8.Status, msg.fields[0].Status)) {
                    return new ToggleStatus(x_8.Status, false);
                }
                else {
                    return x_8;
                }
            }, state.FilterStatuses), state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_OfFunc_result(new Msg(2, newQuery_2, new AsyncOperationStatus$1(0)))];
        }
        else {
            return [new State(state.Auction, state.AllAuctions, newQuery_2, state.Presales, state.CurrentPage, toggleStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_OfFunc_result(new Msg(2, newQuery_2, new AsyncOperationStatus$1(0)))];
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new State(state.Auction, state.AllAuctions, state.Query, state.Presales, state.CurrentPage, state.FilterStatuses, new Deferred$1(3, msg.fields[1].fields[0].fields[0]), state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_none()];
            }
            else {
                let newPresaleList;
                const matchValue_2 = state.Presales;
                if (matchValue_2.tag === 2) {
                    if (matchValue_2.fields[0].tag === 1) {
                        throw (new Error(tWithArgs("errors.fromServer.presaleReportErrorWithArg", {
                            error: matchValue_2.fields[0].fields[0],
                        })));
                    }
                    else {
                        newPresaleList = map((x_9) => {
                            if (x_9.Id === msg.fields[0]) {
                                return new Presales_Presale(x_9.Id, x_9.AuctionId, x_9.SubmissionId, x_9.SellerId, x_9.SellerName, new Presales_PresaleReportStatus(2), x_9.Lots);
                            }
                            else {
                                return x_9;
                            }
                        }, matchValue_2.fields[0].fields[0].Result);
                    }
                }
                else if (matchValue_2.tag === 3) {
                    throw (new Error(tWithArgs("errors.fromServer.presaleReportErrorWithArg", {
                        error: matchValue_2.fields[0],
                    })));
                }
                else {
                    newPresaleList = empty();
                }
                return [new State(state.Auction, state.AllAuctions, state.Query, new Deferred$1(2, new FSharpResult$2(0, (matchValue_3 = state.Presales, (matchValue_3.tag === 2) ? ((matchValue_3.fields[0].tag === 0) ? ((x_10 = matchValue_3.fields[0].fields[0], new Common_PagingResult$1(x_10.Page, x_10.PageSize, x_10.Total, newPresaleList))) : (() => {
                    throw (new Error(t("errors.fromServer.markAsSentByPostError")));
                })()) : (() => {
                    throw (new Error(t("errors.fromServer.markAsSentByPostError")));
                })()))), state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_none()];
            }
        }
        else {
            return [new State(state.Auction, state.AllAuctions, state.Query, state.Presales, state.CurrentPage, state.FilterStatuses, new Deferred$1(1), state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_fromAsync(Cmd_markAsSentByPost(msg.fields[0]))];
        }
    }
    else if (msg.tag === 6) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new State(state.Auction, state.AllAuctions, state.Query, state.Presales, state.CurrentPage, state.FilterStatuses, new Deferred$1(3, msg.fields[1].fields[0].fields[0]), state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_none()];
            }
            else {
                let newPresaleList_1;
                const matchValue_4 = state.Presales;
                if (matchValue_4.tag === 2) {
                    if (matchValue_4.fields[0].tag === 1) {
                        throw (new Error(tWithArgs("errors.fromServer.presaleReportErrorWithArg", {
                            error: matchValue_4.fields[0].fields[0],
                        })));
                    }
                    else {
                        newPresaleList_1 = map((x_11) => {
                            if (x_11.Id === msg.fields[0]) {
                                return new Presales_Presale(x_11.Id, x_11.AuctionId, x_11.SubmissionId, x_11.SellerId, x_11.SellerName, new Presales_PresaleReportStatus(0), x_11.Lots);
                            }
                            else {
                                return x_11;
                            }
                        }, matchValue_4.fields[0].fields[0].Result);
                    }
                }
                else if (matchValue_4.tag === 3) {
                    throw (new Error(tWithArgs("errors.fromServer.presaleReportErrorWithArg", {
                        error: matchValue_4.fields[0],
                    })));
                }
                else {
                    newPresaleList_1 = empty();
                }
                return [new State(state.Auction, state.AllAuctions, state.Query, new Deferred$1(2, new FSharpResult$2(0, (matchValue_5 = state.Presales, (matchValue_5.tag === 2) ? ((matchValue_5.fields[0].tag === 0) ? ((x_12 = matchValue_5.fields[0].fields[0], new Common_PagingResult$1(x_12.Page, x_12.PageSize, x_12.Total, newPresaleList_1))) : (() => {
                    throw (new Error(t("errors.fromServer.unmarkAsSentByPostError")));
                })()) : (() => {
                    throw (new Error(t("errors.fromServer.unmarkAsSentByPostError")));
                })()))), state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_none()];
            }
        }
        else {
            return [new State(state.Auction, state.AllAuctions, state.Query, state.Presales, state.CurrentPage, state.FilterStatuses, new Deferred$1(1), state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_fromAsync(Cmd_unMarkPresaleAsSentByPost(msg.fields[0]))];
        }
    }
    else if (msg.tag === 7) {
        return [new State(state.Auction, state.AllAuctions, state.Query, state.Presales, state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, defaultArg(tryFind((x_13) => (x_13.NameStatus === msg.fields[0]), state.ListChangeStatus), new SentByPostStatus(0, t("reports.sent"))), state.SearchString), Cmd_none()];
    }
    else if (msg.tag === 8) {
        let newQuery_3;
        const matchValue_6 = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_2;
        if (matchValue_6 != null) {
            if ((s_1 = matchValue_6[1], msg.fields[0] === matchValue_6[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue_6[0];
                s_2 = matchValue_6[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_4 = state.Query;
                newQuery_3 = (new Common_PagingQuery$1(inputRecord_4.Payload, inputRecord_4.Page, inputRecord_4.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_2)], inputRecord_4.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_5 = state.Query;
                newQuery_3 = (new Common_PagingQuery$1(inputRecord_5.Payload, inputRecord_5.Page, inputRecord_5.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_5.LocalizationContext));
                break;
            }
        }
        return [new State(state.Auction, state.AllAuctions, newQuery_3, state.Presales, state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_OfFunc_result(new Msg(2, newQuery_3, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 9) {
        return [new State(state.Auction, state.AllAuctions, state.Query, state.Presales, state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, msg.fields[0]), Cmd_none()];
    }
    else if (msg.tag === 10) {
        if (msg.fields[0] === "Enter") {
            let newQuery_4;
            const query_4 = state.Query;
            newQuery_4 = (new Common_PagingQuery$1((inputRecord_6 = state.Query.Payload, new Presales_PresaleReportFilter(inputRecord_6.AuctionId, inputRecord_6.ByStatus, state.SearchString.trim().toLowerCase())), 1, query_4.PageSize, query_4.SortBy, query_4.LocalizationContext));
            return [new State(state.Auction, state.AllAuctions, newQuery_4, state.Presales, state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_fromAsync(Cmd_loadPresales(newQuery_4))];
        }
        else if (msg.fields[0] === "Escape") {
            let newQuery_5;
            const query_5 = state.Query;
            newQuery_5 = (new Common_PagingQuery$1((inputRecord_7 = state.Query.Payload, new Presales_PresaleReportFilter(inputRecord_7.AuctionId, inputRecord_7.ByStatus, "")), 1, query_5.PageSize, query_5.SortBy, query_5.LocalizationContext));
            return [new State(state.Auction, state.AllAuctions, newQuery_5, state.Presales, state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_fromAsync(Cmd_loadPresales(newQuery_5))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        let newAuction;
        const value = new SortAuction(AuctionIdModule_empty, "");
        newAuction = defaultArg(tryFind((x) => equals_1(x.Id, AuctionIdModule_fromString(msg.fields[0])), state.AllAuctions), value);
        let newQuery;
        const query = state.Query;
        newQuery = (new Common_PagingQuery$1((inputRecord = state.Query.Payload, new Presales_PresaleReportFilter(newAuction.Id, inputRecord.ByStatus, inputRecord.FreeTextSearch)), 1, query.PageSize, query.SortBy, query.LocalizationContext));
        return [new State(newAuction, state.AllAuctions, newQuery, state.Presales, state.CurrentPage, state.FilterStatuses, state.Processing, state.ListChangeStatus, state.ChoosingStatus, state.SearchString), Cmd_OfFunc_result(new Msg(2, newQuery, new AsyncOperationStatus$1(0)))];
    }
}

export function tableHeader(state, dispatch, unitVar2) {
    let props_18, props_8, props_10, props_12, props_14;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(8, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_2(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_2(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    const props_20 = ofArray([["className", "is-fluid"], ["className", "mt-4"], ["className", "mb-2"], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.customerId"), "userId")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-5"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.name"), "userName")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.submissionId"), "submissionId")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.status"), "status")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-1"]))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_20)));
}

export const headerOfLots = (() => {
    let props_10, props, props_2, props_4, props_6, props_8;
    const props_12 = ofArray([["className", join(" ", ["no-height-section-to-print"])], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-vcentered"], ["className", "has-text-grey"], ["style", {
        fontSize: 10 + "px",
    }], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-1"], ["className", "pl-1"], ["className", "pr-0"], ["children", t("common.lotNumber")]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-1"], ["className", "pl-1"], ["className", "pr-0"], ["children", t("common.itemId")]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "is-4"], ["children", t("common.title")]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-3"], ["className", "has-text-centered"], ["children", t("auction.estimatedPrice")]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-3"], ["className", "has-text-centered"], ["children", t("auction.reservedPrice")]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_12)));
})();

export function lotItem(lot) {
    let props, props_2, props_4, props_6, props_8;
    const props_10 = ofArray([["className", join(" ", ["no-height-section-to-print"])], ["className", "is-vcentered"], ["className", "is-size-6"], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-1"], ["className", "pl-1"], ["className", "pr-0"], ["children", OrderNumberModule_toString(lot.LotNumber)]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-1"], ["className", "pl-1"], ["className", "pr-0"], ["children", ItemIdModule_toString(lot.ItemId)]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "is-4"], ["className", "is-text-overflow-hidden"], ["children", Localized_translate(lot.Name)]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-3"], ["className", "has-text-centered"], ["children", equals_2(lot.EstimatedPrice, CurrencyModule_zero) ? "-" : Currency_format(lot.EstimatedPrice)]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-3"], ["className", "has-text-centered"], ["children", equals_2(lot.ReservedPrice, CurrencyModule_zero) ? "-" : Currency_format(lot.ReservedPrice)]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_10)));
}

export function OrderItem(orderItemInputProps) {
    const dispatch = orderItemInputProps.dispatch;
    const state = orderItemInputProps.state;
    const presale = orderItemInputProps.presale;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setConfirmation = patternInput[1];
    const isConfirmation = patternInput[0];
    const isSavingDisabled = equals(presale.ReportStatus, new Presales_PresaleReportStatus(1)) ? true : isEmpty(presale.Lots);
    const props_46 = ofArray([["className", join(" ", ["no-height-section-to-print"])], ["className", "has-background-light"], ["className", "mb-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(headerOfLots), delay(() => append_1(map_1((lot) => lotItem(lot), sortBy_2((x) => x.LotNumber, presale.Lots, {
        Compare: (x_1, y) => comparePrimitives(x_1, y),
    })), delay(() => append_1(singleton_2(createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", empty())))), delay(() => {
        let props_19, props_12, elms, props_9, patternInput_1, props_17, elms_1, props_14;
        return append_1(singleton_2((props_19 = ofArray([["className", "px-0"], ["className", "py-0"], ["className", "is-vcentered"], ["style", {
            justifyContent: "flex-end",
        }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-8"])))), (props_12 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_9 = singleton_1(["children", Interop_reactApi.Children.toArray([(patternInput_1 = Helpers_extractClasses(toList(delay(() => append_1(isSavingDisabled ? singleton_2(["disabled", true]) : empty_1(), delay(() => append_1(singleton_2(["className", join(" ", ["fa"])]), delay(() => append_1(singleton_2(["className", "has-text-left"]), delay(() => append_1(singleton_2(["className", "is-size-6"]), delay(() => append_1(singleton_2(["className", "has-text-grey"]), delay(() => append_1(singleton_2(["onChange", (ev) => {
            dispatch(new Msg(7, ev.target.value));
        }]), delay(() => append_1(singleton_2(["value", state.ChoosingStatus.NameStatus]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(map((x_2) => createElement("option", {
            children: x_2.NameStatus,
        }), state.ListChangeStatus)))])))))))))))))))))), createElement("div", {
            className: join(" ", cons("select", patternInput_1[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
        }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_9))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_17 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((props_14 = toList(delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(isSavingDisabled ? singleton_2(["disabled", true]) : singleton_2(["onClick", (_arg1) => {
            setConfirmation(!isConfirmation);
        }]), delay(() => singleton_2(["children", t("common.save")]))))))), createElement("a", createObj(Helpers_combineClasses("button", props_14))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_19))))), delay(() => {
            let props_44;
            return singleton_2((props_44 = toList(delay(() => append_1(isConfirmation ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => {
                let props_42, props_40, elms_3, elms_2, props_23, props_28, s, props_38, props_36, props_32, props_34;
                return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg2) => {
                    setConfirmation(!isConfirmation);
                }])))), (props_42 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_40 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(elms_2 = singleton_1((props_23 = singleton_1(["children", t("common.update")]), createElement("span", createObj(Helpers_combineClasses("", props_23))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                })), (props_28 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg3) => {
                    setConfirmation(!isConfirmation);
                }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_28))))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                })), (s = t("reports.updatePayoutSendStatusConfirmation"), createElement("p", {
                    className: "",
                    children: s,
                })), (props_38 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_36 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_32 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["onClick", (_arg4) => {
                    setConfirmation(!isConfirmation);
                }]]), createElement("a", createObj(Helpers_combineClasses("button", props_32)))), (props_34 = ofArray([["className", "is-primary"], ["children", t("common.update")], ["onClick", (_arg5) => {
                    setConfirmation(!isConfirmation);
                    const matchValue = state.ChoosingStatus.Id | 0;
                    switch (matchValue) {
                        case 1: {
                            dispatch(new Msg(5, presale.Id, new AsyncOperationStatus$1(0)));
                            break;
                        }
                        case 2: {
                            dispatch(new Msg(6, presale.Id, new AsyncOperationStatus$1(0)));
                            break;
                        }
                        default: {
                        }
                    }
                }]]), createElement("a", createObj(Helpers_combineClasses("button", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_36))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_38))))])]), createElement("div", createObj(Helpers_combineClasses("box", props_40))))])]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_42))))])]);
            })))), createElement("div", createObj(Helpers_combineClasses("modal", props_44)))));
        }));
    }))))))))))]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_46)));
}

export function PrintPresale(printPresaleInputProps) {
    let props_3;
    const presale = printPresaleInputProps.presale;
    const auctionName = printPresaleInputProps.auctionName;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput[1];
    const generateReport = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading(true);
        return singleton.Bind(presaleApi().generatePresaleReport([presale.AuctionId, presale.SubmissionId, auctionName]), (_arg1) => {
            window.open(_arg1);
            setIsLoading(false);
            return singleton.Return();
        });
    }), (_arg2) => {
        setIsLoading(false);
        return singleton.Zero();
    }));
    const props_5 = ofArray([["className", "has-text-right"], ["style", {
        alignSelf: "flex-end",
    }], ["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append_1(isEmpty(presale.Lots) ? singleton_2(["disabled", true]) : singleton_2(["onClick", (_arg1_1) => {
        startImmediate(generateReport);
    }]), delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(patternInput[0] ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => {
        let elms, s;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-print"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (s = t("common.print"), createElement("span", {
            className: "",
            children: s,
        }))])]);
    })))))))), createElement("a", createObj(Helpers_combineClasses("button", props_3))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("column", props_5)));
}

export function RenderItems(renderItemsInputProps) {
    const dispatch = renderItemsInputProps.dispatch;
    const state = renderItemsInputProps.state;
    const presale = renderItemsInputProps.presale;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isVisible = patternInput[0];
    const props_17 = ofArray([["className", "mb-2"], ["className", "py-3"], ["style", {
        borderRadius: 4,
    }], ["className", "is-size-6"], ["className", "is-fluid"], ["className", "has-background-white"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_15, props_2, props, props_6, props_8, props_13, props_11;
        return append_1(singleton_2((props_15 = ofArray([["className", join(" ", ["no-height-section-to-print"])], ["onClick", (_arg1) => {
            patternInput[1](!isVisible);
        }], ["className", "is-vcentered"], ["className", "px-0"], ["className", "is-size-6"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-text-grey"], ["children", UserIdModule_toString(presale.SellerId)]]), createElement("p", createObj(Helpers_combineClasses("", props))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-uppercase"], ["className", "is-5"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", presale.SellerName]])))), (props_6 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", SubmissionIdModule_toString(presale.SubmissionId)]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = toList(delay(() => append_1(singleton_2(["className", "is-2"]), delay(() => append_1(singleton_2(["className", "has-text-right"]), delay(() => {
            const matchValue = presale.ReportStatus;
            switch (matchValue.tag) {
                case 1: {
                    return append_1(singleton_2(["className", "has-text-primary"]), delay(() => singleton_2(["children", t("reports.emailSent")])));
                }
                case 2: {
                    return append_1(singleton_2(["className", "has-text-primary"]), delay(() => singleton_2(["children", t("reports.postSent")])));
                }
                default: {
                    return append_1(singleton_2(["className", "has-text-grey"]), delay(() => singleton_2(["children", t("reports.unsent")])));
                }
            }
        })))))), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_13 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (isVisible ? singleton_2(["className", join(" ", ["fas", "fa-angle-up"])]) : singleton_2(["className", join(" ", ["fas", "fa-angle-down"])]))))))])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_15))))), delay(() => (isVisible ? append_1(singleton_2(createElement(OrderItem, {
            presale: presale,
            state: state,
            dispatch: dispatch,
        })), delay(() => singleton_2(createElement(PrintPresale, {
            auctionName: state.Auction.AuctionName,
            presale: presale,
        })))) : empty_1())));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_17)));
}

export function ButtonFilterStatus(buttonFilterStatusInputProps) {
    const unitVar2 = buttonFilterStatusInputProps.unitVar2;
    const dispatch = buttonFilterStatusInputProps.dispatch;
    const status = buttonFilterStatusInputProps.status;
    const props = toList(delay(() => append_1((!status.IsCheck) ? append_1(singleton_2(["className", "is-primary"]), delay(() => singleton_2(["className", "is-light"]))) : empty_1(), delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => {
        let matchValue;
        return append_1((matchValue = status.Status, (matchValue != null) ? singleton_2(["children", toString(matchValue)]) : singleton_2(["children", t("common.all")])), delay(() => singleton_2(["onClick", (_arg1) => {
            dispatch(new Msg(4, status));
        }])));
    }))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export const borderLight = ["style", {
    borderBottomStyle: "solid",
    borderBottomWidth: 1 + "px",
    borderBottomColor: "#c4c4c4",
}];

export function Render() {
    let props_2, s, s_1, props_25, props_19, props_9, props_4, props_7, props_16, patternInput_1, elms, children_2;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(init, (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const elms_2 = ofArray([(props_2 = ofArray([["className", "has-text-centered"], ["className", join(" ", ["no-height-section-to-print"])], ["children", Interop_reactApi.Children.toArray([(s = t("reports.presaleReport"), createElement("h1", {
        className: "title",
        children: s,
    })), (s_1 = t("reports.presaleReportSubtitle"), createElement("h2", {
        className: "subtitle",
        children: s_1,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_2)))), (props_25 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", join(" ", ["no-height-section-to-print"])], ["className", "is-grouped"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "has-icons-left"], ["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "searchInput"], ["placeholder", t("reports.searchLotCustomerSubmission")], ["onChange", (ev) => {
        dispatch(new Msg(9, ev.target.value));
    }], ["onKeyUp", (ev_1) => {
        dispatch(new Msg(10, ev_1.key));
    }], ["onInput", (_arg1) => {
        dispatch(new Msg(10, "Escape"));
    }]]), createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", props_4))))), (props_7 = ofArray([["className", "is-left"], ["style", {
        height: 100 + "%",
    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-search", "fa-lg"]),
    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_9)))), (props_16 = singleton_1(["children", Interop_reactApi.Children.toArray([(patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "is-size-6"], ["className", "has-text-grey"], ["onChange", (ev_2) => {
        dispatch(new Msg(0, ev_2.target.value));
    }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x) => createElement("option", {
        value: AuctionIdModule_toString(x.Id),
        children: x.AuctionName,
    }), state_1.AllAuctions)))]])), createElement("div", {
        className: join(" ", cons("select", patternInput_1[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
    }))])]), createElement("div", createObj(Helpers_combineClasses("control", props_16)))), (elms = toList(delay(() => map_1((status) => createElement(ButtonFilterStatus, {
        status: status,
        dispatch: dispatch,
    }), state_1.FilterStatuses))), createElement("div", {
        className: "buttons",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_19)))), tableHeader(state_1, dispatch, void 0), (children_2 = toList(delay(() => {
        let props_21, elms_1;
        const matchValue = state_1.Presales;
        let pattern_matching_result, er, ex, presales;
        if (matchValue.tag === 1) {
            pattern_matching_result = 0;
        }
        else if (matchValue.tag === 2) {
            if (matchValue.fields[0].tag === 0) {
                pattern_matching_result = 3;
                presales = matchValue.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
                er = matchValue.fields[0].fields[0];
            }
        }
        else if (matchValue.tag === 3) {
            pattern_matching_result = 2;
            ex = matchValue.fields[0];
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton_2(loaderBig());
            }
            case 1: {
                return singleton_2(er);
            }
            case 2: {
                return singleton_2(ex.message);
            }
            case 3: {
                return isEmpty(presales.Result) ? singleton_2((props_21 = ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", t("auction.noLots")]]), createElement("p", createObj(Helpers_combineClasses("", props_21))))) : append_1(singleton_2((elms_1 = toList(delay(() => map_1((presale) => createElement(RenderItems, {
                    presale: presale,
                    state: state_1,
                    dispatch: dispatch,
                }), presales.Result))), createElement("div", {
                    className: "container",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))), delay(() => singleton_2(renderPagination(presales.Page, presales.Total, presales.PageSize, (arg_2) => {
                    dispatch(new Msg(3, arg_2));
                }))));
            }
        }
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    }))])]), createElement("section", createObj(Helpers_combineClasses("section", props_25))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

