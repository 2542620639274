import { toString, Record, Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { class_type, list_type, unit_type, string_type, record_type, bool_type, union_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { collect, empty as empty_1, append, singleton, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Form_getFieldError } from "./Common.js";
import { createElement } from "react";
import * as react from "react";
import { comparePrimitives, equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { sortBy, map, singleton as singleton_1, fold, cons, empty, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { tWithArgs, getCurrentLanguage, t } from "./Localization.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, Deferred$1$reflection, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { singleton as singleton_2 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { commonApi, settingsApi } from "./Communication.js";
import { SettingsPayout_AccountNumberType$reflection, SettingsPayout_ISOVersion, SettingsPayout_DebtorXMLCredentials, SettingsPayout_PayoutSettingsXML, SettingsPayout_PayoutSettingsXML$reflection, SettingsPayout_ISOVersion$reflection, SettingsPayout_DebtorSUSBankCredentials, SettingsPayout_PayoutSettingsSUS, SettingsPayout_PayoutSettingsSUS$reflection } from "./Shared/Shared.js";
import { toList as toList_1, map as map_1, empty as empty_2 } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PayoutSettings_XML_validate, PayoutSettings_XML_validateIf, PayoutSettings_SUS_validate, PayoutSettings_SUS_validateIf } from "./Validation.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Localized_getCountryName } from "./bidflow/src/infrastructure/Localization.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { isCurrentUserAdmin } from "./LocalStorage.js";

export class FieldType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Text", "Number"];
    }
}

export function FieldType$reflection() {
    return union_type("Client.SettingsPayout.FieldType", [], FieldType, () => [[], []]);
}

export function renderField(fieldType, label, value, placeholder, isInProgress, errors, formError, onChange) {
    let props_14, props_12;
    const elms_2 = singleton_1((props_14 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(props_12 = toList(delay(() => {
        let elms_1, props_2, elms, props_9;
        const errors_1 = Form_getFieldError(errors, formError);
        return singleton(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = toList(delay(() => {
            let props_6, props_4;
            return (fieldType.tag === 1) ? singleton((props_6 = toList(delay(() => append((!equals(errors_1, empty())) ? singleton(["className", "is-danger"]) : empty_1(), delay(() => append(singleton(["disabled", isInProgress]), delay(() => append(singleton(["onChange", (ev_1) => {
                onChange(ev_1.target.value);
            }]), delay(() => append(singleton(["placeholder", placeholder]), delay(() => {
                let value_31;
                return singleton((value_31 = value, ["ref", (e_1) => {
                    if ((!(e_1 == null)) ? (!equals(e_1.value, value_31)) : false) {
                        e_1.value = value_31;
                    }
                }]));
            })))))))))), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_6)))))) : singleton((props_4 = toList(delay(() => append((!equals(errors_1, empty())) ? singleton(["className", "is-danger"]) : empty_1(), delay(() => append(singleton(["disabled", isInProgress]), delay(() => append(singleton(["onChange", (ev) => {
                onChange(ev.target.value);
            }]), delay(() => append(singleton(["placeholder", placeholder]), delay(() => {
                let value_19;
                return singleton((value_19 = value, ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_19)) : false) {
                        e.value = value_19;
                    }
                }]));
            })))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4))))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_9 = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_9))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    })), createElement("div", createObj(Helpers_combineClasses("field", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))));
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function RenderDescriptionSettings(description, isUpdateResultInProgress, errors, updateDescription) {
    const props_10 = toList(delay(() => {
        let elms_1, props_2, props, elms, props_4, props_7;
        const errors_1 = Form_getFieldError(errors, "Description");
        return singleton(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.description")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1((props_4 = toList(delay(() => append((!equals(errors_1, empty())) ? singleton(["className", "is-danger"]) : empty_1(), delay(() => append(singleton(["disabled", isUpdateResultInProgress]), delay(() => append(singleton(["onChange", (ev) => {
            updateDescription(ev.target.value);
        }]), delay(() => append(singleton(["placeholder", t("settings.description")]), delay(() => {
            let value_16;
            return singleton((value_16 = description, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_16)) : false) {
                    e.value = value_16;
                }
            }]));
        })))))))))), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_4))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_7 = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_7))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    }));
    return createElement("div", createObj(Helpers_combineClasses("field", props_10)));
}

export class Format extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SUS", "XML"];
    }
}

export function Format$reflection() {
    return union_type("Client.SettingsPayout.Format", [], Format, () => [[], []]);
}

export class ExportFormat extends Record {
    constructor(Format, IsEnabled) {
        super();
        this.Format = Format;
        this.IsEnabled = IsEnabled;
    }
}

export function ExportFormat$reflection() {
    return record_type("Client.SettingsPayout.ExportFormat", [], ExportFormat, () => [["Format", Format$reflection()], ["IsEnabled", bool_type]]);
}

export function RenderExportFormats(renderExportFormatsInputProps) {
    const updateFormat = renderExportFormatsInputProps.updateFormat;
    const isUpdateResultInProgress = renderExportFormatsInputProps.isUpdateResultInProgress;
    const format = renderExportFormatsInputProps.format;
    const elms = toList(delay(() => {
        let props_6, props_2;
        return (format.Format.tag === 0) ? singleton((props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("input", {
            disabled: isUpdateResultInProgress,
            className: join(" ", ["is-checkradio"]),
            id: "checkboxSwedbank",
            type: "checkbox",
            name: "checkboxSwedbank",
            onChange: (ev_1) => {
                const _arg2 = ev_1.target.checked;
                updateFormat(!format.IsEnabled);
            },
            checked: format.IsEnabled,
        }), createElement("label", {
            htmlFor: "checkboxSwedbank",
            children: t("settings.isSwedbankPayoutAvailable"),
        })])]), createElement("div", createObj(Helpers_combineClasses("field", props_6))))) : singleton((props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("input", {
            disabled: isUpdateResultInProgress,
            className: join(" ", ["is-checkradio"]),
            id: "checkboxHandelsbanken",
            type: "checkbox",
            name: "checkboxHandelsbanken",
            onChange: (ev) => {
                const _arg1 = ev.target.checked;
                updateFormat(!format.IsEnabled);
            },
            checked: format.IsEnabled,
        }), createElement("label", {
            htmlFor: "checkboxHandelsbanken",
            children: t("settings.isHandelsbankenPayoutAvailable"),
        })])]), createElement("div", createObj(Helpers_combineClasses("field", props_2)))));
    }));
    return createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export class SUSSettings_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AgreementNumberChanged", "RegNumberChanged", "DescriptionChanged", "IsExportEnabledChanged", "CancelEdit", "SaveDebtorCredentialsForm"];
    }
}

export function SUSSettings_Msg$reflection() {
    return union_type("Client.SettingsPayout.SUSSettings.Msg", [], SUSSettings_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function SUSSettings_Cmd_updatePayoutSUSSettings(info) {
    return singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(Remoting_handleNonAuth(settingsApi().updatePayoutSUSSettings(info)), (_arg1) => singleton_2.Return(new SUSSettings_Msg(5, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton_2.Return(new SUSSettings_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export class SUSSettings_State extends Record {
    constructor(Settings, OriginalSettings, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.Settings = Settings;
        this.OriginalSettings = OriginalSettings;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function SUSSettings_State$reflection() {
    return record_type("Client.SettingsPayout.SUSSettings.State", [], SUSSettings_State, () => [["Settings", SettingsPayout_PayoutSettingsSUS$reflection()], ["OriginalSettings", SettingsPayout_PayoutSettingsSUS$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export function SUSSettings_init(settings) {
    return [new SUSSettings_State(settings, settings, empty_2(), false, new Deferred$1(0)), Cmd_none()];
}

export function SUSSettings_withCompanySettings(x, state) {
    return new SUSSettings_State(x, state.OriginalSettings, state.FormErrors, state.NeedToValidate, state.SaveFormOperation);
}

export function SUSSettings_withDebtor(debtorInfo, state) {
    const inputRecord = state.Settings;
    return new SettingsPayout_PayoutSettingsSUS(debtorInfo, inputRecord.Description, inputRecord.IsExportEnabled);
}

export function SUSSettings_withFormAndErrors(form, errors, state) {
    return new SUSSettings_State(form, state.OriginalSettings, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function SUSSettings_update(msg, state) {
    let inputRecord_2, inputRecord_3;
    const withValidatedForm = (arg) => {
        const tupledArg = PayoutSettings_SUS_validateIf(state.NeedToValidate, arg);
        return (state_1) => SUSSettings_withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 1) {
        return [withValidatedForm(SUSSettings_withDebtor(new SettingsPayout_DebtorSUSBankCredentials(state.Settings.Debtor.AgreementNumber, msg.fields[0]), state))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm((inputRecord_2 = state.Settings, new SettingsPayout_PayoutSettingsSUS(inputRecord_2.Debtor, msg.fields[0], inputRecord_2.IsExportEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm((inputRecord_3 = state.Settings, new SettingsPayout_PayoutSettingsSUS(inputRecord_3.Debtor, inputRecord_3.Description, msg.fields[0])))(state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new SUSSettings_State(state.OriginalSettings, state.OriginalSettings, empty_2(), false, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            return [new SUSSettings_State(state.Settings, state.Settings, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.Settings, state.OriginalSettings)) {
            const matchValue = PayoutSettings_SUS_validate(state.Settings);
            if (matchValue.tag === 1) {
                return [new SUSSettings_State(state.Settings, state.OriginalSettings, matchValue.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new SUSSettings_State(state.Settings, state.OriginalSettings, empty_2(), true, new Deferred$1(1)), Cmd_fromAsync(SUSSettings_Cmd_updatePayoutSUSSettings(state.Settings))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [withValidatedForm(SUSSettings_withDebtor(new SettingsPayout_DebtorSUSBankCredentials(msg.fields[0], state.Settings.Debtor.RegNumber), state))(state), Cmd_none()];
    }
}

export function SUSSettings_RenderPayoutSUSSettings(settings) {
    let props_10, props_2, props, props_8, props_4, props_6;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(SUSSettings_init(settings), (msg, state) => SUSSettings_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isInProgress = equals(state_1.SaveFormOperation, new Deferred$1(1));
    return react.createElement(react.Fragment, {}, createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_10 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-variable"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([renderField(new FieldType(0), t("settings.swedbankAgreementNumber"), state_1.Settings.Debtor.AgreementNumber, t("settings.swedbankAgreementNumber"), isInProgress, state_1.FormErrors, "AgreementNumber", (arg) => {
            dispatch(new SUSSettings_Msg(0, arg));
        }), renderField(new FieldType(0), t("settings.swedbankRegistrationNumber"), state_1.Settings.Debtor.RegNumber, t("settings.swedbankRegistrationNumber"), isInProgress, state_1.FormErrors, "RegisterNumber", (arg_1) => {
            dispatch(new SUSSettings_Msg(1, arg_1));
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_2)))), RenderDescriptionSettings(state_1.Settings.Description, equals(state_1.SaveFormOperation, new Deferred$1(1)), state_1.FormErrors, (x) => {
            dispatch(new SUSSettings_Msg(2, x));
        }), createElement(RenderExportFormats, {
            format: new ExportFormat(new Format(0), state_1.Settings.IsExportEnabled),
            isUpdateResultInProgress: equals(state_1.SaveFormOperation, new Deferred$1(1)),
            updateFormat: (x_1) => {
                dispatch(new SUSSettings_Msg(3, x_1));
            },
        }), (props_8 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x_2) => {
            x_2.preventDefault();
            dispatch(new SUSSettings_Msg(4));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_4)))), (props_6 = toList(delay(() => append(singleton(["className", "is-primary"]), delay(() => append(equals(state_1.Settings, state_1.OriginalSettings) ? singleton(["disabled", true]) : empty_1(), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append(singleton(["children", t("common.save")]), delay(() => singleton(["onClick", (x_3) => {
            x_3.preventDefault();
            dispatch(new SUSSettings_Msg(5, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_8))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_10))))]),
    }));
}

export class XMLSettings_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OrganisationIdChanged", "DebtorBICChanged", "DebtorNameChanged", "DebtorIbanChanged", "DescriptionChanged", "IsExportEnabledChanged", "ISOVersionChanged", "CancelEdit", "SaveDebtorCredentialsForm"];
    }
}

export function XMLSettings_Msg$reflection() {
    return union_type("Client.SettingsPayout.XMLSettings.Msg", [], XMLSettings_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", SettingsPayout_ISOVersion$reflection()]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function XMLSettings_Cmd_updatePayoutXMLSettings(info) {
    return singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(Remoting_handleNonAuth(settingsApi().updatePayoutXMLSettings(info)), (_arg1) => singleton_2.Return(new XMLSettings_Msg(8, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton_2.Return(new XMLSettings_Msg(8, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export class XMLSettings_State extends Record {
    constructor(Settings, OriginalSettings, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.Settings = Settings;
        this.OriginalSettings = OriginalSettings;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function XMLSettings_State$reflection() {
    return record_type("Client.SettingsPayout.XMLSettings.State", [], XMLSettings_State, () => [["Settings", SettingsPayout_PayoutSettingsXML$reflection()], ["OriginalSettings", SettingsPayout_PayoutSettingsXML$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export function XMLSettings_init(settings) {
    return [new XMLSettings_State(settings, settings, empty_2(), false, new Deferred$1(0)), Cmd_none()];
}

export function XMLSettings_withCompanySettings(x, state) {
    return new XMLSettings_State(x, state.OriginalSettings, state.FormErrors, state.NeedToValidate, state.SaveFormOperation);
}

export function XMLSettings_withDebtor(debtorInfo, state) {
    const inputRecord = state.Settings;
    return new SettingsPayout_PayoutSettingsXML(debtorInfo, inputRecord.Description, inputRecord.ISOVersion, inputRecord.IsExportEnabled);
}

export function XMLSettings_withFormAndErrors(form, errors, state) {
    return new XMLSettings_State(form, state.OriginalSettings, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function XMLSettings_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord;
    const withValidatedForm = (arg) => {
        const tupledArg = PayoutSettings_XML_validateIf(state.NeedToValidate, arg);
        return (state_1) => XMLSettings_withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 1) {
        return [withValidatedForm(XMLSettings_withDebtor((inputRecord_1 = state.Settings.Debtor, new SettingsPayout_DebtorXMLCredentials(inputRecord_1.OrganisationId, inputRecord_1.DebtorName, inputRecord_1.DebtorIban, msg.fields[0])), state))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm(XMLSettings_withDebtor((inputRecord_2 = state.Settings.Debtor, new SettingsPayout_DebtorXMLCredentials(inputRecord_2.OrganisationId, msg.fields[0], inputRecord_2.DebtorIban, inputRecord_2.DebtorBIC)), state))(state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm(XMLSettings_withDebtor((inputRecord_3 = state.Settings.Debtor, new SettingsPayout_DebtorXMLCredentials(inputRecord_3.OrganisationId, inputRecord_3.DebtorName, msg.fields[0], inputRecord_3.DebtorBIC)), state))(state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [withValidatedForm((inputRecord_4 = state.Settings, new SettingsPayout_PayoutSettingsXML(inputRecord_4.Debtor, msg.fields[0], inputRecord_4.ISOVersion, inputRecord_4.IsExportEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [withValidatedForm((inputRecord_5 = state.Settings, new SettingsPayout_PayoutSettingsXML(inputRecord_5.Debtor, inputRecord_5.Description, inputRecord_5.ISOVersion, msg.fields[0])))(state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [withValidatedForm((inputRecord_6 = state.Settings, new SettingsPayout_PayoutSettingsXML(inputRecord_6.Debtor, inputRecord_6.Description, msg.fields[0], inputRecord_6.IsExportEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new XMLSettings_State(state.OriginalSettings, state.OriginalSettings, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            return [new XMLSettings_State(state.Settings, state.Settings, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.Settings, state.OriginalSettings)) {
            const matchValue = PayoutSettings_XML_validate(state.Settings);
            if (matchValue.tag === 1) {
                return [new XMLSettings_State(state.Settings, state.OriginalSettings, matchValue.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new XMLSettings_State(state.Settings, state.OriginalSettings, empty_2(), true, new Deferred$1(1)), Cmd_fromAsync(XMLSettings_Cmd_updatePayoutXMLSettings(state.Settings))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [withValidatedForm(XMLSettings_withDebtor((inputRecord = state.Settings.Debtor, new SettingsPayout_DebtorXMLCredentials(msg.fields[0], inputRecord.DebtorName, inputRecord.DebtorIban, inputRecord.DebtorBIC)), state))(state), Cmd_none()];
    }
}

export function XMLSettings_RenderISOVersionDropdown(xMLSettings_RenderISOVersionDropdownInputProps) {
    let elms_1, props_2, props, elms, patternInput;
    const updateFormat = xMLSettings_RenderISOVersionDropdownInputProps.updateFormat;
    const isoVersion = xMLSettings_RenderISOVersionDropdownInputProps.isoVersion;
    const toValue = (_arg2) => {
        if (_arg2.tag === 1) {
            return "v3";
        }
        else {
            return "v2";
        }
    };
    const props_11 = ofArray([["disabled", xMLSettings_RenderISOVersionDropdownInputProps.isUpdateResultInProgress], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.ISOVersion")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["value", toValue(isoVersion)], ["onChange", (ev) => {
        let matchValue, s;
        updateFormat((matchValue = ((s = ev.target.value, (s === "v2") ? (new FSharpResult$2(0, new SettingsPayout_ISOVersion(0))) : ((s === "v3") ? (new FSharpResult$2(0, new SettingsPayout_ISOVersion(1))) : (new FSharpResult$2(1, `Unknown ISO version ${s}`))))), (matchValue.tag === 1) ? (() => {
            throw (new Error(matchValue.fields[0]));
        })() : matchValue.fields[0]));
    }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x_3) => createElement("option", {
        value: toValue(x_3),
        children: (x_3.tag === 1) ? t("settings.ISOVersionV3") : t("settings.ISOVersionV2"),
    }), ofArray([new SettingsPayout_ISOVersion(0), new SettingsPayout_ISOVersion(1)]))))]])), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_11)));
}

export function XMLSettings_RenderPayoutXMLSettings(settings) {
    let props_10, props_2, props, props_8, props_4, props_6;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(XMLSettings_init(settings), (msg, state) => XMLSettings_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isInProgress = equals(state_1.SaveFormOperation, new Deferred$1(1));
    return react.createElement(react.Fragment, {}, createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_10 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-variable"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([renderField(new FieldType(0), t("settings.handelsbankenOrganisationId"), state_1.Settings.Debtor.OrganisationId, t("settings.handelsbankenOrganisationId"), isInProgress, state_1.FormErrors, "OrganisationId", (arg) => {
            dispatch(new XMLSettings_Msg(0, arg));
        }), renderField(new FieldType(0), t("settings.handelsbankenDebtorName"), state_1.Settings.Debtor.DebtorName, t("settings.handelsbankenDebtorName"), isInProgress, state_1.FormErrors, "DebtorName", (arg_1) => {
            dispatch(new XMLSettings_Msg(2, arg_1));
        }), renderField(new FieldType(0), t("settings.handelsbankenDebtorIBAN"), state_1.Settings.Debtor.DebtorIban, t("settings.handelsbankenDebtorIBAN"), isInProgress, state_1.FormErrors, "DebtorIban", (arg_2) => {
            dispatch(new XMLSettings_Msg(3, arg_2));
        }), renderField(new FieldType(0), t("settings.handelsbankenDebtorBIC"), state_1.Settings.Debtor.DebtorBIC, t("settings.handelsbankenDebtorBIC"), isInProgress, state_1.FormErrors, "DebtorBIC", (arg_3) => {
            dispatch(new XMLSettings_Msg(1, arg_3));
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_2)))), RenderDescriptionSettings(state_1.Settings.Description, equals(state_1.SaveFormOperation, new Deferred$1(1)), state_1.FormErrors, (x) => {
            dispatch(new XMLSettings_Msg(4, x));
        }), createElement(XMLSettings_RenderISOVersionDropdown, {
            isoVersion: state_1.Settings.ISOVersion,
            isUpdateResultInProgress: equals(state_1.SaveFormOperation, new Deferred$1(1)),
            updateFormat: (x_1) => {
                dispatch(new XMLSettings_Msg(6, x_1));
            },
        }), createElement(RenderExportFormats, {
            format: new ExportFormat(new Format(1), state_1.Settings.IsExportEnabled),
            isUpdateResultInProgress: equals(state_1.SaveFormOperation, new Deferred$1(1)),
            updateFormat: (x_2) => {
                dispatch(new XMLSettings_Msg(5, x_2));
            },
        }), (props_8 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x_3) => {
            x_3.preventDefault();
            dispatch(new XMLSettings_Msg(7));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_4)))), (props_6 = toList(delay(() => append(singleton(["className", "is-primary"]), delay(() => append(equals(state_1.Settings, state_1.OriginalSettings) ? singleton(["disabled", true]) : empty_1(), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append(singleton(["children", t("common.save")]), delay(() => singleton(["onClick", (x_4) => {
            x_4.preventDefault();
            dispatch(new XMLSettings_Msg(8, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_8))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_10))))]),
    }));
}

export class AccountNumbers_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AccountNumberChanged", "CancelEdit", "SaveForm"];
    }
}

export function AccountNumbers_Msg$reflection() {
    return union_type("Client.SettingsPayout.AccountNumbers.Msg", [], AccountNumbers_Msg, () => [[["Item1", SettingsPayout_AccountNumberType$reflection()], ["Item2", string_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function AccountNumbers_Cmd_updateAccountNumbers(info) {
    return singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(Remoting_handleNonAuth(settingsApi().updateAccountNumberSettings(info)), (_arg1) => singleton_2.Return(new AccountNumbers_Msg(2, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton_2.Return(new AccountNumbers_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export class AccountNumbers_State extends Record {
    constructor(Settings, OriginalSettings, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.Settings = Settings;
        this.OriginalSettings = OriginalSettings;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function AccountNumbers_State$reflection() {
    return record_type("Client.SettingsPayout.AccountNumbers.State", [], AccountNumbers_State, () => [["Settings", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [SettingsPayout_AccountNumberType$reflection(), string_type])], ["OriginalSettings", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [SettingsPayout_AccountNumberType$reflection(), string_type])], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export function AccountNumbers_init(settings) {
    return [new AccountNumbers_State(settings, settings, empty_2(), false, new Deferred$1(0)), Cmd_none()];
}

export function AccountNumbers_withFormAndErrors(form, errors, state) {
    return new AccountNumbers_State(form, state.OriginalSettings, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function AccountNumbers_update(msg, state) {
    if (msg.tag === 1) {
        return [new AccountNumbers_State(state.OriginalSettings, state.OriginalSettings, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            return [new AccountNumbers_State(state.Settings, state.Settings, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!state.Settings.Equals(state.OriginalSettings)) {
            return [new AccountNumbers_State(state.Settings, state.OriginalSettings, empty_2(), true, new Deferred$1(1)), Cmd_fromAsync(AccountNumbers_Cmd_updateAccountNumbers(state.Settings))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new AccountNumbers_State(map_1((key, v) => {
            if (equals(key, msg.fields[0])) {
                return msg.fields[1];
            }
            else {
                return v;
            }
        }, state.Settings), state.OriginalSettings, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
    }
}

export function AccountNumbers_RenderComponent(accountNumbers_RenderComponentInputProps) {
    let props_10, props_2, props, props_8, props_4, props_6;
    const residentCountry = accountNumbers_RenderComponentInputProps.residentCountry;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(AccountNumbers_init(accountNumbers_RenderComponentInputProps.settings), (msg, state) => AccountNumbers_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isInProgress = equals(state_1.SaveFormOperation, new Deferred$1(1));
    return react.createElement(react.Fragment, {}, createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_10 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-variable"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((matchValue) => {
            const accountNumber = matchValue[0];
            let patternInput_1;
            switch (accountNumber.tag) {
                case 0: {
                    patternInput_1 = [t("reports.buyerCommissionOfEUBuyerVATFreeColumn"), t("reports.buyerCommissionOfEUBuyerVATFreeColumn"), ""];
                    break;
                }
                case 1: {
                    patternInput_1 = [t("reports.buyerCommissionOfNonEUBuyerVATFreeColumn"), t("reports.buyerCommissionOfNonEUBuyerVATFreeColumn"), ""];
                    break;
                }
                case 26: {
                    patternInput_1 = [t("common.buyerHammerFee"), t("common.buyerHammerFee"), ""];
                    break;
                }
                case 2: {
                    patternInput_1 = [t("common.buyerHammerFeeOfEUBuyerVATFree"), t("common.buyerHammerFeeOfEUBuyerVATFree"), ""];
                    break;
                }
                case 3: {
                    patternInput_1 = [t("common.buyerHammerFeeOfNonEUBuyerVATFree"), t("common.buyerHammerFeeOfNonEUBuyerVATFree"), ""];
                    break;
                }
                case 4: {
                    patternInput_1 = [t("orders.SumWithBuyerCommission"), t("orders.SumWithBuyerCommission"), ""];
                    break;
                }
                case 5: {
                    const residentCountry_1 = Localized_getCountryName(getCurrentLanguage())(residentCountry);
                    patternInput_1 = [tWithArgs("reports.auctionRevenue", {
                        residentCountry: residentCountry_1,
                    }), tWithArgs("reports.auctionRevenue", {
                        residentCountry: residentCountry_1,
                    }), ""];
                    break;
                }
                case 6: {
                    patternInput_1 = [t("reports.outgoingVat"), t("reports.outgoingVat"), ""];
                    break;
                }
                case 13: {
                    patternInput_1 = [t("common.transports"), t("common.transports"), ""];
                    break;
                }
                case 14: {
                    patternInput_1 = [t("reports.transportFeeVATFreeEU"), t("reports.transportFeeVATFreeEU"), ""];
                    break;
                }
                case 15: {
                    patternInput_1 = [t("reports.transportFeeVATFreeExport"), t("reports.transportFeeVATFreeExport"), ""];
                    break;
                }
                case 16: {
                    patternInput_1 = [t("common.administrativeFees"), t("common.administrativeFees"), ""];
                    break;
                }
                case 17: {
                    patternInput_1 = [t("reports.administrativeFeeVATFreeEU"), t("reports.administrativeFeeVATFreeEU"), ""];
                    break;
                }
                case 18: {
                    patternInput_1 = [t("reports.administrativeFeeVATFreeExport"), t("reports.administrativeFeeVATFreeExport"), ""];
                    break;
                }
                case 19: {
                    patternInput_1 = [t("common.recyclingFees"), t("common.recyclingFees"), ""];
                    break;
                }
                case 20: {
                    patternInput_1 = [t("reports.recyclingFeeVATFreeEU"), t("reports.recyclingFeeVATFreeEU"), ""];
                    break;
                }
                case 21: {
                    patternInput_1 = [t("reports.recyclingFeeVATFreeExport"), t("reports.recyclingFeeVATFreeExport"), ""];
                    break;
                }
                case 22: {
                    patternInput_1 = [t("common.citesFees"), t("common.citesFees"), ""];
                    break;
                }
                case 23: {
                    patternInput_1 = [t("auction.resaleRights"), t("auction.resaleRights"), ""];
                    break;
                }
                case 24: {
                    patternInput_1 = [t("auction.turnover"), t("auction.turnover"), ""];
                    break;
                }
                case 7: {
                    patternInput_1 = [t("reports.salesCommissionVATFreeEU"), t("reports.salesCommissionVATFreeEU"), ""];
                    break;
                }
                case 8: {
                    patternInput_1 = [t("reports.salesCommissionVATFreeExport"), t("reports.salesCommissionVATFreeExport"), ""];
                    break;
                }
                case 9: {
                    patternInput_1 = [t("reports.photoCommissionVATFreeEU"), t("reports.photoCommissionVATFreeEU"), ""];
                    break;
                }
                case 10: {
                    patternInput_1 = [t("reports.photoCommissionVATFreeExport"), t("reports.photoCommissionVATFreeExport"), ""];
                    break;
                }
                case 11: {
                    patternInput_1 = [t("reports.sellerHammerFeeVATFreeEU"), t("reports.sellerHammerFeeVATFreeEU"), ""];
                    break;
                }
                case 12: {
                    patternInput_1 = [t("reports.sellerHammerFeeVATFreeExport"), t("reports.sellerHammerFeeVATFreeExport"), ""];
                    break;
                }
                case 27: {
                    patternInput_1 = [t("reports.toPayOut"), t("reports.toPayOut"), ""];
                    break;
                }
                default: {
                    patternInput_1 = [t("orders.buyerCommission"), t("orders.buyerCommission"), ""];
                }
            }
            return singleton(renderField(new FieldType(1), patternInput_1[0], matchValue[1], patternInput_1[1], isInProgress, state_1.FormErrors, patternInput_1[2], (newValue) => {
                dispatch(new AccountNumbers_Msg(0, accountNumber, newValue));
            }));
        }, sortBy((tupledArg) => toString(tupledArg[0]), toList_1(state_1.Settings), {
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_2)))), (props_8 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x_2) => {
            x_2.preventDefault();
            dispatch(new AccountNumbers_Msg(1));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_4)))), (props_6 = toList(delay(() => append(singleton(["className", "is-primary"]), delay(() => append(state_1.Settings.Equals(state_1.OriginalSettings) ? singleton(["disabled", true]) : empty_1(), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append(singleton(["children", t("common.save")]), delay(() => singleton(["onClick", (x_3) => {
            x_3.preventDefault();
            dispatch(new AccountNumbers_Msg(2, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_8))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_10))))]),
    }));
}

export function AccountNumbers_Render() {
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(singleton_2.Delay(() => singleton_2.Bind(Remoting_handleNonAuth(settingsApi().getAccountNumberSettings()), (_arg1) => singleton_2.Bind(Remoting_handleNonAuth(commonApi().loadCompanyBasicSettings()), (_arg2) => singleton_2.Return({
        AccountNumbers: _arg1,
        ResidentCountry: _arg2.ResidentCountry,
    })))), []);
    switch (settings.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            return settings.fields[0].message;
        }
        case 2: {
            const settings_1 = settings.fields[0];
            return createElement(AccountNumbers_RenderComponent, {
                settings: settings_1.AccountNumbers,
                residentCountry: settings_1.ResidentCountry,
            });
        }
        default: {
            return loader("big");
        }
    }
}

export class EditMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AccountNumbers", "SUSSettings", "XMLSettings"];
    }
}

export function EditMode$reflection() {
    return union_type("Client.SettingsPayout.EditMode", [], EditMode, () => [[], [], []]);
}

export function Render() {
    let props_1, s, props_15, props_13, children, props_4, props_7, props_10, props_19, props_17;
    const patternInput = useFeliz_React__React_useState_Static_1505(new EditMode(0));
    const setEditMode = patternInput[1];
    const editMode = patternInput[0];
    if (isCurrentUserAdmin()) {
        const settings = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getPayoutSettings()), []);
        switch (settings.tag) {
            case 1: {
                return loader("big");
            }
            case 3: {
                return settings.fields[0].message;
            }
            case 2: {
                const settings_1 = settings.fields[0];
                const elms = ofArray([(props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("reports.payouts"), createElement("h1", {
                    className: "title",
                    children: s,
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1)))), (props_15 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-toggle"], ["className", "is-centered"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_4 = toList(delay(() => append(equals(editMode, new EditMode(0)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "is-normal-padding",
                    children: t("settings.accountNumbers"),
                    onClick: (_arg1) => {
                        setEditMode(new EditMode(0));
                    },
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_7 = toList(delay(() => append(equals(editMode, new EditMode(1)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "is-normal-padding",
                    children: t("settings.payoutSwedbankSettings"),
                    onClick: (_arg2) => {
                        setEditMode(new EditMode(1));
                    },
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7)))), (props_10 = toList(delay(() => append(equals(editMode, new EditMode(2)) ? singleton(["className", "is-active"]) : empty_1(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "is-normal-padding",
                    children: t("settings.payoutHandelsbankenSettings"),
                    onClick: (_arg3) => {
                        setEditMode(new EditMode(2));
                    },
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_10))))]), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_13))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_15)))), (props_19 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_17 = ofArray([["className", "is-6"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((editMode.tag === 2) ? singleton(createElement(XMLSettings_RenderPayoutXMLSettings, settings_1.PayoutXML)) : ((editMode.tag === 0) ? singleton(createElement(AccountNumbers_Render, null)) : singleton(createElement(SUSSettings_RenderPayoutSUSSettings, settings_1.PayoutSUS))))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_19))))]);
                return createElement("div", {
                    className: "container",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                });
            }
            default: {
                return loader("big");
            }
        }
    }
    else {
        return null;
    }
}

