import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { bool_type, option_type, int32_type, record_type, union_type, string_type, list_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { LotsReportFilter, LotsReportFilter$reflection, AuctionReportInformation$reflection, CategoryCatalog$reflection, AuctionsEntity$reflection } from "../Shared/Shared.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingQuery$1$reflection, Common_PagingResult$1$reflection } from "../Shared/Common.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { lotApi, auctionApi } from "../Communication.js";
import { cons, find, append, map, empty, singleton as singleton_2, ofArray, head, sortByDescending } from "../fable_modules/fable-library.3.6.1/List.js";
import { toLocalTime, compare } from "../fable_modules/fable-library.3.6.1/Date.js";
import { AuctionIdModule_toString, AuctionIdModule_fromString, ReservedPriceModule_toInt, CurrencyModule_fromInt, SubmissionIdModule_empty, CategoryIdModule_toString, CategoryIdModule_fromString, AuctionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Localized_translate, getPrimaryLanguage, t, getLocalizationContext } from "../Localization.js";
import { createObj, equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Cmd_OfFunc_result, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map as map_2, empty as empty_1, append as append_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { LocalizedStringModule_from } from "../bidflow/src/infrastructure/Localization.js";
import { map as map_1, value as value_90 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { Currency_formatN, Currency_formatNOption } from "../Common.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "../Loader.js";
import { date as date_1 } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { sv } from "date-fns/locale";
import { format as format_1 } from "date-fns";
import { TenderReportToPrint_toPrintSection } from "../Print/PrintButtons.js";
import { renderPagination } from "../Components/Pagination.js";

export class State extends Record {
    constructor(Auction, Auctions, Categories, Lots, Query) {
        super();
        this.Auction = Auction;
        this.Auctions = Auctions;
        this.Categories = Categories;
        this.Lots = Lots;
        this.Query = Query;
    }
}

export function State$reflection() {
    return record_type("TenderReport.State", [], State, () => [["Auction", class_type("System.Int64")], ["Auctions", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AuctionsEntity$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(AuctionsEntity$reflection())]], [["ErrorValue", string_type]]]))], ["Categories", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CategoryCatalog$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CategoryCatalog$reflection()]], [["ErrorValue", string_type]]]))], ["Lots", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(AuctionReportInformation$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(AuctionReportInformation$reflection())]], [["ErrorValue", string_type]]]))], ["Query", Common_PagingQuery$1$reflection(LotsReportFilter$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadAuctions", "LoadCategories", "LoadLots", "ChangeAuction", "PageChanged", "ChangeCategoryFilter", "ChangeNoBidFilter", "ChangeReservedPriceFilter", "OnColumnClicked"];
    }
}

export function Msg$reflection() {
    return union_type("TenderReport.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AuctionsEntity$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(AuctionsEntity$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CategoryCatalog$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CategoryCatalog$reflection()]], [["ErrorValue", string_type]]]))]], [["Item1", Common_PagingQuery$1$reflection(LotsReportFilter$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(AuctionReportInformation$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(AuctionReportInformation$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.Int64")]], [["Item", int32_type]], [["Item", option_type(int32_type)]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]]]);
}

export const Cmd_loadAuctionsData = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAllAuctions()), (_arg1) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, sortByDescending((a) => a.Date, _arg1, {
    Compare: (x, y) => compare(x, y),
}))))))), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_loadLots(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotApi().getLotsForReport(query)), (_arg1) => singleton.Return(new Msg(2, query, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(2, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_loadCategories(auctionId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getCategories(auctionId)), (_arg1) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(page) {
    return [new State(AuctionIdModule_empty, new Deferred$1(0), new Deferred$1(0), new Deferred$1(0), new Common_PagingQuery$1(new LotsReportFilter(AuctionIdModule_empty, void 0, false, false), page, 20, void 0, getLocalizationContext())), Cmd_fromAsync(Cmd_loadAuctionsData)];
}

export function update(msg, state) {
    let inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, s, inputRecord, inputRecord_1;
    if (msg.tag === 2) {
        if (msg.fields[1].tag === 1) {
            if (!equals(msg.fields[0], state.Query)) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.Auction, state.Auctions, state.Categories, new Deferred$1(2, msg.fields[1].fields[0]), state.Query), Cmd_none()];
            }
        }
        else {
            return [new State(state.Auction, state.Auctions, state.Categories, new Deferred$1(1), state.Query), Cmd_fromAsync(Cmd_loadLots(msg.fields[0]))];
        }
    }
    else if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.Auction, state.Auctions, new Deferred$1(2, msg.fields[0].fields[0]), state.Lots, state.Query), Cmd_OfFunc_result(new Msg(2, state.Query, new AsyncOperationStatus$1(0)))];
        }
        else {
            return [new State(state.Auction, state.Auctions, new Deferred$1(1), state.Lots, state.Query), Cmd_fromAsync(Cmd_loadCategories(state.Auction))];
        }
    }
    else if (msg.tag === 4) {
        let newQuery;
        const inputRecord_2 = state.Query;
        newQuery = (new Common_PagingQuery$1(inputRecord_2.Payload, msg.fields[0], inputRecord_2.PageSize, inputRecord_2.SortBy, inputRecord_2.LocalizationContext));
        return [new State(state.Auction, state.Auctions, state.Categories, state.Lots, newQuery), Cmd_fromAsync(Cmd_loadLots(newQuery))];
    }
    else if (msg.tag === 3) {
        return [new State(msg.fields[0], state.Auctions, state.Categories, state.Lots, (inputRecord_3 = state.Query, new Common_PagingQuery$1((inputRecord_4 = state.Query.Payload, new LotsReportFilter(msg.fields[0], inputRecord_4.ByCategory, inputRecord_4.ByNoBid, inputRecord_4.ByUnreachedReservedPrice)), 1, inputRecord_3.PageSize, inputRecord_3.SortBy, inputRecord_3.LocalizationContext))), Cmd_OfFunc_result(new Msg(1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 5) {
        const newState = new State(state.Auction, state.Auctions, state.Categories, state.Lots, (inputRecord_5 = state.Query, new Common_PagingQuery$1((inputRecord_6 = state.Query.Payload, new LotsReportFilter(inputRecord_6.AuctionId, msg.fields[0], inputRecord_6.ByNoBid, inputRecord_6.ByUnreachedReservedPrice)), 1, inputRecord_5.PageSize, inputRecord_5.SortBy, inputRecord_5.LocalizationContext)));
        return [newState, Cmd_OfFunc_result(new Msg(2, newState.Query, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 6) {
        const newState_1 = new State(state.Auction, state.Auctions, state.Categories, state.Lots, (inputRecord_7 = state.Query, new Common_PagingQuery$1((inputRecord_8 = state.Query.Payload, new LotsReportFilter(inputRecord_8.AuctionId, inputRecord_8.ByCategory, msg.fields[0], inputRecord_8.ByUnreachedReservedPrice)), 1, inputRecord_7.PageSize, inputRecord_7.SortBy, inputRecord_7.LocalizationContext)));
        return [newState_1, Cmd_OfFunc_result(new Msg(2, newState_1.Query, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 7) {
        const newState_2 = new State(state.Auction, state.Auctions, state.Categories, state.Lots, (inputRecord_9 = state.Query, new Common_PagingQuery$1((inputRecord_10 = state.Query.Payload, new LotsReportFilter(inputRecord_10.AuctionId, inputRecord_10.ByCategory, inputRecord_10.ByNoBid, msg.fields[0])), 1, inputRecord_9.PageSize, inputRecord_9.SortBy, inputRecord_9.LocalizationContext)));
        return [newState_2, Cmd_OfFunc_result(new Msg(2, newState_2.Query, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 8) {
        let newQuery_1;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_11 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_11.Payload, inputRecord_11.Page, inputRecord_11.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_11.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_12 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_12.Payload, inputRecord_12.Page, inputRecord_12.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_12.LocalizationContext));
                break;
            }
        }
        return [new State(state.Auction, state.Auctions, state.Categories, state.Lots, newQuery_1), Cmd_OfFunc_result(new Msg(2, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.fields[0].tag === 1) {
        const auctionId = (msg.fields[0].fields[0].tag === 1) ? AuctionIdModule_empty : head(msg.fields[0].fields[0].fields[0]).Id;
        return [new State(auctionId, new Deferred$1(2, msg.fields[0].fields[0]), state.Categories, state.Lots, (inputRecord = state.Query, new Common_PagingQuery$1((inputRecord_1 = state.Query.Payload, new LotsReportFilter(auctionId, inputRecord_1.ByCategory, inputRecord_1.ByNoBid, inputRecord_1.ByUnreachedReservedPrice)), inputRecord.Page, inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext))), Cmd_OfFunc_result(new Msg(1, new AsyncOperationStatus$1(0)))];
    }
    else {
        return [new State(state.Auction, new Deferred$1(1), state.Categories, state.Lots, state.Query), Cmd_fromAsync(Cmd_loadAuctionsData)];
    }
}

export function TableHeader(tableHeaderInputProps) {
    let props_28, props_8, props_10, props_12, props_14, props_16, props_18, props_20, props_22, props_24, props_26;
    const unitVar2 = tableHeaderInputProps.unitVar2;
    const dispatch = tableHeaderInputProps.dispatch;
    const state = tableHeaderInputProps.state;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(8, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_1(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_1(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_1(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    const props_30 = ofArray([["className", "is-fluid"], ["className", "mb-2"], ["className", "pt-4"], ["children", Interop_reactApi.Children.toArray([(props_28 = ofArray([["className", "is-vcentered"], ["className", "is-variable"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-1"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.lot"), "LotNumber")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-1"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.itemId"), "ItemId")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-1"], ["style", {
        marginRight: 32,
    }], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.submissionId"), "SubmissionId")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-3"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.lotTitle"), "Name")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.bidders"), "BiddersCount")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_18 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("reports.highest"), "MaxBid")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), (props_20 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("reports.next"), "BeforeMaxBid")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_20)))), (props_22 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.estimatedPrice"), "EstimatedPrice")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22)))), (props_24 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("reports.actualBid"), "ActualBid")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_24)))), (props_26 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.reservedPrice"), "ReservedPrice")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_28))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_30)));
}

export function CategoryDropdown(categoryDropdownInputProps) {
    const categoryChanged = categoryDropdownInputProps.categoryChanged;
    const categoryState = categoryDropdownInputProps.categoryState;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(LocalizedStringModule_from(singleton_2([getPrimaryLanguage(), t("reports.allCategories")])));
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const id = patternInput_2[0];
    let categories;
    if (categoryState.tag === 1) {
        categories = empty();
    }
    else if (categoryState.tag === 2) {
        if (categoryState.fields[0].tag === 0) {
            const c = map((x) => ({
                Id: x.Id,
                Name: x.Name,
            }), categoryState.fields[0].fields[0].Categories);
            categories = append(singleton_2({
                Id: void 0,
                Name: LocalizedStringModule_from(singleton_2([getPrimaryLanguage(), t("reports.allCategories")])),
            }), c);
        }
        else {
            categories = empty();
        }
    }
    else {
        categories = empty();
    }
    const patternInput_3 = Helpers_extractClasses(toList(delay(() => append_1((equals(categoryState, new Deferred$1(0)) ? true : equals(categoryState, new Deferred$1(1))) ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append_1(singleton_1(["className", "is-fullwidth"]), delay(() => append_1(singleton_1(["className", "has-text-left"]), delay(() => append_1(singleton_1(["className", "mb-1"]), delay(() => append_1(singleton_1(["onChange", (ev) => {
        const x_1 = ev.target.value;
        const categoryId = (x_1 === "") ? (void 0) : CategoryIdModule_fromString(x_1);
        const category = find((c_1) => equals(c_1.Id, categoryId), categories);
        patternInput_1[1](category.Name);
        patternInput_2[1](category.Id);
        patternInput[1](!patternInput[0]);
        categoryChanged(category.Id);
    }]), delay(() => append_1(singleton_1(["value", (id != null) ? CategoryIdModule_toString(value_90(id)) : ""]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map((category_1) => createElement("option", {
        value: (category_1.Id != null) ? CategoryIdModule_toString(value_90(category_1.Id)) : "",
        children: Localized_translate(category_1.Name),
    }), categories)))))]))))))))))))))));
    return createElement("div", {
        className: join(" ", cons("select", patternInput_3[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_3[1]))]),
    });
}

export function itemTable(lot) {
    let props_20, props, props_2, props_4, props_6, props_8, props_10, props_12, props_14, props_16, props_18;
    const props_22 = ofArray([["className", "mb-2"], ["className", "py-3"], ["style", {
        borderRadius: 4,
    }], ["className", "is-size-6"], ["className", "is-fluid"], ["className", "has-background-white"], ["children", Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", "is-vcentered"], ["className", "has-text-grey"], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-1"], ["className", "has-text-left"], ["children", `${lot.LotNumber}`]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-1"], ["className", "has-text-left"], ["children", `${lot.ItemId}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "is-1"], ["style", {
        marginRight: 32,
    }], ["className", "has-text-left"], ["children", equals_1(lot.ItemSubmissionId, SubmissionIdModule_empty) ? "" : (`${lot.ItemSubmissionId}`)]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-3"], ["className", "has-text-left"], ["className", "is-text-overflow-hidden"], ["children", Localized_translate(lot.Name)]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", lot.BiddersCount]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Currency_formatNOption("-", map_1((x) => CurrencyModule_fromInt(x.Bid), lot.MaxBid))]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Currency_formatNOption("-", map_1((x_2) => CurrencyModule_fromInt(x_2.Bid), lot.BeforeMaxBid))]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", `${Currency_formatN(CurrencyModule_fromInt(lot.EstimatedPrice))}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Currency_formatNOption("-", map_1((x_5) => CurrencyModule_fromInt(x_5.Bid), lot.ActualBid))]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_18 = ofArray([["className", "is-1"], ["style", {
        textAlign: "center",
    }], ["children", Currency_formatNOption("-", map_1((arg) => CurrencyModule_fromInt(ReservedPriceModule_toInt(arg)), lot.ReservedPrice))]]), createElement("div", createObj(Helpers_combineClasses("column", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_20))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_22)));
}

export function Render(renderInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderInputProps.page), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const elms_3 = toList(delay(() => {
        let props_2, s, s_1;
        return append_1(singleton_1((props_2 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("reports.tenderReport"), createElement("h1", {
            className: "title",
            children: s,
        })), (s_1 = t("reports.tenderReportSubtitle"), createElement("h2", {
            className: "subtitle",
            children: s_1,
        }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_2))))), delay(() => {
            let props_24;
            const matchValue = state_1.Auctions;
            return (matchValue.tag === 1) ? singleton_1(loader("big")) : ((matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? singleton_1((props_24 = ofArray([["className", "pt-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props_19, props_10, patternInput_1, elms, elms_1, props_13, elms_2, props_16;
                return append_1(singleton_1((props_19 = ofArray([["className", "is-uppercase"], ["className", "is-grouped"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "is-size-6"], ["className", "has-text-grey"], ["onChange", (ev) => {
                    dispatch(new Msg(3, AuctionIdModule_fromString(ev.target.value)));
                }], ["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg_1) => createElement("option", {
                    value: AuctionIdModule_toString(tupledArg_1[0]),
                    children: tupledArg_1[1],
                }), map((tupledArg) => {
                    let date, copyOfStruct, locale, formatOptions;
                    return [tupledArg[0], (Localized_translate(tupledArg[1]) + ", ") + ((date = ((copyOfStruct = date_1(tupledArg[2]), toLocalTime(copyOfStruct))), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_1(date, "dd MMMM yyyy", formatOptions))))))];
                }, map((x_1) => [x_1.Id, x_1.Name, x_1.Date], matchValue.fields[0].fields[0])))))]])), createElement("div", {
                    className: join(" ", cons("select", patternInput_1[0])),
                    children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_10)))), (elms = singleton_2(createElement(CategoryDropdown, {
                    categoryState: state_1.Categories,
                    categoryChanged: (id_2) => {
                        dispatch(new Msg(5, id_2));
                    },
                })), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (elms_1 = singleton_2((props_13 = toList(delay(() => append_1(singleton_1(["className", "is-primary"]), delay(() => append_1((!state_1.Query.Payload.ByNoBid) ? singleton_1(["className", "is-light"]) : empty_1(), delay(() => append_1(singleton_1(["children", t("reports.lotsWithoutBids")]), delay(() => singleton_1(["onClick", (x_5) => {
                    x_5.preventDefault();
                    dispatch(new Msg(6, !state_1.Query.Payload.ByNoBid));
                }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_13))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                })), (elms_2 = singleton_2((props_16 = toList(delay(() => append_1(singleton_1(["className", "is-primary"]), delay(() => append_1((!state_1.Query.Payload.ByUnreachedReservedPrice) ? singleton_1(["className", "is-light"]) : empty_1(), delay(() => append_1(singleton_1(["children", t("reports.reservedPriceNotReached")]), delay(() => singleton_1(["onClick", (x_6) => {
                    x_6.preventDefault();
                    dispatch(new Msg(7, !state_1.Query.Payload.ByUnreachedReservedPrice));
                }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_16))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                })), createElement(TenderReportToPrint_toPrintSection, state_1.Query)])]]), createElement("div", createObj(Helpers_combineClasses("field", props_19))))), delay(() => append_1(singleton_1(createElement(TableHeader, {
                    state: state_1,
                    dispatch: dispatch,
                })), delay(() => {
                    let props_22;
                    const matchValue_1 = state_1.Lots;
                    return (matchValue_1.tag === 1) ? singleton_1(loader("big")) : ((matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? append_1(equals(matchValue_1.fields[0].fields[0].Result, empty()) ? singleton_1((props_22 = ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", t("auction.noLots")]]), createElement("p", createObj(Helpers_combineClasses("", props_22))))) : map_2((lot) => itemTable(lot), matchValue_1.fields[0].fields[0].Result), delay(() => singleton_1(renderPagination(matchValue_1.fields[0].fields[0].Page, matchValue_1.fields[0].fields[0].Total, matchValue_1.fields[0].fields[0].PageSize, (arg_2) => {
                        dispatch(new Msg(4, arg_2));
                    })))) : singleton_1(createElement("div", {
                        children: [matchValue_1.fields[0].fields[0]],
                    }))) : singleton_1(null));
                }))));
            }))))]]), createElement("section", createObj(Helpers_combineClasses("section", props_24))))) : singleton_1(createElement("div", {
                children: [matchValue.fields[0].fields[0]],
            }))) : singleton_1(null));
        }));
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    });
}

