import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { ItemWithNoAuction$reflection, AuctionStatistic$reflection, ResellLotStatus, ResellLotStatus$reflection } from "./Shared/Shared.js";
import { union_type, string_type, int32_type, unit_type, class_type, record_type, bool_type, option_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { isEmpty, singleton as singleton_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingResult$1$reflection, Common_PagingQuery$1$reflection } from "./Shared/Common.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { itemApi } from "./Communication.js";
import { Localized_translate, t, getLocalizationContext } from "./Localization.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map as map_1, append, empty, singleton as singleton_2, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { Image_large, Image_smallContains, Image_imagePlaceholder } from "./Css.js";
import { map, value as value_125 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { ReservedPriceModule_toInt, CurrencyModule_fromInt, ItemIdModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { Currency_formatNOption, Currency_formatN } from "./Common.js";
import { ActionType, CreateOrResellLotComponent } from "./Components/CreateLot.js";
import { navigate, Route, toPath } from "./Router.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "./Loader.js";
import { renderPagination } from "./Components/Pagination.js";

export class ToggleStatus extends Record {
    constructor(Status, IsCheck) {
        super();
        this.Status = Status;
        this.IsCheck = IsCheck;
    }
}

export function ToggleStatus$reflection() {
    return record_type("Client.WorkDepot.ToggleStatus", [], ToggleStatus, () => [["Status", option_type(ResellLotStatus$reflection())], ["IsCheck", bool_type]]);
}

export const toggleButtonDeliveryStatus = ofArray([new ToggleStatus(void 0, true), new ToggleStatus(new ResellLotStatus(0), false), new ToggleStatus(new ResellLotStatus(1), false), new ToggleStatus(new ResellLotStatus(2), false)]);

export class State extends Record {
    constructor(AuctionId, Query, CurrentPage, SearchString, Statistic, Items) {
        super();
        this.AuctionId = AuctionId;
        this.Query = Query;
        this.CurrentPage = (CurrentPage | 0);
        this.SearchString = SearchString;
        this.Statistic = Statistic;
        this.Items = Items;
    }
}

export function State$reflection() {
    return record_type("Client.WorkDepot.State", [], State, () => [["AuctionId", class_type("System.Int64")], ["Query", Common_PagingQuery$1$reflection(unit_type)], ["CurrentPage", int32_type], ["SearchString", string_type], ["Statistic", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionStatistic$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionStatistic$reflection()]], [["ErrorValue", string_type]]]))], ["Items", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(ItemWithNoAuction$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(ItemWithNoAuction$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdatePageState", "PageChanged", "OnColumnClickedUnsoldItems", "SearchStringChange", "LoadItemsWithNoAuctions"];
    }
}

export function Msg$reflection() {
    return union_type("Client.WorkDepot.Msg", [], Msg, () => [[["Item", string_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item1", Common_PagingQuery$1$reflection(unit_type)], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(ItemWithNoAuction$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(ItemWithNoAuction$reflection())]], [["ErrorValue", string_type]]]))]]]);
}

export function loadItems(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(itemApi().getItemsNotParticipatedInAuction(query)), (_arg1) => singleton.Return(new Msg(4, query, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(4, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(auctionId, page) {
    const query = new Common_PagingQuery$1(void 0, page, 20, void 0, getLocalizationContext());
    return [new State(auctionId, query, page, "", new Deferred$1(0), new Deferred$1(0)), Cmd_batch(singleton_1(Cmd_OfFunc_result(new Msg(4, query, new AsyncOperationStatus$1(0)))))];
}

export function update(msg, state) {
    let s;
    if (msg.tag === 1) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Common_PagingQuery$1(inputRecord.Payload, msg.fields[0], inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(state.AuctionId, newQuery, msg.fields[0], state.SearchString, state.Statistic, state.Items), Cmd_OfFunc_result(new Msg(4, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 2) {
        let newQuery_1;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_1 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, inputRecord_1.Page, inputRecord_1.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_1.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_2 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, inputRecord_2.Page, inputRecord_2.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_2.LocalizationContext));
                break;
            }
        }
        return [new State(state.AuctionId, newQuery_1, state.CurrentPage, state.SearchString, state.Statistic, state.Items), Cmd_OfFunc_result(new Msg(4, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 3) {
        return [new State(state.AuctionId, state.Query, state.CurrentPage, msg.fields[0], state.Statistic, state.Items), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[1].tag === 1) {
            if (!equals(msg.fields[0], state.Query)) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.AuctionId, state.Query, state.CurrentPage, state.SearchString, state.Statistic, new Deferred$1(2, msg.fields[1].fields[0])), Cmd_none()];
            }
        }
        else {
            return [new State(state.AuctionId, state.Query, state.CurrentPage, state.SearchString, state.Statistic, new Deferred$1(1)), Cmd_fromAsync(loadItems(msg.fields[0]))];
        }
    }
    else {
        window.open(msg.fields[0]);
        return init(state.AuctionId, state.CurrentPage);
    }
}

export function tableHeader(state, dispatch) {
    let props_24, props_8, props_10, props_12, props_14, props_16, props_18;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(2, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_2(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_2(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    const props_26 = ofArray([["className", "my-4"], ["className", "px-0"], ["className", "mx-0"], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "px-0"], ["className", "mx-0"], ["className", "my-0"], ["className", "is-gapless"], ["className", "is-vcentered"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["style", {
        width: 20,
        maxWidth: 70,
    }], ["className", "ml-2"]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.itemId"), "id")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.lotTitle"), "title")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.category"), "category")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.estimatedPriceColumn"), "estimated")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_18 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.reservedPriceColumn"), "reservedPrice")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-1"])))), createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-1"]))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_24))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_26)));
}

export function TableLine(tableLineInputProps) {
    let props_21, props_1, props_3, props_5, props_7, props_9, props_11, props_13, props_19, props_16, props_28;
    const updateAuctionPageStateAndOpenNewTab = tableLineInputProps.updateAuctionPageStateAndOpenNewTab;
    const item = tableLineInputProps.item;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowImage = patternInput_1[1];
    const isShowImage = patternInput_1[0];
    return createElement("div", {
        style: {
            marginBottom: 20,
            borderRadius: 0,
            backgroundColor: "#FFFFFF",
        },
        className: "py-0",
        className: "px-0",
        children: Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "is-variable"], ["className", "is-1"], ["className", "is-vcentered"], ["className", "is-centered"], ["className", "is-size-6"], ["style", {
            marginBottom: 0,
        }], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "is-narrow"], ["className", "ml-2"], ["className", "py-1"], ["style", createObj(toList(delay(() => ((item.Image != null) ? singleton_2(["cursor", "zoom-in"]) : empty()))))], ["children", Interop_reactApi.Children.toArray([createElement("img", createObj(toList(delay(() => append((item.Image != null) ? singleton_2(["onClick", (_arg1) => {
            setShowImage(!isShowImage);
        }]) : empty(), delay(() => append(singleton_2(["style", {
            display: "flex",
            maxWidth: 62,
        }]), delay(() => ((item.Image == null) ? append(singleton_2(["className", "workDepotErrorPlaceholderImage"]), delay(() => singleton_2(["src", Image_imagePlaceholder]))) : singleton_2(["src", Image_smallContains(value_125(item.Image))]))))))))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (props_3 = ofArray([["onClick", (_arg2) => {
            setVisible(!isVisible);
        }], ["className", "py-1"], ["className", "has-text-left"], ["children", ItemIdModule_toString(item.Id)]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_5 = ofArray([["onClick", (_arg3) => {
            setVisible(!isVisible);
        }], ["className", "py-1"], ["className", "is-2"], ["className", "has-text-left"], ["children", Localized_translate(item.Title)]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_7 = ofArray([["onClick", (_arg4) => {
            setVisible(!isVisible);
        }], ["className", "py-1"], ["className", "is-2"], ["children", Localized_translate(item.Category.Name)], ["className", "is-text-overflow-hidden"]]), createElement("div", createObj(Helpers_combineClasses("column", props_7)))), (props_9 = ofArray([["onClick", (_arg5) => {
            setVisible(!isVisible);
        }], ["className", "py-1"], ["className", "is-1"], ["className", "has-text-right"], ["children", Currency_formatN(item.EstimatedPrice)], ["className", "is-text-overflow-hidden"]]), createElement("div", createObj(Helpers_combineClasses("column", props_9)))), (props_11 = ofArray([["onClick", (_arg6) => {
            setVisible(!isVisible);
        }], ["className", "py-1"], ["className", "is-1"], ["className", "has-text-right"], ["children", Currency_formatNOption("-", map((arg) => CurrencyModule_fromInt(ReservedPriceModule_toInt(arg)), item.ReservedPrice))]]), createElement("div", createObj(Helpers_combineClasses("column", props_11)))), (props_13 = ofArray([["className", "py-1"], ["className", "is-1"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => singleton_2(createElement(CreateOrResellLotComponent, {
            itemId: item.Id,
            disabled: false,
            auctionPageAndFilterState: void 0,
            updateAuctionStateAndOpenNewTab: updateAuctionPageStateAndOpenNewTab,
            actionType: new ActionType(2, item.Id, item.Category),
        }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_19 = ofArray([["className", "is-1"], ["className", "has-background-grey-lighter"], ["className", "py-0"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: "edit_button_href_work_depot",
            href: toPath(new Route(15, item.Id)),
            children: Interop_reactApi.Children.toArray([(props_16 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-sign-in-alt", "fa-lg"]),
            })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_16))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_19))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_21)))), (props_28 = toList(delay(() => append(isShowImage ? singleton_2(["className", "is-active"]) : empty(), delay(() => {
            let elms_1, elms;
            return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg7) => {
                setShowImage(!isShowImage);
            }])))), (elms_1 = singleton_1((elms = singleton_1(createElement("img", createObj(toList(delay(() => singleton_2(["src", (item.Image == null) ? Image_imagePlaceholder : Image_large(value_125(item.Image))])))))), createElement("div", {
                className: "card-image",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), createElement("div", {
                className: "modal-content",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]);
        })))), createElement("div", createObj(Helpers_combineClasses("modal", props_28))))]),
    });
}

export function Render(renderInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderInputProps.auctionId, renderInputProps.page), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_8 = ofArray([["className", "pt-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_2, props;
        return append(singleton_2((props_2 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-primary"], ["children", `+ ${t("auction.addItem")}`], ["onClick", (x) => {
            x.preventDefault();
            navigate(toPath(new Route(6, void 0, void 0)));
        }]]), createElement("a", createObj(Helpers_combineClasses("button", props))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_2))))), delay(() => append(singleton_2(tableHeader(state_1, dispatch)), delay(() => {
            let props_5;
            const matchValue = state_1.Items;
            let pattern_matching_result, ex, items;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 2;
                    items = matchValue.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    ex = matchValue.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(loader("small"));
                }
                case 1: {
                    return singleton_2(createElement("h1", {
                        children: [ex],
                    }));
                }
                case 2: {
                    return append(isEmpty(items.Result) ? singleton_2((props_5 = ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", t("auction.noItems")]]), createElement("p", createObj(Helpers_combineClasses("", props_5))))) : empty(), delay(() => {
                        let elms;
                        return append(singleton_2((elms = toList(delay(() => map_1((item) => createElement(TableLine, {
                            item: item,
                            updateAuctionPageStateAndOpenNewTab: (url) => {
                                dispatch(new Msg(0, url));
                            },
                        }), items.Result))), createElement("div", {
                            className: "container",
                            children: Interop_reactApi.Children.toArray(Array.from(elms)),
                        }))), delay(() => singleton_2(renderPagination(items.Page, items.Total, items.PageSize, (arg) => {
                            dispatch(new Msg(1, arg));
                        }))));
                    }));
                }
            }
        }))));
    }))))]]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_8)));
}

