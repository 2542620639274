import { Autocomplete, Props, FoundResult, Props_get_Default } from "./Autocomplete.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { KeyValuePair_create, Remoting_handleNonAuth } from "../Extensions.js";
import { submissionsApi } from "../Communication.js";
import { map } from "../fable_modules/fable-library.3.6.1/List.js";
import { UserIdModule_fromString, SubmissionIdModule_fromString, UserIdModule_toString, SubmissionIdModule_toString } from "../bidflow/src/infrastructure/Auction.js";
import { toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { sv } from "date-fns/locale";
import { format as format_1 } from "date-fns";
import { toInt } from "../fable_modules/fable-library.3.6.1/Long.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { iterate } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { map as map_1, defaultArg, toArray } from "../fable_modules/fable-library.3.6.1/Option.js";
import { createElement } from "react";

export function SearchSubmission(searchSubmissionInputProps) {
    const isStartSearchOnInit = searchSubmissionInputProps.isStartSearchOnInit;
    const isAutofocus = searchSubmissionInputProps.isAutofocus;
    const dependencies = searchSubmissionInputProps.dependencies;
    const onInputRef = searchSubmissionInputProps.onInputRef;
    const onSelect = searchSubmissionInputProps.onSelect;
    const selected = searchSubmissionInputProps.selected;
    let ps;
    const inputRecord = Props_get_Default();
    ps = (new Props(500, (s) => singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(submissionsApi().search(s)), (_arg1) => {
        const result = _arg1;
        const items = map((x) => {
            let submission, date_1, date, locale, formatOptions;
            return KeyValuePair_create([SubmissionIdModule_toString(x.SubmissionId), UserIdModule_toString(x.SellerId), x.SellerName], (submission = x, (date_1 = ((date = toLocalTime(submission.SubmittedDate), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_1(date, "dd MMMM yyyy", formatOptions)))))), `${submission.SubmissionId} / ${submission.SellerName} / ${date_1} / ${submission.Warehouse} / ${submission.Size}`)));
        }, result.Result);
        return singleton.Return(new FSharpResult$2(0, new FoundResult(s, ~(~toInt(result.Total)), items)));
    })), (_arg2) => singleton.Return(new FSharpResult$2(1, _arg2.message)))), (option_2) => {
        iterate((tupledArg) => {
            onSelect([SubmissionIdModule_fromString(tupledArg[0]), UserIdModule_fromString(tupledArg[1]), tupledArg[2]]);
        }, toArray(option_2));
    }, (_arg1_1) => {
    }, inputRecord.OnBlur, defaultArg(map_1((x_3) => SubmissionIdModule_toString(x_3), selected), ""), isAutofocus, onInputRef, inputRecord.Localization, isStartSearchOnInit, 5, true));
    return createElement(Autocomplete, {
        props: ps,
        dependencies: dependencies,
    });
}

