import { Union, Record } from "../../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, unit_type, union_type, string_type, bool_type, class_type } from "../../fable_modules/fable-library.3.6.1/Reflection.js";
import { Agreement_InfoCompany, User_ContactPersonInfo, User_CompanyInfo, Agreement_InfoPerson, User_PersonalInfo, Agreement_AgreementType, CommonQueries_CompanyBasicSettings, Agreement_AgreementTab, CommonQueries_CompanyBasicSettings$reflection, Agreement_AgreementTab$reflection, Agreement_AgreementType$reflection } from "../../Shared/Shared.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../../Extensions.js";
import { Cmd_none, Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { agreementApi, commonApi } from "../../Communication.js";
import { t } from "../../Localization.js";
import { createObj, equals } from "../../fable_modules/fable-library.3.6.1/Util.js";
import { SellerPerson_validateAgreement, SellerCompany_validateAgreement } from "../../Validation.js";
import { CountryCodeModule_empty } from "../../bidflow/src/infrastructure/Auction.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "../../Loader.js";
import { createElement } from "react";
import { dividedTop } from "../BorderDividedLine.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library.3.6.1/Seq.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { singleton as singleton_2, ofArray } from "../../fable_modules/fable-library.3.6.1/List.js";
import { ProfileComponent } from "./AgreementCompanyProfile.js";
import { ProfileComponent as ProfileComponent_1 } from "./AgreementPersonProfile.js";

export class State extends Record {
    constructor(AgreementId, AgreementType, OriginalAgreementType, AgreementTab, IsEditProfile, ResidentCompanySettings, SaveFormOperation) {
        super();
        this.AgreementId = AgreementId;
        this.AgreementType = AgreementType;
        this.OriginalAgreementType = OriginalAgreementType;
        this.AgreementTab = AgreementTab;
        this.IsEditProfile = IsEditProfile;
        this.ResidentCompanySettings = ResidentCompanySettings;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function State$reflection() {
    return record_type("Components.AgreementProfile.State", [], State, () => [["AgreementId", class_type("System.Int64")], ["AgreementType", Agreement_AgreementType$reflection()], ["OriginalAgreementType", Agreement_AgreementType$reflection()], ["AgreementTab", Agreement_AgreementTab$reflection()], ["IsEditProfile", bool_type], ["ResidentCompanySettings", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CommonQueries_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CommonQueries_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadBasicSettings", "SwitchEditProfile", "CancelChangesProfile", "TypeTabChanged", "AgreementInfoChanged", "ModifyInfo"];
    }
}

export function Msg$reflection() {
    return union_type("Components.AgreementProfile.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CommonQueries_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CommonQueries_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))]], [], [], [["Item", Agreement_AgreementTab$reflection()]], [["Item", Agreement_AgreementType$reflection()]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function init(agreementType, agreementId) {
    return [new State(agreementId, agreementType, agreementType, (agreementType.tag === 1) ? (new Agreement_AgreementTab(1)) : (new Agreement_AgreementTab(0)), false, new Deferred$1(0), new Deferred$1(0)), Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0)))];
}

export function loadResidentCountry() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg1) => {
        const settings = _arg1;
        return singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, new CommonQueries_CompanyBasicSettings(settings.ResidentCountry, settings.CollectPersonalNumber)))));
    })), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function saveInfo(agreementId, agreementType) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind((agreementType.tag === 1) ? agreementApi().modifyCompanyInfo([agreementId, agreementType.fields[0]]) : agreementApi().modifyPersonalInfo([agreementId, agreementType.fields[0]]), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.agreementDoesNotExist"))))) : singleton.Return(new Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function update(msg, state) {
    let matchValue, info_1, info, settings_1, settings;
    if (msg.tag === 3) {
        return [new State(state.AgreementId, (matchValue = state.AgreementType, (matchValue.tag === 1) ? ((info_1 = matchValue.fields[0], (msg.fields[0].tag === 1) ? (new Agreement_AgreementType(1, info_1)) : (new Agreement_AgreementType(0, new Agreement_InfoPerson(info_1.Email, new User_PersonalInfo(info_1.ContactPerson.FirstName, info_1.ContactPerson.LastName, info_1.ContactPerson.Phone, ""), info_1.Address, info_1.BankInfo, info_1.PayoutAccount))))) : ((info = matchValue.fields[0], (msg.fields[0].tag === 1) ? (new Agreement_AgreementType(1, new Agreement_InfoCompany(info.Email, new User_CompanyInfo("", "", "", true), info.BankInfo, info.Address, new User_ContactPersonInfo(info.PersonalInfo.FirstName, info.PersonalInfo.LastName, info.PersonalInfo.Phone, info.Email), info.PayoutAccount))) : (new Agreement_AgreementType(0, info))))), state.OriginalAgreementType, msg.fields[0], state.IsEditProfile, state.ResidentCompanySettings, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new State(state.AgreementId, state.AgreementType, state.OriginalAgreementType, state.AgreementTab, true, state.ResidentCompanySettings, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new State(state.AgreementId, state.OriginalAgreementType, state.OriginalAgreementType, (state.OriginalAgreementType.tag === 1) ? (new Agreement_AgreementTab(1)) : (new Agreement_AgreementTab(0)), false, state.ResidentCompanySettings, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new State(state.AgreementId, msg.fields[0], state.OriginalAgreementType, state.AgreementTab, state.IsEditProfile, state.ResidentCompanySettings, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 0) {
                return [new State(state.AgreementId, state.AgreementType, state.AgreementType, state.AgreementTab, false, state.ResidentCompanySettings, new Deferred$1(2, new FSharpResult$2(0, void 0))), Cmd_none()];
            }
            else {
                return [new State(state.AgreementId, state.AgreementType, state.OriginalAgreementType, state.AgreementTab, state.IsEditProfile, state.ResidentCompanySettings, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
        }
        else if (!equals(state.AgreementType, state.OriginalAgreementType)) {
            const matchValue_2 = state.AgreementType;
            if (matchValue_2.tag === 1) {
                const matchValue_4 = SellerCompany_validateAgreement((settings_1 = state.ResidentCompanySettings, (settings_1.tag === 1) ? CountryCodeModule_empty : ((settings_1.tag === 2) ? ((settings_1.fields[0].tag === 0) ? settings_1.fields[0].fields[0].ResidentCountry : CountryCodeModule_empty) : CountryCodeModule_empty)), matchValue_2.fields[0]);
                if (matchValue_4.tag === 1) {
                    return [new State(state.AgreementId, state.AgreementType, state.OriginalAgreementType, state.AgreementTab, state.IsEditProfile, state.ResidentCompanySettings, new Deferred$1(0)), Cmd_none()];
                }
                else {
                    return [new State(state.AgreementId, state.AgreementType, state.OriginalAgreementType, state.AgreementTab, state.IsEditProfile, state.ResidentCompanySettings, new Deferred$1(1)), Cmd_fromAsync(saveInfo(state.AgreementId, state.AgreementType))];
                }
            }
            else {
                const matchValue_3 = SellerPerson_validateAgreement((settings = state.ResidentCompanySettings, (settings.tag === 1) ? (void 0) : ((settings.tag === 2) ? ((settings.fields[0].tag === 0) ? settings.fields[0].fields[0] : (void 0)) : (void 0))), matchValue_2.fields[0]);
                if (matchValue_3.tag === 1) {
                    return [new State(state.AgreementId, state.AgreementType, state.OriginalAgreementType, state.AgreementTab, state.IsEditProfile, state.ResidentCompanySettings, new Deferred$1(0)), Cmd_none()];
                }
                else {
                    return [new State(state.AgreementId, state.AgreementType, state.OriginalAgreementType, state.AgreementTab, state.IsEditProfile, state.ResidentCompanySettings, new Deferred$1(1)), Cmd_fromAsync(saveInfo(state.AgreementId, state.AgreementType))];
                }
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new State(state.AgreementId, state.AgreementType, state.OriginalAgreementType, state.AgreementTab, state.IsEditProfile, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.SaveFormOperation), Cmd_none()];
        }
        else {
            return [new State(state.AgreementId, state.AgreementType, state.OriginalAgreementType, state.AgreementTab, state.IsEditProfile, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.SaveFormOperation), Cmd_none()];
        }
    }
    else {
        return [new State(state.AgreementId, state.AgreementType, state.OriginalAgreementType, state.AgreementTab, state.IsEditProfile, new Deferred$1(1), state.SaveFormOperation), Cmd_fromAsync(loadResidentCountry())];
    }
}

export function CommonProfileComponent(commonProfileComponentInputProps) {
    const agreementId = commonProfileComponentInputProps.agreementId;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(commonProfileComponentInputProps.info, agreementId), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const matchValue = state_1.ResidentCompanySettings;
    let pattern_matching_result, error, settings;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 2;
            settings = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
            error = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return loader("big");
        }
        case 1: {
            return createElement("div", {
                children: [error],
            });
        }
        case 2: {
            const props_25 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let dispatch_1, state_2, elms, props_9, props_7, children, props_1, props_4;
                return append(singleton_1((dispatch_1 = dispatch, (state_2 = state_1, (elms = singleton_2((props_9 = ofArray([["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-uppercase"], ["className", "is-small"], ["className", "is-toggle"], ["className", "my-0"], ["className", "mt-5"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_1 = toList(delay(() => append(equals(state_2.AgreementTab, new Agreement_AgreementTab(0)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => append(state_2.IsEditProfile ? singleton_1(["style", {
                    cursor: "pointer",
                }]) : singleton_1(["className", "disabled"]), delay(() => append(singleton_1(["className", "has-text-grey-dark"]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", createObj(toList(delay(() => append(singleton_1(["children", "Person"]), delay(() => append((!state_2.IsEditProfile) ? singleton_1(["style", {
                    pointerEvents: "none",
                }]) : empty(), delay(() => singleton_1(["onClick", (x) => {
                    x.preventDefault();
                    dispatch_1(new Msg(3, new Agreement_AgreementTab(0)));
                }])))))))))])]))))))))), createElement("li", createObj(Helpers_combineClasses("", props_1)))), (props_4 = toList(delay(() => append(state_2.IsEditProfile ? singleton_1(["style", {
                    cursor: "pointer",
                }]) : singleton_1(["className", "disabled"]), delay(() => append(singleton_1(["className", "has-text-grey-dark"]), delay(() => append(equals(state_2.AgreementTab, new Agreement_AgreementTab(1)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", createObj(toList(delay(() => append(singleton_1(["children", "Company"]), delay(() => append((!state_2.IsEditProfile) ? singleton_1(["style", {
                    pointerEvents: "none",
                }]) : empty(), delay(() => singleton_1(["onClick", (x_1) => {
                    x_1.preventDefault();
                    dispatch_1(new Msg(3, new Agreement_AgreementTab(1)));
                }])))))))))])]))))))))), createElement("li", createObj(Helpers_combineClasses("", props_4))))]), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9))))), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))))), delay(() => {
                    let matchValue_1;
                    return append((matchValue_1 = state_1.AgreementType, (matchValue_1.tag === 1) ? singleton_1(createElement(ProfileComponent, {
                        info: matchValue_1.fields[0],
                        basicSettings: settings,
                        isEditMode: state_1.IsEditProfile,
                        agreementId: agreementId,
                        updateFormInfo: (x_4) => {
                            dispatch(new Msg(4, x_4));
                        },
                    })) : singleton_1(createElement(ProfileComponent_1, {
                        info: matchValue_1.fields[0],
                        settings: settings,
                        isEditMode: state_1.IsEditProfile,
                        agreementId: agreementId,
                        updateFormInfo: (x_3) => {
                            dispatch(new Msg(4, x_3));
                        },
                    }))), delay(() => {
                        let elms_1, dispatch_2, state_3, props_17, children_3, props_12, props_15, props_21;
                        return singleton_1((elms_1 = singleton_2((dispatch_2 = dispatch, (state_3 = state_1, state_3.IsEditProfile ? ((props_17 = ofArray([["className", "has-text-right"], ["style", {
                            alignSelf: "flex-end",
                        }], ["children", Interop_reactApi.Children.toArray([(children_3 = singleton_2((props_12 = toList(delay(() => append(singleton_1(["className", "mb-3"]), delay(() => append(singleton_1(["children", t("common.cancel")]), delay(() => append(equals(state_3.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-static"]) : empty(), delay(() => singleton_1(["onClick", (_arg1) => {
                            dispatch_2(new Msg(2));
                        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12))))), createElement("p", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_3)),
                        })), (props_15 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => append(singleton_1(["disabled", equals(state_3.AgreementType, state_3.OriginalAgreementType)]), delay(() => append(equals(state_3.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => singleton_1(["onClick", (x_2) => {
                            x_2.preventDefault();
                            dispatch_2(new Msg(5, new AsyncOperationStatus$1(0)));
                        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_17))))) : ((props_21 = ofArray([["className", "has-text-right"], ["style", {
                            alignSelf: "flex-end",
                        }], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "has-background-grey-lighter"], ["children", "Redigera inlämningsorder"], ["onClick", (_arg2) => {
                            dispatch_2(new Msg(1));
                        }]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_21)))))))), createElement("div", {
                            className: "columns",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        })));
                    }));
                }));
            }))))]]);
            return createElement("section", createObj(Helpers_combineClasses("section", props_25)));
        }
    }
}

