import { getToken } from "./LocalStorage.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "./fable_modules/Fable.Remoting.Client.7.10.0/Remoting.fs.js";
import { ReviseReport_IReviseReportApi$reflection, KU91Report_IKU91ReportApi$reflection, ClaimsReport_IClaimsReportApi$reflection, VatFreeReport_IVatFreeReportApi$reflection, Developer_IDeveloperApi$reflection, Localization_ILocalizationSettingsApi$reflection, Content_IContentApi$reflection, ISettingsApi$reflection, Presales_IPresaleApi$reflection, RoyaltyReport_IRoyaltyApi$reflection, PayOuts_IPayOutApi$reflection, Orders_IOrdersApi$reflection, Images_IImagesApi$reflection, ILotApi$reflection, IItemApi$reflection, Submissions_ISubmissionsApi$reflection, Agreement_AgreementApi$reflection, User_UserApi$reflection, CommonQueries_CommonApi$reflection, SignIn_ISignInApi$reflection, IAuctionApi$reflection, Route_builder } from "./Shared/Shared.js";

function token() {
    return `Bearer ${getToken()}`;
}

export const auctionApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: IAuctionApi$reflection,
    });
};

export const signInApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: SignIn_ISignInApi$reflection,
    });
};

export const commonApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: CommonQueries_CommonApi$reflection,
    });
};

export const usersApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: User_UserApi$reflection,
    });
};

export const agreementApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Agreement_AgreementApi$reflection,
    });
};

export const submissionsApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Submissions_ISubmissionsApi$reflection,
    });
};

export const itemApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: IItemApi$reflection,
    });
};

export const lotApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: ILotApi$reflection,
    });
};

export const imagesApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Images_IImagesApi$reflection,
    });
};

export const ordersApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Orders_IOrdersApi$reflection,
    });
};

export const payOutApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: PayOuts_IPayOutApi$reflection,
    });
};

export const royaltyApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: RoyaltyReport_IRoyaltyApi$reflection,
    });
};

export const presaleApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Presales_IPresaleApi$reflection,
    });
};

export const settingsApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: ISettingsApi$reflection,
    });
};

export const contentApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Content_IContentApi$reflection,
    });
};

export const localizationSettingsApi = () => Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
    ResolveType: Localization_ILocalizationSettingsApi$reflection,
});

export const developerApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Developer_IDeveloperApi$reflection,
    });
};

export const vatFreeReportApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: VatFreeReport_IVatFreeReportApi$reflection,
    });
};

export const claimsReportApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: ClaimsReport_IClaimsReportApi$reflection,
    });
};

export const ku91ReportApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: KU91Report_IKU91ReportApi$reflection,
    });
};

export const reviseReportApi = () => {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: ReviseReport_IReviseReportApi$reflection,
    });
};

