import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { BiddingIntervals_TypeInterval, BiddingIntervals_BiddingInterval, BiddingIntervals_Errors$reflection, BiddingIntervals_TypeInterval$reflection, BiddingIntervals_BiddingInterval$reflection } from "./Shared/Shared.js";
import { int32_type, record_type, union_type, unit_type, class_type, string_type, bool_type, list_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi } from "./Communication.js";
import { FSharpMap__get_IsEmpty, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { fold, cons, ofArray, indexed, length, map, mapIndexed, tail, singleton as singleton_1, reverse, append, last } from "./fable_modules/fable-library.3.6.1/List.js";
import { BiddingIntervals_validate, BiddingIntervals_validateIf } from "./Validation.js";
import { tWithArgs, t } from "./Localization.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { parse } from "./fable_modules/fable-library.3.6.1/Int32.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty as empty_1, singleton as singleton_2, map as map_1, append as append_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Form_getFieldError } from "./Common.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";

export class State extends Record {
    constructor(Intervals, OriginalIntervals, EditMode, FormErrors, SaveError, SaveFormOperation) {
        super();
        this.Intervals = Intervals;
        this.OriginalIntervals = OriginalIntervals;
        this.EditMode = EditMode;
        this.FormErrors = FormErrors;
        this.SaveError = SaveError;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function State$reflection() {
    return record_type("BiddingIntervals.State", [], State, () => [["Intervals", list_type(BiddingIntervals_BiddingInterval$reflection())], ["OriginalIntervals", list_type(BiddingIntervals_BiddingInterval$reflection())], ["EditMode", bool_type], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["SaveError", string_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AddBiddingInterval", "DeleteBiddingInterval", "UpdateBiddingInterval", "SwitchEditMode", "SaveForm"];
    }
}

export function Msg$reflection() {
    return union_type("BiddingIntervals.Msg", [], Msg, () => [[], [], [["Item1", int32_type], ["Item2", BiddingIntervals_TypeInterval$reflection()], ["Item3", int32_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, BiddingIntervals_Errors$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", BiddingIntervals_Errors$reflection()]]]))]]]);
}

export function Cmd_updateBiddingInterval(intervals) {
    return singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().updateBiddingIntervals(intervals)), (_arg1) => singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, _arg1)))));
}

export function init(intervals, unitVar1) {
    return [new State(intervals, intervals, false, empty(), "", new Deferred$1(0)), Cmd_none()];
}

export function update(msg, state) {
    let tupledArg_1;
    if (msg.tag === 0) {
        const lastItem = last(state.Intervals);
        const lastItem_1 = new BiddingIntervals_BiddingInterval(lastItem.From, lastItem.From * 2, lastItem.BidSize);
        const newInterval = new BiddingIntervals_BiddingInterval(lastItem_1.To, 2147483647 - (2147483647 % (lastItem_1.BidSize * 2)), lastItem_1.BidSize * 2);
        return [new State(append(reverse(append(singleton_1(lastItem_1), tail(reverse(state.Intervals)))), singleton_1(newInterval)), state.OriginalIntervals, state.EditMode, state.FormErrors, state.SaveError, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new State(reverse(mapIndexed((i, x) => {
            if (i === 0) {
                return new BiddingIntervals_BiddingInterval(x.From, 2147483647 - (2147483647 % (x.BidSize * 2)), x.BidSize);
            }
            else {
                return x;
            }
        }, tail(reverse(state.Intervals)))), state.OriginalIntervals, state.EditMode, state.FormErrors, state.SaveError, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [(tupledArg_1 = BiddingIntervals_validateIf(map((tupledArg) => {
            const i_2 = tupledArg[0] | 0;
            const old = tupledArg[1];
            if (i_2 === msg.fields[0]) {
                const value_2 = msg.fields[2] | 0;
                const biddingInterval = old;
                const change = msg.fields[1];
                switch (change.tag) {
                    case 1: {
                        return new BiddingIntervals_BiddingInterval(biddingInterval.From, value_2, biddingInterval.BidSize);
                    }
                    case 2: {
                        if (i_2 === (length(state.Intervals) - 1)) {
                            return new BiddingIntervals_BiddingInterval(biddingInterval.From, 2147483647 - (2147483647 % value_2), value_2);
                        }
                        else {
                            return new BiddingIntervals_BiddingInterval(biddingInterval.From, biddingInterval.To, value_2);
                        }
                    }
                    default: {
                        return new BiddingIntervals_BiddingInterval(value_2, biddingInterval.To, biddingInterval.BidSize);
                    }
                }
            }
            else {
                return old;
            }
        }, indexed(state.Intervals))), new State(tupledArg_1[0], state.OriginalIntervals, state.EditMode, tupledArg_1[1], state.SaveError, state.SaveFormOperation)), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                const er = msg.fields[0].fields[0].fields[0];
                return [new State(state.Intervals, state.OriginalIntervals, state.EditMode, state.FormErrors, (er.tag === 0) ? t("errors.fromServer.intervalsIsNotValid") : tWithArgs("errors.fromServer.unknownError ", {
                    error: er,
                }), new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new State(state.Intervals, state.OriginalIntervals, false, state.FormErrors, state.SaveError, new Deferred$1(0)), Cmd_none()];
            }
        }
        else if (!equals(state.Intervals, state.OriginalIntervals)) {
            const matchValue = BiddingIntervals_validate(state.Intervals);
            if (matchValue.tag === 1) {
                return [new State(state.Intervals, state.OriginalIntervals, state.EditMode, matchValue.fields[0], state.SaveError, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new State(state.Intervals, state.OriginalIntervals, state.EditMode, empty(), state.SaveError, new Deferred$1(1)), Cmd_fromAsync(Cmd_updateBiddingInterval(state.Intervals))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [state.EditMode ? (new State(state.OriginalIntervals, state.OriginalIntervals, !state.EditMode, state.FormErrors, state.SaveError, state.SaveFormOperation)) : (new State(state.Intervals, state.OriginalIntervals, !state.EditMode, state.FormErrors, state.SaveError, state.SaveFormOperation)), Cmd_none()];
    }
}

export const header = createElement("thead", {
    className: "small-font-size",
    children: Interop_reactApi.Children.toArray([(() => {
        let children, children_2, children_4;
        const children_6 = ofArray([(children = singleton_1(createElement("span", {
            children: ["From"],
        })), createElement("th", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), (children_2 = singleton_1(createElement("span", {
            children: ["To"],
        })), createElement("th", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_4 = singleton_1(createElement("span", {
            children: ["Bid size"],
        })), createElement("th", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))]);
        return createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        });
    })()]),
});

export function row(isNotEditTermsMode, ind, biddingInterval, lastElement, dispatch) {
    let children_6, children, props, value_6, children_2, props_3, value_17, children_4, props_6, value_28;
    return react.createElement(react.Fragment, {}, (children_6 = ofArray([(children = singleton_1((props = ofArray([["disabled", isNotEditTermsMode], ["onChange", (ev) => {
        dispatch(new Msg(2, ind, new BiddingIntervals_TypeInterval(0), parse(ev.target.value, 511, false, 32)));
    }], ["placeholder", "0"], (value_6 = (`${biddingInterval.From}`), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_6)) : false) {
            e.value = value_6;
        }
    }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props)))))), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = singleton_1((props_3 = ofArray([["disabled", isNotEditTermsMode ? true : (ind === lastElement)], ["onChange", (ev_1) => {
        dispatch(new Msg(2, ind, new BiddingIntervals_TypeInterval(1), parse(ev_1.target.value, 511, false, 32)));
    }], ["placeholder", "0"], (value_17 = ((ind === lastElement) ? "-" : (`${biddingInterval.To}`)), ["ref", (e_1) => {
        if ((!(e_1 == null)) ? (!equals(e_1.value, value_17)) : false) {
            e_1.value = value_17;
        }
    }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_3)))))), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = singleton_1((props_6 = ofArray([["disabled", isNotEditTermsMode], ["onChange", (ev_2) => {
        dispatch(new Msg(2, ind, new BiddingIntervals_TypeInterval(2), parse(ev_2.target.value, 511, false, 32)));
    }], ["placeholder", "0"], (value_28 = (`${biddingInterval.BidSize}`), ["ref", (e_2) => {
        if ((!(e_2 == null)) ? (!equals(e_2.value, value_28)) : false) {
            e_2.value = value_28;
        }
    }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_6)))))), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    }))]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    })));
}

export function rows(isNotEditTermsMode, matrix, addRow, removeRow, dispatch) {
    const children_2 = toList(delay(() => append_1(map_1((biddingInterval) => row(isNotEditTermsMode, biddingInterval[0], biddingInterval[1], length(matrix) - 1, dispatch), indexed(matrix)), delay(() => {
        let children, props, props_2;
        return singleton_2((children = singleton_1(createElement("td", {
            style: {
                whiteSpace: "nowrap",
            },
            children: Interop_reactApi.Children.toArray([(props = toList(delay(() => append_1(singleton_2(["style", {
                color: "#D3D3D3",
            }]), delay(() => append_1(singleton_2(["className", join(" ", ["fas", "fa-plus-circle"])]), delay(() => ((!isNotEditTermsMode) ? singleton_2(["onClick", (x) => {
                x.preventDefault();
                addRow();
            }]) : empty_1()))))))), createElement("span", createObj(Helpers_combineClasses("icon", props)))), (props_2 = toList(delay(() => append_1(singleton_2(["style", {
                color: "#D3D3D3",
            }]), delay(() => append_1(singleton_2(["className", join(" ", ["fas", "fa-minus-circle"])]), delay(() => ((!isNotEditTermsMode) ? singleton_2(["onClick", (x_1) => {
                x_1.preventDefault();
                removeRow();
            }]) : empty_1()))))))), createElement("span", createObj(Helpers_combineClasses("icon", props_2))))]),
        })), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })));
    }))));
    return createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function RenderComponent(renderComponentInputProps) {
    const unitVar1 = renderComponentInputProps.unitVar1;
    const intervals = renderComponentInputProps.intervals;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => init(intervals, void 0), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", {
        className: join(" ", ["pt-6", "mt-5", "has-background-white"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_6;
            return append_1(singleton_2((props_6 = toList(delay(() => {
                let elms_1, elms, props, props_3;
                const errors = Form_getFieldError(state_1.FormErrors, "Intervals");
                return singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(elms = singleton_1((props = ofArray([["style", {
                    marginLeft: "auto",
                    marginRight: "auto",
                }], ["children", Interop_reactApi.Children.toArray([header, rows(!state_1.EditMode, state_1.Intervals, () => {
                    dispatch(new Msg(0));
                }, () => {
                    dispatch(new Msg(1));
                }, dispatch)])]]), createElement("table", createObj(Helpers_combineClasses("table", props))))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (props_3 = ofArray([["className", "has-text-centered"], ["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_3))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))])]);
            })), createElement("div", createObj(Helpers_combineClasses("field", props_6))))), delay(() => append_1((state_1.SaveError !== "") ? singleton_2(createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "has-text-centered"], ["className", "is-danger"], ["children", state_1.SaveError]]))))) : empty_1(), delay(() => {
                let props_16;
                return singleton_2((props_16 = ofArray([["className", "pr-6"], ["className", "pb-6"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_10, props_14;
                    return state_1.EditMode ? append_1(singleton_2((props_10 = ofArray([["className", "is-dark"], ["onClick", (_arg3) => {
                        dispatch(new Msg(3));
                    }], ["children", t("common.cancel")]]), createElement("button", createObj(Helpers_combineClasses("button", props_10))))), delay(() => {
                        let props_12;
                        return singleton_2((props_12 = toList(delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append_1((equals(state_1.Intervals, state_1.OriginalIntervals) ? true : (!FSharpMap__get_IsEmpty(state_1.FormErrors))) ? singleton_2(["disabled", true]) : empty_1(), delay(() => append_1(singleton_2(["onClick", (_arg4) => {
                            dispatch(new Msg(4, new AsyncOperationStatus$1(0)));
                        }]), delay(() => singleton_2(["children", t("common.save")]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12)))));
                    })) : singleton_2((props_14 = ofArray([["className", "has-background-grey-lighter"], ["onClick", (_arg5) => {
                        dispatch(new Msg(3));
                    }], ["children", t("common.edit")]]), createElement("button", createObj(Helpers_combineClasses("button", props_14)))));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_16)))));
            }))));
        })))),
    });
}

export function Render() {
    const biddingIntervals = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(settingsApi().getBiddingIntervals()))), []);
    switch (biddingIntervals.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            const value = biddingIntervals.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            return createElement(RenderComponent, {
                intervals: biddingIntervals.fields[0],
            });
        }
        default: {
            return null;
        }
    }
}

