import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { record_type, union_type, string_type, unit_type, bool_type, class_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi, commonApi } from "./Communication.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { toPath, Route, navigateTo } from "./Router.js";
import { Settings_AnalyticIds, Settings_GoogleAnalytics, Settings_AnalyticIds$reflection, CommonQueries_Settings } from "./Shared/Shared.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { createElement } from "react";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { singleton as singleton_2, cons, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { t } from "./Localization.js";
import { SubmissionIdModule_fromString } from "./bidflow/src/infrastructure/Auction.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { isCurrentUserAdmin } from "./LocalStorage.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";
import { Render as Render_1 } from "./CompanySettings.js";

export class Submission_State extends Record {
    constructor(SubmissionId, CollectPersonalNumber, SaveFormOperation) {
        super();
        this.SubmissionId = SubmissionId;
        this.CollectPersonalNumber = CollectPersonalNumber;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function Submission_State$reflection() {
    return record_type("SettingsSystem.Submission.State", [], Submission_State, () => [["SubmissionId", class_type("System.Int64")], ["CollectPersonalNumber", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Submission_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SubmissionIdChanged", "CollectPersonalNumberChanged", "SaveForm"];
    }
}

export function Submission_Msg$reflection() {
    return union_type("SettingsSystem.Submission.Msg", [], Submission_Msg, () => [[["Item", class_type("System.Int64")]], [["Item", bool_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Submission_saveSettings(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(commonApi().saveSettings(state)), (_arg1) => singleton.Return(new Submission_Msg(2, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Submission_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Submission_init(settings) {
    return [new Submission_State(settings.SubmissionId, settings.CollectPersonalNumber, new Deferred$1(0)), Cmd_none()];
}

export function Submission_update(msg, state) {
    if (msg.tag === 1) {
        return [new Submission_State(state.SubmissionId, msg.fields[0], state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Submission_State(state.SubmissionId, state.CollectPersonalNumber, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
            }
            else {
                return [new Submission_State(state.SubmissionId, state.CollectPersonalNumber, new Deferred$1(2, msg.fields[0].fields[0])), navigateTo(new Route(0))];
            }
        }
        else {
            return [new Submission_State(state.SubmissionId, state.CollectPersonalNumber, new Deferred$1(1)), Cmd_fromAsync(Submission_saveSettings(new CommonQueries_Settings(state.SubmissionId, state.CollectPersonalNumber)))];
        }
    }
    else {
        return [new Submission_State(msg.fields[0], state.CollectPersonalNumber, state.SaveFormOperation), Cmd_none()];
    }
}

export function Submission_RenderSubmissionIdSetting(settings) {
    let props_28, props_26;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(Submission_init(settings), (msg, state) => Submission_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const elms_2 = singleton_2((props_28 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["className", "is-half"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let matchValue;
        return append((matchValue = state_1.SaveFormOperation, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 1) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", matchValue.fields[0].fields[0]]]))))) : singleton_1(null)) : singleton_1(null)), delay(() => {
            let props_11, props_9, props_4, props_2, elms, props_6, value_22;
            return append(singleton_1((props_11 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.ownSubmissionForResale")]]), createElement("label", createObj(Helpers_combineClasses("label", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_4)))), (elms = singleton_2((props_6 = ofArray([["onChange", (ev) => {
                dispatch(new Submission_Msg(0, SubmissionIdModule_fromString(ev.target.value)));
            }], ["placeholder", t("common.submissionId")], (value_22 = state_1.SubmissionId, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_22)) : false) {
                    e.value = value_22;
                }
            }])]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_6)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_9))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_11))))), delay(() => {
                let props_18, props_16, elms_1;
                return append(singleton_1((props_18 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["style", {
                    display: "inline-table",
                }], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([createElement("input", {
                    disabled: equals(state_1.SaveFormOperation, new Deferred$1(1)),
                    className: "is-checkradio",
                    id: "exampleCheckboxMedium",
                    type: "checkbox",
                    name: "exampleCheckboxMedium",
                    onChange: (ev_1) => {
                        dispatch(new Submission_Msg(1, ev_1.target.checked));
                    },
                    checked: state_1.CollectPersonalNumber,
                }), createElement("label", {
                    htmlFor: "exampleCheckboxMedium",
                    children: t("settings.collectPersonalNumber"),
                })]), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_16))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_18))))), delay(() => {
                    let props_24, props_20, props_22;
                    return singleton_1((props_24 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["href", toPath(new Route(0))]]), createElement("a", createObj(Helpers_combineClasses("button", props_20)))), (props_22 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (x_2) => {
                        x_2.preventDefault();
                        dispatch(new Submission_Msg(2, new AsyncOperationStatus$1(0)));
                    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_24)))));
                }));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_28)))));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export class Analytics_State extends Record {
    constructor(Original, Current, SaveFormOperation) {
        super();
        this.Original = Original;
        this.Current = Current;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function Analytics_State$reflection() {
    return record_type("SettingsSystem.Analytics.State", [], Analytics_State, () => [["Original", Settings_AnalyticIds$reflection()], ["Current", Settings_AnalyticIds$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Analytics_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AnalyticsIdChanged", "TagManagerIdChanged", "GoogleAnalyticDisabled", "PixelIdChanged", "SaveForm"];
    }
}

export function Analytics_Msg$reflection() {
    return union_type("SettingsSystem.Analytics.Msg", [], Analytics_Msg, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Analytics_saveSettings(ids) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().updateAnalyticIds(ids)), (_arg1) => singleton.Return(new Analytics_Msg(4, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Analytics_Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Analytics_init(analyticsIds) {
    return [new Analytics_State(analyticsIds, analyticsIds, new Deferred$1(0)), Cmd_none()];
}

export function Analytics_update(msg, state) {
    if (msg.tag === 0) {
        return [new Analytics_State(state.Original, new Settings_AnalyticIds(new Settings_GoogleAnalytics(1, msg.fields[0]), state.Current.PixelId), state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new Analytics_State(state.Original, new Settings_AnalyticIds(new Settings_GoogleAnalytics(2, msg.fields[0]), state.Current.PixelId), state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new Analytics_State(state.Original, new Settings_AnalyticIds(new Settings_GoogleAnalytics(0), state.Current.PixelId), state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Analytics_State(state.Original, state.Current, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
            }
            else {
                return [new Analytics_State(state.Current, state.Current, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
            }
        }
        else {
            return [new Analytics_State(state.Original, state.Current, new Deferred$1(1)), Cmd_fromAsync(Analytics_saveSettings(state.Current))];
        }
    }
    else {
        return [new Analytics_State(state.Original, new Settings_AnalyticIds(state.Current.Google, msg.fields[0]), state.SaveFormOperation), Cmd_none()];
    }
}

export function Analytics_RenderAnalyticsIds(analyticsIds) {
    let props_55, props_53;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(Analytics_init(analyticsIds), (msg, state) => Analytics_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const elms_8 = singleton_2((props_55 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_53 = ofArray([["className", "is-half"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let matchValue;
        return append((matchValue = state_1.SaveFormOperation, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 1) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", matchValue.fields[0].fields[0]]]))))) : singleton_1(null)) : singleton_1(null)), delay(() => {
            let props_15, elms_1, props_4, props_2, elms;
            return append(singleton_1((props_15 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_4 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.selectGoogleAnalytics")]]), createElement("label", createObj(Helpers_combineClasses("label", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_4)))), (elms = toList(delay(() => {
                let patternInput_1;
                let value_17;
                const matchValue_1 = state_1.Current.Google;
                switch (matchValue_1.tag) {
                    case 2: {
                        value_17 = "gtm";
                        break;
                    }
                    case 0: {
                        value_17 = "disabled";
                        break;
                    }
                    default: {
                        value_17 = "analytics";
                    }
                }
                return singleton_1((patternInput_1 = Helpers_extractClasses(toList(delay(() => append(singleton_1(["onChange", (ev) => {
                    let _arg1;
                    dispatch((_arg1 = ev.target.value, (_arg1 === "analytics") ? (new Analytics_Msg(0, "")) : ((_arg1 === "gtm") ? (new Analytics_Msg(1, "")) : (new Analytics_Msg(2)))));
                }]), delay(() => append(singleton_1(["children", Interop_reactApi.Children.toArray([createElement("option", {
                    value: "disabled",
                    children: "Disabled",
                }), createElement("option", {
                    value: "analytics",
                    children: "Analytics",
                }), createElement("option", {
                    value: "gtm",
                    children: "Tag Manager",
                })])]), delay(() => singleton_1(["defaultValue", value_17])))))))), createElement("div", {
                    className: join(" ", cons("select", patternInput_1[0])),
                    children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
                })));
            })), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_15))))), delay(() => {
                let matchValue_2, props_25, elms_3, props_19, props_17, elms_2, props_35, elms_5, props_29, props_27, elms_4;
                return append((matchValue_2 = state_1.Current.Google, (matchValue_2.tag === 1) ? singleton_1((props_25 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_19 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_17 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.googleAnalyticsId")]]), createElement("label", createObj(Helpers_combineClasses("label", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_19)))), (elms_2 = toList(delay(() => {
                    let props_21, value_56;
                    let value_52;
                    const matchValue_3 = state_1.Current.Google;
                    switch (matchValue_3.tag) {
                        case 2:
                        case 0: {
                            value_52 = "";
                            break;
                        }
                        default: {
                            value_52 = matchValue_3.fields[0];
                        }
                    }
                    return singleton_1((props_21 = ofArray([["onChange", (ev_1) => {
                        dispatch(new Analytics_Msg(0, ev_1.target.value));
                    }], ["placeholder", t("settings.googleAnalyticsId")], (value_56 = value_52, ["ref", (e) => {
                        if ((!(e == null)) ? (!equals(e.value, value_56)) : false) {
                            e.value = value_56;
                        }
                    }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_21))))));
                })), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_25))))) : ((matchValue_2.tag === 2) ? singleton_1((props_35 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_5 = ofArray([(props_29 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_27 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.googleTagManagerId")]]), createElement("label", createObj(Helpers_combineClasses("label", props_27))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_29)))), (elms_4 = toList(delay(() => {
                    let props_31, value_81;
                    let value_77;
                    const matchValue_4 = state_1.Current.Google;
                    switch (matchValue_4.tag) {
                        case 1:
                        case 0: {
                            value_77 = "";
                            break;
                        }
                        default: {
                            value_77 = matchValue_4.fields[0];
                        }
                    }
                    return singleton_1((props_31 = ofArray([["onChange", (ev_2) => {
                        dispatch(new Analytics_Msg(1, ev_2.target.value));
                    }], ["placeholder", t("settings.googleTagManagerId")], (value_81 = value_77, ["ref", (e_1) => {
                        if ((!(e_1 == null)) ? (!equals(e_1.value, value_81)) : false) {
                            e_1.value = value_81;
                        }
                    }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_31))))));
                })), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                }))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_35))))) : singleton_1(null))), delay(() => {
                    let props_45, elms_7, props_39, props_37, elms_6, props_41, value_105;
                    return append(singleton_1((props_45 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_7 = ofArray([(props_39 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_37 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.metaPixelId")]]), createElement("label", createObj(Helpers_combineClasses("label", props_37))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_39)))), (elms_6 = singleton_2((props_41 = ofArray([["onChange", (ev_3) => {
                        dispatch(new Analytics_Msg(3, ev_3.target.value));
                    }], ["placeholder", t("settings.metaPixelId")], (value_105 = state_1.Current.PixelId, ["ref", (e_2) => {
                        if ((!(e_2 == null)) ? (!equals(e_2.value, value_105)) : false) {
                            e_2.value = value_105;
                        }
                    }])]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_41)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                    }))]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_45))))), delay(() => {
                        let props_51, props_47, props_49;
                        return singleton_1((props_51 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_47 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["href", toPath(new Route(0))]]), createElement("button", createObj(Helpers_combineClasses("button", props_47)))), (props_49 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["disabled", equals(state_1.Original, state_1.Current)]), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (x_3) => {
                            x_3.preventDefault();
                            dispatch(new Analytics_Msg(4, new AsyncOperationStatus$1(0)));
                        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_51)))));
                    }));
                }));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_53))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_55)))));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    });
}

export class EditMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SubmissionIdSetting", "AnalyticsSettings", "CompanySettings"];
    }
}

export function EditMode$reflection() {
    return union_type("SettingsSystem.EditMode", [], EditMode, () => [[], [], []]);
}

export function Render() {
    const patternInput = useFeliz_React__React_useState_Static_1505(new EditMode(0));
    const setEditMode = patternInput[1];
    const editMode = patternInput[0];
    if (isCurrentUserAdmin()) {
        const loadData_1 = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(commonApi().loadSettings()), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getAnalyticIds()), (_arg2) => singleton.Return({
            AnalyticIds: _arg2,
            Settings: _arg1,
        })))), []);
        switch (loadData_1.tag) {
            case 1: {
                return loader("big");
            }
            case 3: {
                return loadData_1.fields[0].message;
            }
            case 2: {
                const info = loadData_1.fields[0];
                const elms = toList(delay(() => {
                    let props_1, s;
                    return append(singleton_1((props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("settings.settings"), createElement("h1", {
                        className: "title",
                        children: s,
                    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1))))), delay(() => {
                        let props_15, props_13, children, props_4, props_7, props_10;
                        return append(singleton_1((props_15 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-toggle"], ["className", "is-centered"], ["className", "is-medium"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_4 = toList(delay(() => append(equals(editMode, new EditMode(0)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("settings.settings"),
                            onClick: (_arg1_1) => {
                                setEditMode(new EditMode(0));
                            },
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_7 = toList(delay(() => append(equals(editMode, new EditMode(1)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("settings.websiteAnalytics"),
                            onClick: (_arg2_1) => {
                                setEditMode(new EditMode(1));
                            },
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7)))), (props_10 = toList(delay(() => append(equals(editMode, new EditMode(2)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                            className: "is-normal-padding",
                            children: t("settings.company"),
                            onClick: (_arg3) => {
                                setEditMode(new EditMode(2));
                            },
                        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_10))))]), createElement("ul", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_13))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_15))))), delay(() => ((editMode.tag === 1) ? singleton_1(createElement(Analytics_RenderAnalyticsIds, info.AnalyticIds)) : ((editMode.tag === 2) ? singleton_1(createElement(Render_1, null)) : singleton_1(createElement(Submission_RenderSubmissionIdSetting, info.Settings))))));
                    }));
                }));
                return createElement("div", {
                    className: "container",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                });
            }
            default: {
                return loader("big");
            }
        }
    }
    else {
        return null;
    }
}

