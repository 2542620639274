import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { tuple_type, record_type, bool_type, union_type, string_type, unit_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Submissions_SubmissionNote, Submissions_SubmissionNote$reflection } from "../Shared/Shared.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52, Deferred$1, Deferred$1$reflection } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { submissionsApi } from "../Communication.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { createObj, equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { empty as empty_1, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Date.js";
import { toLocalTime, minValue } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { equals as equals_2 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { UserIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { empty, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { t } from "../Localization.js";
import { sv } from "date-fns/locale";
import { format as format_1 } from "date-fns";
import { loader } from "../Loader.js";

export class State extends Record {
    constructor(SubmissionId, Note, OriginalNote, SaveFormOperation, IsEditMode) {
        super();
        this.SubmissionId = SubmissionId;
        this.Note = Note;
        this.OriginalNote = OriginalNote;
        this.SaveFormOperation = SaveFormOperation;
        this.IsEditMode = IsEditMode;
    }
}

export function State$reflection() {
    return record_type("Components.SubmissionNotes.State", [], State, () => [["SubmissionId", class_type("System.Int64")], ["Note", Submissions_SubmissionNote$reflection()], ["OriginalNote", Submissions_SubmissionNote$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["IsEditMode", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SwitchEditMode", "CancelEditMode", "TextChanged", "ModifySubmissionNote"];
    }
}

export function Msg$reflection() {
    return union_type("Components.SubmissionNotes.Msg", [], Msg, () => [[], [], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(string_type, class_type("System.DateTimeOffset")), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(string_type, class_type("System.DateTimeOffset"))]], [["ErrorValue", string_type]]]))]]]);
}

export function updateSubmissionNoteText(id, note) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(submissionsApi().updateSubmissionNote([id, note.Text])), (_arg1) => {
        const response = _arg1;
        return (response.tag === 1) ? singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, response.fields[0])))) : singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, response.fields[0]))));
    })), (_arg2) => singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(submissionNote, id) {
    return [new State(id, submissionNote, submissionNote, new Deferred$1(0), false), Cmd_none()];
}

export function update(msg, state) {
    let inputRecord;
    if (msg.tag === 0) {
        return [new State(state.SubmissionId, state.Note, state.OriginalNote, state.SaveFormOperation, true), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new State(state.SubmissionId, state.OriginalNote, state.OriginalNote, state.SaveFormOperation, false), Cmd_none()];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.SubmissionId, state.Note, state.OriginalNote, new Deferred$1(3, new Error(msg.fields[0].fields[0].fields[0])), state.IsEditMode), Cmd_none()];
            }
            else {
                let note;
                const inputRecord_1 = state.Note;
                note = (new Submissions_SubmissionNote(inputRecord_1.SubmissionId, inputRecord_1.Text, inputRecord_1.UserId, msg.fields[0].fields[0].fields[0][0], msg.fields[0].fields[0].fields[0][1]));
                return [new State(state.SubmissionId, note, note, new Deferred$1(0), false), Cmd_none()];
            }
        }
        else {
            return [new State(state.SubmissionId, state.Note, state.OriginalNote, new Deferred$1(1), state.IsEditMode), Cmd_fromAsync(updateSubmissionNoteText(state.SubmissionId, state.Note))];
        }
    }
    else {
        return [new State(state.SubmissionId, (inputRecord = state.Note, new Submissions_SubmissionNote(inputRecord.SubmissionId, msg.fields[0], inputRecord.UserId, inputRecord.UserName, inputRecord.LastChangedDate)), state.OriginalNote, state.SaveFormOperation, state.IsEditMode), Cmd_none()];
    }
}

export function renderForm(note, state, dispatch) {
    let elms_1, elms, props_2, props, value_7;
    const props_7 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1(createElement("form", {
        disabled: !state.IsEditMode,
        onSubmit: (x) => {
            x.preventDefault();
            dispatch(new Msg(3, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray([(elms = singleton_1((props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = ofArray([["rows", 20], ["disabled", !state.IsEditMode], (value_7 = note.Text, ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_7)) : false) {
                e.value = value_7;
            }
        }]), ["onChange", (ev) => {
            dispatch(new Msg(2, ev.target.value));
        }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_2))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_7)));
}

export function RenderContent(renderContentInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderContentInputProps.submissionNote, renderContentInputProps.id), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const noteExists = (!equals_1(state_1.Note.LastChangedDate, minValue())) ? true : (!equals_2(state_1.Note.UserId, UserIdModule_empty));
    const elms = toList(delay(() => {
        let props_2, props, date, locale, formatOptions;
        return append(noteExists ? singleton_2((props_2 = ofArray([["className", "mt-4"], ["className", "mb-0"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-size-5"], ["className", "has-text-grey-light"], ["className", "mx-2"], ["children", (((t("users.lastUpdated") + ": ") + ((date = toLocalTime(state_1.Note.LastChangedDate), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_1(date, "yyyy-MM-dd HH:mm:ss", formatOptions))))))) + ", ") + state_1.Note.UserName]]), createElement("span", createObj(Helpers_combineClasses("", props))))])]]), createElement("div", createObj(Helpers_combineClasses("block", props_2))))) : empty(), delay(() => {
            let props_4;
            return append(singleton_2((props_4 = toList(delay(() => append((!noteExists) ? singleton_2(["style", {
                marginTop: 2.5 + "em",
            }]) : empty(), delay(() => append(singleton_2(["className", "has-background-primary-light"]), delay(() => append(singleton_2(["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1)) ? true : (!state_1.IsEditMode)]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([renderForm(state_1.Note, state_1, dispatch)])]))))))))), createElement("div", createObj(Helpers_combineClasses("box", props_4))))), delay(() => {
                let props_18, props_16;
                return singleton_2((props_18 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", empty_1()))), (props_16 = ofArray([["className", "has-text-right"], ["style", {
                    alignSelf: "flex-end",
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_8, props_14, props_10, props_12;
                    return (!state_1.IsEditMode) ? singleton_2((props_8 = ofArray([["className", "has-background-grey-lighter"], ["children", t("common.edit")], ["onClick", (_arg1) => {
                        dispatch(new Msg(0));
                    }]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))) : singleton_2((props_14 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_10 = toList(delay(() => append(singleton_2(["children", t("common.cancel")]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_2(["className", "is-static"]) : empty(), delay(() => singleton_2(["onClick", (_arg2) => {
                        dispatch(new Msg(1));
                    }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_10)))), (props_12 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["children", t("common.save")]), delay(() => append(singleton_2(["disabled", equals(state_1.Note, state_1.OriginalNote)]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => singleton_2(["onClick", (x) => {
                        x.preventDefault();
                        dispatch(new Msg(3, new AsyncOperationStatus$1(0)));
                    }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_14)))));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_18)))));
            }));
        }));
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function Render(renderInputProps) {
    const id = renderInputProps.id;
    const info = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(submissionsApi().getSubmissionNote(id)), (_arg1) => singleton.Return(_arg1))), [id]);
    switch (info.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            const value = info.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const content = info.fields[0];
            const submissionNote_1 = (content == null) ? (new Submissions_SubmissionNote(id, "", UserIdModule_empty, "", minValue())) : content;
            return createElement(RenderContent, {
                submissionNote: submissionNote_1,
                id: id,
            });
        }
        default: {
            return null;
        }
    }
}

