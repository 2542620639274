import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { User_SystemUser, User_Manager, User_Manager$reflection } from "../Shared/Shared.js";
import { record_type, bool_type, class_type, list_type, union_type, string_type, unit_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi } from "../Communication.js";
import { t, tWithArgs } from "../Localization.js";
import { createElement } from "react";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.6.1/MapUtil.js";
import { Manager_validate } from "../Validation.js";
import { Route_AccountDetailsSubmissions_7639071A, toPath, Route, navigateTo } from "../Router.js";
import { cons, empty as empty_2, singleton as singleton_2, ofArray, fold } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { dividedTop } from "../Components/BorderDividedLine.js";
import { Form_getFieldError } from "../Common.js";
import { EmailModule_toString, EmailModule_ofString } from "../bidflow/src/infrastructure/Auction.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";

export class State extends Record {
    constructor(Form, SaveFormOperation, FormErrors, NeedToValidate) {
        super();
        this.Form = Form;
        this.SaveFormOperation = SaveFormOperation;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
    }
}

export function State$reflection() {
    return record_type("Bidflow.BO.Client.Users.Manager.Edit.State", [], State, () => [["Form", User_Manager$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailChanged", "FirstNameChanged", "LastNameChanged", "NeedAutoPrintLotLabelChange", "SaveForm"];
    }
}

export function Msg$reflection() {
    return union_type("Bidflow.BO.Client.Users.Manager.Edit.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function init(company) {
    return [new State(company, new Deferred$1(0), empty(), false), Cmd_none()];
}

export function saveProfile(x) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(usersApi().updateManager(x)), (_arg1) => ((_arg1.tag === 1) ? ((_arg1.fields[0].tag === 1) ? singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, tWithArgs("errors.fromServer.userWithEmailDoesNotExistWithArg", {
        email: (props, children) => react.createElement("s", keyValueList(props, 1), ...children),
    }))))) : ((_arg1.fields[0].tag === 2) ? singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.agreementDoesNotExist"))))) : singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, tWithArgs("errors.fromServer.userAlreadyExistWithArg", {
        email: _arg1.fields[0].fields[0],
    }))))))) : singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function withForm(form, state) {
    return new State(form, state.SaveFormOperation, state.FormErrors, state.NeedToValidate);
}

export function withFormAndErrors(form, errors, state) {
    return new State(form, state.SaveFormOperation, errors, state.NeedToValidate);
}

export function update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord;
    const withValidatedForm = (arg) => {
        let tupledArg;
        const admin_1 = arg;
        if (state.NeedToValidate) {
            const matchValue = Manager_validate(admin_1);
            tupledArg = ((matchValue.tag === 1) ? [admin_1, matchValue.fields[0]] : [matchValue.fields[0], empty()]);
        }
        else {
            tupledArg = [admin_1, empty()];
        }
        return (state_1) => withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 1) {
        return [withValidatedForm((inputRecord_1 = state.Form, new User_Manager(inputRecord_1.UserId, inputRecord_1.Email, msg.fields[0], inputRecord_1.LastName, inputRecord_1.NeedAutoPrintLotLabel)))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm((inputRecord_2 = state.Form, new User_Manager(inputRecord_2.UserId, inputRecord_2.Email, inputRecord_2.FirstName, msg.fields[0], inputRecord_2.NeedAutoPrintLotLabel)))(state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm((inputRecord_3 = state.Form, new User_Manager(inputRecord_3.UserId, inputRecord_3.Email, inputRecord_3.FirstName, inputRecord_3.LastName, msg.fields[0])))(state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.Form, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.NeedToValidate), Cmd_none()];
            }
            else {
                return [new State(state.Form, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.NeedToValidate), navigateTo(new Route(7, 1, new User_SystemUser(0), void 0))];
            }
        }
        else {
            const matchValue_1 = Manager_validate(state.Form);
            if (matchValue_1.tag === 1) {
                return [new State(state.Form, new Deferred$1(0), matchValue_1.fields[0], true), Cmd_none()];
            }
            else {
                return [new State(state.Form, new Deferred$1(1), empty(), true), Cmd_fromAsync(saveProfile(state.Form))];
            }
        }
    }
    else {
        return [withValidatedForm((inputRecord = state.Form, new User_Manager(inputRecord.UserId, msg.fields[0], inputRecord.FirstName, inputRecord.LastName, inputRecord.NeedAutoPrintLotLabel)))(state), Cmd_none()];
    }
}

export function ErrorsHelpBlock(errors) {
    const props = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]);
    return createElement("p", createObj(Helpers_combineClasses("help", props)));
}

export function Render(manager) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(manager), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    let error;
    const matchValue = state_1.SaveFormOperation;
    let pattern_matching_result, x;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            x = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            error = x;
            break;
        }
        case 1: {
            error = "";
            break;
        }
    }
    const elms_15 = toList(delay(() => {
        let props_2, props;
        return append(singleton_1((props_2 = singleton_2(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-uppercase"], ["children", t("users.editManager")]]), createElement("h3", createObj(Helpers_combineClasses("title is-3", props))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_2))))), delay(() => append((error !== "") ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", error]]))))) : empty_1(), delay(() => {
            let props_49, elms_10, props_8, props_6, props_46, elms_2, props_20, props_18, elms_9, elms_5, props_31, elms_8, props_42, props_69, elms_13, props_55, props_51, props_53, elms_12, props_65, props_63, elms_11, props_60, props_58;
            return append(singleton_1(createElement("div", {
                children: Interop_reactApi.Children.toArray([(props_49 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_10 = ofArray([(props_8 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.general")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_46 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_20 = ofArray([["className", "is-half"], ["children", Interop_reactApi.Children.toArray([(props_18 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_1, props_12, props_10, elms, props_14;
                    const errors = Form_getFieldError(state_1.FormErrors, "Email");
                    return singleton_1((elms_1 = ofArray([(props_12 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.email")]]), createElement("label", createObj(Helpers_combineClasses("label", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_12)))), (elms = singleton_2((props_14 = toList(delay(() => append((!equals(errors, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev) => {
                        dispatch(new Msg(0, EmailModule_ofString(ev.target.value)));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterEmail")]), delay(() => {
                        let value_43;
                        return singleton_1((value_43 = EmailModule_toString(state_1.Form.Email), ["ref", (e) => {
                            if ((!(e == null)) ? (!equals(e.value, value_43)) : false) {
                                e.value = value_43;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_14)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    })), ErrorsHelpBlock(errors)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                })), (elms_9 = ofArray([(elms_5 = singleton_2((props_31 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_4, props_25, props_23, elms_3, props_27;
                    const errors_1 = Form_getFieldError(state_1.FormErrors, "FirstName");
                    return singleton_1((elms_4 = ofArray([(props_25 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.firstName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_25)))), (elms_3 = singleton_2((props_27 = toList(delay(() => append((!equals(errors_1, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_1) => {
                        dispatch(new Msg(1, ev_1.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterFirstName")]), delay(() => {
                        let value_73;
                        return singleton_1((value_73 = state_1.Form.FirstName, ["ref", (e_1) => {
                            if ((!(e_1 == null)) ? (!equals(e_1.value, value_73)) : false) {
                                e_1.value = value_73;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_27)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                    })), ErrorsHelpBlock(errors_1)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_31))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                })), (elms_8 = singleton_2((props_42 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_7, props_36, props_34, elms_6, props_38;
                    const errors_2 = Form_getFieldError(state_1.FormErrors, "LastName");
                    return singleton_1((elms_7 = ofArray([(props_36 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.lastName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_36)))), (elms_6 = singleton_2((props_38 = toList(delay(() => append((!equals(errors_2, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_2) => {
                        dispatch(new Msg(2, ev_2.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterLastName")]), delay(() => {
                        let value_102;
                        return singleton_1((value_102 = state_1.Form.LastName, ["ref", (e_2) => {
                            if ((!(e_2 == null)) ? (!equals(e_2.value, value_102)) : false) {
                                e_2.value = value_102;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_38)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                    })), ErrorsHelpBlock(errors_2)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_42))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                }))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_46))))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_49)))), (props_69 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_13 = ofArray([(props_55 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_51 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.userSettings")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_51)))), (props_53 = ofArray([["style", {
                    fontSize: 0.7 + "rem",
                }], ["className", "has-text-grey-light"], ["children", t("users.userSettingsHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_53))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_55)))), (elms_12 = singleton_2((props_65 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_63 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_11 = singleton_2((props_60 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                    className: join(" ", ["is-checkradio"]),
                    id: "needAutoPrintLotLabel",
                    type: "checkbox",
                    name: "needAutoPrintLotLabel",
                    onChange: (ev_3) => {
                        dispatch(new Msg(3, ev_3.target.checked));
                    },
                    checked: state_1.Form.NeedAutoPrintLotLabel,
                }), (props_58 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.needAutoPrintLotLabel")], ["htmlFor", "needAutoPrintLotLabel"]]), createElement("label", createObj(Helpers_combineClasses("label", props_58))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_60))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_63))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_65))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                }))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_69))))]),
            })), delay(() => {
                let props_83, props_81, elms_14, props_78, props_74, props_76;
                return singleton_1((props_83 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(props_81 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-one-quarter"])))), (elms_14 = singleton_2((props_78 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_74 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["href", toPath(Route_AccountDetailsSubmissions_7639071A(manager.UserId))]]), createElement("a", createObj(Helpers_combineClasses("button", props_74)))), (props_76 = ofArray([["className", "is-primary"], ["children", t("common.save")], ["onClick", (_arg1_1) => {
                    dispatch(new Msg(4, new AsyncOperationStatus$1(0)));
                }]]), createElement("button", createObj(Helpers_combineClasses("button", props_76))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_78))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
                }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_81))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_83)))));
            }));
        }))));
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
    });
}

