import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Image_lotMedium800ImagePathContains, Image_imagePlaceholder, Image_originalImagePathPng, Image_originalImageUrl, Image_smallContains } from "../Css.js";
import { head, isEmpty, singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { ImageIdModule_toString } from "../bidflow/src/infrastructure/Auction.js";

export function RenderImage(image) {
    let elms_1, elms, props_2, props_12;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setFullScreen = patternInput[1];
    const children_4 = ofArray([(elms_1 = singleton((elms = singleton((props_2 = ofArray([["style", {
        cursor: "pointer",
        minHeight: 70,
    }], ["children", Interop_reactApi.Children.toArray([createElement("figure", {
        className: "is-16by9",
        className: join(" ", ["image"]),
        children: Interop_reactApi.Children.toArray([createElement("img", {
            src: Image_smallContains(image),
            alt: "Placeholder image",
        })]),
    })])], ["onClick", (_arg1) => {
        setFullScreen(true);
    }]]), createElement("div", createObj(Helpers_combineClasses("card-image", props_2))))), createElement("div", {
        className: "card",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_12 = toList(delay(() => append(patternInput[0] ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
        let elms_3, elms_2;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg2) => {
            setFullScreen(false);
        }])))), (elms_3 = singleton((elms_2 = singleton(createElement("figure", {
            className: "is-16by9",
            className: join(" ", ["image"]),
            children: Interop_reactApi.Children.toArray([createElement("img", {
                src: Image_originalImageUrl(image),
                alt: "Placeholder image",
            })]),
        })), createElement("div", {
            className: "card-image",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), createElement("div", {
            className: "modal-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("modal", props_12))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    });
}

export function RenderBigImage(renderBigImageInputProps) {
    let props_1, props_9;
    const flag = renderBigImageInputProps.flag;
    const image = renderBigImageInputProps.image;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setFullScreen = patternInput[1];
    return createElement("div", {
        style: {
            marginBottom: 10,
        },
        children: Interop_reactApi.Children.toArray([(props_1 = toList(delay(() => append(flag ? singleton_1(["className", "is-2by1"]) : empty(), delay(() => append(singleton_1(["onClick", (_arg1) => {
            setFullScreen(true);
        }]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("img", createObj(toList(delay(() => append(flag ? singleton_1(["className", "previewHomeImageBottom"]) : singleton_1(["className", "previewHomeImageCenter"]), delay(() => append(singleton_1(["src", defaultArg(map(Image_originalImagePathPng, image), Image_imagePlaceholder)]), delay(() => singleton_1(["alt", "Placeholder image"])))))))))])]))))))), createElement("figure", createObj(Helpers_combineClasses("image", props_1)))), (props_9 = toList(delay(() => append(patternInput[0] ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
            let elms_1, elms;
            return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg2) => {
                setFullScreen(false);
            }])))), (elms_1 = singleton((elms = singleton(createElement("figure", {
                className: "is-16by9",
                className: join(" ", ["image"]),
                children: Interop_reactApi.Children.toArray([createElement("img", {
                    src: defaultArg(map(Image_originalImagePathPng, image), Image_imagePlaceholder),
                    alt: "Placeholder image",
                })]),
            })), createElement("div", {
                className: "card-image",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), createElement("div", {
                className: "modal-content",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]);
        })))), createElement("div", createObj(Helpers_combineClasses("modal", props_9))))]),
    });
}

export function RenderBigImages(renderBigImagesInputProps) {
    let elms_1, elms, props_2, props_12;
    const images = renderBigImagesInputProps.images;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setFullScreen = patternInput[1];
    const getUrl = (images_1, isMedium) => {
        if (isEmpty(images_1)) {
            return Image_imagePlaceholder;
        }
        else if (isMedium) {
            return Image_lotMedium800ImagePathContains(head(images_1));
        }
        else {
            return Image_originalImageUrl(head(images_1));
        }
    };
    const children_4 = ofArray([(elms_1 = singleton((elms = singleton((props_2 = ofArray([["style", {
        cursor: "pointer",
        minHeight: 150,
    }], ["children", Interop_reactApi.Children.toArray([createElement("figure", {
        className: join(" ", ["image"]),
        children: Interop_reactApi.Children.toArray([createElement("img", {
            key: isEmpty(images) ? "0" : ImageIdModule_toString(head(images).Id),
            src: getUrl(images, true),
            alt: "Add image first",
        })]),
    })])], ["onClick", (_arg1) => {
        setFullScreen(true);
    }]]), createElement("div", createObj(Helpers_combineClasses("card-image", props_2))))), createElement("div", {
        className: "card",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_12 = toList(delay(() => append(patternInput[0] ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
        let elms_3, elms_2;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg2) => {
            setFullScreen(false);
        }])))), (elms_3 = singleton((elms_2 = singleton(createElement("figure", {
            className: "is-16by9",
            className: join(" ", ["image"]),
            children: Interop_reactApi.Children.toArray([createElement("img", {
                src: getUrl(images, false),
                alt: "Placeholder image",
            })]),
        })), createElement("div", {
            className: "card-image",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), createElement("div", {
            className: "modal-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("modal", props_12))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    });
}

export function RenderBigImagePng(renderBigImagePngInputProps) {
    let props_1, props_9;
    const flag = renderBigImagePngInputProps.flag;
    const image = renderBigImagePngInputProps.image;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setFullScreen = patternInput[1];
    return createElement("div", {
        style: {
            marginBottom: 10,
        },
        children: Interop_reactApi.Children.toArray([(props_1 = ofArray([["onClick", (_arg1) => {
            setFullScreen(true);
        }], ["children", Interop_reactApi.Children.toArray([createElement("img", createObj(toList(delay(() => append(flag ? singleton_1(["className", "previewHomeImageBottom"]) : singleton_1(["className", "previewHomeImageCenter"]), delay(() => append(singleton_1(["src", defaultArg(map(Image_originalImagePathPng, image), Image_imagePlaceholder)]), delay(() => singleton_1(["alt", "Placeholder image"])))))))))])]]), createElement("figure", createObj(Helpers_combineClasses("image", props_1)))), (props_9 = toList(delay(() => append(patternInput[0] ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
            let elms_1, elms;
            return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg2) => {
                setFullScreen(false);
            }])))), (elms_1 = singleton((elms = singleton(createElement("figure", {
                className: "is-16by9",
                className: join(" ", ["image"]),
                children: Interop_reactApi.Children.toArray([createElement("img", {
                    src: defaultArg(map(Image_originalImagePathPng, image), Image_imagePlaceholder),
                    alt: "Placeholder image",
                })]),
            })), createElement("div", {
                className: "card-image",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), createElement("div", {
                className: "modal-content",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]);
        })))), createElement("div", createObj(Helpers_combineClasses("modal", props_9))))]),
    });
}

