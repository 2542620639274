import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { payOutApi, royaltyApi, commonApi, ordersApi, lotApi, settingsApi, submissionsApi } from "../Communication.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { getCurrentLanguage, tWithArgs, t } from "../Localization.js";
import { item, empty as empty_1, length, find, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { toString } from "../fable_modules/fable-library.3.6.1/Long.js";
import { backButtonHref } from "../Components/BackButton.js";
import { Route_AccountDetailsSubmissionsPage, toPath } from "../Router.js";
import { filter, sumBy, map, collect, empty, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { PayOuts_Receipt_get_Empty, PayOuts_ResultReportSummaries$reflection, PayOuts_OrderResultReportFilter$reflection, Orders_TransactionsType, Orders_PaymentType, Settings_DisplayIdType } from "../Shared/Shared.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { ClaimsReport_printClaimsReport, ClaimsReport_header, CalendarShipping_printShippingCalendar, CalendarShipping_header, FinalPriceReportToPrint_printResultReport, FinalPriceReportToPrint_header, ResultReport_printPeriodStatisticResultReport, ResultReport_printResultReport, ResultReport_header, Royalties_printRoyalty, Royalties_header, UnPaidOrdersTransactions_transactions, Transactions_transactions, PaidOrdersTransactions_transactions, Transport_transportOrdersTable, Transport_tableHeader, Transport_header, TenderReport_itemTable, TenderReport_tableHeader, TenderReport_header, Delivery_printOrder, Delivery_header } from "./ToPrint.js";
import { Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Common_PagingQuery$1$reflection, Common_PagingQuery$1 } from "../Shared/Common.js";
import { CountryCodeModule_sweden } from "../bidflow/src/infrastructure/Auction.js";
import { op_Addition } from "../fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "../fable_modules/fable-library.3.6.1/Decimal.js";
import { Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, string_type, list_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { fromDate } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { endOfDay, startOfDay } from "date-fns";

export function SubmissionPrintButton_header(submissionPrintButton_headerInputProps) {
    let props_28, props_26, props_10, props_8, props_2, props, props_6, props_4, props_24, props_22, props_15, props_20;
    const userState = submissionPrintButton_headerInputProps.userState;
    const id = submissionPrintButton_headerInputProps.id;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading2Button = patternInput_1[1];
    const generateSubmissionPdf = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading(true);
        return singleton.Bind(submissionsApi().getSubmissionForPrint(id), (_arg1) => {
            window.open(_arg1);
            setIsLoading(false);
            return singleton.Return();
        });
    }), (_arg2) => {
        setIsLoading(false);
        return singleton.Zero();
    }));
    const generateSubmissionPdfWithoutTerms = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading2Button(true);
        return singleton.Bind(submissionsApi().getSubmissionForPrintWithoutTerms(id), (_arg3) => {
            window.open(_arg3);
            setIsLoading2Button(false);
            return singleton.Return();
        });
    }), (_arg4) => {
        setIsLoading2Button(false);
        return singleton.Zero();
    }));
    const props_30 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_28 = ofArray([["className", "pb-2"], ["children", Interop_reactApi.Children.toArray([(props_26 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_10 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-text-grey"], ["className", "is-uppercase"], ["className", "is-size-4"], ["children", t("common.submission")]]), createElement("h1", createObj(Helpers_combineClasses("title", props))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_6 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "has-text-grey"], ["className", "is-uppercase"], ["className", "is-size-4"], ["children", "# " + toString(id)]]), createElement("h1", createObj(Helpers_combineClasses("title", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_8))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_24 = ofArray([["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([backButtonHref(toPath(Route_AccountDetailsSubmissionsPage(userState.UserId, userState.Page)), t("common.back")), (props_15 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(patternInput[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
        let elms, s;
        return append(singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-print"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (s = t("common.print"), createElement("span", {
            className: "",
            children: s,
        }))])]), delay(() => singleton_2(["onClick", (_arg1_1) => {
            startImmediate(generateSubmissionPdf);
        }])));
    })))))), createElement("button", createObj(Helpers_combineClasses("button", props_15)))), (props_20 = toList(delay(() => append(patternInput_1[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
        let elms_1, s_1;
        return append(singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-print"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (s_1 = t("users.printSubmission"), createElement("span", {
            className: "",
            children: s_1,
        }))])]), delay(() => singleton_2(["onClick", (_arg2_1) => {
            startImmediate(generateSubmissionPdfWithoutTerms);
        }])));
    })))), createElement("button", createObj(Helpers_combineClasses("button", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_24))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_26))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_28))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_30)));
}

export function SubmissionPrintButton_footer(submissionPrintButton_footerInputProps) {
    let props_10, props_3, props_8;
    const userState = submissionPrintButton_footerInputProps.userState;
    const id = submissionPrintButton_footerInputProps.id;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading2Button = patternInput_1[1];
    const generateSubmissionPdf = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading(true);
        return singleton.Bind(submissionsApi().getSubmissionForPrint(id), (_arg1) => {
            window.open(_arg1);
            setIsLoading(false);
            return singleton.Return();
        });
    }), (_arg2) => {
        setIsLoading(false);
        return singleton.Zero();
    }));
    const generateSubmissionPdfWithoutTerms = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading2Button(true);
        return singleton.Bind(submissionsApi().getSubmissionForPrintWithoutTerms(id), (_arg3) => {
            window.open(_arg3);
            setIsLoading2Button(false);
            return singleton.Return();
        });
    }), (_arg4) => {
        setIsLoading2Button(false);
        return singleton.Zero();
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_10 = ofArray([["style", {
            justifyContent: "flex-end",
        }], ["children", Interop_reactApi.Children.toArray([backButtonHref(toPath(Route_AccountDetailsSubmissionsPage(userState.UserId, userState.Page)), t("common.back")), (props_3 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(patternInput[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
            let elms, s;
            return append(singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                className: join(" ", ["fa", "fa-print"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (s = t("common.print"), createElement("span", {
                className: "",
                children: s,
            }))])]), delay(() => singleton_2(["onClick", (_arg1_1) => {
                startImmediate(generateSubmissionPdf);
            }])));
        })))))), createElement("button", createObj(Helpers_combineClasses("button", props_3)))), (props_8 = toList(delay(() => append(patternInput_1[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
            let elms_1, s_1;
            return append(singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1(createElement("i", {
                className: join(" ", ["fa", "fa-print"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            })), (s_1 = t("users.printSubmission"), createElement("span", {
                className: "",
                children: s_1,
            }))])]), delay(() => singleton_2(["onClick", (_arg2_1) => {
                startImmediate(generateSubmissionPdfWithoutTerms);
            }])));
        })))), createElement("button", createObj(Helpers_combineClasses("button", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_10))))]),
    });
}

export function FreightToPrint_toPrintSection(freightToPrint_toPrintSectionInputProps) {
    let props_6, props_4, elms, s;
    const allZones = freightToPrint_toPrintSectionInputProps.allZones;
    const ordersToPrint = freightToPrint_toPrintSectionInputProps.ordersToPrint;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setSettings = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Settings_DisplayIdType(0));
    const loadSettings = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettingsForLot()), (_arg2) => {
        setSettings(_arg1.CompanyName);
        patternInput_1[1](_arg2.DisplayIdType);
        window.print();
        return singleton.Return();
    }))), (_arg3) => {
        setSettings("");
        return singleton.Zero();
    }));
    return react.createElement(react.Fragment, {}, createElement("div", {
        id: "section-to-print-album",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(Delivery_header(patternInput[0])), delay(() => collect((order) => singleton_2(Delivery_printOrder(patternInput_1[0], order, find((x) => (x.DeliveryCostZoneId === order.Provider), allZones))), ordersToPrint))))))),
    }), (props_6 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
        className: join(" ", ["fa", "fa-print"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (s = tWithArgs("users.printWithArg", {
        amount: length(ordersToPrint),
    }), createElement("span", {
        className: "",
        children: s,
    }))])], ["onClick", (x_1) => {
        x_1.preventDefault();
        startImmediate(loadSettings);
    }]]), createElement("a", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))));
}

export function TenderReportToPrint_toPrintSection(query) {
    let props_4;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setLoadedLots = patternInput[1];
    const loadedLots = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const getTransportOrders = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setLoadedLots(new Deferred$1(1));
        const query_1 = new Common_PagingQuery$1(query.Payload, 1, 1000, query.SortBy, query.LocalizationContext);
        return singleton.Bind(Remoting_handleNonAuth(lotApi().getLotsForReport(query_1)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg2) => {
            patternInput_1[1](_arg2.CompanyName);
            setLoadedLots(new Deferred$1(2, _arg1));
            window.print();
            return singleton.Return();
        }));
    }), (_arg3) => {
        setLoadedLots(new Deferred$1(3, _arg3));
        return singleton.Zero();
    }));
    const lots_1 = (loadedLots.tag === 1) ? empty_1() : ((loadedLots.tag === 3) ? empty_1() : ((loadedLots.tag === 2) ? loadedLots.fields[0].Result : empty_1()));
    return react.createElement(react.Fragment, {}, createElement("div", {
        style: {
            overflow: "hidden",
        },
        id: "section-to-print-album",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(TenderReport_header(patternInput_1[0])), delay(() => append(singleton_2(TenderReport_tableHeader), delay(() => map((lot) => TenderReport_itemTable(lot), lots_1))))))))),
    }), (props_4 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["onClick", (_arg1_1) => {
        startImmediate(getTransportOrders);
    }]), delay(() => append(equals(loadedLots, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
        let elms, s;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-print"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (s = t("common.print"), createElement("span", {
            className: "",
            children: s,
        }))])]);
    })))))))), createElement("a", createObj(Helpers_combineClasses("button", props_4)))));
}

export function TransportToPrint_toPrintSection(transportToPrint_toPrintSectionInputProps) {
    let props_6, props_4, elms, s;
    const performedTransport = transportToPrint_toPrintSectionInputProps.performedTransport;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setSettings = patternInput[1];
    const loadSettings = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg1) => {
        setSettings(_arg1.CompanyName);
        window.print();
        return singleton.Return();
    })), (_arg2) => {
        setSettings("");
        return singleton.Zero();
    }));
    return react.createElement(react.Fragment, {}, createElement("div", {
        id: "section-to-print-album",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(Transport_header(patternInput[0])), delay(() => append(singleton_2(Transport_tableHeader), delay(() => map((order) => Transport_transportOrdersTable(order), performedTransport))))))))),
    }), (props_6 = ofArray([["className", "has-text-right"], ["className", "no-height-section-to-print"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
        className: join(" ", ["fa", "fa-print"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (s = tWithArgs("users.printWithArg", {
        amount: length(performedTransport),
    }), createElement("span", {
        className: "",
        children: s,
    }))])], ["onClick", (x) => {
        x.preventDefault();
        startImmediate(loadSettings);
    }]]), createElement("a", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))));
}

export function CashReportToPrint_toPrintSection(query) {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setReport = patternInput[1];
    const report = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(CountryCodeModule_sweden);
    const getPaidOrders = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setReport(new Deferred$1(1));
        const query_1 = new Common_PagingQuery$1(query.Payload, 1, 1000, query.SortBy, query.LocalizationContext);
        return singleton.Bind(Remoting_handleNonAuth(ordersApi().getOrderTransactionInfo(query_1)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg2) => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg3) => {
            patternInput_1[1](_arg2.CompanyName);
            patternInput_2[1](_arg3.ResidentCountry);
            setReport(new Deferred$1(2, _arg1));
            window.print();
            return singleton.Return();
        })));
    }), (_arg4) => {
        setReport(new Deferred$1(3, _arg4));
        return singleton.Zero();
    }));
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let r, paymentsInfo, Svea, Bankgiro, Swish;
        return append((report.tag === 1) ? singleton_2(null) : ((report.tag === 3) ? singleton_2(null) : ((report.tag === 2) ? ((r = report.fields[0], (paymentsInfo = ((Svea = sumBy((x_1) => x_1.TotalSum, filter((x) => {
            if (((((equals(x.PaymentsKind, new Orders_PaymentType(5)) ? true : equals(x.PaymentsKind, new Orders_PaymentType(9))) ? true : equals(x.PaymentsKind, new Orders_PaymentType(6))) ? true : equals(x.PaymentsKind, new Orders_PaymentType(7))) ? true : equals(x.PaymentsKind, new Orders_PaymentType(10))) ? true : equals(x.PaymentsKind, new Orders_PaymentType(11))) {
                return true;
            }
            else {
                return equals(x.PaymentsKind, new Orders_PaymentType(8));
            }
        }, r.Transactions.Result), {
            GetZero: () => (new Decimal(0)),
            Add: (x_2, y) => op_Addition(x_2, y),
        }), (Bankgiro = sumBy((x_4) => x_4.TotalSum, filter((x_3) => equals(x_3.PaymentsKind, new Orders_PaymentType(3)), r.Transactions.Result), {
            GetZero: () => (new Decimal(0)),
            Add: (x_5, y_1) => op_Addition(x_5, y_1),
        }), (Swish = sumBy((x_7) => x_7.TotalSum, filter((x_6) => equals(x_6.PaymentsKind, new Orders_PaymentType(0)), r.Transactions.Result), {
            GetZero: () => (new Decimal(0)),
            Add: (x_8, y_2) => op_Addition(x_8, y_2),
        }), {
            Bankgiro: Bankgiro,
            Card: sumBy((x_10) => x_10.TotalSum, filter((x_9) => equals(x_9.PaymentsKind, new Orders_PaymentType(1)), r.Transactions.Result), {
                GetZero: () => (new Decimal(0)),
                Add: (x_11, y_3) => op_Addition(x_11, y_3),
            }),
            Cash: sumBy((x_13) => x_13.TotalSum, filter((x_12) => equals(x_12.PaymentsKind, new Orders_PaymentType(2)), r.Transactions.Result), {
                GetZero: () => (new Decimal(0)),
                Add: (x_14, y_4) => op_Addition(x_14, y_4),
            }),
            Refund: sumBy((x_16) => x_16.TotalSum, filter((x_15) => equals(x_15.TransactionsType, new Orders_TransactionsType(1)), r.Transactions.Result), {
                GetZero: () => (new Decimal(0)),
                Add: (x_17, y_5) => op_Addition(x_17, y_5),
            }),
            Svea: Svea,
            Swish: Swish,
        })))), singleton_2(createElement("div", {
            className: "section-to-print",
            children: Interop_reactApi.Children.toArray([PaidOrdersTransactions_transactions(r, patternInput_2[0], patternInput_1[0], paymentsInfo)]),
        }))))) : singleton_2(null))), delay(() => {
            let props_6, props_4;
            return singleton_2((props_6 = ofArray([["className", "is-narrow"], ["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["onClick", (_arg1_1) => {
                startImmediate(getPaidOrders);
            }]), delay(() => append(equals(report, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
                let elms, s;
                return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                    className: join(" ", ["fa", "fa-print"]),
                })), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (s = t("common.print"), createElement("span", {
                    className: "",
                    children: s,
                }))])]);
            })))))))), createElement("a", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))));
        }));
    })));
}

export function TransactionsReportToPrint_toPrintSection(transactionsReportToPrint_toPrintSectionInputProps) {
    const auction = transactionsReportToPrint_toPrintSectionInputProps.auction;
    const datePeriod = transactionsReportToPrint_toPrintSectionInputProps.datePeriod;
    const query = transactionsReportToPrint_toPrintSectionInputProps.query;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setReport = patternInput[1];
    const report = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(CountryCodeModule_sweden);
    const getTransactions = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setReport(new Deferred$1(1));
        const query_1 = new Common_PagingQuery$1(query.Payload, 1, 1000, query.SortBy, query.LocalizationContext);
        return singleton.Bind(Remoting_handleNonAuth(ordersApi().getTransactionsReportInfo(query_1)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg2) => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg3) => {
            patternInput_1[1](_arg2.CompanyName);
            patternInput_2[1](_arg3.ResidentCountry);
            setReport(new Deferred$1(2, _arg1));
            window.print();
            return singleton.Return();
        })));
    }), (_arg4) => {
        setReport(new Deferred$1(3, _arg4));
        return singleton.Zero();
    }));
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let r, paymentsInfo, Svea, Bankgiro, Swish;
        return append((report.tag === 1) ? singleton_2(null) : ((report.tag === 3) ? singleton_2(null) : ((report.tag === 2) ? ((r = report.fields[0], (paymentsInfo = ((Svea = sumBy((x_1) => x_1.TotalSum, filter((x) => {
            if (((((equals(x.PaymentsKind, new Orders_PaymentType(5)) ? true : equals(x.PaymentsKind, new Orders_PaymentType(9))) ? true : equals(x.PaymentsKind, new Orders_PaymentType(6))) ? true : equals(x.PaymentsKind, new Orders_PaymentType(7))) ? true : equals(x.PaymentsKind, new Orders_PaymentType(10))) ? true : equals(x.PaymentsKind, new Orders_PaymentType(11))) {
                return true;
            }
            else {
                return equals(x.PaymentsKind, new Orders_PaymentType(8));
            }
        }, r.Transactions.Result), {
            GetZero: () => (new Decimal(0)),
            Add: (x_2, y) => op_Addition(x_2, y),
        }), (Bankgiro = sumBy((x_4) => x_4.TotalSum, filter((x_3) => equals(x_3.PaymentsKind, new Orders_PaymentType(3)), r.Transactions.Result), {
            GetZero: () => (new Decimal(0)),
            Add: (x_5, y_1) => op_Addition(x_5, y_1),
        }), (Swish = sumBy((x_7) => x_7.TotalSum, filter((x_6) => equals(x_6.PaymentsKind, new Orders_PaymentType(0)), r.Transactions.Result), {
            GetZero: () => (new Decimal(0)),
            Add: (x_8, y_2) => op_Addition(x_8, y_2),
        }), {
            Bankgiro: Bankgiro,
            Card: sumBy((x_10) => x_10.TotalSum, filter((x_9) => equals(x_9.PaymentsKind, new Orders_PaymentType(1)), r.Transactions.Result), {
                GetZero: () => (new Decimal(0)),
                Add: (x_11, y_3) => op_Addition(x_11, y_3),
            }),
            Cash: sumBy((x_13) => x_13.TotalSum, filter((x_12) => equals(x_12.PaymentsKind, new Orders_PaymentType(2)), r.Transactions.Result), {
                GetZero: () => (new Decimal(0)),
                Add: (x_14, y_4) => op_Addition(x_14, y_4),
            }),
            Refund: sumBy((x_16) => x_16.TotalSum, filter((x_15) => equals(x_15.TransactionsType, new Orders_TransactionsType(1)), r.Transactions.Result), {
                GetZero: () => (new Decimal(0)),
                Add: (x_17, y_5) => op_Addition(x_17, y_5),
            }),
            Svea: Svea,
            Swish: Swish,
        })))), singleton_2(createElement("div", {
            className: "section-to-print",
            children: Interop_reactApi.Children.toArray([Transactions_transactions(r, patternInput_2[0], patternInput_1[0], datePeriod, paymentsInfo, auction)]),
        }))))) : singleton_2(null))), delay(() => {
            let props_6, props_4;
            return singleton_2((props_6 = ofArray([["className", "is-narrow"], ["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["onClick", (_arg1_1) => {
                startImmediate(getTransactions);
            }]), delay(() => append(equals(report, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
                let elms, s;
                return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                    className: join(" ", ["fa", "fa-print"]),
                })), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (s = t("common.print"), createElement("span", {
                    className: "",
                    children: s,
                }))])]);
            })))))))), createElement("a", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))));
        }));
    })));
}

export function ControlReportToPrint_toPrintSection(query) {
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setReport = patternInput_1[1];
    const report = patternInput_1[0];
    const getUnpaidOrders = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setReport(new Deferred$1(1));
        const query_1 = new Common_PagingQuery$1(query.Payload, 1, 1000, query.SortBy, query.LocalizationContext);
        return singleton.Bind(Remoting_handleNonAuth(ordersApi().getUnpaidOrdersReportByAuction(query_1)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg2) => {
            patternInput[1](_arg2.CompanyName);
            setReport(new Deferred$1(2, _arg1));
            window.print();
            return singleton.Return();
        }));
    }), (_arg3) => {
        setReport(new Deferred$1(3, _arg3));
        return singleton.Zero();
    }));
    return react.createElement(react.Fragment, {}, ...toList(delay(() => append((report.tag === 1) ? singleton_2(null) : ((report.tag === 3) ? singleton_2(null) : ((report.tag === 2) ? singleton_2(createElement("div", {
        className: "section-to-print",
        children: Interop_reactApi.Children.toArray([UnPaidOrdersTransactions_transactions(report.fields[0], patternInput[0])]),
    })) : singleton_2(null))), delay(() => {
        let props_6, props_4;
        return singleton_2((props_6 = ofArray([["className", "is-narrow"], ["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(equals(report, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
            let elms, s;
            return append(singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                className: join(" ", ["fa", "fa-print"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (s = t("common.print"), createElement("span", {
                className: "",
                children: s,
            }))])]), delay(() => singleton_2(["onClick", (_arg1_1) => {
                startImmediate(getUnpaidOrders);
            }])));
        })))))), createElement("a", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))));
    })))));
}

export function RoyaltiesToPrint_toPrintSection(royaltiesToPrint_toPrintSectionInputProps) {
    let props_13, props_11, props_4, elms, s, props_9;
    const query = royaltiesToPrint_toPrintSectionInputProps.query;
    const royaltiesToPrint = royaltiesToPrint_toPrintSectionInputProps.royaltiesToPrint;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput[1];
    const generateRoyaltyPdf = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading(true);
        return singleton.Bind(royaltyApi().getRoyaltyReport(query), (_arg1) => {
            window.open(_arg1);
            setIsLoading(false);
            return singleton.Return();
        });
    }), (_arg2) => {
        setIsLoading(false);
        return singleton.Zero();
    }));
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const setSettings = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Settings_DisplayIdType(0));
    const loadSettings = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg3) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettingsForLot()), (_arg4) => {
        setSettings(_arg3.CompanyName);
        patternInput_2[1](_arg4.DisplayIdType);
        window.print();
        return singleton.Return();
    }))), (_arg5) => {
        setSettings("");
        return singleton.Zero();
    }));
    return react.createElement(react.Fragment, {}, createElement("div", {
        id: "section-to-print-album",
        children: Interop_reactApi.Children.toArray([Royalties_header(patternInput_1[0]), Royalties_printRoyalty(patternInput_2[0], royaltiesToPrint)]),
    }), (props_13 = ofArray([["className", "has-text-right"], ["style", {
        alignSelf: "flex-end",
    }], ["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-primary"], ["onClick", (x) => {
        x.preventDefault();
        startImmediate(loadSettings);
    }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
        className: join(" ", ["fa", "fa-print"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (s = (`Skriv ut(${length(royaltiesToPrint)})`), createElement("span", {
        className: "",
        children: s,
    }))])]]), createElement("a", createObj(Helpers_combineClasses("button", props_4)))), (props_9 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(patternInput[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
        let elms_1, s_1;
        return append(singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-print"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (s_1 = t("reports.report"), createElement("span", {
            className: "",
            children: s_1,
        }))])]), delay(() => singleton_2(["onClick", (_arg1_1) => {
            startImmediate(generateRoyaltyPdf);
        }])));
    })))))), createElement("button", createObj(Helpers_combineClasses("button", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))));
}

export class ResultReportToPrint_PrintType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Statistic", "ResultReport"];
    }
}

export function ResultReportToPrint_PrintType$reflection() {
    return union_type("Print.PrintButtons.ResultReportToPrint.PrintType", [], ResultReportToPrint_PrintType, () => [[["Item", list_type(class_type("System.DateTime"))]], [["query", Common_PagingQuery$1$reflection(PayOuts_OrderResultReportFilter$reflection())], ["statistic", PayOuts_ResultReportSummaries$reflection()], ["auctionName", string_type]]]);
}

export class ResultReportToPrint_ToPrint extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Statistic", "ResultReport"];
    }
}

export function ResultReportToPrint_ToPrint$reflection() {
    return union_type("Print.PrintButtons.ResultReportToPrint.ToPrint", [], ResultReportToPrint_ToPrint, () => [[], []]);
}

export function ResultReportToPrint_printResultReport(resultReportToPrint_printResultReportInputProps) {
    let props_5, props_3;
    const auctionName = resultReportToPrint_printResultReportInputProps.auctionName;
    const statistic = resultReportToPrint_printResultReportInputProps.statistic;
    const query = resultReportToPrint_printResultReportInputProps.query;
    const setIsToPrint = resultReportToPrint_printResultReportInputProps.setIsToPrint;
    const isToPrint = resultReportToPrint_printResultReportInputProps.isToPrint;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const patternInput_1 = useFeliz_React__React_useState_Static_1505({
        PayOut: empty_1(),
        ResidentCountry: CountryCodeModule_sweden,
        Totals: PayOuts_Receipt_get_Empty(),
    });
    const content = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput_2[1];
    window.addEventListener("afterprint", (x) => {
        setIsToPrint(void 0);
    });
    return react.createElement(react.Fragment, {}, (props_5 = ofArray([["className", "has-text-right"], ["className", "is-narrow"], ["className", join(" ", ["no-height-section-to-print", "no-width-section-to-print"])], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(patternInput_2[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
        const newQuery = new Common_PagingQuery$1(query.Payload, 1, 1000, query.SortBy, query.LocalizationContext);
        return append(singleton_2(["onClick", (x_1) => {
            let query_1;
            x_1.preventDefault();
            startImmediate((query_1 = newQuery, singleton.Delay(() => {
                setIsLoading(true);
                return singleton.Bind(Remoting_handleNonAuth(payOutApi().getResultReport(query_1)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(payOutApi().getTotals(query_1.Payload.AuctionId)), (_arg2) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg3) => singleton.Bind(Remoting_handleNonAuth(commonApi().loadCompanyBasicSettings()), (_arg4) => {
                    patternInput[1](_arg3.CompanyName);
                    patternInput_1[1]({
                        PayOut: _arg1.Result,
                        ResidentCountry: _arg4.ResidentCountry,
                        Totals: _arg2,
                    });
                    setIsLoading(false);
                    setIsToPrint(new ResultReportToPrint_ToPrint(1));
                    window.print();
                    return singleton.Zero();
                }))));
            })));
        }]), delay(() => {
            let elms, s;
            return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                className: join(" ", ["fa", "fa-print"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (s = t("reports.printAuctionDetails"), createElement("span", {
                className: "",
                children: s,
            }))])]);
        }));
    })))))), createElement("a", createObj(Helpers_combineClasses("button", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), createElement("div", createObj(toList(delay(() => append((isToPrint == null) ? append(singleton_2(["className", join(" ", ["no-height-section-to-print", "no-width-section-to-print"])]), delay(() => singleton_2(["style", {
        display: "none",
    }]))) : ((isToPrint.tag === 0) ? append(singleton_2(["className", join(" ", ["no-height-section-to-print", "no-width-section-to-print"])]), delay(() => singleton_2(["style", {
        display: "none",
    }]))) : append(singleton_2(["className", "section-to-print"]), delay(() => singleton_2(["style", {
        overflowX: "hidden",
        marginRight: 0,
    }])))), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([ResultReport_header(auctionName, patternInput[0]), ResultReport_printResultReport(content.ResidentCountry, content.PayOut, statistic, content.Totals)])]))))))));
}

export function ResultReportToPrint_printStatistic(resultReportToPrint_printStatisticInputProps) {
    let props_5, props_3;
    const period = resultReportToPrint_printStatisticInputProps.period;
    const setIsToPrint = resultReportToPrint_printStatisticInputProps.setIsToPrint;
    const isToPrint = resultReportToPrint_printStatisticInputProps.isToPrint;
    const startDate = fromDate(startOfDay(fromDate(item(0, period))));
    const endDate = fromDate(endOfDay(fromDate(item(1, period))));
    const patternInput = useFeliz_React__React_useState_Static_1505({
        CompanyName: "",
        ResidentCountry: CountryCodeModule_sweden,
        Statistics: empty_1(),
    });
    const content = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput_1[1];
    window.addEventListener("afterprint", (x) => {
        setIsToPrint(void 0);
    });
    return react.createElement(react.Fragment, {}, (props_5 = ofArray([["className", "has-text-right"], ["className", "is-narrow"], ["className", join(" ", ["no-height-section-to-print", "no-width-section-to-print"])], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(patternInput_1[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["onClick", (x_1) => {
        x_1.preventDefault();
        startImmediate(singleton.Delay(() => {
            setIsLoading(true);
            return singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg1) => singleton.Bind(Remoting_handleNonAuth(payOutApi().getDatePeriodStatistic([startDate, endDate, getCurrentLanguage()])), (_arg2) => singleton.Bind(Remoting_handleNonAuth(commonApi().loadCompanyBasicSettings()), (_arg3) => {
                patternInput[1]({
                    CompanyName: _arg1.CompanyName,
                    ResidentCountry: _arg3.ResidentCountry,
                    Statistics: _arg2,
                });
                setIsLoading(false);
                setIsToPrint(new ResultReportToPrint_ToPrint(0));
                window.print();
                return singleton.Zero();
            })));
        }));
    }]), delay(() => {
        let elms, s;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-print"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (s = t("reports.printSummary"), createElement("span", {
            className: "",
            children: s,
        }))])]);
    })))))))), createElement("a", createObj(Helpers_combineClasses("button", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), createElement("div", createObj(toList(delay(() => append((isToPrint == null) ? append(singleton_2(["className", join(" ", ["no-height-section-to-print", "no-width-section-to-print"])]), delay(() => singleton_2(["style", {
        display: "none",
    }]))) : ((isToPrint.tag === 1) ? append(singleton_2(["className", join(" ", ["no-height-section-to-print", "no-width-section-to-print"])]), delay(() => singleton_2(["style", {
        display: "none",
    }]))) : append(singleton_2(["className", "section-to-print"]), delay(() => singleton_2(["style", {
        overflowX: "hidden",
        marginRight: 0,
    }])))), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([ResultReport_printPeriodStatisticResultReport(content.CompanyName, content.ResidentCountry, startDate, endDate, content.Statistics)])]))))))));
}

export function ResultReportToPrint_toPrintSection(resultReportToPrint_toPrintSectionInputProps) {
    const printType = resultReportToPrint_toPrintSectionInputProps.printType;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setIsToPrint = patternInput[1];
    const isToPrint = patternInput[0];
    if (printType.tag === 1) {
        return createElement(ResultReportToPrint_printResultReport, {
            isToPrint: isToPrint,
            setIsToPrint: setIsToPrint,
            query: printType.fields[0],
            statistic: printType.fields[1],
            auctionName: printType.fields[2],
        });
    }
    else {
        return createElement(ResultReportToPrint_printStatistic, {
            isToPrint: isToPrint,
            setIsToPrint: setIsToPrint,
            period: printType.fields[0],
        });
    }
}

export function FinalPriceReportToPrint_toPrintSection(finalPriceReportToPrint_toPrintSectionInputProps) {
    let props_6, props_4;
    const query = finalPriceReportToPrint_toPrintSectionInputProps.query;
    const statistic = finalPriceReportToPrint_toPrintSectionInputProps.statistic;
    const category = finalPriceReportToPrint_toPrintSectionInputProps.category;
    const auctionName = finalPriceReportToPrint_toPrintSectionInputProps.auctionName;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty_1());
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput_2[1];
    return react.createElement(react.Fragment, {}, createElement("div", {
        style: {
            overflow: "hidden",
        },
        id: "section-to-print-album",
        children: Interop_reactApi.Children.toArray([FinalPriceReportToPrint_header(auctionName, category, patternInput[0]), FinalPriceReportToPrint_printResultReport(patternInput_1[0], statistic)]),
    }), (props_6 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["style", {
        alignSelf: "flex-end",
    }], ["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(patternInput_2[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => {
        const newQuery = new Common_PagingQuery$1(query.Payload, 1, 1000, query.SortBy, query.LocalizationContext);
        return append(singleton_2(["onClick", (x) => {
            x.preventDefault();
            startImmediate(singleton.Delay(() => {
                setIsLoading(true);
                return singleton.Bind(Remoting_handleNonAuth(lotApi().getFinalPriceReport(newQuery)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg2) => {
                    patternInput[1](_arg2.CompanyName);
                    patternInput_1[1](_arg1.Result);
                    setIsLoading(false);
                    window.print();
                    return singleton.Zero();
                }));
            }));
        }]), delay(() => {
            let elms, s;
            return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                className: join(" ", ["fa", "fa-print"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (s = t("common.print"), createElement("span", {
                className: "",
                children: s,
            }))])]);
        }));
    })))))), createElement("a", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))));
}

export function CalendarShippingToPrint_toPrintSection(ordersToPrint, deliveryZones) {
    let props_6, props_4, elms, s;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const loadSettings = singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg1) => {
        patternInput[1](_arg1.CompanyName);
        window.print();
        return singleton.Zero();
    }));
    const orders = (ordersToPrint.tag === 1) ? empty_1() : ((ordersToPrint.tag === 3) ? empty_1() : ((ordersToPrint.tag === 2) ? ordersToPrint.fields[0] : empty_1()));
    return react.createElement(react.Fragment, {}, createElement("div", {
        id: "section-to-print-album",
        children: Interop_reactApi.Children.toArray([CalendarShipping_header(patternInput[0]), CalendarShipping_printShippingCalendar(orders, deliveryZones)]),
    }), (props_6 = ofArray([["className", "is-narrow"], ["className", "no-height-section-to-print"], ["onClick", (x) => {
        x.preventDefault();
        startImmediate(loadSettings);
    }], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
        className: join(" ", ["fa", "fa-print"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (s = t("common.print"), createElement("span", {
        className: "",
        children: s,
    }))])]]), createElement("a", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))));
}

export function ClaimsReport_toPrintSection(ordersToPrint, payload) {
    let props_6, props_4, elms, s;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const loadSettings = singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg1) => {
        patternInput[1](_arg1.CompanyName);
        window.print();
        return singleton.Zero();
    }));
    const items_1 = (ordersToPrint.tag === 1) ? empty_1() : ((ordersToPrint.tag === 2) ? ((ordersToPrint.fields[0].tag === 0) ? ordersToPrint.fields[0].fields[0] : empty_1()) : empty_1());
    return react.createElement(react.Fragment, {}, createElement("div", {
        id: "section-to-print-album",
        children: Interop_reactApi.Children.toArray([ClaimsReport_header(patternInput[0], payload), ClaimsReport_printClaimsReport(items_1)]),
    }), (props_6 = ofArray([["className", "is-narrow"], ["className", "no-height-section-to-print"], ["onClick", (x) => {
        x.preventDefault();
        startImmediate(loadSettings);
    }], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
        className: join(" ", ["fa", "fa-print"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (s = t("common.print"), createElement("span", {
        className: "",
        children: s,
    }))])]]), createElement("a", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))));
}

