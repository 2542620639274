import { min, comparePrimitives, max, createObj, round } from "../fable_modules/fable-library.3.6.1/Util.js";
import { toNumber } from "../fable_modules/fable-library.3.6.1/Long.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { tWithArgs } from "../Localization.js";
import { map, empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_1 } from "../fable_modules/fable-library.3.6.1/List.js";
import { rangeDouble } from "../fable_modules/fable-library.3.6.1/Range.js";

export function getTotalPages(total, pageSize) {
    return ~(~round(Math.ceil(toNumber(total) / pageSize)));
}

export function renderPagination(current, totalItems, pageSize, onChange) {
    let props_9, elms;
    const totalPages = getTotalPages(totalItems, pageSize) | 0;
    const children_7 = singleton_1((props_9 = ofArray([["className", "is-right"], ["className", "my-5"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        className: "ml-1",
        style: {
            fontSize: 14 + "px",
        },
        children: tWithArgs("common.rowsWithArg", {
            amount: totalItems,
        }),
    }), (elms = toList(delay(() => {
        let xs_3;
        const paginationLink = (pageIndex) => {
            let props_1, children;
            const children_2 = singleton_1((props_1 = toList(delay(() => append(singleton(["style", {
                display: "flex",
            }]), delay(() => append((current === pageIndex) ? singleton(["className", "is-current"]) : empty(), delay(() => append(singleton(["onClick", (x) => {
                onChange(pageIndex);
                x.preventDefault();
            }]), delay(() => singleton(["children", pageIndex]))))))))), (children = singleton_1(createElement("a", createObj(Helpers_combineClasses("pagination-link", props_1)))), createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))));
            return createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            });
        };
        const leftRightExtraPages = 2;
        let ellipsis;
        const children_4 = singleton_1((xs_3 = ofArray([["dangerouslySetInnerHTML", {
            __html: "\u0026hellip;",
        }], ["dangerouslySetInnerHTML", {
            __html: "…",
        }]]), createElement("span", createObj(Helpers_combineClasses("pagination-ellipsis", xs_3)))));
        ellipsis = createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        });
        return append(singleton(paginationLink(1)), delay(() => append((current > (leftRightExtraPages + 2)) ? singleton(ellipsis) : empty(), delay(() => append(map((p) => paginationLink(p), rangeDouble(max((x_1, y) => comparePrimitives(x_1, y), 2, current - leftRightExtraPages), 1, min((x_2, y_1) => comparePrimitives(x_2, y_1), totalPages - 1, current + leftRightExtraPages))), delay(() => append((current < ((totalPages - leftRightExtraPages) - 1)) ? singleton(ellipsis) : empty(), delay(() => ((totalPages > 1) ? singleton(paginationLink(totalPages)) : empty())))))))));
    })), createElement("ul", {
        className: "pagination-list",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("nav", createObj(Helpers_combineClasses("pagination", props_9)))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_7)),
    });
}

