import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "./Extensions.js";
import { agreementApi } from "./Communication.js";
import { loader } from "./Loader.js";
import { createElement } from "react";
import { singleton, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Render as Render_1 } from "./AgreementCompany.js";
import { Render as Render_2 } from "./AgreementPerson.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";

export function Render(renderInputProps) {
    const agreementId = renderInputProps.agreementId;
    const agreement = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(agreementApi().getAgreement(agreementId)), []);
    switch (agreement.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            const value = agreement.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const content = agreement.fields[0];
            const children = toList(delay(() => ((content.tag === 1) ? singleton(createElement(Render_1, content.fields[0])) : singleton(createElement(Render_2, content.fields[0])))));
            return createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
        }
        default: {
            return null;
        }
    }
}

