import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { User_ContactPersonInfo, User_BankInfo, User_Address, User_SellerCompanyInfo, User_SellerCompany, CommonQueries_CompanyBasicSettings, CommonQueries_CompanyBasicSettings$reflection, User_SellerCompany$reflection } from "../Shared/Shared.js";
import { record_type, bool_type, class_type, list_type, unit_type, union_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Remoting_handleNonAuth, AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi, commonApi } from "../Communication.js";
import { t, tWithArgs } from "../Localization.js";
import { PhoneModule_toString, PhoneModule_ofString, EmailModule_toString, EmailModule_ofString, CountryCodeModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { SellerCompany_validate, SellerCompany_validateIf } from "../Validation.js";
import { toPath, Route_AccountDetailsSubmissions_7639071A, navigateTo } from "../Router.js";
import { CountryModule_shortCode } from "../bidflow/src/infrastructure/Countries.js";
import { append as append_1, map, cons, empty as empty_2, singleton as singleton_2, ofArray, fold } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { dividedTop } from "../Components/BorderDividedLine.js";
import { CountriesInput_getSortedList, Form_getFieldError } from "../Common.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { isCurrentUserAdmin } from "../LocalStorage.js";

export class State extends Record {
    constructor(Form, BasicCompanySettings, SaveFormOperation, FormErrors, NeedToValidate) {
        super();
        this.Form = Form;
        this.BasicCompanySettings = BasicCompanySettings;
        this.SaveFormOperation = SaveFormOperation;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
    }
}

export function State$reflection() {
    return record_type("CompanyProfile.State", [], State, () => [["Form", User_SellerCompany$reflection()], ["BasicCompanySettings", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CommonQueries_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CommonQueries_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadBasicSettings", "CompanyNameChanged", "OrganizationNumberChanged", "VatNumberChanged", "EmailChanged", "NonExistentEmailChanged", "NotifyAboutOverbidChanged", "NotifyNewsletterChanged", "AvoidWarehouseFeeChanged", "ZipCodeChanged", "AddressChanged", "CityChanged", "CountryChanged", "IsVATDisabledChanged", "ClearingNumberChanged", "BankNumberChanged", "BankChanged", "IbanCountryChanged", "IbanChanged", "CommentChanged", "FirstNameChanged", "LastNameChanged", "PersonalPhoneChanged", "PersonalEmailChanged", "SaveForm"];
    }
}

export function Msg$reflection() {
    return union_type("CompanyProfile.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CommonQueries_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CommonQueries_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function init(company) {
    return [new State(company, new Deferred$1(0), new Deferred$1(0), empty(), false), Cmd_none()];
}

export function loadResidentCountry() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg1) => {
        const settings = _arg1;
        return singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, new CommonQueries_CompanyBasicSettings(settings.ResidentCountry, settings.CollectPersonalNumber)))));
    })), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function saveProfile(x) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(usersApi().updateSellerCompany(x)), (_arg1) => ((_arg1.tag === 1) ? ((_arg1.fields[0].tag === 1) ? singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, tWithArgs("errors.fromServer.userWithIdNotFoundWithArg", {
        userId: x.UserId,
    }))))) : ((_arg1.fields[0].tag === 2) ? singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.agreementDoesNotExist"))))) : singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, tWithArgs("errors.fromServer.userAlreadyExistWithArg", {
        email: _arg1.fields[0].fields[0],
    }))))))) : singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function withForm(seller, state) {
    return new State(seller, state.BasicCompanySettings, state.SaveFormOperation, state.FormErrors, state.NeedToValidate);
}

export function withAddress(address, person) {
    return new User_SellerCompany(person.UserId, person.Email, person.NonExistentEmail, person.NeedNotifyAboutOverbid, person.NeedNotifyNewsletter, person.NeedAvoidWarehouseFee, person.CompanyInfo, person.BankInfo, address, person.ContactPerson);
}

export function withBankInfo(bankInfo, person) {
    return new User_SellerCompany(person.UserId, person.Email, person.NonExistentEmail, person.NeedNotifyAboutOverbid, person.NeedNotifyNewsletter, person.NeedAvoidWarehouseFee, person.CompanyInfo, bankInfo, person.Address, person.ContactPerson);
}

export function withCompanyInfo(x, person) {
    return new User_SellerCompany(person.UserId, person.Email, person.NonExistentEmail, person.NeedNotifyAboutOverbid, person.NeedNotifyNewsletter, person.NeedAvoidWarehouseFee, x, person.BankInfo, person.Address, person.ContactPerson);
}

export function withContactPerson(x, person) {
    return new User_SellerCompany(person.UserId, person.Email, person.NonExistentEmail, person.NeedNotifyAboutOverbid, person.NeedNotifyNewsletter, person.NeedAvoidWarehouseFee, person.CompanyInfo, person.BankInfo, person.Address, x);
}

export function withFormAndErrors(form, errors, state) {
    return new State(form, state.BasicCompanySettings, state.SaveFormOperation, errors, state.NeedToValidate);
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12, inputRecord_13, inputRecord_14, inputRecord_15, inputRecord_16, inputRecord_17, inputRecord_18, inputRecord_19, inputRecord_20, inputRecord_21, inputRecord_22, inputRecord_23;
    const basicSettings = (settings) => {
        let pattern_matching_result, s;
        if (settings.tag === 1) {
            pattern_matching_result = 0;
        }
        else if (settings.tag === 2) {
            if (settings.fields[0].tag === 0) {
                pattern_matching_result = 2;
                s = settings.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return CountryCodeModule_empty;
            }
            case 1: {
                return CountryCodeModule_empty;
            }
            case 2: {
                return s.ResidentCountry;
            }
        }
    };
    let validate;
    const residentCountry = basicSettings(state.BasicCompanySettings);
    validate = ((company) => SellerCompany_validateIf(residentCountry, state.NeedToValidate, company));
    const withValidatedForm = (arg) => {
        const tupledArg = validate(arg);
        return (state_1) => withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 4) {
        return [withValidatedForm((inputRecord = state.Form, new User_SellerCompany(inputRecord.UserId, msg.fields[0], inputRecord.NonExistentEmail, inputRecord.NeedNotifyAboutOverbid, inputRecord.NeedNotifyNewsletter, inputRecord.NeedAvoidWarehouseFee, inputRecord.CompanyInfo, inputRecord.BankInfo, inputRecord.Address, inputRecord.ContactPerson)))(state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new State((inputRecord_1 = state.Form, new User_SellerCompany(inputRecord_1.UserId, inputRecord_1.Email, msg.fields[0], inputRecord_1.NeedNotifyAboutOverbid, inputRecord_1.NeedNotifyNewsletter, inputRecord_1.NeedAvoidWarehouseFee, inputRecord_1.CompanyInfo, inputRecord_1.BankInfo, inputRecord_1.Address, inputRecord_1.ContactPerson)), state.BasicCompanySettings, state.SaveFormOperation, state.FormErrors, state.NeedToValidate), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [new State((inputRecord_2 = state.Form, new User_SellerCompany(inputRecord_2.UserId, inputRecord_2.Email, inputRecord_2.NonExistentEmail, inputRecord_2.NeedNotifyAboutOverbid, inputRecord_2.NeedNotifyNewsletter, msg.fields[0], inputRecord_2.CompanyInfo, inputRecord_2.BankInfo, inputRecord_2.Address, inputRecord_2.ContactPerson)), state.BasicCompanySettings, state.SaveFormOperation, state.FormErrors, state.NeedToValidate), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new State((inputRecord_3 = state.Form, new User_SellerCompany(inputRecord_3.UserId, inputRecord_3.Email, inputRecord_3.NonExistentEmail, msg.fields[0], inputRecord_3.NeedNotifyNewsletter, inputRecord_3.NeedAvoidWarehouseFee, inputRecord_3.CompanyInfo, inputRecord_3.BankInfo, inputRecord_3.Address, inputRecord_3.ContactPerson)), state.BasicCompanySettings, state.SaveFormOperation, state.FormErrors, state.NeedToValidate), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new State((inputRecord_4 = state.Form, new User_SellerCompany(inputRecord_4.UserId, inputRecord_4.Email, inputRecord_4.NonExistentEmail, inputRecord_4.NeedNotifyAboutOverbid, msg.fields[0], inputRecord_4.NeedAvoidWarehouseFee, inputRecord_4.CompanyInfo, inputRecord_4.BankInfo, inputRecord_4.Address, inputRecord_4.ContactPerson)), state.BasicCompanySettings, state.SaveFormOperation, state.FormErrors, state.NeedToValidate), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [withValidatedForm(withCompanyInfo((inputRecord_5 = state.Form.CompanyInfo, new User_SellerCompanyInfo(msg.fields[0], inputRecord_5.OrganizationNumber, inputRecord_5.IsVATDisabled, inputRecord_5.VatNumber)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm(withCompanyInfo((inputRecord_6 = state.Form.CompanyInfo, new User_SellerCompanyInfo(inputRecord_6.Name, msg.fields[0], inputRecord_6.IsVATDisabled, inputRecord_6.VatNumber)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm(withCompanyInfo((inputRecord_7 = state.Form.CompanyInfo, new User_SellerCompanyInfo(inputRecord_7.Name, inputRecord_7.OrganizationNumber, inputRecord_7.IsVATDisabled, msg.fields[0])), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [withValidatedForm(withAddress((inputRecord_8 = state.Form.Address, new User_Address(msg.fields[0], inputRecord_8.ZipCode, inputRecord_8.City, inputRecord_8.Country)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withValidatedForm(withAddress((inputRecord_9 = state.Form.Address, new User_Address(inputRecord_9.Address, msg.fields[0], inputRecord_9.City, inputRecord_9.Country)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [withValidatedForm(withAddress((inputRecord_10 = state.Form.Address, new User_Address(inputRecord_10.Address, inputRecord_10.ZipCode, msg.fields[0], inputRecord_10.Country)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [withValidatedForm(withAddress((inputRecord_11 = state.Form.Address, new User_Address(inputRecord_11.Address, inputRecord_11.ZipCode, inputRecord_11.City, msg.fields[0])), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [new State((inputRecord_12 = state.Form, new User_SellerCompany(inputRecord_12.UserId, inputRecord_12.Email, inputRecord_12.NonExistentEmail, inputRecord_12.NeedNotifyAboutOverbid, inputRecord_12.NeedNotifyNewsletter, inputRecord_12.NeedAvoidWarehouseFee, (inputRecord_13 = state.Form.CompanyInfo, new User_SellerCompanyInfo(inputRecord_13.Name, inputRecord_13.OrganizationNumber, msg.fields[0], inputRecord_13.VatNumber)), inputRecord_12.BankInfo, inputRecord_12.Address, inputRecord_12.ContactPerson)), state.BasicCompanySettings, state.SaveFormOperation, state.FormErrors, state.NeedToValidate), Cmd_none()];
    }
    else if (msg.tag === 14) {
        return [withValidatedForm(withBankInfo((inputRecord_14 = state.Form.BankInfo, new User_BankInfo(msg.fields[0], inputRecord_14.BankNumber, inputRecord_14.Bank, inputRecord_14.Comment, inputRecord_14.IbanCountry, inputRecord_14.Iban)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [withValidatedForm(withBankInfo((inputRecord_15 = state.Form.BankInfo, new User_BankInfo(inputRecord_15.ClearingNumber, msg.fields[0], inputRecord_15.Bank, inputRecord_15.Comment, inputRecord_15.IbanCountry, inputRecord_15.Iban)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 16) {
        return [withValidatedForm(withBankInfo((inputRecord_16 = state.Form.BankInfo, new User_BankInfo(inputRecord_16.ClearingNumber, inputRecord_16.BankNumber, msg.fields[0], inputRecord_16.Comment, inputRecord_16.IbanCountry, inputRecord_16.Iban)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 17) {
        return [withValidatedForm(withBankInfo((inputRecord_17 = state.Form.BankInfo, new User_BankInfo(inputRecord_17.ClearingNumber, inputRecord_17.BankNumber, inputRecord_17.Bank, inputRecord_17.Comment, msg.fields[0], inputRecord_17.Iban)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 18) {
        return [withValidatedForm(withBankInfo((inputRecord_18 = state.Form.BankInfo, new User_BankInfo(inputRecord_18.ClearingNumber, inputRecord_18.BankNumber, inputRecord_18.Bank, inputRecord_18.Comment, inputRecord_18.IbanCountry, msg.fields[0])), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 19) {
        return [withValidatedForm(withBankInfo((inputRecord_19 = state.Form.BankInfo, new User_BankInfo(inputRecord_19.ClearingNumber, inputRecord_19.BankNumber, inputRecord_19.Bank, msg.fields[0], inputRecord_19.IbanCountry, inputRecord_19.Iban)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 20) {
        return [withValidatedForm(withContactPerson((inputRecord_20 = state.Form.ContactPerson, new User_ContactPersonInfo(msg.fields[0], inputRecord_20.LastName, inputRecord_20.Phone, inputRecord_20.Email)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 21) {
        return [withValidatedForm(withContactPerson((inputRecord_21 = state.Form.ContactPerson, new User_ContactPersonInfo(inputRecord_21.FirstName, msg.fields[0], inputRecord_21.Phone, inputRecord_21.Email)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 22) {
        return [withValidatedForm(withContactPerson((inputRecord_22 = state.Form.ContactPerson, new User_ContactPersonInfo(inputRecord_22.FirstName, inputRecord_22.LastName, msg.fields[0], inputRecord_22.Email)), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 23) {
        return [withValidatedForm(withContactPerson((inputRecord_23 = state.Form.ContactPerson, new User_ContactPersonInfo(inputRecord_23.FirstName, inputRecord_23.LastName, inputRecord_23.Phone, msg.fields[0])), state.Form))(state), Cmd_none()];
    }
    else if (msg.tag === 24) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.Form, state.BasicCompanySettings, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.NeedToValidate), Cmd_none()];
            }
            else {
                return [new State(state.Form, state.BasicCompanySettings, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.NeedToValidate), navigateTo(Route_AccountDetailsSubmissions_7639071A(state.Form.UserId))];
            }
        }
        else {
            const matchValue = SellerCompany_validate(basicSettings(state.BasicCompanySettings), state.Form);
            if (matchValue.tag === 1) {
                return [new State(state.Form, state.BasicCompanySettings, new Deferred$1(0), matchValue.fields[0], true), Cmd_none()];
            }
            else {
                return [new State(state.Form, state.BasicCompanySettings, new Deferred$1(1), empty(), true), Cmd_fromAsync(saveProfile(state.Form))];
            }
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new State(state.Form, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.SaveFormOperation, state.FormErrors, state.NeedToValidate), Cmd_none()];
        }
        else {
            return [new State(state.Form, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.SaveFormOperation, state.FormErrors, state.NeedToValidate), Cmd_none()];
        }
    }
    else {
        return [new State(state.Form, new Deferred$1(1), state.SaveFormOperation, state.FormErrors, state.NeedToValidate), Cmd_fromAsync(loadResidentCountry())];
    }
}

export function CountryCodeToString(c) {
    if (c === CountryCodeModule_empty) {
        return "-";
    }
    else {
        return CountryModule_shortCode(c);
    }
}

export function ErrorsHelpBlock(errors) {
    const props = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]);
    return createElement("p", createObj(Helpers_combineClasses("help", props)));
}

export function Render(company) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(company), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    let error;
    const matchValue = state_1.SaveFormOperation;
    let pattern_matching_result, x;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            x = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            error = x;
            break;
        }
        case 1: {
            error = "";
            break;
        }
    }
    const elms_53 = toList(delay(() => {
        let props_2, props;
        return append(singleton_1((props_2 = singleton_2(["children", Interop_reactApi.Children.toArray([(props = singleton_2(["children", t("users.editCompany")]), createElement("h3", createObj(Helpers_combineClasses("title is-3", props))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_2))))), delay(() => append((error !== "") ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", error]]))))) : empty_1(), delay(() => {
            let props_33, elms_3, props_10, props_6, props_8, props_30, props_20, props_28, elms_2, props_25, props_23, props_146, elms_20, props_39, props_35, props_37, elms_19, props_236, elms_32, props_152, props_148, props_150, elms_31, props_162, props_188, props_174, props_172, props_186, props_184, props_222, props_203, props_201, props_220, children_29, props_232, props_296, elms_44, props_242, props_238, props_240, elms_43, props_268, props_254, props_252, props_266, props_264, props_292, elms_39, props_278, elms_42, props_289, props_316, elms_47, props_302, props_298, props_300, elms_46, props_312, props_310, elms_45, props_307, props_305, props_346, elms_51, props_322, props_318, props_320, elms_50, props_332, props_330, elms_48, props_327, props_325, props_342, props_340, elms_49, props_337, props_335;
            return append(singleton_1(createElement("div", {
                children: Interop_reactApi.Children.toArray([(props_33 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_10 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.basicInformation")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_6)))), (props_8 = ofArray([["style", {
                    fontSize: 0.7 + "rem",
                }], ["className", "has-text-grey-light"], ["children", t("users.basicInformationHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_30 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(props_20 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_1, props_14, props_12, elms, props_16;
                    const errors = Form_getFieldError(state_1.FormErrors, "Email");
                    return singleton_1((elms_1 = ofArray([(props_14 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.email")]]), createElement("label", createObj(Helpers_combineClasses("label", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_14)))), (elms = singleton_2((props_16 = toList(delay(() => append((!equals(errors, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev) => {
                        dispatch(new Msg(4, EmailModule_ofString(ev.target.value)));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterEmail")]), delay(() => {
                        let value_46;
                        return singleton_1((value_46 = EmailModule_toString(state_1.Form.Email), ["ref", (e) => {
                            if ((!(e == null)) ? (!equals(e.value, value_46)) : false) {
                                e.value = value_46;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_16)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    })), ErrorsHelpBlock(errors)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_20)))), (props_28 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_25 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                    className: join(" ", ["is-checkradio"]),
                    id: "NonExistentEmail",
                    type: "checkbox",
                    name: "NonExistentEmail",
                    onChange: (ev_1) => {
                        dispatch(new Msg(5, ev_1.target.checked));
                    },
                    checked: state_1.Form.NonExistentEmail,
                }), (props_23 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.nonExistentEmail")], ["htmlFor", "NonExistentEmail"]]), createElement("label", createObj(Helpers_combineClasses("label", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_25))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_28))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_30))))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_33)))), (props_146 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_20 = ofArray([(props_39 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_35 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.companyInfo")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_35)))), (props_37 = ofArray([["style", {
                    fontSize: 0.7 + "rem",
                }], ["className", "has-text-grey-light"], ["children", t("users.compannyInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_37))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_39)))), (elms_19 = toList(delay(() => {
                    let props_49;
                    return append(singleton_1((props_49 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let elms_5, props_43, props_41, elms_4, props_45;
                        const errors_1 = Form_getFieldError(state_1.FormErrors, "CompanyName");
                        return singleton_1((elms_5 = ofArray([(props_43 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_41 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.companyName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_41))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_43)))), (elms_4 = singleton_2((props_45 = toList(delay(() => append((!equals(errors_1, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_2) => {
                            dispatch(new Msg(1, ev_2.target.value));
                        }]), delay(() => append(singleton_1(["placeholder", t("users.enterCompanyName")]), delay(() => {
                            let value_125;
                            return singleton_1((value_125 = state_1.Form.CompanyInfo.Name, ["ref", (e_1) => {
                                if ((!(e_1 == null)) ? (!equals(e_1.value, value_125)) : false) {
                                    e_1.value = value_125;
                                }
                            }]));
                        })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_45)))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                        })), ErrorsHelpBlock(errors_1)]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                        })));
                    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_49))))), delay(() => {
                        let props_75, props_61, props_59, props_73, props_71;
                        return append(singleton_1((props_75 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_61 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_59 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let elms_7, props_53, props_51, elms_6, props_55;
                            const errors_2 = Form_getFieldError(state_1.FormErrors, "OrganizationNumber");
                            return singleton_1((elms_7 = ofArray([(props_53 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_51 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.orgNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_51))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_53)))), (elms_6 = singleton_2((props_55 = toList(delay(() => append((!equals(errors_2, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_3) => {
                                dispatch(new Msg(2, ev_3.target.value));
                            }]), delay(() => append(singleton_1(["placeholder", t("users.enterOrgNumber")]), delay(() => {
                                let value_153;
                                return singleton_1((value_153 = state_1.Form.CompanyInfo.OrganizationNumber, ["ref", (e_2) => {
                                    if ((!(e_2 == null)) ? (!equals(e_2.value, value_153)) : false) {
                                        e_2.value = value_153;
                                    }
                                }]));
                            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_55)))))), createElement("div", {
                                className: "field-body",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                            })), ErrorsHelpBlock(errors_2)]), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                            })));
                        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_59))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_61)))), (props_73 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_71 = toList(delay(() => {
                            let elms_9, props_65, props_63, elms_8, props_67;
                            const errors_3 = Form_getFieldError(state_1.FormErrors, "VatNumber");
                            return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_9 = ofArray([(props_65 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_63 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.vatNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_63))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_65)))), (elms_8 = singleton_2((props_67 = toList(delay(() => append((!equals(errors_3, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_4) => {
                                dispatch(new Msg(3, ev_4.target.value));
                            }]), delay(() => append(singleton_1(["placeholder", t("users.enterVatNumber")]), delay(() => {
                                let value_182;
                                return singleton_1((value_182 = state_1.Form.CompanyInfo.VatNumber, ["ref", (e_3) => {
                                    if ((!(e_3 == null)) ? (!equals(e_3.value, value_182)) : false) {
                                        e_3.value = value_182;
                                    }
                                }]));
                            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_67)))))), createElement("div", {
                                className: "field-body",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                            })), ErrorsHelpBlock(errors_3)]), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                            }))])]);
                        })), createElement("div", createObj(Helpers_combineClasses("field", props_71))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_73))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_75))))), delay(() => {
                            let props_101, props_87, props_85, props_99, props_97;
                            return append(singleton_1((props_101 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_87 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_85 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let elms_11, props_79, props_77, elms_10, props_81;
                                const errors_4 = Form_getFieldError(state_1.FormErrors, "Address");
                                return singleton_1((elms_11 = ofArray([(props_79 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_77 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props_77))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_79)))), (elms_10 = singleton_2((props_81 = toList(delay(() => append((!equals(errors_4, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_5) => {
                                    dispatch(new Msg(10, ev_5.target.value));
                                }]), delay(() => append(singleton_1(["placeholder", t("users.enterAddress")]), delay(() => {
                                    let value_212;
                                    return singleton_1((value_212 = state_1.Form.Address.Address, ["ref", (e_4) => {
                                        if ((!(e_4 == null)) ? (!equals(e_4.value, value_212)) : false) {
                                            e_4.value = value_212;
                                        }
                                    }]));
                                })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_81)))))), createElement("div", {
                                    className: "field-body",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                                })), ErrorsHelpBlock(errors_4)]), createElement("div", {
                                    className: "control",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                                })));
                            }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_85))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_87)))), (props_99 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_97 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let elms_13, props_91, props_89, elms_12, props_93;
                                const errors_5 = Form_getFieldError(state_1.FormErrors, "ZipCode");
                                return singleton_1((elms_13 = ofArray([(props_91 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_89 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.zipCode")]]), createElement("label", createObj(Helpers_combineClasses("label", props_89))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_91)))), (elms_12 = singleton_2((props_93 = toList(delay(() => append((!equals(errors_5, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_6) => {
                                    dispatch(new Msg(9, ev_6.target.value));
                                }]), delay(() => append(singleton_1(["placeholder", t("users.enterZipCode")]), delay(() => {
                                    let value_241;
                                    return singleton_1((value_241 = state_1.Form.Address.ZipCode, ["ref", (e_5) => {
                                        if ((!(e_5 == null)) ? (!equals(e_5.value, value_241)) : false) {
                                            e_5.value = value_241;
                                        }
                                    }]));
                                })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_93)))))), createElement("div", {
                                    className: "field-body",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                                })), ErrorsHelpBlock(errors_5)]), createElement("div", {
                                    className: "control",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
                                })));
                            }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_97))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_99))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_101))))), delay(() => {
                                let props_130, props_113, props_111, props_128, props_126;
                                return append(singleton_1((props_130 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_113 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_111 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                    let elms_15, props_105, props_103, elms_14, props_107;
                                    const errors_6 = Form_getFieldError(state_1.FormErrors, "City");
                                    return singleton_1((elms_15 = ofArray([(props_105 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_103 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.city")]]), createElement("label", createObj(Helpers_combineClasses("label", props_103))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_105)))), (elms_14 = singleton_2((props_107 = toList(delay(() => append((!equals(errors_6, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_7) => {
                                        dispatch(new Msg(11, ev_7.target.value));
                                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterCity")]), delay(() => {
                                        let value_271;
                                        return singleton_1((value_271 = state_1.Form.Address.City, ["ref", (e_6) => {
                                            if ((!(e_6 == null)) ? (!equals(e_6.value, value_271)) : false) {
                                                e_6.value = value_271;
                                            }
                                        }]));
                                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_107)))))), createElement("div", {
                                        className: "field-body",
                                        children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
                                    })), ErrorsHelpBlock(errors_6)]), createElement("div", {
                                        className: "control",
                                        children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
                                    })));
                                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_111))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_113)))), (props_128 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_126 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                    let elms_17, props_117, props_115, elms_16, patternInput_1;
                                    const errors_7 = Form_getFieldError(state_1.FormErrors, "Country");
                                    return singleton_1((elms_17 = ofArray([(props_117 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_115 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.country")]]), createElement("label", createObj(Helpers_combineClasses("label", props_115))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_117)))), (elms_16 = singleton_2((patternInput_1 = Helpers_extractClasses(toList(delay(() => append((!equals(errors_7, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(singleton_1(["className", "has-text-left"]), delay(() => append(singleton_1(["className", "mb-1"]), delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["onChange", (ev_8) => {
                                        dispatch(new Msg(12, ev_8.target.value));
                                    }]), delay(() => append(singleton_1(["value", state_1.Form.Address.Country]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => createElement("option", {
                                        value: tupledArg[0],
                                        children: tupledArg[1],
                                    }), CountriesInput_getSortedList())))])))))))))))))))))), createElement("div", {
                                        className: join(" ", cons("select", patternInput_1[0])),
                                        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
                                    }))), createElement("div", {
                                        className: "field-body",
                                        children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
                                    })), ErrorsHelpBlock(errors_7)]), createElement("div", {
                                        className: "control",
                                        children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
                                    })));
                                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_126))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_128))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_130))))), delay(() => {
                                    let props_142, props_140, props_138, elms_18, props_135, props_133;
                                    return isCurrentUserAdmin() ? singleton_1((props_142 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_140 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_138 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_18 = singleton_2((props_135 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                                        className: join(" ", ["is-checkradio"]),
                                        disabled: equals(state_1.SaveFormOperation, new Deferred$1(1)),
                                        id: "VATCheckbox",
                                        type: "checkbox",
                                        name: "VATCheckbox",
                                        onChange: (ev_9) => {
                                            dispatch(new Msg(13, ev_9.target.checked));
                                        },
                                        checked: state_1.Form.CompanyInfo.IsVATDisabled,
                                    }), (props_133 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.isVATDisabled")], ["htmlFor", "VATCheckbox"]]), createElement("label", createObj(Helpers_combineClasses("label", props_133))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_135))))), createElement("div", {
                                        className: "control",
                                        children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
                                    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_138))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_140))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_142))))) : empty_1();
                                }));
                            }));
                        }));
                    }));
                })), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
                }))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_146)))), (props_236 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_32 = ofArray([(props_152 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_148 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.bankInfo")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_148)))), (props_150 = ofArray([["style", {
                    fontSize: 0.7 + "rem",
                }], ["className", "has-text-grey-light"], ["children", t("users.bankInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_150))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_152)))), (elms_31 = ofArray([(props_162 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_22, props_156, props_154, elms_21, props_158;
                    const errors_8 = Form_getFieldError(state_1.FormErrors, "ClearingNumber");
                    return singleton_1((elms_22 = ofArray([(props_156 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_154 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.clearingNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_154))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_156)))), (elms_21 = singleton_2((props_158 = toList(delay(() => append((!equals(errors_8, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_10) => {
                        dispatch(new Msg(14, ev_10.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterClearingNumber")]), delay(() => {
                        let value_397;
                        return singleton_1((value_397 = state_1.Form.BankInfo.ClearingNumber, ["ref", (e_7) => {
                            if ((!(e_7 == null)) ? (!equals(e_7.value, value_397)) : false) {
                                e_7.value = value_397;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_158)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
                    })), ErrorsHelpBlock(errors_8)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_162)))), (props_188 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_174 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_172 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_24, props_166, props_164, elms_23, props_168;
                    const errors_9 = Form_getFieldError(state_1.FormErrors, "BankNumber");
                    return singleton_1((elms_24 = ofArray([(props_166 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_164 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.accountNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_164))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_166)))), (elms_23 = singleton_2((props_168 = toList(delay(() => append((!equals(errors_9, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_11) => {
                        dispatch(new Msg(15, ev_11.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterAccountNumber")]), delay(() => {
                        let value_425;
                        return singleton_1((value_425 = state_1.Form.BankInfo.BankNumber, ["ref", (e_8) => {
                            if ((!(e_8 == null)) ? (!equals(e_8.value, value_425)) : false) {
                                e_8.value = value_425;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_168)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
                    })), ErrorsHelpBlock(errors_9)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_24)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_172))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_174)))), (props_186 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_184 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_26, props_178, props_176, elms_25, props_180;
                    const errors_10 = Form_getFieldError(state_1.FormErrors, "Bank");
                    return singleton_1((elms_26 = ofArray([(props_178 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_176 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.bank")]]), createElement("label", createObj(Helpers_combineClasses("label", props_176))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_178)))), (elms_25 = singleton_2((props_180 = toList(delay(() => append((!equals(errors_10, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_12) => {
                        dispatch(new Msg(16, ev_12.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterBank")]), delay(() => {
                        let value_454;
                        return singleton_1((value_454 = state_1.Form.BankInfo.Bank, ["ref", (e_9) => {
                            if ((!(e_9 == null)) ? (!equals(e_9.value, value_454)) : false) {
                                e_9.value = value_454;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_180)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_25)),
                    })), ErrorsHelpBlock(errors_10)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_26)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_184))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_186))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_188)))), (props_222 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_203 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_201 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_28, props_192, props_190, elms_27, patternInput_2;
                    const errors_11 = Form_getFieldError(state_1.FormErrors, "IbanCountry");
                    return singleton_1((elms_28 = ofArray([(props_192 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_190 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.ibanCountry")]]), createElement("label", createObj(Helpers_combineClasses("label", props_190))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_192)))), (elms_27 = singleton_2((patternInput_2 = Helpers_extractClasses(toList(delay(() => append((!equals(errors_11, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(singleton_1(["className", "has-text-left"]), delay(() => append(singleton_1(["className", "mb-1"]), delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["onChange", (ev_13) => {
                        dispatch(new Msg(17, ev_13.target.value));
                    }]), delay(() => append(singleton_1(["value", state_1.Form.BankInfo.IbanCountry]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg_1) => createElement("option", {
                        value: tupledArg_1[0],
                        children: tupledArg_1[1],
                    }), append_1(singleton_2([CountryCodeModule_empty, "Select country"]), CountriesInput_getSortedList()))))])))))))))))))))))), createElement("div", {
                        className: join(" ", cons("select", patternInput_2[0])),
                        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_2[1]))]),
                    }))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_27)),
                    })), ErrorsHelpBlock(errors_11)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_28)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_201))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_203)))), (props_220 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(children_29 = toList(delay(() => {
                    let props_207, props_205;
                    const errors_12 = Form_getFieldError(state_1.FormErrors, "IBAN");
                    return append(singleton_1((props_207 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_205 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.IBAN")]]), createElement("label", createObj(Helpers_combineClasses("label", props_205))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_207))))), delay(() => {
                        let props_217, props_211, props_209, props_215, props_213;
                        return append(singleton_1((props_217 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_211 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_209 = ofArray([["className", "is-static"], ["children", CountryCodeToString(state_1.Form.BankInfo.IbanCountry)]]), createElement("a", createObj(Helpers_combineClasses("button", props_209))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_211)))), (props_215 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_213 = toList(delay(() => append((!equals(errors_12, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_14) => {
                            dispatch(new Msg(18, ev_14.target.value));
                        }]), delay(() => append(singleton_1(["placeholder", t("users.enterIBAN")]), delay(() => {
                            let value_532;
                            return singleton_1((value_532 = state_1.Form.BankInfo.Iban, ["ref", (e_10) => {
                                if ((!(e_10 == null)) ? (!equals(e_10.value, value_532)) : false) {
                                    e_10.value = value_532;
                                }
                            }]));
                        })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_213)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_215))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_217))))), delay(() => singleton_1(ErrorsHelpBlock(errors_12))));
                    }));
                })), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_29)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_220))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_222)))), (props_232 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_30, props_226, props_224, elms_29, props_228;
                    const errors_13 = Form_getFieldError(state_1.FormErrors, "Comment");
                    return singleton_1((elms_30 = ofArray([(props_226 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_224 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.comment")]]), createElement("label", createObj(Helpers_combineClasses("label", props_224))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_226)))), (elms_29 = singleton_2((props_228 = toList(delay(() => append((!equals(errors_13, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_15) => {
                        dispatch(new Msg(19, ev_15.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterComment")]), delay(() => {
                        let value_555;
                        return singleton_1((value_555 = state_1.Form.BankInfo.Comment, ["ref", (e_11) => {
                            if ((!(e_11 == null)) ? (!equals(e_11.value, value_555)) : false) {
                                e_11.value = value_555;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_228)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_29)),
                    })), ErrorsHelpBlock(errors_13)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_30)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_232))))]), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_31)),
                }))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_32)),
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_236)))), (props_296 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_44 = ofArray([(props_242 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_238 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.contactPerson")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_238)))), (props_240 = ofArray([["style", {
                    fontSize: 0.7 + "rem",
                }], ["className", "has-text-grey-light"], ["children", t("users.contactPersonHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_240))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_242)))), (elms_43 = ofArray([(props_268 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_254 = ofArray([["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_252 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_34, props_246, props_244, elms_33, props_248;
                    const errors_14 = Form_getFieldError(state_1.FormErrors, "FirstName");
                    return singleton_1((elms_34 = ofArray([(props_246 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_244 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.firstName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_244))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_246)))), (elms_33 = singleton_2((props_248 = toList(delay(() => append((!equals(errors_14, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_16) => {
                        dispatch(new Msg(20, ev_16.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterFirstName")]), delay(() => {
                        let value_612;
                        return singleton_1((value_612 = state_1.Form.ContactPerson.FirstName, ["ref", (e_12) => {
                            if ((!(e_12 == null)) ? (!equals(e_12.value, value_612)) : false) {
                                e_12.value = value_612;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_248)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_33)),
                    })), ErrorsHelpBlock(errors_14)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_34)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_252))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_254)))), (props_266 = ofArray([["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_264 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_36, props_258, props_256, elms_35, props_260;
                    const errors_15 = Form_getFieldError(state_1.FormErrors, "LastName");
                    return singleton_1((elms_36 = ofArray([(props_258 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_256 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.lastName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_256))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_258)))), (elms_35 = singleton_2((props_260 = toList(delay(() => append((!equals(errors_15, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_17) => {
                        dispatch(new Msg(21, ev_17.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterLastName")]), delay(() => {
                        let value_641;
                        return singleton_1((value_641 = state_1.Form.ContactPerson.LastName, ["ref", (e_13) => {
                            if ((!(e_13 == null)) ? (!equals(e_13.value, value_641)) : false) {
                                e_13.value = value_641;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_260)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_35)),
                    })), ErrorsHelpBlock(errors_15)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_36)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_264))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_266))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_268)))), (props_292 = ofArray([["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(elms_39 = singleton_2((props_278 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_38, props_272, props_270, elms_37, props_274;
                    const errors_16 = Form_getFieldError(state_1.FormErrors, "Phone");
                    return singleton_1((elms_38 = ofArray([(props_272 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_270 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.phoneNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_270))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_272)))), (elms_37 = singleton_2((props_274 = toList(delay(() => append((!equals(errors_16, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_18) => {
                        dispatch(new Msg(22, PhoneModule_ofString(ev_18.target.value)));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterPhoneNumber")]), delay(() => {
                        let value_672;
                        return singleton_1((value_672 = PhoneModule_toString(state_1.Form.ContactPerson.Phone), ["ref", (e_14) => {
                            if ((!(e_14 == null)) ? (!equals(e_14.value, value_672)) : false) {
                                e_14.value = value_672;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_274)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_37)),
                    })), ErrorsHelpBlock(errors_16)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_38)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_278))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_39)),
                })), (elms_42 = singleton_2((props_289 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_41, props_283, props_281, elms_40, props_285;
                    const errors_17 = Form_getFieldError(state_1.FormErrors, "PersonalEmail");
                    return singleton_1((elms_41 = ofArray([(props_283 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_281 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.email")]]), createElement("label", createObj(Helpers_combineClasses("label", props_281))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_283)))), (elms_40 = singleton_2((props_285 = toList(delay(() => append((!equals(errors_17, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_19) => {
                        dispatch(new Msg(23, EmailModule_ofString(ev_19.target.value)));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterEmail")]), delay(() => {
                        let value_702;
                        return singleton_1((value_702 = EmailModule_toString(state_1.Form.ContactPerson.Email), ["ref", (e_15) => {
                            if ((!(e_15 == null)) ? (!equals(e_15.value, value_702)) : false) {
                                e_15.value = value_702;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_285)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_40)),
                    })), ErrorsHelpBlock(errors_17)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_41)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_289))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_42)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_292))))]), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_43)),
                }))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_44)),
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_296)))), (props_316 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_47 = ofArray([(props_302 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_298 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.userSettings")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_298)))), (props_300 = ofArray([["style", {
                    fontSize: 0.7 + "rem",
                }], ["className", "has-text-grey-light"], ["children", t("users.userSettingsHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_300))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_302)))), (elms_46 = singleton_2((props_312 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_310 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_45 = singleton_2((props_307 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                    className: join(" ", ["is-checkradio"]),
                    id: "AvoidWarehouseFee",
                    type: "checkbox",
                    name: "AvoidWarehouseFee",
                    onChange: (ev_20) => {
                        dispatch(new Msg(8, ev_20.target.checked));
                    },
                    checked: state_1.Form.NeedAvoidWarehouseFee,
                }), (props_305 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.avoidWarehouseFee")], ["htmlFor", "AvoidWarehouseFee"]]), createElement("label", createObj(Helpers_combineClasses("label", props_305))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_307))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_45)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_310))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_312))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_46)),
                }))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_47)),
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_316)))), (props_346 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_51 = ofArray([(props_322 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_318 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.notifications")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_318)))), (props_320 = ofArray([["style", {
                    fontSize: 0.7 + "rem",
                }], ["className", "has-text-grey-light"], ["children", t("users.notificationsHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_320))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_322)))), (elms_50 = ofArray([(props_332 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_330 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_48 = singleton_2((props_327 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                    className: join(" ", ["is-checkradio"]),
                    id: "NeedNotifyOutbid",
                    type: "checkbox",
                    name: "NeedNotifyOutbid",
                    onChange: (ev_21) => {
                        dispatch(new Msg(6, ev_21.target.checked));
                    },
                    checked: state_1.Form.NeedNotifyAboutOverbid,
                }), (props_325 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.whenOutbided")], ["htmlFor", "NeedNotifyOutbid"]]), createElement("label", createObj(Helpers_combineClasses("label", props_325))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_327))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_48)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_330))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_332)))), (props_342 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_340 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_49 = singleton_2((props_337 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                    className: join(" ", ["is-checkradio"]),
                    id: "NeedNotifyNewsletter",
                    type: "checkbox",
                    name: "NeedNotifyNewsletter",
                    onChange: (ev_22) => {
                        dispatch(new Msg(7, ev_22.target.checked));
                    },
                    checked: state_1.Form.NeedNotifyNewsletter,
                }), (props_335 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.newsletter")], ["htmlFor", "NeedNotifyNewsletter"]]), createElement("label", createObj(Helpers_combineClasses("label", props_335))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_337))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_49)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_340))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_342))))]), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_50)),
                }))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_51)),
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_346))))]),
            })), delay(() => {
                let props_360, props_358, elms_52, props_355, props_351, props_353;
                return singleton_1((props_360 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(props_358 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-one-quarter"])))), (elms_52 = singleton_2((props_355 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_351 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["href", toPath(Route_AccountDetailsSubmissions_7639071A(company.UserId))]]), createElement("a", createObj(Helpers_combineClasses("button", props_351)))), (props_353 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))]), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (_arg1_1) => {
                    dispatch(new Msg(24, new AsyncOperationStatus$1(0)));
                }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_353))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_355))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_52)),
                }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_358))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_360)))));
            }));
        }))));
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_53)),
    });
}

