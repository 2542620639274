import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { InvoiceDraftIdModule_toInt, OrderIdModule_fromString, OrderIdModule_toString, InvoiceDraftIdModule_fromInt, CurrencyModule_zero, OrderIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Orders_CreateTransaction_Request, Orders_PaymentType } from "../Shared/Shared.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { ordersApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map, singleton as singleton_1, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { parse } from "../fable_modules/fable-library.3.6.1/Int32.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { empty, append, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export function Component() {
    let elms_10, elms_9, props_2, props_34, elms_1, s_2, elms, props_5, value_24, elms_3, elms_2, props_10, value_43, elms_5, elms_4, props_15, value_62, elms_7, elms_6, patternInput_5, value_82, props_27, props_32, elms_8, props_29;
    const patternInput = useFeliz_React__React_useState_Static_1505(OrderIdModule_empty);
    const orderId = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(CurrencyModule_zero);
    const cost = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(InvoiceDraftIdModule_fromInt(1));
    const draftInvoiceId = patternInput_2[0] | 0;
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(new Orders_PaymentType(1));
    const paymentMethod = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_4[0];
    const compensate_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => {
        const request = new Orders_CreateTransaction_Request(orderId, draftInvoiceId, cost, paymentMethod);
        return singleton.ReturnFrom(Remoting_handleNonAuth(ordersApi().createTransaction(request)));
    }), patternInput_4[1]);
    const fromMethod = (m_1) => {
        switch (m_1.tag) {
            case 0: {
                return "Swish";
            }
            case 1: {
                return "Kortbetalning";
            }
            case 2: {
                return "Kontant";
            }
            case 3: {
                return "Bankgiro";
            }
            case 12: {
                return "Invoice";
            }
            default: {
                throw (new Error(`Unexpected value of payment method ${m_1}`));
            }
        }
    };
    const elms_11 = singleton_1((elms_10 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Create transaction",
    }), (elms_9 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Create transaction",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_34 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(s_2 = (`Order id = ${orderId}`), createElement("label", {
        className: "label",
        children: s_2,
    })), (elms = singleton_1((props_5 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_24 = OrderIdModule_toString(orderId), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_24)) : false) {
            e.value = value_24;
        }
    }]), ["onChange", (ev) => {
        patternInput[1](OrderIdModule_fromString(ev.target.value));
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_5)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = ofArray([createElement("label", {
        className: "label",
        children: "Antal",
    }), (elms_2 = singleton_1((props_10 = ofArray([["required", true], (value_43 = cost, ["ref", (e_1) => {
        if ((!(e_1 == null)) ? (!equals(e_1.value, value_43)) : false) {
            e_1.value = value_43;
        }
    }]), ["onChange", (ev_1) => {
        patternInput_1[1](ev_1.target.value);
    }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_10)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_5 = ofArray([createElement("label", {
        className: "label",
        children: "Draft invoice id",
    }), (elms_4 = singleton_1((props_15 = ofArray([["required", true], (value_62 = (InvoiceDraftIdModule_toInt(draftInvoiceId) | 0), ["ref", (e_2) => {
        if ((!(e_2 == null)) ? (!equals(e_2.value, value_62)) : false) {
            e_2.value = (value_62 | 0);
        }
    }]), ["onChange", (ev_2) => {
        patternInput_2[1](InvoiceDraftIdModule_fromInt(parse(ev_2.target.value, 511, false, 32)));
    }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_15)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })), (elms_7 = ofArray([createElement("label", {
        className: "label",
        children: "Payment method",
    }), (elms_6 = singleton_1((patternInput_5 = Helpers_extractClasses(ofArray([["required", true], (value_82 = fromMethod(paymentMethod), ["ref", (e_3) => {
        if ((!(e_3 == null)) ? (!equals(e_3.value, value_82)) : false) {
            e_3.value = value_82;
        }
    }]), ["onChange", (ev_3) => {
        let m;
        patternInput_3[1]((m = ev_3.target.value, (m === "Swish") ? (new Orders_PaymentType(0)) : ((m === "Kortbetalning") ? (new Orders_PaymentType(1)) : ((m === "Kontant") ? (new Orders_PaymentType(2)) : ((m === "Bankgiro") ? (new Orders_PaymentType(3)) : ((m === "Invoice") ? (new Orders_PaymentType(12)) : (() => {
            throw (new Error(`Unexpected value of payment method ${m}`));
        })()))))));
    }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x_6) => createElement("option", {
        children: fromMethod(x_6),
    }), ofArray([new Orders_PaymentType(1), new Orders_PaymentType(0), new Orders_PaymentType(2), new Orders_PaymentType(3), new Orders_PaymentType(12)]))))]])), createElement("div", {
        className: join(" ", cons("select", patternInput_5[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_5[1]))]),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    })), (props_27 = toList(delay(() => ((data.tag === 1) ? singleton_2(["children", ""]) : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? append(singleton_2(["className", "is-danger"]), delay(() => singleton_2(["children", data.fields[0].fields[0]]))) : append(singleton_2(["className", "is-success"]), delay(() => singleton_2(["children", "Successfully created transaction."])))) : ((data.tag === 3) ? append(singleton_2(["className", "is-danger"]), delay(() => singleton_2(["children", data.fields[0].message]))) : singleton_2(["children", ""])))))), createElement("p", createObj(Helpers_combineClasses("help", props_27)))), (props_32 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_8 = singleton_1((props_29 = toList(delay(() => append(singleton_2(["className", "is-danger"]), delay(() => append(Deferred_inProgress(data) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["children", "Submit"]), delay(() => singleton_2(["onClick", (x_8) => {
        x_8.preventDefault();
        compensate_1();
    }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_29))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_32))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_34))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
    });
}

