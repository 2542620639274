import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { developerApi } from "../Communication.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_1 } from "../fable_modules/fable-library.3.6.1/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Developer_CopyDomain } from "../Shared/Shared.js";

export function Component() {
    let elms_3, elms_2, props_9, elms_1, elms, props_3, props_5;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const operation = patternInput[0];
    const startCopying_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D((x) => singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(developerApi().copyTranslationsFromSwedenToFinnish(x)), () => singleton.Return())), patternInput[1]);
    const elms_4 = singleton_1((elms_3 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Copy Item \u0026 Lots content translations from Swedish to Finnish",
    }), (elms_2 = ofArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-4"])))), (props_9 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = ofArray([(props_3 = toList(delay(() => append(Deferred_inProgress(operation) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["children", "Start copy lots"]), delay(() => singleton_2(["onClick", (_arg1_1) => {
        startCopying_1(new Developer_CopyDomain(0));
    }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_3)))), (props_5 = toList(delay(() => append(Deferred_inProgress(operation) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["children", "Start copying items"]), delay(() => singleton_2(["onClick", (_arg2) => {
        startCopying_1(new Developer_CopyDomain(1));
    }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_5))))]), createElement("div", {
        className: "buttons",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    });
}

