import { NumericFormat } from "react-number-format";
import { Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { class_type, record_type, option_type, float64_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { defaultArg, map } from "../fable_modules/fable-library.3.6.1/Option.js";
import Decimal from "../fable_modules/fable-library.3.6.1/Decimal.js";

export const NumberFormatModule_numberFormat = NumericFormat;

export class NumberFormatValues extends Record {
    constructor(formattedValue, value, floatValue) {
        super();
        this.formattedValue = formattedValue;
        this.value = value;
        this.floatValue = floatValue;
    }
}

export function NumberFormatValues$reflection() {
    return record_type("Client.Components.Inputs.NumberFormatValues", [], NumberFormatValues, () => [["formattedValue", string_type], ["value", string_type], ["floatValue", option_type(float64_type)]]);
}

export function NumberFormatValues__get_decimalValue(this$) {
    return map((value) => (new Decimal(value)), this$.floatValue);
}

export function NumberFormatValues__decimalValueOrDefault_32C73145(this$, x) {
    return defaultArg(NumberFormatValues__get_decimalValue(this$), x);
}

export class NumberFormat {
    constructor() {
    }
}

export function NumberFormat$reflection() {
    return class_type("Client.Components.Inputs.NumberFormat", void 0, NumberFormat);
}

