import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { LotsForResellFilter, LotForResellInfo$reflection, AuctionStatistic$reflection, LotsForResellFilter$reflection, ResellLotStatus, ResellLotStatus$reflection } from "./Shared/Shared.js";
import { list_type, union_type, string_type, int32_type, class_type, record_type, bool_type, option_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { isEmpty, empty, cons, singleton as singleton_1, filter, map, contains, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingQuery$1$reflection } from "./Shared/Common.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { lotApi } from "./Communication.js";
import { int32ToString, createObj, safeHash, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Localized_translate, t, getLocalizationContext } from "./Localization.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { AuctionArg, AuctionResellLotState } from "./Router.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map as map_2, empty as empty_1, append, singleton as singleton_2, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { isCurrentUserAdmin as isCurrentUserAdmin_1 } from "./LocalStorage.js";
import { Image_large, Image_smallContains, Image_imagePlaceholder } from "./Css.js";
import { map as map_1, value as value_193 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { ReservedPriceModule_toInt, CurrencyModule_fromInt, OrderNumberModule_toString, ItemIdModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { Currency_formatNOption, Currency_formatN } from "./Common.js";
import { ActionType, CreateOrResellLotComponent } from "./Components/CreateLot.js";
import { RenderItemsHistory } from "./Components/SubmissionItems.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "./Loader.js";
import { renderPagination } from "./Components/Pagination.js";

export class ToggleStatus extends Record {
    constructor(Status, IsCheck) {
        super();
        this.Status = Status;
        this.IsCheck = IsCheck;
    }
}

export function ToggleStatus$reflection() {
    return record_type("Client.AuctionResellLots.ToggleStatus", [], ToggleStatus, () => [["Status", option_type(ResellLotStatus$reflection())], ["IsCheck", bool_type]]);
}

export const toggleButtonDeliveryStatus = ofArray([new ToggleStatus(void 0, true), new ToggleStatus(new ResellLotStatus(0), false), new ToggleStatus(new ResellLotStatus(1), false), new ToggleStatus(new ResellLotStatus(2), false)]);

export class State extends Record {
    constructor(AuctionId, QueryUnsoldItems, CurrentPageUnsoldItems, SearchString, Statistic, LotsForResell, ResellLotsStatus) {
        super();
        this.AuctionId = AuctionId;
        this.QueryUnsoldItems = QueryUnsoldItems;
        this.CurrentPageUnsoldItems = (CurrentPageUnsoldItems | 0);
        this.SearchString = SearchString;
        this.Statistic = Statistic;
        this.LotsForResell = LotsForResell;
        this.ResellLotsStatus = ResellLotsStatus;
    }
}

export function State$reflection() {
    return record_type("Client.AuctionResellLots.State", [], State, () => [["AuctionId", class_type("System.Int64")], ["QueryUnsoldItems", Common_PagingQuery$1$reflection(LotsForResellFilter$reflection())], ["CurrentPageUnsoldItems", int32_type], ["SearchString", string_type], ["Statistic", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionStatistic$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionStatistic$reflection()]], [["ErrorValue", string_type]]]))], ["LotsForResell", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [LotForResellInfo$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", LotForResellInfo$reflection()]], [["ErrorValue", string_type]]]))], ["ResellLotsStatus", list_type(ToggleStatus$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdatePageState", "PageChangedUnsoldItems", "OnColumnClickedUnsoldItems", "SearchStringChange", "LoadLotsForResell", "ChangeDeliveryStatus"];
    }
}

export function Msg$reflection() {
    return union_type("Client.AuctionResellLots.Msg", [], Msg, () => [[["Item", string_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item1", Common_PagingQuery$1$reflection(LotsForResellFilter$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [LotForResellInfo$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", LotForResellInfo$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", ToggleStatus$reflection()]]]);
}

export function loadLotsForResale(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotApi().getLotsForResale(query)), (_arg1) => singleton.Return(new Msg(4, query, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(4, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function getUnsold(state) {
    return contains(new ResellLotStatus(0), state.QueryUnsoldItems.Payload.ByStatus, {
        Equals: (x, y) => equals(x, y),
        GetHashCode: (x) => safeHash(x),
    });
}

export function getClaimed(state) {
    return contains(new ResellLotStatus(1), state.QueryUnsoldItems.Payload.ByStatus, {
        Equals: (x, y) => equals(x, y),
        GetHashCode: (x) => safeHash(x),
    });
}

export function getWithdrawn(state) {
    return contains(new ResellLotStatus(2), state.QueryUnsoldItems.Payload.ByStatus, {
        Equals: (x, y) => equals(x, y),
        GetHashCode: (x) => safeHash(x),
    });
}

export function init(auctionId, auctionState) {
    const unsoldFilter = auctionState.IsUnsoldFilterOn ? (new ToggleStatus(new ResellLotStatus(0), true)) : (new ToggleStatus(new ResellLotStatus(0), false));
    const claimFilter = auctionState.IsClaimedOrRefundedFilterOn ? (new ToggleStatus(new ResellLotStatus(1), true)) : (new ToggleStatus(new ResellLotStatus(1), false));
    const withdrawn = auctionState.IsWithDrawnFilterOn ? (new ToggleStatus(new ResellLotStatus(1), true)) : (new ToggleStatus(new ResellLotStatus(1), false));
    const lotsStatuses = (((!auctionState.IsUnsoldFilterOn) ? (!auctionState.IsWithDrawnFilterOn) : false) ? (!auctionState.IsClaimedOrRefundedFilterOn) : false) ? toggleButtonDeliveryStatus : ofArray([new ToggleStatus(void 0, false), unsoldFilter, claimFilter, withdrawn]);
    const query2 = new Common_PagingQuery$1(new LotsForResellFilter(map((x_1) => {
        const matchValue = x_1.Status;
        if (matchValue == null) {
            throw (new Error("Unexpected resell lot status: None"));
        }
        else {
            return matchValue;
        }
    }, filter((x) => x.IsCheck, ofArray([unsoldFilter, claimFilter, withdrawn])))), auctionState.Page, 20, void 0, getLocalizationContext());
    return [new State(auctionId, query2, auctionState.Page, "", new Deferred$1(0), new Deferred$1(0), lotsStatuses), Cmd_batch(singleton_1(Cmd_OfFunc_result(new Msg(4, query2, new AsyncOperationStatus$1(0)))))];
}

export function update(msg, state) {
    let s, matchValue_1, z;
    if (msg.tag === 1) {
        let newQuery;
        const inputRecord = state.QueryUnsoldItems;
        newQuery = (new Common_PagingQuery$1(inputRecord.Payload, msg.fields[0], inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(state.AuctionId, newQuery, msg.fields[0], state.SearchString, state.Statistic, state.LotsForResell, state.ResellLotsStatus), Cmd_OfFunc_result(new Msg(4, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 2) {
        let newQuery_1;
        const matchValue = state.QueryUnsoldItems.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_1 = state.QueryUnsoldItems;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, inputRecord_1.Page, inputRecord_1.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_1.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_2 = state.QueryUnsoldItems;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, inputRecord_2.Page, inputRecord_2.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_2.LocalizationContext));
                break;
            }
        }
        return [new State(state.AuctionId, newQuery_1, state.CurrentPageUnsoldItems, state.SearchString, state.Statistic, state.LotsForResell, state.ResellLotsStatus), Cmd_OfFunc_result(new Msg(4, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 3) {
        return [new State(state.AuctionId, state.QueryUnsoldItems, state.CurrentPageUnsoldItems, msg.fields[0], state.Statistic, state.LotsForResell, state.ResellLotsStatus), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[1].tag === 1) {
            if (!equals(msg.fields[0], state.QueryUnsoldItems)) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.AuctionId, state.QueryUnsoldItems, state.CurrentPageUnsoldItems, state.SearchString, state.Statistic, new Deferred$1(2, msg.fields[1].fields[0]), state.ResellLotsStatus), Cmd_none()];
            }
        }
        else {
            return [new State(state.AuctionId, state.QueryUnsoldItems, state.CurrentPageUnsoldItems, state.SearchString, state.Statistic, new Deferred$1(1), state.ResellLotsStatus), Cmd_fromAsync(loadLotsForResale(msg.fields[0]))];
        }
    }
    else if (msg.tag === 5) {
        let newQuery_2;
        const query = state.QueryUnsoldItems;
        newQuery_2 = (new Common_PagingQuery$1((matchValue_1 = msg.fields[0].Status, (matchValue_1 != null) ? ((z = matchValue_1, msg.fields[0].IsCheck ? (new LotsForResellFilter(filter((x_2) => (!equals(x_2, z)), state.QueryUnsoldItems.Payload.ByStatus))) : (new LotsForResellFilter(cons(z, state.QueryUnsoldItems.Payload.ByStatus))))) : (new LotsForResellFilter(empty()))), 1, query.PageSize, query.SortBy, query.LocalizationContext));
        if (msg.fields[0].Status != null) {
            return [new State(state.AuctionId, newQuery_2, 1, state.SearchString, state.Statistic, state.LotsForResell, map((x_3) => {
                if (equals(x_3.Status, msg.fields[0].Status)) {
                    return new ToggleStatus(msg.fields[0].Status, !msg.fields[0].IsCheck);
                }
                else if (x_3.Status == null) {
                    return new ToggleStatus(void 0, false);
                }
                else {
                    return x_3;
                }
            }, state.ResellLotsStatus)), Cmd_fromAsync(loadLotsForResale(newQuery_2))];
        }
        else {
            return [new State(state.AuctionId, newQuery_2, 1, state.SearchString, state.Statistic, state.LotsForResell, toggleButtonDeliveryStatus), Cmd_fromAsync(loadLotsForResale(newQuery_2))];
        }
    }
    else {
        window.open(msg.fields[0]);
        return init(state.AuctionId, new AuctionResellLotState(getUnsold(state), getClaimed(state), getWithdrawn(state), state.CurrentPageUnsoldItems));
    }
}

export function tableHeader(state, dispatch) {
    let props_21;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(2, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.QueryUnsoldItems.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_2(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_2(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    const props_23 = ofArray([["className", "my-4"], ["className", "px-0"], ["className", "mx-0"], ["children", Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "px-0"], ["className", "mx-0"], ["className", "my-0"], ["className", "is-gapless"], ["className", "is-vcentered"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([createElement("td", {
        style: {
            width: 8 + "%",
        },
    }), createElement("td", {
        style: {
            width: 5 + "%",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.itemId"), "itemId")]),
    }), createElement("td", {
        style: {
            width: 5 + "%",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.lotNumber"), "id")]),
    }), createElement("td", {
        style: {
            width: 5 + "%",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.priority"), "priority")]),
    }), createElement("td", {
        style: {
            width: 27 + "%",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.lotTitle"), "title")]),
    }), createElement("td", {
        style: {
            width: 5 + "%",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.category"), "category")]),
    }), createElement("td", {
        style: {
            width: 5 + "%",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.bidders"), "bidders")]),
    }), createElement("td", {
        style: {
            width: 5 + "%",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.bids"), "bids")]),
    }), createElement("td", {
        style: {
            width: 7 + "%",
            textAlign: "right",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.currentBidColumn"), "currentBid")]),
    }), createElement("td", {
        style: {
            width: 7 + "%",
            textAlign: "right",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.estimatedPriceColumn"), "estimated")]),
    }), createElement("td", {
        style: {
            width: 7 + "%",
            textAlign: "right",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.reservedPriceColumn"), "reservedPrice")]),
    }), createElement("td", {
        style: {
            width: 10 + "%",
            textAlign: "right",
        },
        children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.status"), "status")]),
    }), createElement("td", {
        style: {
            width: 6 + "%",
        },
    })])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_21))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_23)));
}

export function TableLineResellLot(tableLineResellLotInputProps) {
    let matchValue;
    const updateAuctionPageStateAndOpenNewTab = tableLineResellLotInputProps.updateAuctionPageStateAndOpenNewTab;
    const auctionPageFilterState = tableLineResellLotInputProps.auctionPageFilterState;
    const lot = tableLineResellLotInputProps.lot;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowImage = patternInput_1[1];
    const isShowImage = patternInput_1[0];
    const isCurrentUserAdmin = isCurrentUserAdmin_1();
    return createElement("div", {
        style: {
            marginBottom: 10,
            borderRadius: 0,
            backgroundColor: "#FFFFFF",
            borderLeft: (((4 + "px ") + "solid") + " ") + ((matchValue = lot.LotStatus, (matchValue.tag === 1) ? "#808080" : ((matchValue.tag === 2) ? "#808080" : ((matchValue.tag === 3) ? "#19bb9c" : ((matchValue.tag === 4) ? "#f17288" : ((matchValue.tag === 5) ? "#000000" : ((matchValue.tag === 6) ? "#f17288" : ((matchValue.tag === 7) ? "#EEB24B" : ((matchValue.tag === 8) ? "#EEB24B" : ((matchValue.tag === 9) ? "#000000" : ((matchValue.tag === 10) ? "#000000" : ((matchValue.tag === 11) ? "#000000" : "#808080")))))))))))),
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let matchValue_1;
            return append(singleton_2(createElement("tr", {
                className: "auctionTable",
                children: Interop_reactApi.Children.toArray([createElement("td", createObj(toList(delay(() => append((lot.Image != null) ? singleton_2(["onClick", (_arg1) => {
                    setShowImage(!isShowImage);
                }]) : empty_1(), delay(() => append(singleton_2(["style", createObj(toList(delay(() => append(singleton_2(["width", 7 + "%"]), delay(() => append(singleton_2(["minWidth", 10]), delay(() => ((lot.Image != null) ? singleton_2(["cursor", "zoom-in"]) : empty_1()))))))))]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("img", createObj(toList(delay(() => append((lot.Image != null) ? singleton_2(["onClick", (_arg2) => {
                    setShowImage(!isShowImage);
                }]) : empty_1(), delay(() => append(singleton_2(["style", {
                    display: "flex",
                }]), delay(() => singleton_2(["src", (lot.Image == null) ? Image_imagePlaceholder : Image_smallContains(value_193(lot.Image))])))))))))])]))))))))), createElement("td", {
                    onClick: (_arg3) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    className: "has-text-left",
                    children: ItemIdModule_toString(lot.ItemId),
                }), createElement("td", {
                    onClick: (_arg4) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    className: "has-text-left",
                    children: OrderNumberModule_toString(lot.OrderNumber),
                }), createElement("td", {
                    onClick: (_arg5) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    className: "has-text-left",
                    children: (matchValue_1 = (lot.Priority | 0), (matchValue_1 === 1) ? "2" : ((matchValue_1 === 2) ? "1" : "3")),
                }), createElement("td", {
                    onClick: (_arg6) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 27 + "%",
                        maxWidth: 20,
                    },
                    children: Localized_translate(lot.Title),
                    className: "is-text-overflow-hidden",
                }), createElement("td", {
                    onClick: (_arg7) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 7 + "%",
                        maxWidth: 50,
                        paddingLeft: 0,
                    },
                    children: Localized_translate(lot.Category.Name),
                    className: "is-text-overflow-hidden",
                }), createElement("td", {
                    onClick: (_arg8) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    children: lot.Bidders,
                }), createElement("td", {
                    onClick: (_arg9) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                    },
                    children: lot.BidsCount,
                }), createElement("td", {
                    onClick: (_arg10) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 7 + "%",
                        textAlign: "right",
                    },
                    children: Currency_formatN(CurrencyModule_fromInt(lot.CurrentBid)),
                }), createElement("td", {
                    onClick: (_arg11) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 5 + "%",
                        textAlign: "right",
                    },
                    children: Currency_formatN(CurrencyModule_fromInt(lot.Estimate)),
                }), createElement("td", {
                    onClick: (_arg12) => {
                        setVisible(!isVisible);
                    },
                    style: {
                        width: 7 + "%",
                        textAlign: "right",
                    },
                    children: Currency_formatNOption("-", map_1((arg) => CurrencyModule_fromInt(ReservedPriceModule_toInt(arg)), lot.ReservedPrice)),
                }), createElement("td", createObj(toList(delay(() => append(singleton_2(["onClick", (_arg13) => {
                    setVisible(!isVisible);
                }]), delay(() => append(singleton_2(["style", {
                    width: 10 + "%",
                    textAlign: "right",
                }]), delay(() => {
                    const matchValue_2 = lot.LotStatus;
                    switch (matchValue_2.tag) {
                        case 1: {
                            return append(singleton_2(["children", t("auction.lotStatus.biddingStarted")]), delay(() => singleton_2(["className", "has-text-grey"])));
                        }
                        case 2: {
                            return append(singleton_2(["children", t("auction.lotStatus.activeBidding")]), delay(() => singleton_2(["className", "has-text-grey"])));
                        }
                        case 3: {
                            return append(singleton_2(["children", t("auction.lotStatus.biddingFinishedSold")]), delay(() => singleton_2(["className", "has-text-primary"])));
                        }
                        case 4: {
                            return append(singleton_2(["children", t("auction.lotStatus.biddingFinishedUnsold")]), delay(() => singleton_2(["className", "has-text-danger"])));
                        }
                        case 5: {
                            return append(singleton_2(["children", t("auction.lotStatus.deleted")]), delay(() => singleton_2(["className", "has-text-black"])));
                        }
                        case 6: {
                            return append(singleton_2(["children", t("auction.lotStatus.withdrawn")]), delay(() => singleton_2(["className", "has-text-danger"])));
                        }
                        case 7: {
                            return append(singleton_2(["children", t("auction.lotStatus.claimed")]), delay(() => singleton_2(["className", "has-text-warning"])));
                        }
                        case 8: {
                            return append(singleton_2(["children", t("auction.lotStatus.refunded")]), delay(() => singleton_2(["className", "has-text-warning"])));
                        }
                        case 9: {
                            return append(singleton_2(["children", t("auction.lotStatus.recycled")]), delay(() => singleton_2(["className", "has-text-black"])));
                        }
                        case 10: {
                            return append(singleton_2(["children", t("auction.lotStatus.returned")]), delay(() => singleton_2(["className", "has-text-black"])));
                        }
                        case 11: {
                            return append(singleton_2(["children", t("auction.lotStatus.returnedToAuctionHouse")]), delay(() => singleton_2(["className", "has-text-black"])));
                        }
                        default: {
                            return append(singleton_2(["children", t("auction.lotStatus.created")]), delay(() => singleton_2(["className", "has-text-grey"])));
                        }
                    }
                })))))))), createElement("td", {
                    style: {
                        width: 5 + "%",
                    },
                    className: "has-text-right",
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        const disabledRule = (lot.ItemReservedForAuction != null) ? true : (lot.DoneStatus != null);
                        return singleton_2(createElement(CreateOrResellLotComponent, {
                            itemId: lot.ItemId,
                            disabled: disabledRule,
                            auctionPageAndFilterState: auctionPageFilterState,
                            updateAuctionStateAndOpenNewTab: updateAuctionPageStateAndOpenNewTab,
                            actionType: new ActionType(1, lot.Id, lot.Category),
                        }));
                    })))),
                })]),
            })), delay(() => append((isVisible ? isCurrentUserAdmin : false) ? singleton_2(createElement("div", {
                style: {
                    margin: 20,
                },
                children: Interop_reactApi.Children.toArray([createElement(RenderItemsHistory, {
                    itemId: lot.ItemId,
                    doneStatus: lot.DoneStatus,
                })]),
            })) : empty_1(), delay(() => {
                let props_21;
                return singleton_2((props_21 = toList(delay(() => append(isShowImage ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => {
                    let elms_1, elms;
                    return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg14) => {
                        setShowImage(!isShowImage);
                    }])))), (elms_1 = singleton_1((elms = singleton_1(createElement("img", createObj(toList(delay(() => singleton_2(["src", (lot.Image == null) ? Image_imagePlaceholder : Image_large(value_193(lot.Image))])))))), createElement("div", {
                        className: "card-image",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))), createElement("div", {
                        className: "modal-content",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    }))])]);
                })))), createElement("div", createObj(Helpers_combineClasses("modal", props_21)))));
            }))));
        })))),
    });
}

export function ButtonFilterStatus(buttonFilterStatusInputProps) {
    const unitVar3 = buttonFilterStatusInputProps.unitVar3;
    const dispatch = buttonFilterStatusInputProps.dispatch;
    const lotsForResell = buttonFilterStatusInputProps.lotsForResell;
    const status = buttonFilterStatusInputProps.status;
    const patternInput = (lotsForResell.tag === 1) ? ["", "", ""] : ((lotsForResell.tag === 2) ? ((lotsForResell.fields[0].tag === 0) ? [int32ToString(lotsForResell.fields[0].fields[0].Statistic.BiddingFinishedUnsold), int32ToString(lotsForResell.fields[0].fields[0].Statistic.ClaimedOrRefunded), int32ToString(lotsForResell.fields[0].fields[0].Statistic.Withdrawn)] : ["", "", ""]) : ["", "", ""]);
    const props = toList(delay(() => append(singleton_2(["className", "mr-0"]), delay(() => append(singleton_2(["className", "ml-2"]), delay(() => append((!status.IsCheck) ? append(singleton_2(["className", "is-primary"]), delay(() => singleton_2(["className", "is-light"]))) : singleton_2(["className", "is-primary"]), delay(() => {
        let matchValue, s;
        return append((matchValue = status.Status, (matchValue != null) ? ((s = matchValue, (s.tag === 2) ? singleton_2(["children", ((t("auction.lotStatus.withdrawn") + " ( ") + patternInput[2]) + " )"]) : ((s.tag === 1) ? singleton_2(["children", ((t("auction.lotStatus.claimedOrRefunded") + " ( ") + patternInput[1]) + " )"]) : singleton_2(["children", ((t("auction.lotStatus.biddingFinishedUnsold") + " ( ") + patternInput[0]) + " )"])))) : singleton_2(["children", t("common.all")])), delay(() => singleton_2(["onClick", (_arg1) => {
            dispatch(new Msg(5, status));
        }])));
    }))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export function Render(renderInputProps) {
    const auctionId = renderInputProps.auctionId;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(auctionId, renderInputProps.auctionState), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_6 = ofArray([["className", "pt-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props;
        return append(singleton_2((props = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_2((status) => createElement(ButtonFilterStatus, {
            status: status,
            lotsForResell: state_1.LotsForResell,
            dispatch: dispatch,
        }), state_1.ResellLotsStatus)))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props))))), delay(() => append(singleton_2(tableHeader(state_1, dispatch)), delay(() => {
            let props_3;
            const matchValue = state_1.LotsForResell;
            let pattern_matching_result, ex, lots;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 2;
                    lots = matchValue.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    ex = matchValue.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(loader("small"));
                }
                case 1: {
                    return singleton_2(createElement("h1", {
                        children: [ex],
                    }));
                }
                case 2: {
                    return append(isEmpty(lots.LotsForResell.Result) ? singleton_2((props_3 = ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", t("auction.noLots")]]), createElement("p", createObj(Helpers_combineClasses("", props_3))))) : empty_1(), delay(() => {
                        let elms;
                        return append(singleton_2((elms = toList(delay(() => map_2((lot) => createElement(TableLineResellLot, {
                            lot: lot,
                            auctionPageFilterState: new AuctionArg(1, auctionId, new AuctionResellLotState(getUnsold(state_1), getClaimed(state_1), getWithdrawn(state_1), state_1.CurrentPageUnsoldItems)),
                            updateAuctionPageStateAndOpenNewTab: (url) => {
                                dispatch(new Msg(0, url));
                            },
                        }), lots.LotsForResell.Result))), createElement("div", {
                            className: "container",
                            children: Interop_reactApi.Children.toArray(Array.from(elms)),
                        }))), delay(() => singleton_2(renderPagination(lots.LotsForResell.Page, lots.LotsForResell.Total, lots.LotsForResell.PageSize, (arg) => {
                            dispatch(new Msg(1, arg));
                        }))));
                    }));
                }
            }
        }))));
    }))))]]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_6)));
}

