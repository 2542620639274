import { SubmissionIdModule_fromString, SubmissionIdModule_toString, AuctionIdModule_fromString, AuctionIdModule_toString, SubmissionIdModule_empty, AuctionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Submissions_ResetCommissionInfo } from "../Shared/Shared.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { cons, ofArray, singleton as singleton_1 } from "../fable_modules/fable-library.3.6.1/List.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { compare } from "../fable_modules/fable-library.3.6.1/Long.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { submissionsApi } from "../Communication.js";

export const empty = new Submissions_ResetCommissionInfo(AuctionIdModule_empty, SubmissionIdModule_empty, SubmissionIdModule_empty);

export function modalWindow(isVisible, state, resetStatus, resetCommissions, onClick) {
    const props_21 = toList(delay(() => append(isVisible ? singleton(["className", "is-active"]) : empty_1(), delay(() => append(singleton(["style", {
        overflow: "visible",
    }]), delay(() => {
        let props_19, props_8, elms, props_6, elms_1, props_10, props_17;
        return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg1) => {
            onClick();
        }])))), (props_19 = ofArray([["className", "has-background-light"], ["style", {
            borderRadius: 4,
        }], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "mx-5"], ["className", "mt-5"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("h4", {
            className: "title is-4",
            children: "Reset commissions",
        })), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_6 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg2) => {
            onClick();
        }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_8)))), (elms_1 = singleton_1((props_10 = ofArray([["className", "mx-5"], ["children", `Are you sure you want to reset commissions in lots in auction ${state.AuctionId} and in submissions from ${state.StartSubmissionId} to ${state.EndSubmissionId}?`]]), createElement("span", createObj(Helpers_combineClasses("", props_10))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (props_17 = ofArray([["className", "has-text-right"], ["className", "mx-5"], ["className", "mb-5"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const isDisabled = equals(resetStatus, new Deferred$1(1));
            return append(singleton(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "mr-3"], ["className", "is-dark"], ["disabled", isDisabled], ["children", "Cancel"], ["onClick", (_arg3) => {
                onClick();
            }]]))))), delay(() => {
                let props_15;
                return singleton((props_15 = toList(delay(() => append(singleton(["className", "is-primary"]), delay(() => (equals(resetStatus, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : append(singleton(["children", "Reset"]), delay(() => singleton(["onClick", (_arg4) => {
                    onClick();
                    resetCommissions();
                }])))))))), createElement("button", createObj(Helpers_combineClasses("button", props_15)))));
            }));
        }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_19))))])]);
    }))))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_21)));
}

export function Component() {
    let elms_9, elms_8, props_2, props_31;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty);
    const state = patternInput[0];
    const setState = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput_1[1];
    const isVisible = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_2[0];
    const resetCommissions = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton_2.Delay(() => ((compare(state.StartSubmissionId, state.EndSubmissionId) > 0) ? singleton_2.Return(new FSharpResult$2(1, "StartSubmissionId should be less than end submission id")) : singleton_2.ReturnFrom(Remoting_handleNonAuth(submissionsApi().resetCommissions(state))))), patternInput_2[1]);
    const elms_10 = singleton_1((elms_9 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Reset commissions in Lots and Submissions",
    }), (elms_8 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Reset buyer commission, hammer fee in lots and seller commission (% and min), photo and hammer fee in submissions.",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_31 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, s_2, elms, props_5, value_24;
        return append(singleton((elms_1 = ofArray([(s_2 = (`Reset in Lots in Auction id = ${state.AuctionId}`), createElement("label", {
            className: "label",
            children: s_2,
        })), (elms = singleton_1((props_5 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_24 = AuctionIdModule_toString(state.AuctionId), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_24)) : false) {
                e.value = value_24;
            }
        }]), ["onChange", (ev) => {
            setState(new Submissions_ResetCommissionInfo(AuctionIdModule_fromString(ev.target.value), state.StartSubmissionId, state.EndSubmissionId));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_5)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let elms_6, s_3, elms_5, elms_4, elms_2, props_10, value_45, elms_3, props_13, value_58;
            return append(singleton((elms_6 = ofArray([(s_3 = (`Reset in Submissions in range = [${state.StartSubmissionId}; ${state.EndSubmissionId}]`), createElement("label", {
                className: "label",
                children: s_3,
            })), (elms_5 = singleton_1((elms_4 = ofArray([(elms_2 = singleton_1((props_10 = ofArray([["required", true], ["placeholder", "From submission id"], (value_45 = SubmissionIdModule_toString(state.StartSubmissionId), ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals(e_1.value, value_45)) : false) {
                    e_1.value = value_45;
                }
            }]), ["onChange", (ev_1) => {
                setState(new Submissions_ResetCommissionInfo(state.AuctionId, SubmissionIdModule_fromString(ev_1.target.value), state.EndSubmissionId));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_10)))))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            })), (elms_3 = singleton_1((props_13 = ofArray([["required", true], ["placeholder", "To submission id"], (value_58 = SubmissionIdModule_toString(state.EndSubmissionId), ["ref", (e_2) => {
                if ((!(e_2 == null)) ? (!equals(e_2.value, value_58)) : false) {
                    e_2.value = value_58;
                }
            }]), ["onChange", (ev_2) => {
                setState(new Submissions_ResetCommissionInfo(state.AuctionId, state.StartSubmissionId, SubmissionIdModule_fromString(ev_2.target.value)));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_13)))))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))]), createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            }))]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            }))), delay(() => {
                let props_20, props_24;
                return append((data.tag === 1) ? singleton(null) : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? singleton(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", data.fields[0].fields[0]]]))))) : singleton((props_20 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", {
                    children: ["Done"],
                })])]), createElement("div", createObj(Helpers_combineClasses("notification", props_20)))))) : ((data.tag === 3) ? singleton((props_24 = ofArray([["className", "is-danger"], ["children", data.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_24))))) : singleton(null))), delay(() => {
                    let props_29, elms_7, props_26;
                    return singleton((props_29 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_7 = singleton_1((props_26 = toList(delay(() => append(singleton(["className", "is-danger"]), delay(() => append(Deferred_inProgress(data) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append(singleton(["children", "Reset commissions"]), delay(() => singleton(["onClick", (_arg1) => {
                        setVisible(true);
                    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_26))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_29)))));
                }));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_31))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    })), modalWindow(isVisible, state, data, () => {
        resetCommissions();
    }, () => {
        setVisible(!isVisible);
    })]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    });
}

