import { Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, string_type, int32_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { CategoryIdModule_fromString, CategoryIdModule_toString, AuctionIdModule_fromString, AuctionIdModule_toString, CategoryIdModule_empty, AuctionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { LocalizedStringModule_setTranslation, LangModule_toString, LocalizedStringModule_empty } from "../bidflow/src/infrastructure/Localization.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Localized_translateTo, getSupportedLanguages, getPrimaryLanguage } from "../Localization.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { ChangeCategoryNameRequest } from "../Shared/Shared.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { auctionApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton as singleton_2, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { stringHash, equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, map, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { List_distinct } from "../fable_modules/fable-library.3.6.1/Seq2.js";

export class State extends Record {
    constructor(AuctionId, CategoryId, Name) {
        super();
        this.AuctionId = AuctionId;
        this.CategoryId = (CategoryId | 0);
        this.Name = Name;
    }
}

export function State$reflection() {
    return record_type("Components.AuctionCatalog.View.State", [], State, () => [["AuctionId", class_type("System.Int64")], ["CategoryId", int32_type], ["Name", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])]]);
}

export const StateModule_empty = new State(AuctionIdModule_empty, CategoryIdModule_empty, LocalizedStringModule_empty);

export function Component() {
    let elms_7, elms_6, props_2, props_33;
    const patternInput = useFeliz_React__React_useState_Static_1505(StateModule_empty);
    const state = patternInput[0];
    const setState = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(getPrimaryLanguage());
    const lang = patternInput_2[0];
    const changeNames = useFeliz_React__React_useDeferredCallback_Static_7088D81D((x) => singleton.Delay(() => {
        const request = new ChangeCategoryNameRequest(x.AuctionId, x.CategoryId, x.Name);
        return singleton.ReturnFrom(Remoting_handleNonAuth(auctionApi().changeCategoryName(request)));
    }), patternInput_1[1]);
    const elms_8 = singleton_2((elms_7 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Change category name",
    }), (elms_6 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Change category name of the auction",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_33 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, s_2, elms, props_5, value_24;
        return append(singleton_1((elms_1 = ofArray([(s_2 = (`Auction id = ${state.AuctionId}`), createElement("label", {
            className: "label",
            children: s_2,
        })), (elms = singleton_2((props_5 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_24 = AuctionIdModule_toString(state.AuctionId), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_24)) : false) {
                e.value = value_24;
            }
        }]), ["onChange", (ev) => {
            setState(new State(AuctionIdModule_fromString(ev.target.value), state.CategoryId, state.Name));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_5)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let elms_3, s_3, elms_2, props_10, value_45;
            return append(singleton_1((elms_3 = ofArray([(s_3 = (`Category id = ${state.CategoryId}`), createElement("label", {
                className: "label",
                children: s_3,
            })), (elms_2 = singleton_2((props_10 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_45 = CategoryIdModule_toString(state.CategoryId), ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals(e_1.value, value_45)) : false) {
                    e_1.value = value_45;
                }
            }]), ["onChange", (ev_1) => {
                setState(new State(state.AuctionId, CategoryIdModule_fromString(ev_1.target.value), state.Name));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_10)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))), delay(() => {
                let props_18, children_4;
                return append(singleton_1((props_18 = ofArray([["className", "is-uppercase"], ["className", "is-toggle"], ["children", Interop_reactApi.Children.toArray([(children_4 = toList(delay(() => map((lng) => {
                    const props_15 = toList(delay(() => append((lng === lang) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                        className: "is-small-padding",
                        onClick: (_arg1) => {
                            patternInput_2[1](lng);
                        },
                        children: LangModule_toString(lng),
                    })])])))));
                    return createElement("li", createObj(Helpers_combineClasses("", props_15)));
                }, List_distinct(cons(getPrimaryLanguage(), getSupportedLanguages()), {
                    Equals: (x_5, y) => (x_5 === y),
                    GetHashCode: (x_5) => stringHash(x_5),
                })))), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_18))))), delay(() => {
                    let elms_4, props_20, value_70;
                    return append(singleton_1((elms_4 = singleton_2((props_20 = ofArray([(value_70 = Localized_translateTo(lang, state.Name), ["ref", (e_2) => {
                        if ((!(e_2 == null)) ? (!equals(e_2.value, value_70)) : false) {
                            e_2.value = value_70;
                        }
                    }]), ["onChange", (ev_2) => {
                        setState(new State(state.AuctionId, state.CategoryId, LocalizedStringModule_setTranslation(lang, ev_2.target.value, state.Name)));
                    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_20)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                    }))), delay(() => {
                        let props_24, props_26;
                        return append((data.tag === 1) ? singleton_1(null) : ((data.tag === 2) ? singleton_1((props_24 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", {
                            children: ["Successfully changed the names."],
                        })])]), createElement("div", createObj(Helpers_combineClasses("notification", props_24))))) : ((data.tag === 3) ? singleton_1((props_26 = ofArray([["className", "is-danger"], ["children", data.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_26))))) : singleton_1(null))), delay(() => {
                            let props_31, elms_5, props_28;
                            return singleton_1((props_31 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_2((props_28 = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(data) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Change the names"]), delay(() => singleton_1(["onClick", (_arg2) => {
                                changeNames(state);
                            }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_28))))), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_31)))));
                        }));
                    }));
                }));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_33))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    });
}

