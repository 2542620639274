import { getToken, setItem, tryGetItem } from "./LocalStorage.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505, useReact_useEffect_Z101E1A95, useReact_useEffect_Z5234A374, React_createDisposable_3A5B6456, useReact_useEffectOnce_Z5ECA432F, useReact_useMemo_CF4EA67, useReact_useRef_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { HubConnectionBuilder$5_$ctor_Z66CB2AA1, HubConnectionBuilder$5__withUrl_Z6B680FF3, HubConnectionBuilder$5__withAutomaticReconnect, HubConnectionBuilder$5__configureLogging_2D37BB17 } from "./fable_modules/Fable.SignalR.0.11.4/SignalR.fs.js";
import { ConnectionBuilder__accessTokenFactory_5106B011 } from "./fable_modules/Fable.SignalR.0.11.4/HttpClient.fs.js";
import { HubConnection$5__stopNow, HubConnection$5__startNow, HubConnection$5_$ctor_3ED56BCC, Bindings_signalR } from "./fable_modules/Fable.SignalR.0.11.4/HubConnection.fs.js";
import { Json_TextMessageFormat_write, Json_TextMessageFormat_parse, HubRecords_CloseMessage$reflection, HubRecords_PingMessage$reflection, HubRecords_CancelInvocationMessage$reflection, HubRecords_StreamInvocationMessage$1$reflection, HubRecords_CompletionMessage$1$reflection, HubRecords_StreamItemMessage$1$reflection, HubRecords_InvocationMessage$1$reflection, Json_JsonProtocol_$ctor, MsgPack_parseMsg, MsgPack_MsgPackProtocol_$ctor } from "./fable_modules/Fable.SignalR.0.11.4/Protocols.fs.js";
import { join, toFail, printf, toText } from "./fable_modules/fable-library.3.6.1/String.js";
import { fold, choose as choose_1, map as map_2, partition, tryFindIndex, append as append_1, ofArray, sortBy, singleton, reverse, cons, empty } from "./fable_modules/fable-library.3.6.1/List.js";
import { Reader__Read_24524716, Reader_$ctor_6C95DA22 } from "./fable_modules/Fable.Remoting.MsgPack.1.9.0/Read.fs.js";
import { fromInteger, op_Subtraction, compare, fromBits, op_Addition } from "./fable_modules/fable-library.3.6.1/Long.js";
import { union_type, bool_type, option_type, record_type, string_type, list_type, enum_type, int32_type, unit_type, class_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { StreamFrom_Action, Action$reflection, StreamFrom_Action$reflection, StreamFrom_Response$reflection, Response$reflection } from "./Shared/Streams.js";
import { InvokeArg$1$reflection, MsgPack_Msg$4, MsgPack_Msg$4$reflection } from "./fable_modules/Fable.SignalR.0.11.4/Shared.fs.js";
import { writeObject } from "./fable_modules/Fable.Remoting.MsgPack.1.9.0/Write.fs.js";
import { choose, addRangeInPlace } from "./fable_modules/fable-library.3.6.1/Array.js";
import { createObj, comparePrimitives, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { SimpleJson_readPath, SimpleJson_parse } from "./fable_modules/Fable.SimpleJson.3.20.0/SimpleJson.fs.js";
import { defaultArg, toArray, some, map, value as value_82 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B, Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.20.0/Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.20.0/TypeInfo.Converter.fs.js";
import { Result_Map, FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { empty as empty_1, singleton as singleton_2, append, map as map_1, delay, toList, iterate } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { StreamSubscriber$1 } from "./fable_modules/Fable.SignalR.0.11.4/Types.fs.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { traverseAsyncResultA } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/List.fs.js";
import { mapError } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/AsyncResult.fs.js";
import { t, tWithArgs } from "./Localization.js";
import { AsyncResultCE_asyncResult, AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/AsyncResultCE.fs.js";
import { Browser_Types_File__File_ReadAsByteArray } from "./fable_modules/Fable.Remoting.Client.7.10.0/Extensions.fs.js";
import { Remoting_handleNonAuth } from "./Extensions.js";
import { imagesApi } from "./Communication.js";
import { ImageInFolder$reflection, Image$reflection, Images_UploadImageIntoFolderCommitInfo } from "./Shared/Shared.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { rangeDouble } from "./fable_modules/fable-library.3.6.1/Range.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { add, empty as empty_2 } from "./fable_modules/fable-library.3.6.1/Set.js";
import { Cmd_ofSub, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { List_swapToRight, List_swapToLeft } from "./bidflow/src/infrastructure/Extensions.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { RenderImage } from "./Components/Image.js";

export function ImportImages_LocalStorage_tryGetSelectedFolder() {
    return tryGetItem("import.image.selected.folder");
}

export function ImportImages_LocalStorage_saveSelectedFolder(folder) {
    setItem("import.image.selected.folder", folder);
}

export function useImportImages_importImages(onImageAdded, onFolderAdded, dependencies) {
    let hub_2;
    const connection_1 = useReact_useRef_1505(useReact_useMemo_CF4EA67(() => {
        let protocol, protocol_1;
        const _ = HubConnectionBuilder$5__configureLogging_2D37BB17(HubConnectionBuilder$5__withAutomaticReconnect(HubConnectionBuilder$5__withUrl_Z6B680FF3(HubConnectionBuilder$5_$ctor_Z66CB2AA1(new Bindings_signalR.HubConnectionBuilder()), "/SignalR", (builder) => ConnectionBuilder__accessTokenFactory_5106B011(builder, getToken))), 1);
        return HubConnection$5_$ctor_3ED56BCC(_["hub@10"].withHubProtocol(_.useMsgPack ? ((protocol = MsgPack_MsgPackProtocol_$ctor(), {
            name: "messagepack",
            version: 1,
            transferFormat: 2,
            parseMessages(input, logger) {
                return Array.from((() => {
                    let arg10_1;
                    try {
                        const buffer_1 = input;
                        const reader = Reader_$ctor_6C95DA22(new Uint8Array(buffer_1));
                        const read = (pos_mut, xs_mut) => {
                            read:
                            while (true) {
                                const pos = pos_mut, xs = xs_mut;
                                const matchValue = op_Addition(op_Addition(Reader__Read_24524716(reader, class_type("System.UInt64")), pos), fromBits(1, 0, true));
                                if (compare(op_Subtraction(fromInteger(buffer_1.byteLength, true, 2), matchValue), fromBits(0, 0, true)) > 0) {
                                    pos_mut = matchValue;
                                    xs_mut = cons(MsgPack_parseMsg(Reader__Read_24524716(reader, MsgPack_Msg$4$reflection(unit_type, Response$reflection(), Response$reflection(), StreamFrom_Response$reflection()))), xs);
                                    continue read;
                                }
                                else {
                                    return cons(MsgPack_parseMsg(Reader__Read_24524716(reader, MsgPack_Msg$4$reflection(unit_type, Response$reflection(), Response$reflection(), StreamFrom_Response$reflection()))), xs);
                                }
                                break;
                            }
                        };
                        return reverse(read(fromBits(0, 0, true), empty()));
                    }
                    catch (e) {
                        logger.log(4, (arg10_1 = e.message, toText(printf("An error occured during message deserialization: %s"))(arg10_1)));
                        return empty();
                    }
                })());
            },
            writeMessage(msg_2) {
                let matchValue_1, invocation, matchValue_2, invocation_1, arg10_3, streamItem, completion, streamInvocation, cancelInvocation, close;
                const message = msg_2;
                const outArr = [];
                writeObject((matchValue_1 = (message.type | 0), (matchValue_1 === 1) ? ((invocation = message, (matchValue_2 = invocation.target, (matchValue_2 === "Invoke") ? ((invocation.arguments.length === 2) ? (new MsgPack_Msg$4(1, invocation.headers, invocation.invocationId, invocation.target, invocation.arguments[0], invocation.arguments[1], invocation.streamIds)) : ((invocation_1 = message, new MsgPack_Msg$4(2, invocation_1.headers, invocation_1.invocationId, invocation_1.target, invocation_1.arguments, invocation_1.streamIds)))) : ((matchValue_2 === "Send") ? (new MsgPack_Msg$4(0, invocation.headers, invocation.invocationId, invocation.target, invocation.arguments, invocation.streamIds)) : ((matchValue_2 === "StreamTo") ? (new MsgPack_Msg$4(0, invocation.headers, invocation.invocationId, invocation.target, invocation.arguments, invocation.streamIds)) : ((arg10_3 = invocation.target, toFail(printf("Invalid Invocation Target: %s"))(arg10_3)))))))) : ((matchValue_1 === 2) ? ((streamItem = message, new MsgPack_Msg$4(3, streamItem.headers, streamItem.invocationId, streamItem.item))) : ((matchValue_1 === 3) ? ((completion = message, new MsgPack_Msg$4(4, completion.headers, completion.invocationId, completion.error, completion.result))) : ((matchValue_1 === 4) ? ((streamInvocation = message, new MsgPack_Msg$4(5, streamInvocation.headers, streamInvocation.invocationId, streamInvocation.target, streamInvocation.arguments, streamInvocation.streamIds))) : ((matchValue_1 === 5) ? ((cancelInvocation = message, new MsgPack_Msg$4(6, cancelInvocation.headers, cancelInvocation.invocationId))) : ((matchValue_1 === 6) ? (new MsgPack_Msg$4(7)) : ((matchValue_1 === 7) ? ((close = message, new MsgPack_Msg$4(8, close.error, close.allowReconnect))) : toFail(printf("Invalid message: %A"))(message)))))))), MsgPack_Msg$4$reflection(StreamFrom_Action$reflection(), Action$reflection(), unit_type, unit_type), outArr);
                if (compare(fromInteger(outArr.length, true, 2), fromBits(2147483648, 0, true)) > 0) {
                    throw (new Error("Messages over 2GB are not supported."));
                }
                else {
                    const msgArr = [];
                    writeObject(fromInteger(outArr.length, true, 2), class_type("System.UInt64"), msgArr);
                    addRangeInPlace(outArr, msgArr);
                    return (new Uint8Array(msgArr)).buffer;
                }
            },
        })) : ((protocol_1 = Json_JsonProtocol_$ctor(), {
            name: "json",
            version: 1,
            transferFormat: 1,
            parseMessages(input_1, logger_2) {
                const input_2 = input_1;
                const logger_3 = logger_2;
                return Array.from(((typeof input_2) === "string") ? (equals(input_2, "") ? [] : (() => {
                    let arg10_9;
                    try {
                        return choose((m) => {
                            let msg_4;
                            const parsedRaw = SimpleJson_parse(m);
                            let _arg2;
                            const parsedRaw_1 = parsedRaw;
                            const msgType_1 = value_82(map((arg00_4) => Convert_fromJson(arg00_4, createTypeInfo(enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]]))), SimpleJson_readPath(singleton("type"), parsedRaw))) | 0;
                            switch (msgType_1) {
                                case 1: {
                                    let _arg1;
                                    try {
                                        _arg1 = (new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_InvocationMessage$1$reflection(Response$reflection())))));
                                    }
                                    catch (ex) {
                                        _arg1 = (new FSharpResult$2(1, ex.message));
                                    }
                                    _arg2 = ((_arg1.tag === 1) ? Result_Map((arg_1) => {
                                        let msg_6;
                                        return (msg_6 = arg_1, ((msg_6.target === "") ? (() => {
                                            throw (new Error("Invalid payload for Invocation message."));
                                        })() : (void 0), ((msg_6.invocationId != null) ? ((value_82(msg_6.invocationId) === "") ? (() => {
                                            throw (new Error("Invalid payload for Invocation message."));
                                        })() : (void 0)) : (void 0), msg_6)));
                                    }, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_InvocationMessage$1$reflection(InvokeArg$1$reflection(Response$reflection())))));
                                        }
                                        catch (ex_1) {
                                            return new FSharpResult$2(1, ex_1.message);
                                        }
                                    })()) : (new FSharpResult$2(0, (msg_4 = _arg1.fields[0], ((msg_4.target === "") ? (() => {
                                        throw (new Error("Invalid payload for Invocation message."));
                                    })() : (void 0), ((msg_4.invocationId != null) ? ((value_82(msg_4.invocationId) === "") ? (() => {
                                        throw (new Error("Invalid payload for Invocation message."));
                                    })() : (void 0)) : (void 0), msg_4))))));
                                    break;
                                }
                                case 2: {
                                    _arg2 = Result_Map((arg_3) => {
                                        let msg_8, matchValue_3, invocationId_1;
                                        return (msg_8 = arg_3, (matchValue_3 = msg_8.invocationId, (matchValue_3 != null) ? ((matchValue_3 !== "") ? ((invocationId_1 = matchValue_3, msg_8)) : (() => {
                                            throw (new Error("Invalid payload for StreamItem message."));
                                        })()) : (() => {
                                            throw (new Error("Invalid payload for StreamItem message."));
                                        })()));
                                    }, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_StreamItemMessage$1$reflection(StreamFrom_Response$reflection()))));
                                        }
                                        catch (ex_2) {
                                            return new FSharpResult$2(1, ex_2.message);
                                        }
                                    })());
                                    break;
                                }
                                case 3: {
                                    _arg2 = Result_Map((arg_5) => {
                                        let msg_10, fail, matchValue_4, err;
                                        return (msg_10 = arg_5, (fail = (() => {
                                            throw (new Error("Invalid payload for Completion message."));
                                        }), ((matchValue_4 = [msg_10.result, msg_10.error], (matchValue_4[0] == null) ? ((matchValue_4[1] != null) ? ((err = matchValue_4[1], (err === "") ? fail() : (void 0))) : ((msg_10.invocationId === "") ? fail() : (void 0))) : ((matchValue_4[1] != null) ? fail() : ((msg_10.invocationId === "") ? fail() : (void 0)))), msg_10)));
                                    }, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_CompletionMessage$1$reflection(Response$reflection()))));
                                        }
                                        catch (ex_3) {
                                            return new FSharpResult$2(1, ex_3.message);
                                        }
                                    })());
                                    break;
                                }
                                case 4: {
                                    _arg2 = Result_Map((arg_6) => arg_6, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_StreamInvocationMessage$1$reflection(unit_type))));
                                        }
                                        catch (ex_4) {
                                            return new FSharpResult$2(1, ex_4.message);
                                        }
                                    })());
                                    break;
                                }
                                case 5: {
                                    _arg2 = Result_Map((arg_7) => arg_7, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_CancelInvocationMessage$reflection())));
                                        }
                                        catch (ex_5) {
                                            return new FSharpResult$2(1, ex_5.message);
                                        }
                                    })());
                                    break;
                                }
                                case 6: {
                                    _arg2 = Result_Map((arg_8) => arg_8, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_PingMessage$reflection())));
                                        }
                                        catch (ex_6) {
                                            return new FSharpResult$2(1, ex_6.message);
                                        }
                                    })());
                                    break;
                                }
                                case 7: {
                                    _arg2 = Result_Map((arg_9) => arg_9, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_CloseMessage$reflection())));
                                        }
                                        catch (ex_7) {
                                            return new FSharpResult$2(1, ex_7.message);
                                        }
                                    })());
                                    break;
                                }
                                default: {
                                    _arg2 = toFail(printf("Invalid message: %A"))(parsedRaw_1);
                                }
                            }
                            if (_arg2.tag === 1) {
                                logger_3.log(4, toText(printf("Unknown message type: %s"))(_arg2.fields[0]));
                                return void 0;
                            }
                            else {
                                return some(_arg2.fields[0]);
                            }
                        }, Json_TextMessageFormat_parse(input_2));
                    }
                    catch (e_2) {
                        logger_3.log(4, (arg10_9 = e_2.message, toText(printf("An error occured during message deserialization: %s"))(arg10_9)));
                        return [];
                    }
                })()) : ((logger_3.log(4, "Invalid input for JSON hub protocol. Expected a string, got an array buffer instead."), [])));
            },
            writeMessage(msg_12) {
                return Json_TextMessageFormat_write(Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(msg_12));
            },
        }))).build(), _.handlers);
    }, dependencies));
    useReact_useEffectOnce_Z5ECA432F(() => {
        let objectArg;
        HubConnection$5__startNow(connection_1.current);
        return React_createDisposable_3A5B6456((objectArg = connection_1.current, () => {
            HubConnection$5__stopNow(objectArg);
        }));
    });
    hub_2 = useReact_useRef_1505(connection_1.current);
    const subscription = useReact_useRef_1505(void 0);
    useReact_useEffect_Z5234A374(() => React_createDisposable_3A5B6456(() => {
        iterate((sub) => {
            sub.Dispose();
        }, toArray(subscription.current));
    }), dependencies);
    const subscriber = new StreamSubscriber$1((_arg1_1) => {
        if (_arg1_1.tag === 1) {
            onFolderAdded(_arg1_1.fields[0]);
        }
        else {
            onImageAdded(_arg1_1.fields[0]);
        }
    }, (err_1) => {
        console.error(some(err_1));
    }, () => {
        console.debug("Import images subscription is completed.");
    });
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => singleton_1.Bind(hub_2.current.streamFrom(new StreamFrom_Action(0)), (_arg2_1) => {
            let sub_1;
            subscription.current = ((sub_1 = (_arg2_1.subscribe(subscriber)), {
                Dispose() {
                    sub_1.dispose();
                },
            }));
            return singleton_1.Zero();
        })));
    }, dependencies);
}

export function UploadImage(uploadImageInputProps) {
    const setInProgress = uploadImageInputProps.setInProgress;
    const inProgress = uploadImageInputProps.inProgress;
    const folder = uploadImageInputProps.folder;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const error = patternInput[0];
    const uploadFileCallback = useReact_useCallback_93353E((arg_3) => {
        startImmediate(singleton_1.Delay(() => {
            patternInput[1]("");
            setInProgress(true);
            return singleton_1.Bind(traverseAsyncResultA((file) => mapError((x_16) => tWithArgs("errors.fromServer.uploadImagesWithError", {
                error: x_16,
            }), AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD(AsyncResultCE_asyncResult, () => {
                const asyncResult_2 = singleton_1.Bind(Browser_Types_File__File_ReadAsByteArray(file), (arg) => singleton_1.Return(new FSharpResult$2(0, arg)));
                return singleton_1.Delay(() => singleton_1.Bind(asyncResult_2, (_arg1_2) => {
                    let asyncResult_1;
                    const result_1 = _arg1_2;
                    return (result_1.tag === 1) ? singleton_1.Return(new FSharpResult$2(1, result_1.fields[0])) : singleton_1.ReturnFrom((asyncResult_1 = singleton_1.Bind(Remoting_handleNonAuth(imagesApi().uploadImage(result_1.fields[0])), (arg_1) => singleton_1.Return(new FSharpResult$2(0, arg_1))), singleton_1.Delay(() => singleton_1.Bind(asyncResult_1, (_arg1_1) => {
                        let args;
                        const result = _arg1_1;
                        return (result.tag === 1) ? singleton_1.Return(new FSharpResult$2(1, result.fields[0])) : singleton_1.ReturnFrom((args = (new Images_UploadImageIntoFolderCommitInfo(result.fields[0], folder, file.name)), singleton_1.Bind(Remoting_handleNonAuth(imagesApi().uploadImageCommitToFolder(args)), (arg_2) => singleton_1.Return(new FSharpResult$2(0, void 0)))));
                    }))));
                }));
            })), sortBy((x_17) => x_17.name, arg_3, {
                Compare: (x_18, y) => comparePrimitives(x_18, y),
            })), (_arg3) => {
                setInProgress(false);
                return singleton_1.Zero();
            });
        }));
    }, [folder]);
    const props_8 = ofArray([["className", "is-fullwidth"], ["className", "is-primary"], ["className", "has-text-centered"], ["disabled", inProgress], ["style", {
        borderStyle: "solid",
        minHeight: 70 + "px",
    }], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["multiple", true], ["tabIndex", -1], ["disabled", inProgress], ["onChange", (ev) => {
        const fileList = ev.target.files;
        if (!(fileList == null)) {
            uploadFileCallback(toList(delay(() => map_1((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1)))));
        }
    }]]))))), createElement("span", {
        className: join(" ", ["icon-text", "is-text-overflow-hidden"]),
        style: {
            alignItems: "center",
            padding: 5,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_3;
            return append(singleton_2((props_3 = singleton(["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (inProgress ? singleton_2(["className", join(" ", ["fas", "fa-spinner", "fa-spin"])]) : singleton_2(["className", join(" ", ["fa", "fa-plus"])]))))))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))), delay(() => {
                let value_34;
                return append(singleton_2((value_34 = (inProgress ? "" : t("common.newImage")), createElement("span", {
                    children: [value_34],
                }))), delay(() => ((error !== "") ? singleton_2(createElement("span", {
                    children: [error],
                })) : empty_1())));
            }));
        })))),
    })])]]);
    return createElement("span", createObj(Helpers_combineClasses("button", props_8)));
}

export class ImagesOnStage extends Record {
    constructor(Images, ImagesToDelete) {
        super();
        this.Images = Images;
        this.ImagesToDelete = ImagesToDelete;
    }
}

export function ImagesOnStage$reflection() {
    return record_type("Client.EditImages.ImagesOnStage", [], ImagesOnStage, () => [["Images", list_type(Image$reflection())], ["ImagesToDelete", list_type(string_type)]]);
}

export const ImagesOnStageModule_empty = new ImagesOnStage(empty(), empty());

export class State extends Record {
    constructor(Stage, OriginalImages, VideoLink, SelectedFolder, Folders, IsLoading) {
        super();
        this.Stage = Stage;
        this.OriginalImages = OriginalImages;
        this.VideoLink = VideoLink;
        this.SelectedFolder = SelectedFolder;
        this.Folders = Folders;
        this.IsLoading = IsLoading;
    }
}

export function State$reflection() {
    return record_type("Client.EditImages.State", [], State, () => [["Stage", ImagesOnStage$reflection()], ["OriginalImages", list_type(ImageInFolder$reflection())], ["VideoLink", option_type(string_type)], ["SelectedFolder", string_type], ["Folders", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])], ["IsLoading", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnImageAdded", "VideoLinkChanged", "OnFolderAdded", "FolderSelected", "MoveImageLeft", "MoveImageRight", "DeleteImage", "ChangeIsLoading"];
    }
}

export function Msg$reflection() {
    return union_type("Client.EditImages.Msg", [], Msg, () => [[["Item", ImageInFolder$reflection()]], [["Item", option_type(string_type)]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]]]);
}

export function init() {
    return [new State(ImagesOnStageModule_empty, empty(), void 0, defaultArg(ImportImages_LocalStorage_tryGetSelectedFolder(), ""), empty_2({
        Compare: (x, y) => comparePrimitives(x, y),
    }), false), Cmd_none()];
}

export class ExternalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnImagesChanged", "OnVideoLinkChanged"];
    }
}

export function ExternalMsg$reflection() {
    return union_type("Client.EditImages.ExternalMsg", [], ExternalMsg, () => [[["Item", ImagesOnStage$reflection()]], [["Item", option_type(string_type)]]]);
}

export function update(onChange, msg, state) {
    switch (msg.tag) {
        case 0: {
            const image_1 = msg.fields[0];
            const imagesInSelectedFolder = (image_1.Folder === state.SelectedFolder) ? singleton(image_1.Image) : empty();
            let newState_1;
            const OriginalImages = append_1(singleton(image_1), state.OriginalImages);
            newState_1 = (new State(new ImagesOnStage(append_1(state.Stage.Images, imagesInSelectedFolder), state.Stage.ImagesToDelete), OriginalImages, state.VideoLink, state.SelectedFolder, state.Folders, state.IsLoading));
            return [newState_1, Cmd_ofSub((_arg2) => {
                onChange(new ExternalMsg(0, newState_1.Stage));
            })];
        }
        case 1: {
            const l = msg.fields[0];
            return [new State(state.Stage, state.OriginalImages, l, state.SelectedFolder, state.Folders, state.IsLoading), Cmd_ofSub((_arg3) => {
                onChange(new ExternalMsg(1, l));
            })];
        }
        case 2: {
            return [new State(state.Stage, state.OriginalImages, state.VideoLink, state.SelectedFolder, add(msg.fields[0], state.Folders), state.IsLoading), Cmd_none()];
        }
        case 4: {
            const newState_2 = new State(new ImagesOnStage(defaultArg(map((x_2) => List_swapToLeft(x_2)(state.Stage.Images), tryFindIndex((x_1) => (x_1.Id === msg.fields[0]), state.Stage.Images)), state.Stage.Images), state.Stage.ImagesToDelete), state.OriginalImages, state.VideoLink, state.SelectedFolder, state.Folders, state.IsLoading);
            return [newState_2, Cmd_ofSub((_arg4) => {
                onChange(new ExternalMsg(0, newState_2.Stage));
            })];
        }
        case 5: {
            const newState_3 = new State(new ImagesOnStage(defaultArg(map((x_4) => List_swapToRight(x_4)(state.Stage.Images), tryFindIndex((x_3) => (x_3.Id === msg.fields[0]), state.Stage.Images)), state.Stage.Images), state.Stage.ImagesToDelete), state.OriginalImages, state.VideoLink, state.SelectedFolder, state.Folders, state.IsLoading);
            return [newState_3, Cmd_ofSub((_arg5) => {
                onChange(new ExternalMsg(0, newState_3.Stage));
            })];
        }
        case 6: {
            let newState_4;
            const patternInput = partition((x_5) => (x_5.Id === msg.fields[0]), state.Stage.Images);
            newState_4 = (new State(new ImagesOnStage(patternInput[1], append_1(state.Stage.ImagesToDelete, map_2((x_6) => x_6.Id, patternInput[0]))), state.OriginalImages, state.VideoLink, state.SelectedFolder, state.Folders, state.IsLoading));
            return [newState_4, Cmd_ofSub((_arg6) => {
                onChange(new ExternalMsg(0, newState_4.Stage));
            })];
        }
        case 7: {
            return [new State(state.Stage, state.OriginalImages, state.VideoLink, state.SelectedFolder, state.Folders, msg.fields[0]), Cmd_none()];
        }
        default: {
            const x = msg.fields[0];
            const newState = new State(new ImagesOnStage(choose_1((image) => {
                if (image.Folder === x) {
                    return image.Image;
                }
                else {
                    return void 0;
                }
            }, state.OriginalImages), empty()), state.OriginalImages, state.VideoLink, x, state.Folders, state.IsLoading);
            ImportImages_LocalStorage_saveSelectedFolder(newState.SelectedFolder);
            return [newState, Cmd_ofSub((_arg1) => {
                onChange(new ExternalMsg(0, newState.Stage));
            })];
        }
    }
}

export function EditImages(editImagesInputProps) {
    let props_18, elms, props_25, patternInput_1, elms_1, props_31, s, props_29, value_67, props_34;
    const videoLinkErrors = editImagesInputProps.videoLinkErrors;
    const onChange = editImagesInputProps.onChange;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(init, (msg, state) => update(onChange, msg, state), editImagesInputProps.dependencies);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    useImportImages_importImages((arg) => {
        dispatch(new Msg(0, arg));
    }, (arg_1) => {
        dispatch(new Msg(2, arg_1));
    }, []);
    const props_36 = ofArray([["style", {
        padding: 15,
    }], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(map_1((image) => {
        let props_12, props_2, props, props_6, props_4, props_10, props_8;
        const props_14 = ofArray([["className", "is-one-third"], ["children", Interop_reactApi.Children.toArray([createElement(RenderImage, image), (props_12 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
            cursor: "pointer",
        }], ["className", "has-text-primary"], ["className", join(" ", ["fa", "fa-arrow-left"])], ["onClick", (_arg1) => {
            dispatch(new Msg(4, image.Id));
        }]]), createElement("span", createObj(Helpers_combineClasses("icon", props))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_6 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["style", {
            cursor: "pointer",
        }], ["className", "has-text-primary"], ["className", join(" ", ["fa", "fa-arrow-right"])], ["onClick", (_arg2) => {
            dispatch(new Msg(5, image.Id));
        }]]), createElement("span", createObj(Helpers_combineClasses("icon", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_10 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["style", {
            cursor: "pointer",
        }], ["className", "has-text-danger"], ["className", join(" ", ["far", "fa-trash-alt"])], ["onClick", (_arg3) => {
            dispatch(new Msg(6, image.Id));
        }]]), createElement("span", createObj(Helpers_combineClasses("icon", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_12))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("column", props_14)));
    }, state_1.Stage.Images), delay(() => {
        let props_16;
        return singleton_2((props_16 = ofArray([["className", "is-one-third"], ["children", Interop_reactApi.Children.toArray([createElement(UploadImage, {
            folder: state_1.SelectedFolder,
            inProgress: state_1.IsLoading,
            setInProgress: (arg_2) => {
                dispatch(new Msg(7, arg_2));
            },
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))));
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18)))), (elms = singleton((props_25 = singleton(["children", Interop_reactApi.Children.toArray([(patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-grey"], ["tabIndex", -1], ["onChange", (ev) => {
        dispatch(new Msg(3, ev.target.value));
    }], ["value", state_1.SelectedFolder], ["children", Interop_reactApi.Children.toArray(Array.from(map_1((arg00) => createElement("option", {
        children: [arg00],
    }), state_1.Folders)))]])), createElement("div", {
        className: join(" ", cons("select", patternInput_1[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
    }))])]), createElement("div", createObj(Helpers_combineClasses("control", props_25))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton((props_31 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(s = t("common.youTubeLink"), createElement("label", {
        className: "label",
        children: s,
    })), (props_29 = ofArray([["placeholder", "https://youtu.be/video"], (value_67 = ((state_1.VideoLink != null) ? value_82(state_1.VideoLink) : ""), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_67)) : false) {
            e.value = value_67;
        }
    }]), ["onChange", (ev_1) => {
        const x = ev_1.target.value;
        if (x === "") {
            dispatch(new Msg(1, void 0));
        }
        else {
            dispatch(new Msg(1, x));
        }
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_29)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_31))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_34 = ofArray([["className", "is-danger"], ["children", fold((s_1, x_1) => (`${s_1} ${x_1}`), "", videoLinkErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_34))))])]]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_36)));
}

