import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { useVisible } from "react-hooks-visible";
import { defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "./fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton, append, map, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function Breadcrumbs(breadcrumbsInputProps) {
    let props_3, children;
    const unitVar1 = breadcrumbsInputProps.unitVar1;
    const list = breadcrumbsInputProps.list;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const elemRef = useVisible()[0];
    const callback = (_arg1) => {
        patternInput[1](false);
    };
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = matchValue, copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            document.addEventListener(eventType, fn, addOptions);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                document.removeEventListener(eventType, fn, removeOptions);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = matchValue_1, copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_7 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_7), [options_7]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_7), [options_7]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            document.addEventListener(eventType_1, fn_1, addOptions_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                document.removeEventListener(eventType_1, fn_1, removeOptions_1);
            }
        });
    }));
    const props_5 = ofArray([["className", "px-3"], ["className", "is-light"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "breadcrumbStyle"], ["className", "has-arrow-separator"], ["aria-label", "breadcrumbs"], ["children", Interop_reactApi.Children.toArray([(children = toList(delay(() => map((i) => createElement("li", createObj(toList(delay(() => append((i.Href === "") ? singleton(["className", join(" ", ["is-active"])]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
        href: i.Href,
        children: i.Name,
    })])]))))))), list))), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]), createElement("nav", createObj(Helpers_combineClasses("breadcrumb", props_3))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_5)));
}

