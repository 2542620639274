import { Record, Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { record_type, list_type, union_type, class_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Remoting_handleNonAuth, Cmd_fromAsync, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { BasicSettings_RenderFreightZone, CostMatrix_FreightCostZone$reflection } from "./SettingsFreightCostZone.js";
import { isEmpty, ofArray, singleton as singleton_1, exists, sortDescending, tryHead, indexed, map, append, filter, sortBy } from "./fable_modules/fable-library.3.6.1/List.js";
import { parse } from "./fable_modules/fable-library.3.6.1/Int32.js";
import { substring } from "./fable_modules/fable-library.3.6.1/String.js";
import { DeliveryCostZoneIdModule_localFreight, DeliveryCostZoneIdModule_postnord, DeliveryCostZoneIdModule_dbSchenker, DeliveryCostZoneIdModule_artMove, DeliveryCostZoneIdModule_fromString, DeliveryCostZoneIdModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { createObj, safeHash, equals, comparePrimitives } from "./fable_modules/fable-library.3.6.1/Util.js";
import { List_except } from "./fable_modules/fable-library.3.6.1/Seq2.js";
import { SettingsFreight_DeliveryServiceType, SettingsFreight_FreightZone$reflection, SettingsFreight_FreightZone } from "./Shared/Shared.js";
import { map as map_1, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { newGuid } from "./fable_modules/fable-library.3.6.1/Guid.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { LocalizedStringModule_empty } from "./bidflow/src/infrastructure/Localization.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "./Localization.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { map as map_2, empty, singleton as singleton_2, append as append_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { settingsApi } from "./Communication.js";
import { loader } from "./Loader.js";
import { isCurrentUserAdmin } from "./LocalStorage.js";
import { CompanyPickUp_Render, CompanyDelivery_Render } from "./SettingsCommonFreight.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AddFreightZone", "ArchiveFreightZone", "FreightZoneChanged"];
    }
}

export function Msg$reflection() {
    return union_type("SettingsFreight.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(string_type)]], [["Item", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", CostMatrix_FreightCostZone$reflection()]]]);
}

export function Cmd_updateIdForZoneFreightTypes(allFreightTypes) {
    const zoneFreightTypes = sortBy((x_1) => parse(substring(DeliveryCostZoneIdModule_toString(x_1.Id), 4), 511, false, 32), filter((x) => (DeliveryCostZoneIdModule_toString(x.Id).indexOf("zone") >= 0), allFreightTypes), {
        Compare: (x_2, y) => comparePrimitives(x_2, y),
    });
    return append(List_except(zoneFreightTypes, allFreightTypes, {
        Equals: (x_5, y_1) => equals(x_5, y_1),
        GetHashCode: (x_5) => safeHash(x_5),
    }), map((tupledArg) => {
        const zone = tupledArg[1];
        const idx = tupledArg[0] | 0;
        if (zone.Id === DeliveryCostZoneIdModule_fromString(`zone${(idx + 1)}`)) {
            return zone;
        }
        else {
            return new SettingsFreight_FreightZone(DeliveryCostZoneIdModule_fromString(`zone${(idx + 1)}`), zone.GuidId, zone.Name, zone.Description, zone.DeliveryServiceType, zone.IsArchived, zone.IsNewAdded);
        }
    }, indexed(zoneFreightTypes)));
}

export function Cmd_getNextZoneId(zones) {
    return defaultArg(map_1((x_3) => DeliveryCostZoneIdModule_fromString(`zone${(x_3 + 1)}`), tryHead(sortDescending(map((x_1) => parse(substring(DeliveryCostZoneIdModule_toString(x_1.Id), 4), 511, false, 32), filter((x) => (DeliveryCostZoneIdModule_toString(x.Id).indexOf("zone") >= 0), zones)), {
        Compare: (x_2, y) => comparePrimitives(x_2, y),
    }))), DeliveryCostZoneIdModule_fromString("zone1"));
}

export function Cmd_addFreightZone(originalSettingsFromDb, settings) {
    return singleton.Delay(() => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, Cmd_getNextZoneId(List_except(filter((x_1) => {
        if (x_1.IsArchived) {
            return !exists((x) => (x.Id === x_1.Id), originalSettingsFromDb);
        }
        else {
            return false;
        }
    }, settings), settings, {
        Equals: (x_2, y) => equals(x_2, y),
        GetHashCode: (x_2) => safeHash(x_2),
    }))))));
}

export class State extends Record {
    constructor(FreightZones, OriginalFreightZones) {
        super();
        this.FreightZones = FreightZones;
        this.OriginalFreightZones = OriginalFreightZones;
    }
}

export function State$reflection() {
    return record_type("SettingsFreight.State", [], State, () => [["FreightZones", list_type(SettingsFreight_FreightZone$reflection())], ["OriginalFreightZones", list_type(SettingsFreight_FreightZone$reflection())]]);
}

export function init(settings) {
    const toState = (x) => (new SettingsFreight_FreightZone(x.DeliveryCostZoneId, newGuid(), x.Name, x.Description, x.DeliveryServiceType, x.IsArchived, false));
    return [new State(map(toState, settings), map(toState, settings)), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(Cmd_updateIdForZoneFreightTypes(filter((x_1) => (!(((x_1.GuidId === msg.fields[0]) ? x_1.IsArchived : false) ? x_1.IsNewAdded : false)), map((x) => {
            if (x.GuidId === msg.fields[0]) {
                return new SettingsFreight_FreightZone(x.Id, x.GuidId, x.Name, x.Description, x.DeliveryServiceType, !x.IsArchived, x.IsNewAdded);
            }
            else {
                return x;
            }
        }, state.FreightZones))), state.OriginalFreightZones), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new State(map((x_2) => {
            if (x_2.GuidId === msg.fields[0]) {
                const z = msg.fields[1];
                return new SettingsFreight_FreightZone(z.Id, z.GuidId, z.Name, z.Description, z.DeliveryServiceType, z.IsArchived, z.IsNewAdded);
            }
            else {
                return x_2;
            }
        }, state.FreightZones), state.OriginalFreightZones), Cmd_none()];
    }
    else if (msg.fields[0].tag === 1) {
        return [new State(append(state.FreightZones, singleton_1(new SettingsFreight_FreightZone(msg.fields[0].fields[0], newGuid(), LocalizedStringModule_empty, LocalizedStringModule_empty, new SettingsFreight_DeliveryServiceType(2), false, true))), state.OriginalFreightZones), Cmd_none()];
    }
    else {
        return [state, Cmd_fromAsync(Cmd_addFreightZone(state.OriginalFreightZones, state.FreightZones))];
    }
}

export const headerTable = createElement("div", {
    style: {
        borderRadius: 0,
        backgroundColor: "transparent",
        borderLeft: (((4 + "px ") + "solid") + " ") + "transparent",
    },
    children: Interop_reactApi.Children.toArray([(() => {
        let props, props_2;
        const props_6 = ofArray([["className", "px-5"], ["className", "mx-0"], ["className", "my-0"], ["className", "py-0"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "is-size-7"], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-5"], ["children", t("settings.zoneId")]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-5"], ["children", t("common.title")]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-2"]))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("columns", props_6)));
    })()]),
});

export function RenderSetting(renderSettingInputProps) {
    let elms, props_16;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderSettingInputProps.settings), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    return react.createElement(react.Fragment, {}, (elms = singleton_1((props_16 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_2, props;
        return append_1(singleton_2((props_2 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-outlined"], ["className", "is-primary"], ["className", "is-uppercase"], ["className", "mb-1"], ["children", t("settings.createFreightType")], ["disabled", exists((f) => f.IsNewAdded, state_1.FreightZones)], ["onClick", (_arg1) => {
            dispatch(new Msg(0, new AsyncOperationStatus$1(0)));
            patternInput_1[1](true);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_2))))), delay(() => {
            let props_4;
            return append_1(singleton_2((props_4 = toList(delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(singleton_2(["style", {
                textAlign: "right",
            }]), delay(() => (patternInput_1[0] ? singleton_2(["children", t("settings.newDeliveryZoneWarning")]) : empty()))))))), createElement("p", createObj(Helpers_combineClasses("help", props_4))))), delay(() => append_1(singleton_2(headerTable), delay(() => {
                let children;
                const specialDeliveryZones = filter((f_1) => {
                    if (((f_1.Id === DeliveryCostZoneIdModule_artMove) ? true : (f_1.Id === DeliveryCostZoneIdModule_dbSchenker)) ? true : (f_1.Id === DeliveryCostZoneIdModule_postnord)) {
                        return true;
                    }
                    else {
                        return f_1.Id === DeliveryCostZoneIdModule_localFreight;
                    }
                }, state_1.FreightZones);
                const otherZones = List_except(specialDeliveryZones, state_1.FreightZones, {
                    Equals: (x, y) => equals(x, y),
                    GetHashCode: (x) => safeHash(x),
                });
                return append_1((!isEmpty(specialDeliveryZones)) ? singleton_2((children = toList(delay(() => {
                    let props_8, props_6;
                    return append_1(singleton_2((props_8 = ofArray([["className", "py-5"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-uppercase"], ["children", t("settings.specialDelivery")]]), createElement("h4", createObj(Helpers_combineClasses("title is-4", props_6))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_8))))), delay(() => map_2((setting) => createElement(BasicSettings_RenderFreightZone, {
                        settingsList: state_1.FreightZones,
                        settings: setting,
                        archiveFreightZone: (x_1) => {
                            if (x_1.tag === 0) {
                                dispatch(new Msg(2, x_1.fields[0], x_1.fields[1]));
                            }
                            else {
                                dispatch(new Msg(1, x_1.fields[0]));
                            }
                        },
                    }), specialDeliveryZones)));
                })), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))) : empty(), delay(() => {
                    let children_2;
                    return singleton_2((children_2 = toList(delay(() => {
                        let props_13, props_11;
                        return append_1(singleton_2((props_13 = ofArray([["className", "py-5"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-uppercase"], ["children", t("settings.homeDelivery")]]), createElement("h4", createObj(Helpers_combineClasses("title is-4", props_11))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_13))))), delay(() => map_2((setting_1) => createElement(BasicSettings_RenderFreightZone, {
                            settingsList: state_1.FreightZones,
                            settings: setting_1,
                            archiveFreightZone: (x_3) => {
                                if (x_3.tag === 0) {
                                    dispatch(new Msg(2, x_3.fields[0], x_3.fields[1]));
                                }
                                else {
                                    dispatch(new Msg(1, x_3.fields[0]));
                                }
                            },
                        }), otherZones)));
                    })), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    })));
                }));
            }))));
        }));
    }))))]), createElement("section", createObj(Helpers_combineClasses("section", props_16))))), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })));
}

export function Render() {
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getAllFreightZones()), []);
    switch (settings.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            return settings.fields[0].message;
        }
        case 2: {
            return createElement(RenderSetting, {
                settings: settings.fields[0],
            });
        }
        default: {
            return loader("big");
        }
    }
}

export class Tab extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompanyPickUp", "CompanyDelivery", "FreightZone"];
    }
}

export function Tab$reflection() {
    return union_type("SettingsFreight.Tab", [], Tab, () => [[], [], []]);
}

export function RenderFreightSettings() {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Tab(0));
    const tab = patternInput[0];
    const setTab = patternInput[1];
    if (isCurrentUserAdmin()) {
        const elms = toList(delay(() => {
            let props_1, s;
            return append_1(singleton_2((props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("settings.freightSettings"), createElement("h1", {
                className: "title",
                children: s,
            }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1))))), delay(() => {
                let props_13, children, props_4, props_7, props_10;
                return append_1(singleton_2((props_13 = ofArray([["className", "is-toggle"], ["className", "is-centered"], ["className", "mb-2"], ["className", "mt-0"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_4 = toList(delay(() => append_1(equals(tab, new Tab(0)) ? singleton_2(["className", "is-active"]) : empty(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "is-normal-padding",
                    onClick: (_arg1) => {
                        setTab(new Tab(0));
                    },
                    children: t("settings.pickUpInfoTab"),
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_7 = toList(delay(() => append_1(equals(tab, new Tab(1)) ? singleton_2(["className", "is-active"]) : empty(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "is-normal-padding",
                    onClick: (_arg2) => {
                        setTab(new Tab(1));
                    },
                    children: t("settings.homeDeliveryInfoTab"),
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7)))), (props_10 = toList(delay(() => append_1(equals(tab, new Tab(2)) ? singleton_2(["className", "is-active"]) : empty(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "is-normal-padding",
                    onClick: (_arg3) => {
                        setTab(new Tab(2));
                    },
                    children: t("settings.freightZonesTab"),
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_10))))]), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_13))))), delay(() => ((tab.tag === 1) ? singleton_2(createElement(CompanyDelivery_Render, null)) : ((tab.tag === 2) ? singleton_2(createElement(Render, null)) : singleton_2(createElement(CompanyPickUp_Render, null))))));
            }));
        }));
        return createElement("div", {
            className: "container",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        });
    }
    else {
        return null;
    }
}

