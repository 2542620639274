import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { int32_type, option_type, union_type, unit_type, record_type, bool_type, class_type, list_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Priority_fromES, Submissions_PageType, AuctionStatus, PrintLabelInfo, Priority_toES, Dimensions, ItemLotBasicInformationField, PublishLotInfo, WithdrawInfo, RemoveLotInfo, Lot, Fee, GpsPosition, LotInformation, Priority_Priority$reflection, Category$reflection, CategoryCatalog$reflection, LotInformation$reflection, Lot$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, Remoting_handleNonAuth, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { navigate, EditLotArg, toPath, AuctionState, Route, AuctionArg, AuctionResellLotState, navigateTo, EditLotArg$reflection } from "./Router.js";
import { LocalizedStringModule_getTranslationOrEmpty, LocalizedMap_getValueOrEmpty, LocalizedStringModule_setTranslation, LocalizedMap_add } from "./bidflow/src/infrastructure/Localization.js";
import { Lot_Edit_validate, Lot_Edit_validateIf, Lot_Edit_EditLotValidation } from "./Validation.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi, auctionApi, itemApi, lotApi } from "./Communication.js";
import { append as append_1, empty as empty_2, fold as fold_1, cons, head, isEmpty, singleton as singleton_1, ofArray, tryFind } from "./fable_modules/fable-library.3.6.1/List.js";
import { Localized_translateTo, Localized_translate, getCurrentLanguage, getPrimaryLanguage, t } from "./Localization.js";
import { tryFind as tryFind_1, map, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { defaultArg, value as value_566 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { ReservedPrice_tryParseOrDefault, Currency_currencySymbol, Currency_tryParseOrDefault, Decimal_optionalToString, Form_getFieldError, Decimal_tryParse } from "./Common.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { SelectPriority, SelectCategory } from "./Components/CreateLot.js";
import { collect, fold, empty as empty_1, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { isNullOrWhiteSpace, join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Image_mediumContains, Image_imagePlaceholder } from "./Css.js";
import { ReturnRecycleObject, ReturnOrRecycleComponent } from "./Components/ReturnOrRecycleObject.js";
import { UndoRecycle_ByLotId, UndoReturn_ByLotId } from "./Components/UndoRecycleAndReturnItem.js";
import { Component_ConfirmationWithComment, Component_Props_get_Default, Component_Props, Feliz_ConfirmationWithCommentProperty } from "./Components/Confirmation.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loaderBig } from "./Loader.js";
import { EditImages } from "./EditItemImages.js";
import { toNumber, fromParts } from "./fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.6.1/Decimal.js";
import { NumberFormatModule_numberFormat } from "./Components/InputDecimal.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { SearchSubmission } from "./Components/SearchSubmissionComponent.js";
import { languageSwitcher } from "./Components/ContentLanguageSwitcher.js";
import { SearchItemName } from "./Components/SearchItemNameComponent.js";
import { CurrencyModule_fromDecimal, CurrencyModule_toDecimal, PercentModule_fromDecimal, PercentModule_toDecimal, ReservedPriceModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { edit } from "./Components/Freight.js";

export class ValidationState extends Record {
    constructor(FormErrors, NeedToValidate) {
        super();
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
    }
}

export function ValidationState$reflection() {
    return record_type("EditLot.ValidationState", [], ValidationState, () => [["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type]]);
}

export class State extends Record {
    constructor(OriginalLot, LotInfo, Catalog, SaveOperation, RemoveOrWithdrawOperation, IsEditMode, AuctionId, ReasonForWithdraw, ReasonReturnedOrRecycled, AuctionPageAndFilterState, LotNumber, ContentLanguage, Validation) {
        super();
        this.OriginalLot = OriginalLot;
        this.LotInfo = LotInfo;
        this.Catalog = Catalog;
        this.SaveOperation = SaveOperation;
        this.RemoveOrWithdrawOperation = RemoveOrWithdrawOperation;
        this.IsEditMode = IsEditMode;
        this.AuctionId = AuctionId;
        this.ReasonForWithdraw = ReasonForWithdraw;
        this.ReasonReturnedOrRecycled = ReasonReturnedOrRecycled;
        this.AuctionPageAndFilterState = AuctionPageAndFilterState;
        this.LotNumber = LotNumber;
        this.ContentLanguage = ContentLanguage;
        this.Validation = Validation;
    }
}

export function State$reflection() {
    return record_type("EditLot.State", [], State, () => [["OriginalLot", Lot$reflection()], ["LotInfo", LotInformation$reflection()], ["Catalog", CategoryCatalog$reflection()], ["SaveOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["RemoveOrWithdrawOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["IsEditMode", bool_type], ["AuctionId", class_type("System.Int64")], ["ReasonForWithdraw", string_type], ["ReasonReturnedOrRecycled", string_type], ["AuctionPageAndFilterState", EditLotArg$reflection()], ["LotNumber", string_type], ["ContentLanguage", string_type], ["Validation", ValidationState$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CategoryChange", "TitleChanged", "DescriptionChanged", "ConditionChanged", "HeightChanged", "WidthChanged", "LengthChanged", "DepthChanged", "DiameterChanged", "GPSAddressChanged", "GPSMapLinkChanged", "EstimatedPriceChanged", "ReservedPriceChanged", "HammerFeeChanged", "HasRoyaltiesChanged", "PurchaseCommissionChanged", "FreightZoneChanged", "SubmissionChanged", "ChangePriority", "SwitchToEditMode", "CancelChanges", "SaveChanges", "RemoveLot", "WithdrawLot", "ReasonChange", "ReasonReturnedOrRecycledChanged", "ContentLanguageChanged", "PublishLot"];
    }
}

export function Msg$reflection() {
    return union_type("EditLot.Msg", [], Msg, () => [[["Item", Category$reflection()]], [["Item", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", option_type(string_type)]], [["Item", option_type(string_type)]], [["Item", class_type("System.Decimal")]], [["Item", int32_type]], [["Item", class_type("System.Decimal")]], [["Item", bool_type]], [["Item", class_type("System.Decimal")]], [["Item1", string_type], ["Item2", option_type(int32_type)]], [["Item1", class_type("System.Int64")], ["Item2", class_type("System.Int64")], ["Item3", string_type]], [["Item", Priority_Priority$reflection()]], [], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function withLotInfo(info, state) {
    return new State(state.OriginalLot, info, state.Catalog, state.SaveOperation, state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation);
}

export function withBasicInfoField(field, lang, value, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(LocalizedMap_add(field, lang, value, state.LotInfo.BasicInfo), inputRecord.Dimensions, inputRecord.Fee, inputRecord.Freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withDimension(dimension, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, dimension, inputRecord.Fee, inputRecord.Freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withFee(fee, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, inputRecord.Dimensions, fee, inputRecord.Freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withFreight(freight, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, inputRecord.Dimensions, inputRecord.Fee, freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withGPSPosition(gpsPosition, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, inputRecord.Dimensions, inputRecord.Fee, inputRecord.Freight, gpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withGPSPositionFormAndErrors(mapLink, errors, state) {
    const state_1 = withGPSPosition(new GpsPosition(state.LotInfo.GpsPosition.Address, mapLink), state);
    return new State(state_1.OriginalLot, state_1.LotInfo, state_1.Catalog, state_1.SaveOperation, state_1.RemoveOrWithdrawOperation, state_1.IsEditMode, state_1.AuctionId, state_1.ReasonForWithdraw, state_1.ReasonReturnedOrRecycled, state_1.AuctionPageAndFilterState, state_1.LotNumber, state_1.ContentLanguage, new ValidationState(errors, state.Validation.NeedToValidate));
}

export function withCommissionsFormAndErrors(commissions, errors, state) {
    let inputRecord;
    const state_1 = withGPSPosition(new GpsPosition(state.LotInfo.GpsPosition.Address, commissions.GPSLink), withFee((inputRecord = state.LotInfo.Fee, new Fee(inputRecord.EstimatedPrice, inputRecord.ReservedPrice, commissions.PurchaseCommission, commissions.HammerFee, inputRecord.HasRoyalties)), state));
    return new State(state_1.OriginalLot, state_1.LotInfo, state_1.Catalog, state_1.SaveOperation, state_1.RemoveOrWithdrawOperation, state_1.IsEditMode, state_1.AuctionId, state_1.ReasonForWithdraw, state_1.ReasonReturnedOrRecycled, state_1.AuctionPageAndFilterState, state_1.LotNumber, state_1.ContentLanguage, new ValidationState(errors, state.Validation.NeedToValidate));
}

export function toValidationType(purchaseCommission, hammerFee, link) {
    return new Lot_Edit_EditLotValidation(link, purchaseCommission, hammerFee);
}

export function saveLot(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let lot;
        const inputRecord = state.OriginalLot;
        lot = (new Lot(inputRecord.LotId, inputRecord.AuctionId, inputRecord.ItemId, inputRecord.OrderNumber, state.LotInfo));
        return singleton.Bind(lotApi().modifyLot(lot), () => singleton.Return(new Msg(21, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))));
    }), (_arg2) => singleton.Return(new Msg(21, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function remove(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const info = new RemoveLotInfo(state.OriginalLot.ItemId, state.OriginalLot.LotId, state.OriginalLot.AuctionId, state.OriginalLot.Info.CategoryId);
        return singleton.Bind(lotApi().removeLot(info), () => singleton.Return(new Msg(22, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))));
    }), (_arg2) => singleton.Return(new Msg(22, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function withdraw(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const info = new WithdrawInfo(state.OriginalLot.ItemId, state.OriginalLot.LotId, state.OriginalLot.AuctionId, state.ReasonForWithdraw);
        return singleton.Bind(Remoting_handleNonAuth(lotApi().withdrawLot(info)), () => singleton.Return(new Msg(23, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))));
    }), (_arg2) => singleton.Return(new Msg(23, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function publishLot(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const info = new PublishLotInfo(state.OriginalLot.ItemId, state.OriginalLot.LotId, state.OriginalLot.AuctionId);
        return singleton.Bind(Remoting_handleNonAuth(lotApi().publishLot(info)), (_arg1) => singleton.Return(new Msg(27, new AsyncOperationStatus$1(1, _arg1))));
    }), (_arg2) => singleton.Return(new Msg(27, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function category(state) {
    return tryFind((x) => (x.Id === state.LotInfo.CategoryId), state.Catalog.Categories);
}

export function getNextOrderNumber(state) {
    const category_1 = category(state);
    let pattern_matching_result;
    if (category_1 != null) {
        if (category_1.Id === state.OriginalLot.Info.CategoryId) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return `${state.OriginalLot.OrderNumber}`;
        }
        case 1: {
            if (category_1 == null) {
                return "-";
            }
            else {
                const matchValue = category_1.NextOrderNumber;
                if (matchValue.tag === 1) {
                    return `${matchValue.fields[0]}`;
                }
                else {
                    return t("auction.selectCategory");
                }
            }
        }
    }
}

export function init(lot, categories, auctionState) {
    let category_1;
    const initState = new State(lot, lot.Info, categories, new Deferred$1(0), new Deferred$1(0), false, lot.AuctionId, "", "", auctionState, "-", getPrimaryLanguage(), new ValidationState(empty(), false));
    return [initState, (category_1 = category(initState), (category_1 != null) ? Cmd_OfFunc_result(new Msg(0, value_566(category_1))) : Cmd_none())];
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, tupledArg_1, tupledArg_2, inputRecord_11, inputRecord_14, tupledArg_4, tupledArg_3, tupledArg_6, tupledArg_5, tupledArg_8, tupledArg_7;
    const validate = (terms) => Lot_Edit_validateIf(state.Validation.NeedToValidate, terms);
    if (msg.tag === 0) {
        return [new State(state.OriginalLot, (inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, inputRecord.Dimensions, inputRecord.Fee, inputRecord.Freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, msg.fields[0].Id, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state.Catalog, state.SaveOperation, state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, getNextOrderNumber(state), state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 17) {
        return [new State(state.OriginalLot, (inputRecord_1 = state.LotInfo, new LotInformation(inputRecord_1.BasicInfo, inputRecord_1.Dimensions, inputRecord_1.Fee, inputRecord_1.Freight, inputRecord_1.GpsPosition, inputRecord_1.Images, inputRecord_1.VideoLink, inputRecord_1.CategoryId, inputRecord_1.IsWithdraw, msg.fields[0], msg.fields[1], msg.fields[2], inputRecord_1.IsUnSold, inputRecord_1.ItemReservedForAuction, inputRecord_1.IsReturned, inputRecord_1.IsRecycle, inputRecord_1.IsPublish, inputRecord_1.Priority)), state.Catalog, state.SaveOperation, state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [withBasicInfoField(new ItemLotBasicInformationField(0), state.ContentLanguage, msg.fields[0], state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withBasicInfoField(new ItemLotBasicInformationField(1), msg.fields[0], msg.fields[1], state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withBasicInfoField(new ItemLotBasicInformationField(2), msg.fields[0], msg.fields[1], state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        const newHeightStr = msg.fields[0].trim();
        return [withDimension((inputRecord_2 = state.LotInfo.Dimensions, new Dimensions(Decimal_tryParse(newHeightStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newHeightStr, state.LotInfo.Dimensions.HeightInfo), inputRecord_2.Length, inputRecord_2.LengthInfo, inputRecord_2.Width, inputRecord_2.WidthInfo, inputRecord_2.Depth, inputRecord_2.DepthInfo, inputRecord_2.Diameter, inputRecord_2.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        const newWidthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_3 = state.LotInfo.Dimensions, new Dimensions(inputRecord_3.Height, inputRecord_3.HeightInfo, inputRecord_3.Length, inputRecord_3.LengthInfo, Decimal_tryParse(newWidthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newWidthStr, state.LotInfo.Dimensions.WidthInfo), inputRecord_3.Depth, inputRecord_3.DepthInfo, inputRecord_3.Diameter, inputRecord_3.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        const newLengthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_4 = state.LotInfo.Dimensions, new Dimensions(inputRecord_4.Height, inputRecord_4.HeightInfo, Decimal_tryParse(newLengthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newLengthStr, state.LotInfo.Dimensions.LengthInfo), inputRecord_4.Width, inputRecord_4.WidthInfo, inputRecord_4.Depth, inputRecord_4.DepthInfo, inputRecord_4.Diameter, inputRecord_4.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        const newDepthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_5 = state.LotInfo.Dimensions, new Dimensions(inputRecord_5.Height, inputRecord_5.HeightInfo, inputRecord_5.Length, inputRecord_5.LengthInfo, inputRecord_5.Width, inputRecord_5.WidthInfo, Decimal_tryParse(newDepthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newDepthStr, state.LotInfo.Dimensions.DepthInfo), inputRecord_5.Diameter, inputRecord_5.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        const newDiameterStr = msg.fields[0].trim();
        return [withDimension((inputRecord_6 = state.LotInfo.Dimensions, new Dimensions(inputRecord_6.Height, inputRecord_6.HeightInfo, inputRecord_6.Length, inputRecord_6.LengthInfo, inputRecord_6.Width, inputRecord_6.WidthInfo, inputRecord_6.Depth, inputRecord_6.DepthInfo, Decimal_tryParse(newDiameterStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newDiameterStr, state.LotInfo.Dimensions.DiameterInfo))), state), Cmd_none()];
    }
    else if (msg.tag === 14) {
        return [withFee((inputRecord_7 = state.LotInfo.Fee, new Fee(inputRecord_7.EstimatedPrice, inputRecord_7.ReservedPrice, inputRecord_7.PurchaseCommission, inputRecord_7.Hammer, msg.fields[0])), state), Cmd_none()];
    }
    else if (msg.tag === 15) {
        const withValidatedForm = (arg) => {
            const tupledArg = validate(arg);
            return (state_10) => withCommissionsFormAndErrors(tupledArg[0], tupledArg[1], state_10);
        };
        const args = toValidationType(msg.fields[0], state.LotInfo.Fee.Hammer, state.LotInfo.GpsPosition.MapLink);
        const x_11 = withValidatedForm(args)(state);
        return [withValidatedForm(args)(state), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [withFee((inputRecord_8 = state.LotInfo.Fee, new Fee(msg.fields[0], inputRecord_8.ReservedPrice, inputRecord_8.PurchaseCommission, inputRecord_8.Hammer, inputRecord_8.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [withFee((inputRecord_9 = state.LotInfo.Fee, new Fee(inputRecord_9.EstimatedPrice, msg.fields[0], inputRecord_9.PurchaseCommission, inputRecord_9.Hammer, inputRecord_9.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [(tupledArg_1 = validate(toValidationType(state.LotInfo.Fee.PurchaseCommission, msg.fields[0], state.LotInfo.GpsPosition.MapLink)), withCommissionsFormAndErrors(tupledArg_1[0], tupledArg_1[1], state)), Cmd_none()];
    }
    else if (msg.tag === 16) {
        return [withFreight(map((idx, v) => {
            if (idx === msg.fields[0]) {
                return msg.fields[1];
            }
            else {
                return v;
            }
        }, state.LotInfo.Freight), state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withGPSPosition(new GpsPosition(msg.fields[0], state.LotInfo.GpsPosition.MapLink), state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [(tupledArg_2 = validate(toValidationType(state.LotInfo.Fee.PurchaseCommission, state.LotInfo.Fee.Hammer, msg.fields[0])), withCommissionsFormAndErrors(tupledArg_2[0], tupledArg_2[1], state)), Cmd_none()];
    }
    else if (msg.tag === 18) {
        return [new State(state.OriginalLot, (inputRecord_11 = state.LotInfo, new LotInformation(inputRecord_11.BasicInfo, inputRecord_11.Dimensions, inputRecord_11.Fee, inputRecord_11.Freight, inputRecord_11.GpsPosition, inputRecord_11.Images, inputRecord_11.VideoLink, inputRecord_11.CategoryId, inputRecord_11.IsWithdraw, inputRecord_11.SubmissionId, inputRecord_11.SellerId, inputRecord_11.SellerName, inputRecord_11.IsUnSold, inputRecord_11.ItemReservedForAuction, inputRecord_11.IsReturned, inputRecord_11.IsRecycle, inputRecord_11.IsPublish, Priority_toES(msg.fields[0]))), state.Catalog, state.SaveOperation, state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 19) {
        return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, state.RemoveOrWithdrawOperation, true, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 20) {
        return [new State(state.OriginalLot, state.OriginalLot.Info, state.Catalog, state.SaveOperation, state.RemoveOrWithdrawOperation, false, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 21) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 0) {
                return [new State((inputRecord_14 = state.OriginalLot, new Lot(inputRecord_14.LotId, inputRecord_14.AuctionId, inputRecord_14.ItemId, inputRecord_14.OrderNumber, state.LotInfo)), state.LotInfo, state.Catalog, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.RemoveOrWithdrawOperation, false, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
            }
            else {
                return [new State(state.OriginalLot, state.LotInfo, state.Catalog, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
            }
        }
        else {
            const formValidation = Lot_Edit_validate(toValidationType(state.LotInfo.Fee.PurchaseCommission, state.LotInfo.Fee.Hammer, state.LotInfo.GpsPosition.MapLink));
            if (formValidation.tag === 1) {
                return [new State(state.OriginalLot, state.LotInfo, state.Catalog, new Deferred$1(0), state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, new ValidationState(formValidation.fields[0], true)), Cmd_none()];
            }
            else {
                return [new State(state.OriginalLot, state.LotInfo, state.Catalog, new Deferred$1(1), state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, new ValidationState(empty(), true)), Cmd_fromAsync(saveLot(state))];
            }
        }
    }
    else if (msg.tag === 22) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 0) {
                const matchValue = state.AuctionPageAndFilterState;
                if (matchValue.tag === 1) {
                    const s_1 = matchValue.fields[2];
                    return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), navigateTo(new Route(2, (tupledArg_4 = [state.OriginalLot.AuctionId, new AuctionResellLotState(s_1.IsUnsoldFilterOn, s_1.IsClaimedOrRefundedFilterOn, s_1.IsWithDrawnFilterOn, s_1.Page)], new AuctionArg(1, tupledArg_4[0], tupledArg_4[1]))))];
                }
                else {
                    const s = matchValue.fields[2];
                    return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), navigateTo(new Route(2, (tupledArg_3 = [state.OriginalLot.AuctionId, new AuctionState(s.IsUnsoldFilterOn, s.IsIncompleteFilterOn, s.Page)], new AuctionArg(0, tupledArg_3[0], tupledArg_3[1]))))];
                }
            }
            else {
                return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
            }
        }
        else {
            return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(1), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_fromAsync(remove(state))];
        }
    }
    else if (msg.tag === 23) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 0) {
                const matchValue_1 = state.AuctionPageAndFilterState;
                if (matchValue_1.tag === 1) {
                    const s_3 = matchValue_1.fields[2];
                    return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), navigateTo(new Route(2, (tupledArg_6 = [state.OriginalLot.AuctionId, new AuctionResellLotState(s_3.IsUnsoldFilterOn, s_3.IsClaimedOrRefundedFilterOn, s_3.IsWithDrawnFilterOn, s_3.Page)], new AuctionArg(1, tupledArg_6[0], tupledArg_6[1]))))];
                }
                else {
                    const s_2 = matchValue_1.fields[2];
                    return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), navigateTo(new Route(2, (tupledArg_5 = [state.OriginalLot.AuctionId, new AuctionState(s_2.IsUnsoldFilterOn, s_2.IsIncompleteFilterOn, s_2.Page)], new AuctionArg(0, tupledArg_5[0], tupledArg_5[1]))))];
                }
            }
            else {
                return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
            }
        }
        else {
            return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(1), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_fromAsync(withdraw(state))];
        }
    }
    else if (msg.tag === 24) {
        return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, msg.fields[0], state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 25) {
        return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, msg.fields[0], state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 27) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 0) {
                const matchValue_2 = state.AuctionPageAndFilterState;
                if (matchValue_2.tag === 1) {
                    const s_5 = matchValue_2.fields[2];
                    return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), navigateTo(new Route(2, (tupledArg_8 = [state.OriginalLot.AuctionId, new AuctionResellLotState(s_5.IsUnsoldFilterOn, s_5.IsClaimedOrRefundedFilterOn, s_5.IsWithDrawnFilterOn, s_5.Page)], new AuctionArg(1, tupledArg_8[0], tupledArg_8[1]))))];
                }
                else {
                    const s_4 = matchValue_2.fields[2];
                    return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), navigateTo(new Route(2, (tupledArg_7 = [state.OriginalLot.AuctionId, new AuctionState(s_4.IsUnsoldFilterOn, s_4.IsIncompleteFilterOn, s_4.Page)], new AuctionArg(0, tupledArg_7[0], tupledArg_7[1]))))];
                }
            }
            else {
                return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_none()];
            }
        }
        else {
            return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, new Deferred$1(1), state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, state.ContentLanguage, state.Validation), Cmd_fromAsync(publishLot(state))];
        }
    }
    else {
        return [new State(state.OriginalLot, state.LotInfo, state.Catalog, state.SaveOperation, state.RemoveOrWithdrawOperation, state.IsEditMode, state.AuctionId, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.AuctionPageAndFilterState, state.LotNumber, msg.fields[0], state.Validation), Cmd_none()];
    }
}

export function RenderSelectCategory(renderSelectCategoryInputProps) {
    let props_15, elms_2, elms_1, props_2, props, elms, props_13, props_11, props_7, props_9;
    const onSelectCategory = renderSelectCategoryInputProps.onSelectCategory;
    const selectedCategory = renderSelectCategoryInputProps.selectedCategory;
    const auctionId = renderSelectCategoryInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const category_1 = patternInput[0];
    return react.createElement(react.Fragment, {}, (props_15 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_1((elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.category")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1(createElement(SelectCategory, {
        auctionId: auctionId,
        selected: selectedCategory,
        onSelectCategory: (c) => {
            patternInput[1](c);
            onSelectCategory(c);
        },
        dependencies: [],
    })), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (props_13 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["style", {
        justifyContent: "flex-end",
    }], ["className", "mt-5"], ["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-static"], ["className", "has-background-grey-lighter"], ["children", t("common.lotNumber")]]), createElement("span", createObj(Helpers_combineClasses("button", props_7)))), (props_9 = toList(delay(() => append(singleton_2(["className", "is-static"]), delay(() => append(singleton_2(["className", "has-background-primary"]), delay(() => append(singleton_2(["className", "has-text-white"]), delay(() => {
        let matchValue;
        return singleton_2(["children", (category_1 == null) ? "-" : ((matchValue = category_1.NextOrderNumber, (matchValue.tag === 1) ? (`${matchValue.fields[0]}`) : t("auction.selectCategory")))]);
    })))))))), createElement("span", createObj(Helpers_combineClasses("button", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_11))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_13))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_15)))));
}

export function renderGoToLotButtons(state) {
    let props_2, props_6;
    const auctionPath = (x) => {
        const matchValue = state.AuctionPageAndFilterState;
        if (matchValue.tag === 1) {
            return toPath(new Route(20, new EditLotArg(1, x[0], x[1], matchValue.fields[2])));
        }
        else {
            return toPath(new Route(20, new EditLotArg(0, x[0], x[1], matchValue.fields[2])));
        }
    };
    const isLoading = equals(state.SaveOperation, new Deferred$1(1));
    const props_8 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["onClick", (_arg1_3) => {
        startImmediate(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotApi().getPreviousLotLink(state.OriginalLot.LotId)), (_arg1_2) => {
            navigate(auctionPath(_arg1_2));
            return singleton.Zero();
        })));
    }]), delay(() => {
        let s_2;
        return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("i", {
            style: {
                paddingRight: 10,
            },
            className: join(" ", ["fa", "fa-arrow-left"]),
        }), (s_2 = t("auction.previousLot"), createElement("span", {
            className: "",
            children: s_2,
        }))])]);
    })))))), createElement("a", createObj(Helpers_combineClasses("button", props_2)))), (props_6 = toList(delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["onClick", (_arg2_1) => {
        startImmediate(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotApi().getNextLotLink(state.OriginalLot.LotId)), (_arg2) => {
            navigate(auctionPath(_arg2));
            return singleton.Zero();
        })));
    }]), delay(() => {
        let s_3;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(s_3 = t("auction.nextLot"), createElement("span", {
            className: "",
            children: s_3,
        })), createElement("i", {
            style: {
                paddingLeft: 10,
            },
            className: join(" ", ["fa", "fa-arrow-right"]),
        })])]);
    })))))), createElement("a", createObj(Helpers_combineClasses("button", props_6))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("buttons", props_8)));
}

export function PrintLotLabel(state) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput[1];
    const generateReceipt = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading(true);
        const info = new PrintLabelInfo(state.OriginalLot.ItemId, state.OriginalLot.OrderNumber, state.OriginalLot.Info.Fee.EstimatedPrice, getCurrentLanguage());
        return singleton.Bind(lotApi().generatePrintLabelWithQRCode(info), (_arg1) => {
            window.open(_arg1);
            setIsLoading(false);
            return singleton.Return();
        });
    }), (_arg2) => {
        setIsLoading(false);
        return singleton.Zero();
    }));
    const props_3 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(patternInput[0] ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["onClick", (_arg1_1) => {
        startImmediate(generateReceipt);
    }]), delay(() => {
        let s, elms;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(s = t("common.printLabel"), createElement("span", {
            className: "",
            children: s,
        })), (elms = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-print"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]);
    }))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props_3)));
}

export function RenderButtons(renderButtonsInputProps) {
    let props, props_2;
    const auctionStatus = renderButtonsInputProps.auctionStatus;
    const dispatch = renderButtonsInputProps.dispatch;
    const state = renderButtonsInputProps.state;
    const isLoading = equals(state.SaveOperation, new Deferred$1(1));
    const isRemoving = equals(state.RemoveOrWithdrawOperation, new Deferred$1(1));
    const hasNotChanged = equals(state.LotInfo, state.OriginalLot.Info);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setCorrectBidVisible = patternInput[1];
    const isCorrectBidVisible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setWithdrawVisible = patternInput_1[1];
    const isWithdrawVisible = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setDeleteVisible = patternInput_2[1];
    const isDeleteVisible = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const moreOptionsVisible = patternInput_3[0];
    if (state.IsEditMode) {
        const children = ofArray([(props = toList(delay(() => append(singleton_2(["className", "is-dark"]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["children", t("common.cancel")]), delay(() => singleton_2(["onClick", (x) => {
            x.preventDefault();
            dispatch(new Msg(20));
        }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props)))), (props_2 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["disabled", hasNotChanged]), delay(() => append(singleton_2(["children", t("common.save")]), delay(() => singleton_2(["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new Msg(21, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_2))))]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        const children_9 = toList(delay(() => append(moreOptionsVisible ? singleton_2(createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const isNeedDisabling = (((((!state.OriginalLot.Info.IsUnSold) ? (!state.OriginalLot.Info.IsWithdraw) : false) ? true : (!equals(auctionStatus, new AuctionStatus(0)))) ? true : state.OriginalLot.Info.IsRecycle) ? true : state.OriginalLot.Info.IsReturned) ? true : (state.OriginalLot.Info.ItemReservedForAuction != null);
                let auctionArg;
                const matchValue = state.AuctionPageAndFilterState;
                if (matchValue.tag === 1) {
                    const s_1 = matchValue.fields[2];
                    auctionArg = (new AuctionArg(1, state.AuctionId, new AuctionResellLotState(s_1.IsUnsoldFilterOn, s_1.IsClaimedOrRefundedFilterOn, s_1.IsWithDrawnFilterOn, s_1.Page)));
                }
                else {
                    const s = matchValue.fields[2];
                    auctionArg = (new AuctionArg(0, state.AuctionId, new AuctionState(s.IsUnsoldFilterOn, s.IsIncompleteFilterOn, s.Page)));
                }
                const image = isEmpty(state.OriginalLot.Info.Images) ? Image_imagePlaceholder : Image_mediumContains(head(state.OriginalLot.Info.Images));
                return append((!isNeedDisabling) ? singleton_2(createElement(ReturnOrRecycleComponent, {
                    object: new ReturnRecycleObject(1, state.OriginalLot.ItemId, state.OriginalLot.OrderNumber, Localized_translate(LocalizedMap_getValueOrEmpty(new ItemLotBasicInformationField(0), state.OriginalLot.Info.BasicInfo)), image, isNeedDisabling, true, state.AuctionId, auctionArg),
                })) : empty_1(), delay(() => append(state.OriginalLot.Info.IsReturned ? singleton_2(createElement(UndoReturn_ByLotId, {
                    lotId: state.OriginalLot.LotId,
                })) : empty_1(), delay(() => append(state.OriginalLot.Info.IsRecycle ? singleton_2(createElement(UndoRecycle_ByLotId, {
                    lotId: state.OriginalLot.LotId,
                })) : empty_1(), delay(() => {
                    let props_5;
                    return append(singleton_2((props_5 = toList(delay(() => append(singleton_2(["className", "is-danger"]), delay(() => append(singleton_2(["children", t("auction.correctMaxBid")]), delay(() => append((state.OriginalLot.Info.IsRecycle ? true : state.OriginalLot.Info.IsReturned) ? singleton_2(["disabled", true]) : empty_1(), delay(() => singleton_2(["onClick", (_arg1_1) => {
                        setCorrectBidVisible(!isCorrectBidVisible);
                    }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_5))))), delay(() => {
                        let props_8, props_10, props_14, props_16;
                        let buttonPublish;
                        const props_12 = ofArray([["disabled", state.OriginalLot.Info.IsPublish], ["onClick", (_arg2) => {
                            if (!state.OriginalLot.Info.IsPublish) {
                                dispatch(new Msg(27, new AsyncOperationStatus$1(0)));
                            }
                        }], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-medium"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-gavel"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_8)))), (props_10 = singleton_1(["children", t("auction.publishLot")]), createElement("span", createObj(Helpers_combineClasses("", props_10))))])]]);
                        buttonPublish = createElement("a", createObj(Helpers_combineClasses("button", props_12)));
                        switch (auctionStatus.tag) {
                            case 1: {
                                return append(singleton_2((props_14 = toList(delay(() => append(singleton_2(["className", "is-danger"]), delay(() => append(state.OriginalLot.Info.IsWithdraw ? singleton_2(["disabled", true]) : empty_1(), delay(() => append(singleton_2(["children", t("auction.withdraw")]), delay(() => singleton_2(["onClick", (_arg3) => {
                                    setWithdrawVisible(!isWithdrawVisible);
                                }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_14))))), delay(() => singleton_2(buttonPublish)));
                            }
                            case 2: {
                                return append(singleton_2((props_16 = toList(delay(() => append(singleton_2(["className", "is-danger"]), delay(() => append(isRemoving ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["children", t("auction.deleteLot")]), delay(() => singleton_2(["onClick", (_arg4) => {
                                    setDeleteVisible(!isDeleteVisible);
                                }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_16))))), delay(() => singleton_2(buttonPublish)));
                            }
                            default: {
                                return singleton_2(null);
                            }
                        }
                    }));
                }))))));
            })))),
        })) : empty_1(), delay(() => {
            let props_22, props_24, props_26;
            return append(singleton_2(createElement("div", {
                children: Interop_reactApi.Children.toArray([(props_22 = toList(delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["tabIndex", -1]), delay(() => {
                    let matchValue_1, s_3, s_2;
                    return append(singleton_2(["href", (matchValue_1 = state.AuctionPageAndFilterState, (matchValue_1.tag === 1) ? ((s_3 = matchValue_1.fields[2], toPath(new Route(2, new AuctionArg(1, state.AuctionId, new AuctionResellLotState(s_3.IsUnsoldFilterOn, s_3.IsClaimedOrRefundedFilterOn, s_3.IsWithDrawnFilterOn, s_3.Page)))))) : ((s_2 = matchValue_1.fields[2], toPath(new Route(2, new AuctionArg(0, state.AuctionId, new AuctionState(s_2.IsUnsoldFilterOn, s_2.IsIncompleteFilterOn, s_2.Page)))))))]), delay(() => {
                        let elms, s_4;
                        return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                            className: join(" ", ["fa", "fa-chevron-left"]),
                        })), createElement("span", {
                            className: "icon",
                            children: Interop_reactApi.Children.toArray(Array.from(elms)),
                        })), (s_4 = t("auction.backToAuction"), createElement("span", {
                            className: "",
                            children: s_4,
                        }))])]);
                    }));
                })))))), createElement("a", createObj(Helpers_combineClasses("button", props_22)))), (props_24 = toList(delay(() => append(singleton_2(["className", "has-background-grey-lighter"]), delay(() => append(moreOptionsVisible ? singleton_2(["children", t("auction.hideActions")]) : singleton_2(["children", t("auction.moreActions")]), delay(() => singleton_2(["onClick", (_arg5) => {
                    patternInput_3[1](!moreOptionsVisible);
                }]))))))), createElement("a", createObj(Helpers_combineClasses("button", props_24)))), (props_26 = toList(delay(() => append(isRemoving ? singleton_2(["disabled", true]) : empty_1(), delay(() => append((state.OriginalLot.Info.IsRecycle ? true : state.OriginalLot.Info.IsReturned) ? singleton_2(["disabled", true]) : empty_1(), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["children", t("common.edit")]), delay(() => singleton_2(["onClick", (x_2) => {
                    x_2.preventDefault();
                    dispatch(new Msg(19));
                }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_26)))), createElement(PrintLotLabel, state)]),
            })), delay(() => {
                let props_29, props_31;
                return append(isCorrectBidVisible ? singleton_2((props_29 = [new Feliz_ConfirmationWithCommentProperty(0, t("auction.correctMaxBid")), new Feliz_ConfirmationWithCommentProperty(1, t("auction.correctMaxBidConfirmation")), new Feliz_ConfirmationWithCommentProperty(6, (comment) => singleton.Delay(() => singleton.Bind(lotApi().correctLimitedBid([state.OriginalLot.LotId, comment]), () => {
                    setCorrectBidVisible(false);
                    return singleton.Zero();
                }))), new Feliz_ConfirmationWithCommentProperty(2, t("auction.correctMaxBid")), new Feliz_ConfirmationWithCommentProperty(7, () => {
                    setCorrectBidVisible(false);
                })], (props_31 = fold((props_30, oneProp) => {
                    switch (oneProp.tag) {
                        case 1: {
                            return new Component_Props(props_30.DialogCaption, oneProp.fields[0], props_30.SuccessText, props_30.CancelText, props_30.CommentText, props_30.Comment, props_30.OnSuccess, props_30.OnCancel, props_30.Key, props_30.IsVisible);
                        }
                        case 2: {
                            return new Component_Props(props_30.DialogCaption, props_30.Title, oneProp.fields[0], props_30.CancelText, props_30.CommentText, props_30.Comment, props_30.OnSuccess, props_30.OnCancel, props_30.Key, props_30.IsVisible);
                        }
                        case 3: {
                            return new Component_Props(props_30.DialogCaption, props_30.Title, props_30.SuccessText, oneProp.fields[0], props_30.CommentText, props_30.Comment, props_30.OnSuccess, props_30.OnCancel, props_30.Key, props_30.IsVisible);
                        }
                        case 4: {
                            return new Component_Props(props_30.DialogCaption, props_30.Title, props_30.SuccessText, props_30.CancelText, oneProp.fields[0], props_30.Comment, props_30.OnSuccess, props_30.OnCancel, props_30.Key, props_30.IsVisible);
                        }
                        case 5: {
                            return new Component_Props(props_30.DialogCaption, props_30.Title, props_30.SuccessText, props_30.CancelText, props_30.CommentText, oneProp.fields[0], props_30.OnSuccess, props_30.OnCancel, props_30.Key, props_30.IsVisible);
                        }
                        case 7: {
                            return new Component_Props(props_30.DialogCaption, props_30.Title, props_30.SuccessText, props_30.CancelText, props_30.CommentText, props_30.Comment, props_30.OnSuccess, oneProp.fields[0], props_30.Key, props_30.IsVisible);
                        }
                        case 6: {
                            return new Component_Props(props_30.DialogCaption, props_30.Title, props_30.SuccessText, props_30.CancelText, props_30.CommentText, props_30.Comment, oneProp.fields[0], props_30.OnCancel, props_30.Key, props_30.IsVisible);
                        }
                        case 8: {
                            return new Component_Props(props_30.DialogCaption, props_30.Title, props_30.SuccessText, props_30.CancelText, props_30.CommentText, props_30.Comment, props_30.OnSuccess, props_30.OnCancel, oneProp.fields[0], props_30.IsVisible);
                        }
                        case 9: {
                            return new Component_Props(props_30.DialogCaption, props_30.Title, props_30.SuccessText, props_30.CancelText, props_30.CommentText, props_30.Comment, props_30.OnSuccess, props_30.OnCancel, props_30.Key, oneProp.fields[0]);
                        }
                        default: {
                            return new Component_Props(oneProp.fields[0], props_30.Title, props_30.SuccessText, props_30.CancelText, props_30.CommentText, props_30.Comment, props_30.OnSuccess, props_30.OnCancel, props_30.Key, props_30.IsVisible);
                        }
                    }
                }, Component_Props_get_Default(), props_29), createElement(Component_ConfirmationWithComment, (($value) => { $value.key = $value.Key; return $value; })(props_31))))) : empty_1(), delay(() => {
                    let props_63;
                    return append(singleton_2((props_63 = toList(delay(() => append(isWithdrawVisible ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => append(singleton_2(["style", {
                        overflow: "visible",
                    }]), delay(() => {
                        let props_61, props_40, elms_1, s_5, props_38, elms_2, props_42, props_53, elms_4, props_47, props_45, elms_3, props_49, value_139, props_59, props_55, props_57;
                        return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg7) => {
                            setWithdrawVisible(!isWithdrawVisible);
                        }])))), (props_61 = ofArray([["className", "has-background-light"], ["style", {
                            borderRadius: 4,
                        }], ["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["className", "mx-5"], ["className", "mt-5"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((s_5 = t("auction.withdraw"), createElement("h4", {
                            className: "title is-4",
                            children: s_5,
                        }))), createElement("div", {
                            className: "column",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        })), (props_38 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg8) => {
                            setWithdrawVisible(!isWithdrawVisible);
                        }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_38))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_40)))), (elms_2 = singleton_1((props_42 = ofArray([["className", "my-4"], ["className", "mx-5"], ["children", t("auction.withdrawConfirmation")]]), createElement("span", createObj(Helpers_combineClasses("", props_42))))), createElement("div", {
                            className: "column",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                        })), (props_53 = ofArray([["className", "my-4"], ["className", "mx-5"], ["children", Interop_reactApi.Children.toArray([(elms_4 = ofArray([(props_47 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_45 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.reason")]]), createElement("label", createObj(Helpers_combineClasses("label", props_45))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_47)))), (elms_3 = singleton_1((props_49 = ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["onChange", (ev) => {
                            dispatch(new Msg(24, ev.target.value));
                        }], (value_139 = state.ReasonForWithdraw, ["ref", (e) => {
                            if ((!(e == null)) ? (!equals(e.value, value_139)) : false) {
                                e.value = value_139;
                            }
                        }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_49)))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                        }))]), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_53)))), (props_59 = ofArray([["className", "has-text-right"], ["className", "mx-5"], ["className", "mb-5"], ["children", Interop_reactApi.Children.toArray([(props_55 = ofArray([["className", "mr-3"], ["className", "is-dark"], ["disabled", equals(state.RemoveOrWithdrawOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (_arg9) => {
                            setWithdrawVisible(!isWithdrawVisible);
                        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_55)))), (props_57 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => (equals(state.RemoveOrWithdrawOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : append(singleton_2(["children", t("auction.withdraw")]), delay(() => singleton_2(["onClick", (_arg10) => {
                            dispatch(new Msg(23, new AsyncOperationStatus$1(0)));
                        }])))))))), createElement("button", createObj(Helpers_combineClasses("button", props_57))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_59))))])]]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_61))))])]);
                    })))))), createElement("div", createObj(Helpers_combineClasses("modal", props_63))))), delay(() => {
                        let props_86;
                        return singleton_2((props_86 = toList(delay(() => append(isDeleteVisible ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => append(singleton_2(["style", {
                            overflow: "visible",
                        }]), delay(() => {
                            let props_84, props_73, elms_5, s_6, props_71, elms_6, props_75, props_82, props_78, props_80;
                            return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg11) => {
                                setDeleteVisible(!isDeleteVisible);
                            }])))), (props_84 = ofArray([["className", "has-background-light"], ["style", {
                                borderRadius: 4,
                            }], ["children", Interop_reactApi.Children.toArray([(props_73 = ofArray([["className", "mx-5"], ["className", "mt-5"], ["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_1((s_6 = t("common.delete"), createElement("h4", {
                                className: "title is-4",
                                children: s_6,
                            }))), createElement("div", {
                                className: "column",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                            })), (props_71 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg12) => {
                                setDeleteVisible(!isDeleteVisible);
                            }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_71))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_73)))), (elms_6 = singleton_1((props_75 = ofArray([["className", "my-4"], ["className", "mx-5"], ["children", t("auction.deleteLotConfirmation")]]), createElement("span", createObj(Helpers_combineClasses("", props_75))))), createElement("div", {
                                className: "column",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                            })), (props_82 = ofArray([["className", "has-text-right"], ["className", "mx-5"], ["className", "mb-5"], ["children", Interop_reactApi.Children.toArray([(props_78 = ofArray([["className", "mr-3"], ["className", "is-dark"], ["disabled", equals(state.RemoveOrWithdrawOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (_arg13) => {
                                setDeleteVisible(!isDeleteVisible);
                            }]]), createElement("button", createObj(Helpers_combineClasses("button", props_78)))), (props_80 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => (equals(state.RemoveOrWithdrawOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : append(singleton_2(["children", t("common.delete")]), delay(() => singleton_2(["onClick", (_arg14) => {
                                dispatch(new Msg(22, new AsyncOperationStatus$1(0)));
                                setDeleteVisible(!isDeleteVisible);
                            }])))))))), createElement("button", createObj(Helpers_combineClasses("button", props_80))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_82))))])]]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_84))))])]);
                        })))))), createElement("div", createObj(Helpers_combineClasses("modal", props_86)))));
                    }));
                }));
            }));
        }))));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_9)),
        });
    }
}

export function RenderEditImages(renderEditImagesInputProps) {
    const itemId = renderEditImagesInputProps.itemId;
    const item = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(itemApi().getItem(itemId)))), [itemId]);
    switch (item.tag) {
        case 1: {
            return loaderBig();
        }
        case 3: {
            const value = item.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const result = item.fields[0];
            return createElement(EditImages, {
                itemId: itemId,
                images: result.Info.Images,
                videoLink: result.Info.VideoLink,
            });
        }
        default: {
            return null;
        }
    }
}

export function renderGPSPositionFields(state, dispatch) {
    let elms_1, elms, props_2, props, props_6, props_4, value_9, elms_3;
    const props_22 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([(value_9 = ((state.LotInfo.GpsPosition.Address != null) ? value_566(state.LotInfo.GpsPosition.Address) : ""), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_9)) : false) {
            e.value = value_9;
        }
    }]), ["onChange", (ev) => {
        const x = ev.target.value;
        if (x === "") {
            dispatch(new Msg(9, void 0));
        }
        else {
            dispatch(new Msg(9, x));
        }
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_6))))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = toList(delay(() => {
        let elms_2, props_12, props_10, props_16, props_14, value_31;
        const errors = Form_getFieldError(state.Validation.FormErrors, "GpsMapLink");
        return append(singleton_2((elms_2 = ofArray([(props_12 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.linkToMaps")]]), createElement("label", createObj(Helpers_combineClasses("label", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_12)))), (props_16 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([(value_31 = ((state.LotInfo.GpsPosition.MapLink != null) ? value_566(state.LotInfo.GpsPosition.MapLink) : ""), ["ref", (e_1) => {
            if ((!(e_1 == null)) ? (!equals(e_1.value, value_31)) : false) {
                e_1.value = value_31;
            }
        }]), ["onChange", (ev_1) => {
            const x_1 = ev_1.target.value;
            if (x_1 === "") {
                dispatch(new Msg(10, void 0));
            }
            else {
                dispatch(new Msg(10, x_1));
            }
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_14)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_16))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), delay(() => {
            let props_19;
            return singleton_2((props_19 = ofArray([["className", "is-danger"], ["children", fold_1((s, x_2) => (`${s} ${x_2}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_19)))));
        }));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_22)));
}

export function tryDecimalOrDefault(defaultValue, str) {
    if (isNullOrWhiteSpace(str)) {
        return fromParts(0, 0, 0, false, 0);
    }
    else {
        return defaultArg(Decimal_tryParse(str), defaultValue);
    }
}

export function renderField(label, prefix, value, placeholder, onChange, state, formErrors) {
    const elms_2 = toList(delay(() => {
        let elms_1, props_2, props_10, props_5, props_4, elms, props_12;
        const errors = Form_getFieldError(state.Validation.FormErrors, formErrors);
        return singleton_2((elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_10 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append((!equals(errors, empty_2())) ? singleton_2(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_2(["onValueChange", (x) => {
            onChange(new Decimal(defaultArg(x.floatValue, 0)));
        }]), delay(() => append(singleton_2(["placeholder", placeholder]), delay(() => append(singleton_2(["value", toNumber(value)]), delay(() => (equals(state.SaveOperation, new Deferred$1(1)) ? singleton_2(["disabled", true]) : empty_1()))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_4, singleton_1(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_5)))), (elms = singleton_1(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["children", prefix]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_10)))), (props_12 = ofArray([["className", "is-danger"], ["children", fold_1((s, x_1) => (`${s} ${x_1}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_12))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })));
    }));
    return createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function RenderWithLoadCategories(renderWithLoadCategoriesInputProps) {
    let props_51, props_2, props, props_49, props_40, props_42, props_47, elms, props_44, props_227, props_225, props_221, props_223;
    const auctionStatus = renderWithLoadCategoriesInputProps.auctionStatus;
    const freightSettings = renderWithLoadCategoriesInputProps.freightSettings;
    const lot = renderWithLoadCategoriesInputProps.lot;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(lot, renderWithLoadCategoriesInputProps.catalog, renderWithLoadCategoriesInputProps.auctionState), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const getBasicInfoValue = (field) => Localized_translateTo(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field, state_1.LotInfo.BasicInfo));
    const elms_25 = ofArray([(props_51 = ofArray([["className", "py-2"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "mt-3"], ["className", "pl-0"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-size-6"], ["className", "has-text-weight-light"], ["className", "has-text-grey-light"], ["children", t("auction.editLot")]]), createElement("h1", createObj(Helpers_combineClasses("title", props))))])]]), createElement("h1", createObj(Helpers_combineClasses("title", props_2)))), (props_49 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["className", "is-4"], ["style", {
        display: "flex",
        alignItems: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_8, props_4, props_6;
        return append(singleton_2((props_8 = ofArray([["className", "has-text-grey-dark"], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(singleton_2(["className", "is-size-5"]), delay(() => {
            const text = t("common.itemId");
            return singleton_2(["children", `${text}`]);
        })))), createElement("span", createObj(Helpers_combineClasses("", props_4)))), (props_6 = ofArray([["className", "is-size-4"], ["className", "has-text-weight-bold"], ["children", `${lot.ItemId}`]]), createElement("span", createObj(Helpers_combineClasses("", props_6))))])]]), createElement("span", createObj(Helpers_combineClasses("", props_8))))), delay(() => {
            let props_14, props_12, props_10, props_20, props_18, props_16, props_26, props_24, props_22, props_32, props_30, props_28, props_38, props_36, props_34;
            return lot.Info.IsReturned ? singleton_2((props_14 = ofArray([["className", "py-0"], ["className", "is-narrow"], ["style", {
                display: "flex",
                alignSelf: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "customTag"], ["className", "is-rounded"], ["className", "is-dark"], ["children", t("auction.returnedToSeller")]]), createElement("span", createObj(Helpers_combineClasses("tag", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))) : (lot.Info.IsRecycle ? singleton_2((props_20 = ofArray([["className", "py-0"], ["className", "is-narrow"], ["style", {
                display: "flex",
                alignSelf: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "customTag"], ["className", "is-rounded"], ["className", "is-dark"], ["children", t("auction.recycled")]]), createElement("span", createObj(Helpers_combineClasses("tag", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))) : (lot.Info.IsWithdraw ? singleton_2((props_26 = ofArray([["className", "py-0"], ["className", "is-narrow"], ["style", {
                display: "flex",
                alignSelf: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-rounded"], ["className", "is-danger"], ["children", t("auction.lotStatus.withdrawn")]]), createElement("span", createObj(Helpers_combineClasses("tag", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_26))))) : (lot.Info.IsPublish ? singleton_2((props_32 = ofArray([["className", "py-0"], ["className", "is-narrow"], ["style", {
                display: "flex",
                alignSelf: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([(props_30 = ofArray([["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([(props_28 = ofArray([["className", "is-rounded"], ["className", "is-primary"], ["children", t("auction.lotStatus.biddingStarted")]]), createElement("span", createObj(Helpers_combineClasses("tag", props_28))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_30))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_32))))) : ((!lot.Info.IsPublish) ? singleton_2((props_38 = ofArray([["className", "py-0"], ["className", "is-narrow"], ["style", {
                display: "flex",
                alignSelf: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([(props_36 = ofArray([["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "is-rounded"], ["className", "has-background-grey"], ["children", t("auction.lotStatus.created")]]), createElement("span", createObj(Helpers_combineClasses("tag", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_36))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_38))))) : empty_1()))));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_40)))), (props_42 = ofArray([["className", "is-narrow"], ["style", {
        display: "flex",
        alignItems: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([renderGoToLotButtons(state_1)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_42)))), (props_47 = ofArray([["style", {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        minHeight: 40,
        paddingRight: 0,
    }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_44 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(RenderButtons, {
        state: state_1,
        dispatch: dispatch,
        auctionStatus: auctionStatus,
    })])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_44))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_47))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_49))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_51)))), (props_227 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_225 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_221 = ofArray([["className", "is-8"], ["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
        disabled: !state_1.IsEditMode,
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_81, props_79, props_61, elms_1, props_55, props_53, props_58, props_69, elms_3, props_65, props_63, elms_2, props_71, props_77, props_75;
            return append(singleton_2((props_81 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_79 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_61 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_55 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_53 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.submission")]]), createElement("label", createObj(Helpers_combineClasses("label", props_53))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_55)))), (props_58 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (state_1.IsEditMode ? singleton_2(createElement(SearchSubmission, {
                selected: state_1.LotInfo.SubmissionId,
                onSelect: (arg) => {
                    let tupledArg;
                    dispatch((tupledArg = arg, new Msg(17, tupledArg[0], tupledArg[1], tupledArg[2])));
                },
                onInputRef: (_arg1) => {
                },
                dependencies: [],
                isAutofocus: false,
                isStartSearchOnInit: false,
            })) : singleton_2(createElement("a", {
                style: {
                    fontWeight: 600,
                    textDecoration: "underline",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    paddingTop: ("calc(" + "0.5em - 1px") + ")",
                },
                className: "has-text-grey-dark",
                href: toPath(new Route(22, state_1.LotInfo.SellerId, state_1.LotInfo.SubmissionId, new Submissions_PageType(0))),
                children: `#${state_1.LotInfo.SubmissionId}/ ${state_1.LotInfo.SellerName}`,
            })))))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_58))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_61)))), (props_69 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_65 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_63 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.category")]]), createElement("label", createObj(Helpers_combineClasses("label", props_63))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_65)))), (elms_2 = singleton_1(createElement(SelectCategory, {
                auctionId: state_1.OriginalLot.AuctionId,
                selected: state_1.LotInfo.CategoryId,
                onSelectCategory: (arg_1) => {
                    dispatch(new Msg(0, arg_1));
                },
                dependencies: [state_1.LotInfo.CategoryId],
            })), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_69)))), (props_71 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([createElement(SelectPriority, {
                lotId: void 0,
                setPriority: (arg_2) => {
                    dispatch(new Msg(18, arg_2));
                },
                priority: Priority_fromES(state_1.LotInfo.Priority),
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_71)))), (props_77 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props_75 = ofArray([["style", {
                justifyContent: "flex-end",
            }], ["className", "mt-5"], ["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["className", "has-background-black"], ["className", "has-text-white"], ["className", "has-text-weight-bold"], ["children", state_1.LotNumber]]))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_75))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_77))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_79))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_81))))), delay(() => {
                let props_161, props_89, props_85, props_83, props_87, props_98, props_93, props_91, elms_4, props_95, value_267, props_107, props_102, props_100, elms_5, props_104, value_289, props_159, elms_7, elms_6, props_111, props_109, props_115, props_113, elms_9, elms_8, props_121, props_119, props_125, props_123, elms_11, elms_10, props_131, props_129, props_135, props_133, elms_13, elms_12, props_141, props_139, props_145, props_143, elms_15, elms_14, props_151, props_149, props_155, props_153;
                return append(singleton_2((props_161 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([languageSwitcher(state_1.ContentLanguage, (arg_3) => {
                    dispatch(new Msg(26, arg_3));
                }), (props_89 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_85 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_83 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.title")]]), createElement("label", createObj(Helpers_combineClasses("label", props_83))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_85)))), (props_87 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const name_92 = getBasicInfoValue(new ItemLotBasicInformationField(0));
                    return singleton_2(createElement(SearchItemName, {
                        selected: name_92,
                        onSelect: (arg_4) => {
                            dispatch(new Msg(1, arg_4));
                        },
                        onChange: (arg_5) => {
                            dispatch(new Msg(1, arg_5));
                        },
                        onInputRef: (_arg2) => {
                        },
                        dependencies: [state_1.ContentLanguage],
                        isAutofocus: false,
                        isStartSearchOnInit: false,
                    }));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_87))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_89)))), (props_98 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_93 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_91 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.lotDescription")]]), createElement("label", createObj(Helpers_combineClasses("label", props_91))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_93)))), (elms_4 = singleton_1((props_95 = ofArray([["rows", 2], (value_267 = getBasicInfoValue(new ItemLotBasicInformationField(1)), ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_267)) : false) {
                        e.value = value_267;
                    }
                }]), ["onChange", (ev) => {
                    dispatch(new Msg(2, state_1.ContentLanguage, ev.target.value));
                }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_95))))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_98)))), (props_107 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_102 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_100 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.lotCondition")]]), createElement("label", createObj(Helpers_combineClasses("label", props_100))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_102)))), (elms_5 = singleton_1((props_104 = ofArray([["rows", 1], (value_289 = getBasicInfoValue(new ItemLotBasicInformationField(2)), ["ref", (e_1) => {
                    if ((!(e_1 == null)) ? (!equals(e_1.value, value_289)) : false) {
                        e_1.value = value_289;
                    }
                }]), ["onChange", (ev_1) => {
                    dispatch(new Msg(3, state_1.ContentLanguage, ev_1.target.value));
                }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_104))))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_107)))), (props_159 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_7 = singleton_1((elms_6 = ofArray([(props_111 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_109 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.height")]]), createElement("label", createObj(Helpers_combineClasses("label", props_109))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_111)))), (props_115 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_113 = toList(delay(() => append((state_1.LotInfo.Dimensions.Height != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Height)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.HeightInfo)]), delay(() => singleton_2(["onChange", (ev_2) => {
                    dispatch(new Msg(4, ev_2.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_113)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_115))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                })), (elms_9 = singleton_1((elms_8 = ofArray([(props_121 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_119 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.length")]]), createElement("label", createObj(Helpers_combineClasses("label", props_119))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_121)))), (props_125 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_123 = toList(delay(() => append((state_1.LotInfo.Dimensions.Length != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Length)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.LengthInfo)]), delay(() => singleton_2(["onChange", (ev_3) => {
                    dispatch(new Msg(6, ev_3.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_123)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_125))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                })), (elms_11 = singleton_1((elms_10 = ofArray([(props_131 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_129 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.width")]]), createElement("label", createObj(Helpers_combineClasses("label", props_129))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_131)))), (props_135 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_133 = toList(delay(() => append((state_1.LotInfo.Dimensions.Width != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Width)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.WidthInfo)]), delay(() => singleton_2(["onChange", (ev_4) => {
                    dispatch(new Msg(5, ev_4.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_133)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_135))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                })), (elms_13 = singleton_1((elms_12 = ofArray([(props_141 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_139 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.depth")]]), createElement("label", createObj(Helpers_combineClasses("label", props_139))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_141)))), (props_145 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_143 = toList(delay(() => append((state_1.LotInfo.Dimensions.Depth != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Depth)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.DepthInfo)]), delay(() => singleton_2(["onChange", (ev_5) => {
                    dispatch(new Msg(7, ev_5.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_143)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_145))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
                })), (elms_15 = singleton_1((elms_14 = ofArray([(props_151 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_149 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.diameter")]]), createElement("label", createObj(Helpers_combineClasses("label", props_149))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_151)))), (props_155 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_153 = toList(delay(() => append((state_1.LotInfo.Dimensions.Diameter != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Diameter)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.DiameterInfo)]), delay(() => singleton_2(["onChange", (ev_6) => {
                    dispatch(new Msg(8, ev_6.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_153)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_155))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
                }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_159)))), renderGPSPositionFields(state_1, dispatch)])]]), createElement("section", createObj(Helpers_combineClasses("section", props_161))))), delay(() => {
                    let props_209, props_203, elms_18, elms_17, props_165, props_163, props_174, props_169, props_167, elms_16, props_171, elms_21, elms_20, props_180, props_178, props_189, props_184, props_182, elms_19, props_186, elms_23, props_200, props_195, props_193, elms_22, props_207;
                    return append(singleton_2((props_209 = ofArray([["className", "py-1"], ["className", "px-0"], ["className", "has-background-grey-lighter"], ["style", {
                        borderRadius: 4,
                    }], ["children", Interop_reactApi.Children.toArray([(props_203 = ofArray([["style", {
                        margin: 0,
                    }], ["children", Interop_reactApi.Children.toArray([(elms_18 = singleton_1((elms_17 = ofArray([(props_165 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_163 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.estimatedPrice")]]), createElement("label", createObj(Helpers_combineClasses("label", props_163))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_165)))), (props_174 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_169 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_167 = toList(delay(() => append(state_1.IsEditMode ? singleton_2(["placeholder", "200"]) : empty_1(), delay(() => {
                        let value_436;
                        return append(singleton_2((value_436 = state_1.LotInfo.Fee.EstimatedPrice, ["ref", (e_2) => {
                            if ((!(e_2 == null)) ? (!equals(e_2.value, value_436)) : false) {
                                e_2.value = value_436;
                            }
                        }])), delay(() => singleton_2(["onChange", (ev_7) => {
                            dispatch(new Msg(11, Currency_tryParseOrDefault(ev_7.target.value)));
                        }])));
                    })))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_167)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_169)))), (elms_16 = singleton_1((props_171 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_171))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_174))))]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
                    }))), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
                    })), (elms_21 = singleton_1((elms_20 = ofArray([(props_180 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_178 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.reservedPrice")]]), createElement("label", createObj(Helpers_combineClasses("label", props_178))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_180)))), (props_189 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_184 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_182 = toList(delay(() => append(state_1.IsEditMode ? singleton_2(["placeholder", "200"]) : empty_1(), delay(() => {
                        let value_472;
                        return append(singleton_2((value_472 = ReservedPriceModule_toString(state_1.LotInfo.Fee.ReservedPrice), ["ref", (e_3) => {
                            if ((!(e_3 == null)) ? (!equals(e_3.value, value_472)) : false) {
                                e_3.value = value_472;
                            }
                        }])), delay(() => singleton_2(["onChange", (ev_8) => {
                            dispatch(new Msg(12, ReservedPrice_tryParseOrDefault(ev_8.target.value)));
                        }])));
                    })))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_182)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_184)))), (elms_19 = singleton_1((props_186 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_186))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_189))))]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
                    }))), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
                    })), (elms_23 = singleton_1((props_200 = ofArray([["style", {
                        display: "inline-table",
                    }], ["children", Interop_reactApi.Children.toArray([(props_195 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_193 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.resaleRights")]]), createElement("label", createObj(Helpers_combineClasses("label", props_193))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_195)))), (elms_22 = ofArray([createElement("input", {
                        disabled: !state_1.IsEditMode,
                        className: "is-checkradio is-large has-background-color is-white",
                        id: "exampleCheckboxMedium",
                        type: "checkbox",
                        name: "exampleCheckboxMedium",
                        checked: state_1.LotInfo.Fee.HasRoyalties,
                        onChange: (ev_9) => {
                            dispatch(new Msg(14, ev_9.target.checked));
                        },
                    }), createElement("label", {
                        htmlFor: "exampleCheckboxMedium",
                    })]), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_200))))), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_203)))), (props_207 = ofArray([["style", {
                        margin: 0,
                    }], ["children", Interop_reactApi.Children.toArray([renderField(t("orders.buyerCommission"), "%", PercentModule_toDecimal(state_1.LotInfo.Fee.PurchaseCommission), t("orders.buyerCommission"), (arg_17) => {
                        dispatch(new Msg(15, PercentModule_fromDecimal(arg_17)));
                    }, state_1, "BuyerCommission"), renderField(t("auction.hammerFee"), Currency_currencySymbol(), CurrencyModule_toDecimal(state_1.LotInfo.Fee.Hammer), t("auction.hammerFee"), (arg_19) => {
                        dispatch(new Msg(13, CurrencyModule_fromDecimal(arg_19)));
                    }, state_1, "HammerFee"), createElement("div", createObj(Helpers_combineClasses("column", empty_2())))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_207))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_209))))), delay(() => {
                        let props_213, props_211;
                        return append(singleton_2((props_213 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_211 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((zone) => {
                            const freightClass = tryFind_1(zone.DeliveryCostZoneId, state_1.LotInfo.Freight);
                            return (freightClass == null) ? singleton_2(null) : singleton_2(edit(zone, value_566(freightClass), (x_8) => {
                                dispatch(new Msg(16, zone.DeliveryCostZoneId, x_8));
                            }));
                        }, freightSettings)))))]), createElement("div", createObj(Helpers_combineClasses("columns", props_211))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_213))))), delay(() => {
                            let props_218, elms_24, props_215;
                            return state_1.IsEditMode ? singleton_2((props_218 = ofArray([["className", "py-2"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(elms_24 = singleton_1((props_215 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(RenderButtons, {
                                state: state_1,
                                dispatch: dispatch,
                                auctionStatus: auctionStatus,
                            })])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_215))))), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_24)),
                            }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_218))))) : empty_1();
                        }));
                    }));
                }));
            }));
        })))),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_221)))), (props_223 = ofArray([["className", "has-background-white"], ["style", {
        borderRadius: 4,
        marginLeft: 10,
    }], ["children", Interop_reactApi.Children.toArray([createElement(RenderEditImages, {
        itemId: state_1.OriginalLot.ItemId,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_223))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_225))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_227))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_25)),
    });
}

export function Render(renderInputProps) {
    const arg = renderInputProps.arg;
    const lotId = (arg.tag === 1) ? arg.fields[1] : arg.fields[1];
    const item = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotApi().getLot(lotId)), (_arg1) => {
        const lot = _arg1;
        return singleton.Bind(Remoting_handleNonAuth(auctionApi().getCategories(lot.AuctionId)), (_arg2) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getFreightSettings()), (_arg3) => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAuction(lot.AuctionId)), (_arg4) => singleton.Return({
            AuctionStatus: _arg4.Status,
            Categories: _arg2,
            FreightSettings: _arg3,
            Lot: lot,
        }))));
    })), [lotId]);
    switch (item.tag) {
        case 1: {
            return loaderBig();
        }
        case 3: {
            const value = item.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const result = item.fields[0];
            return createElement(RenderWithLoadCategories, {
                lot: result.Lot,
                catalog: result.Categories,
                freightSettings: result.FreightSettings,
                auctionStatus: result.AuctionStatus,
                auctionState: arg,
            });
        }
        default: {
            return null;
        }
    }
}

