import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { User_SystemUser, Errors_ServerErrorModule_explain, Errors_ServerError, User_RegisterSellerCompany, User_ContactPersonInfo, User_Address, User_BankInfo, User_SellerCompanyInfo, Errors_ServerError$reflection, User_RegisterSellerCompany$reflection } from "../Shared/Shared.js";
import { record_type, bool_type, class_type, list_type, union_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Remoting_handleNonAuth, AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { PhoneModule_toString, PhoneModule_ofString, EmailModule_toString, EmailModule_ofString, PhoneModule_empty, CountryCodeModule_empty, EmailModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi, commonApi } from "../Communication.js";
import { value as value_861 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { CountriesInput_getSortedList, Form_getFieldError, Form_validationErrorsCompany } from "../Common.js";
import { Route, toPath, Route_AccountDetailsSubmissions_7639071A, navigateTo } from "../Router.js";
import { CountryModule_shortCode } from "../bidflow/src/infrastructure/Countries.js";
import { append as append_1, map, empty as empty_2, singleton as singleton_2, cons, isEmpty, ofArray, fold } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "../Localization.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { dividedTop } from "../Components/BorderDividedLine.js";
import { isCurrentUserAdmin } from "../LocalStorage.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loaderBig } from "../Loader.js";

export class State extends Record {
    constructor(User, ResidentCountry, FormErrors, FormValidation, Registration) {
        super();
        this.User = User;
        this.ResidentCountry = ResidentCountry;
        this.FormErrors = FormErrors;
        this.FormValidation = FormValidation;
        this.Registration = Registration;
    }
}

export function State$reflection() {
    return record_type("CompanyCustomer.State", [], State, () => [["User", User_RegisterSellerCompany$reflection()], ["ResidentCountry", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["FormValidation", bool_type], ["Registration", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", Errors_ServerError$reflection()]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadResidentCountry", "CompanyNameChanged", "OrganizationNumberChanged", "VatNumberChanged", "EmailChanged", "NonExistentEmailChanged", "PasswordChanged", "PasswordConfirmationChanged", "ZipCodeChanged", "AddressChanged", "CityChanged", "CountryChanged", "IsVATDisabledChanged", "ClearingNumberChanged", "BankNumberChanged", "BankChanged", "IbanCountryChanged", "IbanChanged", "CommentChanged", "FirstNameChanged", "LastNameChanged", "PersonalPhoneChanged", "PersonalEmailChanged", "AvoidWarehouseFeeChanged", "RegisterAccount"];
    }
}

export function Msg$reflection() {
    return union_type("CompanyCustomer.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", Errors_ServerError$reflection()]]]))]]]);
}

export function init() {
    return [new State(new User_RegisterSellerCompany(EmailModule_empty, false, false, "", "", new User_SellerCompanyInfo("", "", false, ""), new User_BankInfo("", "", "", "", CountryCodeModule_empty, ""), new User_Address("", "", "", CountryCodeModule_empty), new User_ContactPersonInfo("", "", PhoneModule_empty, EmailModule_empty)), new Deferred$1(0), empty(), false, new Deferred$1(0)), Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0)))];
}

export function loadResidentCountry() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg1) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.ResidentCountry)))))), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function register(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(usersApi().registerSellerCompany(state.User)), (_arg1) => {
        const result = _arg1;
        return (result.tag === 1) ? singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))) : singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(0, result.fields[0]))));
    })), (_arg2) => singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message)))))));
}

export function update(msg, state) {
    let inputRecord_1, inputRecord_3, inputRecord_5, inputRecord_7, inputRecord_11, inputRecord_13, inputRecord_15, inputRecord_17, inputRecord_18, inputRecord_19, inputRecord_21, inputRecord_23, inputRecord_25, inputRecord_27, inputRecord_29, inputRecord_31, inputRecord_33, inputRecord_35, inputRecord_37, inputRecord_39;
    const residentCountry = (stateResidentCountry) => {
        let pattern_matching_result, result;
        if (stateResidentCountry.tag === 1) {
            pattern_matching_result = 0;
        }
        else if (stateResidentCountry.tag === 2) {
            if (stateResidentCountry.fields[0].tag === 0) {
                pattern_matching_result = 2;
                result = stateResidentCountry.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return CountryCodeModule_empty;
            }
            case 1: {
                return CountryCodeModule_empty;
            }
            case 2: {
                return result;
            }
        }
    };
    if (msg.tag === 1) {
        let newState;
        const inputRecord = state.User;
        newState = (new User_RegisterSellerCompany(inputRecord.Email, inputRecord.NonExistentEmail, inputRecord.NeedAvoidWarehouseFee, inputRecord.Password, inputRecord.PasswordConfirmation, (inputRecord_1 = state.User.CompanyInfo, new User_SellerCompanyInfo(msg.fields[0], inputRecord_1.OrganizationNumber, inputRecord_1.IsVATDisabled, inputRecord_1.VatNumber)), inputRecord.BankInfo, inputRecord.Address, inputRecord.ContactPerson));
        return [new State(newState, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 2) {
        let newState_1;
        const inputRecord_2 = state.User;
        newState_1 = (new User_RegisterSellerCompany(inputRecord_2.Email, inputRecord_2.NonExistentEmail, inputRecord_2.NeedAvoidWarehouseFee, inputRecord_2.Password, inputRecord_2.PasswordConfirmation, (inputRecord_3 = state.User.CompanyInfo, new User_SellerCompanyInfo(inputRecord_3.Name, msg.fields[0], inputRecord_3.IsVATDisabled, inputRecord_3.VatNumber)), inputRecord_2.BankInfo, inputRecord_2.Address, inputRecord_2.ContactPerson));
        return [new State(newState_1, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_1, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 3) {
        let newState_2;
        const inputRecord_4 = state.User;
        newState_2 = (new User_RegisterSellerCompany(inputRecord_4.Email, inputRecord_4.NonExistentEmail, inputRecord_4.NeedAvoidWarehouseFee, inputRecord_4.Password, inputRecord_4.PasswordConfirmation, (inputRecord_5 = state.User.CompanyInfo, new User_SellerCompanyInfo(inputRecord_5.Name, inputRecord_5.OrganizationNumber, inputRecord_5.IsVATDisabled, msg.fields[0])), inputRecord_4.BankInfo, inputRecord_4.Address, inputRecord_4.ContactPerson));
        return [new State(newState_2, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_2, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 4) {
        let newState_3;
        const inputRecord_6 = state.User;
        newState_3 = (new User_RegisterSellerCompany(msg.fields[0], inputRecord_6.NonExistentEmail, inputRecord_6.NeedAvoidWarehouseFee, inputRecord_6.Password, inputRecord_6.PasswordConfirmation, inputRecord_6.CompanyInfo, inputRecord_6.BankInfo, inputRecord_6.Address, inputRecord_6.ContactPerson));
        return [new State(newState_3, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_3, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new State((inputRecord_7 = state.User, new User_RegisterSellerCompany(inputRecord_7.Email, msg.fields[0], inputRecord_7.NeedAvoidWarehouseFee, inputRecord_7.Password, inputRecord_7.PasswordConfirmation, inputRecord_7.CompanyInfo, inputRecord_7.BankInfo, inputRecord_7.Address, inputRecord_7.ContactPerson)), state.ResidentCountry, state.FormErrors, state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 6) {
        let newState_5;
        const inputRecord_8 = state.User;
        newState_5 = (new User_RegisterSellerCompany(inputRecord_8.Email, inputRecord_8.NonExistentEmail, inputRecord_8.NeedAvoidWarehouseFee, msg.fields[0], inputRecord_8.PasswordConfirmation, inputRecord_8.CompanyInfo, inputRecord_8.BankInfo, inputRecord_8.Address, inputRecord_8.ContactPerson));
        return [new State(newState_5, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_5, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 7) {
        let newState_6;
        const inputRecord_9 = state.User;
        newState_6 = (new User_RegisterSellerCompany(inputRecord_9.Email, inputRecord_9.NonExistentEmail, inputRecord_9.NeedAvoidWarehouseFee, inputRecord_9.Password, msg.fields[0], inputRecord_9.CompanyInfo, inputRecord_9.BankInfo, inputRecord_9.Address, inputRecord_9.ContactPerson));
        return [new State(newState_6, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_6, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 8) {
        let newState_7;
        const inputRecord_10 = state.User;
        newState_7 = (new User_RegisterSellerCompany(inputRecord_10.Email, inputRecord_10.NonExistentEmail, inputRecord_10.NeedAvoidWarehouseFee, inputRecord_10.Password, inputRecord_10.PasswordConfirmation, inputRecord_10.CompanyInfo, inputRecord_10.BankInfo, (inputRecord_11 = state.User.Address, new User_Address(inputRecord_11.Address, msg.fields[0], inputRecord_11.City, inputRecord_11.Country)), inputRecord_10.ContactPerson));
        return [new State(newState_7, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_7, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 9) {
        let newState_8;
        const inputRecord_12 = state.User;
        newState_8 = (new User_RegisterSellerCompany(inputRecord_12.Email, inputRecord_12.NonExistentEmail, inputRecord_12.NeedAvoidWarehouseFee, inputRecord_12.Password, inputRecord_12.PasswordConfirmation, inputRecord_12.CompanyInfo, inputRecord_12.BankInfo, (inputRecord_13 = state.User.Address, new User_Address(msg.fields[0], inputRecord_13.ZipCode, inputRecord_13.City, inputRecord_13.Country)), inputRecord_12.ContactPerson));
        return [new State(newState_8, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_8, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 10) {
        let newState_9;
        const inputRecord_14 = state.User;
        newState_9 = (new User_RegisterSellerCompany(inputRecord_14.Email, inputRecord_14.NonExistentEmail, inputRecord_14.NeedAvoidWarehouseFee, inputRecord_14.Password, inputRecord_14.PasswordConfirmation, inputRecord_14.CompanyInfo, inputRecord_14.BankInfo, (inputRecord_15 = state.User.Address, new User_Address(inputRecord_15.Address, inputRecord_15.ZipCode, msg.fields[0], inputRecord_15.Country)), inputRecord_14.ContactPerson));
        return [new State(newState_9, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_9, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 11) {
        let newState_10;
        const inputRecord_16 = state.User;
        newState_10 = (new User_RegisterSellerCompany(inputRecord_16.Email, inputRecord_16.NonExistentEmail, inputRecord_16.NeedAvoidWarehouseFee, inputRecord_16.Password, inputRecord_16.PasswordConfirmation, inputRecord_16.CompanyInfo, inputRecord_16.BankInfo, (inputRecord_17 = state.User.Address, new User_Address(inputRecord_17.Address, inputRecord_17.ZipCode, inputRecord_17.City, msg.fields[0])), inputRecord_16.ContactPerson));
        return [new State(newState_10, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_10, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [new State((inputRecord_18 = state.User, new User_RegisterSellerCompany(inputRecord_18.Email, inputRecord_18.NonExistentEmail, inputRecord_18.NeedAvoidWarehouseFee, inputRecord_18.Password, inputRecord_18.PasswordConfirmation, (inputRecord_19 = state.User.CompanyInfo, new User_SellerCompanyInfo(inputRecord_19.Name, inputRecord_19.OrganizationNumber, msg.fields[0], inputRecord_19.VatNumber)), inputRecord_18.BankInfo, inputRecord_18.Address, inputRecord_18.ContactPerson)), state.ResidentCountry, state.FormErrors, state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 13) {
        let newState_12;
        const inputRecord_20 = state.User;
        newState_12 = (new User_RegisterSellerCompany(inputRecord_20.Email, inputRecord_20.NonExistentEmail, inputRecord_20.NeedAvoidWarehouseFee, inputRecord_20.Password, inputRecord_20.PasswordConfirmation, inputRecord_20.CompanyInfo, (inputRecord_21 = state.User.BankInfo, new User_BankInfo(msg.fields[0], inputRecord_21.BankNumber, inputRecord_21.Bank, inputRecord_21.Comment, inputRecord_21.IbanCountry, inputRecord_21.Iban)), inputRecord_20.Address, inputRecord_20.ContactPerson));
        return [new State(newState_12, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_12, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 14) {
        let newState_13;
        const inputRecord_22 = state.User;
        newState_13 = (new User_RegisterSellerCompany(inputRecord_22.Email, inputRecord_22.NonExistentEmail, inputRecord_22.NeedAvoidWarehouseFee, inputRecord_22.Password, inputRecord_22.PasswordConfirmation, inputRecord_22.CompanyInfo, (inputRecord_23 = state.User.BankInfo, new User_BankInfo(inputRecord_23.ClearingNumber, msg.fields[0], inputRecord_23.Bank, inputRecord_23.Comment, inputRecord_23.IbanCountry, inputRecord_23.Iban)), inputRecord_22.Address, inputRecord_22.ContactPerson));
        return [new State(newState_13, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_13, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 15) {
        let newState_14;
        const inputRecord_24 = state.User;
        newState_14 = (new User_RegisterSellerCompany(inputRecord_24.Email, inputRecord_24.NonExistentEmail, inputRecord_24.NeedAvoidWarehouseFee, inputRecord_24.Password, inputRecord_24.PasswordConfirmation, inputRecord_24.CompanyInfo, (inputRecord_25 = state.User.BankInfo, new User_BankInfo(inputRecord_25.ClearingNumber, inputRecord_25.BankNumber, msg.fields[0], inputRecord_25.Comment, inputRecord_25.IbanCountry, inputRecord_25.Iban)), inputRecord_24.Address, inputRecord_24.ContactPerson));
        return [new State(newState_14, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_14, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 16) {
        let newState_15;
        const inputRecord_26 = state.User;
        newState_15 = (new User_RegisterSellerCompany(inputRecord_26.Email, inputRecord_26.NonExistentEmail, inputRecord_26.NeedAvoidWarehouseFee, inputRecord_26.Password, inputRecord_26.PasswordConfirmation, inputRecord_26.CompanyInfo, (inputRecord_27 = state.User.BankInfo, new User_BankInfo(inputRecord_27.ClearingNumber, inputRecord_27.BankNumber, inputRecord_27.Bank, inputRecord_27.Comment, msg.fields[0], inputRecord_27.Iban)), inputRecord_26.Address, inputRecord_26.ContactPerson));
        return [new State(newState_15, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_15, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 17) {
        let newState_16;
        const inputRecord_28 = state.User;
        newState_16 = (new User_RegisterSellerCompany(inputRecord_28.Email, inputRecord_28.NonExistentEmail, inputRecord_28.NeedAvoidWarehouseFee, inputRecord_28.Password, inputRecord_28.PasswordConfirmation, inputRecord_28.CompanyInfo, (inputRecord_29 = state.User.BankInfo, new User_BankInfo(inputRecord_29.ClearingNumber, inputRecord_29.BankNumber, inputRecord_29.Bank, inputRecord_29.Comment, inputRecord_29.IbanCountry, msg.fields[0])), inputRecord_28.Address, inputRecord_28.ContactPerson));
        return [new State(newState_16, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_16, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 18) {
        let newState_17;
        const inputRecord_30 = state.User;
        newState_17 = (new User_RegisterSellerCompany(inputRecord_30.Email, inputRecord_30.NonExistentEmail, inputRecord_30.NeedAvoidWarehouseFee, inputRecord_30.Password, inputRecord_30.PasswordConfirmation, inputRecord_30.CompanyInfo, (inputRecord_31 = state.User.BankInfo, new User_BankInfo(inputRecord_31.ClearingNumber, inputRecord_31.BankNumber, inputRecord_31.Bank, msg.fields[0], inputRecord_31.IbanCountry, inputRecord_31.Iban)), inputRecord_30.Address, inputRecord_30.ContactPerson));
        return [new State(newState_17, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_17, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 19) {
        let newState_18;
        const inputRecord_32 = state.User;
        newState_18 = (new User_RegisterSellerCompany(inputRecord_32.Email, inputRecord_32.NonExistentEmail, inputRecord_32.NeedAvoidWarehouseFee, inputRecord_32.Password, inputRecord_32.PasswordConfirmation, inputRecord_32.CompanyInfo, inputRecord_32.BankInfo, inputRecord_32.Address, (inputRecord_33 = state.User.ContactPerson, new User_ContactPersonInfo(msg.fields[0], inputRecord_33.LastName, inputRecord_33.Phone, inputRecord_33.Email))));
        return [new State(newState_18, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_18, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 20) {
        let newState_19;
        const inputRecord_34 = state.User;
        newState_19 = (new User_RegisterSellerCompany(inputRecord_34.Email, inputRecord_34.NonExistentEmail, inputRecord_34.NeedAvoidWarehouseFee, inputRecord_34.Password, inputRecord_34.PasswordConfirmation, inputRecord_34.CompanyInfo, inputRecord_34.BankInfo, inputRecord_34.Address, (inputRecord_35 = state.User.ContactPerson, new User_ContactPersonInfo(inputRecord_35.FirstName, msg.fields[0], inputRecord_35.Phone, inputRecord_35.Email))));
        return [new State(newState_19, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_19, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 21) {
        let newState_20;
        const inputRecord_36 = state.User;
        newState_20 = (new User_RegisterSellerCompany(inputRecord_36.Email, inputRecord_36.NonExistentEmail, inputRecord_36.NeedAvoidWarehouseFee, inputRecord_36.Password, inputRecord_36.PasswordConfirmation, inputRecord_36.CompanyInfo, inputRecord_36.BankInfo, inputRecord_36.Address, (inputRecord_37 = state.User.ContactPerson, new User_ContactPersonInfo(inputRecord_37.FirstName, inputRecord_37.LastName, msg.fields[0], inputRecord_37.Email))));
        return [new State(newState_20, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_20, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 22) {
        let newState_21;
        const inputRecord_38 = state.User;
        newState_21 = (new User_RegisterSellerCompany(inputRecord_38.Email, inputRecord_38.NonExistentEmail, inputRecord_38.NeedAvoidWarehouseFee, inputRecord_38.Password, inputRecord_38.PasswordConfirmation, inputRecord_38.CompanyInfo, inputRecord_38.BankInfo, inputRecord_38.Address, (inputRecord_39 = state.User.ContactPerson, new User_ContactPersonInfo(inputRecord_39.FirstName, inputRecord_39.LastName, inputRecord_39.Phone, msg.fields[0]))));
        return [new State(newState_21, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_21, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 23) {
        let newState_22;
        const inputRecord_40 = state.User;
        newState_22 = (new User_RegisterSellerCompany(inputRecord_40.Email, inputRecord_40.NonExistentEmail, msg.fields[0], inputRecord_40.Password, inputRecord_40.PasswordConfirmation, inputRecord_40.CompanyInfo, inputRecord_40.BankInfo, inputRecord_40.Address, inputRecord_40.ContactPerson));
        return [new State(newState_22, state.ResidentCountry, value_861(Form_validationErrorsCompany(residentCountry(state.ResidentCountry), newState_22, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 24) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.User, state.ResidentCountry, state.FormErrors, state.FormValidation, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new State(state.User, state.ResidentCountry, state.FormErrors, state.FormValidation, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), navigateTo(Route_AccountDetailsSubmissions_7639071A(msg.fields[0].fields[0].fields[0]))];
            }
        }
        else {
            const newState_23 = new State(state.User, state.ResidentCountry, state.FormErrors, true, state.Registration);
            const errors_21 = Form_validationErrorsCompany(residentCountry(state.ResidentCountry), state.User, newState_23.FormValidation);
            if (value_861(errors_21).Equals(empty())) {
                return [new State(state.User, state.ResidentCountry, value_861(errors_21), true, new Deferred$1(1)), Cmd_fromAsync(register(state))];
            }
            else {
                return [new State(state.User, state.ResidentCountry, value_861(errors_21), true, new Deferred$1(0)), Cmd_none()];
            }
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new State(state.User, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation, state.Registration), Cmd_none()];
        }
        else {
            return [new State(state.User, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation, state.Registration), Cmd_OfFunc_result(new Msg(11, msg.fields[0].fields[0].fields[0]))];
        }
    }
    else {
        return [new State(state.User, new Deferred$1(1), state.FormErrors, state.FormValidation, state.Registration), Cmd_fromAsync(loadResidentCountry())];
    }
}

export function CountryCodeToString(c) {
    if (c === CountryCodeModule_empty) {
        return "-";
    }
    else {
        return CountryModule_shortCode(c);
    }
}

export function ErrorsHelpBlock(errors) {
    const props = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]);
    return createElement("p", createObj(Helpers_combineClasses("help", props)));
}

export function renderError(state) {
    const matchValue = state.Registration;
    let pattern_matching_result, error;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = ofArray([["className", "has-text-centered"], ["className", "has-background-danger"], ["className", "has-text-white"], ["children", Errors_ServerErrorModule_explain(error)]]);
            return createElement("div", createObj(Helpers_combineClasses("box", props)));
        }
        case 1: {
            return null;
        }
    }
}

export function view(state, dispatch) {
    let elms_8, elms_7, props_4, props, props_2, props_44, props_14, props_22, elms_2, props_19, props_17, props_32, props_42, props_159, elms_25, props_52, props_48, props_50, elms_24, props_249, elms_37, props_165, props_161, props_163, elms_36, props_175, props_201, props_187, props_185, props_199, props_197, props_235, props_216, props_214, props_233, children_34, props_245, props_309, elms_49, props_255, props_251, props_253, elms_48, props_281, props_267, props_265, props_279, props_277, props_305, elms_44, props_291, elms_47, props_302, props_329, elms_52, props_315, props_311, props_313, elms_51, props_325, props_323, elms_50, props_320, props_318, props_343, props_341, elms_53, props_338, props_334, props_336;
    const inProgress = equals(state.Registration, new Deferred$1(1));
    const children_56 = ofArray([createElement("fieldset", {
        disabled: inProgress,
        children: Interop_reactApi.Children.toArray([(elms_8 = singleton_2((elms_7 = ofArray([(props_4 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.general")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props)))), (props_2 = ofArray([["style", {
            fontSize: 0.7 + "rem",
        }], ["className", "has-text-grey-light"], ["children", t("users.generalInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_44 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(props_14 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_1, props_8, props_6, elms, props_10;
            const errors = Form_getFieldError(state.FormErrors, "Email");
            return singleton_1((elms_1 = ofArray([(props_8 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.email")]]), createElement("label", createObj(Helpers_combineClasses("label", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_8)))), (elms = singleton_2((props_10 = toList(delay(() => append((!isEmpty(errors)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev) => {
                dispatch(new Msg(4, EmailModule_ofString(ev.target.value)));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterEmail")]), delay(() => {
                let value_42;
                return singleton_1((value_42 = EmailModule_toString(state.User.Email), ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_42)) : false) {
                        e.value = value_42;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_10)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), ErrorsHelpBlock(errors)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_14)))), (props_22 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_19 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
            className: join(" ", ["is-checkradio"]),
            id: "NonExistentEmail",
            type: "checkbox",
            name: "NonExistentEmail",
            onChange: (ev_1) => {
                dispatch(new Msg(5, ev_1.target.checked));
            },
            checked: state.User.NonExistentEmail,
        }), (props_17 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.nonExistentEmail")], ["htmlFor", "NonExistentEmail"]]), createElement("label", createObj(Helpers_combineClasses("label", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_19))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_22)))), (props_32 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_4, props_26, props_24, elms_3, props_28;
            const errors_1 = Form_getFieldError(state.FormErrors, "Password");
            return singleton_1((elms_4 = ofArray([(props_26 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.password")]]), createElement("label", createObj(Helpers_combineClasses("label", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_26)))), (elms_3 = singleton_2((props_28 = toList(delay(() => append((!isEmpty(errors_1)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_2) => {
                dispatch(new Msg(6, ev_2.target.value));
            }]), delay(() => {
                let value_91;
                return singleton_1((value_91 = state.User.Password, ["ref", (e_1) => {
                    if ((!(e_1 == null)) ? (!equals(e_1.value, value_91)) : false) {
                        e_1.value = value_91;
                    }
                }]));
            })))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_28)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            })), ErrorsHelpBlock(errors_1)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_32)))), (props_42 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_6, props_36, props_34, elms_5, props_38;
            const errors_2 = Form_getFieldError(state.FormErrors, "PasswordConfirmation");
            return singleton_1((elms_6 = ofArray([(props_36 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.confirmPassword")]]), createElement("label", createObj(Helpers_combineClasses("label", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_36)))), (elms_5 = singleton_2((props_38 = toList(delay(() => append((!isEmpty(errors_2)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_3) => {
                dispatch(new Msg(7, ev_3.target.value));
            }]), delay(() => {
                let value_116;
                return singleton_1((value_116 = state.User.PasswordConfirmation, ["ref", (e_2) => {
                    if ((!(e_2 == null)) ? (!equals(e_2.value, value_116)) : false) {
                        e_2.value = value_116;
                    }
                }]));
            })))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_38)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            })), ErrorsHelpBlock(errors_2)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_42))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_44))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
        }))), createElement("section", {
            className: "section",
            children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
        })), (props_159 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_25 = ofArray([(props_52 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_48 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.companyInfo")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_48)))), (props_50 = ofArray([["style", {
            fontSize: 0.7 + "rem",
        }], ["className", "has-text-grey-light"], ["children", t("users.companyInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_50))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_52)))), (elms_24 = toList(delay(() => {
            let props_62;
            return append(singleton_1((props_62 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let elms_10, props_56, props_54, elms_9, props_58;
                const errors_3 = Form_getFieldError(state.FormErrors, "CompanyName");
                return singleton_1((elms_10 = ofArray([(props_56 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_54 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.companyName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_54))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_56)))), (elms_9 = singleton_2((props_58 = toList(delay(() => append((!equals(errors_3, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_4) => {
                    dispatch(new Msg(1, ev_4.target.value));
                }]), delay(() => append(singleton_1(["placeholder", t("users.companyNamePlaceholder")]), delay(() => {
                    let value_171;
                    return singleton_1((value_171 = state.User.CompanyInfo.Name, ["ref", (e_3) => {
                        if ((!(e_3 == null)) ? (!equals(e_3.value, value_171)) : false) {
                            e_3.value = value_171;
                        }
                    }]));
                })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_58)))))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                })), ErrorsHelpBlock(errors_3)]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                })));
            }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_62))))), delay(() => {
                let props_88, props_74, props_72, props_86, props_84;
                return append(singleton_1((props_88 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_74 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_72 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_12, props_66, props_64, elms_11, props_68;
                    const errors_4 = Form_getFieldError(state.FormErrors, "OrganizationNumber");
                    return singleton_1((elms_12 = ofArray([(props_66 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_64 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.orgNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_64))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_66)))), (elms_11 = singleton_2((props_68 = toList(delay(() => append((!equals(errors_4, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_5) => {
                        dispatch(new Msg(2, ev_5.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.organizationNumberPlaceholder")]), delay(() => {
                        let value_199;
                        return singleton_1((value_199 = state.User.CompanyInfo.OrganizationNumber, ["ref", (e_4) => {
                            if ((!(e_4 == null)) ? (!equals(e_4.value, value_199)) : false) {
                                e_4.value = value_199;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_68)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                    })), ErrorsHelpBlock(errors_4)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_72))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_74)))), (props_86 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_84 = toList(delay(() => {
                    let elms_14, props_78, props_76, elms_13, props_80;
                    const errors_5 = Form_getFieldError(state.FormErrors, "VatNumber");
                    return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_14 = ofArray([(props_78 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_76 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.vatNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_76))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_78)))), (elms_13 = singleton_2((props_80 = toList(delay(() => append((!equals(errors_5, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_6) => {
                        dispatch(new Msg(3, ev_6.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.vatNumberPlaceholder")]), delay(() => {
                        let value_228;
                        return singleton_1((value_228 = state.User.CompanyInfo.VatNumber, ["ref", (e_5) => {
                            if ((!(e_5 == null)) ? (!equals(e_5.value, value_228)) : false) {
                                e_5.value = value_228;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_80)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
                    })), ErrorsHelpBlock(errors_5)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
                    }))])]);
                })), createElement("div", createObj(Helpers_combineClasses("field", props_84))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_86))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_88))))), delay(() => {
                    let props_114, props_100, props_98, props_112, props_110;
                    return append(singleton_1((props_114 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_100 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_98 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let elms_16, props_92, props_90, elms_15, props_94;
                        const errors_6 = Form_getFieldError(state.FormErrors, "Address");
                        return singleton_1((elms_16 = ofArray([(props_92 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_90 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props_90))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_92)))), (elms_15 = singleton_2((props_94 = toList(delay(() => append((!equals(errors_6, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_7) => {
                            dispatch(new Msg(9, ev_7.target.value));
                        }]), delay(() => append(singleton_1(["placeholder", t("users.enterAddress")]), delay(() => {
                            let value_258;
                            return singleton_1((value_258 = state.User.Address.Address, ["ref", (e_6) => {
                                if ((!(e_6 == null)) ? (!equals(e_6.value, value_258)) : false) {
                                    e_6.value = value_258;
                                }
                            }]));
                        })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_94)))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
                        })), ErrorsHelpBlock(errors_6)]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
                        })));
                    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_98))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_100)))), (props_112 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_110 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let elms_18, props_104, props_102, elms_17, props_106;
                        const errors_7 = Form_getFieldError(state.FormErrors, "ZipCode");
                        return singleton_1((elms_18 = ofArray([(props_104 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_102 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.zipCode")]]), createElement("label", createObj(Helpers_combineClasses("label", props_102))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_104)))), (elms_17 = singleton_2((props_106 = toList(delay(() => append((!equals(errors_7, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_8) => {
                            dispatch(new Msg(8, ev_8.target.value));
                        }]), delay(() => append(singleton_1(["placeholder", t("users.enterZipCode")]), delay(() => {
                            let value_287;
                            return singleton_1((value_287 = state.User.Address.ZipCode, ["ref", (e_7) => {
                                if ((!(e_7 == null)) ? (!equals(e_7.value, value_287)) : false) {
                                    e_7.value = value_287;
                                }
                            }]));
                        })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_106)))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
                        })), ErrorsHelpBlock(errors_7)]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
                        })));
                    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_110))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_112))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_114))))), delay(() => {
                        let props_143, props_126, props_124, props_141, props_139;
                        return append(singleton_1((props_143 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_126 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_124 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let elms_20, props_118, props_116, elms_19, props_120;
                            const errors_8 = Form_getFieldError(state.FormErrors, "City");
                            return singleton_1((elms_20 = ofArray([(props_118 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_116 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.city")]]), createElement("label", createObj(Helpers_combineClasses("label", props_116))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_118)))), (elms_19 = singleton_2((props_120 = toList(delay(() => append((!equals(errors_8, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_9) => {
                                dispatch(new Msg(10, ev_9.target.value));
                            }]), delay(() => append(singleton_1(["placeholder", t("users.enterCity")]), delay(() => {
                                let value_317;
                                return singleton_1((value_317 = state.User.Address.City, ["ref", (e_8) => {
                                    if ((!(e_8 == null)) ? (!equals(e_8.value, value_317)) : false) {
                                        e_8.value = value_317;
                                    }
                                }]));
                            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_120)))))), createElement("div", {
                                className: "field-body",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
                            })), ErrorsHelpBlock(errors_8)]), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
                            })));
                        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_124))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_126)))), (props_141 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_139 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                            let elms_22, props_130, props_128, elms_21, patternInput;
                            const errors_9 = Form_getFieldError(state.FormErrors, "Country");
                            return singleton_1((elms_22 = ofArray([(props_130 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_128 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.country")]]), createElement("label", createObj(Helpers_combineClasses("label", props_128))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_130)))), (elms_21 = singleton_2((patternInput = Helpers_extractClasses(toList(delay(() => append((!equals(errors_9, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(singleton_1(["className", "has-text-left"]), delay(() => append(singleton_1(["className", "mb-1"]), delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["onChange", (ev_10) => {
                                dispatch(new Msg(11, ev_10.target.value));
                            }]), delay(() => {
                                let value_352;
                                return append(singleton_1((value_352 = state.User.Address.Country, ["ref", (e_9) => {
                                    if ((!(e_9 == null)) ? (!equals(e_9.value, value_352)) : false) {
                                        e_9.value = value_352;
                                    }
                                }])), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => createElement("option", {
                                    value: tupledArg[0],
                                    children: tupledArg[1],
                                }), CountriesInput_getSortedList())))])));
                            }))))))))))))))), createElement("div", {
                                className: join(" ", cons("select", patternInput[0])),
                                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
                            }))), createElement("div", {
                                className: "field-body",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
                            })), ErrorsHelpBlock(errors_9)]), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
                            })));
                        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_139))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_141))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_143))))), delay(() => {
                            let props_155, props_153, props_151, elms_23, props_148, props_146;
                            return isCurrentUserAdmin() ? singleton_1((props_155 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_153 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_151 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_23 = singleton_2((props_148 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                                className: join(" ", ["is-checkradio"]),
                                disabled: equals(state.Registration, new Deferred$1(1)),
                                id: "VATCheckbox",
                                type: "checkbox",
                                name: "VATCheckbox",
                                onChange: (ev_11) => {
                                    dispatch(new Msg(12, ev_11.target.checked));
                                },
                                checked: state.User.CompanyInfo.IsVATDisabled,
                            }), (props_146 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.isVATDisabled")], ["htmlFor", "VATCheckbox"]]), createElement("label", createObj(Helpers_combineClasses("label", props_146))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_148))))), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
                            }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_151))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_153))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_155))))) : empty_1();
                        }));
                    }));
                }));
            }));
        })), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_24)),
        }))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_25)),
        }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_159)))), (props_249 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_37 = ofArray([(props_165 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_161 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.bank")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_161)))), (props_163 = ofArray([["style", {
            fontSize: 0.7 + "rem",
        }], ["className", "has-text-grey-light"], ["children", t("users.bankInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_163))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_165)))), (elms_36 = ofArray([(props_175 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_27, props_169, props_167, elms_26, props_171;
            const errors_10 = Form_getFieldError(state.FormErrors, "ClearingNumber");
            return singleton_1((elms_27 = ofArray([(props_169 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_167 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.clearingNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_167))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_169)))), (elms_26 = singleton_2((props_171 = toList(delay(() => append((!equals(errors_10, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_12) => {
                dispatch(new Msg(13, ev_12.target.value));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterClearingNumber")]), delay(() => {
                let value_445;
                return singleton_1((value_445 = state.User.BankInfo.ClearingNumber, ["ref", (e_10) => {
                    if ((!(e_10 == null)) ? (!equals(e_10.value, value_445)) : false) {
                        e_10.value = value_445;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_171)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_26)),
            })), ErrorsHelpBlock(errors_10)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_27)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_175)))), (props_201 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_187 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_185 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_29, props_179, props_177, elms_28, props_181;
            const errors_11 = Form_getFieldError(state.FormErrors, "BankNumber");
            return singleton_1((elms_29 = ofArray([(props_179 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_177 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.accountNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_177))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_179)))), (elms_28 = singleton_2((props_181 = toList(delay(() => append((!equals(errors_11, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_13) => {
                dispatch(new Msg(14, ev_13.target.value));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterAccountNumber")]), delay(() => {
                let value_473;
                return singleton_1((value_473 = state.User.BankInfo.BankNumber, ["ref", (e_11) => {
                    if ((!(e_11 == null)) ? (!equals(e_11.value, value_473)) : false) {
                        e_11.value = value_473;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_181)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_28)),
            })), ErrorsHelpBlock(errors_11)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_29)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_185))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_187)))), (props_199 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_197 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_31, props_191, props_189, elms_30, props_193;
            const errors_12 = Form_getFieldError(state.FormErrors, "Bank");
            return singleton_1((elms_31 = ofArray([(props_191 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_189 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.bank")]]), createElement("label", createObj(Helpers_combineClasses("label", props_189))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_191)))), (elms_30 = singleton_2((props_193 = toList(delay(() => append((!equals(errors_12, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_14) => {
                dispatch(new Msg(15, ev_14.target.value));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterBank")]), delay(() => {
                let value_502;
                return singleton_1((value_502 = state.User.BankInfo.Bank, ["ref", (e_12) => {
                    if ((!(e_12 == null)) ? (!equals(e_12.value, value_502)) : false) {
                        e_12.value = value_502;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_193)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_30)),
            })), ErrorsHelpBlock(errors_12)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_31)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_197))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_199))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_201)))), (props_235 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_216 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_214 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_33, props_205, props_203, elms_32, patternInput_1;
            const errors_13 = Form_getFieldError(state.FormErrors, "IbanCountry");
            return singleton_1((elms_33 = ofArray([(props_205 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_203 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.ibanCountry")]]), createElement("label", createObj(Helpers_combineClasses("label", props_203))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_205)))), (elms_32 = singleton_2((patternInput_1 = Helpers_extractClasses(toList(delay(() => append((!equals(errors_13, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(singleton_1(["className", "has-text-left"]), delay(() => append(singleton_1(["className", "mb-1"]), delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["onChange", (ev_15) => {
                dispatch(new Msg(16, ev_15.target.value));
            }]), delay(() => append(singleton_1(["value", state.User.BankInfo.IbanCountry]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg_1) => createElement("option", {
                value: tupledArg_1[0],
                children: tupledArg_1[1],
            }), append_1(singleton_2([CountryCodeModule_empty, "Select country"]), CountriesInput_getSortedList()))))])))))))))))))))))), createElement("div", {
                className: join(" ", cons("select", patternInput_1[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
            }))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_32)),
            })), ErrorsHelpBlock(errors_13)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_33)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_214))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_216)))), (props_233 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(children_34 = toList(delay(() => {
            let props_220, props_218;
            const errors_14 = Form_getFieldError(state.FormErrors, "IBAN");
            return append(singleton_1((props_220 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_218 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.IBAN")]]), createElement("label", createObj(Helpers_combineClasses("label", props_218))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_220))))), delay(() => {
                let props_230, props_224, props_222, props_228, props_226;
                return append(singleton_1((props_230 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_224 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_222 = ofArray([["className", "is-static"], ["children", CountryCodeToString(state.User.BankInfo.IbanCountry)]]), createElement("a", createObj(Helpers_combineClasses("button", props_222))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_224)))), (props_228 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_226 = toList(delay(() => append((!equals(errors_14, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_16) => {
                    dispatch(new Msg(17, ev_16.target.value));
                }]), delay(() => append(singleton_1(["placeholder", t("users.enterIBAN")]), delay(() => {
                    let value_580;
                    return singleton_1((value_580 = state.User.BankInfo.Iban, ["ref", (e_13) => {
                        if ((!(e_13 == null)) ? (!equals(e_13.value, value_580)) : false) {
                            e_13.value = value_580;
                        }
                    }]));
                })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_226)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_228))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_230))))), delay(() => singleton_1(ErrorsHelpBlock(errors_14))));
            }));
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_34)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_233))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_235)))), (props_245 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_35, props_239, props_237, elms_34, props_241;
            const errors_15 = Form_getFieldError(state.FormErrors, "Comment");
            return singleton_1((elms_35 = ofArray([(props_239 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_237 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.comment")]]), createElement("label", createObj(Helpers_combineClasses("label", props_237))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_239)))), (elms_34 = singleton_2((props_241 = toList(delay(() => append((!equals(errors_15, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_17) => {
                dispatch(new Msg(18, ev_17.target.value));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterComment")]), delay(() => {
                let value_603;
                return singleton_1((value_603 = state.User.BankInfo.Comment, ["ref", (e_14) => {
                    if ((!(e_14 == null)) ? (!equals(e_14.value, value_603)) : false) {
                        e_14.value = value_603;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_241)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_34)),
            })), ErrorsHelpBlock(errors_15)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_35)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_245))))]), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_36)),
        }))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_37)),
        }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_249)))), (props_309 = ofArray([["style", {
            borderTopStyle: "solid",
            borderTopWidth: 1 + "px",
            borderTopColor: "#c4c4c4",
        }], ["children", Interop_reactApi.Children.toArray([(elms_49 = ofArray([(props_255 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_251 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.contactPerson")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_251)))), (props_253 = ofArray([["style", {
            fontSize: 0.7 + "rem",
        }], ["className", "has-text-grey-light"], ["children", t("users.contactPersonHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_253))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_255)))), (elms_48 = ofArray([(props_281 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_267 = ofArray([["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_265 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_39, props_259, props_257, elms_38, props_261;
            const errors_16 = Form_getFieldError(state.FormErrors, "FirstName");
            return singleton_1((elms_39 = ofArray([(props_259 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_257 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.firstName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_257))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_259)))), (elms_38 = singleton_2((props_261 = toList(delay(() => append((!equals(errors_16, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_18) => {
                dispatch(new Msg(19, ev_18.target.value));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterFirstName")]), delay(() => {
                let value_664;
                return singleton_1((value_664 = state.User.ContactPerson.FirstName, ["ref", (e_15) => {
                    if ((!(e_15 == null)) ? (!equals(e_15.value, value_664)) : false) {
                        e_15.value = value_664;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_261)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_38)),
            })), ErrorsHelpBlock(errors_16)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_39)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_265))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_267)))), (props_279 = ofArray([["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_277 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_41, props_271, props_269, elms_40, props_273;
            const errors_17 = Form_getFieldError(state.FormErrors, "LastName");
            return singleton_1((elms_41 = ofArray([(props_271 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_269 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.lastName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_269))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_271)))), (elms_40 = singleton_2((props_273 = toList(delay(() => append((!equals(errors_17, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_19) => {
                dispatch(new Msg(20, ev_19.target.value));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterLastName")]), delay(() => {
                let value_693;
                return singleton_1((value_693 = state.User.ContactPerson.LastName, ["ref", (e_16) => {
                    if ((!(e_16 == null)) ? (!equals(e_16.value, value_693)) : false) {
                        e_16.value = value_693;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_273)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_40)),
            })), ErrorsHelpBlock(errors_17)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_41)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_277))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_279))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_281)))), (props_305 = ofArray([["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(elms_44 = singleton_2((props_291 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_43, props_285, props_283, elms_42, props_287;
            const errors_18 = Form_getFieldError(state.FormErrors, "Phone");
            return singleton_1((elms_43 = ofArray([(props_285 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_283 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.phoneNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_283))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_285)))), (elms_42 = singleton_2((props_287 = toList(delay(() => append((!equals(errors_18, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_20) => {
                dispatch(new Msg(21, PhoneModule_ofString(ev_20.target.value)));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterPhoneNumber")]), delay(() => {
                let value_724;
                return singleton_1((value_724 = PhoneModule_toString(state.User.ContactPerson.Phone), ["ref", (e_17) => {
                    if ((!(e_17 == null)) ? (!equals(e_17.value, value_724)) : false) {
                        e_17.value = value_724;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_287)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_42)),
            })), ErrorsHelpBlock(errors_18)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_43)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_291))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_44)),
        })), (elms_47 = singleton_2((props_302 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_46, props_296, props_294, elms_45, props_298;
            const errors_19 = Form_getFieldError(state.FormErrors, "PersonalEmail");
            return singleton_1((elms_46 = ofArray([(props_296 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_294 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.email")]]), createElement("label", createObj(Helpers_combineClasses("label", props_294))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_296)))), (elms_45 = singleton_2((props_298 = toList(delay(() => append((!equals(errors_19, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_21) => {
                dispatch(new Msg(22, EmailModule_ofString(ev_21.target.value)));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterEmail")]), delay(() => {
                let value_754;
                return singleton_1((value_754 = EmailModule_toString(state.User.ContactPerson.Email), ["ref", (e_18) => {
                    if ((!(e_18 == null)) ? (!equals(e_18.value, value_754)) : false) {
                        e_18.value = value_754;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_298)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_45)),
            })), ErrorsHelpBlock(errors_19)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_46)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_302))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_47)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_305))))]), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_48)),
        }))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_49)),
        }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_309)))), (props_329 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_52 = ofArray([(props_315 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_311 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.userSettings")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_311)))), (props_313 = ofArray([["style", {
            fontSize: 0.7 + "rem",
        }], ["className", "has-text-grey-light"], ["children", t("users.userSettingsHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_313))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_315)))), (elms_51 = singleton_2((props_325 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_323 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_50 = singleton_2((props_320 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
            className: join(" ", ["is-checkradio"]),
            id: "AvoidWarehouseFee",
            type: "checkbox",
            name: "AvoidWarehouseFee",
            onChange: (ev_22) => {
                dispatch(new Msg(23, ev_22.target.checked));
            },
            checked: state.User.NeedAvoidWarehouseFee,
        }), (props_318 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.avoidWarehouseFee")], ["htmlFor", "AvoidWarehouseFee"]]), createElement("label", createObj(Helpers_combineClasses("label", props_318))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_320))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_50)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_323))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_325))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_51)),
        }))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_52)),
        }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_329))))]),
    }), (props_343 = ofArray([["style", {
        borderTopStyle: "solid",
        borderTopWidth: 1 + "px",
        borderTopColor: "#c4c4c4",
    }], ["children", Interop_reactApi.Children.toArray([(props_341 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-one-quarter"])))), (elms_53 = singleton_2((props_338 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_334 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["href", toPath(new Route(7, 1, new User_SystemUser(0), void 0))]]), createElement("a", createObj(Helpers_combineClasses("button", props_334)))), (props_336 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", t("common.create")]), delay(() => append(inProgress ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => singleton_1(["onClick", (_arg1) => {
        dispatch(new Msg(24, new AsyncOperationStatus$1(0)));
    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_336))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_338))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_53)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_341)))), renderError(state)])]]), createElement("section", createObj(Helpers_combineClasses("section", props_343))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_56)),
    });
}

export function Render() {
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(init, (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const matchValue = state_1.ResidentCountry;
    let pattern_matching_result, error;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return loaderBig();
        }
        case 1: {
            return view(state_1, patternInput[1]);
        }
        case 2: {
            const props = ofArray([["style", {
                whiteSpace: "pre-line",
                marginTop: 10,
            }], ["className", "is-danger"], ["children", error]]);
            return createElement("div", createObj(Helpers_combineClasses("notification", props)));
        }
    }
}

