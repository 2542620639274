import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { ItemIdModule_fromString, ItemIdModule_toString, PayOutIdModule_fromString, PayOutIdModule_toString, ItemIdModule_empty, PayOutIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { PayOuts_RemoveItemFromPayoutRequest } from "../Shared/Shared.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { payOutApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton as singleton_2, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export function Component() {
    let elms_6, elms_5, props_3, props_25;
    const patternInput = useFeliz_React__React_useState_Static_1505(PayOutIdModule_empty);
    const payoutId = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(ItemIdModule_empty);
    const itemId = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_2[0];
    const removeItem_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => {
        const request = new PayOuts_RemoveItemFromPayoutRequest(payoutId, itemId, "");
        return singleton.ReturnFrom(Remoting_handleNonAuth(payOutApi().removeItemFromPayout(request)));
    }), patternInput_2[1]);
    const elms_7 = singleton_2((elms_6 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Remove item from payout",
    }), (elms_5 = ofArray([(props_3 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Remove item from payout",
    }), createElement("span", {
        children: ["Note: this will apply for the orders in status \u0027Created\u0027. "],
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_25 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, s_2, elms, props_6, value_25;
        return append(singleton_1((elms_1 = ofArray([(s_2 = (`Utbetalning id = ${payoutId}`), createElement("label", {
            className: "label",
            children: s_2,
        })), (elms = singleton_2((props_6 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_25 = PayOutIdModule_toString(payoutId), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_25)) : false) {
                e.value = value_25;
            }
        }]), ["onChange", (ev) => {
            patternInput[1](PayOutIdModule_fromString(ev.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_6)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let elms_3, s_3, elms_2, props_11, value_46;
            return append(singleton_1((elms_3 = ofArray([(s_3 = (`Item id = ${itemId}`), createElement("label", {
                className: "label",
                children: s_3,
            })), (elms_2 = singleton_2((props_11 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_46 = ItemIdModule_toString(itemId), ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals(e_1.value, value_46)) : false) {
                    e_1.value = value_46;
                }
            }]), ["onChange", (ev_1) => {
                patternInput_1[1](ItemIdModule_fromString(ev_1.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_11)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))), delay(() => {
                let props_16, props_18;
                return append((data.tag === 1) ? singleton_1(null) : ((data.tag === 2) ? singleton_1((props_16 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", {
                    children: ["Successfully removed item from payout."],
                })])]), createElement("div", createObj(Helpers_combineClasses("notification", props_16))))) : ((data.tag === 3) ? singleton_1((props_18 = ofArray([["className", "is-danger"], ["children", data.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_18))))) : singleton_1(null))), delay(() => {
                    let props_23, elms_4, props_20;
                    return singleton_1((props_23 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_4 = singleton_2((props_20 = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(data) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Remove item"]), delay(() => singleton_1(["onClick", (_arg1) => {
                        removeItem_1();
                    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_20))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_23)))));
                }));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_25))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    });
}

