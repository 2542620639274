import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { option_type, int32_type, union_type, unit_type, record_type, bool_type, class_type, list_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Submissions_PageType, PrintLabelInfo, AuctionModeType, Dimensions, ItemLotBasicInformationField, Fee, GpsPosition, ItemWithFullDataInfo, Item as Item_1, Item$reflection, WarehouseCategory$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52, Deferred$1, Deferred$1$reflection } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { LocalizedStringModule_getTranslationOrEmpty, LocalizedMap_getValueOrEmpty, LocalizedStringModule_setTranslation, LocalizedMap_add } from "./bidflow/src/infrastructure/Localization.js";
import { Lot_Edit_validate, Lot_Edit_validateIf, Lot_Edit_EditLotValidation } from "./Validation.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi, lotApi, itemApi } from "./Communication.js";
import { Localized_translate, tWithArgs, Localized_translateTo, getCurrentLanguage, getPrimaryLanguage, t } from "./Localization.js";
import { tryFind as tryFind_1, map, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Currency_decimalDigits, ReservedPrice_tryParseOrDefault, ReservedPrice_toString, Currency_currencySymbol, Currency_tryParseOrDefault, Decimal_optionalToString, Form_getFieldError, Decimal_tryParse } from "./Common.js";
import { toPath, navigate, Route, navigateTo } from "./Router.js";
import { tryFind, map as map_1, append as append_1, empty as empty_2, head, isEmpty, fold, singleton as singleton_1, cons, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { toArray, defaultArg, value as value_547 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { collect, iterate, empty as empty_1, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { isNullOrWhiteSpace, join } from "./fable_modules/fable-library.3.6.1/String.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { CurrencyModule_fromDecimal, CurrencyModule_toDecimal, PercentModule_fromDecimal, PercentModule_toDecimal, CategoryIdModule_fromString, CategoryIdModule_toString, CategoryIdModule_empty, SubmissionIdModule_empty, SubmissionIdModule_isEmpty } from "./bidflow/src/infrastructure/Auction.js";
import { Image_medium, Image_imagePlaceholder } from "./Css.js";
import { ReturnRecycleObject, ReturnOrRecycleComponent } from "./Components/ReturnOrRecycleObject.js";
import { UndoRecycle_ByItemId, UndoReturn_ByItemId } from "./Components/UndoRecycleAndReturnItem.js";
import { toString, toNumber, fromParts } from "./fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.6.1/Decimal.js";
import { NumberFormatModule_numberFormat } from "./Components/InputDecimal.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { equals as equals_1 } from "./fable_modules/fable-library.3.6.1/Long.js";
import { SearchSubmission } from "./Components/SearchSubmissionComponent.js";
import { languageSwitcher } from "./Components/ContentLanguageSwitcher.js";
import { SearchItemName } from "./Components/SearchItemNameComponent.js";
import { edit } from "./Components/Freight.js";
import { dividedTop } from "./Components/BorderDividedLine.js";
import { EditImages } from "./EditItemImages.js";
import { loaderBig } from "./Loader.js";

export class ValidationState extends Record {
    constructor(FormErrors, NeedToValidate) {
        super();
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
    }
}

export function ValidationState$reflection() {
    return record_type("EditItem.ValidationState", [], ValidationState, () => [["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type]]);
}

export class State extends Record {
    constructor(Categories, OriginalItem, Item, SaveOperation, RemoveOperation, ReasonForWithdraw, ReasonReturnedOrRecycled, IsEditMode, ContentLanguage, Validation) {
        super();
        this.Categories = Categories;
        this.OriginalItem = OriginalItem;
        this.Item = Item;
        this.SaveOperation = SaveOperation;
        this.RemoveOperation = RemoveOperation;
        this.ReasonForWithdraw = ReasonForWithdraw;
        this.ReasonReturnedOrRecycled = ReasonReturnedOrRecycled;
        this.IsEditMode = IsEditMode;
        this.ContentLanguage = ContentLanguage;
        this.Validation = Validation;
    }
}

export function State$reflection() {
    return record_type("EditItem.State", [], State, () => [["Categories", list_type(WarehouseCategory$reflection())], ["OriginalItem", Item$reflection()], ["Item", Item$reflection()], ["SaveOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["RemoveOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["ReasonForWithdraw", string_type], ["ReasonReturnedOrRecycled", string_type], ["IsEditMode", bool_type], ["ContentLanguage", string_type], ["Validation", ValidationState$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CategoryChange", "TitleChanged", "DescriptionChanged", "ConditionChanged", "HeightChanged", "WidthChanged", "LengthChanged", "DepthChanged", "DiameterChanged", "EstimatedPriceChanged", "ReservedPriceChanged", "HammerFeeChanged", "HasRoyaltiesChanged", "FreightZoneChanged", "GPSAddressChanged", "GPSMapLinkChanged", "PurchaseCommissionChanged", "SubmissionChanged", "SwitchToEditMode", "CancelChanges", "SaveChanges", "ContentLanguageChanged"];
    }
}

export function Msg$reflection() {
    return union_type("EditItem.Msg", [], Msg, () => [[["Item", WarehouseCategory$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("System.Decimal")]], [["Item", int32_type]], [["Item", class_type("System.Decimal")]], [["Item", bool_type]], [["Item1", string_type], ["Item2", option_type(int32_type)]], [["Item", option_type(string_type)]], [["Item", option_type(string_type)]], [["Item", class_type("System.Decimal")]], [["Item1", class_type("System.Int64")], ["Item2", class_type("System.Int64")], ["Item3", string_type]], [], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", string_type]]]);
}

export function withItem(item, state) {
    return new State(state.Categories, state.OriginalItem, item, state.SaveOperation, state.RemoveOperation, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.IsEditMode, state.ContentLanguage, state.Validation);
}

export function withItemInfo(item, state) {
    let inputRecord;
    return new State(state.Categories, state.OriginalItem, (inputRecord = state.Item, new Item_1(inputRecord.Id, item, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsRemoved, inputRecord.IsReturned, inputRecord.IsRecycle)), state.SaveOperation, state.RemoveOperation, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.IsEditMode, state.ContentLanguage, state.Validation);
}

export function withBasicInfoField(field, lang, value, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.Item.Info, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, LocalizedMap_add(field, lang, value, state.Item.Info.BasicInformation), inputRecord.Dimensions, inputRecord.Fee, inputRecord.Images, inputRecord.VideoLink, inputRecord.GpsPosition, inputRecord.Freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function withDimension(dimension, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.Item.Info, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, inputRecord.BasicInformation, dimension, inputRecord.Fee, inputRecord.Images, inputRecord.VideoLink, inputRecord.GpsPosition, inputRecord.Freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function withFee(fee, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.Item.Info, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, inputRecord.BasicInformation, inputRecord.Dimensions, fee, inputRecord.Images, inputRecord.VideoLink, inputRecord.GpsPosition, inputRecord.Freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function withFreight(freight, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.Item.Info, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, inputRecord.BasicInformation, inputRecord.Dimensions, inputRecord.Fee, inputRecord.Images, inputRecord.VideoLink, inputRecord.GpsPosition, freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function withGPSPosition(gpsPosition, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.Item.Info, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, inputRecord.BasicInformation, inputRecord.Dimensions, inputRecord.Fee, inputRecord.Images, inputRecord.VideoLink, gpsPosition, inputRecord.Freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function toValidationType(purchaseCommission, hammerFee, link) {
    return new Lot_Edit_EditLotValidation(link, purchaseCommission, hammerFee);
}

export function withCommissionsFormAndErrors(commissions, errors, state) {
    let inputRecord_1, inputRecord;
    const state_1 = withGPSPosition(new GpsPosition(state.Item.Info.GpsPosition.Address, commissions.GPSLink), withFee((inputRecord_1 = state.Item.Info.Fee, new Fee(inputRecord_1.EstimatedPrice, inputRecord_1.ReservedPrice, inputRecord_1.PurchaseCommission, commissions.HammerFee, inputRecord_1.HasRoyalties)), withFee((inputRecord = state.Item.Info.Fee, new Fee(inputRecord.EstimatedPrice, inputRecord.ReservedPrice, commissions.PurchaseCommission, inputRecord.Hammer, inputRecord.HasRoyalties)), state)));
    return new State(state_1.Categories, state_1.OriginalItem, state_1.Item, state_1.SaveOperation, state_1.RemoveOperation, state_1.ReasonForWithdraw, state_1.ReasonReturnedOrRecycled, state_1.IsEditMode, state_1.ContentLanguage, new ValidationState(errors, state.Validation.NeedToValidate));
}

export function saveItem(item) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(itemApi().modifyItem(item)), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Msg(20, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.itemDoesNotExist"))))) : singleton.Return(new Msg(20, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Msg(20, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(item, categories) {
    return [new State(categories, item, item, new Deferred$1(0), new Deferred$1(0), "", "", false, getPrimaryLanguage(), new ValidationState(empty(), false)), Cmd_none()];
}

export function update(msg, state) {
    let inputRecord_1, inputRecord, inputRecord_2, tupledArg, tupledArg_1, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, tupledArg_2;
    const validate = (terms) => Lot_Edit_validateIf(state.Validation.NeedToValidate, terms);
    if (msg.tag === 17) {
        return [withItemInfo((inputRecord_1 = state.Item.Info, new ItemWithFullDataInfo(inputRecord_1.CategoryId, msg.fields[0], inputRecord_1.BasicInformation, inputRecord_1.Dimensions, inputRecord_1.Fee, inputRecord_1.Images, inputRecord_1.VideoLink, inputRecord_1.GpsPosition, inputRecord_1.Freight, inputRecord_1.IsDeleted, inputRecord_1.IsReturned, inputRecord_1.IsRecycled)), withItem((inputRecord = state.Item, new Item_1(inputRecord.Id, inputRecord.Info, msg.fields[1], msg.fields[2], inputRecord.IsRemoved, inputRecord.IsReturned, inputRecord.IsRecycle)), state)), Cmd_none()];
    }
    else if (msg.tag === 0) {
        return [withItemInfo((inputRecord_2 = state.Item.Info, new ItemWithFullDataInfo(msg.fields[0].Id, inputRecord_2.SubmissionId, inputRecord_2.BasicInformation, inputRecord_2.Dimensions, inputRecord_2.Fee, inputRecord_2.Images, inputRecord_2.VideoLink, inputRecord_2.GpsPosition, inputRecord_2.Freight, inputRecord_2.IsDeleted, inputRecord_2.IsReturned, inputRecord_2.IsRecycled)), state), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [withBasicInfoField(new ItemLotBasicInformationField(0), state.ContentLanguage, msg.fields[0], state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withBasicInfoField(new ItemLotBasicInformationField(1), state.ContentLanguage, msg.fields[0], state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withBasicInfoField(new ItemLotBasicInformationField(2), msg.fields[0], msg.fields[1], state), Cmd_none()];
    }
    else if (msg.tag === 14) {
        return [withGPSPosition(new GpsPosition(msg.fields[0], state.Item.Info.GpsPosition.MapLink), state), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [(tupledArg = validate(toValidationType(state.Item.Info.Fee.PurchaseCommission, state.Item.Info.Fee.Hammer, msg.fields[0])), withCommissionsFormAndErrors(tupledArg[0], tupledArg[1], state)), Cmd_none()];
    }
    else if (msg.tag === 16) {
        return [(tupledArg_1 = validate(toValidationType(msg.fields[0], state.Item.Info.Fee.Hammer, state.Item.Info.GpsPosition.MapLink)), withCommissionsFormAndErrors(tupledArg_1[0], tupledArg_1[1], state)), Cmd_none()];
    }
    else if (msg.tag === 4) {
        const newHeightStr = msg.fields[0].trim();
        return [withDimension((inputRecord_4 = state.Item.Info.Dimensions, new Dimensions(Decimal_tryParse(newHeightStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newHeightStr, state.Item.Info.Dimensions.HeightInfo), inputRecord_4.Length, inputRecord_4.LengthInfo, inputRecord_4.Width, inputRecord_4.WidthInfo, inputRecord_4.Depth, inputRecord_4.DepthInfo, inputRecord_4.Diameter, inputRecord_4.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        const newWidthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_5 = state.Item.Info.Dimensions, new Dimensions(inputRecord_5.Height, inputRecord_5.HeightInfo, inputRecord_5.Length, inputRecord_5.LengthInfo, Decimal_tryParse(newWidthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newWidthStr, state.Item.Info.Dimensions.WidthInfo), inputRecord_5.Depth, inputRecord_5.DepthInfo, inputRecord_5.Diameter, inputRecord_5.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        const newLengthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_6 = state.Item.Info.Dimensions, new Dimensions(inputRecord_6.Height, inputRecord_6.HeightInfo, Decimal_tryParse(newLengthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newLengthStr, state.Item.Info.Dimensions.LengthInfo), inputRecord_6.Width, inputRecord_6.WidthInfo, inputRecord_6.Depth, inputRecord_6.DepthInfo, inputRecord_6.Diameter, inputRecord_6.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        const newDepthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_7 = state.Item.Info.Dimensions, new Dimensions(inputRecord_7.Height, inputRecord_7.HeightInfo, inputRecord_7.Length, inputRecord_7.LengthInfo, inputRecord_7.Width, inputRecord_7.WidthInfo, Decimal_tryParse(newDepthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newDepthStr, state.Item.Info.Dimensions.DepthInfo), inputRecord_7.Diameter, inputRecord_7.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        const newDiameterStr = msg.fields[0].trim();
        return [withDimension((inputRecord_8 = state.Item.Info.Dimensions, new Dimensions(inputRecord_8.Height, inputRecord_8.HeightInfo, inputRecord_8.Length, inputRecord_8.LengthInfo, inputRecord_8.Width, inputRecord_8.WidthInfo, inputRecord_8.Depth, inputRecord_8.DepthInfo, Decimal_tryParse(newDiameterStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newDiameterStr, state.Item.Info.Dimensions.DiameterInfo))), state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [withFee((inputRecord_9 = state.Item.Info.Fee, new Fee(inputRecord_9.EstimatedPrice, inputRecord_9.ReservedPrice, inputRecord_9.PurchaseCommission, inputRecord_9.Hammer, msg.fields[0])), state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withFee((inputRecord_10 = state.Item.Info.Fee, new Fee(msg.fields[0], inputRecord_10.ReservedPrice, inputRecord_10.PurchaseCommission, inputRecord_10.Hammer, inputRecord_10.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [withFee((inputRecord_11 = state.Item.Info.Fee, new Fee(inputRecord_11.EstimatedPrice, msg.fields[0], inputRecord_11.PurchaseCommission, inputRecord_11.Hammer, inputRecord_11.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [(tupledArg_2 = validate(toValidationType(state.Item.Info.Fee.PurchaseCommission, msg.fields[0], state.Item.Info.GpsPosition.MapLink)), withCommissionsFormAndErrors(tupledArg_2[0], tupledArg_2[1], state)), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [withFreight(map((idx, v) => {
            if (idx === msg.fields[0]) {
                return msg.fields[1];
            }
            else {
                return v;
            }
        }, state.Item.Info.Freight), state), Cmd_none()];
    }
    else if (msg.tag === 18) {
        return [new State(state.Categories, state.OriginalItem, state.Item, state.SaveOperation, state.RemoveOperation, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, true, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 19) {
        return [new State(state.Categories, state.OriginalItem, state.OriginalItem, state.SaveOperation, state.RemoveOperation, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, false, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 20) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.Categories, state.OriginalItem, state.Item, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.RemoveOperation, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.IsEditMode, state.ContentLanguage, state.Validation), Cmd_none()];
            }
            else {
                return [new State(state.Categories, state.Item, state.Item, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.RemoveOperation, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, false, state.ContentLanguage, state.Validation), navigateTo(new Route(3, new AuctionModeType(2), 1))];
            }
        }
        else {
            const formValidation = Lot_Edit_validate(toValidationType(state.Item.Info.Fee.PurchaseCommission, state.Item.Info.Fee.Hammer, state.Item.Info.GpsPosition.MapLink));
            if (formValidation.tag === 1) {
                return [new State(state.Categories, state.OriginalItem, state.Item, new Deferred$1(0), state.RemoveOperation, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.IsEditMode, state.ContentLanguage, new ValidationState(formValidation.fields[0], true)), Cmd_none()];
            }
            else {
                return [new State(state.Categories, state.OriginalItem, state.Item, new Deferred$1(1), state.RemoveOperation, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.IsEditMode, state.ContentLanguage, new ValidationState(empty(), true)), Cmd_fromAsync(saveItem(state.Item))];
            }
        }
    }
    else {
        return [new State(state.Categories, state.OriginalItem, state.Item, state.SaveOperation, state.RemoveOperation, state.ReasonForWithdraw, state.ReasonReturnedOrRecycled, state.IsEditMode, msg.fields[0], state.Validation), Cmd_none()];
    }
}

export function renderSaveOperationError(state) {
    const matchValue = state.SaveOperation;
    let pattern_matching_result, ex, reason;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 1;
            reason = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue.tag === 0) {
        pattern_matching_result = 2;
    }
    else if (matchValue.tag === 1) {
        pattern_matching_result = 2;
    }
    else {
        pattern_matching_result = 0;
        ex = matchValue.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = ofArray([["className", "is-danger"], ["className", "is-light"], ["children", ex.message]]);
            return createElement("p", createObj(Helpers_combineClasses("help", props)));
        }
        case 1: {
            return createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "is-danger"], ["className", "is-light"], ["children", reason]]))));
        }
        case 2: {
            return null;
        }
    }
}

export function renderGPSPositionFields(state, dispatch) {
    let elms_1, elms, props_2, props, props_6, props_4, value_9, elms_3;
    const props_22 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([(value_9 = ((state.Item.Info.GpsPosition.Address != null) ? value_547(state.Item.Info.GpsPosition.Address) : ""), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_9)) : false) {
            e.value = value_9;
        }
    }]), ["onChange", (ev) => {
        const x = ev.target.value;
        if (x === "") {
            dispatch(new Msg(14, void 0));
        }
        else {
            dispatch(new Msg(14, x));
        }
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_6))))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = toList(delay(() => {
        let elms_2, props_12, props_10, props_16, props_14, value_31;
        const errors = Form_getFieldError(state.Validation.FormErrors, "GpsMapLink");
        return append(singleton_2((elms_2 = ofArray([(props_12 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.linkToMaps")]]), createElement("label", createObj(Helpers_combineClasses("label", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_12)))), (props_16 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([(value_31 = ((state.Item.Info.GpsPosition.MapLink != null) ? value_547(state.Item.Info.GpsPosition.MapLink) : ""), ["ref", (e_1) => {
            if ((!(e_1 == null)) ? (!equals(e_1.value, value_31)) : false) {
                e_1.value = value_31;
            }
        }]), ["onChange", (ev_1) => {
            const x_1 = ev_1.target.value;
            if (x_1 === "") {
                dispatch(new Msg(15, void 0));
            }
            else {
                dispatch(new Msg(15, x_1));
            }
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_14)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_16))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), delay(() => {
            let props_19;
            return singleton_2((props_19 = ofArray([["className", "is-danger"], ["children", fold((s, x_2) => (`${s} ${x_2}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_19)))));
        }));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_22)));
}

export function renderGoToLotButtons(state) {
    const props_3 = toList(delay(() => append(equals(state.SaveOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["tabIndex", -1]), delay(() => append(singleton_2(["onClick", (x) => {
        x.preventDefault();
        navigate(toPath(new Route(3, new AuctionModeType(2), 1)));
    }]), delay(() => {
        let elms, s;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-chevron-left"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (s = t("auction.backToAuctions"), createElement("span", {
            className: "",
            children: s,
        }))])]);
    }))))))));
    return createElement("a", createObj(Helpers_combineClasses("button", props_3)));
}

export function PrintLotLabel(state) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput[1];
    const generateReceipt = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading(true);
        const info = new PrintLabelInfo(state.OriginalItem.Id, void 0, state.OriginalItem.Info.Fee.EstimatedPrice, getCurrentLanguage());
        return singleton.Bind(lotApi().generatePrintLabelWithQRCode(info), (_arg1) => {
            window.open(_arg1);
            setIsLoading(false);
            return singleton.Return();
        });
    }), (_arg2) => {
        setIsLoading(false);
        return singleton.Zero();
    }));
    const props_3 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(patternInput[0] ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["onClick", (_arg1_1) => {
        startImmediate(generateReceipt);
    }]), delay(() => {
        let s, elms;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(s = t("common.printLabel"), createElement("span", {
            className: "",
            children: s,
        })), (elms = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-print"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]);
    }))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props_3)));
}

export function RenderButtons(renderButtonsInputProps) {
    let props, props_2;
    const dispatch = renderButtonsInputProps.dispatch;
    const state = renderButtonsInputProps.state;
    const isLoading = equals(state.SaveOperation, new Deferred$1(1));
    const isSubmissionNotSelected = SubmissionIdModule_isEmpty(state.Item.Info.SubmissionId);
    const isRemoving = equals(state.RemoveOperation, new Deferred$1(1));
    const hasNotChanged = equals(state.Item, state.OriginalItem);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const moreOptionsVisible = patternInput[0];
    if (state.IsEditMode) {
        const children = ofArray([(props = toList(delay(() => append(singleton_2(["className", "is-dark"]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["children", t("common.cancel")]), delay(() => singleton_2(["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new Msg(19));
        }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props)))), (props_2 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["disabled", hasNotChanged ? true : isSubmissionNotSelected]), delay(() => append(singleton_2(["children", t("common.save")]), delay(() => singleton_2(["onClick", (x_2) => {
            x_2.preventDefault();
            dispatch(new Msg(20, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_2))))]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        const children_2 = toList(delay(() => append(moreOptionsVisible ? singleton_2(createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const isNeedDisabling = (state.OriginalItem.Info.IsDeleted ? true : state.OriginalItem.Info.IsRecycled) ? true : state.OriginalItem.Info.IsReturned;
                const image = isEmpty(state.OriginalItem.Info.Images) ? Image_imagePlaceholder : Image_medium(head(state.OriginalItem.Info.Images));
                return append((!isNeedDisabling) ? singleton_2(createElement(ReturnOrRecycleComponent, {
                    object: new ReturnRecycleObject(0, state.OriginalItem.Id, image, isNeedDisabling, true),
                })) : empty_1(), delay(() => append(state.OriginalItem.Info.IsReturned ? singleton_2(createElement(UndoReturn_ByItemId, {
                    itemId: state.OriginalItem.Id,
                })) : empty_1(), delay(() => (state.OriginalItem.Info.IsRecycled ? singleton_2(createElement(UndoRecycle_ByItemId, {
                    itemId: state.OriginalItem.Id,
                })) : empty_1())))));
            })))),
        })) : empty_1(), delay(() => {
            let props_6, props_8;
            return singleton_2(createElement("div", {
                children: Interop_reactApi.Children.toArray([renderGoToLotButtons(state), (props_6 = toList(delay(() => append(singleton_2(["className", "has-background-grey-lighter"]), delay(() => append(moreOptionsVisible ? singleton_2(["children", t("auction.hideActions")]) : singleton_2(["children", t("auction.moreActions")]), delay(() => singleton_2(["onClick", (_arg1) => {
                    patternInput[1](!moreOptionsVisible);
                }]))))))), createElement("a", createObj(Helpers_combineClasses("button", props_6)))), (props_8 = toList(delay(() => append(isRemoving ? singleton_2(["disabled", true]) : empty_1(), delay(() => append((state.OriginalItem.Info.IsRecycled ? true : state.OriginalItem.Info.IsReturned) ? singleton_2(["disabled", true]) : empty_1(), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["children", t("common.edit")]), delay(() => singleton_2(["onClick", (x_3) => {
                    x_3.preventDefault();
                    dispatch(new Msg(18));
                }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_8)))), createElement(PrintLotLabel, state)]),
            }));
        }))));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
    }
}

export function tryDecimalOrDefault(defaultValue, str) {
    if (isNullOrWhiteSpace(str)) {
        return fromParts(0, 0, 0, false, 0);
    }
    else {
        return defaultArg(Decimal_tryParse(str), defaultValue);
    }
}

export function renderField(label, prefix, value, placeholder, onChange, state, formErrors) {
    const elms_2 = toList(delay(() => {
        let elms_1, props_2, props_10, props_5, props_4, elms, props_12;
        const errors = Form_getFieldError(state.Validation.FormErrors, formErrors);
        return singleton_2((elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_10 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append((!equals(errors, empty_2())) ? singleton_2(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_2(["placeholder", placeholder]), delay(() => append(singleton_2(["value", toNumber(value)]), delay(() => append(singleton_2(["onValueChange", (x) => {
            onChange(new Decimal(defaultArg(x.floatValue, 0)));
        }]), delay(() => (equals(state.SaveOperation, new Deferred$1(1)) ? singleton_2(["disabled", true]) : empty_1()))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_4, singleton_1(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_5)))), (elms = singleton_1(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["children", prefix]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_10)))), (props_12 = ofArray([["className", "is-danger"], ["children", fold((s, x_1) => (`${s} ${x_1}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_12))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })));
    }));
    return createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function RenderWithLoadCategories(renderWithLoadCategoriesInputProps) {
    let props_37, props_35, props_28, props_2, props, props_26, props_33, elms, props_30, props_226, elms_28, props_221, props_223;
    const freightSettings = renderWithLoadCategoriesInputProps.freightSettings;
    const item = renderWithLoadCategoriesInputProps.item;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(item, renderWithLoadCategoriesInputProps.catalog), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const suppressPropagateEnterKey = (ev) => {
        if (ev.key === "Enter") {
            const this$ = ev;
            this$.stopPropagation();
            this$.nativeEvent.stopImmediatePropagation();
        }
    };
    const getBasicInfoValue = (field) => Localized_translateTo(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field, state_1.Item.Info.BasicInformation));
    const props_228 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_37 = ofArray([["className", "py-2"], ["children", Interop_reactApi.Children.toArray([(props_35 = ofArray([["className", "mt-3"], ["children", Interop_reactApi.Children.toArray([(props_28 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "pl-0"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-size-6"], ["className", "has-text-weight-light"], ["className", "has-text-grey-light"], ["children", t("auction.editItem")]]), createElement("h1", createObj(Helpers_combineClasses("title", props))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_26 = ofArray([["className", "pl-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_6, props_4;
        return append(singleton_2((props_6 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "has-text-grey-dark"], ["className", "is-uppercase"], ["className", "is-size-4"], ["children", tWithArgs("auction.itemId", {
            itemId: item.Id,
        })]]), createElement("h1", createObj(Helpers_combineClasses("title", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6))))), delay(() => {
            let props_12, props_10, props_8, props_18, props_16, props_14, props_24, props_22, props_20;
            return item.Info.IsReturned ? singleton_2((props_12 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "customTag"], ["className", "is-rounded"], ["className", "is-dark"], ["children", t("auction.returnedToSeller")]]), createElement("span", createObj(Helpers_combineClasses("tag", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12))))) : (item.Info.IsRecycled ? singleton_2((props_18 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "customTag"], ["className", "is-rounded"], ["className", "is-dark"], ["children", t("auction.recycled")]]), createElement("span", createObj(Helpers_combineClasses("tag", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_18))))) : (item.Info.IsDeleted ? singleton_2((props_24 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "are-medium"], ["children", Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", "is-rounded"], ["className", "is-danger"], ["children", t("auction.lotStatus.deleted")]]), createElement("span", createObj(Helpers_combineClasses("tag", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("tags", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_24))))) : empty_1()));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_28)))), (props_33 = ofArray([["style", {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        minHeight: 100,
        paddingRight: 0,
    }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_30 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(RenderButtons, {
        state: state_1,
        dispatch: dispatch,
    })])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_30))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_33))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_35)))), renderSaveOperationError(state_1)])]]), createElement("section", createObj(Helpers_combineClasses("section", props_37)))), (props_226 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(elms_28 = ofArray([(props_221 = ofArray([["className", "is-8"], ["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
        disabled: (!state_1.IsEditMode) ? true : equals(state_1.SaveOperation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_66, props_64, props_47, elms_1, props_41, props_39, props_44, props_62, elms_2, props_51, props_49, props_59;
            return append(singleton_2((props_66 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_64 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_47 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_41 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_39 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.submission")]]), createElement("label", createObj(Helpers_combineClasses("label", props_39))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_41)))), (props_44 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                if (state_1.IsEditMode) {
                    const submissionId = equals_1(state_1.Item.Info.SubmissionId, SubmissionIdModule_empty) ? (void 0) : state_1.Item.Info.SubmissionId;
                    return singleton_2(createElement(SearchSubmission, {
                        selected: submissionId,
                        onSelect: (arg) => {
                            let tupledArg;
                            dispatch((tupledArg = arg, new Msg(17, tupledArg[0], tupledArg[1], tupledArg[2])));
                        },
                        onInputRef: (_arg1) => {
                        },
                        dependencies: [],
                        isAutofocus: true,
                        isStartSearchOnInit: true,
                    }));
                }
                else {
                    return singleton_2(createElement("a", {
                        style: {
                            fontWeight: 600,
                            textDecoration: "underline",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            paddingTop: ("calc(" + "0.5em - 1px") + ")",
                        },
                        className: "has-text-grey-dark",
                        href: toPath(new Route(22, state_1.Item.SellerId, state_1.Item.Info.SubmissionId, new Submissions_PageType(0))),
                        children: `#${state_1.Item.Info.SubmissionId}/ ${state_1.Item.SellerName}`,
                    }));
                }
            }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_44))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_47)))), (props_62 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(elms_2 = ofArray([(props_51 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_49 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.category")]]), createElement("label", createObj(Helpers_combineClasses("label", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_51)))), (props_59 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let patternInput_1, value_156;
                const options = toList(delay(() => append((state_1.Item.Info.CategoryId === CategoryIdModule_empty) ? singleton_2(createElement("option", {
                    value: CategoryIdModule_toString(CategoryIdModule_empty),
                    children: t("common.noCategory"),
                })) : empty_1(), delay(() => map_1((category) => createElement("option", {
                    value: CategoryIdModule_toString(category.Id),
                    children: Localized_translate(category.Name),
                }), state_1.Categories)))));
                return singleton_2((patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["onChange", (ev_1) => {
                    const categoryId = CategoryIdModule_fromString(ev_1.target.value) | 0;
                    iterate((arg_1) => {
                        dispatch(new Msg(0, arg_1));
                    }, toArray(tryFind((x_1) => (x_1.Id === categoryId), state_1.Categories)));
                }], (value_156 = CategoryIdModule_toString(state_1.Item.Info.CategoryId), ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_156)) : false) {
                        e.value = value_156;
                    }
                }]), ["children", Interop_reactApi.Children.toArray(Array.from(options))]])), createElement("div", {
                    className: join(" ", cons("select", patternInput_1[0])),
                    children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
                })));
            }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_59))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_62))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_64))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_66))))), delay(() => {
                let props_147, props_75, elms_3, props_70, props_68, props_72, props_84, props_79, props_77, elms_4, props_81, value_204, props_93, props_88, props_86, elms_5, props_90, value_229, props_145, elms_7, elms_6, props_97, props_95, props_101, props_99, elms_9, elms_8, props_107, props_105, props_111, props_109, elms_11, elms_10, props_117, props_115, props_121, props_119, elms_13, elms_12, props_127, props_125, props_131, props_129, elms_15, elms_14, props_137, props_135, props_141, props_139;
                return append(singleton_2((props_147 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([languageSwitcher(state_1.ContentLanguage, (arg_3) => {
                    dispatch(new Msg(21, arg_3));
                }), (props_75 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_70 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_68 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.lotTitle")]]), createElement("label", createObj(Helpers_combineClasses("label", props_68))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_70)))), (props_72 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const name_78 = getBasicInfoValue(new ItemLotBasicInformationField(0));
                    return singleton_2(createElement(SearchItemName, {
                        selected: name_78,
                        onSelect: (arg_4) => {
                            dispatch(new Msg(1, arg_4));
                        },
                        onChange: (arg_5) => {
                            dispatch(new Msg(1, arg_5));
                        },
                        onInputRef: (_arg2) => {
                        },
                        dependencies: [state_1.ContentLanguage],
                        isAutofocus: false,
                        isStartSearchOnInit: false,
                    }));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_72))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_75)))), (props_84 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_79 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_77 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.lotDescription")]]), createElement("label", createObj(Helpers_combineClasses("label", props_77))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_79)))), (elms_4 = singleton_1((props_81 = ofArray([["rows", 2], ["onKeyDown", suppressPropagateEnterKey], ["onKeyUp", suppressPropagateEnterKey], ["onKeyPress", suppressPropagateEnterKey], (value_204 = getBasicInfoValue(new ItemLotBasicInformationField(1)), ["ref", (e_1) => {
                    if ((!(e_1 == null)) ? (!equals(e_1.value, value_204)) : false) {
                        e_1.value = value_204;
                    }
                }]), ["onChange", (ev_2) => {
                    dispatch(new Msg(2, ev_2.target.value));
                }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_81))))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_84)))), (props_93 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_88 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_86 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.lotCondition")]]), createElement("label", createObj(Helpers_combineClasses("label", props_86))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_88)))), (elms_5 = singleton_1((props_90 = ofArray([["rows", 1], ["onKeyDown", suppressPropagateEnterKey], ["onKeyUp", suppressPropagateEnterKey], ["onKeyPress", suppressPropagateEnterKey], (value_229 = getBasicInfoValue(new ItemLotBasicInformationField(2)), ["ref", (e_2) => {
                    if ((!(e_2 == null)) ? (!equals(e_2.value, value_229)) : false) {
                        e_2.value = value_229;
                    }
                }]), ["onChange", (ev_3) => {
                    dispatch(new Msg(3, state_1.ContentLanguage, ev_3.target.value));
                }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_90))))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_93)))), (props_145 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_7 = singleton_1((elms_6 = ofArray([(props_97 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_95 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.height")]]), createElement("label", createObj(Helpers_combineClasses("label", props_95))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_97)))), (props_101 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_99 = toList(delay(() => append((state_1.Item.Info.Dimensions.Height != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.Item.Info.Dimensions.Height)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.Item.Info.Dimensions.HeightInfo)]), delay(() => singleton_2(["onChange", (ev_4) => {
                    dispatch(new Msg(4, ev_4.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_99)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_101))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                })), (elms_9 = singleton_1((elms_8 = ofArray([(props_107 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_105 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.length")]]), createElement("label", createObj(Helpers_combineClasses("label", props_105))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_107)))), (props_111 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_109 = toList(delay(() => append((state_1.Item.Info.Dimensions.Length != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.Item.Info.Dimensions.Length)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.Item.Info.Dimensions.LengthInfo)]), delay(() => singleton_2(["onChange", (ev_5) => {
                    dispatch(new Msg(6, ev_5.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_109)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_111))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                })), (elms_11 = singleton_1((elms_10 = ofArray([(props_117 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_115 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.width")]]), createElement("label", createObj(Helpers_combineClasses("label", props_115))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_117)))), (props_121 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_119 = toList(delay(() => append((state_1.Item.Info.Dimensions.Width != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.Item.Info.Dimensions.Width)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.Item.Info.Dimensions.WidthInfo)]), delay(() => singleton_2(["onChange", (ev_6) => {
                    dispatch(new Msg(5, ev_6.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_119)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_121))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                })), (elms_13 = singleton_1((elms_12 = ofArray([(props_127 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_125 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.depth")]]), createElement("label", createObj(Helpers_combineClasses("label", props_125))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_127)))), (props_131 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_129 = toList(delay(() => append((state_1.Item.Info.Dimensions.Depth != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.Item.Info.Dimensions.Depth)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.Item.Info.Dimensions.DepthInfo)]), delay(() => singleton_2(["onChange", (ev_7) => {
                    dispatch(new Msg(7, ev_7.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_129)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_131))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
                })), (elms_15 = singleton_1((elms_14 = ofArray([(props_137 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_135 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.diameter")]]), createElement("label", createObj(Helpers_combineClasses("label", props_135))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_137)))), (props_141 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_139 = toList(delay(() => append((state_1.Item.Info.Dimensions.Diameter != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.Item.Info.Dimensions.Diameter)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.Item.Info.Dimensions.DiameterInfo)]), delay(() => singleton_2(["onChange", (ev_8) => {
                    dispatch(new Msg(8, ev_8.target.value));
                }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_139)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_141))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
                }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_145)))), renderGPSPositionFields(state_1, dispatch)])]]), createElement("section", createObj(Helpers_combineClasses("section", props_147))))), delay(() => {
                    let props_209, props_189, elms_18, elms_17, props_151, props_149, props_160, props_155, props_153, elms_16, props_157, elms_21, elms_20, props_166, props_164, props_175, props_170, props_168, elms_19, props_172, elms_23, props_186, props_181, props_179, elms_22, props_205, elms_26, elms_25, props_193, props_191, props_201, props_196, props_195, elms_24, props_198;
                    return append(singleton_2((props_209 = ofArray([["className", "py-1"], ["className", "px-0"], ["className", "has-background-grey-lighter"], ["style", {
                        borderRadius: 4,
                    }], ["children", Interop_reactApi.Children.toArray([(props_189 = ofArray([["style", {
                        margin: 0,
                    }], ["children", Interop_reactApi.Children.toArray([(elms_18 = singleton_1((elms_17 = ofArray([(props_151 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_149 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.estimatedPrice")]]), createElement("label", createObj(Helpers_combineClasses("label", props_149))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_151)))), (props_160 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_155 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_153 = toList(delay(() => append(state_1.IsEditMode ? singleton_2(["placeholder", "200"]) : empty_1(), delay(() => {
                        let value_377;
                        return append(singleton_2((value_377 = toString(state_1.Item.Info.Fee.EstimatedPrice), ["ref", (e_3) => {
                            if ((!(e_3 == null)) ? (!equals(e_3.value, value_377)) : false) {
                                e_3.value = value_377;
                            }
                        }])), delay(() => singleton_2(["onChange", (ev_9) => {
                            dispatch(new Msg(9, Currency_tryParseOrDefault(ev_9.target.value)));
                        }])));
                    })))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_153)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_155)))), (elms_16 = singleton_1((props_157 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_157))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_160))))]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
                    }))), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
                    })), (elms_21 = singleton_1((elms_20 = ofArray([(props_166 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_164 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.reservedPrice")]]), createElement("label", createObj(Helpers_combineClasses("label", props_164))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_166)))), (props_175 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_170 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_168 = toList(delay(() => append(state_1.IsEditMode ? singleton_2(["placeholder", "200"]) : empty_1(), delay(() => {
                        let value_413;
                        return append(singleton_2((value_413 = ReservedPrice_toString(state_1.Item.Info.Fee.ReservedPrice), ["ref", (e_4) => {
                            if ((!(e_4 == null)) ? (!equals(e_4.value, value_413)) : false) {
                                e_4.value = value_413;
                            }
                        }])), delay(() => singleton_2(["onChange", (ev_10) => {
                            dispatch(new Msg(10, ReservedPrice_tryParseOrDefault(ev_10.target.value)));
                        }])));
                    })))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_168)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_170)))), (elms_19 = singleton_1((props_172 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_172))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_175))))]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
                    }))), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
                    })), (elms_23 = singleton_1((props_186 = ofArray([["style", {
                        display: "inline-table",
                    }], ["children", Interop_reactApi.Children.toArray([(props_181 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_179 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.resaleRights")]]), createElement("label", createObj(Helpers_combineClasses("label", props_179))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_181)))), (elms_22 = ofArray([createElement("input", {
                        disabled: (!state_1.IsEditMode) ? true : equals(state_1.SaveOperation, new Deferred$1(1)),
                        className: "is-checkradio is-large has-background-color is-white",
                        id: "exampleCheckboxMedium",
                        type: "checkbox",
                        name: "exampleCheckboxMedium",
                        onChange: (ev_11) => {
                            dispatch(new Msg(12, ev_11.target.checked));
                        },
                        checked: state_1.Item.Info.Fee.HasRoyalties,
                    }), createElement("label", {
                        htmlFor: "exampleCheckboxMedium",
                    })]), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_186))))), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_189)))), (props_205 = ofArray([["style", {
                        margin: 0,
                    }], ["children", Interop_reactApi.Children.toArray([renderField(t("orders.buyerCommission"), "%", PercentModule_toDecimal(state_1.Item.Info.Fee.PurchaseCommission), t("orders.buyerCommission"), (arg_17) => {
                        dispatch(new Msg(16, PercentModule_fromDecimal(arg_17)));
                    }, state_1, "BuyerCommission"), (elms_26 = singleton_1((elms_25 = ofArray([(props_193 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_191 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.hammerFee")]]), createElement("label", createObj(Helpers_combineClasses("label", props_191))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_193)))), (props_201 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_196 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_195 = ofArray([["placeholder", "200"], ["decimalScale", Currency_decimalDigits()], ["value", toNumber(CurrencyModule_toDecimal(state_1.Item.Info.Fee.Hammer))], ["onValueChange", (x_14) => {
                        dispatch(new Msg(11, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_14.floatValue, 0)))));
                    }]]), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_195, singleton_1(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_196)))), (elms_24 = singleton_1((props_198 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_198))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_24)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_201))))]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_25)),
                    }))), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_26)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_205)))), createElement("div", createObj(Helpers_combineClasses("column", empty_2())))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_209))))), delay(() => {
                        let props_213, props_211;
                        return append(singleton_2((props_213 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_211 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((zone) => {
                            const freightClass = tryFind_1(zone.DeliveryCostZoneId, state_1.Item.Info.Freight);
                            return (freightClass == null) ? singleton_2(edit(zone, void 0, (x_16) => {
                                dispatch(new Msg(13, zone.DeliveryCostZoneId, x_16));
                            })) : singleton_2(edit(zone, value_547(freightClass), (x_15) => {
                                dispatch(new Msg(13, zone.DeliveryCostZoneId, x_15));
                            }));
                        }, freightSettings)))))]), createElement("div", createObj(Helpers_combineClasses("columns", props_211))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_213))))), delay(() => {
                            let props_218, elms_27, props_215;
                            return state_1.IsEditMode ? singleton_2((props_218 = ofArray([["className", "py-4"], ["className", "px-0"], dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_27 = singleton_1((props_215 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(RenderButtons, {
                                state: state_1,
                                dispatch: dispatch,
                            })])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_215))))), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_27)),
                            }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_218))))) : empty_1();
                        }));
                    }));
                }));
            }));
        })))),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_221)))), (props_223 = ofArray([["className", "has-background-white"], ["style", {
        borderRadius: 4,
        marginLeft: 10,
    }], ["children", Interop_reactApi.Children.toArray([createElement(EditImages, {
        itemId: state_1.Item.Id,
        images: state_1.Item.Info.Images,
        videoLink: state_1.Item.Info.VideoLink,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_223))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_28)),
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_226))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_228)));
}

export function Render(renderInputProps) {
    const itemId = renderInputProps.itemId;
    const item_1 = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(itemApi().getItem(itemId)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getWarehouseCategories()), (_arg2) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getFreightSettings()), (_arg3) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getOrderCommissions()), (_arg4) => singleton.Return({
        Catalog: _arg2,
        FreightSettings: _arg3,
        Item: _arg1,
        SelectedCategoryId: CategoryIdModule_empty,
        ["СommissionsSettings"]: _arg4,
    })))))), [itemId]);
    switch (item_1.tag) {
        case 1: {
            return loaderBig();
        }
        case 3: {
            const value = item_1.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const result_1 = item_1.fields[0];
            return createElement(RenderWithLoadCategories, {
                item: result_1.Item,
                catalog: result_1.Catalog,
                freightSettings: result_1.FreightSettings,
            });
        }
        default: {
            return null;
        }
    }
}

