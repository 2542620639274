import { collect, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { createElement } from "react";
import { createObj, int32ToString } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Currency_format } from "../Common.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Localized_translate } from "../Localization.js";
import { singleton as singleton_1, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { FreightClassModule_fromInt } from "../bidflow/src/infrastructure/Auction.js";
import { parse } from "../fable_modules/fable-library.3.6.1/Int32.js";

export function edit(zone, selected, onChange) {
    let elms_1, props_4, props_2, elms, patternInput;
    const options = toList(delay(() => append(singleton(createElement("option", {
        value: "None",
        children: "N/A",
    })), delay(() => collect((matchValue_1) => {
        const cls = matchValue_1[0] | 0;
        return singleton(createElement("option", {
            value: int32ToString(cls),
            children: `${Currency_format(matchValue_1[1])}: Class ${cls}`,
        }));
    }, zone.FreightCost)))));
    const props_12 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_4 = ofArray([["className", join(" ", ["is-text-overflow-hidden"])], ["className", "has-text-left"], ["className", "is-text-overflow-hidden"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", Localized_translate(zone.Name)]]), createElement("label", createObj(Helpers_combineClasses("label", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_4)))), (elms = singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["value", defaultArg(map((value_25) => int32ToString(value_25), selected), "None")], ["onChange", (ev) => {
        const value_1 = ev.target.value;
        onChange((() => {
            try {
                return FreightClassModule_fromInt(parse(value_1, 511, false, 32));
            }
            catch (matchValue) {
                return void 0;
            }
        })());
    }], ["children", Interop_reactApi.Children.toArray(Array.from(options))]])), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("column", props_12)));
}

