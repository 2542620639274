import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { WarehouseCategory, Submissions_SubmissionFilter, WarehouseCategory$reflection, Submissions_SubmissionFilter$reflection, Submissions_SubmissionsEntity$reflection } from "./Shared/Shared.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingQuery$1$reflection, Common_PagingResult$1$reflection } from "./Shared/Common.js";
import { Deferred$1, Deferred$1$reflection } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { union_type, class_type, record_type, list_type, int32_type, option_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { submissionsApi, settingsApi } from "./Communication.js";
import { singleton as singleton_1, sortBy as sortBy_2, ofArrayWithTail, filter, map, contains, cons, tail, head, isEmpty, ofArray, empty } from "./fable_modules/fable-library.3.6.1/List.js";
import { t as t_3, getPrimaryLanguage, Localized_translate, getLocalizationContext } from "./Localization.js";
import { SizeSubmission_list, Warehouse_list } from "./Common.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createObj, comparePrimitives, stringHash, numberHash, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { CategoryIdModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { LocalizedStringModule_from } from "./bidflow/src/infrastructure/Localization.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { append, map as map_1, singleton as singleton_2, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { dividedTop } from "./Components/BorderDividedLine.js";
import { loader } from "./Loader.js";
import { renderItemsSubmission } from "./ForAgreement.js";
import { renderPagination } from "./Components/Pagination.js";

export class State extends Record {
    constructor(LoadedSubmissions, Error$, CurrentPage, Query, TemplateCategories, LagerFilters, SizeFilters) {
        super();
        this.LoadedSubmissions = LoadedSubmissions;
        this.Error = Error$;
        this.CurrentPage = (CurrentPage | 0);
        this.Query = Query;
        this.TemplateCategories = TemplateCategories;
        this.LagerFilters = LagerFilters;
        this.SizeFilters = SizeFilters;
    }
}

export function State$reflection() {
    return record_type("Submissions.State", [], State, () => [["LoadedSubmissions", Deferred$1$reflection(Common_PagingResult$1$reflection(Submissions_SubmissionsEntity$reflection()))], ["Error", option_type(string_type)], ["CurrentPage", int32_type], ["Query", Common_PagingQuery$1$reflection(Submissions_SubmissionFilter$reflection())], ["TemplateCategories", list_type(WarehouseCategory$reflection())], ["LagerFilters", list_type(string_type)], ["SizeFilters", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadSubmissions", "LoadCategories", "PagingGoto", "OnColumnClicked", "TagsChange", "LagerFiltersChange", "SizeFiltersChange"];
    }
}

export function Msg$reflection() {
    return union_type("Submissions.Msg", [], Msg, () => [[["Item1", Common_PagingQuery$1$reflection(Submissions_SubmissionFilter$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(Submissions_SubmissionsEntity$reflection()), class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(Submissions_SubmissionsEntity$reflection())]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(WarehouseCategory$reflection()), class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", list_type(WarehouseCategory$reflection())]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item", int32_type]], [["Item", string_type]], [["Item", WarehouseCategory$reflection()]], [["Item", string_type]], [["Item", string_type]]]);
}

export const Cmd_loadCategories = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getWarehouseCategories()), (_arg1) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));

export function Cmd_loadData(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(submissionsApi().getSubmissions(query)), (_arg1) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export function init(page) {
    const query = new Common_PagingQuery$1(new Submissions_SubmissionFilter(empty(), empty(), empty()), 1, 20, void 0, getLocalizationContext());
    return [new State(new Deferred$1(0), void 0, page, query, empty(), Warehouse_list, SizeSubmission_list), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(0, query, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Msg(1, new AsyncOperationStatus$1(0)))]))];
}

export function update(msg, state) {
    let s, inputRecord_3, inputRecord_4, inputRecord_6, inputRecord_7, inputRecord_9, inputRecord_10;
    const remove_first = (pred, lst) => {
        let pattern_matching_result, h_1, t_1;
        if (!isEmpty(lst)) {
            if (pred(head(lst))) {
                pattern_matching_result = 0;
                h_1 = head(lst);
                t_1 = tail(lst);
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return t_1;
            }
            case 1: {
                if (!isEmpty(lst)) {
                    return cons(head(lst), remove_first(pred, tail(lst)));
                }
                else {
                    return empty();
                }
            }
        }
    };
    if (msg.tag === 0) {
        if (msg.fields[1].tag === 1) {
            if (!equals(msg.fields[0], state.Query)) {
                return [state, Cmd_none()];
            }
            else if (msg.fields[1].fields[0].tag === 1) {
                return [new State(new Deferred$1(3, msg.fields[1].fields[0].fields[0]), state.Error, state.CurrentPage, state.Query, state.TemplateCategories, state.LagerFilters, state.SizeFilters), Cmd_none()];
            }
            else {
                return [new State(new Deferred$1(2, msg.fields[1].fields[0].fields[0]), state.Error, state.CurrentPage, state.Query, state.TemplateCategories, state.LagerFilters, state.SizeFilters), Cmd_none()];
            }
        }
        else {
            return [new State(new Deferred$1(1), state.Error, state.CurrentPage, state.Query, state.TemplateCategories, state.LagerFilters, state.SizeFilters), Cmd_fromAsync(Cmd_loadData(msg.fields[0]))];
        }
    }
    else if (msg.tag === 2) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Common_PagingQuery$1(inputRecord.Payload, msg.fields[0], inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(state.LoadedSubmissions, state.Error, msg.fields[0], newQuery, state.TemplateCategories, state.LagerFilters, state.SizeFilters), Cmd_OfFunc_result(new Msg(0, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 3) {
        let newQuery_1;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result_1, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result_1 = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                const inputRecord_1 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, inputRecord_1.Page, inputRecord_1.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_1.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_2 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, inputRecord_2.Page, inputRecord_2.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_2.LocalizationContext));
                break;
            }
        }
        return [new State(state.LoadedSubmissions, state.Error, state.CurrentPage, newQuery_1, state.TemplateCategories, state.LagerFilters, state.SizeFilters), Cmd_OfFunc_result(new Msg(0, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 4) {
        let newQuery_2;
        const inputRecord_5 = state.Query;
        newQuery_2 = (new Common_PagingQuery$1((msg.fields[0].Id === CategoryIdModule_empty) ? ((inputRecord_3 = state.Query.Payload, new Submissions_SubmissionFilter(empty(), inputRecord_3.ByLager, inputRecord_3.BySize))) : ((inputRecord_4 = state.Query.Payload, new Submissions_SubmissionFilter(contains(msg.fields[0].Id, map((x_5) => x_5.Id, state.Query.Payload.ByCategories), {
            Equals: (x_6, y_1) => (x_6 === y_1),
            GetHashCode: (x_6) => numberHash(x_6),
        }) ? filter((y_2) => (y_2.Id !== msg.fields[0].Id), state.Query.Payload.ByCategories) : cons(msg.fields[0], state.Query.Payload.ByCategories), inputRecord_4.ByLager, inputRecord_4.BySize))), inputRecord_5.Page, inputRecord_5.PageSize, inputRecord_5.SortBy, inputRecord_5.LocalizationContext));
        return [new State(state.LoadedSubmissions, state.Error, state.CurrentPage, newQuery_2, state.TemplateCategories, state.LagerFilters, state.SizeFilters), Cmd_OfFunc_result(new Msg(0, newQuery_2, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 5) {
        let newQuery_3;
        const inputRecord_8 = state.Query;
        newQuery_3 = (new Common_PagingQuery$1((msg.fields[0] === "Alla") ? ((inputRecord_6 = state.Query.Payload, new Submissions_SubmissionFilter(inputRecord_6.ByCategories, empty(), inputRecord_6.BySize))) : ((inputRecord_7 = state.Query.Payload, new Submissions_SubmissionFilter(inputRecord_7.ByCategories, contains(msg.fields[0], state.Query.Payload.ByLager, {
            Equals: (x_8, y_3) => (x_8 === y_3),
            GetHashCode: (x_8) => stringHash(x_8),
        }) ? ((msg.fields[0] === "Lager A") ? filter((y_5) => (y_5 !== "WRH-A"), filter((y_4) => (y_4 !== msg.fields[0]), state.Query.Payload.ByLager)) : ((msg.fields[0] === "Lager B") ? filter((y_7) => (y_7 !== "WRH-B"), filter((y_6) => (y_6 !== msg.fields[0]), state.Query.Payload.ByLager)) : ((msg.fields[0] === "Lager C") ? filter((y_9) => (y_9 !== "WRH-C"), filter((y_8) => (y_8 !== msg.fields[0]), state.Query.Payload.ByLager)) : filter((y_10) => (y_10 !== msg.fields[0]), state.Query.Payload.ByLager)))) : ((msg.fields[0] === "Lager A") ? ofArrayWithTail(["WRH-A", msg.fields[0]], state.Query.Payload.ByLager) : ((msg.fields[0] === "Lager B") ? ofArrayWithTail(["WRH-B", msg.fields[0]], state.Query.Payload.ByLager) : ((msg.fields[0] === "Lager C") ? ofArrayWithTail(["WRH-C", msg.fields[0]], state.Query.Payload.ByLager) : cons(msg.fields[0], state.Query.Payload.ByLager)))), inputRecord_7.BySize))), inputRecord_8.Page, inputRecord_8.PageSize, inputRecord_8.SortBy, inputRecord_8.LocalizationContext));
        return [new State(state.LoadedSubmissions, state.Error, state.CurrentPage, newQuery_3, state.TemplateCategories, state.LagerFilters, state.SizeFilters), Cmd_OfFunc_result(new Msg(0, newQuery_3, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 6) {
        let newQuery_4;
        const inputRecord_11 = state.Query;
        newQuery_4 = (new Common_PagingQuery$1((msg.fields[0] === "Alla") ? ((inputRecord_9 = state.Query.Payload, new Submissions_SubmissionFilter(inputRecord_9.ByCategories, inputRecord_9.ByLager, empty()))) : ((inputRecord_10 = state.Query.Payload, new Submissions_SubmissionFilter(inputRecord_10.ByCategories, inputRecord_10.ByLager, contains(msg.fields[0], state.Query.Payload.BySize, {
            Equals: (x_10, y_11) => (x_10 === y_11),
            GetHashCode: (x_10) => stringHash(x_10),
        }) ? filter((y_12) => (y_12 !== msg.fields[0]), state.Query.Payload.BySize) : cons(msg.fields[0], state.Query.Payload.BySize)))), inputRecord_11.Page, inputRecord_11.PageSize, inputRecord_11.SortBy, inputRecord_11.LocalizationContext));
        return [new State(state.LoadedSubmissions, state.Error, state.CurrentPage, newQuery_4, state.TemplateCategories, state.LagerFilters, state.SizeFilters), Cmd_OfFunc_result(new Msg(0, newQuery_4, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [(() => {
                throw (new Error("Error with downloading warehouse categories"));
            })(), Cmd_none()];
        }
        else {
            return [new State(state.LoadedSubmissions, state.Error, state.CurrentPage, state.Query, sortBy_2((x_1) => Localized_translate(x_1.Name), cons(new WarehouseCategory(CategoryIdModule_empty, LocalizedStringModule_from(singleton_1([getPrimaryLanguage(), t_3("reports.allCategories")]))), msg.fields[0].fields[0].fields[0]), {
                Compare: (x_2, y) => comparePrimitives(x_2, y),
            }), state.LagerFilters, state.SizeFilters), Cmd_none()];
        }
    }
    else {
        return [new State(new Deferred$1(1), state.Error, state.CurrentPage, state.Query, state.TemplateCategories, state.LagerFilters, state.SizeFilters), Cmd_fromAsync(Cmd_loadCategories)];
    }
}

export function tableHeaderSubmission(state, dispatch) {
    let props_22, props_8, props_10, props_12, props_14, props_16, props_18;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(3, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_2(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_2(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    return createElement("div", {
        style: {
            marginBottom: 0.5 + "rem",
            paddingLeft: 1 + "rem",
            marginRight: 1 + "rem",
        },
        children: Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "px-0"], ["className", "mx-0"], ["className", "my-0"], ["className", "py-0"], ["className", "is-vcentered"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-1"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t_3("common.submission"), "id")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t_3("common.warehouseFee"), "warehouse")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t_3("common.startDate"), "date")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-1"], ["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t_3("common.size"), "size")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t_3("common.seller"), "seller")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_18 = ofArray([["className", "is-3"], ["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t_3("common.categories"), "tags")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-1"]))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_22))))]),
    });
}

export function Render(renderInputProps) {
    let props_1, s, props_23, props_6, elms, props_13, props_11, props_20, props_18, props_27;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderInputProps.page), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const elms_1 = ofArray([(props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t_3("common.warehouse"), createElement("h1", {
        className: "title",
        children: s,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1)))), (props_23 = ofArray([["className", "pt-0"], ["className", "pb-4"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "mb-6"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_1((tag) => {
        const props_4 = toList(delay(() => {
            const props_3 = contains(tag.Id, map((x) => x.Id, state_1.Query.Payload.ByCategories), {
                Equals: (x_1, y) => (x_1 === y),
                GetHashCode: (x_1) => numberHash(x_1),
            });
            return append(singleton_2(["onClick", (_arg1) => {
                dispatch(new Msg(4, tag));
            }]), delay(() => append(((tag.Id === CategoryIdModule_empty) ? isEmpty(state_1.Query.Payload.ByCategories) : false) ? singleton_2(["className", "is-primary"]) : (props_3 ? singleton_2(["className", "is-primary"]) : append(singleton_2(["className", "is-primary"]), delay(() => singleton_2(["className", "is-light"])))), delay(() => singleton_2(["children", Localized_translate(tag.Name)])))));
        }));
        return createElement("button", createObj(Helpers_combineClasses("button", props_4)));
    }, state_1.TemplateCategories)))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_6)))), (elms = ofArray([(props_13 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_1((tag_1) => {
        const props_9 = toList(delay(() => {
            const props_8 = contains(tag_1, state_1.Query.Payload.ByLager, {
                Equals: (x_2, y_1) => (x_2 === y_1),
                GetHashCode: (x_2) => stringHash(x_2),
            });
            return append(singleton_2(["onClick", (_arg2) => {
                dispatch(new Msg(5, tag_1));
            }]), delay(() => append(((tag_1 === "Alla") ? isEmpty(state_1.Query.Payload.ByLager) : false) ? singleton_2(["className", "is-primary"]) : (props_8 ? singleton_2(["className", "is-primary"]) : append(singleton_2(["className", "is-primary"]), delay(() => singleton_2(["className", "is-light"])))), delay(() => singleton_2(["children", tag_1])))));
        }));
        return createElement("button", createObj(Helpers_combineClasses("button", props_9)));
    }, state_1.LagerFilters)))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_11))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_20 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_1((tag_2) => {
        const props_16 = toList(delay(() => {
            const props_15 = contains(tag_2, state_1.Query.Payload.BySize, {
                Equals: (x_3, y_2) => (x_3 === y_2),
                GetHashCode: (x_3) => stringHash(x_3),
            });
            return append(singleton_2(["onClick", (_arg3) => {
                dispatch(new Msg(6, tag_2));
            }]), delay(() => append(((tag_2 === "Alla") ? isEmpty(state_1.Query.Payload.BySize) : false) ? singleton_2(["className", "is-primary"]) : (props_15 ? singleton_2(["className", "is-primary"]) : append(singleton_2(["className", "is-primary"]), delay(() => singleton_2(["className", "is-light"])))), delay(() => singleton_2(["children", tag_2])))));
        }));
        return createElement("button", createObj(Helpers_combineClasses("button", props_16)));
    }, state_1.SizeFilters)))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_23)))), (props_27 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let value_65;
        const matchValue = state_1.LoadedSubmissions;
        switch (matchValue.tag) {
            case 1: {
                return singleton_2(loader("big"));
            }
            case 3: {
                return singleton_2((value_65 = matchValue.fields[0].message, createElement("div", {
                    children: [value_65],
                })));
            }
            case 2: {
                const content = matchValue.fields[0];
                return append(singleton_2(tableHeaderSubmission(state_1, dispatch)), delay(() => {
                    let children_1;
                    return append(singleton_2((children_1 = toList(delay(() => map_1((submission) => renderItemsSubmission(submission, empty()), content.Result))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    }))), delay(() => singleton_2(renderPagination(content.Page, content.Total, content.PageSize, (arg) => {
                        dispatch(new Msg(2, arg));
                    }))));
                }));
            }
            default: {
                return singleton_2(null);
            }
        }
    }))))]]), createElement("section", createObj(Helpers_combineClasses("section", props_27))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

