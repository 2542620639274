import { Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, int32_type, bool_type, string_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { AuctionState, AuctionArg, AuctionResellLotState, Route, toPath, AuctionArg$reflection } from "../Router.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { RecycleLotInfo, AuctionModeType, ReturnLotInfo } from "../Shared/Shared.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { lotApi } from "../Communication.js";
import { createElement } from "react";
import * as react from "react";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { t } from "../Localization.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";

export class ReturnRecycleObject extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Item", "Lot"];
    }
}

export function ReturnRecycleObject$reflection() {
    return union_type("Components.ReturnOrRecycleObject.ReturnRecycleObject", [], ReturnRecycleObject, () => [[["itemId", class_type("System.Int64")], ["image", string_type], ["disabled", bool_type], ["isButton", bool_type]], [["itemId", class_type("System.Int64")], ["orderNumber", int32_type], ["title", string_type], ["image", string_type], ["disabled", bool_type], ["isButton", bool_type], ["Item7", class_type("System.Int64")], ["auctionPageAndFilterState", AuctionArg$reflection()]]]);
}

export function ReturnOrRecycleComponent(returnOrRecycleComponentInputProps) {
    const object = returnOrRecycleComponentInputProps.object;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput_1[1];
    const isLoading = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const reason = patternInput_2[0];
    const patternInput_3 = (object.tag === 1) ? [object.fields[0], object.fields[1], object.fields[2], object.fields[3], object.fields[4], object.fields[5], object.fields[7]] : [object.fields[0], void 0, void 0, object.fields[1], object.fields[2], object.fields[3], void 0];
    const title_1 = patternInput_3[2];
    const orderNumber_1 = patternInput_3[1];
    const itemId_2 = patternInput_3[0];
    const isButton_2 = patternInput_3[5];
    const disabled_2 = patternInput_3[4];
    const auctionPageAndFilterState_1 = patternInput_3[6];
    const returnLot = useReact_useCallback_93353E(() => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            setIsLoading(true);
            const info = new ReturnLotInfo(itemId_2, reason);
            return singleton.Bind(Remoting_handleNonAuth(lotApi().returnItem(info)), (_arg1) => {
                let auctionId_1, s_1, auctionId, s;
                if (_arg1.tag === 1) {
                    setIsLoading(false);
                    return singleton.Zero();
                }
                else {
                    setIsLoading(false);
                    if (isButton_2) {
                        document.location.replace((auctionPageAndFilterState_1 == null) ? toPath(new Route(3, new AuctionModeType(2), 1)) : ((auctionPageAndFilterState_1.tag === 1) ? ((auctionId_1 = auctionPageAndFilterState_1.fields[0], (s_1 = auctionPageAndFilterState_1.fields[1], toPath(new Route(2, new AuctionArg(1, auctionId_1, new AuctionResellLotState(s_1.IsUnsoldFilterOn, s_1.IsClaimedOrRefundedFilterOn, s_1.IsWithDrawnFilterOn, s_1.Page))))))) : ((auctionId = auctionPageAndFilterState_1.fields[0], (s = auctionPageAndFilterState_1.fields[1], toPath(new Route(2, new AuctionArg(0, auctionId, new AuctionState(s.IsUnsoldFilterOn, s.IsIncompleteFilterOn, s.Page)))))))));
                        return singleton.Zero();
                    }
                    else {
                        document.location.reload();
                        return singleton.Zero();
                    }
                }
            });
        }), (_arg2) => {
            setIsLoading(false);
            return singleton.Zero();
        })));
    }, [reason]);
    const recycleLot = useReact_useCallback_93353E(() => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            setIsLoading(true);
            const info_1 = new RecycleLotInfo(itemId_2, reason);
            return singleton.Bind(Remoting_handleNonAuth(lotApi().recycleItem(info_1)), (_arg3) => {
                let auctionId_3, s_3, auctionId_2, s_2;
                if (_arg3.tag === 1) {
                    setIsLoading(false);
                    return singleton.Zero();
                }
                else {
                    setIsLoading(false);
                    if (isButton_2) {
                        document.location.replace((auctionPageAndFilterState_1 == null) ? toPath(new Route(3, new AuctionModeType(2), 1)) : ((auctionPageAndFilterState_1.tag === 1) ? ((auctionId_3 = auctionPageAndFilterState_1.fields[0], (s_3 = auctionPageAndFilterState_1.fields[1], toPath(new Route(2, new AuctionArg(1, auctionId_3, new AuctionResellLotState(s_3.IsUnsoldFilterOn, s_3.IsClaimedOrRefundedFilterOn, s_3.IsWithDrawnFilterOn, s_3.Page))))))) : ((auctionId_2 = auctionPageAndFilterState_1.fields[0], (s_2 = auctionPageAndFilterState_1.fields[1], toPath(new Route(2, new AuctionArg(0, auctionId_2, new AuctionState(s_2.IsUnsoldFilterOn, s_2.IsIncompleteFilterOn, s_2.Page)))))))));
                        return singleton.Zero();
                    }
                    else {
                        document.location.reload();
                        return singleton.Zero();
                    }
                }
            });
        }), (_arg4) => {
            setIsLoading(false);
            return singleton.Zero();
        })));
    }, [reason]);
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props, props_5, props_3;
        return append(isButton_2 ? singleton_1((props = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(singleton_1(["disabled", disabled_2]), delay(() => append((!disabled_2) ? singleton_1(["onClick", (_arg1_1) => {
            setVisible(!isVisible);
        }]) : empty(), delay(() => singleton_1(["children", t("auction.deleteItem")]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props))))) : singleton_1((props_5 = ofArray([["style", {
            borderStyle: "none",
            padding: 0,
            height: 1.655 + "em",
        }], ["onClick", (_arg2_1) => {
            setVisible(!isVisible);
        }], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-trash-alt", "fa-lg"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_5))))), delay(() => {
            let props_53;
            return singleton_1((props_53 = toList(delay(() => append(isVisible ? singleton_1(["className", "is-active"]) : empty(), delay(() => append(singleton_1(["style", {
                overflow: "visible",
            }]), delay(() => {
                let props_51, props_16, props_10, s_4, props_14, props_31, elms, elms_1, props_28, props_26, props_41, elms_3, props_35, props_33, elms_2, props_37, value_106, props_49, props_43, props_45, props_47;
                return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_2(["onClick", (_arg3_1) => {
                    setVisible(!isVisible);
                }])))), (props_51 = ofArray([["className", "has-background-light"], ["style", {
                    borderRadius: 4,
                }], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "mx-5"], ["className", "mt-5"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-10"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(s_4 = t("auction.recycleOrReturnItem"), createElement("h4", {
                    className: "title is-4",
                    children: s_4,
                }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_14 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton_2(["onClick", (_arg4_1) => {
                    setVisible(!isVisible);
                }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_16)))), (props_31 = ofArray([["className", "my-4"], ["className", "mx-5"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => {
                    let s_5;
                    return append(singleton_1((s_5 = t("auction.recycleOrReturnItemConfirmation"), createElement("p", {
                        className: "",
                        children: s_5,
                    }))), delay(() => {
                        let orderNumber_2, props_19;
                        return append((orderNumber_1 == null) ? singleton_1(null) : ((orderNumber_2 = (orderNumber_1 | 0), singleton_1((props_19 = ofArray([["className", "has-text-weight-bold"], ["children", t("common.lotNumber") + (`: ${orderNumber_2}`)]]), createElement("p", createObj(Helpers_combineClasses("", props_19))))))), delay(() => {
                            let props_21;
                            if (title_1 == null) {
                                return singleton_1(null);
                            }
                            else {
                                const title_2 = title_1;
                                return singleton_1((props_21 = ofArray([["className", "has-text-weight-bold"], ["children", t("common.title") + (`: ${title_2}`)]]), createElement("p", createObj(Helpers_combineClasses("", props_21)))));
                            }
                        }));
                    }));
                })), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (elms_1 = singleton_2((props_28 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_26 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("figure", {
                    className: "is-16by9",
                    className: join(" ", ["image"]),
                    children: Interop_reactApi.Children.toArray([createElement("img", {
                        src: patternInput_3[3],
                        alt: "Image placeholder",
                    })]),
                })])]), createElement("div", createObj(Helpers_combineClasses("card-image", props_26))))])]), createElement("div", createObj(Helpers_combineClasses("card", props_28))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_31)))), (props_41 = ofArray([["className", "my-4"], ["className", "mx-5"], ["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_35 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_33 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.comment")]]), createElement("label", createObj(Helpers_combineClasses("label", props_33))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_35)))), (elms_2 = singleton_2((props_37 = ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["onChange", (ev) => {
                    patternInput_2[1](ev.target.value);
                }], (value_106 = reason, ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_106)) : false) {
                        e.value = value_106;
                    }
                }])]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_37))))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))]), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_41)))), (props_49 = ofArray([["className", "has-text-right"], ["className", "mx-5"], ["className", "mb-5"], ["children", Interop_reactApi.Children.toArray([(props_43 = toList(delay(() => append(singleton_1(["className", "mr-5"]), delay(() => append(singleton_1(["className", "is-danger"]), delay(() => (isLoading ? singleton_1(["className", "is-loading"]) : append(singleton_1(["children", t("auction.returnToSeller")]), delay(() => singleton_1(["onClick", (_arg5) => {
                    returnLot();
                    setVisible(!isVisible);
                }])))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_43)))), (props_45 = toList(delay(() => append(singleton_1(["className", "mr-5"]), delay(() => append(singleton_1(["className", "is-danger"]), delay(() => (isLoading ? singleton_1(["className", "is-loading"]) : append(singleton_1(["children", t("auction.recycleItem")]), delay(() => singleton_1(["onClick", (_arg6) => {
                    recycleLot();
                    setVisible(!isVisible);
                }])))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_45)))), (props_47 = ofArray([["className", "mr-3"], ["className", "is-dark"], ["disabled", isLoading], ["children", t("common.cancel")], ["onClick", (_arg7) => {
                    setVisible(!isVisible);
                }]]), createElement("button", createObj(Helpers_combineClasses("button", props_47))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_51))))])]);
            })))))), createElement("div", createObj(Helpers_combineClasses("modal", props_53)))));
        }));
    })));
}

