import { Convert_fromJson, Convert_serialize } from "./fable_modules/Fable.SimpleJson.3.20.0/Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.20.0/TypeInfo.Converter.fs.js";
import { SignIn_SignedInUser$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.20.0/SimpleJson.fs.js";
import { map, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import jwt_decode from "jwt-decode";

export function saveUser(user) {
    localStorage.setItem("loggedUser", Convert_serialize(user, createTypeInfo(SignIn_SignedInUser$reflection())));
}

export function removeUser() {
    localStorage.removeItem("loggedUser");
}

export function getUser() {
    let matchValue;
    let matchValue_1;
    const arg00 = localStorage.getItem("loggedUser");
    try {
        matchValue_1 = (new FSharpResult$2(0, (matchValue = SimpleJson_tryParse(arg00), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(SignIn_SignedInUser$reflection())) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })())));
    }
    catch (ex) {
        matchValue_1 = (new FSharpResult$2(1, ex.message));
    }
    if (matchValue_1.tag === 1) {
        return void 0;
    }
    else {
        return matchValue_1.fields[0];
    }
}

export function getToken() {
    return defaultArg(map((x) => x.AccessToken, getUser()), "");
}

export function isCurrentUserAdmin() {
    return defaultArg(map((user) => (jwt_decode(user.AccessToken)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] === "admin"), getUser()), false);
}

export function isCurrentUserManager() {
    return defaultArg(map((user) => (jwt_decode(user.AccessToken)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] === "manager"), getUser()), false);
}

export function setItem(key, value) {
    localStorage.setItem(key, value);
}

export function tryGetItem(key) {
    const value = localStorage.getItem(key);
    if (value == null) {
        return void 0;
    }
    else {
        return value;
    }
}

