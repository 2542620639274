import { toString, Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { string_type, union_type, unit_type, option_type, list_type, tuple_type, enum_type, record_type, class_type, int32_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Orders_Delivery_DataToUpdate, Orders_Delivery_IsToUpdate$1, Orders_Delivery_OrderDeliveryStatus, Orders_Delivery_ShippingCalendarQuery, Orders_Delivery_ShippingCalendarWeekQuery, ToggleFilters_Zone, ToggleFilters_ToggleZone, Orders_Delivery_DataToUpdate$reflection, Orders_Delivery_ShippingCalendarWeekInfo$reflection, Orders_Delivery_ShippingCalendarQuery$reflection, SettingsFreight_FreightCostBasicSetting$reflection, ToggleFilters_ToggleZone$reflection, Orders_Delivery_Order$reflection } from "./Shared/Shared.js";
import { Deferred_map, Deferred$1, Deferred$1$reflection } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { find, indexed, tryHead, cons, filter, ofSeq, ofArray, empty, singleton as singleton_1, append, map } from "./fable_modules/fable-library.3.6.1/List.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { ordersApi, settingsApi } from "./Communication.js";
import { getCurrentLanguage, t, Localized_translate } from "./Localization.js";
import { dayOfWeek as dayOfWeek_2, toLocalTime as toLocalTime_1, fromDate } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { format as format_1, getISOWeek, endOfDay, startOfDay, addDays, startOfISOWeek } from "date-fns";
import { day as day_2, dayOfWeek as dayOfWeek_1, toLocalTime, now, fromDateTimeOffset } from "./fable_modules/fable-library.3.6.1/Date.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { countBy } from "./fable_modules/fable-library.3.6.1/Seq2.js";
import { createObj, equals, numberHash } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Common_Sorting, Common_SortingModule_toggle } from "./Shared/Common.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map as map_1, collect, empty as empty_1, append as append_1, singleton as singleton_2, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { value as value_119 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Value, EnableWeekNumbers, Locale, OnChange, Disabled, ClassName, custom, DateFormat, Id, flatpickr } from "./fable_modules/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import { Impl_createRemoveOptions, Impl_adjustPassive } from "./fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { sv } from "date-fns/locale";
import { OrderIdModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { Delivery_addressToString } from "./Common.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { LangModule_swedish } from "./bidflow/src/infrastructure/Localization.js";
import { CalendarShippingToPrint_toPrintSection } from "./Print/PrintButtons.js";
import { dividedTop } from "./Components/BorderDividedLine.js";
import { loader } from "./Loader.js";

export class WeekOverview extends Record {
    constructor(WeekNumber, FlatpickrSelectedDay) {
        super();
        this.WeekNumber = (WeekNumber | 0);
        this.FlatpickrSelectedDay = FlatpickrSelectedDay;
    }
}

export function WeekOverview$reflection() {
    return record_type("CalendarShipping.WeekOverview", [], WeekOverview, () => [["WeekNumber", int32_type], ["FlatpickrSelectedDay", class_type("System.DateTime")]]);
}

export class WeekOrdersCount extends Record {
    constructor(AllDeliveryOrders, FilteredDeliveryOrders) {
        super();
        this.AllDeliveryOrders = AllDeliveryOrders;
        this.FilteredDeliveryOrders = FilteredDeliveryOrders;
    }
}

export function WeekOrdersCount$reflection() {
    return record_type("CalendarShipping.WeekOrdersCount", [], WeekOrdersCount, () => [["AllDeliveryOrders", list_type(tuple_type(enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]]), int32_type))], ["FilteredDeliveryOrders", list_type(tuple_type(enum_type("System.DayOfWeek", int32_type, [["Sunday", 0], ["Monday", 1], ["Tuesday", 2], ["Wednesday", 3], ["Thursday", 4], ["Friday", 5], ["Saturday", 6]]), int32_type))]]);
}

export class State extends Record {
    constructor(DayOnWeek, WeekOverview, LoadedOrders, LoadedWeekOrdersCount, ActiveDeliveryZones, AllDeliveryZones, Query, Processing) {
        super();
        this.DayOnWeek = DayOnWeek;
        this.WeekOverview = WeekOverview;
        this.LoadedOrders = LoadedOrders;
        this.LoadedWeekOrdersCount = LoadedWeekOrdersCount;
        this.ActiveDeliveryZones = ActiveDeliveryZones;
        this.AllDeliveryZones = AllDeliveryZones;
        this.Query = Query;
        this.Processing = Processing;
    }
}

export function State$reflection() {
    return record_type("CalendarShipping.State", [], State, () => [["DayOnWeek", option_type(class_type("System.DateTime"))], ["WeekOverview", WeekOverview$reflection()], ["LoadedOrders", Deferred$1$reflection(list_type(Orders_Delivery_Order$reflection()))], ["LoadedWeekOrdersCount", Deferred$1$reflection(WeekOrdersCount$reflection())], ["ActiveDeliveryZones", Deferred$1$reflection(list_type(ToggleFilters_ToggleZone$reflection()))], ["AllDeliveryZones", list_type(SettingsFreight_FreightCostBasicSetting$reflection())], ["Query", Orders_Delivery_ShippingCalendarQuery$reflection()], ["Processing", Deferred$1$reflection(unit_type)]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadOrders", "LoadDeliveryZones", "LoadWeekOrdersCount", "UpdateChanges", "ChangeDeliveryZone", "OnDayClicked", "WeekChanged", "OnColumnClicked"];
    }
}

export function Msg$reflection() {
    return union_type("CalendarShipping.Msg", [], Msg, () => [[["Item1", Orders_Delivery_ShippingCalendarQuery$reflection()], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Orders_Delivery_Order$reflection()), class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", list_type(Orders_Delivery_Order$reflection())]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item", AsyncOperationStatus$1$reflection(tuple_type(list_type(SettingsFreight_FreightCostBasicSetting$reflection()), list_type(ToggleFilters_ToggleZone$reflection())))]], [["Item1", class_type("System.DateTime")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Orders_Delivery_ShippingCalendarWeekInfo$reflection(), class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", Orders_Delivery_ShippingCalendarWeekInfo$reflection()]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item1", Orders_Delivery_DataToUpdate$reflection()], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item", ToggleFilters_ToggleZone$reflection()]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", string_type]]]);
}

export function Cmd_defaultDeliveryZones(zones) {
    return map((x) => {
        const matchValue = x.Zone;
        if (matchValue == null) {
            return new ToggleFilters_ToggleZone(x.Zone, true);
        }
        else {
            const z = matchValue;
            return new ToggleFilters_ToggleZone(x.Zone, false);
        }
    }, zones);
}

export function Cmd_loadDeliveryZones() {
    return singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getAllFreightZones()), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getActiveFreightZones()), (_arg2) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, [_arg1, append(singleton_1(new ToggleFilters_ToggleZone(void 0, true)), map((x) => (new ToggleFilters_ToggleZone(new ToggleFilters_Zone(x.DeliveryCostZoneId, Localized_translate(x.Name)), false)), _arg2))]))))));
}

export function Cmd_loadOrders(arg) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(ordersApi().getDeliveryOrdersByDay(arg)), (_arg1) => singleton.Return(new Msg(0, arg, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(0, arg, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export function Cmd_loadWeekOrdersCount(query, dateOnWeek) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const arg = new Orders_Delivery_ShippingCalendarWeekQuery(query.FilterByDeliveryZone, fromDate(startOfISOWeek(dateOnWeek)), fromDate(addDays(fromDate(startOfISOWeek(dateOnWeek)), 6)));
        return singleton.Bind(Remoting_handleNonAuth(ordersApi().getWeekDeliveryOrdersCountPerDay(arg)), (_arg1) => singleton.Return(new Msg(2, fromDateTimeOffset(dateOnWeek, 0), new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))));
    }), (_arg2) => singleton.Return(new Msg(2, fromDateTimeOffset(dateOnWeek, 0), new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export function Cmd_updateChanges(info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(ordersApi().updateChanges(info), () => singleton.Return(new Msg(3, info, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))), (_arg2) => singleton.Return(new Msg(3, info, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export function init() {
    let WeekOverview_1;
    const query = new Orders_Delivery_ShippingCalendarQuery(empty(), fromDate(startOfDay(now())), fromDate(endOfDay(now())), void 0);
    return [(WeekOverview_1 = (new WeekOverview(getISOWeek(now()), now())), new State(now(), WeekOverview_1, new Deferred$1(0), new Deferred$1(0), new Deferred$1(0), empty(), query, new Deferred$1(0))), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(2, now(), new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Msg(1, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Msg(0, query, new AsyncOperationStatus$1(0)))]))];
}

export function update(msg, state) {
    let s, matchValue_7;
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, state.LoadedWeekOrdersCount, new Deferred$1(2, msg.fields[0].fields[0][1]), msg.fields[0].fields[0][0], state.Query, state.Processing), Cmd_none()];
        }
        else {
            return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, state.LoadedWeekOrdersCount, new Deferred$1(1), state.AllDeliveryZones, state.Query, state.Processing), Cmd_fromAsync(Cmd_loadDeliveryZones())];
        }
    }
    else if (msg.tag === 2) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, new Deferred$1(3, msg.fields[1].fields[0].fields[0]), state.ActiveDeliveryZones, state.AllDeliveryZones, state.Query, state.Processing), Cmd_none()];
            }
            else {
                const transport = msg.fields[1].fields[0].fields[0];
                return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, new Deferred$1(2, new WeekOrdersCount(ofSeq(countBy((x_1) => {
                    let copyOfStruct = toLocalTime(x_1);
                    return dayOfWeek_1(copyOfStruct) | 0;
                }, transport.AllDeliveryOrders, {
                    Equals: (x_2, y) => (x_2 === y),
                    GetHashCode: (x_2) => numberHash(x_2),
                })), ofSeq(countBy((x_3) => {
                    let copyOfStruct_1 = toLocalTime_1(x_3);
                    return dayOfWeek_2(copyOfStruct_1) | 0;
                }, transport.FilteredDeliveryOrders, {
                    Equals: (x_4, y_1) => (x_4 === y_1),
                    GetHashCode: (x_4) => numberHash(x_4),
                })))), state.ActiveDeliveryZones, state.AllDeliveryZones, state.Query, state.Processing), Cmd_none()];
            }
        }
        else {
            return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, new Deferred$1(1), state.ActiveDeliveryZones, state.AllDeliveryZones, state.Query, state.Processing), Cmd_fromAsync(Cmd_loadWeekOrdersCount(state.Query, fromDate(msg.fields[0])))];
        }
    }
    else if (msg.tag === 5) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Orders_Delivery_ShippingCalendarQuery(inputRecord.FilterByDeliveryZone, fromDate(startOfDay(msg.fields[0])), fromDate(endOfDay(msg.fields[0])), inputRecord.SortBy));
        return [new State(msg.fields[0], state.WeekOverview, state.LoadedOrders, state.LoadedWeekOrdersCount, state.ActiveDeliveryZones, state.AllDeliveryZones, newQuery, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 4) {
        if (state.ActiveDeliveryZones.tag === 2) {
            let newQuery_1;
            const matchValue_1 = msg.fields[0].Zone;
            if (matchValue_1 != null) {
                const z = matchValue_1;
                if (msg.fields[0].IsCheck) {
                    const inputRecord_2 = state.Query;
                    newQuery_1 = (new Orders_Delivery_ShippingCalendarQuery(filter((x_5) => (x_5 !== z.Id), state.Query.FilterByDeliveryZone), inputRecord_2.StartDay, inputRecord_2.EndDay, inputRecord_2.SortBy));
                }
                else {
                    const inputRecord_3 = state.Query;
                    newQuery_1 = (new Orders_Delivery_ShippingCalendarQuery(cons(z.Id, state.Query.FilterByDeliveryZone), inputRecord_3.StartDay, inputRecord_3.EndDay, inputRecord_3.SortBy));
                }
            }
            else {
                const inputRecord_1 = state.Query;
                newQuery_1 = (new Orders_Delivery_ShippingCalendarQuery(empty(), inputRecord_1.StartDay, inputRecord_1.EndDay, inputRecord_1.SortBy));
            }
            let cmd;
            const matchValue_3 = state.LoadedOrders;
            cmd = ((matchValue_3.tag === 2) ? ((equals(matchValue_3.fields[0], empty()) ? equals(state.DayOnWeek, void 0) : false) ? Cmd_OfFunc_result(new Msg(2, state.WeekOverview.FlatpickrSelectedDay, new AsyncOperationStatus$1(0))) : Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(2, state.WeekOverview.FlatpickrSelectedDay, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Msg(0, newQuery_1, new AsyncOperationStatus$1(0)))]))) : Cmd_none());
            const matchValue_4 = msg.fields[0].Zone;
            if (matchValue_4 != null) {
                const selectedZone = matchValue_4;
                return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, state.LoadedWeekOrdersCount, Deferred_map((x_6) => map((x_7) => {
                    const matchValue_5 = x_7.Zone;
                    if (matchValue_5 == null) {
                        return new ToggleFilters_ToggleZone(x_7.Zone, false);
                    }
                    else if (equals(matchValue_5, selectedZone)) {
                        return new ToggleFilters_ToggleZone(x_7.Zone, !x_7.IsCheck);
                    }
                    else {
                        return x_7;
                    }
                }, x_6), state.ActiveDeliveryZones), state.AllDeliveryZones, newQuery_1, state.Processing), cmd];
            }
            else {
                return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, state.LoadedWeekOrdersCount, Deferred_map((zones_1) => Cmd_defaultDeliveryZones(zones_1), state.ActiveDeliveryZones), state.AllDeliveryZones, newQuery_1, state.Processing), cmd];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 6) {
        return [new State(void 0, new WeekOverview(getISOWeek(msg.fields[0]), msg.fields[0]), new Deferred$1(2, empty()), state.LoadedWeekOrdersCount, state.ActiveDeliveryZones, state.AllDeliveryZones, state.Query, state.Processing), Cmd_OfFunc_result(new Msg(2, msg.fields[0], new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 3) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, state.LoadedWeekOrdersCount, state.ActiveDeliveryZones, state.AllDeliveryZones, state.Query, new Deferred$1(3, msg.fields[1].fields[0].fields[0])), Cmd_none()];
            }
            else {
                return init();
            }
        }
        else {
            return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, state.LoadedWeekOrdersCount, state.ActiveDeliveryZones, state.AllDeliveryZones, state.Query, new Deferred$1(1)), Cmd_fromAsync(Cmd_updateChanges(msg.fields[0]))];
        }
    }
    else if (msg.tag === 7) {
        let newQuery_2;
        const matchValue_6 = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue_6 != null) {
            if ((s = matchValue_6[1], msg.fields[0] === matchValue_6[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue_6[0];
                s_1 = matchValue_6[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_5 = state.Query;
                newQuery_2 = (new Orders_Delivery_ShippingCalendarQuery(inputRecord_5.FilterByDeliveryZone, inputRecord_5.StartDay, inputRecord_5.EndDay, [msg.fields[0], Common_SortingModule_toggle(s_1)]));
                break;
            }
            case 1: {
                const inputRecord_6 = state.Query;
                newQuery_2 = (new Orders_Delivery_ShippingCalendarQuery(inputRecord_6.FilterByDeliveryZone, inputRecord_6.StartDay, inputRecord_6.EndDay, [msg.fields[0], new Common_Sorting(0)]));
                break;
            }
        }
        return [new State(state.DayOnWeek, state.WeekOverview, state.LoadedOrders, state.LoadedWeekOrdersCount, state.ActiveDeliveryZones, state.AllDeliveryZones, newQuery_2, state.Processing), (matchValue_7 = state.LoadedOrders, (matchValue_7.tag === 2) ? ((equals(matchValue_7.fields[0], empty()) ? equals(state.DayOnWeek, void 0) : false) ? Cmd_none() : Cmd_OfFunc_result(new Msg(0, newQuery_2, new AsyncOperationStatus$1(0)))) : Cmd_none())];
    }
    else if (msg.fields[1].tag === 1) {
        if (msg.fields[1].fields[0].tag === 1) {
            return [new State(state.DayOnWeek, state.WeekOverview, new Deferred$1(3, msg.fields[1].fields[0].fields[0]), state.LoadedWeekOrdersCount, state.ActiveDeliveryZones, state.AllDeliveryZones, state.Query, state.Processing), Cmd_none()];
        }
        else {
            return [new State(state.DayOnWeek, state.WeekOverview, new Deferred$1(2, msg.fields[1].fields[0].fields[0]), state.LoadedWeekOrdersCount, state.ActiveDeliveryZones, state.AllDeliveryZones, state.Query, state.Processing), Cmd_none()];
        }
    }
    else {
        return [new State(state.DayOnWeek, state.WeekOverview, new Deferred$1(1), state.LoadedWeekOrdersCount, state.ActiveDeliveryZones, state.AllDeliveryZones, state.Query, state.Processing), Cmd_fromAsync(Cmd_loadOrders(msg.fields[0]))];
    }
}

export function tableHeader(state, dispatch) {
    let props_22, props_8, props_10, props_12, props_14, props_16, props_18;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(7, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_2(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_2(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    const props_24 = ofArray([["className", "is-fluid"], ["className", "mb-2"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "tableHeader"], ["className", "is-gapless"], ["className", "px-0"], ["className", "is-1"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("orders.buyer"), "name")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("orders.phoneNumber"), "phone")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("orders.deliveryDate"), "deliveryDate")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("orders.orderId"), "orderId")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("orders.phoneNumber"), "provider")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_18 = ofArray([["className", "is-3"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("orders.address"), "address")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-1"]))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_22))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_24)));
}

export function EditElement(editElementInputProps) {
    let props_30, props_4, elms_1, elms, props, props_13, props_11, props_6, elms_2, props_8, props_23, elms_3, props_20, patternInput_4, props_28, elms_4, props_25, props_55;
    const updateFunc = editElementInputProps.updateFunc;
    const isProcessing = editElementInputProps.isProcessing;
    const order = editElementInputProps.order;
    const patternInput = useFeliz_React__React_useState_Static_1505(order.DeliveryDate);
    const setDate = patternInput[1];
    const date = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(order.FreightId);
    const freightId = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(order.Status);
    const status = patternInput_2[0];
    const originalInfo = {
        Date: order.DeliveryDate,
        FreightId: order.FreightId,
        Status: order.Status,
    };
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setConfirmation = patternInput_3[1];
    const isConfirmation = patternInput_3[0];
    return react.createElement(react.Fragment, {}, (props_30 = ofArray([["className", "has-text-right"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = singleton_1((props = ofArray([["placeholder", t("orders.deliveryExternalId")], ["defaultValue", (freightId != null) ? value_119(freightId) : ""], ["onChange", (ev) => {
        patternInput_1[1](ev.target.value);
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_13 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "has-addons"], ["style", {
        justifyContent: "center",
    }], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", join(" ", ["is-expanded"])], ["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([Id(`DeliveryDate${order.Id}`), DateFormat("d M Y"), custom("value", date, false), custom("locale", {
        firstDayOfWeek: 1,
    }, true), ClassName("input"), Disabled(false), OnChange((x_1) => {
        setDate(fromDate(x_1));
    }), Locale(Swedish)]))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_6)))), (elms_2 = singleton_1((props_8 = ofArray([["className", "is-primary"], ["children", t("common.clear")], ["onClick", (x_2) => {
        x_2.preventDefault();
        setDate(void 0);
        const eventType = "DOMContentLoaded";
        const action_1 = (_arg1) => {
            document.getElementById(`DeliveryDate${order.Id}`).flatpickr().clear();
        };
        const options_1 = void 0;
        const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_1), [options_1]);
        const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_1), [options_1]);
        const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
            action_1(arg);
        }), [action_1]);
        useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
            if (addOptions == null) {
                document.addEventListener(eventType, fn);
            }
            else {
                document.addEventListener(eventType, fn, addOptions);
            }
            return React_createDisposable_3A5B6456(() => {
                if (removeOptions == null) {
                    document.removeEventListener(eventType, fn);
                }
                else {
                    document.removeEventListener(eventType, fn, removeOptions);
                }
            });
        }));
    }]]), createElement("button", createObj(Helpers_combineClasses("button", props_8))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_23 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_1((props_20 = singleton_1(["children", Interop_reactApi.Children.toArray([(patternInput_4 = Helpers_extractClasses(ofArray([["className", join(" ", ["fa"])], ["className", "has-text-left"], ["className", "is-size-6"], ["className", "mb-1"], ["className", "has-text-grey"], ["onChange", (ev_1) => {
        let s;
        patternInput_2[1]((s = ev_1.target.value, (s === "Unpacked") ? (new Orders_Delivery_OrderDeliveryStatus(0)) : ((s === "Packed") ? (new Orders_Delivery_OrderDeliveryStatus(1)) : ((s === "Delivered") ? (new Orders_Delivery_OrderDeliveryStatus(2)) : (() => {
            throw (new Error("Can\u0027t find order delivery status"));
        })()))));
    }], ["defaultValue", toString(status)], ["children", Interop_reactApi.Children.toArray(Array.from(map((x_4) => createElement("option", {
        value: toString(x_4),
        children: toString(x_4),
    }), ofArray([new Orders_Delivery_OrderDeliveryStatus(0), new Orders_Delivery_OrderDeliveryStatus(1), new Orders_Delivery_OrderDeliveryStatus(2)]))))]])), createElement("div", {
        className: join(" ", cons("select", patternInput_4[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_4[1]))]),
    }))])]), createElement("div", createObj(Helpers_combineClasses("control", props_20))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_23)))), (props_28 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(elms_4 = singleton_1((props_25 = toList(delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(equals({
        Date: date,
        FreightId: freightId,
        Status: status,
    }, originalInfo) ? singleton_2(["disabled", true]) : append_1(singleton_2(["disabled", false]), delay(() => singleton_2(["onClick", (x_5) => {
        x_5.preventDefault();
        setConfirmation(!isConfirmation);
    }]))), delay(() => singleton_2(["children", t("common.save")]))))))), createElement("a", createObj(Helpers_combineClasses("button", props_25))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_28))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_30)))), (props_55 = toList(delay(() => append_1(isConfirmation ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => {
        let props_53, props_51, elms_6, elms_5, props_34, props_39, s_1, props_49, props_47, props_43, props_45;
        return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg2) => {
            setConfirmation(!isConfirmation);
        }])))), (props_53 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_51 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_6 = ofArray([(elms_5 = singleton_1((props_34 = singleton_1(["children", t("orders.updateOrderInformation")]), createElement("span", createObj(Helpers_combineClasses("", props_34))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        })), (props_39 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg3) => {
            setConfirmation(!isConfirmation);
        }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_39))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
        })), (s_1 = t("orders.updateOrderInformationConfirmation"), createElement("p", {
            className: "",
            children: s_1,
        })), (props_49 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_47 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_43 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["onClick", (_arg4) => {
            setConfirmation(!isConfirmation);
        }]]), createElement("a", createObj(Helpers_combineClasses("button", props_43)))), (props_45 = toList(delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(equals(isProcessing, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append_1(singleton_2(["children", t("common.update")]), delay(() => singleton_2(["onClick", (_arg5) => {
            updateFunc(new Orders_Delivery_DataToUpdate(order.UserId, order.Id, equals(date, originalInfo.Date) ? (new Orders_Delivery_IsToUpdate$1(1)) : (new Orders_Delivery_IsToUpdate$1(0, date)), equals(freightId, originalInfo.FreightId) ? (new Orders_Delivery_IsToUpdate$1(1)) : (new Orders_Delivery_IsToUpdate$1(0, freightId)), equals(status, originalInfo.Status) ? (new Orders_Delivery_IsToUpdate$1(1)) : (new Orders_Delivery_IsToUpdate$1(0, status))));
        }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_45))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_47))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_49))))])]), createElement("div", createObj(Helpers_combineClasses("box", props_51))))])]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_53))))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("modal", props_55)))));
}

export function personInfo(order, deliveryZone, isVisible, setVisibleFunction) {
    let props, props_2, props_4, date, copyOfStruct, locale, formatOptions, props_6, props_8, props_10, props_15, props_13;
    const props_17 = ofArray([["className", "is-vcentered"], ["className", "px-0"], ["className", "is-size-6"], ["className", "is-gapless"], ["className", "is-1"], ["style", {
        cursor: "pointer",
    }], ["onClick", (_arg1) => {
        setVisibleFunction(!isVisible);
    }], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-2"], ["style", {
        whiteSpace: "pre-wrap",
    }], ["className", "is-uppercase"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", order.PersonalData.Name]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-2"], ["className", "has-text-grey"], ["children", `${order.PersonalData.Phone}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "is-2"], ["children", (order.DeliveryDate != null) ? ((date = ((copyOfStruct = value_119(order.DeliveryDate), toLocalTime_1(copyOfStruct))), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_1(date, "dd MMMM yyyy", formatOptions)))))) : ""]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-1"], ["children", OrderIdModule_toString(order.Id)]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-1"], ["children", Localized_translate(deliveryZone.Name)]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-3"], ["className", "has-text-right"], ["className", "has-text-grey"], ["style", {
        whiteSpace: "pre-wrap",
    }], ["children", Delivery_addressToString(order.Delivery)]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_15 = ofArray([["className", "is-1"], ["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (isVisible ? singleton_2(["className", join(" ", ["fas", "fa-angle-up"])]) : singleton_2(["className", join(" ", ["fas", "fa-angle-down"])]))))))])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_17)));
}

export function RenderItems(renderItemsInputProps) {
    const dispatch = renderItemsInputProps.dispatch;
    const state = renderItemsInputProps.state;
    const deliveryZone = renderItemsInputProps.deliveryZone;
    const order = renderItemsInputProps.order;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    const props_1 = ofArray([["className", "is-fluid"], ["className", "has-background-white"], ["className", "mb-2"], ["className", "py-3"], ["className", "is-size-6"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (isVisible ? singleton_2(createElement("div", {
        children: Interop_reactApi.Children.toArray([personInfo(order, deliveryZone, isVisible, setVisible), createElement(EditElement, {
            order: order,
            isProcessing: state.Processing,
            updateFunc: (x) => {
                dispatch(new Msg(3, x, new AsyncOperationStatus$1(0)));
            },
        })]),
    })) : singleton_2(personInfo(order, deliveryZone, isVisible, setVisible)))))))]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_1)));
}

export function ButtonFilterZone(buttonFilterZoneInputProps) {
    const unitVar2 = buttonFilterZoneInputProps.unitVar2;
    const dispatch = buttonFilterZoneInputProps.dispatch;
    const zone = buttonFilterZoneInputProps.zone;
    const props = toList(delay(() => append_1(singleton_2(["className", "mr-0"]), delay(() => append_1(singleton_2(["className", "ml-2"]), delay(() => append_1((!zone.IsCheck) ? append_1(singleton_2(["className", "is-primary"]), delay(() => singleton_2(["className", "is-light"]))) : singleton_2(["className", "is-primary"]), delay(() => {
        let matchValue;
        return append_1((matchValue = zone.Zone, (matchValue != null) ? singleton_2(["children", matchValue.Name]) : singleton_2(["children", t("common.all")])), delay(() => singleton_2(["onClick", (_arg1) => {
            dispatch(new Msg(4, zone));
        }])));
    }))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export function Render() {
    let elms_1, elms, props_2, props, props_8, props_6, props_4, props_38;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const elms_4 = ofArray([(elms_1 = singleton_1((elms = ofArray([(props_2 = ofArray([["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-uppercase"], ["className", "is-size-3"], ["children", t("orders.shippingCalendar")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_8 = ofArray([["className", "has-text-right"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([flatpickr(toList(delay(() => append_1(singleton_2(DateFormat("Vecka - W")), delay(() => append_1(singleton_2(custom("locale", {
        firstDayOfWeek: 1,
    }, true)), delay(() => append_1(singleton_2(EnableWeekNumbers(true)), delay(() => append_1(singleton_2(ClassName("input")), delay(() => append_1(singleton_2(OnChange((arg) => {
        dispatch(new Msg(6, arg));
    })), delay(() => append_1((getCurrentLanguage() === LangModule_swedish) ? singleton_2(Locale(Swedish)) : empty_1(), delay(() => ((state_1.WeekOverview.WeekNumber === getISOWeek(now())) ? singleton_2(Value(now())) : empty_1()))))))))))))))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), CalendarShippingToPrint_toPrintSection(state_1.LoadedOrders, state_1.AllDeliveryZones)])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_38 = ofArray([dividedTop, ["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_27;
        return append_1(singleton_2((props_27 = toList(delay(() => append_1(singleton_2(["className", "is-variable"]), delay(() => append_1(singleton_2(["className", "is-1"]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let value_84;
            const matchValue = state_1.LoadedWeekOrdersCount;
            switch (matchValue.tag) {
                case 1: {
                    return singleton_2(loader("big"));
                }
                case 3: {
                    return singleton_2((value_84 = matchValue.fields[0].message, createElement("div", {
                        children: [value_84],
                    })));
                }
                case 2: {
                    const info = matchValue.fields[0];
                    return collect((matchValue_1) => {
                        let elms_2, isDayClicked, matchValue_2, d, copyOfStruct, addDaysCount, countFilteredDelivery, daysList_1, countAllDelivery, daysList, props_23;
                        const i = matchValue_1[0] | 0;
                        const day_1 = matchValue_1[1] | 0;
                        return singleton_2((elms_2 = singleton_1((isDayClicked = ((matchValue_2 = state_1.DayOnWeek, (matchValue_2 == null) ? false : ((d = matchValue_2, ((copyOfStruct = addDays(startOfISOWeek(state_1.WeekOverview.FlatpickrSelectedDay), i), day_2(copyOfStruct))) === day_2(d))))), (addDaysCount = (i | 0), (countFilteredDelivery = (((daysList_1 = tryHead(map((tuple_1) => tuple_1[1], filter((tupledArg_1) => (tupledArg_1[0] === day_1), info.FilteredDeliveryOrders))), equals(daysList_1, void 0) ? 0 : value_119(daysList_1))) | 0), (countAllDelivery = (((daysList = tryHead(map((tuple) => tuple[1], filter((tupledArg) => (tupledArg[0] === day_1), info.AllDeliveryOrders))), equals(daysList, void 0) ? 0 : value_119(daysList))) | 0), (props_23 = toList(delay(() => append_1(((addDaysCount === 5) ? true : (addDaysCount === 6)) ? (isDayClicked ? singleton_2(["style", {
                            cursor: "pointer",
                            border: (((1 + "px ") + "solid") + " ") + "#19bb9c",
                        }]) : singleton_2(["style", {
                            cursor: "pointer",
                            borderTop: (((1 + "px ") + "solid") + " ") + "#19bb9c",
                        }])) : (isDayClicked ? singleton_2(["style", {
                            cursor: "pointer",
                            border: (((1 + "px ") + "solid") + " ") + "#19bb9c",
                        }]) : singleton_2(["style", {
                            cursor: "pointer",
                        }])), delay(() => append_1(singleton_2(["className", "is-size-6"]), delay(() => append_1(singleton_2(["className", "px-2"]), delay(() => append_1(singleton_2(["className", "py-2"]), delay(() => append_1(singleton_2(["onClick", (x) => {
                            const date_3 = addDays(startOfISOWeek(state_1.WeekOverview.FlatpickrSelectedDay), addDaysCount);
                            x.preventDefault();
                            dispatch(new Msg(5, date_3));
                        }]), delay(() => {
                            let s, day, props_13, date_6, locale, formatOptions, props_21, props_19, props_15, props_17;
                            return singleton_2(["children", Interop_reactApi.Children.toArray([(s = (`${((day = (day_1 | 0), (day === 0) ? t("common.weekdays.sunday") : ((day === 1) ? t("common.weekdays.monday") : ((day === 2) ? t("common.weekdays.tuesday") : ((day === 3) ? t("common.weekdays.wednesday") : ((day === 4) ? t("common.weekdays.thursday") : ((day === 5) ? t("common.weekdays.friday") : ((day === 6) ? t("common.weekdays.saturday") : "Error day"))))))))}`), createElement("p", {
                                className: "",
                                children: s,
                            })), (props_13 = ofArray([["className", "pb-3"], ["className", "has-text-weight-bold"], ["children", (date_6 = addDays(startOfISOWeek(state_1.WeekOverview.FlatpickrSelectedDay), addDaysCount), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_1(date_6, "d MMM yyyy", formatOptions)))))]]), createElement("p", createObj(Helpers_combineClasses("", props_13)))), (props_21 = ofArray([["className", "mb-0"], ["className", "is-gapless"], ["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", "pb-0"], ["className", "mb-0"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["className", "has-text-primary"], ["children", `${countFilteredDelivery}/${countAllDelivery} `]]), createElement("span", createObj(Helpers_combineClasses("", props_15)))), (props_17 = ofArray([["className", "is-uppercase"], ["children", t("orders.orders")]]), createElement("span", createObj(Helpers_combineClasses("", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_19))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_21))))])]);
                        })))))))))))), createElement("div", createObj(Helpers_combineClasses("box", props_23))))))))), createElement("div", {
                            className: "column",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                        })));
                    }, indexed(ofArray([1, 2, 3, 4, 5, 6, 0])));
                }
                default: {
                    return singleton_2(null);
                }
            }
        }))))]))))))), createElement("div", createObj(Helpers_combineClasses("columns", props_27))))), delay(() => {
            let value_89, value_90, elms_3, props_33, props_31;
            const matchValue_3 = state_1.LoadedOrders;
            switch (matchValue_3.tag) {
                case 1: {
                    return singleton_2(loader("big"));
                }
                case 3: {
                    return singleton_2((value_89 = matchValue_3.fields[0].message, createElement("div", {
                        children: [value_89],
                    })));
                }
                case 2: {
                    const orders = matchValue_3.fields[0];
                    const matchValue_4 = state_1.ActiveDeliveryZones;
                    switch (matchValue_4.tag) {
                        case 1: {
                            return singleton_2(loader("big"));
                        }
                        case 3: {
                            return singleton_2((value_90 = matchValue_4.fields[0].message, createElement("div", {
                                children: [value_90],
                            })));
                        }
                        case 2: {
                            return append_1(singleton_2((elms_3 = singleton_1((props_33 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_1((zone) => createElement(ButtonFilterZone, {
                                zone: zone,
                                dispatch: dispatch,
                            }), matchValue_4.fields[0])))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_31))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_33))))), createElement("div", {
                                className: "columns",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                            }))), delay(() => append_1(singleton_2(tableHeader(state_1, dispatch)), delay(() => {
                                let props_36;
                                return equals(orders, empty()) ? singleton_2((props_36 = ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", t("orders.noShippingInformation")]]), createElement("p", createObj(Helpers_combineClasses("", props_36))))) : collect((o) => {
                                    const deliveryZone = find((x_1) => (x_1.DeliveryCostZoneId === o.Provider), state_1.AllDeliveryZones);
                                    return singleton_2(createElement(RenderItems, {
                                        order: o,
                                        deliveryZone: deliveryZone,
                                        state: state_1,
                                        dispatch: dispatch,
                                    }));
                                }, orders);
                            }))));
                        }
                        default: {
                            return singleton_2(loader("big"));
                        }
                    }
                }
                default: {
                    return singleton_2(null);
                }
            }
        }));
    }))))]]), createElement("section", createObj(Helpers_combineClasses("section", props_38))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    });
}

