import { Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, option_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { CreateLotInfo, ResellLotInfo, Priority_toES, Priority_Priority, Priority_fromMethod, Priority_toMethod, Priority_fromES, Category$reflection } from "../Shared/Shared.js";
import { Deferred$1, useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { lotApi, auctionApi } from "../Communication.js";
import { empty, append, singleton as singleton_1, delay, toList, iterate } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { map as map_1, defaultArg, toArray } from "../fable_modules/fable-library.3.6.1/Option.js";
import { singleton as singleton_2, tryFind, cons, ofArray, map, filter, tryHead } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals } from "../fable_modules/fable-library.3.6.1/Long.js";
import { createElement } from "react";
import * as react from "react";
import { loaderSmall } from "../Loader.js";
import { Helpers_combineClasses, Helpers_extractClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { AuctionIdModule_empty, CategoryIdModule_toString, CategoryIdModule_fromString, AuctionIdModule_toString, AuctionIdModule_fromString } from "../bidflow/src/infrastructure/Auction.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { sv } from "date-fns/locale";
import { format as format_1 } from "date-fns";
import { tWithArgs, t, Localized_translate } from "../Localization.js";
import { interpolate, toText, join } from "../fable_modules/fable-library.3.6.1/String.js";
import { equals as equals_1, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { EditLotArg, AuctionResellLotState, Route, AuctionArg, AuctionState, toPath } from "../Router.js";

export class ActionType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Create", "Resell", "CreateFromWorkDepot", "MoveLotForAnotherAuction"];
    }
}

export function ActionType$reflection() {
    return union_type("Components.CreateLot.ActionType", [], ActionType, () => [[], [["Item1", class_type("System.Int64")], ["Item2", Category$reflection()]], [["Item1", class_type("System.Int64")], ["Item2", Category$reflection()]], [["Item1", class_type("System.Int64")], ["Item2", Category$reflection()], ["Item3", option_type(class_type("System.Int64"))]]]);
}

export function SelectAuction(selectAuctionInputProps) {
    const actionType = selectAuctionInputProps.actionType;
    const onSelectAuction = selectAuctionInputProps.onSelectAuction;
    const auctions_2 = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getUpcomingAuctions()), (_arg1) => {
        let id;
        const auctions = _arg1;
        iterate((x) => {
            onSelectAuction(x.Id);
        }, toArray(tryHead(auctions)));
        const oldAuction = (actionType.tag === 3) ? actionType.fields[2] : (void 0);
        return singleton.Return((oldAuction != null) ? ((id = oldAuction, filter((x_2) => (!equals(x_2.Id, id)), auctions))) : auctions);
    })), []);
    switch (auctions_2.tag) {
        case 3: {
            const value_4 = auctions_2.fields[0].message;
            return createElement("div", {
                children: [value_4],
            });
        }
        case 1: {
            return loaderSmall();
        }
        case 2: {
            const patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "has-text-grey"], ["onChange", (ev) => {
                onSelectAuction(AuctionIdModule_fromString(ev.target.value));
            }], ["children", Interop_reactApi.Children.toArray(Array.from(map((auction_1) => {
                let auction, date_1, date, locale, formatOptions;
                return createElement("option", {
                    value: AuctionIdModule_toString(auction_1.Id),
                    children: (auction = auction_1, (date_1 = ((date = toLocalTime(auction.Date), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_1(date, "dd MMMM yyyy", formatOptions)))))), `${date_1} / ${Localized_translate(auction.Name)}`)),
                });
            }, auctions_2.fields[0])))]]));
            return createElement("div", {
                className: join(" ", cons("select", patternInput[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
            });
        }
        default: {
            return null;
        }
    }
}

export function SelectCategory(selectCategoryInputProps) {
    let selected_1, auctionId_1, value_21;
    const dependencies = selectCategoryInputProps.dependencies;
    const onSelectCategory = selectCategoryInputProps.onSelectCategory;
    const selected = selectCategoryInputProps.selected;
    const catalog = useFeliz_React__React_useDeferred_Static_2344FC52((selected_1 = selected, (auctionId_1 = selectCategoryInputProps.auctionId, singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getCategories(auctionId_1)), (_arg1) => {
        let categoryId;
        const result = _arg1;
        iterate(onSelectCategory, toArray((selected_1 == null) ? tryHead(filter((x_1) => (x_1.Capacity > x_1.Used), result.Categories)) : ((categoryId = (selected_1 | 0), tryFind((x) => (x.Id === categoryId), result.Categories)))));
        return singleton.Return(result);
    })))), dependencies);
    switch (catalog.tag) {
        case 1:
        case 3: {
            const patternInput = Helpers_extractClasses(ofArray([["className", join(" ", ["is-loading"])], ["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "has-text-grey"]]));
            return createElement("div", {
                className: join(" ", cons("select", patternInput[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
            });
        }
        case 2: {
            const categories = catalog.fields[0];
            const patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "has-text-grey"], ["onChange", (ev) => {
                const categoryId_1 = CategoryIdModule_fromString(ev.target.value) | 0;
                iterate(onSelectCategory, toArray(tryFind((x_5) => (x_5.Id === categoryId_1), categories.Categories)));
            }], (value_21 = defaultArg(map_1((x_6) => CategoryIdModule_toString(x_6), selected), ""), ["ref", (e) => {
                if ((!(e == null)) ? (!equals_1(e.value, value_21)) : false) {
                    e.value = value_21;
                }
            }]), ["children", Interop_reactApi.Children.toArray(Array.from(map((category) => createElement("option", {
                value: CategoryIdModule_toString(category.Id),
                children: Localized_translate(category.Name),
            }), categories.Categories)))]]));
            return createElement("div", {
                className: join(" ", cons("select", patternInput_1[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
            });
        }
        default: {
            const patternInput = Helpers_extractClasses(ofArray([["className", join(" ", ["is-loading"])], ["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "has-text-grey"]]));
            return createElement("div", {
                className: join(" ", cons("select", patternInput[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
            });
        }
    }
}

export function SelectPriority(selectPriorityInputProps) {
    let lotId_1, props_2, props, elms;
    const priority = selectPriorityInputProps.priority;
    const setPriority = selectPriorityInputProps.setPriority;
    const lotId = selectPriorityInputProps.lotId;
    const priorityLoad = (lotId == null) ? (new Deferred$1(2, Priority_fromES(0))) : useFeliz_React__React_useDeferred_Static_2344FC52((lotId_1 = lotId, singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotApi().getPriority(lotId_1)), (_arg1) => {
        const result = _arg1 | 0;
        setPriority(Priority_fromES(result));
        return singleton.Return(Priority_fromES(result));
    }))), []);
    const elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.priority")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = toList(delay(() => {
        let patternInput, value_16;
        return (priorityLoad.tag === 1) ? singleton_1(null) : ((priorityLoad.tag === 3) ? singleton_1(null) : ((priorityLoad.tag === 2) ? singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "has-text-grey"], ["onChange", (ev) => {
            setPriority(Priority_toMethod(ev.target.value));
        }], (value_16 = Priority_fromMethod(priority), ["ref", (e) => {
            if ((!(e == null)) ? (!equals_1(e.value, value_16)) : false) {
                e.value = value_16;
            }
        }]), ["children", Interop_reactApi.Children.toArray(Array.from(map((x_1) => createElement("option", {
            children: x_1,
        }), ofArray(["Veckans Utvalda", "Katalogen", "Market"]))))]])), createElement("div", {
            className: join(" ", cons("select", patternInput[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
        }))) : singleton_1(null)));
    })), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]);
    return createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

export function CreateOrResellLotComponent(createOrResellLotComponentInputProps) {
    const actionType = createOrResellLotComponentInputProps.actionType;
    const updateAuctionStateAndOpenNewTab = createOrResellLotComponentInputProps.updateAuctionStateAndOpenNewTab;
    const auctionPageAndFilterState = createOrResellLotComponentInputProps.auctionPageAndFilterState;
    const disabled = createOrResellLotComponentInputProps.disabled;
    const itemId = createOrResellLotComponentInputProps.itemId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const auctionId = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505((actionType.tag === 1) ? actionType.fields[1] : ((actionType.tag === 2) ? actionType.fields[1] : ((actionType.tag === 3) ? actionType.fields[1] : (void 0))));
    const category = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(new Priority_Priority(1));
    const setPriority = patternInput_4[1];
    const priority = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const setError = patternInput_5[1];
    const onSave = useReact_useCallback_93353E(() => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            let args_1, args_2, args_3, args, value_22;
            setIsLoading(true);
            setError(void 0);
            const matchValue = [auctionId, category];
            let pattern_matching_result, auctionId_2, category_1;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    pattern_matching_result = 0;
                    auctionId_2 = matchValue[0];
                    category_1 = matchValue[1];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton.Bind((actionType.tag === 1) ? ((args_1 = (new ResellLotInfo(itemId, actionType.fields[0], auctionId_2, category_1.Id, Priority_toES(priority))), lotApi().resellLot(args_1))) : ((actionType.tag === 2) ? ((args_2 = (new CreateLotInfo(itemId, auctionId_2, category_1.Id, Priority_toES(priority))), lotApi().createLot(args_2))) : ((actionType.tag === 3) ? ((args_3 = (new ResellLotInfo(itemId, actionType.fields[0], auctionId_2, category_1.Id, Priority_toES(priority))), lotApi().moveLot(args_3))) : ((args = (new CreateLotInfo(itemId, auctionId_2, category_1.Id, Priority_toES(priority))), lotApi().createLot(args))))), (_arg2) => {
                        let _arg1, value_10, auctionId_1, elms_1, value_11, s_1, s, value_19, value_20, value_21, lotId, elms, value;
                        if (_arg2.tag === 1) {
                            setIsLoading(false);
                            setError((_arg1 = _arg2.fields[0], (_arg1.tag === 4) ? ((value_10 = t("errors.fromServer.noMoreAvailableSlotsInCategory"), createElement("span", {
                                children: [value_10],
                            }))) : ((_arg1.tag === 1) ? ((auctionId_1 = _arg1.fields[0], (elms_1 = ofArray([(value_11 = t("errors.fromServer.itemIsReservedForAuction"), createElement("span", {
                                children: [value_11],
                            })), createElement("a", {
                                children: t("auction.backToAuction"),
                                href: (auctionPageAndFilterState == null) ? toPath(new Route(2, new AuctionArg(0, AuctionIdModule_empty, new AuctionState(false, false, 1)))) : ((auctionPageAndFilterState.tag === 1) ? ((s_1 = auctionPageAndFilterState.fields[1], toPath(new Route(2, new AuctionArg(1, auctionId_1, new AuctionResellLotState(s_1.IsUnsoldFilterOn, s_1.IsClaimedOrRefundedFilterOn, s_1.IsWithDrawnFilterOn, s_1.Page)))))) : ((s = auctionPageAndFilterState.fields[1], toPath(new Route(2, new AuctionArg(0, auctionId_1, new AuctionState(s.IsUnsoldFilterOn, s.IsIncompleteFilterOn, s.Page))))))),
                            })]), createElement("div", {
                                className: "content",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                            })))) : ((_arg1.tag === 0) ? ((value_19 = t("errors.fromServer.itemDoesNotExist"), createElement("span", {
                                children: [value_19],
                            }))) : ((_arg1.tag === 3) ? ((value_20 = t("errors.fromServer.categoryIsNotSelected"), createElement("span", {
                                children: [value_20],
                            }))) : ((_arg1.tag === 5) ? ((value_21 = tWithArgs("errors.fromServer.unknownError", {
                                error: _arg1.fields[0],
                            }), createElement("span", {
                                children: [value_21],
                            }))) : ((lotId = _arg1.fields[0], (elms = ofArray([(value = t("errors.fromServer.itemIsReservedForAuction"), createElement("span", {
                                children: [value],
                            })), createElement("a", createObj(toList(delay(() => append(singleton_1(["children", "Go to lot"]), delay(() => {
                                if (auctionId == null) {
                                    throw (new Error(t("errors.fromServer.auctionIdDoesNotExist")));
                                    return empty();
                                }
                                else {
                                    const a = auctionId;
                                    return append(singleton_1(["href", toPath(new Route(20, new EditLotArg(0, a, lotId, new AuctionState(false, false, 1))))]), delay(() => singleton_1(["onClick", (_arg1_1) => {
                                        window.open(toPath(new Route(20, new EditLotArg(0, a, lotId, new AuctionState(false, false, 1)))));
                                    }])));
                                }
                            }))))))]), createElement("div", {
                                className: "content",
                                children: Interop_reactApi.Children.toArray(Array.from(elms)),
                            }))))))))));
                            return singleton.Zero();
                        }
                        else {
                            setIsLoading(false);
                            setVisible(false);
                            updateAuctionStateAndOpenNewTab(toPath(new Route(20, new EditLotArg(0, auctionId_2, _arg2.fields[0], new AuctionState(false, false, 1)))));
                            return singleton.Zero();
                        }
                    });
                }
                case 1: {
                    setIsLoading(false);
                    setError((value_22 = t("errors.fromServer.selectAuctionAndCategory"), createElement("span", {
                        children: [value_22],
                    })));
                    return singleton.Zero();
                }
            }
        }), (_arg3) => {
            let value_23;
            setIsLoading(false);
            setError((value_23 = _arg3.message, createElement("span", {
                children: [value_23],
            })));
            return singleton.Zero();
        })));
    }, [[auctionId, category]]);
    let canSave;
    const matchValue_1 = [auctionId, category];
    let pattern_matching_result_1, category_2;
    if (matchValue_1[0] != null) {
        if (matchValue_1[1] != null) {
            pattern_matching_result_1 = 0;
            category_2 = matchValue_1[1];
        }
        else {
            pattern_matching_result_1 = 1;
        }
    }
    else {
        pattern_matching_result_1 = 1;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            canSave = ((category_2.NextOrderNumber.tag === 1) ? true : false);
            break;
        }
        case 1: {
            canSave = false;
            break;
        }
    }
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props_15, props_13;
        return append(singleton_1((props_15 = ofArray([["className", "is-primary"], ["className", "is-outlined"], ["className", "mr-4"], ["style", {
            borderRadius: 50 + "%",
            padding: 0,
            height: 1.655 + "em",
        }], ["disabled", disabled], ["onClick", (_arg2_1) => {
            setVisible(true);
        }], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["style", {
            margin: 0,
        }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fas", "fa-plus"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_13))))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_15))))), delay(() => {
            let props_63, elms_9, elms_2, props_19, props_54, props_48, elms_5, elms_4, props_26, props_24, elms_3, elms_8, elms_7, props_33, props_31, elms_6, props_38, props_46, props_44, props_40, props_42, props_51, props_60, props_56, props_58;
            return isVisible ? singleton_1((props_63 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_2(["onClick", (_arg3_1) => {
                setVisible(!isVisible);
            }])))), (elms_9 = ofArray([(elms_2 = ofArray([(props_19 = ofArray([["className", "has-text-left"], ["children", t("common.create")], ["className", "is-size-4"], ["className", "my-2"]]), createElement("p", createObj(Helpers_combineClasses("modal-card-title", props_19)))), createElement("button", createObj(Helpers_combineClasses("delete", singleton_2(["onClick", (_arg4) => {
                setVisible(false);
            }]))))]), createElement("header", {
                className: "modal-card-head",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            })), (props_54 = ofArray([["className", "has-background-light"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
                children: Interop_reactApi.Children.toArray([(props_48 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_2((elms_4 = ofArray([(props_26 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.auction")]]), createElement("label", createObj(Helpers_combineClasses("label", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_26)))), (elms_3 = singleton_2(createElement(SelectAuction, {
                    onSelectAuction: (arg_1) => {
                        patternInput_1[1](arg_1);
                    },
                    actionType: actionType,
                })), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                })), (elms_8 = singleton_2((elms_7 = ofArray([(props_33 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.category")]]), createElement("label", createObj(Helpers_combineClasses("label", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_33)))), (elms_6 = toList(delay(() => {
                    if (auctionId == null) {
                        return singleton_1(null);
                    }
                    else {
                        const auctionId_3 = auctionId;
                        const categoryId = map_1((x) => x.Id, category);
                        return singleton_1(createElement(SelectCategory, {
                            auctionId: auctionId_3,
                            selected: categoryId,
                            onSelectCategory: (arg_2) => {
                                patternInput_2[1](arg_2);
                            },
                            dependencies: [auctionId_3, categoryId],
                        }));
                    }
                })), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                }))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                })), (props_38 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((actionType.tag === 2) ? singleton_1(createElement(SelectPriority, {
                    lotId: void 0,
                    setPriority: setPriority,
                    priority: priority,
                })) : ((actionType.tag === 1) ? singleton_1(createElement(SelectPriority, {
                    lotId: actionType.fields[0],
                    setPriority: setPriority,
                    priority: priority,
                })) : ((actionType.tag === 3) ? singleton_1(createElement(SelectPriority, {
                    lotId: actionType.fields[0],
                    setPriority: setPriority,
                    priority: priority,
                })) : singleton_1(createElement(SelectPriority, {
                    lotId: void 0,
                    setPriority: setPriority,
                    priority: priority,
                })))))))))]), createElement("div", createObj(Helpers_combineClasses("column", props_38)))), (props_46 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_44 = ofArray([["style", {
                    justifyContent: "flex-end",
                }], ["className", "mt-5"], ["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["className", "is-static"], ["className", "has-background-grey-lighter"], ["children", t("common.lotNumber")]]), createElement("a", createObj(Helpers_combineClasses("button", props_40)))), (props_42 = toList(delay(() => append(singleton_1(["className", "is-static"]), delay(() => append(singleton_1(["className", "has-background-primary"]), delay(() => append(singleton_1(["className", "has-text-white"]), delay(() => {
                    let matchValue_3;
                    return singleton_1(["children", (category == null) ? "-" : ((matchValue_3 = category.NextOrderNumber, (matchValue_3.tag === 1) ? toText(interpolate("%d%P()", [matchValue_3.fields[0]])) : t("auction.selectCategory")))]);
                })))))))), createElement("a", createObj(Helpers_combineClasses("button", props_42))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_44))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_46))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_48)))), (props_51 = ofArray([["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([defaultArg(patternInput_5[0], createElement("span", {
                    children: [""],
                }))])]]), createElement("p", createObj(Helpers_combineClasses("help", props_51))))]),
            })])]]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_54)))), (props_60 = ofArray([["style", {
                justifyContent: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([(props_56 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["onClick", (_arg5) => {
                setVisible(!isVisible);
            }]]), createElement("button", createObj(Helpers_combineClasses("button", props_56)))), (props_58 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", t("common.create")]), delay(() => append(singleton_1(["disabled", !canSave]), delay(() => append(patternInput_3[0] ? singleton_1(["className", "is-loading"]) : empty(), delay(() => singleton_1(["onClick", (_arg6) => {
                onSave();
            }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_58))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_60))))]), createElement("div", {
                className: "modal-card",
                children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("modal", props_63))))) : empty();
        }));
    })));
}

