import { Union, Record } from "../../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, bool_type, union_type, unit_type, string_type, class_type } from "../../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../../Extensions.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { agreementApi } from "../../Communication.js";
import { t } from "../../Localization.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { dividedTop } from "../BorderDividedLine.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { singleton as singleton_1, cons, ofArray } from "../../fable_modules/fable-library.3.6.1/List.js";
import { empty, append, singleton as singleton_2, delay, toList } from "../../fable_modules/fable-library.3.6.1/Seq.js";

export class State extends Record {
    constructor(AgreementId, AgreementName, OriginalAgreementName, SaveFormOperation, IsNotEditNameMode) {
        super();
        this.AgreementId = AgreementId;
        this.AgreementName = AgreementName;
        this.OriginalAgreementName = OriginalAgreementName;
        this.SaveFormOperation = SaveFormOperation;
        this.IsNotEditNameMode = IsNotEditNameMode;
    }
}

export function State$reflection() {
    return record_type("Components.EditNameAgreement.State", [], State, () => [["AgreementId", class_type("System.Int64")], ["AgreementName", string_type], ["OriginalAgreementName", string_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["IsNotEditNameMode", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SwitchEditName", "CancelChangesName", "NameChanged", "ModifyAgreementName"];
    }
}

export function Msg$reflection() {
    return union_type("Components.EditNameAgreement.Msg", [], Msg, () => [[], [], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function init(agreementName, agreementId) {
    return [new State(agreementId, agreementName, agreementName, new Deferred$1(0), true), Cmd_none()];
}

export function saveAgreementName(agreementId, name) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(agreementApi().modifyAgreementName([agreementId, name]), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.agreementDoesNotExist"))))) : singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.AgreementId, state.OriginalAgreementName, state.OriginalAgreementName, state.SaveFormOperation, true), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new State(state.AgreementId, msg.fields[0], state.OriginalAgreementName, state.SaveFormOperation, state.IsNotEditNameMode), Cmd_none()];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 0) {
                return [new State(state.AgreementId, state.AgreementName, state.AgreementName, new Deferred$1(2, new FSharpResult$2(0, void 0)), true), Cmd_none()];
            }
            else {
                return [new State(state.AgreementId, state.AgreementName, state.OriginalAgreementName, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsNotEditNameMode), Cmd_none()];
            }
        }
        else {
            return [new State(state.AgreementId, state.AgreementName, state.OriginalAgreementName, new Deferred$1(1), state.IsNotEditNameMode), Cmd_fromAsync(saveAgreementName(state.AgreementId, state.AgreementName))];
        }
    }
    else {
        return [new State(state.AgreementId, state.AgreementName, state.OriginalAgreementName, state.SaveFormOperation, false), Cmd_none()];
    }
}

export function AgreementName(agreementNameInputProps) {
    let props_19, props_2, elms_1, props_7, elms, props_4, value_15, props_17;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(agreementNameInputProps.name, agreementNameInputProps.agreementId), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_21 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(props_19 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["style", {
        alignSelf: "center",
    }], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-primary"], ["children", "Namn"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (elms_1 = singleton_1((props_7 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_4 = ofArray([["disabled", state_1.IsNotEditNameMode], (value_15 = state_1.AgreementName, ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_15)) : false) {
            e.value = value_15;
        }
    }]), ["onChange", (ev) => {
        dispatch(new Msg(2, ev.target.value));
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_7))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_17 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let children_2, props_12, props_14;
        return state_1.IsNotEditNameMode ? singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-primary"], ["children", "Edit name"], ["onClick", (_arg1) => {
            dispatch(new Msg(0));
        }]]))))) : singleton_2((children_2 = ofArray([(props_12 = toList(delay(() => append(singleton_2(["className", "mr-3"]), delay(() => append(singleton_2(["children", t("common.cancel")]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_2(["className", "is-static"]) : empty(), delay(() => singleton_2(["onClick", (_arg2) => {
            dispatch(new Msg(1));
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12)))), (props_14 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["children", t("common.save")]), delay(() => append(singleton_2(["disabled", state_1.AgreementName === state_1.OriginalAgreementName]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty(), delay(() => singleton_2(["onClick", (x) => {
            x.preventDefault();
            dispatch(new Msg(3, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_14))))]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_17))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_19))))])]]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_21)));
}

