import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Localized_translate, Localized_formatDateTime, t } from "./Localization.js";
import { contains, singleton, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { fastStructuralHash, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { AgreementIdModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { map, append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { toLocalTime } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { sv } from "date-fns/locale";
import { format as format_4 } from "date-fns";
import { interpolate, toText, join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Route, toPath } from "./Router.js";
import { equals } from "./fable_modules/fable-library.3.6.1/Long.js";
import { Submissions_PageType } from "./Shared/Shared.js";

export const tableHeader = (() => {
    let props_18, props, props_2, props_12, props_10, props_8, props_14;
    const props_20 = ofArray([["className", "mt-6"], ["className", "mb-4"], ["className", "is-fluid"], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-vcentered"], ["className", "is-mobile"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-1"], ["children", t("common.id")]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-2"], ["children", t("common.user")]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-3"], ["children", "Agreement name"]])))), (props_12 = ofArray([["className", "is-3"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "px-0"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "px-0"], ["children", "Skapades"]])))), (props_8 = ofArray([["className", "px-0"], ["className", "has-text-right"], ["children", t("common.endDate")]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", t("common.status")]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), createElement("div", createObj(Helpers_combineClasses("column", singleton(["className", "is-1"]))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_20)));
})();

export function renderItems(agreement) {
    let props_43, props, props_20, props_18, props_10, props_6, props_8, props_16, props_12, props_14, props_34, props_41, props_39, props_37;
    const props_45 = ofArray([["className", "mb-4"], ["className", "is-fluid"], ["className", "has-background-white"], ["children", Interop_reactApi.Children.toArray([(props_43 = ofArray([["className", "is-size-6"], ["className", "is-mobile"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
        overflow: "auto",
    }], ["className", "is-1"], ["children", AgreementIdModule_toString(agreement.Id)]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-2"], ["children", agreement.User]])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-3"], ["className", "is-uppercase"], ["className", "is-text-overflow-hidden"], ["children", agreement.Name]])))), (props_20 = ofArray([["className", "is-3"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_6 = toList(delay(() => {
        let date, locale, formatOptions;
        return singleton_1(["children", (date = toLocalTime(agreement.CreateDate), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_4(date, "dd MMMM ", formatOptions)))))]);
    })), createElement("span", createObj(Helpers_combineClasses("", props_6)))), (props_8 = toList(delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => {
        let date_2, locale_1, formatOptions_1;
        return singleton_1(["children", (date_2 = toLocalTime(agreement.CreateDate), (locale_1 = sv, (formatOptions_1 = {}, (formatOptions_1.locale = locale_1, format_4(date_2, "yyyy", formatOptions_1)))))]);
    })))), createElement("span", createObj(Helpers_combineClasses("", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_16 = ofArray([["className", "px-0"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_12 = toList(delay(() => {
        let date_3, locale_2, formatOptions_2;
        return singleton_1(["children", (date_3 = toLocalTime(agreement.EndDate), (locale_2 = sv, (formatOptions_2 = {}, (formatOptions_2.locale = locale_2, format_4(date_3, "dd MMMM ", formatOptions_2)))))]);
    })), createElement("span", createObj(Helpers_combineClasses("", props_12)))), (props_14 = toList(delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => {
        let date_5, locale_3, formatOptions_3;
        return singleton_1(["children", (date_5 = toLocalTime(agreement.EndDate), (locale_3 = sv, (formatOptions_3 = {}, (formatOptions_3.locale = locale_3, format_4(date_5, "yyyy", formatOptions_3)))))]);
    })))), createElement("span", createObj(Helpers_combineClasses("", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_20)))), (props_34 = ofArray([["className", "is-2"], ["className", "pr-3"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        const matchValue = agreement.Status;
        switch (matchValue.tag) {
            case 2: {
                return singleton_1(createElement("div", {
                    className: "has-text-danger",
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fas", "fa-hourglass-half", "mr-1"]),
                    }), createElement("span", {
                        children: "To accept",
                    })]),
                }));
            }
            case 1: {
                return singleton_1(createElement("div", {
                    className: "has-text-primary",
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fas", "fa-hourglass-start", "mr-1"]),
                    }), createElement("span", {
                        children: "To sign",
                    })]),
                }));
            }
            case 0: {
                return singleton_1(createElement("div", {
                    className: "has-text-primary",
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fas", "fa-bell", "mr-1"]),
                    }), createElement("span", {
                        children: "Draft",
                    })]),
                }));
            }
            default: {
                return singleton_1(createElement("div", {
                    className: "has-text-primary",
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fas", "fa-check-double", "mr-1"]),
                    }), createElement("span", {
                        children: "Signed",
                    })]),
                }));
            }
        }
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_34)))), (props_41 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_39 = ofArray([["className", "is-rounded"], ["className", "is-small"], ["className", "is-primary"], ["className", "mr-3"], ["className", "py-0"], ["className", "px-3"], ["href", toPath(new Route(12, agreement.Id))], ["children", Interop_reactApi.Children.toArray([(props_37 = singleton(["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fas", "fa-sign-in-alt"]),
    })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_37))))])]]), createElement("a", createObj(Helpers_combineClasses("button", props_39))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_41))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_43))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_45)));
}

export function renderItemsSubmission(x, doneSubmissions) {
    return createElement("div", createObj(toList(delay(() => append(singleton_1(["className", "has-background-white"]), delay(() => append(contains(x.SubmissionId, doneSubmissions, {
        Equals: (x_1, y) => equals(x_1, y),
        GetHashCode: (x_1) => fastStructuralHash(x_1),
    }) ? singleton_1(["style", {
        borderLeft: (((4 + "px ") + "solid") + " ") + "#19bb9c",
        marginBottom: 0.5 + "rem",
        paddingLeft: 12,
    }]) : singleton_1(["style", {
        marginBottom: 0.5 + "rem",
        paddingLeft: 1 + "rem",
    }]), delay(() => {
        let props_24, props, props_2, props_6, props_4, props_8, props_10, props_16, props_14, props_22, props_19;
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "px-0"], ["className", "mx-0"], ["className", "my-0"], ["className", "py-0"], ["className", "is-size-6"], ["className", "is-vcentered"], ["style", {
            display: "table",
            width: 100 + "%",
        }], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
            float: "none",
            display: "table-cell",
            verticalAlign: "middle",
        }], ["className", "is-1"], ["children", toText(interpolate("%A%P()", [x.SubmissionId]))]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["style", {
            float: "none",
            display: "table-cell",
            verticalAlign: "middle",
        }], ["className", "is-2"], ["className", "is-uppercase"], ["children", x.Warehouse]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_6 = ofArray([["style", {
            float: "none",
            display: "table-cell",
            verticalAlign: "middle",
        }], ["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props_4 = singleton(["children", Localized_formatDateTime("dd MMMM yyyy", toLocalTime(x.SubmittedDate))]), createElement("span", createObj(Helpers_combineClasses("", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-1"], ["className", "has-text-centered"], ["className", "has-text-weight-bold"], ["style", {
            float: "none",
            display: "table-cell",
            verticalAlign: "middle",
        }], ["children", x.Size]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["className", "has-text-weight-bold"], ["style", {
            float: "none",
            display: "table-cell",
            verticalAlign: "middle",
        }], ["children", toText(interpolate("%A%P()", [x.SellerName]))]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_16 = ofArray([["className", "is-3"], ["style", {
            float: "none",
            display: "table-cell",
            verticalAlign: "middle",
        }], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "has-text-centered"], ["style", {
            justifyContent: "center",
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map((tag) => {
            const props_12 = toList(delay(() => append(tag.HasItems ? singleton_1(["className", "is-primary"]) : singleton_1(["className", "is-warning"]), delay(() => singleton_1(["children", Localized_translate(tag.Name)])))));
            return createElement("span", createObj(Helpers_combineClasses("tag", props_12)));
        }, x.Tags)))))]]), createElement("div", createObj(Helpers_combineClasses("tags", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_22 = ofArray([["className", "is-1"], ["className", "is-narrow"], ["className", "has-background-grey-lighter"], ["className", "py-0"], ["className", "px-0"], ["style", {
            float: "none",
            display: "table-cell",
            verticalAlign: "middle",
        }], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: "edit_button_href",
            href: toPath(new Route(22, x.SellerId, x.SubmissionId, new Submissions_PageType(0))),
            children: Interop_reactApi.Children.toArray([(props_19 = singleton(["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-sign-in-alt", "fa-lg"]),
            })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_19))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_24))))])]);
    }))))))));
}

