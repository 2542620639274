import { Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { ItemIdModule_fromString, ItemIdModule_toString, AuctionIdModule_fromString, AuctionIdModule_toString, SubmissionIdModule_fromString, SubmissionIdModule_toString, ItemIdModule_empty, AuctionIdModule_empty, SubmissionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { submissionsApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton as singleton_2, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export class State extends Record {
    constructor(SubmissionId, AuctionId, ItemId) {
        super();
        this.SubmissionId = SubmissionId;
        this.AuctionId = AuctionId;
        this.ItemId = ItemId;
    }
}

export function State$reflection() {
    return record_type("Components.Submission.Claim.Fix.View.State", [], State, () => [["SubmissionId", class_type("System.Int64")], ["AuctionId", class_type("System.Int64")], ["ItemId", class_type("System.Int64")]]);
}

export function State_get_Zero() {
    return new State(SubmissionIdModule_empty, AuctionIdModule_empty, ItemIdModule_empty);
}

export function Component() {
    let elms_8, elms_7, props_2, props_31;
    const patternInput = useFeliz_React__React_useState_Static_1505(State_get_Zero());
    const state = patternInput[0];
    const setState = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_1[0];
    const markAsClaimed_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(submissionsApi().markItemAsClaimed([state.SubmissionId, state.AuctionId, state.ItemId])))), patternInput_1[1]);
    const elms_9 = singleton_2((elms_8 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Fix claimed item in submission",
    }), (elms_7 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Mark item in submission as claimed",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_31 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, s_2, elms, props_5, value_24;
        return append(singleton_1((elms_1 = ofArray([(s_2 = (`Submission id = ${state.SubmissionId}`), createElement("label", {
            className: "label",
            children: s_2,
        })), (elms = singleton_2((props_5 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_24 = SubmissionIdModule_toString(state.SubmissionId), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_24)) : false) {
                e.value = value_24;
            }
        }]), ["onChange", (ev) => {
            const state_2 = state;
            setState(new State(SubmissionIdModule_fromString(ev.target.value), state_2.AuctionId, state_2.ItemId));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_5)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let elms_3, s_3, elms_2, props_10, value_45;
            return append(singleton_1((elms_3 = ofArray([(s_3 = (`Auction id = ${state.AuctionId}`), createElement("label", {
                className: "label",
                children: s_3,
            })), (elms_2 = singleton_2((props_10 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_45 = AuctionIdModule_toString(state.AuctionId), ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals(e_1.value, value_45)) : false) {
                    e_1.value = value_45;
                }
            }]), ["onChange", (ev_1) => {
                const state_3 = state;
                setState(new State(state_3.SubmissionId, AuctionIdModule_fromString(ev_1.target.value), state_3.ItemId));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_10)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))), delay(() => {
                let elms_5, s_4, elms_4, props_15, value_66;
                return append(singleton_1((elms_5 = ofArray([(s_4 = (`Item id = ${state.ItemId}`), createElement("label", {
                    className: "label",
                    children: s_4,
                })), (elms_4 = singleton_2((props_15 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_66 = ItemIdModule_toString(state.ItemId), ["ref", (e_2) => {
                    if ((!(e_2 == null)) ? (!equals(e_2.value, value_66)) : false) {
                        e_2.value = value_66;
                    }
                }]), ["onChange", (ev_2) => {
                    const state_1 = state;
                    setState(new State(state_1.SubmissionId, state_1.AuctionId, ItemIdModule_fromString(ev_2.target.value)));
                }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_15)))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                }))]), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                }))), delay(() => {
                    let props_20, value_78, props_24;
                    return append((data.tag === 1) ? singleton_1(null) : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", data.fields[0].fields[0]]]))))) : singleton_1((props_20 = singleton_2(["children", Interop_reactApi.Children.toArray([(value_78 = (`Successfully marked as claimed in submission ${data.fields[0].fields[0]}`), createElement("div", {
                        children: [value_78],
                    }))])]), createElement("div", createObj(Helpers_combineClasses("notification", props_20)))))) : ((data.tag === 3) ? singleton_1((props_24 = ofArray([["className", "is-danger"], ["children", data.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_24))))) : singleton_1(null))), delay(() => {
                        let props_29, elms_6, props_26;
                        return singleton_1((props_29 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_6 = singleton_2((props_26 = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(data) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Mark as claimed"]), delay(() => singleton_1(["onClick", (_arg1) => {
                            markAsClaimed_1();
                        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_26))))), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_29)))));
                    }));
                }));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_31))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    });
}

