import { Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { bool_type, record_type, option_type, string_type, unit_type, class_type, union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { AuctionLocationSettings_extradition, AuctionLocationSettings_payment, AuctionLocationSettings_contact, AuctionLocationSettingsField, AuctionLocationSettings_showing, AuctionModule_LocalizedAuctionLocationSettings, AuctionLocationSettingsField$reflection, AuctionModule_LocalizedAuctionLocationSettings$reflection } from "../Shared/Shared.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { auctionApi } from "../Communication.js";
import { now, fromDate } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { now as now_1, second, minute, hour, day, month, year, create } from "../fable_modules/fable-library.3.6.1/Date.js";
import { empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { t as t_5, getPrimaryLanguage } from "../Localization.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { LocalizedStringModule_getTranslationOrEmpty, LocalizedMap_add } from "../bidflow/src/infrastructure/Localization.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_2, singleton as singleton_1, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { replace, isNullOrEmpty, join } from "../fable_modules/fable-library.3.6.1/String.js";
import { append, empty as empty_1, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { languageSwitcher } from "./ContentLanguageSwitcher.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "../Loader.js";
import { value as value_212 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { Route, AuctionArg, AuctionState, toPath } from "../Router.js";

export class Types_EditAuctionMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GeneralSettings", "PrioritySettings", "LocationSettings"];
    }
}

export function Types_EditAuctionMode$reflection() {
    return union_type("Client.Components.AuctionLocationSettings.Types.EditAuctionMode", [], Types_EditAuctionMode, () => [[], [], []]);
}

export class Types_State extends Record {
    constructor(AuctionId, IsAuctionLoaded, Settings, Error$, EditInformation, SelectedDate, SelectedTime, ContentLanguage) {
        super();
        this.AuctionId = AuctionId;
        this.IsAuctionLoaded = IsAuctionLoaded;
        this.Settings = Settings;
        this.Error = Error$;
        this.EditInformation = EditInformation;
        this.SelectedDate = SelectedDate;
        this.SelectedTime = SelectedTime;
        this.ContentLanguage = ContentLanguage;
    }
}

export function Types_State$reflection() {
    return record_type("Client.Components.AuctionLocationSettings.Types.State", [], Types_State, () => [["AuctionId", class_type("System.Int64")], ["IsAuctionLoaded", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["Settings", AuctionModule_LocalizedAuctionLocationSettings$reflection()], ["Error", option_type(string_type)], ["EditInformation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["SelectedDate", class_type("System.DateTime")], ["SelectedTime", class_type("System.DateTime")], ["ContentLanguage", string_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadAuction", "EditAuctionInfo", "UpdateShowLocationCheckbox", "UpdateSetUpAddress", "UpdateField", "RemoveErrorNotification", "OnContentLanguageChanged"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.Components.AuctionLocationSettings.Types.Msg", [], Types_Msg, () => [[["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionModule_LocalizedAuctionLocationSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionModule_LocalizedAuctionLocationSettings$reflection()]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", bool_type]], [["Item", string_type]], [["Item1", AuctionLocationSettingsField$reflection()], ["Item2", string_type], ["Item3", string_type]], [], [["Item", string_type]]]);
}

export function Cmd_loadAuction(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAuction(id)), (_arg1) => singleton.Return(new Types_Msg(0, id, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.LocalizedAuctionLocationSettings)))))), (_arg2) => singleton.Return(new Types_Msg(0, id, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_editAuctionInfo(state, id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().editAuctionLocation([id, state.Settings])), (_arg1) => singleton.Return(new Types_Msg(1, id, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Types_Msg(1, id, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Common_toDateTimeOffset(date, time) {
    let copyOfStruct;
    return fromDate(create(year(date), month(date), day(date), hour(time), minute(time), second(time)), (copyOfStruct = now(), copyOfStruct.offset));
}

export function State_init(id) {
    return [new Types_State(id, new Deferred$1(0), new AuctionModule_LocalizedAuctionLocationSettings(false, "", empty()), void 0, new Deferred$1(0), now_1(), now_1(), getPrimaryLanguage()), Cmd_OfFunc_result(new Types_Msg(0, id, new AsyncOperationStatus$1(0)))];
}

export function State_update(msg, state) {
    let inputRecord_1, inputRecord_2;
    if (msg.tag === 2) {
        let newState;
        const inputRecord = state.Settings;
        newState = (new AuctionModule_LocalizedAuctionLocationSettings(msg.fields[0], inputRecord.SetUpAddress, inputRecord.Settings));
        if (!msg.fields[0]) {
            return [new Types_State(state.AuctionId, state.IsAuctionLoaded, newState, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
        }
        else {
            return [new Types_State(state.AuctionId, state.IsAuctionLoaded, newState, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_1 = state.Settings, new AuctionModule_LocalizedAuctionLocationSettings(inputRecord_1.DisplayOnLot, msg.fields[0], inputRecord_1.Settings)), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_2 = state.Settings, new AuctionModule_LocalizedAuctionLocationSettings(inputRecord_2.DisplayOnLot, inputRecord_2.SetUpAddress, LocalizedMap_add(msg.fields[0], msg.fields[1], msg.fields[2], state.Settings.Settings))), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 1) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                const er_1 = msg.fields[1].fields[0].fields[0];
                return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Settings, er_1, new Deferred$1(2, new FSharpResult$2(1, er_1)), state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
            else {
                return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Settings, void 0, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Settings, state.Error, new Deferred$1(1), state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_fromAsync(Cmd_editAuctionInfo(state, msg.fields[0]))];
        }
    }
    else if (msg.tag === 5) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Settings, void 0, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Settings, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, msg.fields[0]), Cmd_none()];
    }
    else if (msg.fields[1].tag === 1) {
        if (msg.fields[1].fields[0].tag === 1) {
            return [new Types_State(state.AuctionId, new Deferred$1(2, new FSharpResult$2(1, msg.fields[1].fields[0].fields[0])), state.Settings, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
        }
        else {
            return [new Types_State(state.AuctionId, new Deferred$1(2, new FSharpResult$2(0, void 0)), msg.fields[1].fields[0].fields[0], state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
        }
    }
    else {
        return [new Types_State(state.AuctionId, new Deferred$1(1), state.Settings, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_fromAsync(Cmd_loadAuction(msg.fields[0]))];
    }
}

export function View_RenderLocationSettings(view_RenderLocationSettingsInputProps) {
    let props_79, props_77, props_5, props_1, props_3, props_75, props_9, props_29, props_27, props_13, props_11, props_25, props_23, props_17, props_15, value_71, props_21, props_19, elms, props_31, props_42, elms_2, props_36, props_34, elms_1, props_52, elms_4, props_46, props_44, elms_3, props_62, elms_6, props_56, props_54, elms_5, props_72, elms_8, props_66, props_64, elms_7;
    const dispatch = view_RenderLocationSettingsInputProps.dispatch;
    const state = view_RenderLocationSettingsInputProps.state;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isVisible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const loadKeyFromConfig = singleton.Delay(() => singleton.Bind(auctionApi().loadGoogleMapKey(), (_arg1) => {
        patternInput_1[1](_arg1);
        return singleton.Zero();
    }));
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(loadKeyFromConfig);
    });
    const children_9 = singleton_1((props_79 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_77 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "mb-0"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t_5("auction.description")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_1)))), (props_3 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_5("auction.descriptionHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_75 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
        disabled: equals(state.EditInformation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "pb-3"], ["style", {
            justifyContent: "center",
        }], ["children", Interop_reactApi.Children.toArray([createElement("input", {
            disabled: equals(state.EditInformation, new Deferred$1(1)),
            className: join(" ", ["is-checkradio"]),
            id: "checkboxDisplaySettings",
            type: "checkbox",
            name: "checkboxDisplaySettings",
            onChange: (ev) => {
                const _arg2 = ev.target.checked;
                dispatch(new Types_Msg(2, !state.Settings.DisplayOnLot));
            },
            checked: state.Settings.DisplayOnLot,
        }), createElement("label", {
            htmlFor: "checkboxDisplaySettings",
            children: t_5("auction.auctionLocationSettings.checkboxDisplaySettings"),
        })])]]), createElement("div", createObj(Helpers_combineClasses("field", props_9)))), (props_29 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_27 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_5("auction.auctionLocationSettings.setUpAddress")]]), createElement("label", createObj(Helpers_combineClasses("label", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_13)))), (props_25 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_17 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([(value_71 = state.Settings.SetUpAddress, ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_71)) : false) {
                e.value = value_71;
            }
        }]), ["onChange", (ev_1) => {
            dispatch(new Types_Msg(3, ev_1.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_15)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_17)))), (props_21 = ofArray([["style", {
            alignSelf: "flex-end",
        }], ["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["children", "Preview"], ["disabled", isNullOrEmpty(state.Settings.SetUpAddress)], ["onClick", (_arg3) => {
            patternInput[1](!isVisible);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_19))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_23))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_25))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_27))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_29)))), (elms = singleton_1((props_31 = ofArray([["className", "is-12"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let address_1;
            return isVisible ? singleton_2((address_1 = replace(state.Settings.SetUpAddress, " ", "+"), createElement("iframe", {
                style: {
                    width: 100 + "%",
                    height: 100 + "%",
                    minHeight: 300,
                },
                src: `https://www.google.com/maps/embed/v1/place?key=${patternInput_1[0]}&q=${address_1}`,
            }))) : empty_1();
        }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_31))))), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), languageSwitcher(state.ContentLanguage, (arg) => {
            dispatch(new Types_Msg(6, arg));
        }), (props_42 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_2 = ofArray([(props_36 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_5("auction.auctionLocationSettings.showing")]]), createElement("label", createObj(Helpers_combineClasses("label", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_36)))), (elms_1 = toList(delay(() => {
            let props_38, value_112;
            const value_107 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, AuctionLocationSettings_showing(state.Settings.Settings));
            return singleton_2((props_38 = ofArray([["className", "mr-5"], ["key", `showing-${state.ContentLanguage}`], (value_112 = value_107, ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals(e_1.value, value_112)) : false) {
                    e_1.value = value_112;
                }
            }]), ["onChange", (ev_2) => {
                dispatch(new Types_Msg(4, new AuctionLocationSettingsField(0), state.ContentLanguage, ev_2.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_38)))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_42)))), (props_52 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_4 = ofArray([(props_46 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_44 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_5("auction.auctionLocationSettings.contact")]]), createElement("label", createObj(Helpers_combineClasses("label", props_44))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_46)))), (elms_3 = toList(delay(() => {
            let props_48, value_140;
            const value_135 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, AuctionLocationSettings_contact(state.Settings.Settings));
            return singleton_2((props_48 = ofArray([["className", "mr-5"], ["key", `contact-${state.ContentLanguage}`], (value_140 = value_135, ["ref", (e_2) => {
                if ((!(e_2 == null)) ? (!equals(e_2.value, value_140)) : false) {
                    e_2.value = value_140;
                }
            }]), ["onChange", (ev_3) => {
                dispatch(new Types_Msg(4, new AuctionLocationSettingsField(1), state.ContentLanguage, ev_3.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_48)))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_52)))), (props_62 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_6 = ofArray([(props_56 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_54 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_5("auction.auctionLocationSettings.payment")]]), createElement("label", createObj(Helpers_combineClasses("label", props_54))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_56)))), (elms_5 = toList(delay(() => {
            let props_58, value_168;
            const value_163 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, AuctionLocationSettings_payment(state.Settings.Settings));
            return singleton_2((props_58 = ofArray([["className", "mr-5"], ["key", `payment-${state.ContentLanguage}`], (value_168 = value_163, ["ref", (e_3) => {
                if ((!(e_3 == null)) ? (!equals(e_3.value, value_168)) : false) {
                    e_3.value = value_168;
                }
            }]), ["onChange", (ev_4) => {
                dispatch(new Types_Msg(4, new AuctionLocationSettingsField(2), state.ContentLanguage, ev_4.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_58)))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_62)))), (props_72 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_8 = ofArray([(props_66 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_64 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_5("auction.auctionLocationSettings.extradition")]]), createElement("label", createObj(Helpers_combineClasses("label", props_64))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_66)))), (elms_7 = toList(delay(() => {
            let props_68, value_196;
            const value_191 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, AuctionLocationSettings_extradition(state.Settings.Settings));
            return singleton_2((props_68 = ofArray([["className", "mr-5"], ["key", `extradition-${state.ContentLanguage}`], (value_196 = value_191, ["ref", (e_4) => {
                if ((!(e_4 == null)) ? (!equals(e_4.value, value_196)) : false) {
                    e_4.value = value_196;
                }
            }]), ["onChange", (ev_5) => {
                dispatch(new Types_Msg(4, new AuctionLocationSettingsField(3), state.ContentLanguage, ev_5.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_68)))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_72))))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_75))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_77))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_79)))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_9)),
    });
}

export function View_Render(view_RenderInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(view_RenderInputProps.id), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const matchValue = state_1.IsAuctionLoaded;
    let pattern_matching_result, ex;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            ex = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return loader("big");
        }
        case 1: {
            return createElement("h1", {
                children: [ex],
            });
        }
        case 2: {
            return react.createElement(react.Fragment, {}, ...toList(delay(() => append(singleton_2(createElement(View_RenderLocationSettings, {
                state: state_1,
                dispatch: dispatch,
            })), delay(() => {
                let props_3, s;
                return append((state_1.Error != null) ? singleton_2((props_3 = ofArray([["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("button", {
                    className: join(" ", ["delete"]),
                    onClick: (x) => {
                        x.preventDefault();
                        dispatch(new Types_Msg(5));
                    },
                }), (s = value_212(state_1.Error), createElement("div", {
                    className: "",
                    children: s,
                }))])]]), createElement("div", createObj(Helpers_combineClasses("notification", props_3))))) : empty_1(), delay(() => {
                    let props_17, props_15, props_13, props_11, props_7, props_9;
                    return singleton_2((props_17 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_15 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", empty_2()))), (props_13 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-dark"], ["className", "mr-3"], ["children", t_5("common.cancel")], ["href", toPath(new Route(2, new AuctionArg(0, state_1.AuctionId, new AuctionState(false, false, 1))))]]), createElement("a", createObj(Helpers_combineClasses("button", props_7)))), (props_9 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(equals(state_1.EditInformation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["children", t_5("common.save")]), delay(() => singleton_2(["onClick", (x_1) => {
                        x_1.preventDefault();
                        dispatch(new Types_Msg(1, state_1.AuctionId, new AsyncOperationStatus$1(0)));
                    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_11))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_13))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_15))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_17)))));
                }));
            })))));
        }
    }
}

