import { Union, Record } from "../../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, record_type, option_type, class_type } from "../../fable_modules/fable-library.3.6.1/Reflection.js";
import { Agreement_DeleteScanInfo, Agreement_UploadScanInfo, Agreement_DeleteScanInfo$reflection, Agreement_ScanAgreement$reflection } from "../../Shared/Shared.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../../fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "../../fable_modules/fable-library.3.6.1/Async.js";
import { singleton } from "../../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { ByteArrayExtensions_SaveFileAs_451DD142, Browser_Types_File__File_ReadAsByteArray } from "../../fable_modules/Fable.Remoting.Client.7.10.0/Extensions.fs.js";
import { Remoting_handleNonAuth } from "../../Extensions.js";
import { agreementApi } from "../../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library.3.6.1/Seq.js";
import { createObj } from "../../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { singleton as singleton_2, cons, ofArray } from "../../fable_modules/fable-library.3.6.1/List.js";
import { join } from "../../fable_modules/fable-library.3.6.1/String.js";
import { value as value_26 } from "../../fable_modules/fable-library.3.6.1/Option.js";
import { ScanFileNameModule_toString } from "../../bidflow/src/infrastructure/Auction.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { dividedTopLight } from "../BorderDividedLine.js";

export class State extends Record {
    constructor(AgreementId, Scan) {
        super();
        this.AgreementId = AgreementId;
        this.Scan = Scan;
    }
}

export function State$reflection() {
    return record_type("Components.AgreementScan.State", [], State, () => [["AgreementId", class_type("System.Int64")], ["Scan", option_type(Agreement_ScanAgreement$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ScanUpdated", "ScanDelete"];
    }
}

export function Msg$reflection() {
    return union_type("Components.AgreementScan.Msg", [], Msg, () => [[["Item", Agreement_ScanAgreement$reflection()]], [["Item", Agreement_DeleteScanInfo$reflection()]]]);
}

export function init(agreementId, scan) {
    return [new State(agreementId, scan), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.AgreementId, void 0), Cmd_none()];
    }
    else {
        return [new State(state.AgreementId, msg.fields[0]), Cmd_none()];
    }
}

export function uploadScan(agreementId, onUploaded) {
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setError = patternInput[1];
    const error = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setInProgress = patternInput_1[1];
    const inProgress = patternInput_1[0];
    const uploadFileCallback = useReact_useCallback_93353E((arg) => {
        let file;
        startImmediate((file = arg, singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            setError("");
            setInProgress(true);
            return singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file), (_arg1) => {
                const args = new Agreement_UploadScanInfo(agreementId, _arg1, file.name);
                return singleton.Combine(singleton.Bind(Remoting_handleNonAuth(agreementApi().uploadScanAgreement(args)), (_arg2) => {
                    if (_arg2.tag === 1) {
                        setError(`${_arg2.fields[0]}`);
                        return singleton.Zero();
                    }
                    else {
                        onUploaded(_arg2.fields[0]);
                        return singleton.Zero();
                    }
                }), singleton.Delay(() => {
                    setInProgress(false);
                    return singleton.Zero();
                }));
            });
        }), (_arg3) => {
            setError(_arg3.message);
            setInProgress(false);
            return singleton.Zero();
        }))));
    });
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_5, elms, s;
            return append(singleton_1((props_5 = ofArray([["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["disabled", inProgress], ["onChange", (ev) => {
                const files = ev.target.files;
                if ((!(files == null)) ? (files.length > 0) : false) {
                    uploadFileCallback(files.item(0));
                }
            }]]))))), (elms = singleton_2(createElement("i", createObj(toList(delay(() => (inProgress ? singleton_1(["className", join(" ", ["fas", "fa-spinner", "fa-spin"])]) : singleton_1(["className", join(" ", ["fa", "fa-plus"])]))))))), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (s = (inProgress ? "Laddar upp dokument..." : "Lägg till inlämning"), createElement("span", {
                className: "",
                children: s,
            }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_5))))), delay(() => ((error !== "") ? singleton_1(createElement("span", {
                children: [error],
            })) : empty())));
        })))),
    });
}

export function deleteScan(state, onUploaded) {
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setError = patternInput[1];
    const error = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setInProgress = patternInput_1[1];
    const inProgress = patternInput_1[0];
    const uploadFileCallback = useReact_useCallback_93353E((arg_1) => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            const arg = new Agreement_DeleteScanInfo(value_26(state.Scan).Id, state.AgreementId);
            return singleton.Combine(singleton.Bind(Remoting_handleNonAuth(agreementApi().deleteScanAgreement(arg)), (_arg2) => {
                if (_arg2.tag === 1) {
                    setError(`${_arg2.fields[0]}`);
                    return singleton.Zero();
                }
                else {
                    onUploaded(arg);
                    return singleton.Zero();
                }
            }), singleton.Delay(() => {
                setInProgress(false);
                return singleton.Zero();
            }));
        }), (_arg3) => {
            setError(_arg3.message);
            setInProgress(false);
            return singleton.Zero();
        })));
    });
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_3, elms, s;
            return append(singleton_1((props_3 = ofArray([["disabled", state.Scan == null], ["children", "Radera"], ["onClick", (x) => {
                x.preventDefault();
                uploadFileCallback(state.Scan);
            }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_2(createElement("i", createObj(toList(delay(() => (inProgress ? singleton_1(["className", join(" ", ["fas", "fa-spinner", "fa-spin"])]) : singleton_1(["className", join(" ", ["fa", "fa-plus"])]))))))), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (s = (inProgress ? "Deleting scan" : "Radera"), createElement("span", {
                className: "",
                children: s,
            }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_3))))), delay(() => ((error !== "") ? singleton_1(createElement("span", {
                children: [error],
            })) : empty())));
        })))),
    });
}

export function RenderDownloadScan(scan) {
    const onClick = useReact_useCallback_93353E(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(agreementApi().getFile(scan.Id), (_arg1) => {
            ByteArrayExtensions_SaveFileAs_451DD142(_arg1, ScanFileNameModule_toString(scan.FileName), "application/pdf");
            return singleton.Zero();
        })));
    });
    return createElement("a", {
        className: "has-text-primary",
        target: "_blank",
        children: ScanFileNameModule_toString(scan.FileName),
        onClick: (_arg1_1) => {
            onClick();
        },
    });
}

export function RenderScan(renderScanInputProps) {
    let elms, props_2, props_4, props_6, props_8;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderScanInputProps.agreementId, renderScanInputProps.scan), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_11 = ofArray([dividedTopLight, ["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_2 = ofArray([["className", "is-one-fifth"], ["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-weight-bold"], ["className", "mb-3"], ["children", "Inskannade dokument"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = toList(delay(() => {
        const matchValue = state_1.Scan;
        return (matchValue != null) ? singleton_1(["children", Interop_reactApi.Children.toArray([createElement(RenderDownloadScan, matchValue)])]) : singleton_1(["children", "Hittade inga inlämningsdokument"]);
    })), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([deleteScan(state_1, (arg) => {
        dispatch(new Msg(1, arg));
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([uploadScan(state_1.AgreementId, (arg_1) => {
        dispatch(new Msg(0, arg_1));
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_11)));
}

