import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { ItemFilter, ItemFilter$reflection, ItemInfo$reflection } from "./Shared/Shared.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingQuery$1$reflection, Common_PagingResult$1$reflection } from "./Shared/Common.js";
import { record_type, int32_type, union_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { itemApi } from "./Communication.js";
import { Localized_translate, t, getLocalizationContext } from "./Localization.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { isEmpty, cons, ofArray, singleton as singleton_1 } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map, empty, append, singleton as singleton_2, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ItemIdModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { ActionType, CreateOrResellLotComponent } from "./Components/CreateLot.js";
import { Route, toPath } from "./Router.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { dividedTop } from "./Components/BorderDividedLine.js";
import { loader } from "./Loader.js";
import { renderPagination } from "./Components/Pagination.js";

export class State extends Record {
    constructor(Items, NewNameItem, CurrentPage, Query, SearchString) {
        super();
        this.Items = Items;
        this.NewNameItem = NewNameItem;
        this.CurrentPage = (CurrentPage | 0);
        this.Query = Query;
        this.SearchString = SearchString;
    }
}

export function State$reflection() {
    return record_type("Items.State", [], State, () => [["Items", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(ItemInfo$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(ItemInfo$reflection())]], [["ErrorValue", string_type]]]))], ["NewNameItem", string_type], ["CurrentPage", int32_type], ["Query", Common_PagingQuery$1$reflection(ItemFilter$reflection())], ["SearchString", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadItems", "PageChanged", "OnColumnClicked", "SearchStringChange", "KeyboardEvent", "SearchEnter"];
    }
}

export function Msg$reflection() {
    return union_type("Items.Msg", [], Msg, () => [[["Item1", Common_PagingQuery$1$reflection(ItemFilter$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(ItemInfo$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(ItemInfo$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", Common_PagingQuery$1$reflection(ItemFilter$reflection())]]]);
}

export function loadItems(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const query_1 = new Common_PagingQuery$1(new ItemFilter(query.Payload.FreeTextSearch.trim()), query.Page, query.PageSize, query.SortBy, query.LocalizationContext);
        return singleton.Bind(Remoting_handleNonAuth(itemApi().getItems(query_1)), (_arg1) => singleton.Return(new Msg(0, query_1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))));
    }), (_arg2) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(page, unitVar1) {
    const query = new Common_PagingQuery$1(new ItemFilter(""), 1, 20, void 0, getLocalizationContext());
    return [new State(new Deferred$1(0), "", page, query, ""), Cmd_batch(singleton_1(Cmd_OfFunc_result(new Msg(0, query, new AsyncOperationStatus$1(0)))))];
}

export function update(msg, state) {
    let s;
    if (msg.tag === 1) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Common_PagingQuery$1(inputRecord.Payload, msg.fields[0], inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(state.Items, state.NewNameItem, msg.fields[0], newQuery, state.SearchString), Cmd_OfFunc_result(new Msg(0, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 2) {
        let newQuery_1;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_1 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, inputRecord_1.Page, inputRecord_1.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_1.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_2 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, inputRecord_2.Page, inputRecord_2.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_2.LocalizationContext));
                break;
            }
        }
        return [new State(state.Items, state.NewNameItem, state.CurrentPage, newQuery_1, state.SearchString), Cmd_OfFunc_result(new Msg(0, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 3) {
        return [new State(state.Items, state.NewNameItem, state.CurrentPage, state.Query, msg.fields[0]), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new State(state.Items, state.NewNameItem, 1, state.Query, state.SearchString), Cmd_fromAsync(loadItems(msg.fields[0]))];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0] === "Enter") {
            let newQuery_2;
            const query = state.Query;
            newQuery_2 = (new Common_PagingQuery$1(new ItemFilter(state.SearchString.trim().toLowerCase()), 1, query.PageSize, query.SortBy, query.LocalizationContext));
            return [new State(state.Items, state.NewNameItem, state.CurrentPage, newQuery_2, state.SearchString), Cmd_OfFunc_result(new Msg(5, newQuery_2))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[1].tag === 1) {
        return [new State(new Deferred$1(2, msg.fields[1].fields[0]), state.NewNameItem, state.CurrentPage, state.Query, state.SearchString), Cmd_none()];
    }
    else {
        return [new State(new Deferred$1(1), state.NewNameItem, state.CurrentPage, state.Query, state.SearchString), Cmd_fromAsync(loadItems(state.Query))];
    }
}

export function tableHeader(state, dispatch) {
    let props_18, props_8, props_10, props_12, props_14;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(2, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_2(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_2(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    return createElement("div", {
        style: {
            marginBottom: 0.5 + "rem",
            paddingLeft: 1 + "rem",
        },
        children: Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "tableHeader"], ["className", "px-0"], ["className", "mx-0"], ["className", "my-0"], ["className", "py-0"], ["className", "is-gapless"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton("Item №", "id")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.title"), "title")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.category"), "category")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton("Photos", "Name")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-3"]))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))]),
    });
}

export function tableLine(item) {
    return createElement("div", createObj(toList(delay(() => append(singleton_2(["className", "is-size-6"]), delay(() => append(item.IsReturnedOrRecycled ? singleton_2(["style", {
        borderLeft: (((2 + "px ") + "solid") + " ") + "#000000",
        marginBottom: 0.5 + "rem",
        paddingLeft: 1 + "rem",
    }]) : singleton_2(["style", {
        marginBottom: 0.5 + "rem",
        paddingLeft: 1 + "rem",
    }]), delay(() => append(singleton_2(["className", "has-background-white"]), delay(() => {
        let props_23, props, props_2, props_4, props_15, props_13, props_11, props_9, props_21, props_18;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "px-0"], ["className", "mx-0"], ["className", "my-0"], ["className", "py-0"], ["className", "is-variable"], ["className", "is-1"], ["className", "is-vcentered"], ["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-1"], ["children", ItemIdModule_toString(item.Id)]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-4"], ["children", Localized_translate(item.Title)], ["className", "is-text-overflow-hidden"]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "is-2"], ["className", "is-text-overflow-hidden"], ["children", Localized_translate(item.Category)]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", item.ImagesCount]])))), (props_15 = ofArray([["className", "px-4"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(CreateOrResellLotComponent, {
            itemId: item.Id,
            disabled: item.IsReturnedOrRecycled,
            auctionPageAndFilterState: void 0,
            updateAuctionStateAndOpenNewTab: (url) => {
                window.open(url);
            },
            actionType: new ActionType(0),
        }), (props_11 = ofArray([["className", "is-rounded"], ["className", "is-danger"], ["disabled", item.IsReturnedOrRecycled], ["style", {
            borderRadius: 50 + "%",
            padding: 0,
            height: 1.655 + "em",
        }], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["style", {
            margin: 0,
        }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fas", "fa-times"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_9))))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15)))), (props_21 = ofArray([["className", "is-narrow"], ["className", "is-narrow"], ["className", "has-background-grey-lighter"], ["className", "py-0"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: "edit_button_href",
            href: toPath(new Route(15, item.Id)),
            children: Interop_reactApi.Children.toArray([(props_18 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-sign-in-alt"]),
            })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_18))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_23))))])]);
    }))))))))));
}

export function Render(renderInputProps) {
    let props_11, props_9, props_5, props_3, props_1, props_7, props_29;
    const page = renderInputProps.page;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => init(page, void 0), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_31 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_11 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_9 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_5 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("h1", {
        className: "title",
        children: "All Items",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_3))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_7 = ofArray([["className", "has-text-right"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_9))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_11)))), (props_29 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_23, props_21, elms, props_18, props_16;
        return append(singleton_2((props_23 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_21 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_18 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["className", "searchInput"], ["placeholder", "Sök utrop"], ["onChange", (ev) => {
            dispatch(new Msg(3, ev.target.value));
        }], ["onKeyUp", (ev_1) => {
            dispatch(new Msg(4, ev_1.key));
        }]]))))), (props_16 = ofArray([["className", "is-left"], ["style", {
            height: 100 + "%",
        }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-search", "fa-lg"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_16))))])]]), createElement("p", createObj(Helpers_combineClasses("control", props_18))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_21))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_23))))), delay(() => append(singleton_2(tableHeader(state_1, dispatch)), delay(() => {
            let children_1;
            const matchValue = state_1.Items;
            let pattern_matching_result, ex, items;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 2;
                    items = matchValue.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    ex = matchValue.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(loader("small"));
                }
                case 1: {
                    return singleton_2(createElement("h1", {
                        children: [ex],
                    }));
                }
                case 2: {
                    return singleton_2((children_1 = toList(delay(() => append(isEmpty(items.Result) ? singleton_2(createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", "No items"]]))))) : empty(), delay(() => append(map((item) => tableLine(item), items.Result), delay(() => singleton_2(renderPagination(items.Page, items.Total, items.PageSize, (arg_1) => {
                        dispatch(new Msg(1, arg_1));
                    })))))))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    })));
                }
            }
        }))));
    }))))]]), createElement("section", createObj(Helpers_combineClasses("section", props_29))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_31)));
}

