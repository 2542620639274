import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { union_type, record_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { SignIn_SignInForm, SignIn_SignedInUser$reflection } from "./Shared/Shared.js";
import { Result_MapError, FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { t } from "./Localization.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { signInApi } from "./Communication.js";
import { EmailModule_ofString, EmailModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { Cmd_OfFunc_perform, Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { saveUser } from "./LocalStorage.js";
import { Route, navigateTo } from "./Router.js";
import { singleton as singleton_1, cons, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { Image_logoNew, Image_loginPage } from "./Css.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { empty, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { React_memo_6648A89D } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { ElmishComponentProps$2 } from "./fable_modules/Feliz.ElmishComponents.1.2.0/Components.fs.js";

export class Form extends Record {
    constructor(Login, Password) {
        super();
        this.Login = Login;
        this.Password = Password;
    }
}

export function Form$reflection() {
    return record_type("Login.Form", [], Form, () => [["Login", string_type], ["Password", string_type]]);
}

export class State extends Record {
    constructor(Form, LoginResult) {
        super();
        this.Form = Form;
        this.LoginResult = LoginResult;
    }
}

export function State$reflection() {
    return record_type("Login.State", [], State, () => [["Form", Form$reflection()], ["LoginResult", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [SignIn_SignedInUser$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", SignIn_SignedInUser$reflection()]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoginChanged", "PasswordChanged", "LoginStatus", "UserSaved"];
    }
}

export function Msg$reflection() {
    return union_type("Login.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [SignIn_SignedInUser$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", SignIn_SignedInUser$reflection()]], [["ErrorValue", string_type]]]))]], []]);
}

export function mapError(_arg1) {
    if (_arg1.tag === 1) {
        return t("errors.fromServer.invalidUserOrPassword");
    }
    else {
        return t("errors.fromServer.invalidUserOrPassword");
    }
}

export function login(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const signInForm = new SignIn_SignInForm(form.Login, form.Password);
        return singleton.Bind(signInApi().signIn(signInForm), (_arg1) => singleton.Return(new Msg(2, new AsyncOperationStatus$1(1, Result_MapError((_arg1_1) => mapError(_arg1_1), _arg1)))));
    }), (_arg2) => singleton.Return(new Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init() {
    return [new State(new Form(EmailModule_empty, ""), new Deferred$1(0)), Cmd_none()];
}

export function update(signin, msg, state) {
    if (msg.tag === 1) {
        return [new State(new Form(state.Form.Login, msg.fields[0]), state.LoginResult), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.Form, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                saveUser(msg.fields[0].fields[0].fields[0]);
                return [new State(state.Form, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_batch(ofArray([Cmd_OfFunc_perform(signin, msg.fields[0].fields[0].fields[0], (_arg1) => (new Msg(3))), navigateTo(new Route(0))]))];
            }
        }
        else {
            return [new State(state.Form, new Deferred$1(1)), Cmd_fromAsync(login(state.Form))];
        }
    }
    else if (msg.tag === 3) {
        return [state, Cmd_none()];
    }
    else {
        return [new State(new Form(msg.fields[0], state.Form.Password), state.LoginResult), Cmd_none()];
    }
}

export function getError(state) {
    const matchValue = state.LoginResult;
    let pattern_matching_result, error;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return error;
        }
        case 1: {
            return "";
        }
    }
}

export function view(state, dispatch) {
    let props_33, props_31, props_29, props_1, elms, props_8, props_3, props_6, elms_1, props_16, props_11, props_14, elms_2, props_21, props_19, elms_4, elms_3, props_24;
    const isSaving = (state.LoginResult.tag === 1) ? true : false;
    return createElement("form", {
        style: {
            height: 100 + "vh",
            width: 100 + "vw",
            backgroundSize: "cover",
            backgroundImage: ("url(\u0027" + Image_loginPage) + "\u0027)",
        },
        className: "has-background-light",
        onSubmit: (e) => {
            e.preventDefault();
            dispatch(new Msg(2, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray([createElement("img", {
            style: {
                width: 150,
                position: "absolute",
                top: 40,
                left: 40,
            },
            src: Image_logoNew,
        }), createElement("fieldset", {
            disabled: isSaving,
            children: Interop_reactApi.Children.toArray([(props_33 = ofArray([["style", {
                height: 100 + "vh",
                justifyContent: "flex-end",
                marginTop: 0,
            }], ["className", "has-text-centered"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "pt-0"], ["className", "pb-0"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_29 = ofArray([["style", {
                height: 100 + "vh",
                display: "flex",
                alignItems: "center",
            }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    width: 100 + "%",
                    textAlign: "left",
                },
                children: Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "has-text-weight-bold"], ["children", t("users.logIn")]]), createElement("h4", createObj(Helpers_combineClasses("title is-4", props_1)))), (elms = singleton_1((props_8 = ofArray([["className", join(" ", ["has-icons-left", "mb-4"])], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-medium"], ["placeholder", t("common.email")], ["onChange", (ev) => {
                    dispatch(new Msg(0, EmailModule_ofString(ev.target.value)));
                }]]), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_3))))), (props_6 = ofArray([["className", "is-left"], ["className", "is-small"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fas", "fa-envelope"]),
                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (elms_1 = singleton_1((props_16 = ofArray([["className", join(" ", ["has-icons-left", "mb-4"])], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-medium"], ["placeholder", t("users.password")], ["onChange", (ev_1) => {
                    dispatch(new Msg(1, ev_1.target.value));
                }]]), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_11))))), (props_14 = ofArray([["className", "is-small"], ["className", "is-left"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-lock"]),
                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_16))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                })), (elms_2 = singleton_1((props_21 = ofArray([["className", join(" ", ["mb-4"])], ["children", Interop_reactApi.Children.toArray([(props_19 = toList(delay(() => append(isSaving ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["className", "is-fullwidth"]), delay(() => singleton_2(["children", t("users.logIn")]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_19))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_21))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                })), (elms_4 = singleton_1((elms_3 = singleton_1((props_24 = ofArray([["className", "is-danger"], ["children", getError(state)]]), createElement("p", createObj(Helpers_combineClasses("help", props_24))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                }))]),
            })])]]), createElement("div", createObj(Helpers_combineClasses("box", props_29))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_33))))]),
        })]),
    });
}

export function loginPage(loggedIn) {
    const name = "Login";
    const init_1 = init();
    const key = void 0;
    const fullKey = (key != null) ? ((name + "-") + key) : name;
    return React_memo_6648A89D(name, (input) => {
        const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(input.Initial, input.Update, []);
        return input.Render(patternInput[0], patternInput[1]);
    }, (_arg1) => fullKey)(new ElmishComponentProps$2(init_1, (msg, state) => update(loggedIn, msg, state), (state_1, dispatch) => view(state_1, dispatch)));
}

