import * as bidflowlogo from "../../public/img/bidflowlogo.png";
import * as photo$0020placeholder from "../../public/img/photo placeholder.svg";
import * as bidflow_background_login from "../../public/img/bidflow_background_login.jpg";
import * as Bidflowlogo_2$002E0_white from "../../public/img/Bidflowlogo_2.0_white.svg";
import { ImageUrlModule_fromString, ImageUrlModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { isNullOrEmpty } from "./fable_modules/fable-library.3.6.1/String.js";

export const Image_logo = bidflowlogo;

export const Image_imagePlaceholder = photo$0020placeholder;

export const Image_loginPage = bidflow_background_login;

export const Image_logoNew = Bidflowlogo_2$002E0_white;

export function Image_onErrorLoadImage(fallbackImage, e) {
    const elem = e.target;
    elem.onerror = ((value) => {
    });
    elem.src = ImageUrlModule_toString(fallbackImage);
}

export function Image_onImageLoadFail(x) {
    Image_onErrorLoadImage(Image_imagePlaceholder, x);
}

export const Image_is150x100 = [150, 100];

export const Image_is350x192 = [350, 192];

export const Image_is2736x1824 = [2736, 1824];

export const Image_is800x533 = [800, 533];

export function Image_originalImageUrl(image) {
    const version = isNullOrEmpty(image.Version) ? "bidflow" : image.Version;
    return ImageUrlModule_fromString(`https://img.imageboss.me/${version}/cdn/format:jpg/${image.Id}`);
}

export function Image_url(w, h, image) {
    const version = isNullOrEmpty(image.Version) ? "bidflow" : image.Version;
    return ImageUrlModule_fromString(`https://img.imageboss.me/${version}/cover:center/${w}x${h}/format:jpg/${image.Id}`);
}

export function Image_urlContain(w, h, image) {
    const version = isNullOrEmpty(image.Version) ? "bidflow" : image.Version;
    return ImageUrlModule_fromString(`https://img.imageboss.me/${version}/cover:contain/${w}x${h}/format:jpg/${image.Id}`);
}

export function Image_originalImageUrlPng(image) {
    const version = isNullOrEmpty(image.Version) ? "bidflow" : image.Version;
    return ImageUrlModule_fromString(`https://img.imageboss.me/${version}/cdn/format:png/${image.Id}`);
}

export const Image_small = (image) => Image_url(Image_is150x100[0], Image_is150x100[1], image);

export const Image_smallContains = (image) => Image_urlContain(Image_is150x100[0], Image_is150x100[1], image);

export const Image_medium = (image) => Image_url(Image_is350x192[0], Image_is350x192[1], image);

export const Image_mediumContains = (image) => Image_urlContain(Image_is350x192[0], Image_is350x192[1], image);

export const Image_large = (image) => Image_url(Image_is2736x1824[0], Image_is2736x1824[1], image);

export const Image_lotMedium800ImagePath = (image) => Image_url(Image_is800x533[0], Image_is800x533[1], image);

export const Image_lotMedium800ImagePathContains = (image) => Image_urlContain(Image_is800x533[0], Image_is800x533[1], image);

export const Image_originalImagePath = (image) => Image_originalImageUrl(image);

export const Image_originalImagePathPng = (image) => Image_originalImageUrlPng(image);

