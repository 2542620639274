import { Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { class_type, union_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Cmd_ofSub } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createCancellationToken, startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { removeUser } from "./LocalStorage.js";
import { ProxyRequestException__get_StatusCode, ProxyRequestException } from "./fable_modules/Fable.Remoting.Client.7.10.0/Types.fs.js";

export class Deferred$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasNotStartedYet", "InProgress", "Resolved"];
    }
}

export function Deferred$1$reflection(gen0) {
    return union_type("Extensions.Deferred`1", [gen0], Deferred$1, () => [[], [], [["Item", gen0]]]);
}

export class AsyncOperationStatus$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Started", "Finished"];
    }
}

export function AsyncOperationStatus$1$reflection(gen0) {
    return union_type("Extensions.AsyncOperationStatus`1", [gen0], AsyncOperationStatus$1, () => [[], [["Item", gen0]]]);
}

export function Async_map(f, computation) {
    return singleton.Delay(() => singleton.Bind(computation, (_arg1) => singleton.Return(f(_arg1))));
}

export function Async_tee(f, computation) {
    return singleton.Delay(() => singleton.Bind(computation, (_arg1) => {
        const x = _arg1;
        f(x);
        return singleton.Return(x);
    }));
}

export function Cmd_fromAsync(operation) {
    return Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(operation, (_arg1) => {
            dispatch(_arg1);
            return singleton.Zero();
        })));
    });
}

export function Cmd_fromAsyncCsp(operation) {
    const cs = createCancellationToken();
    return [Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(operation, (_arg1) => {
            dispatch(_arg1);
            return singleton.Zero();
        })), cs);
    }), cs];
}

export function Remoting_handleNonAuth(computation) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.ReturnFrom(computation)), (_arg1) => {
        let pattern_matching_result, ex_1;
        if (_arg1 instanceof ProxyRequestException) {
            if (ProxyRequestException__get_StatusCode(_arg1) === 401) {
                pattern_matching_result = 0;
                ex_1 = _arg1;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                console.debug("Catch 401, removing user from storage and raise un-authorize event");
                removeUser();
                document.dispatchEvent(new Event('Fable.Remoting.Unauthorized'));
                return singleton.Return((() => {
                    throw ex_1;
                })());
            }
            case 1: {
                throw _arg1;
                return null;
            }
        }
    }));
}

export function Remoting_subscribe(msg, _model) {
    return Cmd_ofSub((dispatch) => {
        document.addEventListener('Fable.Remoting.Unauthorized', function (e) {(() => {
            dispatch(msg);
        })() });
    });
}

export function KeyValuePair_key(x) {
    return x[0];
}

export function KeyValuePair_value(x) {
    return x[1];
}

export function KeyValuePair_create(key, value) {
    return [key, value];
}

export class FlatpickrExtensions_Locales {
    constructor() {
    }
}

export function FlatpickrExtensions_Locales$reflection() {
    return class_type("Extensions.FlatpickrExtensions.Locales", void 0, FlatpickrExtensions_Locales);
}

