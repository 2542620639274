import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, record_type, bool_type, lambda_type, class_type, unit_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { t } from "../Localization.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { useReact_useEffect_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Deferred_iter, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_2, empty as empty_1 } from "../fable_modules/fable-library.3.6.1/List.js";

export class Component_Props extends Record {
    constructor(DialogCaption, Title, SuccessText, CancelText, CommentText, Comment$, OnSuccess, OnCancel, Key, IsVisible) {
        super();
        this.DialogCaption = DialogCaption;
        this.Title = Title;
        this.SuccessText = SuccessText;
        this.CancelText = CancelText;
        this.CommentText = CommentText;
        this.Comment = Comment$;
        this.OnSuccess = OnSuccess;
        this.OnCancel = OnCancel;
        this.Key = Key;
        this.IsVisible = IsVisible;
    }
}

export function Component_Props$reflection() {
    return record_type("Components.Confirmation.WithComment.Component.Props", [], Component_Props, () => [["DialogCaption", string_type], ["Title", string_type], ["SuccessText", string_type], ["CancelText", string_type], ["CommentText", string_type], ["Comment", string_type], ["OnSuccess", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))], ["OnCancel", lambda_type(unit_type, unit_type)], ["Key", string_type], ["IsVisible", bool_type]]);
}

export function Component_Props_get_Default() {
    return new Component_Props(t("common.confirmation"), t("common.confirmation"), t("common.confirm"), t("common.cancel"), t("common.comment"), "", (_arg1) => singleton.Delay(() => singleton.Return()), () => {
    }, "", true);
}

export function Component_ConfirmationWithComment(props) {
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const toggleState = patternInput[1];
    const modalState = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(props.Comment);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_2[0];
    const callbackSuccess = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => props.OnSuccess(patternInput_1[0]), patternInput_2[1]);
    useReact_useEffect_3A5B6456(() => {
        Deferred_iter(() => {
            toggleState(false);
        }, data);
    });
    const onCancel = (_arg1) => {
        props.OnCancel();
        toggleState(false);
    };
    const patternInput_3 = (data.tag === 1) ? [true, ""] : ((data.tag === 3) ? [false, data.fields[0].message] : ((data.tag === 2) ? [false, ""] : [false, ""]));
    const isLoading = patternInput_3[0];
    if (!modalState) {
        return null;
    }
    else {
        const props_23 = toList(delay(() => append(singleton_1(["key", props.Key]), delay(() => append(modalState ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
            let elms_4, elms, elms_3, elms_2, elms_1, props_20, props_18;
            return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (elms_4 = ofArray([(elms = ofArray([createElement("p", {
                className: "modal-card-title",
                children: props.DialogCaption,
            }), createElement("button", createObj(Helpers_combineClasses("delete", singleton_2(["onClick", onCancel]))))]), createElement("header", {
                className: "modal-card-head",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (elms_3 = ofArray([createElement("h4", {
                className: "title is-4",
                children: props.Title,
            }), (elms_2 = ofArray([createElement("label", {
                className: "label",
                children: props.CommentText,
            }), (elms_1 = singleton_2(createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["disabled", isLoading], ["onChange", (ev) => {
                patternInput_1[1](ev.target.value);
            }]]))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            })), createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "is-danger"], ["children", patternInput_3[1]]]))))]), createElement("section", {
                className: "modal-card-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            })), (props_20 = ofArray([["style", {
                justifyContent: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["disabled", isLoading], ["children", props.CancelText], ["onClick", onCancel]])))), (props_18 = toList(delay(() => append(isLoading ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(singleton_1(["children", props.SuccessText]), delay(() => singleton_1(["onClick", (x) => {
                x.preventDefault();
                callbackSuccess();
            }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_18))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_20))))]), createElement("div", {
                className: "modal-card",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))])]);
        }))))));
        return createElement("div", createObj(Helpers_combineClasses("modal", props_23)));
    }
}

export class Feliz_ConfirmationWithCommentProperty extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DialogCaption", "Title", "SuccessCaption", "CancelCaption", "CommentTitle", "Comment", "OnSuccess", "OnCancel", "Key", "IsVisible"];
    }
}

export function Feliz_ConfirmationWithCommentProperty$reflection() {
    return union_type("Components.Confirmation.WithComment.Feliz.ConfirmationWithCommentProperty", [], Feliz_ConfirmationWithCommentProperty, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", string_type]], [["Item", bool_type]]]);
}

export class Feliz_prop {
    constructor() {
    }
}

export function Feliz_prop$reflection() {
    return class_type("Components.Confirmation.WithComment.Feliz.prop", void 0, Feliz_prop);
}

export class Feliz_Confirmation {
    constructor() {
    }
}

export function Feliz_Confirmation$reflection() {
    return class_type("Components.Confirmation.WithComment.Feliz.Confirmation", void 0, Feliz_Confirmation);
}

