import { LocalizedStringModule_findFirstNotEmptyTranslation, LocalizedStringModule_getTranslationOrEmpty, LangModule_$007CSwedish$007CEnglish$007CFinnish$007C, LocalizationContext, LangModule_toString, LangModule_fromString, LangModule_swedish } from "./bidflow/src/infrastructure/Localization.js";
import { ofArray, append, contains, singleton } from "./fable_modules/fable-library.3.6.1/List.js";
import { Localization_LocalizationSettings } from "./Shared/Shared.js";
import { defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { stringHash } from "./fable_modules/fable-library.3.6.1/Util.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { localizationSettingsApi } from "./Communication.js";
import i18next from "i18next";
import * as index from "../../locales/index.js";
import { CountryModule_englishName, CountryModule_swedishName, CountryModule_finnishName } from "./bidflow/src/infrastructure/Countries.js";
import { sv, fi } from "date-fns/locale";
import { format as format_4 } from "date-fns";
import { isNullOrEmpty } from "./fable_modules/fable-library.3.6.1/String.js";

const localStorageKey = "lng";

const defaultSettings = new Localization_LocalizationSettings(singleton(LangModule_swedish), LangModule_swedish);

let currentSettings = defaultSettings;

function tryGetCurrentLanguageFromLocalStorage() {
    try {
        return LangModule_fromString(localStorage.getItem(localStorageKey));
    }
    catch (matchValue) {
        return void 0;
    }
}

function setCurrentLanguageToLocalStorage(lang) {
    localStorage.setItem(localStorageKey, LangModule_toString(lang));
}

export function getSupportedLanguages() {
    return currentSettings.SupportedLanguages;
}

export function getPrimaryLanguage() {
    return currentSettings.PrimaryLanguage;
}

export function getCurrentLanguage() {
    return defaultArg(tryGetCurrentLanguageFromLocalStorage(), currentSettings.PrimaryLanguage);
}

export function setCurrentLanguage(lang) {
    if (contains(lang, currentSettings.SupportedLanguages, {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })) {
        setCurrentLanguageToLocalStorage(lang);
    }
    else {
        throw (new Error(`The given language is unknown: ${lang}`));
    }
}

export function getLocalizationContext() {
    return new LocalizationContext(currentSettings.SupportedLanguages, currentSettings.PrimaryLanguage, getCurrentLanguage());
}

const init = startImmediate(singleton_1.Delay(() => singleton_1.Bind(localizationSettingsApi().getSettings(), (_arg1) => {
    let matchValue, isCurrLangNotSupported;
    currentSettings = _arg1;
    if ((matchValue = tryGetCurrentLanguageFromLocalStorage(), (matchValue == null) ? ((setCurrentLanguageToLocalStorage(currentSettings.PrimaryLanguage), currentSettings.PrimaryLanguage !== defaultSettings.PrimaryLanguage)) : ((isCurrLangNotSupported = (!contains(matchValue, currentSettings.SupportedLanguages, {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })), (isCurrLangNotSupported ? setCurrentLanguageToLocalStorage(currentSettings.PrimaryLanguage) : (void 0), isCurrLangNotSupported))))) {
        document.location.reload();
        return singleton_1.Zero();
    }
    else {
        return singleton_1.Zero();
    }
})));

export const i18n = i18next;

export const resources = index;

console.debug(i18n.t("localization:ReceivedAt"));

export const initParams = {
    lng: LangModule_toString(getCurrentLanguage()),
    resources: resources,
};

i18n.init(initParams);

export function t(key) {
    return i18n.t("localization:" + key);
}

export function tWithArgs(key, args) {
    return i18n.t(("localization:" + key), args);
}

export const Localized_getCountryName = (() => {
    const matchValue = getCurrentLanguage();
    const activePatternResult29862 = LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(matchValue);
    return (activePatternResult29862.tag === 2) ? CountryModule_finnishName : ((activePatternResult29862.tag === 0) ? CountryModule_swedishName : CountryModule_englishName);
})();

export function Localized_formatDateTime(format, dt) {
    const matchValue = getCurrentLanguage();
    const activePatternResult29863 = LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(matchValue);
    if (activePatternResult29863.tag === 2) {
        const locale = fi;
        const formatOptions = {};
        formatOptions.locale = locale;
        return format_4(dt, format, formatOptions);
    }
    else if (activePatternResult29863.tag === 0) {
        const locale_1 = sv;
        const formatOptions_1 = {};
        formatOptions_1.locale = locale_1;
        return format_4(dt, format, formatOptions_1);
    }
    else {
        return format_4(dt, format);
    }
}

export function Localized_chooseTranslation(inEnglish, inSweden) {
    const choose = (primary, secondary) => {
        if (isNullOrEmpty(primary)) {
            return secondary;
        }
        else {
            return primary;
        }
    };
    const matchValue = getCurrentLanguage();
    const activePatternResult29868 = LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(matchValue);
    if (activePatternResult29868.tag === 0) {
        return choose(inSweden, inEnglish);
    }
    else if (activePatternResult29868.tag === 2) {
        return choose(inEnglish, inSweden);
    }
    else {
        return choose(inEnglish, inSweden);
    }
}

export function Localized_translateTo(lang, ls) {
    return LocalizedStringModule_getTranslationOrEmpty(lang, ls);
}

export function Localized_translate(ls) {
    return Localized_translateTo(getPrimaryLanguage(), ls);
}

export function Localized_translateToNonEmptyLanguage(ls) {
    const ctx = getLocalizationContext();
    return defaultArg(LocalizedStringModule_findFirstNotEmptyTranslation(append(ofArray([ctx.PrimaryLanguage, ctx.CurrentLanguage]), ctx.SupportedLanguages), ls), "");
}

