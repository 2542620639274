import { Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { list_type, record_type, option_type, tuple_type, string_type, int32_type, union_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { fold, map } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { FSharpChoice$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { LocalizationContext$reflection } from "../bidflow/src/infrastructure/Localization.js";
import { filter, map as map_1 } from "../fable_modules/fable-library.3.6.1/List.js";
import { round } from "../fable_modules/fable-library.3.6.1/Util.js";
import { toNumber } from "../fable_modules/fable-library.3.6.1/Long.js";
import { minute, addMinutes, hour, addHours, day, month, year, create, dayOfWeek as dayOfWeek_1, addDays } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { compare } from "../fable_modules/fable-library.3.6.1/Date.js";

export class Types_Tree$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Leaf", "Node"];
    }
}

export function Types_Tree$2$reflection(gen0, gen1) {
    return union_type("Shared.Types.Tree`2", [gen0, gen1], Types_Tree$2, () => [[["Item", gen0]], [["Item1", gen1], ["Item2", class_type("System.Collections.Generic.IEnumerable`1", [Types_Tree$2$reflection(gen0, gen1)])]]]);
}

export function Types_Tree_cata(fLeaf, fNode, tree) {
    if (tree.tag === 1) {
        return fNode(tree.fields[0], map((tree_1) => Types_Tree_cata(fLeaf, fNode, tree_1), tree.fields[1]));
    }
    else {
        return fLeaf(tree.fields[0]);
    }
}

export function Types_Tree_fold(fLeaf, fNode, acc, tree) {
    if (tree.tag === 1) {
        return fold((acc_1, tree_1) => Types_Tree_fold(fLeaf, fNode, acc_1, tree_1), fNode(acc, tree.fields[0]), tree.fields[1]);
    }
    else {
        return fLeaf(acc, tree.fields[0]);
    }
}

export function Types_Tree_map(fLeaf, fNode, tree) {
    if (tree.tag === 1) {
        return new Types_Tree$2(1, fNode(tree.fields[0]), map((tree_1) => Types_Tree_map(fLeaf, fNode, tree_1), tree.fields[1]));
    }
    else {
        return new Types_Tree$2(0, fLeaf(tree.fields[0]));
    }
}

export function Types_Tree_payload(tree) {
    if (tree.tag === 1) {
        return new FSharpChoice$2(1, tree.fields[0]);
    }
    else {
        return new FSharpChoice$2(0, tree.fields[0]);
    }
}

export class Common_Sorting extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Ascending", "Descending"];
    }
}

export function Common_Sorting$reflection() {
    return union_type("Shared.Common.Sorting", [], Common_Sorting, () => [[], []]);
}

export function Common_SortingModule_toggle(_arg1) {
    if (_arg1.tag === 1) {
        return new Common_Sorting(0);
    }
    else {
        return new Common_Sorting(1);
    }
}

export class Common_PagingQuery$1 extends Record {
    constructor(Payload, Page, PageSize, SortBy, LocalizationContext) {
        super();
        this.Payload = Payload;
        this.Page = (Page | 0);
        this.PageSize = (PageSize | 0);
        this.SortBy = SortBy;
        this.LocalizationContext = LocalizationContext;
    }
}

export function Common_PagingQuery$1$reflection(gen0) {
    return record_type("Shared.Common.PagingQuery`1", [gen0], Common_PagingQuery$1, () => [["Payload", gen0], ["Page", int32_type], ["PageSize", int32_type], ["SortBy", option_type(tuple_type(string_type, Common_Sorting$reflection()))], ["LocalizationContext", LocalizationContext$reflection()]]);
}

export function Common_PagingQuery$1__get_SkipSize(this$) {
    return (this$.Page - 1) * this$.PageSize;
}

export function Common_PagingQuery_create(page, pageSize, sortBy, payload, localizationCtx) {
    return new Common_PagingQuery$1(payload, page, pageSize, sortBy, localizationCtx);
}

export function Common_PagingQuery_createWithNoSorting(page, pageSize) {
    return (payload) => ((localizationCtx) => Common_PagingQuery_create(page, pageSize, void 0, payload, localizationCtx));
}

export function Common_PagingQuery_pageSize(size, query) {
    return new Common_PagingQuery$1(query.Payload, query.Page, size, query.SortBy, query.LocalizationContext);
}

export function Common_PagingQuery_page(page, query) {
    return new Common_PagingQuery$1(query.Payload, page, query.PageSize, query.SortBy, query.LocalizationContext);
}

export function Common_PagingQuery_map(f, x) {
    return new Common_PagingQuery$1(f(x.Payload), x.Page, x.PageSize, x.SortBy, x.LocalizationContext);
}

export class Common_PagingResult$1 extends Record {
    constructor(Page, PageSize, Total, Result) {
        super();
        this.Page = (Page | 0);
        this.PageSize = (PageSize | 0);
        this.Total = Total;
        this.Result = Result;
    }
}

export function Common_PagingResult$1$reflection(gen0) {
    return record_type("Shared.Common.PagingResult`1", [gen0], Common_PagingResult$1, () => [["Page", int32_type], ["PageSize", int32_type], ["Total", class_type("System.Int64")], ["Result", list_type(gen0)]]);
}

export function Common_PagingResult_map(f, x) {
    return new Common_PagingResult$1(x.Page, x.PageSize, x.Total, map_1(f, x.Result));
}

export function Common_PagingResult_filter(f, x) {
    return new Common_PagingResult$1(x.Page, x.PageSize, x.Total, filter(f, x.Result));
}

export function Common_PagingResult_totalPages(x) {
    return ~(~round(Math.ceil(toNumber(x.Total) / x.PageSize)));
}

export function Common_PagingResult_create(page, pageSize, total, result) {
    return new Common_PagingResult$1(page, pageSize, total, result);
}

export function Common_PagingResult_ofQuery(total, result, query) {
    return Common_PagingResult_create(query.Page, query.PageSize, total, result);
}

export function Common_Auction_findNextAuctionDate(now, dayOfWeek, time) {
    const date = addDays(now, ((dayOfWeek + 7) - dayOfWeek_1(now)) % 7);
    let r;
    let copyOfStruct_1;
    let copyOfStruct = create(year(date), month(date), day(date), 0, 0, 0, now.offset);
    copyOfStruct_1 = addHours(copyOfStruct, hour(time));
    r = addMinutes(copyOfStruct_1, minute(time));
    if (compare(r, now) <= 0) {
        return addDays(r, 7);
    }
    else {
        return r;
    }
}

