import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { Settings_User_VerificationNeededForModule_options, Settings_User_VerificationNeededFor, Settings_User_Settings, Settings_User_VerificationNeededFor$reflection, Settings_User_Settings$reflection } from "./Shared/Shared.js";
import { record_type, union_type, unit_type, bool_type, class_type, list_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_handleNonAuth, Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { commonApi, settingsApi } from "./Communication.js";
import { FSharpMap__get_IsEmpty, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Settings_User_validate } from "./Validation.js";
import { t, tWithArgs } from "./Localization.js";
import { CountryModule_swedishName } from "./bidflow/src/infrastructure/Countries.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { singleton as singleton_1, cons, map, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { isCurrentUserAdmin, isCurrentUserManager } from "./LocalStorage.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";

export class Types_State extends Record {
    constructor(Settings, OriginalSettings, ResidentCountry, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.Settings = Settings;
        this.OriginalSettings = OriginalSettings;
        this.ResidentCountry = ResidentCountry;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function Types_State$reflection() {
    return record_type("Client.SettingsUser.Types.State", [], Types_State, () => [["Settings", Settings_User_Settings$reflection()], ["OriginalSettings", Settings_User_Settings$reflection()], ["ResidentCountry", string_type], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["VerificationNeededForChanged", "CancelEdit", "SaveForm"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.SettingsUser.Types.Msg", [], Types_Msg, () => [[["Item", Settings_User_VerificationNeededFor$reflection()]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_saveForm(settings) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(settingsApi().saveUserSettings(settings), () => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(settings) {
    return [new Types_State(settings.UserSettings, settings.UserSettings, settings.ResidentCountry, empty(), true, new Deferred$1(0)), Cmd_none()];
}

export function State_withFormAndErrors(form, errors, state) {
    return new Types_State(form, state.OriginalSettings, state.ResidentCountry, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function State_update(msg, state) {
    let tupledArg, settings_1, matchValue;
    if (msg.tag === 1) {
        return [new Types_State(state.OriginalSettings, state.OriginalSettings, state.ResidentCountry, empty(), false, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            return [new Types_State(state.Settings, state.Settings, state.ResidentCountry, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.Settings, state.OriginalSettings)) {
            const matchValue_1 = Settings_User_validate(state.Settings);
            if (matchValue_1.tag === 1) {
                return [new Types_State(state.Settings, state.OriginalSettings, state.ResidentCountry, matchValue_1.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new Types_State(state.Settings, state.OriginalSettings, state.ResidentCountry, empty(), true, new Deferred$1(1)), Cmd_fromAsync(Cmd_saveForm(state.Settings))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [(tupledArg = ((settings_1 = (new Settings_User_Settings(msg.fields[0])), state.NeedToValidate ? ((matchValue = Settings_User_validate(settings_1), (matchValue.tag === 1) ? [settings_1, matchValue.fields[0]] : [matchValue.fields[0], empty()])) : [settings_1, empty()])), State_withFormAndErrors(tupledArg[0], tupledArg[1], state)), Cmd_none()];
    }
}

export function View_toTitle(residentCountry, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return tWithArgs("settings.allExceptFromResidentCountry", {
                residentCountry: CountryModule_swedishName(residentCountry),
            });
        }
        case 2: {
            return t("common.noOne");
        }
        default: {
            return t("common.all");
        }
    }
}

export function View_toValue(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "allExceptFromResidentCountry";
        }
        case 2: {
            return "noOne";
        }
        default: {
            return "all";
        }
    }
}

export function View_tryParse(s) {
    switch (s) {
        case "all": {
            return new FSharpResult$2(0, new Settings_User_VerificationNeededFor(0));
        }
        case "allExceptFromResidentCountry": {
            return new FSharpResult$2(0, new Settings_User_VerificationNeededFor(1));
        }
        case "noOne": {
            return new FSharpResult$2(0, new Settings_User_VerificationNeededFor(2));
        }
        default: {
            return new FSharpResult$2(1, `Unknown user verification mode ${s}`);
        }
    }
}

export function View_parse(s) {
    const matchValue = View_tryParse(s);
    if (matchValue.tag === 1) {
        throw (new Error(matchValue.fields[0]));
    }
    else {
        return matchValue.fields[0];
    }
}

export function View_render(state, dispatch) {
    let props_1, s, props_26, props_24, props_22, props_14, elms_1, props_5, props_3, elms, patternInput, props_20, props_16, props_18;
    const isFormSaving = equals(state.SaveFormOperation, new Deferred$1(1));
    const needDisableSaving = (isFormSaving ? true : (!(!equals(state.Settings, state.OriginalSettings)))) ? true : (!FSharpMap__get_IsEmpty(state.FormErrors));
    const elms_2 = ofArray([(props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("common.users"), createElement("h1", {
        className: "title",
        children: s,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1)))), createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_26 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "has-text-grey-dark"], ["className", "is-half"], ["children", Interop_reactApi.Children.toArray([(props_14 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_5 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.verificationIsRequiredFor")]]), createElement("label", createObj(Helpers_combineClasses("label", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_5)))), (elms = singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["value", View_toValue(state.Settings.VerificationNeededFor)], ["onChange", (ev) => {
            dispatch(new Types_Msg(0, View_parse(ev.target.value)));
        }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x) => createElement("option", {
            value: View_toValue(x),
            children: View_toTitle(state.ResidentCountry, x),
        }), Settings_User_VerificationNeededForModule_options)))]])), createElement("div", {
            className: join(" ", cons("select", patternInput[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_14)))), (props_20 = ofArray([["className", "mt-6"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_16 = toList(delay(() => append(singleton_2(["className", "is-dark"]), delay(() => append(isFormSaving ? singleton_2(["disabled", true]) : empty_1(), delay(() => append(singleton_2(["children", t("common.cancel")]), delay(() => singleton_2(["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new Types_Msg(1));
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_16)))), (props_18 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(needDisableSaving ? singleton_2(["disabled", true]) : empty_1(), delay(() => append(isFormSaving ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["children", t("common.save")]), delay(() => singleton_2(["onClick", (x_2) => {
            x_2.preventDefault();
            dispatch(new Types_Msg(2, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_24))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_26))))]),
    })]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function Component_InstanceWith(settings) {
    if (isCurrentUserManager() ? true : isCurrentUserAdmin()) {
        const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(settings), (msg, state) => State_update(msg, state), []);
        return View_render(patternInput[0], patternInput[1]);
    }
    else {
        return null;
    }
}

export function Component_Instance() {
    const settings_1 = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getUserSettings()), (_arg1) => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg2) => singleton.Return(new FSharpResult$2(0, {
        ResidentCountry: _arg2.ResidentCountry,
        UserSettings: _arg1,
    }))))), (_arg3) => singleton.Return(new FSharpResult$2(1, _arg3.message))));
    if (isCurrentUserAdmin()) {
        const getSettingsDeferred = useFeliz_React__React_useDeferred_Static_2344FC52(settings_1, []);
        let pattern_matching_result, ex_1, er, settings_2;
        if (getSettingsDeferred.tag === 1) {
            pattern_matching_result = 0;
        }
        else if (getSettingsDeferred.tag === 3) {
            pattern_matching_result = 1;
            ex_1 = getSettingsDeferred.fields[0];
        }
        else if (getSettingsDeferred.tag === 2) {
            if (getSettingsDeferred.fields[0].tag === 0) {
                pattern_matching_result = 3;
                settings_2 = getSettingsDeferred.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 2;
                er = getSettingsDeferred.fields[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return loader("big");
            }
            case 1: {
                return ex_1.message;
            }
            case 2: {
                return er;
            }
            case 3: {
                return createElement(Component_InstanceWith, settings_2);
            }
        }
    }
    else {
        return null;
    }
}

