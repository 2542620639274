import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { buttonIcon } from "./Components/IconButton.js";
import { t } from "./Localization.js";
import { Route, toPath } from "./Router.js";
import { AuctionModeType, User_SystemUser } from "./Shared/Shared.js";
import { ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { isCurrentUserAdmin } from "./LocalStorage.js";
import { dividedTop } from "./Components/BorderDividedLine.js";
import { releaseNotes } from "./Components/ReleaseNotes.js";

export function render() {
    let props_2, props, props_6, props_4, props_8;
    const elms = ofArray([(props_2 = ofArray([["className", "pb-2"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([buttonIcon(t("home.customers"), "fal", "fa-users", toPath(new Route(7, 1, new User_SystemUser(0), void 0)), "homeButton__info"), buttonIcon(t("home.auctions"), "fal", "fa-gavel", toPath(new Route(3, new AuctionModeType(0), 1)), "homeButton__primary"), buttonIcon(t("home.cart"), "fal", "fa-shopping-cart", toPath(new Route(13)), "homeButton__danger"), buttonIcon(t("home.transport"), "fal", "fa-truck", toPath(new Route(23, 1)), "homeButton__warning")])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_2)))), (props_6 = ofArray([["className", "pt-2"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(buttonIcon(t("home.warehouse"), "fal", "fa-inbox", toPath(new Route(14, 1)), "homeButton__grey")), delay(() => append(singleton(buttonIcon(t("home.economy"), "fal", "fa-chart-pie", toPath(new Route(27)), "homeButton__grey")), delay(() => append(isCurrentUserAdmin() ? singleton(buttonIcon(t("home.settings"), "fal", "fa-tools", toPath(new Route(31)), "homeButton__grey")) : empty(), delay(() => singleton(buttonIcon(t("home.content"), "fal", "fa-sidebar", toPath(new Route(37)), "homeButton__grey"))))))))))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_4))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_6)))), (props_8 = ofArray([dividedTop, ["className", "py-4"], ["children", Interop_reactApi.Children.toArray([releaseNotes])]]), createElement("section", createObj(Helpers_combineClasses("section", props_8))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

