import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.3.6.1/List.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Orders_CancelRemoveLotFromOrderRequest } from "../Shared/Shared.js";
import { ordersApi } from "../Communication.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Localized_formatDateTime, t, tWithArgs } from "../Localization.js";
import { EditLotArg, Route, AuctionArg, AuctionState, toPath } from "../Router.js";
import { empty, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";

export function confirmation(question, proceedText, cancelText, onProceed, onCancel) {
    let props_18, props_9, props_3, props_7, props_16, props_11, props_14;
    const props_20 = ofArray([["style", {
        overflow: "visible",
    }], ["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (x) => {
        x.preventDefault();
        onCancel();
    }])))), (props_18 = ofArray([["className", "has-background-light"], ["style", {
        borderRadius: 4,
    }], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "mx-5"], ["className", "mt-5"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("h4", {
        className: "title is-4",
        children: question,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_7 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton(["onClick", (x_1) => {
        x_1.preventDefault();
        onCancel();
    }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_9)))), (props_16 = ofArray([["className", "mx-5"], ["className", "mb-5"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-dark"], ["children", cancelText], ["className", join(" ", ["is-align-items-baseline", "is-justify-content-space-between"])], ["onClick", (x_2) => {
        x_2.preventDefault();
        onCancel();
    }]]), createElement("button", createObj(Helpers_combineClasses("button", props_11)))), (props_14 = ofArray([["onClick", (x_3) => {
        x_3.preventDefault();
        onProceed();
    }], ["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        className: "",
        children: proceedText,
    })])]]), createElement("a", createObj(Helpers_combineClasses("button", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_18))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_20)));
}

export function ClaimedLotsWithCancel(claimedLotsWithCancelInputProps) {
    let children, value_16;
    const onRemove = claimedLotsWithCancelInputProps.onRemove;
    const removedLot = claimedLotsWithCancelInputProps.removedLot;
    const userId = claimedLotsWithCancelInputProps.userId;
    const auctionId = claimedLotsWithCancelInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowConfirmation = patternInput_1[1];
    const cancelRemoveLotFromOrder = singleton_1.Delay(() => {
        const request = new Orders_CancelRemoveLotFromOrderRequest(auctionId, userId, removedLot.Lot.Id, "");
        return singleton_1.Bind(ordersApi().cancelClaimLot(request), (_arg1) => {
            if (_arg1.tag === 1) {
                return singleton_1.Return(new FSharpResult$2(1, _arg1.fields[0]));
            }
            else {
                const response = _arg1.fields[0];
                onRemove(response);
                return singleton_1.Return(new FSharpResult$2(0, response));
            }
        });
    });
    const cancelRemoveLot = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => cancelRemoveLotFromOrder, patternInput[1]);
    const renderError = (error_1) => {
        let props;
        const props_2 = ofArray([["className", "px-3"], ["className", "mx-0"], ["className", "mb-0"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-text-right"], ["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([error_1])]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("columns", props_2)));
    };
    const patternInput_2 = (data.tag === 1) ? [true, null] : ((data.tag === 3) ? [false, renderError(tWithArgs("errors.fromServer.failedWithArg", {
        error: data.fields[0].message,
    }))] : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? ((data.fields[0].fields[0].tag === 0) ? [false, renderError((children = ofArray([(value_16 = t("errors.fromServer.itemIsReservedForAuction"), createElement("span", {
        children: [value_16],
    })), createElement("a", {
        children: t("auction.backToAuction"),
        href: toPath(new Route(2, new AuctionArg(0, data.fields[0].fields[0].fields[0], new AuctionState(false, false, 1)))),
    })]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })))] : ((data.fields[0].fields[0].tag === 2) ? [false, renderError(tWithArgs("errors.fromServer.failedWithArg", {
        error: data.fields[0].fields[0].fields[0],
    }))] : [false, renderError(t("errors.fromServer.itemIsReservedForPayout"))])) : [false, null]) : [false, null]));
    return react.createElement(react.Fragment, {}, ...toList(delay(() => append(patternInput_1[0] ? singleton_2(confirmation(t("orders.cancelClaimConfirmation"), t("orders.cancelClaim"), t("common.back"), () => {
        setShowConfirmation(false);
        cancelRemoveLot();
    }, () => {
        setShowConfirmation(false);
    })) : empty(), delay(() => {
        let props_39, props_11, props_7, props_9, props_17, props_13, props_15, props_24, props_19, props_22, props_30, props_26, props_28, props_37, props_35;
        return append(singleton_2((props_39 = ofArray([["className", "px-3"], ["className", "mx-0"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.action")]]), createElement("p", createObj(Helpers_combineClasses("", props_7)))), (props_9 = ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["children", t("orders.claimed")]]), createElement("p", createObj(Helpers_combineClasses("", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_11)))), (props_17 = ofArray([["className", "has-text-right"], ["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.actionDate")]]), createElement("p", createObj(Helpers_combineClasses("", props_13)))), (props_15 = ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["className", "has-text-grey-light"], ["children", Localized_formatDateTime("dd-MM-yyyy", toLocalTime(removedLot.RemovedAt))]]), createElement("p", createObj(Helpers_combineClasses("", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_17)))), (props_24 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("common.lotNumber")]]), createElement("p", createObj(Helpers_combineClasses("", props_19)))), (props_22 = ofArray([["className", "pl-0"], ["className", "is-size-5"], ["className", "is-text"], ["className", "has-text-weight-bold"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: join(" ", ["link", "has-text-grey-light"]),
            children: `${removedLot.Lot.LotNumber}`,
            onClick: (_arg1_1) => {
                window.open(toPath(new Route(20, new EditLotArg(0, auctionId, removedLot.Lot.Id, new AuctionState(false, false, 1)))));
            },
        })])]]), createElement("p", createObj(Helpers_combineClasses("", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_24)))), (props_30 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.removedBy")]]), createElement("p", createObj(Helpers_combineClasses("", props_26)))), (props_28 = ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["children", `${removedLot.RemovedBy}`]]), createElement("p", createObj(Helpers_combineClasses("", props_28))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_30)))), (props_37 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_35 = toList(delay(() => append(singleton_2(["className", "has-background-white-bis"]), delay(() => append(patternInput_2[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["onClick", (x) => {
            x.preventDefault();
            setShowConfirmation(true);
        }]), delay(() => {
            let s, elms;
            return singleton_2(["children", Interop_reactApi.Children.toArray([(s = t("orders.remove"), createElement("span", {
                className: "",
                children: s,
            })), (elms = singleton(createElement("i", {
                className: join(" ", ["fa", "fa-times"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]);
        })))))))), createElement("button", createObj(Helpers_combineClasses("button", props_35))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_37))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_39))))), delay(() => singleton_2(patternInput_2[1])));
    })))));
}

export function RefundedLotsWithCancel(refundedLotsWithCancelInputProps) {
    let children_2, value_20, children, value_15;
    const onRemove = refundedLotsWithCancelInputProps.onRemove;
    const removedLot = refundedLotsWithCancelInputProps.removedLot;
    const userId = refundedLotsWithCancelInputProps.userId;
    const auctionId = refundedLotsWithCancelInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowConfirmation = patternInput_1[1];
    const cancelRemoveLotFromOrder = singleton_1.Delay(() => {
        const request = new Orders_CancelRemoveLotFromOrderRequest(auctionId, userId, removedLot.Lot.Id, "");
        return singleton_1.Bind(ordersApi().cancelRefundLot(request), (_arg1) => {
            if (_arg1.tag === 1) {
                return singleton_1.Return(new FSharpResult$2(1, _arg1.fields[0]));
            }
            else {
                const response = _arg1.fields[0];
                onRemove(response);
                return singleton_1.Return(new FSharpResult$2(0, response));
            }
        });
    });
    const cancelRemoveLot = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => cancelRemoveLotFromOrder, patternInput[1]);
    const renderError = (error_1) => {
        let props;
        const props_2 = ofArray([["className", "px-3"], ["className", "mx-0"], ["className", "mb-0"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-text-right"], ["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([error_1])]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("columns", props_2)));
    };
    const patternInput_2 = (data.tag === 1) ? [true, null] : ((data.tag === 3) ? [false, renderError(tWithArgs("errors.fromServer.failedWithArg", {
        error: data.fields[0].message,
    }))] : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? ((data.fields[0].fields[0].tag === 0) ? [false, renderError((children_2 = singleton((value_20 = t("errors.fromServer.itemIsReservedForAuction"), createElement("span", {
        children: [value_20],
    }))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })))] : ((data.fields[0].fields[0].tag === 2) ? [false, renderError(tWithArgs("errors.fromServer.failedWithArg", {
        error: data.fields[0].fields[0].fields[0],
    }))] : [false, renderError((children = ofArray([(value_15 = t("errors.fromServer.itemIsReservedForPayout"), createElement("span", {
        children: [value_15],
    })), createElement("a", {
        children: t("auction.backToAuction"),
        href: toPath(new Route(2, new AuctionArg(0, auctionId, new AuctionState(false, false, 1)))),
    })]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })))])) : [false, null]) : [false, null]));
    return react.createElement(react.Fragment, {}, ...toList(delay(() => append(patternInput_1[0] ? singleton_2(confirmation(t("orders.cancelRefundConfirmation"), t("orders.cancelRefund"), t("common.back"), () => {
        setShowConfirmation(false);
        cancelRemoveLot();
    }, () => {
        setShowConfirmation(false);
    })) : empty(), delay(() => {
        let props_41, props_13, props_9, props_11, props_19, props_15, props_17, props_26, props_21, props_24, props_32, props_28, props_30, props_39, props_37;
        return append(singleton_2((props_41 = ofArray([["className", "px-3"], ["className", "mx-0"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.action")]]), createElement("p", createObj(Helpers_combineClasses("", props_9)))), (props_11 = ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["children", t("orders.itemIsRefunded")]]), createElement("p", createObj(Helpers_combineClasses("", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_19 = ofArray([["className", "has-text-right"], ["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.actionDate")]]), createElement("p", createObj(Helpers_combineClasses("", props_15)))), (props_17 = ofArray([["className", "is-size-5"], ["className", "has-text-weight-bold"], ["className", "has-text-grey-light"], ["children", Localized_formatDateTime("dd-MM-yyyy", toLocalTime(removedLot.RemovedAt))]]), createElement("p", createObj(Helpers_combineClasses("", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_19)))), (props_26 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("common.lotNumber")]]), createElement("p", createObj(Helpers_combineClasses("", props_21)))), (props_24 = ofArray([["className", "pl-0"], ["className", "is-size-5"], ["className", "is-text"], ["className", "has-text-weight-bold"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: join(" ", ["link", "has-text-grey-light"]),
            children: `${removedLot.Lot.LotNumber}`,
            onClick: (_arg1_1) => {
                window.open(toPath(new Route(20, new EditLotArg(0, auctionId, removedLot.Lot.Id, new AuctionState(false, false, 1)))));
            },
        })])]]), createElement("p", createObj(Helpers_combineClasses("", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_26)))), (props_32 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_28 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t("orders.removedBy")]]), createElement("p", createObj(Helpers_combineClasses("", props_28)))), (props_30 = ofArray([["className", "has-text-weight-bold"], ["children", `${removedLot.RemovedBy}`]]), createElement("p", createObj(Helpers_combineClasses("", props_30))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_32)))), (props_39 = ofArray([["className", "is-2"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_37 = toList(delay(() => append(singleton_2(["className", "has-background-white-bis"]), delay(() => append(patternInput_2[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["onClick", (x) => {
            x.preventDefault();
            setShowConfirmation(true);
        }]), delay(() => {
            let s, elms;
            return singleton_2(["children", Interop_reactApi.Children.toArray([(s = t("orders.remove"), createElement("span", {
                className: "",
                children: s,
            })), (elms = singleton(createElement("i", {
                className: join(" ", ["fa", "fa-times"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]);
        })))))))), createElement("button", createObj(Helpers_combineClasses("button", props_37))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_39))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_41))))), delay(() => singleton_2(patternInput_2[1])));
    })))));
}

