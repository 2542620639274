import { Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { auctionApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export class ClickedButton extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Pause", "UnPause"];
    }
}

export function ClickedButton$reflection() {
    return union_type("Components.LiveBiddingPause.ClickedButton", [], ClickedButton, () => [[], []]);
}

export function View_Component() {
    let elms_3, elms_2, props_2, props_14, props_4, props_12, elms_1, elms, props_6, props_8;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const clickedButton = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_1[0];
    const changePauseState_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D((clickedButton_1) => singleton.Delay(() => {
        patternInput[1](clickedButton_1);
        return (clickedButton_1.tag === 1) ? singleton.ReturnFrom(Remoting_handleNonAuth(auctionApi().unPause())) : singleton.ReturnFrom(Remoting_handleNonAuth(auctionApi().pause()));
    }), patternInput_1[1]);
    const elms_4 = singleton_2((elms_3 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Pause live bidding auction",
    }), (elms_2 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Pause live bidding auction",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_14 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => ((data.tag === 1) ? singleton_1(["children", ""]) : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? append(singleton_1(["className", "is-danger"]), delay(() => singleton_1(["children", data.fields[0].fields[0]]))) : append(singleton_1(["className", "is-success"]), delay(() => ((clickedButton != null) ? ((clickedButton.tag === 1) ? singleton_1(["children", "Successfully unpaused auction."]) : singleton_1(["children", "Successfully paused auction."])) : singleton_1(["children", ""]))))) : ((data.tag === 3) ? append(singleton_1(["className", "is-danger"]), delay(() => singleton_1(["children", data.fields[0].message]))) : singleton_1(["children", ""])))))), createElement("p", createObj(Helpers_combineClasses("help", props_4)))), (props_12 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_2((elms = ofArray([(props_6 = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append((Deferred_inProgress(data) ? equals(clickedButton, new ClickedButton(0)) : false) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Pause"]), delay(() => singleton_1(["onClick", (x_1) => {
        x_1.preventDefault();
        changePauseState_1(new ClickedButton(0));
    }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_6)))), (props_8 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append((Deferred_inProgress(data) ? equals(clickedButton, new ClickedButton(1)) : false) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Unpause"]), delay(() => singleton_1(["onClick", (x_2) => {
        x_2.preventDefault();
        changePauseState_1(new ClickedButton(1));
    }]))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_8))))]), createElement("div", {
        className: "buttons",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    });
}

