import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton, append, map, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { createElement } from "react";
import { LangModule_toString } from "../bidflow/src/infrastructure/Localization.js";
import { stringHash, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { List_distinct } from "../fable_modules/fable-library.3.6.1/Seq2.js";
import { getSupportedLanguages, getPrimaryLanguage } from "../Localization.js";
import { ofArray, cons } from "../fable_modules/fable-library.3.6.1/List.js";

export function languageSwitcher(current, onSelect) {
    let children;
    const props_4 = ofArray([["className", "is-uppercase"], ["className", "is-toggle"], ["children", Interop_reactApi.Children.toArray([(children = toList(delay(() => map((lang) => {
        const props_1 = toList(delay(() => append((lang === current) ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: "is-small-padding",
            onClick: (_arg1) => {
                onSelect(lang);
            },
            children: LangModule_toString(lang),
        })])])))));
        return createElement("li", createObj(Helpers_combineClasses("", props_1)));
    }, List_distinct(cons(getPrimaryLanguage(), getSupportedLanguages()), {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })))), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("tabs", props_4)));
}

