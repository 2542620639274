import { singleton } from "../fable-library.3.6.1/AsyncBuilder.js";
import { Result_Map, FSharpResult$2, Result_MapError } from "../fable-library.3.6.1/Choice.js";
import { zipError as zipError_1, zip as zip_1, teeErrorIf as teeErrorIf_1, teeError as teeError_1, teeIf as teeIf_1, tee as tee_1, defaultWith as defaultWith_1, defaultError as defaultError_1, defaultValue as defaultValue_1, withError as withError_1, setError as setError_1, requireHead as requireHead_1, requireNotEmpty as requireNotEmpty_1, requireEmpty as requireEmpty_1, requireEqualTo as requireEqualTo_1, requireEqual as requireEqual_1, requireNone as requireNone_1, requireSome as requireSome_1, requireFalse as requireFalse_1, requireTrue as requireTrue_1, map3 as map3_1, map2 as map2_1, fold } from "./Result.fs.js";
import { Async_zip, Async_map3, Async_map2 } from "./Async.fs.js";
import { catchAsync } from "../fable-library.3.6.1/Async.js";

export function mapError(f, ar) {
    return singleton.Bind(ar, (arg) => singleton.Return(Result_MapError(f, arg)));
}

export function bind(f, ar) {
    return singleton.Delay(() => singleton.Bind(ar, (_arg1) => {
        const result = _arg1;
        return singleton.ReturnFrom((result.tag === 1) ? singleton.Delay(() => singleton.Return(new FSharpResult$2(1, result.fields[0]))) : f(result.fields[0]));
    }));
}

export function foldResult(onSuccess, onError, ar) {
    return singleton.Bind(ar, (arg) => singleton.Return(fold(onSuccess, onError, arg)));
}

export function retn(x) {
    return singleton.Return(new FSharpResult$2(0, x));
}

export function returnError(x) {
    return singleton.Return(new FSharpResult$2(1, x));
}

export function map2(f, xR, yR) {
    return Async_map2((x, y) => map2_1(f, x, y), xR, yR);
}

export function map3(f, xR, yR, zR) {
    return Async_map3((x, y, z) => map3_1(f, x, y, z), xR, yR, zR);
}

export function apply(fAR, xAR) {
    return map2((f, x) => f(x), fAR, xAR);
}

export function ignore(ar) {
    return singleton.Bind(ar, (arg) => singleton.Return(Result_Map((value) => {
    }, arg)));
}

export function requireTrue(error, value) {
    return singleton.Bind(value, (arg) => singleton.Return(requireTrue_1(error, arg)));
}

export function requireFalse(error, value) {
    return singleton.Bind(value, (arg) => singleton.Return(requireFalse_1(error, arg)));
}

export function requireSome(error, option) {
    return singleton.Bind(option, (arg) => singleton.Return(requireSome_1(error, arg)));
}

export function requireNone(error, option) {
    return singleton.Bind(option, (arg) => singleton.Return(requireNone_1(error, arg)));
}

export function requireEqual(x1, x2, error) {
    return singleton.Bind(x2, (arg) => singleton.Return(requireEqual_1(x1, arg, error)));
}

export function requireEqualTo(other, error, this$) {
    return singleton.Bind(this$, (arg) => singleton.Return(requireEqualTo_1(other, error, arg)));
}

export function requireEmpty(error, xs) {
    return singleton.Bind(xs, (arg) => singleton.Return(requireEmpty_1(error, arg)));
}

export function requireNotEmpty(error, xs) {
    return singleton.Bind(xs, (arg) => singleton.Return(requireNotEmpty_1(error, arg)));
}

export function requireHead(error, xs) {
    return singleton.Bind(xs, (arg) => singleton.Return(requireHead_1(error, arg)));
}

export function setError(error, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => singleton.Return(setError_1(error, arg)));
}

export function withError(error, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => singleton.Return(withError_1(error, arg)));
}

export function defaultValue(ifError, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => singleton.Return(defaultValue_1(ifError, arg)));
}

export function defaultError(ifOk, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => singleton.Return(defaultError_1(ifOk, arg)));
}

export function defaultWith(ifErrorThunk, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => singleton.Return(defaultWith_1(ifErrorThunk, arg)));
}

export function ignoreError(result) {
    return defaultValue(void 0, result);
}

export function tee(f, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => singleton.Return(tee_1(f, arg)));
}

export function teeIf(predicate, f, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => singleton.Return(teeIf_1(predicate, f, arg)));
}

export function teeError(f, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => singleton.Return(teeError_1(f, arg)));
}

export function teeErrorIf(predicate, f, asyncResult) {
    return singleton.Bind(asyncResult, (arg) => singleton.Return(teeErrorIf_1(predicate, f, arg)));
}

export function zip(x1, x2) {
    return singleton.Bind(Async_zip(x1, x2), (arg) => {
        let tupledArg;
        return singleton.Return((tupledArg = arg, zip_1(tupledArg[0], tupledArg[1])));
    });
}

export function zipError(x1, x2) {
    return singleton.Bind(Async_zip(x1, x2), (arg) => {
        let tupledArg;
        return singleton.Return((tupledArg = arg, zipError_1(tupledArg[0], tupledArg[1])));
    });
}

export function catch$(f, x) {
    return singleton.Bind(catchAsync(x), (arg) => {
        let _arg1, copyOfStruct;
        return singleton.Return((_arg1 = arg, (_arg1.tag === 1) ? (new FSharpResult$2(1, f(_arg1.fields[0]))) : ((copyOfStruct = _arg1.fields[0], (copyOfStruct.tag === 1) ? (new FSharpResult$2(1, copyOfStruct.fields[0])) : (new FSharpResult$2(0, copyOfStruct.fields[0]))))));
    });
}

export function ofAsync(x) {
    return singleton.Bind(x, (arg) => singleton.Return(new FSharpResult$2(0, arg)));
}

export function ofResult(x) {
    return singleton.Return(x);
}

