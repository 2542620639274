import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { AuctionIdModule_fromString, AuctionIdModule_toString, AuctionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { ordersApi } from "../Communication.js";
import { ByteArrayExtensions_SaveFileAs_451DD142 } from "../fable_modules/Fable.Remoting.Client.7.10.0/Extensions.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton as singleton_2, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export function Component() {
    let elms_4, elms_3, props_2, props_16;
    const patternInput = useFeliz_React__React_useState_Static_1505(AuctionIdModule_empty);
    const auctionId = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const overpaidLots = patternInput_1[0];
    const getOverpaid_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D((auctionId_1) => singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(ordersApi().getOverpaidLots(auctionId_1)), (_arg1) => {
        ByteArrayExtensions_SaveFileAs_451DD142(_arg1, `overpaid-auction-${auctionId_1}.csv`, "text/csv");
        return singleton.Zero();
    })), patternInput_1[1]);
    const elms_5 = singleton_2((elms_4 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Get overpaid lots in auction",
    }), (elms_3 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Get overpaid lots in auction",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_16 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, s_2, elms, props_5, value_24;
        return append(singleton_1((elms_1 = ofArray([(s_2 = (`Auction id = ${auctionId}`), createElement("label", {
            className: "label",
            children: s_2,
        })), (elms = singleton_2((props_5 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_24 = AuctionIdModule_toString(auctionId), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_24)) : false) {
                e.value = value_24;
            }
        }]), ["onChange", (ev) => {
            patternInput[1](AuctionIdModule_fromString(ev.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_5)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let props_9;
            return append((overpaidLots.tag === 1) ? singleton_1(null) : ((overpaidLots.tag === 2) ? singleton_1(null) : ((overpaidLots.tag === 3) ? singleton_1((props_9 = ofArray([["className", "is-danger"], ["children", overpaidLots.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_9))))) : singleton_1(null))), delay(() => {
                let props_14, elms_2, props_11;
                return singleton_1((props_14 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_11 = toList(delay(() => append(Deferred_inProgress(overpaidLots) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Get overpaid"]), delay(() => singleton_1(["onClick", (_arg1_1) => {
                    getOverpaid_1(auctionId);
                }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_11))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_14)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    });
}

