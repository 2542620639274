import { getToken, setItem, tryGetItem } from "./LocalStorage.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { UpdateImages, ImportImages, Image$reflection, UpdateVideoLink, UploadImageCommitInfo, ImageInFolder$reflection } from "./Shared/Shared.js";
import { tuple_type, option_type, enum_type, int32_type, unit_type, union_type, record_type, bool_type, class_type, string_type, list_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { StreamFrom_Action, Action$reflection, StreamFrom_Action$reflection, Response$reflection, StreamFrom_Response$reflection } from "./Shared/Streams.js";
import { filter as filter_1, tryFindIndex, contains, fold, isEmpty, tryLast, sortBy, ofArray, reverse, cons, append, singleton, length, partition, empty } from "./fable_modules/fable-library.3.6.1/List.js";
import { filter, ofList, union, map as map_2, toList as toList_1, add, empty as empty_1 } from "./fable_modules/fable-library.3.6.1/Set.js";
import { stringHash, compare as compare_1, createObj, equals, comparePrimitives } from "./fable_modules/fable-library.3.6.1/Util.js";
import { toArray, some, map, value as value_72, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D, useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505, useReact_useEffectOnce_3A5B6456, React_createDisposable_3A5B6456, useReact_useEffectOnce_Z5ECA432F, useReact_useMemo_CF4EA67, useReact_useRef_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { HubConnectionBuilder$5_$ctor_Z66CB2AA1, HubConnectionBuilder$5__withUrl_Z6B680FF3, HubConnectionBuilder$5__withAutomaticReconnect, HubConnectionBuilder$5__configureLogging_2D37BB17 } from "./fable_modules/Fable.SignalR.0.11.4/SignalR.fs.js";
import { ConnectionBuilder__accessTokenFactory_5106B011 } from "./fable_modules/Fable.SignalR.0.11.4/HttpClient.fs.js";
import { HubConnection$5__stopNow, HubConnection$5__startNow, HubConnection$5_$ctor_3ED56BCC, Bindings_signalR } from "./fable_modules/Fable.SignalR.0.11.4/HubConnection.fs.js";
import { Json_TextMessageFormat_write, Json_TextMessageFormat_parse, HubRecords_CloseMessage$reflection, HubRecords_PingMessage$reflection, HubRecords_CancelInvocationMessage$reflection, HubRecords_StreamInvocationMessage$1$reflection, HubRecords_CompletionMessage$1$reflection, HubRecords_StreamItemMessage$1$reflection, HubRecords_InvocationMessage$1$reflection, Json_JsonProtocol_$ctor, MsgPack_parseMsg, MsgPack_MsgPackProtocol_$ctor } from "./fable_modules/Fable.SignalR.0.11.4/Protocols.fs.js";
import { join, toFail, printf, toText } from "./fable_modules/fable-library.3.6.1/String.js";
import { Reader__Read_24524716, Reader_$ctor_6C95DA22 } from "./fable_modules/Fable.Remoting.MsgPack.1.9.0/Read.fs.js";
import { fromInteger, op_Subtraction, compare, fromBits, op_Addition } from "./fable_modules/fable-library.3.6.1/Long.js";
import { InvokeArg$1$reflection, MsgPack_Msg$4, MsgPack_Msg$4$reflection } from "./fable_modules/Fable.SignalR.0.11.4/Shared.fs.js";
import { writeObject } from "./fable_modules/Fable.Remoting.MsgPack.1.9.0/Write.fs.js";
import { choose, addRangeInPlace } from "./fable_modules/fable-library.3.6.1/Array.js";
import { SimpleJson_readPath, SimpleJson_parse } from "./fable_modules/Fable.SimpleJson.3.20.0/SimpleJson.fs.js";
import { Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B, Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.20.0/Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.20.0/TypeInfo.Converter.fs.js";
import { Result_Map, FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { empty as empty_2, map as map_1, singleton as singleton_2, append as append_1, delay, toList, iterate } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { StreamSubscriber$1 } from "./fable_modules/Fable.SignalR.0.11.4/Types.fs.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Helpers_combineClasses, Helpers_extractClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { createElement } from "react";
import * as react from "react";
import { t } from "./Localization.js";
import { traverseAsyncResultA } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/List.fs.js";
import { mapError } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/AsyncResult.fs.js";
import { AsyncResultCE_asyncResult, AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/AsyncResultCE.fs.js";
import { Browser_Types_File__File_ReadAsByteArray } from "./fable_modules/Fable.Remoting.Client.7.10.0/Extensions.fs.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection, Remoting_handleNonAuth } from "./Extensions.js";
import { itemApi } from "./Communication.js";
import { rangeDouble } from "./fable_modules/fable-library.3.6.1/Range.js";
import { YouTubeLink_validateIf } from "./Validation.js";
import { List_swapToRight, List_swapToLeft } from "./bidflow/src/infrastructure/Extensions.js";
import { RenderImage, RenderBigImages } from "./Components/Image.js";

export function ImportImages_LocalStorage_getSelectedFolder() {
    return tryGetItem("import.image.selected.folder");
}

export function ImportImages_LocalStorage_saveSelectedFolder(folder) {
    setItem("import.image.selected.folder", folder);
}

export class ImportImages_State extends Record {
    constructor(Images, Folders, SelectedFolder, IsImporting, Error$) {
        super();
        this.Images = Images;
        this.Folders = Folders;
        this.SelectedFolder = SelectedFolder;
        this.IsImporting = IsImporting;
        this.Error = Error$;
    }
}

export function ImportImages_State$reflection() {
    return record_type("Client.EditItemImages.ImportImages.State", [], ImportImages_State, () => [["Images", list_type(ImageInFolder$reflection())], ["Folders", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [string_type])], ["SelectedFolder", string_type], ["IsImporting", bool_type], ["Error", string_type]]);
}

export class ImportImages_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FromServer", "FolderSelected", "StartImport", "StopImport"];
    }
}

export function ImportImages_Msg$reflection() {
    return union_type("Client.EditItemImages.ImportImages.Msg", [], ImportImages_Msg, () => [[["Item", StreamFrom_Response$reflection()]], [["Item", string_type]], [], []]);
}

export function ImportImages_init() {
    const selectedFolder = ImportImages_LocalStorage_getSelectedFolder();
    return [new ImportImages_State(empty(), empty_1({
        Compare: (x, y) => comparePrimitives(x, y),
    }), defaultArg(selectedFolder, ""), false, ""), Cmd_none()];
}

export function ImportImages_update(callback, onSelectFolder, msg, state) {
    if (msg.tag === 1) {
        ImportImages_LocalStorage_saveSelectedFolder(msg.fields[0]);
        onSelectFolder(msg.fields[0]);
        return [new ImportImages_State(state.Images, state.Folders, msg.fields[0], state.IsImporting, state.Error), Cmd_none()];
    }
    else if (msg.tag === 2) {
        const patternInput = partition((x_3) => (x_3.Folder === state.SelectedFolder), state.Images);
        const files = patternInput[0];
        if (length(files) > 0) {
            callback(files);
        }
        return [new ImportImages_State(patternInput[1], state.Folders, state.SelectedFolder, true, state.Error), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new ImportImages_State(state.Images, state.Folders, state.SelectedFolder, false, state.Error), Cmd_none()];
    }
    else if (msg.fields[0].tag === 1) {
        return [new ImportImages_State(state.Images, add(msg.fields[0].fields[0], state.Folders), state.SelectedFolder, state.IsImporting, state.Error), Cmd_none()];
    }
    else {
        if (state.SelectedFolder === "") {
            ImportImages_LocalStorage_saveSelectedFolder(msg.fields[0].fields[0].Folder);
        }
        let selectedFolder;
        if (state.SelectedFolder === "") {
            onSelectFolder(msg.fields[0].fields[0].Folder);
            selectedFolder = msg.fields[0].fields[0].Folder;
        }
        else {
            selectedFolder = state.SelectedFolder;
        }
        if (state.IsImporting ? (msg.fields[0].fields[0].Folder === selectedFolder) : false) {
            callback(singleton(msg.fields[0].fields[0]));
            return [state, Cmd_none()];
        }
        else {
            return [new ImportImages_State(append(singleton(msg.fields[0].fields[0]), state.Images), add(msg.fields[0].fields[0].Folder, state.Folders), selectedFolder, state.IsImporting, state.Error), Cmd_none()];
        }
    }
}

export function ImportImages_importImages(importImages_importImagesInputProps) {
    let props_5, patternInput_1, elms, props_7;
    const onSelectFolder = importImages_importImagesInputProps.onSelectFolder;
    const onFilesAdded = importImages_importImagesInputProps.onFilesAdded;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(ImportImages_init, (msg, state) => ImportImages_update(onFilesAdded, onSelectFolder, msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    let hub_2;
    const connection_1 = useReact_useRef_1505(useReact_useMemo_CF4EA67(() => {
        let protocol, protocol_1;
        const _ = HubConnectionBuilder$5__configureLogging_2D37BB17(HubConnectionBuilder$5__withAutomaticReconnect(HubConnectionBuilder$5__withUrl_Z6B680FF3(HubConnectionBuilder$5_$ctor_Z66CB2AA1(new Bindings_signalR.HubConnectionBuilder()), "/SignalR", (builder) => ConnectionBuilder__accessTokenFactory_5106B011(builder, getToken))), 1);
        return HubConnection$5_$ctor_3ED56BCC(_["hub@10"].withHubProtocol(_.useMsgPack ? ((protocol = MsgPack_MsgPackProtocol_$ctor(), {
            name: "messagepack",
            version: 1,
            transferFormat: 2,
            parseMessages(input, logger) {
                return Array.from((() => {
                    let arg10_1;
                    try {
                        const buffer_1 = input;
                        const reader = Reader_$ctor_6C95DA22(new Uint8Array(buffer_1));
                        const read = (pos_mut, xs_mut) => {
                            read:
                            while (true) {
                                const pos = pos_mut, xs = xs_mut;
                                const matchValue = op_Addition(op_Addition(Reader__Read_24524716(reader, class_type("System.UInt64")), pos), fromBits(1, 0, true));
                                if (compare(op_Subtraction(fromInteger(buffer_1.byteLength, true, 2), matchValue), fromBits(0, 0, true)) > 0) {
                                    pos_mut = matchValue;
                                    xs_mut = cons(MsgPack_parseMsg(Reader__Read_24524716(reader, MsgPack_Msg$4$reflection(unit_type, Response$reflection(), Response$reflection(), StreamFrom_Response$reflection()))), xs);
                                    continue read;
                                }
                                else {
                                    return cons(MsgPack_parseMsg(Reader__Read_24524716(reader, MsgPack_Msg$4$reflection(unit_type, Response$reflection(), Response$reflection(), StreamFrom_Response$reflection()))), xs);
                                }
                                break;
                            }
                        };
                        return reverse(read(fromBits(0, 0, true), empty()));
                    }
                    catch (e) {
                        logger.log(4, (arg10_1 = e.message, toText(printf("An error occured during message deserialization: %s"))(arg10_1)));
                        return empty();
                    }
                })());
            },
            writeMessage(msg_3) {
                let matchValue_1, invocation, matchValue_2, invocation_1, arg10_3, streamItem, completion, streamInvocation, cancelInvocation, close;
                const message = msg_3;
                const outArr = [];
                writeObject((matchValue_1 = (message.type | 0), (matchValue_1 === 1) ? ((invocation = message, (matchValue_2 = invocation.target, (matchValue_2 === "Invoke") ? ((invocation.arguments.length === 2) ? (new MsgPack_Msg$4(1, invocation.headers, invocation.invocationId, invocation.target, invocation.arguments[0], invocation.arguments[1], invocation.streamIds)) : ((invocation_1 = message, new MsgPack_Msg$4(2, invocation_1.headers, invocation_1.invocationId, invocation_1.target, invocation_1.arguments, invocation_1.streamIds)))) : ((matchValue_2 === "Send") ? (new MsgPack_Msg$4(0, invocation.headers, invocation.invocationId, invocation.target, invocation.arguments, invocation.streamIds)) : ((matchValue_2 === "StreamTo") ? (new MsgPack_Msg$4(0, invocation.headers, invocation.invocationId, invocation.target, invocation.arguments, invocation.streamIds)) : ((arg10_3 = invocation.target, toFail(printf("Invalid Invocation Target: %s"))(arg10_3)))))))) : ((matchValue_1 === 2) ? ((streamItem = message, new MsgPack_Msg$4(3, streamItem.headers, streamItem.invocationId, streamItem.item))) : ((matchValue_1 === 3) ? ((completion = message, new MsgPack_Msg$4(4, completion.headers, completion.invocationId, completion.error, completion.result))) : ((matchValue_1 === 4) ? ((streamInvocation = message, new MsgPack_Msg$4(5, streamInvocation.headers, streamInvocation.invocationId, streamInvocation.target, streamInvocation.arguments, streamInvocation.streamIds))) : ((matchValue_1 === 5) ? ((cancelInvocation = message, new MsgPack_Msg$4(6, cancelInvocation.headers, cancelInvocation.invocationId))) : ((matchValue_1 === 6) ? (new MsgPack_Msg$4(7)) : ((matchValue_1 === 7) ? ((close = message, new MsgPack_Msg$4(8, close.error, close.allowReconnect))) : toFail(printf("Invalid message: %A"))(message)))))))), MsgPack_Msg$4$reflection(StreamFrom_Action$reflection(), Action$reflection(), unit_type, unit_type), outArr);
                if (compare(fromInteger(outArr.length, true, 2), fromBits(2147483648, 0, true)) > 0) {
                    throw (new Error("Messages over 2GB are not supported."));
                }
                else {
                    const msgArr = [];
                    writeObject(fromInteger(outArr.length, true, 2), class_type("System.UInt64"), msgArr);
                    addRangeInPlace(outArr, msgArr);
                    return (new Uint8Array(msgArr)).buffer;
                }
            },
        })) : ((protocol_1 = Json_JsonProtocol_$ctor(), {
            name: "json",
            version: 1,
            transferFormat: 1,
            parseMessages(input_1, logger_2) {
                const input_2 = input_1;
                const logger_3 = logger_2;
                return Array.from(((typeof input_2) === "string") ? (equals(input_2, "") ? [] : (() => {
                    let arg10_9;
                    try {
                        return choose((m) => {
                            let msg_5;
                            const parsedRaw = SimpleJson_parse(m);
                            let _arg2;
                            const parsedRaw_1 = parsedRaw;
                            const msgType_1 = value_72(map((arg00_4) => Convert_fromJson(arg00_4, createTypeInfo(enum_type("Fable.SignalR.Messages.MessageType", int32_type, [["Invocation", 1], ["StreamItem", 2], ["Completion", 3], ["StreamInvocation", 4], ["CancelInvocation", 5], ["Ping", 6], ["Close", 7]]))), SimpleJson_readPath(singleton("type"), parsedRaw))) | 0;
                            switch (msgType_1) {
                                case 1: {
                                    let _arg1;
                                    try {
                                        _arg1 = (new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_InvocationMessage$1$reflection(Response$reflection())))));
                                    }
                                    catch (ex) {
                                        _arg1 = (new FSharpResult$2(1, ex.message));
                                    }
                                    _arg2 = ((_arg1.tag === 1) ? Result_Map((arg_1) => {
                                        let msg_7;
                                        return (msg_7 = arg_1, ((msg_7.target === "") ? (() => {
                                            throw (new Error("Invalid payload for Invocation message."));
                                        })() : (void 0), ((msg_7.invocationId != null) ? ((value_72(msg_7.invocationId) === "") ? (() => {
                                            throw (new Error("Invalid payload for Invocation message."));
                                        })() : (void 0)) : (void 0), msg_7)));
                                    }, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_InvocationMessage$1$reflection(InvokeArg$1$reflection(Response$reflection())))));
                                        }
                                        catch (ex_1) {
                                            return new FSharpResult$2(1, ex_1.message);
                                        }
                                    })()) : (new FSharpResult$2(0, (msg_5 = _arg1.fields[0], ((msg_5.target === "") ? (() => {
                                        throw (new Error("Invalid payload for Invocation message."));
                                    })() : (void 0), ((msg_5.invocationId != null) ? ((value_72(msg_5.invocationId) === "") ? (() => {
                                        throw (new Error("Invalid payload for Invocation message."));
                                    })() : (void 0)) : (void 0), msg_5))))));
                                    break;
                                }
                                case 2: {
                                    _arg2 = Result_Map((arg_3) => {
                                        let msg_9, matchValue_3, invocationId_1;
                                        return (msg_9 = arg_3, (matchValue_3 = msg_9.invocationId, (matchValue_3 != null) ? ((matchValue_3 !== "") ? ((invocationId_1 = matchValue_3, msg_9)) : (() => {
                                            throw (new Error("Invalid payload for StreamItem message."));
                                        })()) : (() => {
                                            throw (new Error("Invalid payload for StreamItem message."));
                                        })()));
                                    }, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_StreamItemMessage$1$reflection(StreamFrom_Response$reflection()))));
                                        }
                                        catch (ex_2) {
                                            return new FSharpResult$2(1, ex_2.message);
                                        }
                                    })());
                                    break;
                                }
                                case 3: {
                                    _arg2 = Result_Map((arg_5) => {
                                        let msg_11, fail, matchValue_4, err;
                                        return (msg_11 = arg_5, (fail = (() => {
                                            throw (new Error("Invalid payload for Completion message."));
                                        }), ((matchValue_4 = [msg_11.result, msg_11.error], (matchValue_4[0] == null) ? ((matchValue_4[1] != null) ? ((err = matchValue_4[1], (err === "") ? fail() : (void 0))) : ((msg_11.invocationId === "") ? fail() : (void 0))) : ((matchValue_4[1] != null) ? fail() : ((msg_11.invocationId === "") ? fail() : (void 0)))), msg_11)));
                                    }, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_CompletionMessage$1$reflection(Response$reflection()))));
                                        }
                                        catch (ex_3) {
                                            return new FSharpResult$2(1, ex_3.message);
                                        }
                                    })());
                                    break;
                                }
                                case 4: {
                                    _arg2 = Result_Map((arg_6) => arg_6, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_StreamInvocationMessage$1$reflection(unit_type))));
                                        }
                                        catch (ex_4) {
                                            return new FSharpResult$2(1, ex_4.message);
                                        }
                                    })());
                                    break;
                                }
                                case 5: {
                                    _arg2 = Result_Map((arg_7) => arg_7, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_CancelInvocationMessage$reflection())));
                                        }
                                        catch (ex_5) {
                                            return new FSharpResult$2(1, ex_5.message);
                                        }
                                    })());
                                    break;
                                }
                                case 6: {
                                    _arg2 = Result_Map((arg_8) => arg_8, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_PingMessage$reflection())));
                                        }
                                        catch (ex_6) {
                                            return new FSharpResult$2(1, ex_6.message);
                                        }
                                    })());
                                    break;
                                }
                                case 7: {
                                    _arg2 = Result_Map((arg_9) => arg_9, (() => {
                                        try {
                                            return new FSharpResult$2(0, Convert_fromJson(parsedRaw_1, createTypeInfo(HubRecords_CloseMessage$reflection())));
                                        }
                                        catch (ex_7) {
                                            return new FSharpResult$2(1, ex_7.message);
                                        }
                                    })());
                                    break;
                                }
                                default: {
                                    _arg2 = toFail(printf("Invalid message: %A"))(parsedRaw_1);
                                }
                            }
                            if (_arg2.tag === 1) {
                                logger_3.log(4, toText(printf("Unknown message type: %s"))(_arg2.fields[0]));
                                return void 0;
                            }
                            else {
                                return some(_arg2.fields[0]);
                            }
                        }, Json_TextMessageFormat_parse(input_2));
                    }
                    catch (e_2) {
                        logger_3.log(4, (arg10_9 = e_2.message, toText(printf("An error occured during message deserialization: %s"))(arg10_9)));
                        return [];
                    }
                })()) : ((logger_3.log(4, "Invalid input for JSON hub protocol. Expected a string, got an array buffer instead."), [])));
            },
            writeMessage(msg_13) {
                return Json_TextMessageFormat_write(Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(msg_13));
            },
        }))).build(), _.handlers);
    }, void 0));
    useReact_useEffectOnce_Z5ECA432F(() => {
        let objectArg;
        HubConnection$5__startNow(connection_1.current);
        return React_createDisposable_3A5B6456((objectArg = connection_1.current, () => {
            HubConnection$5__stopNow(objectArg);
        }));
    });
    hub_2 = useReact_useRef_1505(connection_1.current);
    const subscription = useReact_useRef_1505(void 0);
    useReact_useEffectOnce_Z5ECA432F(() => React_createDisposable_3A5B6456(() => {
        iterate((sub) => {
            sub.Dispose();
        }, toArray(subscription.current));
    }));
    const subscriber = new StreamSubscriber$1((response) => {
        dispatch(new ImportImages_Msg(0, response));
    }, (err_1) => {
        console.error(some(err_1));
    }, () => {
        console.debug("Import images subscription is completed.");
    });
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton_1.Delay(() => singleton_1.Bind(hub_2.current.streamFrom(new StreamFrom_Action(0)), (_arg1_1) => {
            let sub_1;
            subscription.current = ((sub_1 = (_arg1_1.subscribe(subscriber)), {
                Dispose() {
                    sub_1.dispose();
                },
            }));
            return singleton_1.Zero();
        })));
    });
    const props_10 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(patternInput_1 = Helpers_extractClasses(toList(delay(() => append_1(singleton_2(["className", "is-fullwidth"]), delay(() => append_1(singleton_2(["className", "has-text-grey"]), delay(() => append_1(singleton_2(["onChange", (ev) => {
        dispatch(new ImportImages_Msg(1, ev.target.value));
    }]), delay(() => append_1(singleton_2(["value", state_1.SelectedFolder]), delay(() => append_1(singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(map_1((arg00_11) => createElement("option", {
        children: [arg00_11],
    }), state_1.Folders)))]), delay(() => (state_1.IsImporting ? append_1(singleton_2(["disabled", true]), delay(() => append_1(singleton_2(["className", "is-loading"]), delay(() => singleton_2(["style", {
        borderStyle: "none",
    }]))))) : empty_2())))))))))))))), createElement("div", {
        className: join(" ", cons("select", patternInput_1[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_5)))), (elms = singleton((props_7 = toList(delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => (state_1.IsImporting ? append_1(singleton_2(["children", t("common.cancel")]), delay(() => singleton_2(["onClick", (_arg1_2) => {
        dispatch(new ImportImages_Msg(3));
    }]))) : append_1(singleton_2(["children", t("auction.activateCamera")]), delay(() => singleton_2(["onClick", (_arg2_1) => {
        dispatch(new ImportImages_Msg(2));
    }])))))))), createElement("a", createObj(Helpers_combineClasses("button", props_7))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_10)));
}

export function UploadImage(uploadImageInputProps) {
    const onUploaded = uploadImageInputProps.onUploaded;
    const folder = uploadImageInputProps.folder;
    const itemId = uploadImageInputProps.itemId;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setError = patternInput[1];
    const error = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setInProgress = patternInput_1[1];
    const inProgress = patternInput_1[0];
    const uploadFileCallback = useReact_useCallback_93353E((arg_2) => {
        startImmediate(singleton_1.Delay(() => {
            setError("");
            setInProgress(true);
            return singleton_1.Bind(traverseAsyncResultA((file) => mapError((_arg1_3) => t("errors.fromServer.itemDoesNotExist"), AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD(AsyncResultCE_asyncResult, () => {
                const asyncResult_2 = singleton_1.Bind(Browser_Types_File__File_ReadAsByteArray(file), (arg) => singleton_1.Return(new FSharpResult$2(0, arg)));
                return singleton_1.Delay(() => singleton_1.Bind(asyncResult_2, (_arg1_2) => {
                    let asyncResult_1;
                    const result_2 = _arg1_2;
                    return (result_2.tag === 1) ? singleton_1.Return(new FSharpResult$2(1, result_2.fields[0])) : singleton_1.ReturnFrom((asyncResult_1 = singleton_1.Bind(Remoting_handleNonAuth(itemApi().uploadImage(result_2.fields[0])), (arg_1) => singleton_1.Return(new FSharpResult$2(0, arg_1))), singleton_1.Delay(() => singleton_1.Bind(asyncResult_1, (_arg1_1) => {
                        let args;
                        const result_1 = _arg1_1;
                        return (result_1.tag === 1) ? singleton_1.Return(new FSharpResult$2(1, result_1.fields[0])) : singleton_1.ReturnFrom((args = (new UploadImageCommitInfo(itemId, result_1.fields[0], file.name, folder)), Remoting_handleNonAuth(itemApi().uploadImageCommit(args))));
                    }))));
                }));
            })), sortBy((x_12) => x_12.name, arg_2, {
                Compare: (x_13, y) => comparePrimitives(x_13, y),
            })), (_arg3) => {
                const result_3 = _arg3;
                return singleton_1.Combine((result_3.tag === 1) ? ((setError(join(" ", result_3.fields[0])), singleton_1.Zero())) : ((onUploaded(defaultArg(tryLast(result_3.fields[0]), empty())), singleton_1.Zero())), singleton_1.Delay(() => {
                    setInProgress(false);
                    return singleton_1.Zero();
                }));
            });
        }));
    });
    const props_8 = ofArray([["className", "is-fullwidth"], ["className", "is-primary"], ["className", "has-text-centered"], ["disabled", inProgress], ["style", {
        borderStyle: "solid",
        minHeight: 70 + "px",
    }], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["multiple", true], ["tabIndex", -1], ["disabled", inProgress], ["onChange", (ev) => {
        const fileList = ev.target.files;
        if (!(fileList == null)) {
            uploadFileCallback(toList(delay(() => map_1((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1)))));
        }
    }]]))))), createElement("span", {
        className: join(" ", ["icon-text", "is-text-overflow-hidden"]),
        style: {
            alignItems: "center",
            padding: 5,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_3;
            return append_1(singleton_2((props_3 = singleton(["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (inProgress ? singleton_2(["className", join(" ", ["fas", "fa-spinner", "fa-spin"])]) : singleton_2(["className", join(" ", ["fa", "fa-plus"])]))))))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))), delay(() => {
                let value_33;
                return append_1(singleton_2((value_33 = (inProgress ? "" : t("common.newImage")), createElement("span", {
                    children: [value_33],
                }))), delay(() => ((error !== "") ? singleton_2(createElement("span", {
                    children: [error],
                })) : empty_2())));
            }));
        })))),
    })])]]);
    return createElement("span", createObj(Helpers_combineClasses("button", props_8)));
}

export class VideoLink_State extends Record {
    constructor(ItemId, Link, FormErrors, NeedToValidate, SaveLinkOperation) {
        super();
        this.ItemId = ItemId;
        this.Link = Link;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveLinkOperation = SaveLinkOperation;
    }
}

export function VideoLink_State$reflection() {
    return record_type("Client.EditItemImages.VideoLink.State", [], VideoLink_State, () => [["ItemId", class_type("System.Int64")], ["Link", option_type(string_type)], ["FormErrors", list_type(string_type)], ["NeedToValidate", bool_type], ["SaveLinkOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class VideoLink_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SaveLink", "LinkChanged"];
    }
}

export function VideoLink_Msg$reflection() {
    return union_type("Client.EditItemImages.VideoLink.Msg", [], VideoLink_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", option_type(string_type)]]]);
}

export function VideoLink_saveVideoLink(itemId, link) {
    return singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => singleton_1.Bind(Remoting_handleNonAuth(itemApi().updateVideoLink(new UpdateVideoLink(itemId, link))), (_arg1) => ((_arg1.tag === 1) ? singleton_1.Return(new VideoLink_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Item doesn\u0027t exist.")))) : singleton_1.Return(new VideoLink_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton_1.Return(new VideoLink_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function VideoLink_init(itemId, link) {
    return [new VideoLink_State(itemId, link, empty(), false, new Deferred$1(0)), Cmd_none()];
}

export function VideoLink_update(msg, state) {
    if (msg.tag === 0) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new VideoLink_State(state.ItemId, state.Link, state.FormErrors, state.NeedToValidate, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new VideoLink_State(state.ItemId, state.Link, state.FormErrors, state.NeedToValidate, new Deferred$1(2, new FSharpResult$2(0, void 0))), Cmd_none()];
            }
        }
        else {
            const matchValue = YouTubeLink_validateIf(true, state.Link);
            if (isEmpty(matchValue[1])) {
                return [new VideoLink_State(state.ItemId, state.Link, empty(), true, new Deferred$1(1)), Cmd_fromAsync(VideoLink_saveVideoLink(state.ItemId, state.Link))];
            }
            else {
                return [new VideoLink_State(state.ItemId, state.Link, matchValue[1], true, new Deferred$1(0)), Cmd_none()];
            }
        }
    }
    else {
        const patternInput = YouTubeLink_validateIf(state.NeedToValidate, msg.fields[0]);
        return [new VideoLink_State(state.ItemId, patternInput[0], patternInput[1], state.NeedToValidate, state.SaveLinkOperation), Cmd_none()];
    }
}

export function VideoLink_renderVideoLinkComponent(videoLink_renderVideoLinkComponentInputProps) {
    let s, props_9, elms_1, props_3, props_1, value_11, elms, props_5, props_11;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(VideoLink_init(videoLink_renderVideoLinkComponentInputProps.itemId, videoLink_renderVideoLinkComponentInputProps.link), (msg, state) => VideoLink_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return react.createElement(react.Fragment, {}, (s = t("common.youTubeLink"), createElement("label", {
        className: "label",
        children: s,
    })), (props_9 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_3 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["placeholder", "https://youtu.be/video"], (value_11 = ((state_1.Link != null) ? value_72(state_1.Link) : ""), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_11)) : false) {
            e.value = value_11;
        }
    }]), ["onChange", (ev) => {
        const x = ev.target.value;
        if (x === "") {
            dispatch(new VideoLink_Msg(1, void 0));
        }
        else {
            dispatch(new VideoLink_Msg(1, x));
        }
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_1)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_3)))), (elms = singleton((props_5 = toList(delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(equals(state_1.SaveLinkOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => append_1(singleton_2(["children", t("common.save")]), delay(() => singleton_2(["onClick", (x_1) => {
        x_1.preventDefault();
        dispatch(new VideoLink_Msg(0, new AsyncOperationStatus$1(0)));
    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_5))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_9)))), (props_11 = ofArray([["className", "is-danger"], ["children", fold((s_1, x_2) => (`${s_1} ${x_2}`), "", state_1.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_11)))));
}

export class State extends Record {
    constructor(ItemId, Images, OriginalImages, SelectedFolder, IsEditMode, FilesToImport, ImportErrors, UpdateImagesOperation) {
        super();
        this.ItemId = ItemId;
        this.Images = Images;
        this.OriginalImages = OriginalImages;
        this.SelectedFolder = SelectedFolder;
        this.IsEditMode = IsEditMode;
        this.FilesToImport = FilesToImport;
        this.ImportErrors = ImportErrors;
        this.UpdateImagesOperation = UpdateImagesOperation;
    }
}

export function State$reflection() {
    return record_type("Client.EditItemImages.State", [], State, () => [["ItemId", class_type("System.Int64")], ["Images", list_type(Image$reflection())], ["OriginalImages", list_type(Image$reflection())], ["SelectedFolder", string_type], ["IsEditMode", bool_type], ["FilesToImport", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [ImageInFolder$reflection()])], ["ImportErrors", list_type(string_type)], ["UpdateImagesOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Image$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Image$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ImportImages", "UpdateImages", "ImportImageRequested", "ImageUpdated", "MoveImageLeft", "MoveImageRight", "DeleteImage", "OnSelectFolder", "SwitchToEditMode", "CancelEdit"];
    }
}

export function Msg$reflection() {
    return union_type("Client.EditItemImages.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(string_type), list_type(Image$reflection())), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(string_type), list_type(Image$reflection()))]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Image$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Image$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", list_type(ImageInFolder$reflection())]], [["Item", list_type(Image$reflection())]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], []]);
}

export function importImages(state) {
    return singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => {
        const imageIds = toList_1(map_2((x) => x.Image.Id, state.FilesToImport, {
            Compare: (x_1, y) => comparePrimitives(x_1, y),
        }));
        const args = new ImportImages(state.ItemId, imageIds);
        return singleton_1.Bind(itemApi().importImages(args), (_arg1) => ((_arg1.tag === 1) ? singleton_1.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.importError"))))) : singleton_1.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, [imageIds, _arg1.fields[0]]))))));
    }), (_arg2) => singleton_1.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function updateImages(state) {
    return singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => {
        const args = new UpdateImages(state.ItemId, state.Images);
        return singleton_1.Bind(itemApi().updateImages(args), (_arg1) => ((_arg1.tag === 1) ? singleton_1.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.itemDoesNotExist"))))) : singleton_1.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.fields[0]))))));
    }), (_arg2) => singleton_1.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(itemId, images, unitVar2) {
    return [new State(itemId, images, images, defaultArg(ImportImages_LocalStorage_getSelectedFolder(), ""), false, empty_1({
        Compare: (x, y) => compare_1(x, y),
    }), empty(), new Deferred$1(0)), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 9) {
        return [new State(state.ItemId, state.OriginalImages, state.OriginalImages, state.SelectedFolder, false, state.FilesToImport, state.ImportErrors, state.UpdateImagesOperation), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new State(state.ItemId, state.Images, state.OriginalImages, msg.fields[0], state.IsEditMode, state.FilesToImport, state.ImportErrors, state.UpdateImagesOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        const newState = new State(state.ItemId, state.Images, state.OriginalImages, state.SelectedFolder, state.IsEditMode, union(ofList(msg.fields[0], {
            Compare: (x_1, y) => compare_1(x_1, y),
        }), state.FilesToImport), state.ImportErrors, state.UpdateImagesOperation);
        return [newState, Cmd_fromAsync(importImages(newState))];
    }
    else if (msg.tag === 3) {
        return [new State(state.ItemId, msg.fields[0], msg.fields[0], state.SelectedFolder, state.IsEditMode, state.FilesToImport, state.ImportErrors, state.UpdateImagesOperation), Cmd_none()];
    }
    else if (msg.tag === 0) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.ItemId, state.Images, state.OriginalImages, state.SelectedFolder, state.IsEditMode, state.FilesToImport, append(singleton(msg.fields[0].fields[0].fields[0]), state.ImportErrors), state.UpdateImagesOperation), Cmd_none()];
            }
            else {
                return [new State(state.ItemId, msg.fields[0].fields[0].fields[0][1], msg.fields[0].fields[0].fields[0][1], state.SelectedFolder, state.IsEditMode, filter((x_2) => (!contains(x_2.Image.Id, msg.fields[0].fields[0].fields[0][0], {
                    Equals: (x_3, y_1) => (x_3 === y_1),
                    GetHashCode: (x_3) => stringHash(x_3),
                })), state.FilesToImport), state.ImportErrors, state.UpdateImagesOperation), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 4) {
        return [(!state.IsEditMode) ? state : (new State(state.ItemId, defaultArg(map((x_5) => List_swapToLeft(x_5)(state.Images), tryFindIndex((x_4) => (x_4.Id === msg.fields[0]), state.Images)), state.Images), state.OriginalImages, state.SelectedFolder, state.IsEditMode, state.FilesToImport, state.ImportErrors, state.UpdateImagesOperation)), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [(!state.IsEditMode) ? state : (new State(state.ItemId, defaultArg(map((x_7) => List_swapToRight(x_7)(state.Images), tryFindIndex((x_6) => (x_6.Id === msg.fields[0]), state.Images)), state.Images), state.OriginalImages, state.SelectedFolder, state.IsEditMode, state.FilesToImport, state.ImportErrors, state.UpdateImagesOperation)), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [(!state.IsEditMode) ? state : (new State(state.ItemId, filter_1((x_8) => (x_8.Id !== msg.fields[0]), state.Images), state.OriginalImages, state.SelectedFolder, state.IsEditMode, state.FilesToImport, state.ImportErrors, state.UpdateImagesOperation)), Cmd_none()];
    }
    else if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.ItemId, state.Images, state.OriginalImages, state.SelectedFolder, state.IsEditMode, state.FilesToImport, state.ImportErrors, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new State(state.ItemId, msg.fields[0].fields[0].fields[0], msg.fields[0].fields[0].fields[0], state.SelectedFolder, false, state.FilesToImport, state.ImportErrors, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
        }
        else {
            return [new State(state.ItemId, state.Images, state.OriginalImages, state.SelectedFolder, state.IsEditMode, state.FilesToImport, state.ImportErrors, new Deferred$1(1)), Cmd_fromAsync(updateImages(state))];
        }
    }
    else {
        return [new State(state.ItemId, state.OriginalImages, state.OriginalImages, state.SelectedFolder, true, state.FilesToImport, state.ImportErrors, state.UpdateImagesOperation), Cmd_none()];
    }
}

export function EditImages(editImagesInputProps) {
    let props_32, props_19;
    const videoLink = editImagesInputProps.videoLink;
    const images = editImagesInputProps.images;
    const itemId = editImagesInputProps.itemId;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => init(itemId, images, void 0), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return react.createElement(react.Fragment, {}, createElement(RenderBigImages, {
        images: state_1.Images,
    }), (props_32 = ofArray([["style", {
        padding: 15,
    }], ["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(map_1((image) => {
        const props_15 = ofArray([["className", "is-one-third"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(createElement(RenderImage, image)), delay(() => {
            let props_12, props_2, props, props_6, props_4, props_10, props_8;
            return state_1.IsEditMode ? singleton_2((props_12 = ofArray([["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
                cursor: "pointer",
            }], ["className", "has-text-primary"], ["className", join(" ", ["fa", "fa-arrow-left"])], ["onClick", (_arg1) => {
                dispatch(new Msg(4, image.Id));
            }]]), createElement("span", createObj(Helpers_combineClasses("icon", props))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_6 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["style", {
                cursor: "pointer",
            }], ["className", "has-text-primary"], ["className", join(" ", ["fa", "fa-arrow-right"])], ["onClick", (_arg2) => {
                dispatch(new Msg(5, image.Id));
            }]]), createElement("span", createObj(Helpers_combineClasses("icon", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_10 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["style", {
                cursor: "pointer",
            }], ["className", "has-text-danger"], ["className", join(" ", ["far", "fa-trash-alt"])], ["onClick", (_arg3) => {
                dispatch(new Msg(6, image.Id));
            }]]), createElement("span", createObj(Helpers_combineClasses("icon", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_12))))) : singleton_2(createElement("div", {
                style: {
                    height: 24,
                },
            }));
        }))))))]]);
        return createElement("div", createObj(Helpers_combineClasses("column", props_15)));
    }, state_1.Images), delay(() => {
        let props_17;
        return singleton_2((props_17 = ofArray([["className", "is-one-third"], ["children", Interop_reactApi.Children.toArray([createElement(UploadImage, {
            itemId: itemId,
            folder: state_1.SelectedFolder,
            onUploaded: (arg) => {
                dispatch(new Msg(3, arg));
            },
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_17)))));
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_19)))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(createElement(ImportImages_importImages, {
            onFilesAdded: (arg_1) => {
                dispatch(new Msg(2, arg_1));
            },
            onSelectFolder: (arg_2) => {
                dispatch(new Msg(7, arg_2));
            },
        })), delay(() => {
            let props_25, props_21, props_23, props_29, props_27;
            return append_1(state_1.IsEditMode ? singleton_2((props_25 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["onClick", (_arg4) => {
                dispatch(new Msg(9));
            }]]), createElement("button", createObj(Helpers_combineClasses("button", props_21)))), (props_23 = toList(delay(() => append_1(singleton_2(["children", t("common.save")]), delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(singleton_2(["disabled", equals(state_1.Images, state_1.OriginalImages)]), delay(() => append_1(equals(state_1.UpdateImagesOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => singleton_2(["onClick", (_arg5) => {
                dispatch(new Msg(1, new AsyncOperationStatus$1(0)));
            }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_25))))) : singleton_2((props_29 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_27 = ofArray([["children", t("common.edit")], ["className", "has-background-grey-lighter"], ["onClick", (_arg6) => {
                dispatch(new Msg(8));
            }]]), createElement("button", createObj(Helpers_combineClasses("button", props_27))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_29))))), delay(() => singleton_2(createElement(VideoLink_renderVideoLinkComponent, {
                itemId: state_1.ItemId,
                link: videoLink,
            }))));
        })))))),
    })])]]), createElement("section", createObj(Helpers_combineClasses("section", props_32)))));
}

