import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { OrderIdModule_fromString, OrderIdModule_toString, OrderIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Orders_CancelOrderDelivery_Response, Orders_CancelOrderDelivery_Request } from "../Shared/Shared.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { ordersApi } from "../Communication.js";
import { Result_Map } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton as singleton_2, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export function Component() {
    let elms_4, elms_3, props_3, props_22;
    const patternInput = useFeliz_React__React_useState_Static_1505(OrderIdModule_empty);
    const orderId = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_1[0];
    const resetDelivery = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => {
        const request = new Orders_CancelOrderDelivery_Request(orderId);
        return singleton.Bind(Remoting_handleNonAuth(ordersApi().cancelDelivery(request)), (_arg1) => singleton.Return(Result_Map((res_1) => (new Orders_CancelOrderDelivery_Response(res_1.AuctionId, res_1.UserId)), _arg1)));
    }), patternInput_1[1]);
    const elms_5 = singleton_2((elms_4 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Reset order delivery",
    }), (elms_3 = ofArray([(props_3 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Cancel order delivery",
    }), createElement("span", {
        children: ["Note: this will apply for the orders in status \u0027Created\u0027. "],
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_22 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, s_2, elms, props_6, value_25;
        return append(singleton_1((elms_1 = ofArray([(s_2 = (`Order id = ${orderId}`), createElement("label", {
            className: "label",
            children: s_2,
        })), (elms = singleton_2((props_6 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_25 = OrderIdModule_toString(orderId), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_25)) : false) {
                e.value = value_25;
            }
        }]), ["onChange", (ev) => {
            patternInput[1](OrderIdModule_fromString(ev.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_6)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let props_11, props_15;
            return append((data.tag === 1) ? singleton_1(null) : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", data.fields[0].fields[0]]]))))) : singleton_1((props_11 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", {
                children: ["Successfully reset order delivery."],
            })])]), createElement("div", createObj(Helpers_combineClasses("notification", props_11)))))) : ((data.tag === 3) ? singleton_1((props_15 = ofArray([["className", "is-danger"], ["children", data.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_15))))) : singleton_1(null))), delay(() => {
                let props_20, elms_2, props_17;
                return singleton_1((props_20 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_17 = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(data) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Reset delivery"]), delay(() => singleton_1(["onClick", (_arg1_1) => {
                    resetDelivery();
                }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_17))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_20)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_22))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    });
}

