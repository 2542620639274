import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { bool_type, option_type, union_type, list_type, class_type, tuple_type, record_type, int32_type, lambda_type, unit_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { cons, isEmpty, indexed, length, singleton as singleton_1, ofArray, tryItem, empty } from "../fable_modules/fable-library.3.6.1/List.js";
import { tWithArgs, t } from "../Localization.js";
import { Cmd_fromAsync, Cmd_fromAsyncCsp, KeyValuePair_key, AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { toArray, map, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { empty as empty_1, append, singleton as singleton_2, collect, delay, toList, iterate } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { sleep, cancel } from "../fable_modules/fable-library.3.6.1/Async.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";

export class TextTranslations extends Record {
    constructor(Placeholder, StartTypeToSearch, NoItemsFound, FoundItems) {
        super();
        this.Placeholder = Placeholder;
        this.StartTypeToSearch = StartTypeToSearch;
        this.NoItemsFound = NoItemsFound;
        this.FoundItems = FoundItems;
    }
}

export function TextTranslations$reflection() {
    return record_type("Components.Autocomplete.TextTranslations", [], TextTranslations, () => [["Placeholder", string_type], ["StartTypeToSearch", lambda_type(unit_type, string_type)], ["NoItemsFound", lambda_type(unit_type, string_type)], ["FoundItems", lambda_type(int32_type, lambda_type(int32_type, string_type))]]);
}

export class FoundResult extends Record {
    constructor(Term, Total, Items) {
        super();
        this.Term = Term;
        this.Total = (Total | 0);
        this.Items = Items;
    }
}

export function FoundResult$reflection() {
    return record_type("Components.Autocomplete.FoundResult", [], FoundResult, () => [["Term", string_type], ["Total", int32_type], ["Items", list_type(class_type("System.Collections.Generic.KeyValuePair`2", [tuple_type(string_type, string_type, string_type), string_type]))]]);
}

export class Props extends Record {
    constructor(DebounceTimeout, Search, OnSelect, OnChange, OnBlur, SearchText, IsAutoFocus, InputRef, Localization, StartSearchOnInit, MinSearchCharCount, IsShowWhenNoResult) {
        super();
        this.DebounceTimeout = (DebounceTimeout | 0);
        this.Search = Search;
        this.OnSelect = OnSelect;
        this.OnChange = OnChange;
        this.OnBlur = OnBlur;
        this.SearchText = SearchText;
        this.IsAutoFocus = IsAutoFocus;
        this.InputRef = InputRef;
        this.Localization = Localization;
        this.StartSearchOnInit = StartSearchOnInit;
        this.MinSearchCharCount = (MinSearchCharCount | 0);
        this.IsShowWhenNoResult = IsShowWhenNoResult;
    }
}

export function Props$reflection() {
    return record_type("Components.Autocomplete.Props", [], Props, () => [["DebounceTimeout", int32_type], ["Search", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [FoundResult$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", FoundResult$reflection()]], [["ErrorValue", string_type]]])]))], ["OnSelect", lambda_type(option_type(tuple_type(string_type, string_type, string_type)), unit_type)], ["OnChange", lambda_type(string_type, unit_type)], ["OnBlur", bool_type], ["SearchText", string_type], ["IsAutoFocus", bool_type], ["InputRef", lambda_type(class_type("Browser.Types.Element"), unit_type)], ["Localization", TextTranslations$reflection()], ["StartSearchOnInit", bool_type], ["MinSearchCharCount", int32_type], ["IsShowWhenNoResult", bool_type]]);
}

export function Props_get_Default() {
    return new Props(500, (_arg1) => singleton.Delay(() => singleton.Return(new FSharpResult$2(0, new FoundResult("", 0, empty())))), (_arg2) => {
    }, (_arg3) => {
    }, true, "", false, (_arg4) => {
    }, new TextTranslations(t("auction.searchSubmission"), () => t("auction.startTypeToSearch"), () => t("auction.noSubmissionsFound"), (found, total) => tWithArgs("auction.shownXOutOfY", {
        found: found,
        total: total,
    })), true, 0, true);
}

export class Model extends Record {
    constructor(SearchResult, SearchString, DebounceHandler, SelectedIndex, Value, IsActive, MinCharCount) {
        super();
        this.SearchResult = SearchResult;
        this.SearchString = SearchString;
        this.DebounceHandler = DebounceHandler;
        this.SelectedIndex = (SelectedIndex | 0);
        this.Value = Value;
        this.IsActive = IsActive;
        this.MinCharCount = (MinCharCount | 0);
    }
}

export function Model$reflection() {
    return record_type("Components.Autocomplete.Model", [], Model, () => [["SearchResult", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [FoundResult$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", FoundResult$reflection()]], [["ErrorValue", string_type]]]))], ["SearchString", string_type], ["DebounceHandler", option_type(class_type("System.Threading.CancellationTokenSource"))], ["SelectedIndex", int32_type], ["Value", option_type(class_type("System.Collections.Generic.KeyValuePair`2", [tuple_type(string_type, string_type, string_type), string_type]))], ["IsActive", bool_type], ["MinCharCount", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["KeyboardEvent", "QueryResult", "SearchStringChanged", "OnFocus", "ChangeSelectedIndex", "SelectItem"];
    }
}

export function Msg$reflection() {
    return union_type("Components.Autocomplete.Msg", [], Msg, () => [[["Item", class_type("Browser.Types.KeyboardEvent")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [FoundResult$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", FoundResult$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [], [["Item", int32_type]], []]);
}

function searchResult(state) {
    const matchValue = state.SearchResult;
    let pattern_matching_result, xs;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
            xs = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return xs.Items;
        }
        case 1: {
            return empty();
        }
    }
}

function selectedItem(state) {
    const matchValue = state.SearchResult;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            return void 0;
        }
        else {
            return tryItem(state.SelectedIndex, matchValue.fields[0].fields[0].Items);
        }
    }
    else {
        return void 0;
    }
}

export function init(searchString, startSearchOnInit, minSearchCharCount) {
    return [new Model(new Deferred$1(0), searchString, void 0, -1, void 0, false, minSearchCharCount), startSearchOnInit ? Cmd_OfFunc_result(new Msg(1, new AsyncOperationStatus$1(0))) : Cmd_none()];
}

export function update(props, msg, state) {
    let current, current_1;
    if (msg.tag === 2) {
        props.OnChange(msg.fields[0]);
        return [new Model(state.SearchResult, msg.fields[0], state.DebounceHandler, state.SelectedIndex, state.Value, state.IsActive, state.MinCharCount), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [state, Cmd_OfFunc_result(new Msg(1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 4) {
        return [new Model(state.SearchResult, state.SearchString, state.DebounceHandler, msg.fields[0], state.Value, state.IsActive, state.MinCharCount), Cmd_none()];
    }
    else if (msg.tag === 5) {
        const selected = selectedItem(state);
        const patternInput = defaultArg(map((x) => KeyValuePair_key(x), selected), ["", "", ""]);
        props.OnSelect(map((x_1) => KeyValuePair_key(x_1), selected));
        return [new Model(state.SearchResult, patternInput[0], state.DebounceHandler, state.SelectedIndex, selected, false, state.MinCharCount), Cmd_none()];
    }
    else if (msg.tag === 0) {
        iterate((x_2) => {
            cancel(x_2);
        }, toArray(state.DebounceHandler));
        const matchValue = msg.fields[0].code;
        switch (matchValue) {
            case "Enter": {
                return [state, props.OnBlur ? Cmd_OfFunc_result(new Msg(5)) : Cmd_none()];
            }
            case "ArrowUp": {
                return [new Model(state.SearchResult, state.SearchString, state.DebounceHandler, (current = (state.SelectedIndex | 0), defaultArg(map((_arg1_1) => (current - 1), tryItem(current - 1, searchResult(state))), current)), state.Value, state.IsActive, state.MinCharCount), Cmd_none()];
            }
            case "ArrowDown": {
                return [new Model(state.SearchResult, state.SearchString, state.DebounceHandler, (current_1 = (state.SelectedIndex | 0), defaultArg(map((_arg5) => (current_1 + 1), tryItem(current_1 + 1, searchResult(state))), current_1)), state.Value, state.IsActive, state.MinCharCount), Cmd_none()];
            }
            case "Escape": {
                return [state, props.OnBlur ? Cmd_OfFunc_result(new Msg(5)) : Cmd_none()];
            }
            default: {
                const patternInput_1 = Cmd_fromAsyncCsp(singleton.Delay(() => singleton.Bind(sleep(props.DebounceTimeout), () => singleton.Return(new Msg(1, new AsyncOperationStatus$1(0))))));
                return [new Model(state.SearchResult, state.SearchString, patternInput_1[1], state.SelectedIndex, state.Value, state.IsActive, state.MinCharCount), patternInput_1[0]];
            }
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Model(new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.SearchString, void 0, state.SelectedIndex, state.Value, state.IsActive, state.MinCharCount), Cmd_none()];
        }
        else {
            return [new Model(new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.SearchString, void 0, state.SelectedIndex, state.Value, state.IsActive, state.MinCharCount), Cmd_none()];
        }
    }
    else {
        const cmd = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(props.Search(state.SearchString.trim().toLowerCase()), (_arg1) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
        if (state.SearchString.length < state.MinCharCount) {
            return [new Model(new Deferred$1(0), state.SearchString, state.DebounceHandler, state.SelectedIndex, state.Value, false, state.MinCharCount), Cmd_none()];
        }
        else {
            return [new Model(new Deferred$1(1), state.SearchString, state.DebounceHandler, 0, state.Value, true, state.MinCharCount), Cmd_fromAsync(cmd)];
        }
    }
}

function renderExtraInfo(props, model) {
    const infoText = (s) => createElement("div", createObj(Helpers_combineClasses("", ofArray([["className", "is-size-7"], ["className", "has-text-grey"], ["className", "has-text-centered"], ["children", s]]))));
    const matchValue = model.SearchResult;
    let pattern_matching_result;
    if (matchValue.tag === 0) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            if (length(matchValue.fields[0].fields[0].Items) === 0) {
                pattern_matching_result = 1;
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            const elms = singleton_1(infoText(props.Localization.StartTypeToSearch()));
            return createElement("div", {
                className: "dropdown-item",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            });
        }
        case 1: {
            const elms_1 = singleton_1(infoText(props.Localization.NoItemsFound()));
            return createElement("div", {
                className: "dropdown-item",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            });
        }
        case 2: {
            let pattern_matching_result_1, xs_7;
            if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    if (length(matchValue.fields[0].fields[0].Items) > 0) {
                        pattern_matching_result_1 = 0;
                        xs_7 = matchValue.fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const elms_2 = singleton_1(infoText(props.Localization.FoundItems(length(xs_7.Items), xs_7.Total)));
                    return createElement("div", {
                        className: "dropdown-item",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                    });
                }
                case 1: {
                    let pattern_matching_result_2, e;
                    if (matchValue.tag === 2) {
                        if (matchValue.fields[0].tag === 1) {
                            pattern_matching_result_2 = 0;
                            e = matchValue.fields[0].fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            const elms_3 = singleton_1(createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-danger"], ["children", e]])))));
                            return createElement("div", {
                                className: "dropdown-item",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                            });
                        }
                        case 1: {
                            return null;
                        }
                    }
                }
            }
        }
    }
}

function renderItems(model, dispatch) {
    const matchValue = model.SearchResult;
    let pattern_matching_result, xs;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
            xs = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return toList(delay(() => collect((matchValue_1) => {
                let props_1;
                const index = matchValue_1[0] | 0;
                return singleton_2((props_1 = toList(delay(() => append((index === model.SelectedIndex) ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => append(singleton_2(["children", Interop_reactApi.Children.toArray([createElement("span", {
                    className: "",
                    children: matchValue_1[1][1],
                })])]), delay(() => append(singleton_2(["onMouseOver", (x) => {
                    x.preventDefault();
                    dispatch(new Msg(4, index));
                }]), delay(() => singleton_2(["onClick", (_arg1) => {
                    dispatch(new Msg(5));
                }]))))))))), createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_1)))));
            }, indexed(xs.Items))));
        }
        case 1: {
            return singleton_1(null);
        }
    }
}

export function Autocomplete(autocompleteInputProps) {
    const dependencies = autocompleteInputProps.dependencies;
    const props = autocompleteInputProps.props;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(props.SearchText, props.StartSearchOnInit, props.MinSearchCharCount), (msg, state) => update(props, msg, state), dependencies);
    const model = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput_1[1];
    const suppressPropagateEnterKey = (ev) => {
        if (ev.key === "Enter") {
            const this$ = ev;
            this$.stopPropagation();
            this$.nativeEvent.stopImmediatePropagation();
        }
    };
    let hasItems;
    const matchValue = model.SearchResult;
    let pattern_matching_result, xs;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
            xs = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            hasItems = (!isEmpty(xs.Items));
            break;
        }
        case 1: {
            hasItems = false;
            break;
        }
    }
    const props_13 = toList(delay(() => append(model.IsActive ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => append(singleton_2(["style", {
        width: 100 + "%",
    }]), delay(() => append(singleton_2(["onMouseLeave", (_arg1) => {
        setVisible(false);
    }]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_6, elms, props_3;
        return append(singleton_2((props_6 = ofArray([["style", {
            width: 100 + "%",
        }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_3 = toList(delay(() => append(equals(model.SearchResult, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["onKeyDown", suppressPropagateEnterKey]), delay(() => append(singleton_2(["onKeyPress", suppressPropagateEnterKey]), delay(() => append(singleton_2(["onKeyUp", suppressPropagateEnterKey]), delay(() => {
            let props_1;
            return singleton_2(["children", Interop_reactApi.Children.toArray([(props_1 = toList(delay(() => append(singleton_2(["ref", props.InputRef]), delay(() => append(singleton_2(["placeholder", props.Localization.Placeholder]), delay(() => {
                let value_20;
                return append(singleton_2((value_20 = model.SearchString, ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_20)) : false) {
                        e.value = value_20;
                    }
                }])), delay(() => append(singleton_2(["autoFocus", props.IsAutoFocus]), delay(() => append(singleton_2(["onChange", (ev_1) => {
                    dispatch(new Msg(2, ev_1.target.value));
                }]), delay(() => append(singleton_2(["onKeyUp", (arg_1) => {
                    dispatch(new Msg(0, arg_1));
                }]), delay(() => append(singleton_2(["onKeyPress", (_arg2) => {
                    setVisible(true);
                }]), delay(() => append(singleton_2(["onFocus", (_arg3) => {
                    dispatch(new Msg(3));
                    setVisible(true);
                }]), delay(() => (props.OnBlur ? singleton_2(["onBlur", (_arg4) => {
                    dispatch(new Msg(5));
                }]) : empty_1())))))))))))));
            })))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_1)))))])]);
        })))))))))), createElement("div", createObj(Helpers_combineClasses("control", props_3))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("dropdown-trigger", props_6))))), delay(() => {
            let props_11, elms_1;
            return ((props.IsShowWhenNoResult ? true : hasItems) ? patternInput_1[0] : false) ? singleton_2((props_11 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = toList(delay(() => append(renderItems(model, dispatch), delay(() => append(singleton_2(createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", empty())))), delay(() => singleton_2(renderExtraInfo(props, model)))))))), createElement("div", {
                className: "dropdown-content",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_11))))) : empty_1();
        }));
    }))))])))))))));
    return createElement("div", createObj(Helpers_combineClasses("dropdown", props_13)));
}

