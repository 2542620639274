import { map as map_1, sortBy, filter, empty, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { map, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { empty as empty_1, tryFind } from "./fable_modules/fable-library.3.6.1/Map.js";
import { isNullOrEmpty, join, isNullOrWhiteSpace } from "./fable_modules/fable-library.3.6.1/String.js";
import { CurrencyModule_round, CurrencyModule_FormatSettings, CurrencyModule_createFormatter, CurrencyModule_zero, CurrencyModule_fromDecimal, PickPointIdModule_ArtMove_$007CGothenburg$007CStockholm$007CMalmo$007CHelsinki$007CUnknown$007C, ReservedPriceModule_fromInt, CountryCodeModule_$007CEU$007CNonEU$007C, EmailModule_toString, EmailModule_ofString, CountryCodeModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { Validator$1__NotBlank_2B595, Validator$1__IsMail, Validator$1__Test, Validator$1__End_Z5E18B1E2, validateSync } from "./fable_modules/Fable.Validation.0.2.1/Validation.fs.js";
import { getCurrentLanguage, t } from "./Localization.js";
import { SellerCompany_CompanyInfo_organizationNumber, SellerCompany_CompanyInfo_companyName, BankInfo_iban, BankInfo_testedIsEmptyIban, BankInfo_bankNumber, BankInfo_clearingNumber, Address_city, Address_zipCode, Address_address, Common_phone, Common_lastName, Common_firstName, Common_passwordConfirmation, Common_password } from "./Validation.js";
import { CurrencyFormatSettings, User_RegisterSellerCompany, User_ContactPersonInfo, User_SellerCompanyInfo, User_RegisterSellerPersonal, User_BankInfo, User_Address, User_PersonalInfo } from "./Shared/Shared.js";
import { toString, tryParse } from "./fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.6.1/Decimal.js";
import { FSharpRef } from "./fable_modules/fable-library.3.6.1/Types.js";
import { tryParse as tryParse_1 } from "./fable_modules/fable-library.3.6.1/Int32.js";
import { comparePrimitives, int32ToString } from "./fable_modules/fable-library.3.6.1/Util.js";
import { CountryModule_possibleCountries, CountryModule_swedishName } from "./bidflow/src/infrastructure/Countries.js";
import { addMonths, day, month, year, addDays, now } from "./fable_modules/fable-library.3.6.1/Date.js";
import { create, fromDate } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { endOfDay, addWeeks, startOfDay } from "date-fns";
import { LangModule_swedish, LangModule_english } from "./bidflow/src/infrastructure/Localization.js";
import { class_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";

export const Warehouse_list = ofArray(["Alla", "Lager A", "Lager B", "Lager C", "Lager D", "Lager E", "Lager F", "Tibble"]);

export const SizeSubmission_list = ofArray(["Alla", "XS", "S", "M", "L", "XL"]);

export function Form_filter(x, errors) {
    if (x) {
        return errors;
    }
    else {
        return void 0;
    }
}

export function Form_getFieldError(errors, field) {
    return defaultArg(tryFind(field, errors), empty());
}

export function Form_testIban(iban, country) {
    if (!isNullOrWhiteSpace(iban)) {
        return country !== CountryCodeModule_empty;
    }
    else {
        return true;
    }
}

export function Form_validatePrivate(basicSettings, registeredUserPrivate) {
    return validateSync(true, (validator) => {
        let arg10_1;
        return new User_RegisterSellerPersonal(EmailModule_ofString(Validator$1__End_Z5E18B1E2(validator, (arg10_1 = Validator$1__Test(validator, "Email", EmailModule_toString(registeredUserPrivate.Email)), Validator$1__IsMail(validator, t("errors.validation.emailIsInvalid"), arg10_1)))), Validator$1__End_Z5E18B1E2(validator, Validator$1__Test(validator, "NonExistentEmail", registeredUserPrivate.NonExistentEmail)), registeredUserPrivate.NeedAvoidWarehouseFee, Common_password(validator)(registeredUserPrivate.Password), Common_passwordConfirmation(registeredUserPrivate.Password, validator)(registeredUserPrivate.PasswordConfirmation), new User_PersonalInfo(Common_firstName(validator)(registeredUserPrivate.PersonalInfo.FirstName), Common_lastName(validator)(registeredUserPrivate.PersonalInfo.LastName), Common_phone(validator)(registeredUserPrivate.PersonalInfo.Phone), registeredUserPrivate.PersonalInfo.PersonalNumber), new User_Address(Address_address(validator)(registeredUserPrivate.Address.Address), Address_zipCode(validator)(registeredUserPrivate.Address.ZipCode), Address_city(validator)(registeredUserPrivate.Address.City), Validator$1__End_Z5E18B1E2(validator, Validator$1__Test(validator, "Country", registeredUserPrivate.Address.Country))), new User_BankInfo(BankInfo_clearingNumber(validator)(registeredUserPrivate.BankInfo.ClearingNumber), BankInfo_bankNumber(validator)(registeredUserPrivate.BankInfo.BankNumber), Validator$1__End_Z5E18B1E2(validator, Validator$1__Test(validator, "Bank", registeredUserPrivate.BankInfo.Bank)), registeredUserPrivate.BankInfo.Comment, BankInfo_testedIsEmptyIban(registeredUserPrivate.BankInfo.Iban, validator)(registeredUserPrivate.BankInfo.IbanCountry), BankInfo_iban(validator)(registeredUserPrivate.BankInfo.Iban)));
    });
}

export function Form_validationErrorsPrivate(basicSettings, registeredUser, validationState) {
    const form = Form_validatePrivate(basicSettings, registeredUser);
    if (form.tag === 1) {
        return validationState ? form.fields[0] : empty_1();
    }
    else {
        return empty_1();
    }
}

export function Form_validateCompany(residentCountry, registeredUserCompany) {
    return validateSync(true, (validator) => {
        let arg10_1, arg10_7;
        const Email = EmailModule_ofString(Validator$1__End_Z5E18B1E2(validator, (arg10_1 = Validator$1__Test(validator, "Email", EmailModule_toString(registeredUserCompany.Email)), Validator$1__IsMail(validator, t("errors.validation.emailIsInvalid"), arg10_1))));
        const NonExistentEmail = Validator$1__End_Z5E18B1E2(validator, Validator$1__Test(validator, "NonExistentEmail", registeredUserCompany.NonExistentEmail));
        const Password = Common_password(validator)(registeredUserCompany.Password);
        const PasswordConfirmation = Common_passwordConfirmation(registeredUserCompany.Password, validator)(registeredUserCompany.PasswordConfirmation);
        const CompanyInfo = new User_SellerCompanyInfo(SellerCompany_CompanyInfo_companyName(validator)(registeredUserCompany.CompanyInfo.Name), SellerCompany_CompanyInfo_organizationNumber(validator)(registeredUserCompany.CompanyInfo.OrganizationNumber), registeredUserCompany.CompanyInfo.IsVATDisabled, (CountryCodeModule_$007CEU$007CNonEU$007C(registeredUserCompany.Address.Country).tag === 0) ? ((registeredUserCompany.Address.Country !== residentCountry) ? Validator$1__End_Z5E18B1E2(validator, Validator$1__NotBlank_2B595(validator, t("errors.validation.vatIsRequired"))(Validator$1__Test(validator, "VatNumber", registeredUserCompany.CompanyInfo.VatNumber))) : registeredUserCompany.CompanyInfo.VatNumber) : registeredUserCompany.CompanyInfo.VatNumber);
        let Address;
        const ZipCode = Address_zipCode(validator)(registeredUserCompany.Address.ZipCode);
        Address = (new User_Address(Address_address(validator)(registeredUserCompany.Address.Address), ZipCode, Address_city(validator)(registeredUserCompany.Address.City), Validator$1__End_Z5E18B1E2(validator, Validator$1__Test(validator, "Country", registeredUserCompany.Address.Country))));
        return new User_RegisterSellerCompany(Email, NonExistentEmail, registeredUserCompany.NeedAvoidWarehouseFee, Password, PasswordConfirmation, CompanyInfo, new User_BankInfo(BankInfo_clearingNumber(validator)(registeredUserCompany.BankInfo.ClearingNumber), BankInfo_bankNumber(validator)(registeredUserCompany.BankInfo.BankNumber), Validator$1__End_Z5E18B1E2(validator, Validator$1__Test(validator, "Bank", registeredUserCompany.BankInfo.Bank)), registeredUserCompany.BankInfo.Comment, BankInfo_testedIsEmptyIban(registeredUserCompany.BankInfo.Iban, validator)(registeredUserCompany.BankInfo.IbanCountry), BankInfo_iban(validator)(registeredUserCompany.BankInfo.Iban)), Address, new User_ContactPersonInfo(Common_firstName(validator)(registeredUserCompany.ContactPerson.FirstName), Common_lastName(validator)(registeredUserCompany.ContactPerson.LastName), Common_phone(validator)(registeredUserCompany.ContactPerson.Phone), EmailModule_ofString(Validator$1__End_Z5E18B1E2(validator, (arg10_7 = Validator$1__Test(validator, "PersonalEmail", EmailModule_toString(registeredUserCompany.ContactPerson.Email)), Validator$1__IsMail(validator, t("errors.validation.emailIsInvalid"), arg10_7))))));
    });
}

export function Form_validationErrorsCompany(residentCountry, registeredUser, validationState) {
    const form = Form_validateCompany(residentCountry, registeredUser);
    if (form.tag === 1) {
        return validationState ? form.fields[0] : empty_1();
    }
    else {
        return empty_1();
    }
}

export function Decimal_tryParse(s) {
    let patternInput;
    let outArg = new Decimal(0);
    patternInput = [tryParse(s, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (patternInput[0]) {
        return patternInput[1];
    }
    else {
        return void 0;
    }
}

export function Decimal_optionalToString(x) {
    return defaultArg(map((value) => toString(value), x), "");
}

export function Int_tryParse(s) {
    let patternInput;
    let outArg = 0;
    patternInput = [tryParse_1(s, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (patternInput[0]) {
        return patternInput[1];
    }
    else {
        return void 0;
    }
}

export const ReservedPrice_tryParse = (arg) => map((x) => ReservedPriceModule_fromInt(x), Int_tryParse(arg));

export const ReservedPrice_tryParseOrDefault = (arg_1) => defaultArg(map((x) => ReservedPriceModule_fromInt(x), Int_tryParse(arg_1)), 0);

export function ReservedPrice_optionalToString(x) {
    return defaultArg(map((value) => int32ToString(value), x), "");
}

export function ReservedPrice_toString(x) {
    return int32ToString(x);
}

export function Delivery_addressToString(delivery) {
    if (delivery.tag === 1) {
        const address = delivery.fields[0];
        return join(",", filter((arg) => (!isNullOrEmpty(arg)), ofArray([address.Address, address.ZipCode, address.City, CountryModule_swedishName(address.Country)])));
    }
    else {
        const activePatternResult30724 = PickPointIdModule_ArtMove_$007CGothenburg$007CStockholm$007CMalmo$007CHelsinki$007CUnknown$007C(delivery.fields[0]);
        if (activePatternResult30724.tag === 1) {
            return "Stockholm";
        }
        else if (activePatternResult30724.tag === 2) {
            return "Malmö";
        }
        else if (activePatternResult30724.tag === 3) {
            return "Helsingfors";
        }
        else if (activePatternResult30724.tag === 4) {
            return "Unknown ArtMove pickup point";
        }
        else {
            return "Göteborg";
        }
    }
}

export function Delivery_getCityFromAddress(delivery) {
    if (delivery.tag === 1) {
        return delivery.fields[0].City;
    }
    else {
        const activePatternResult30727 = PickPointIdModule_ArtMove_$007CGothenburg$007CStockholm$007CMalmo$007CHelsinki$007CUnknown$007C(delivery.fields[0]);
        if (activePatternResult30727.tag === 1) {
            return "Stockholm";
        }
        else if (activePatternResult30727.tag === 2) {
            return "Malmö";
        }
        else if (activePatternResult30727.tag === 3) {
            return "Helsingfors";
        }
        else if (activePatternResult30727.tag === 4) {
            return "Unknown";
        }
        else {
            return "Göteborg";
        }
    }
}

export function Address_addressToString(address) {
    return join(",", filter((arg) => (!isNullOrEmpty(arg)), ofArray([address.Address, address.ZipCode, address.City, CountryModule_swedishName(address.Country)])));
}

export const Styles_fontForStatistic = ["style", {
    fontSize: 0.65 + "rem",
}];

export const Dates_currentDate = now();

export const Dates_startDate = fromDate(startOfDay((() => {
    let copyOfStruct = Dates_currentDate;
    return addDays(copyOfStruct, -1);
})()));

export const Dates_startDateTimeSpanZero = create((() => {
    let copyOfStruct_1;
    let copyOfStruct = Dates_currentDate;
    copyOfStruct_1 = addDays(copyOfStruct, -1);
    return year(copyOfStruct_1) | 0;
})(), (() => {
    let copyOfStruct_3;
    let copyOfStruct_2 = Dates_currentDate;
    copyOfStruct_3 = addDays(copyOfStruct_2, -1);
    return month(copyOfStruct_3) | 0;
})(), (() => {
    let copyOfStruct_5;
    let copyOfStruct_4 = Dates_currentDate;
    copyOfStruct_5 = addDays(copyOfStruct_4, -1);
    return day(copyOfStruct_5) | 0;
})(), 0, 0, 0, 0);

export const Dates_monthAgo = fromDate(startOfDay((() => {
    let copyOfStruct = Dates_currentDate;
    return addMonths(copyOfStruct, -1);
})()));

export const Dates_monthAgoTimeSpanZero = create((() => {
    let copyOfStruct_1;
    let copyOfStruct = Dates_currentDate;
    copyOfStruct_1 = addMonths(copyOfStruct, -1);
    return year(copyOfStruct_1) | 0;
})(), (() => {
    let copyOfStruct_3;
    let copyOfStruct_2 = Dates_currentDate;
    copyOfStruct_3 = addMonths(copyOfStruct_2, -1);
    return month(copyOfStruct_3) | 0;
})(), (() => {
    let copyOfStruct_5;
    let copyOfStruct_4 = Dates_currentDate;
    copyOfStruct_5 = addMonths(copyOfStruct_4, -1);
    return day(copyOfStruct_5) | 0;
})(), 0, 0, 0, 0);

export const Dates_weekAgo = fromDate(startOfDay(addWeeks(Dates_currentDate, -1)));

export const Dates_endDate = fromDate(endOfDay(Dates_currentDate));

export const Dates_endDateTimeSpanZero = create((() => {
    let copyOfStruct = Dates_currentDate;
    return year(copyOfStruct) | 0;
})(), (() => {
    let copyOfStruct_1 = Dates_currentDate;
    return month(copyOfStruct_1) | 0;
})(), (() => {
    let copyOfStruct_2 = Dates_currentDate;
    return day(copyOfStruct_2) | 0;
})(), 23, 59, 59, 0);

function CountriesInput_sortCountryList(lng) {
    return sortBy((tuple) => tuple[1], map_1((x) => [x.Code, (lng === LangModule_english) ? x.NameEnglish : ((lng === LangModule_swedish) ? x.NameSwedish : x.NameFinnish)], CountryModule_possibleCountries), {
        Compare: (x_1, y) => comparePrimitives(x_1, y),
    });
}

export function CountriesInput_getSortedList() {
    return CountriesInput_sortCountryList(getCurrentLanguage());
}

export const Currency_tryParseOrDefault = (arg_1) => defaultArg(map((value) => CurrencyModule_fromDecimal(value), Decimal_tryParse(arg_1)), CurrencyModule_zero);

export class Currency_Settings_Cache {
    constructor(value) {
        this["value@275"] = value;
    }
}

export function Currency_Settings_Cache$reflection() {
    return class_type("Common.Currency.Settings.Cache", void 0, Currency_Settings_Cache);
}

export function Currency_Settings_Cache_$ctor_Z7FCDC21D(value) {
    return new Currency_Settings_Cache(value);
}

export function Currency_Settings_Cache__get_Value(this$) {
    return this$["value@275"];
}

export function Currency_Settings_Cache__Update_Z7FCDC21D(this$, v) {
    this$["value@275"] = v;
}

export const Currency_Settings_defaultFormatSettings = new CurrencyFormatSettings(2, "", ",", " ", ofArray([3, 3, 3, 3, 3, 3, 3, 3, 3]), 8, 3);

export const Currency_Settings_settings = Currency_Settings_Cache_$ctor_Z7FCDC21D(Currency_Settings_defaultFormatSettings);

export function Currency_Settings_createFormatter() {
    let CurrencySymbol;
    return CurrencyModule_createFormatter((CurrencySymbol = Currency_Settings_Cache__get_Value(Currency_Settings_settings).CurrencySymbol, new CurrencyModule_FormatSettings(Currency_Settings_Cache__get_Value(Currency_Settings_settings).DecimalDigits, CurrencySymbol, Currency_Settings_Cache__get_Value(Currency_Settings_settings).DecimalSeparator, Currency_Settings_Cache__get_Value(Currency_Settings_settings).GroupSeparator, Currency_Settings_Cache__get_Value(Currency_Settings_settings).GroupSizes, Currency_Settings_Cache__get_Value(Currency_Settings_settings).NegativePattern, Currency_Settings_Cache__get_Value(Currency_Settings_settings).PositivePattern)));
}

export function Currency_currencySymbol() {
    return Currency_Settings_Cache__get_Value(Currency_Settings_settings).CurrencySymbol;
}

export function Currency_format(x) {
    return Currency_Settings_createFormatter().Format(x);
}

export function Currency_round(x) {
    return CurrencyModule_round(Currency_Settings_Cache__get_Value(Currency_Settings_settings).DecimalDigits, x);
}

export function Currency_decimalDigits() {
    return Currency_Settings_Cache__get_Value(Currency_Settings_settings).DecimalDigits;
}

export function Currency_formatN(x) {
    return Currency_Settings_createFormatter().FormatNumber(x);
}

export function Currency_formatOption(defaultValue, x) {
    return Currency_Settings_createFormatter().FormatOpt(defaultValue, x);
}

export function Currency_formatNOption(defaultValue, x) {
    return Currency_Settings_createFormatter().FormatNumberOpt(defaultValue, x);
}

export function AccountNumbers_get(key, accountNumbers) {
    return defaultArg(tryFind(key, accountNumbers), "");
}

