import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, option_type, union_type, string_type, unit_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { BasicInformation_subTitle2, BasicInformation_subTitle1, BasicInformation_title, BasicInformationField, BasicInformation_name, ImageModule_empty, AuctionStatus, AuctionModule_GeneralSettings, Image$reflection, BasicInformationField$reflection, AuctionModule_GeneralSettings$reflection } from "../Shared/Shared.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { auctionApi } from "../Communication.js";
import { toLocalTime, now, fromDate } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { now as now_1, second, minute, hour, day, month, year, create } from "../fable_modules/fable-library.3.6.1/Date.js";
import { LocalizedMap_add, LocalizedMap_empty } from "../bidflow/src/infrastructure/Localization.js";
import { Localized_translateTo, t as t_3, getPrimaryLanguage } from "../Localization.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { value as value_207 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { cons, singleton as singleton_2, empty, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Route, AuctionArg, AuctionState, toPath } from "../Router.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { TimeTwentyFour, EnableTimePicker, HideCalendar, Locale, ClassName, OnChange, custom, DateFormat, Value, flatpickr } from "../fable_modules/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import { dividedTop } from "./BorderDividedLine.js";
import { languageSwitcher } from "./ContentLanguageSwitcher.js";
import { EditImages } from "./DownloadBackgroundImage.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "../Loader.js";

export class Types_State extends Record {
    constructor(AuctionId, IsAuctionLoaded, Auction, Error$, EditInformation, SelectedDate, SelectedTime, ContentLanguage) {
        super();
        this.AuctionId = AuctionId;
        this.IsAuctionLoaded = IsAuctionLoaded;
        this.Auction = Auction;
        this.Error = Error$;
        this.EditInformation = EditInformation;
        this.SelectedDate = SelectedDate;
        this.SelectedTime = SelectedTime;
        this.ContentLanguage = ContentLanguage;
    }
}

export function Types_State$reflection() {
    return record_type("Client.Components.AuctionGeneralSettings.Types.State", [], Types_State, () => [["AuctionId", class_type("System.Int64")], ["IsAuctionLoaded", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["Auction", AuctionModule_GeneralSettings$reflection()], ["Error", option_type(string_type)], ["EditInformation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["SelectedDate", class_type("System.DateTime")], ["SelectedTime", class_type("System.DateTime")], ["ContentLanguage", string_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadAuction", "EditAuctionInfo", "UpdateSelectedDate", "UpdateSelectedTime", "UpdateField", "RemoveErrorNotification", "AuctionImagesChanged", "OnContentLanguageChanged"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.Components.AuctionGeneralSettings.Types.Msg", [], Types_Msg, () => [[["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionModule_GeneralSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionModule_GeneralSettings$reflection()]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item1", BasicInformationField$reflection()], ["Item2", string_type], ["Item3", string_type]], [], [["Item", Image$reflection()]], [["Item", string_type]]]);
}

export function Cmd_loadAuction(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAuction(id)), (_arg1) => {
        const auction = _arg1;
        return singleton.Return(new Types_Msg(0, id, new AsyncOperationStatus$1(1, new FSharpResult$2(0, new AuctionModule_GeneralSettings(auction.Date, auction.Status, auction.BasicInformation, auction.AuctionImage)))));
    })), (_arg2) => singleton.Return(new Types_Msg(0, id, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_editAuctionInfo(state, id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().editAuctionGeneralSettings([id, state.Auction])), (_arg1) => singleton.Return(new Types_Msg(1, id, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Types_Msg(1, id, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Common_toDateTimeOffset(date, time) {
    let copyOfStruct;
    return fromDate(create(year(date), month(date), day(date), hour(time), minute(time), second(time)), (copyOfStruct = now(), copyOfStruct.offset));
}

export function State_init(id) {
    return [new Types_State(id, new Deferred$1(0), new AuctionModule_GeneralSettings(now(), new AuctionStatus(2), LocalizedMap_empty(), ImageModule_empty), void 0, new Deferred$1(0), now_1(), now_1(), getPrimaryLanguage()), Cmd_OfFunc_result(new Types_Msg(0, id, new AsyncOperationStatus$1(0)))];
}

export function State_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord;
    if (msg.tag === 3) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_1 = state.Auction, new AuctionModule_GeneralSettings(Common_toDateTimeOffset(toLocalTime(state.Auction.Date), msg.fields[0]), inputRecord_1.Status, inputRecord_1.BasicInformation, inputRecord_1.AuctionImage)), state.Error, state.EditInformation, state.SelectedDate, msg.fields[0], state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_2 = state.Auction, new AuctionModule_GeneralSettings(inputRecord_2.Date, inputRecord_2.Status, LocalizedMap_add(msg.fields[0], msg.fields[1], msg.fields[2], state.Auction.BasicInformation), inputRecord_2.AuctionImage)), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 0) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new Types_State(state.AuctionId, new Deferred$1(2, new FSharpResult$2(1, msg.fields[1].fields[0].fields[0])), state.Auction, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
            else {
                return [new Types_State(state.AuctionId, new Deferred$1(2, new FSharpResult$2(0, void 0)), msg.fields[1].fields[0].fields[0], state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.AuctionId, new Deferred$1(1), state.Auction, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_fromAsync(Cmd_loadAuction(msg.fields[0]))];
        }
    }
    else if (msg.tag === 6) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_3 = state.Auction, new AuctionModule_GeneralSettings(inputRecord_3.Date, inputRecord_3.Status, inputRecord_3.BasicInformation, msg.fields[0])), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 1) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                const er_1 = msg.fields[1].fields[0].fields[0];
                return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, er_1, new Deferred$1(2, new FSharpResult$2(1, er_1)), state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
            else {
                return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, void 0, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, state.Error, new Deferred$1(1), state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_fromAsync(Cmd_editAuctionInfo(state, msg.fields[0]))];
        }
    }
    else if (msg.tag === 5) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, void 0, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, msg.fields[0]), Cmd_none()];
    }
    else {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord = state.Auction, new AuctionModule_GeneralSettings(Common_toDateTimeOffset(msg.fields[0], toLocalTime(state.Auction.Date)), inputRecord.Status, inputRecord.BasicInformation, inputRecord.AuctionImage)), state.Error, state.EditInformation, msg.fields[0], state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
}

export function View_renderErrors(state, dispatch) {
    let s;
    if (state.Error != null) {
        const props_2 = ofArray([["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("button", {
            className: join(" ", ["delete"]),
            onClick: (x) => {
                x.preventDefault();
                dispatch(new Types_Msg(5));
            },
        }), (s = value_207(state.Error), createElement("div", {
            className: "",
            children: s,
        }))])]]);
        return createElement("div", createObj(Helpers_combineClasses("notification", props_2)));
    }
    else {
        return null;
    }
}

export function View_renderButtons(state, dispatch) {
    let props_10, props_8, props_6, props_2, props_4;
    const props_12 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_10 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", empty()))), (props_8 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-dark"], ["className", "mr-3"], ["children", t_3("common.cancel")], ["href", toPath(new Route(2, new AuctionArg(0, state.AuctionId, new AuctionState(false, false, 1))))]]), createElement("a", createObj(Helpers_combineClasses("button", props_2)))), (props_4 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state.EditInformation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["children", t_3("common.save")]), delay(() => singleton_1(["onClick", (x) => {
        x.preventDefault();
        dispatch(new Types_Msg(1, state.AuctionId, new AsyncOperationStatus$1(0)));
    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_6))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))])]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_12)));
}

export function View_renderGeneralSettings(state, dispatch) {
    let props_23, props_21, props_4, props, props_2, elms_4, props_18, elms_1, props_8, elms, elms_3, props_14, elms_2, props_83, props_81, props_29, props_25, props_27, elms_9, props_78, props_76, props_40, elms_5, props_33, props_31, props_37, props_51, elms_6, props_44, props_42, props_48, props_46, props_62, elms_7, props_55, props_53, props_59, props_57, props_73, elms_8, props_66, props_64, props_70, props_68, props_94, props_92, props_89, props_85, props_87, elms_10;
    const children_11 = ofArray([(props_23 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_21 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-0"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-primary"], ["children", t_3("auction.auctionDate")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props)))), (props_2 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_3("auction.auctionDateTimeHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (elms_4 = singleton_2((props_18 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_2(createElement("fieldset", {
        disabled: equals(state.EditInformation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray([(elms = singleton_2(createElement("i", {
            className: join(" ", ["fa", "fa-calendar"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), flatpickr(ofArray([Value(toLocalTime(state.Auction.Date)), DateFormat("d M Y"), custom("locale", {
            firstDayOfWeek: 1,
        }, true), OnChange((arg) => {
            dispatch(new Types_Msg(2, arg));
        }), ClassName("input"), Locale(Swedish)]))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))]),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = singleton_2(createElement("fieldset", {
        disabled: equals(state.EditInformation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2(createElement("i", {
            className: join(" ", ["fa", "fa-clock"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), flatpickr(ofArray([Value(toLocalTime(state.Auction.Date)), HideCalendar(true), EnableTimePicker(true), TimeTwentyFour(true), OnChange((arg_1) => {
            dispatch(new Types_Msg(3, arg_1));
        }), ClassName("input"), Locale(Swedish)]))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_14))))]),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_21))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_23)))), (props_83 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(props_81 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_29 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_25 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t_3("auction.basicInformation")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_25)))), (props_27 = ofArray([["className", "pr-5"], ["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_3("auction.basicInformationHint")]]), createElement("div", createObj(Helpers_combineClasses("", props_27))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_29)))), (elms_9 = singleton_2((props_78 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_76 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
        disabled: equals(state.EditInformation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([languageSwitcher(state.ContentLanguage, (arg_2) => {
            dispatch(new Types_Msg(7, arg_2));
        }), (props_40 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_5 = ofArray([(props_33 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_3("auction.auctionName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_33)))), (props_37 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_35;
            const value_85 = Localized_translateTo(state.ContentLanguage, BasicInformation_name(state.Auction.BasicInformation));
            return singleton_1((props_35 = ofArray([["className", "mr-5"], ["key", `name-${state.ContentLanguage}`], ["defaultValue", value_85], ["onChange", (ev) => {
                dispatch(new Types_Msg(4, new BasicInformationField(0), state.ContentLanguage, ev.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_35))))));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_37))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_40)))), (props_51 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_6 = ofArray([(props_44 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_42 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_3("auction.displayTitle")]]), createElement("label", createObj(Helpers_combineClasses("label", props_42))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_44)))), (props_48 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_46 = ofArray([["className", "mr-5"], ["key", `title-${state.ContentLanguage}`], ["defaultValue", Localized_translateTo(state.ContentLanguage, BasicInformation_title(state.Auction.BasicInformation))], ["onChange", (ev_1) => {
            dispatch(new Types_Msg(4, new BasicInformationField(1), state.ContentLanguage, ev_1.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_46)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_48))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_51)))), (props_62 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_7 = ofArray([(props_55 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_53 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_3("auction.displayTime")]]), createElement("label", createObj(Helpers_combineClasses("label", props_53))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_55)))), (props_59 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_57 = ofArray([["className", "mr-5"], ["key", `subtitle1-${state.ContentLanguage}`], ["placeholder", t_3("auction.displayTimePlaceholderSwedish")], ["defaultValue", Localized_translateTo(state.ContentLanguage, BasicInformation_subTitle1(state.Auction.BasicInformation))], ["onChange", (ev_2) => {
            dispatch(new Types_Msg(4, new BasicInformationField(2), state.ContentLanguage, ev_2.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_57)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_59))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_62)))), (props_73 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_8 = ofArray([(props_66 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_64 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_3("auction.displayTime2")]]), createElement("label", createObj(Helpers_combineClasses("label", props_64))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_66)))), (props_70 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_68 = ofArray([["className", "mr-5"], ["key", `subtitle2-${state.ContentLanguage}`], ["placeholder", t_3("auction.displayTimePlaceholderSwedish2")], ["defaultValue", Localized_translateTo(state.ContentLanguage, BasicInformation_subTitle2(state.Auction.BasicInformation))], ["onChange", (ev_3) => {
            dispatch(new Types_Msg(4, new BasicInformationField(3), state.ContentLanguage, ev_3.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_68)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_70))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_73))))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_76))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_78))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_81))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_83)))), (props_94 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(props_92 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_89 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_85 = ofArray([["className", "mb-0"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-primary"], ["children", t_3("auction.image")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_85)))), (props_87 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_3("auction.imageHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_87))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_89)))), (elms_10 = singleton_2(createElement(EditImages, {
        image: state.Auction.AuctionImage,
        onImagesChanged: (arg_3) => {
            dispatch(new Types_Msg(6, arg_3));
        },
        onDeleteImage: (_arg1) => {
        },
        needDeleteButton: false,
        flag: true,
        dependencies: [equals(state.EditInformation, new Deferred$1(1))],
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_92))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_94))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_11)),
    });
}

export function View_Render(view_RenderInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(view_RenderInputProps.id), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const matchValue = state_1.IsAuctionLoaded;
    let pattern_matching_result, ex;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            ex = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return loader("big");
        }
        case 1: {
            return createElement("h1", {
                children: [ex],
            });
        }
        case 2: {
            return react.createElement(react.Fragment, {}, View_renderGeneralSettings(state_1, dispatch), View_renderErrors(state_1, dispatch), View_renderButtons(state_1, dispatch));
        }
    }
}

