import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, option_type, union_type, string_type, unit_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { SpecialOffersTextInCatalog_subtitleLow, SpecialOffersTextInCatalog_titleLow, SpecialOffersTextInCatalog_subtitleMain, SpecialOffersTextInCatalog_titleMain, SpecialOffersTextInCatalog_subtitleHigh, SpecialOfferInCatalogField, SpecialOffersTextInCatalog_titleHigh, AuctionModule_PrioritySettings, Image$reflection, SpecialOfferInCatalogField$reflection, AuctionModule_PrioritySettings$reflection } from "../Shared/Shared.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { auctionApi } from "../Communication.js";
import { now, fromDate } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { now as now_1, second, minute, hour, day, month, year, create } from "../fable_modules/fable-library.3.6.1/Date.js";
import { LocalizedStringModule_getTranslationOrEmpty, LocalizedMap_add, LocalizedMap_empty } from "../bidflow/src/infrastructure/Localization.js";
import { t as t_6, getPrimaryLanguage } from "../Localization.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { value as value_321 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { cons, singleton as singleton_2, empty, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Route, AuctionArg, AuctionState, toPath } from "../Router.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { languageSwitcher } from "./ContentLanguageSwitcher.js";
import { EditImages } from "./DownloadBackgroundImage.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "../Loader.js";

export class Types_State extends Record {
    constructor(AuctionId, IsAuctionLoaded, Auction, Error$, EditInformation, SelectedDate, SelectedTime, ContentLanguage) {
        super();
        this.AuctionId = AuctionId;
        this.IsAuctionLoaded = IsAuctionLoaded;
        this.Auction = Auction;
        this.Error = Error$;
        this.EditInformation = EditInformation;
        this.SelectedDate = SelectedDate;
        this.SelectedTime = SelectedTime;
        this.ContentLanguage = ContentLanguage;
    }
}

export function Types_State$reflection() {
    return record_type("Client.Components.AuctionPrioritySettings.Types.State", [], Types_State, () => [["AuctionId", class_type("System.Int64")], ["IsAuctionLoaded", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["Auction", AuctionModule_PrioritySettings$reflection()], ["Error", option_type(string_type)], ["EditInformation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["SelectedDate", class_type("System.DateTime")], ["SelectedTime", class_type("System.DateTime")], ["ContentLanguage", string_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadAuction", "EditAuctionInfo", "RemoveErrorNotification", "UpdateField", "FirstImagesChanged", "CatalogImagesChanged", "LowImagesChanged", "DeleteFirstImage", "DeleteCatalogImage", "DeleteLowImage", "OnContentLanguageChanged"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.Components.AuctionPrioritySettings.Types.Msg", [], Types_Msg, () => [[["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [AuctionModule_PrioritySettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", AuctionModule_PrioritySettings$reflection()]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Int64")], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [], [["Item1", SpecialOfferInCatalogField$reflection()], ["Item2", string_type], ["Item3", string_type]], [["Item", Image$reflection()]], [["Item", Image$reflection()]], [["Item", Image$reflection()]], [], [], [], [["Item", string_type]]]);
}

export function Cmd_loadAuction(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAuction(id)), (_arg1) => {
        const auction = _arg1;
        return singleton.Return(new Types_Msg(0, id, new AsyncOperationStatus$1(1, new FSharpResult$2(0, new AuctionModule_PrioritySettings(auction.IsSpecialOffersPublish, auction.SpecialOffersTextInCatalog, auction.FirstImage, auction.CatalogImage, auction.LowImage)))));
    })), (_arg2) => singleton.Return(new Types_Msg(0, id, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_editAuctionInfo(state, id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().editAuctionPrioritySettings([id, state.Auction])), (_arg1) => singleton.Return(new Types_Msg(1, id, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Types_Msg(1, id, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Common_toDateTimeOffset(date, time) {
    let copyOfStruct;
    return fromDate(create(year(date), month(date), day(date), hour(time), minute(time), second(time)), (copyOfStruct = now(), copyOfStruct.offset));
}

export function State_init(id) {
    return [new Types_State(id, new Deferred$1(0), new AuctionModule_PrioritySettings(false, LocalizedMap_empty(), void 0, void 0, void 0), void 0, new Deferred$1(0), now_1(), now_1(), getPrimaryLanguage()), Cmd_OfFunc_result(new Types_Msg(0, id, new AsyncOperationStatus$1(0)))];
}

export function State_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord;
    if (msg.tag === 0) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new Types_State(state.AuctionId, new Deferred$1(2, new FSharpResult$2(1, msg.fields[1].fields[0].fields[0])), state.Auction, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
            else {
                return [new Types_State(state.AuctionId, new Deferred$1(2, new FSharpResult$2(0, void 0)), msg.fields[1].fields[0].fields[0], state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.AuctionId, new Deferred$1(1), state.Auction, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_fromAsync(Cmd_loadAuction(msg.fields[0]))];
        }
    }
    else if (msg.tag === 4) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_1 = state.Auction, new AuctionModule_PrioritySettings(inputRecord_1.IsSpecialOffersPublish, inputRecord_1.SpecialOffersTextInCatalog, msg.fields[0], inputRecord_1.CatalogImage, inputRecord_1.LowImage)), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_2 = state.Auction, new AuctionModule_PrioritySettings(inputRecord_2.IsSpecialOffersPublish, inputRecord_2.SpecialOffersTextInCatalog, inputRecord_2.FirstImage, msg.fields[0], inputRecord_2.LowImage)), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_3 = state.Auction, new AuctionModule_PrioritySettings(inputRecord_3.IsSpecialOffersPublish, inputRecord_3.SpecialOffersTextInCatalog, inputRecord_3.FirstImage, inputRecord_3.CatalogImage, msg.fields[0])), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_4 = state.Auction, new AuctionModule_PrioritySettings(inputRecord_4.IsSpecialOffersPublish, inputRecord_4.SpecialOffersTextInCatalog, void 0, inputRecord_4.CatalogImage, inputRecord_4.LowImage)), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_5 = state.Auction, new AuctionModule_PrioritySettings(inputRecord_5.IsSpecialOffersPublish, inputRecord_5.SpecialOffersTextInCatalog, inputRecord_5.FirstImage, void 0, inputRecord_5.LowImage)), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord_6 = state.Auction, new AuctionModule_PrioritySettings(inputRecord_6.IsSpecialOffersPublish, inputRecord_6.SpecialOffersTextInCatalog, inputRecord_6.FirstImage, inputRecord_6.CatalogImage, void 0)), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 1) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                const er_1 = msg.fields[1].fields[0].fields[0];
                return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, er_1, new Deferred$1(2, new FSharpResult$2(1, er_1)), state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
            else {
                return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, void 0, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, state.Error, new Deferred$1(1), state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_fromAsync(Cmd_editAuctionInfo(state, msg.fields[0]))];
        }
    }
    else if (msg.tag === 2) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, void 0, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, state.Auction, state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, msg.fields[0]), Cmd_none()];
    }
    else {
        return [new Types_State(state.AuctionId, state.IsAuctionLoaded, (inputRecord = state.Auction, new AuctionModule_PrioritySettings(inputRecord.IsSpecialOffersPublish, LocalizedMap_add(msg.fields[0], msg.fields[1], msg.fields[2], state.Auction.SpecialOffersTextInCatalog), inputRecord.FirstImage, inputRecord.CatalogImage, inputRecord.LowImage)), state.Error, state.EditInformation, state.SelectedDate, state.SelectedTime, state.ContentLanguage), Cmd_none()];
    }
}

export function View_renderErrors(state, dispatch) {
    let s;
    if (state.Error != null) {
        const props_2 = ofArray([["className", "is-danger"], ["children", Interop_reactApi.Children.toArray([createElement("button", {
            className: join(" ", ["delete"]),
            onClick: (x) => {
                x.preventDefault();
                dispatch(new Types_Msg(2));
            },
        }), (s = value_321(state.Error), createElement("div", {
            className: "",
            children: s,
        }))])]]);
        return createElement("div", createObj(Helpers_combineClasses("notification", props_2)));
    }
    else {
        return null;
    }
}

export function View_renderButtons(state, dispatch) {
    let props_10, props_8, props_6, props_2, props_4;
    const props_12 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_10 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", empty()))), (props_8 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-dark"], ["className", "mr-3"], ["children", t_6("common.cancel")], ["href", toPath(new Route(2, new AuctionArg(0, state.AuctionId, new AuctionState(false, false, 1))))]]), createElement("a", createObj(Helpers_combineClasses("button", props_2)))), (props_4 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state.EditInformation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["children", t_6("common.save")]), delay(() => singleton_1(["onClick", (x) => {
        x.preventDefault();
        dispatch(new Types_Msg(1, state.AuctionId, new AsyncOperationStatus$1(0)));
    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_6))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))])]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_12)));
}

export function View_renderPrioritySettings(state, dispatch) {
    let props_40, props_29, props_4, props, props_2, props_27, props_14, elms_1, props_8, props_6, elms, props_24, elms_3, props_18, props_16, elms_2, props_38, props_35, props_31, props_33, elms_4, props_84, props_73, props_46, props_42, props_44, props_71, props_68, props_56, elms_6, props_50, props_48, elms_5, props_66, elms_8, props_60, props_58, elms_7, props_82, props_79, props_75, props_77, elms_9, props_128, props_117, props_90, props_86, props_88, props_115, props_112, props_100, elms_11, props_94, props_92, elms_10, props_110, elms_13, props_104, props_102, elms_12, props_126, props_123, props_119, props_121, elms_14;
    const children_15 = ofArray([(props_40 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_29 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-0"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t_6("auction.weeklySpecials")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props)))), (props_2 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_6("auction.weeklySpecialsHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_27 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
        disabled: equals(state.EditInformation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([languageSwitcher(state.ContentLanguage, (arg) => {
            dispatch(new Types_Msg(10, arg));
        }), (props_14 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_8 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_6("auction.weeklySpecialsTitle")]]), createElement("label", createObj(Helpers_combineClasses("label", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_8)))), (elms = toList(delay(() => {
            let props_10;
            const value_34 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, SpecialOffersTextInCatalog_titleHigh(state.Auction.SpecialOffersTextInCatalog));
            return singleton_1((props_10 = ofArray([["className", "mr-5"], ["key", `title-high-prio-${state.ContentLanguage}`], ["defaultValue", value_34], ["onChange", (ev) => {
                dispatch(new Types_Msg(3, new SpecialOfferInCatalogField(0), state.ContentLanguage, ev.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_10))))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_14)))), (props_24 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_18 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_6("auction.weeklySpecialsText")]]), createElement("label", createObj(Helpers_combineClasses("label", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_18)))), (elms_2 = toList(delay(() => {
            let props_20;
            const value_61 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, SpecialOffersTextInCatalog_subtitleHigh(state.Auction.SpecialOffersTextInCatalog));
            return singleton_1((props_20 = ofArray([["rows", 3], ["className", "mr-5"], ["key", `subtitle-high-prio-${state.ContentLanguage}`], ["defaultValue", value_61], ["onChange", (ev_1) => {
                dispatch(new Types_Msg(3, new SpecialOfferInCatalogField(1), state.ContentLanguage, ev_1.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_20)))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_24))))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_27))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_29)))), (props_38 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_35 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "mb-0"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-primary"], ["children", t_6("auction.image")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_31)))), (props_33 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_6("auction.priorityImageHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_33))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_35)))), (elms_4 = singleton_2(createElement(EditImages, {
        image: state.Auction.FirstImage,
        onImagesChanged: (arg_1) => {
            dispatch(new Types_Msg(4, arg_1));
        },
        onDeleteImage: (_arg1) => {
            dispatch(new Types_Msg(7));
        },
        needDeleteButton: true,
        flag: false,
        dependencies: [equals(state.EditInformation, new Deferred$1(1))],
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_38))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_40)))), (props_84 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_73 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_46 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_42 = ofArray([["className", "mb-0"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t_6("auction.weeklyCatalog")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_42)))), (props_44 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_6("auction.weeklyCatalogHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_44))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_46)))), (props_71 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
        disabled: equals(state.EditInformation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([languageSwitcher(state.ContentLanguage, (arg_2) => {
            dispatch(new Types_Msg(10, arg_2));
        }), (props_68 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_56 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_6 = ofArray([(props_50 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_48 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_6("auction.weeklyCatalogTitle")]]), createElement("label", createObj(Helpers_combineClasses("label", props_48))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_50)))), (elms_5 = toList(delay(() => {
            let props_52;
            const value_141 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, SpecialOffersTextInCatalog_titleMain(state.Auction.SpecialOffersTextInCatalog));
            return singleton_1((props_52 = ofArray([["className", "mr-5"], ["key", `title-main-prio-${state.ContentLanguage}`], ["defaultValue", value_141], ["onChange", (ev_2) => {
                dispatch(new Types_Msg(3, new SpecialOfferInCatalogField(2), state.ContentLanguage, ev_2.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_52))))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_56)))), (props_66 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_8 = ofArray([(props_60 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_58 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_6("auction.weeklyCatalogText")]]), createElement("label", createObj(Helpers_combineClasses("label", props_58))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_60)))), (elms_7 = toList(delay(() => {
            let props_62;
            const value_168 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, SpecialOffersTextInCatalog_subtitleMain(state.Auction.SpecialOffersTextInCatalog));
            return singleton_1((props_62 = ofArray([["rows", 3], ["className", "mr-5"], ["key", `subtitle-main-prio-${state.ContentLanguage}`], ["defaultValue", value_168], ["onChange", (ev_3) => {
                dispatch(new Types_Msg(3, new SpecialOfferInCatalogField(3), state.ContentLanguage, ev_3.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_62)))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_66))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_68))))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_71))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_73)))), (props_82 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_79 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_75 = ofArray([["className", "mb-0"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-primary"], ["children", t_6("auction.image")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_75)))), (props_77 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_6("auction.priorityImageHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_77))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_79)))), (elms_9 = singleton_2(createElement(EditImages, {
        image: state.Auction.CatalogImage,
        onImagesChanged: (arg_3) => {
            dispatch(new Types_Msg(5, arg_3));
        },
        onDeleteImage: (_arg2) => {
            dispatch(new Types_Msg(8));
        },
        needDeleteButton: true,
        flag: false,
        dependencies: [equals(state.EditInformation, new Deferred$1(1))],
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_82))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_84)))), (props_128 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_117 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_90 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_86 = ofArray([["className", "mb-0"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t_6("auction.weeklyMarket")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_86)))), (props_88 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_6("auction.weeklyMarketHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_88))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_90)))), (props_115 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
        disabled: equals(state.EditInformation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([languageSwitcher(state.ContentLanguage, (arg_4) => {
            dispatch(new Types_Msg(10, arg_4));
        }), (props_112 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_100 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_11 = ofArray([(props_94 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_92 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_6("auction.weeklyMarketTitle")]]), createElement("label", createObj(Helpers_combineClasses("label", props_92))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_94)))), (elms_10 = toList(delay(() => {
            let props_96;
            const value_249 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, SpecialOffersTextInCatalog_titleLow(state.Auction.SpecialOffersTextInCatalog));
            return singleton_1((props_96 = ofArray([["className", "mr-5"], ["key", `title-low-prio-${state.ContentLanguage}`], ["defaultValue", value_249], ["onChange", (ev_4) => {
                dispatch(new Types_Msg(3, new SpecialOfferInCatalogField(4), state.ContentLanguage, ev_4.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_96))))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_100)))), (props_110 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(elms_13 = ofArray([(props_104 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_102 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_6("auction.weeklyMarketText")]]), createElement("label", createObj(Helpers_combineClasses("label", props_102))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_104)))), (elms_12 = toList(delay(() => {
            let props_106;
            const value_276 = LocalizedStringModule_getTranslationOrEmpty(state.ContentLanguage, SpecialOffersTextInCatalog_subtitleLow(state.Auction.SpecialOffersTextInCatalog));
            return singleton_1((props_106 = ofArray([["rows", 3], ["key", `subtitle-low-prio-${state.ContentLanguage}`], ["className", "mr-5"], ["defaultValue", value_276], ["onChange", (ev_5) => {
                dispatch(new Types_Msg(3, new SpecialOfferInCatalogField(5), state.ContentLanguage, ev_5.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_106)))));
        })), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_110))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_112))))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_115))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_117)))), (props_126 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_123 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_119 = ofArray([["className", "mb-0"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-primary"], ["children", t_6("auction.image")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_119)))), (props_121 = ofArray([["className", "is-size-7"], ["className", "has-text-grey-light"], ["children", t_6("auction.priorityImageHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_121))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_123)))), (elms_14 = singleton_2(createElement(EditImages, {
        image: state.Auction.LowImage,
        onImagesChanged: (arg_5) => {
            dispatch(new Types_Msg(6, arg_5));
        },
        onDeleteImage: (_arg3) => {
            dispatch(new Types_Msg(9));
        },
        needDeleteButton: true,
        flag: false,
        dependencies: [equals(state.EditInformation, new Deferred$1(1))],
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_126))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_128))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_15)),
    });
}

export function View_Render(view_RenderInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(view_RenderInputProps.id), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const matchValue = state_1.IsAuctionLoaded;
    let pattern_matching_result, ex;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            ex = matchValue.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return loader("big");
        }
        case 1: {
            return createElement("h1", {
                children: [ex],
            });
        }
        case 2: {
            return react.createElement(react.Fragment, {}, View_renderPrioritySettings(state_1, dispatch), View_renderErrors(state_1, dispatch), View_renderButtons(state_1, dispatch));
        }
    }
}

