import { Union, Record } from "../../fable_modules/fable-library.3.6.1/Types.js";
import { Agreement_AgreementTerms, Agreement_AgreementTerms$reflection } from "../../Shared/Shared.js";
import { record_type, bool_type, list_type, union_type, string_type, unit_type, class_type } from "../../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../../Extensions.js";
import { singleton as singleton_2, append as append_1, empty as empty_1, isEmpty, ofArray, fold } from "../../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { equals, createObj } from "../../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { isEmpty as isEmpty_1, empty } from "../../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { agreementApi } from "../../Communication.js";
import { t } from "../../Localization.js";
import { Agreement_getFieldError, Agreement_validate } from "../../Validation.js";
import { Currency_currencySymbol, Currency_decimalDigits, Currency_round } from "../../Common.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { dividedTopLight } from "../BorderDividedLine.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_2, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library.3.6.1/Seq.js";
import { toNumber } from "../../fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "../../fable_modules/fable-library.3.6.1/Decimal.js";
import { defaultArg } from "../../fable_modules/fable-library.3.6.1/Option.js";
import { NumberFormatModule_numberFormat } from "../InputDecimal.js";
import { CurrencyModule_fromDecimal, CurrencyModule_toDecimal } from "../../bidflow/src/infrastructure/Auction.js";
import { Disabled, Placeholder, ClassName, OnChange, custom, DateFormat, Value, flatpickr } from "../../fable_modules/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { fromDateTimeOffset } from "../../fable_modules/fable-library.3.6.1/Date.js";
import { fromDate } from "../../fable_modules/fable-library.3.6.1/DateOffset.js";

export class State extends Record {
    constructor(AgreementTerms, OriginalAgreementTerms, AgreementId, SaveFormOperation, FormErrors, ValidateOnChange, IsNotEditTermsMode) {
        super();
        this.AgreementTerms = AgreementTerms;
        this.OriginalAgreementTerms = OriginalAgreementTerms;
        this.AgreementId = AgreementId;
        this.SaveFormOperation = SaveFormOperation;
        this.FormErrors = FormErrors;
        this.ValidateOnChange = ValidateOnChange;
        this.IsNotEditTermsMode = IsNotEditTermsMode;
    }
}

export function State$reflection() {
    return record_type("Components.EditTerms.State", [], State, () => [["AgreementTerms", Agreement_AgreementTerms$reflection()], ["OriginalAgreementTerms", Agreement_AgreementTerms$reflection()], ["AgreementId", class_type("System.Int64")], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["ValidateOnChange", bool_type], ["IsNotEditTermsMode", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SwitchEditTerms", "CancelChangesTerms", "SalesProvisionPercentChanged", "SalesProvisionSEKChanged", "PhotoFeeChanged", "CitesFeeChanged", "RecyclingFeeChanged", "TransportFeeChanged", "AdministrativeFeeChanged", "HammerFeeChanged", "StartDateChanged", "EndDateChanged", "ModifyAgreementTerms"];
    }
}

export function Msg$reflection() {
    return union_type("Components.EditTerms.Msg", [], Msg, () => [[], [], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.DateTimeOffset")]], [["Item", class_type("System.DateTimeOffset")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function ErrorsHelpBlock(errors) {
    const props = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]);
    return createElement("p", createObj(Helpers_combineClasses("help", props)));
}

export function withTerms(terms, state) {
    return new State(terms, state.OriginalAgreementTerms, state.AgreementId, state.SaveFormOperation, state.FormErrors, state.ValidateOnChange, state.IsNotEditTermsMode);
}

export function init(terms, agreementId) {
    return [new State(terms, terms, agreementId, new Deferred$1(0), empty(), false, true), Cmd_none()];
}

export function saveTerms(agreementId, terms) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(agreementApi().modifyAgreementTerms([agreementId, terms]), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Msg(12, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.agreementDoesNotExist"))))) : singleton.Return(new Msg(12, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Msg(12, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function withFormAndErrors(form, errors, state) {
    return new State(form, state.OriginalAgreementTerms, state.AgreementId, state.SaveFormOperation, errors, state.ValidateOnChange, state.IsNotEditTermsMode);
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9;
    const withValidatedForm = (arg) => {
        let tupledArg;
        const terms_1 = arg;
        if (state.ValidateOnChange) {
            const matchValue = Agreement_validate(terms_1);
            tupledArg = ((matchValue.tag === 1) ? [terms_1, matchValue.fields[0]] : [matchValue.fields[0], empty()]);
        }
        else {
            tupledArg = [terms_1, empty()];
        }
        return (state_1) => withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 1) {
        return [new State(state.OriginalAgreementTerms, state.OriginalAgreementTerms, state.AgreementId, state.SaveFormOperation, empty(), false, true), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm((inputRecord = state.AgreementTerms, new Agreement_AgreementTerms(msg.fields[0], inputRecord.SalesProvisionSEK, inputRecord.PhotoFee, inputRecord.CitesFee, inputRecord.RecyclingFee, inputRecord.TransportFee, inputRecord.AdministrativeFee, inputRecord.HammerFee, inputRecord.StartDate, inputRecord.EndDate)))(state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm((inputRecord_1 = state.AgreementTerms, new Agreement_AgreementTerms(inputRecord_1.SalesProvision, Currency_round(msg.fields[0]), inputRecord_1.PhotoFee, inputRecord_1.CitesFee, inputRecord_1.RecyclingFee, inputRecord_1.TransportFee, inputRecord_1.AdministrativeFee, inputRecord_1.HammerFee, inputRecord_1.StartDate, inputRecord_1.EndDate)))(state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [withValidatedForm((inputRecord_2 = state.AgreementTerms, new Agreement_AgreementTerms(inputRecord_2.SalesProvision, inputRecord_2.SalesProvisionSEK, Currency_round(msg.fields[0]), inputRecord_2.CitesFee, inputRecord_2.RecyclingFee, inputRecord_2.TransportFee, inputRecord_2.AdministrativeFee, inputRecord_2.HammerFee, inputRecord_2.StartDate, inputRecord_2.EndDate)))(state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [withValidatedForm((inputRecord_3 = state.AgreementTerms, new Agreement_AgreementTerms(inputRecord_3.SalesProvision, inputRecord_3.SalesProvisionSEK, inputRecord_3.PhotoFee, Currency_round(msg.fields[0]), inputRecord_3.RecyclingFee, inputRecord_3.TransportFee, inputRecord_3.AdministrativeFee, inputRecord_3.HammerFee, inputRecord_3.StartDate, inputRecord_3.EndDate)))(state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [withValidatedForm((inputRecord_4 = state.AgreementTerms, new Agreement_AgreementTerms(inputRecord_4.SalesProvision, inputRecord_4.SalesProvisionSEK, inputRecord_4.PhotoFee, inputRecord_4.CitesFee, Currency_round(msg.fields[0]), inputRecord_4.TransportFee, inputRecord_4.AdministrativeFee, inputRecord_4.HammerFee, inputRecord_4.StartDate, inputRecord_4.EndDate)))(state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [withValidatedForm((inputRecord_5 = state.AgreementTerms, new Agreement_AgreementTerms(inputRecord_5.SalesProvision, inputRecord_5.SalesProvisionSEK, inputRecord_5.PhotoFee, inputRecord_5.CitesFee, inputRecord_5.RecyclingFee, Currency_round(msg.fields[0]), inputRecord_5.AdministrativeFee, inputRecord_5.HammerFee, inputRecord_5.StartDate, inputRecord_5.EndDate)))(state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withValidatedForm((inputRecord_6 = state.AgreementTerms, new Agreement_AgreementTerms(inputRecord_6.SalesProvision, inputRecord_6.SalesProvisionSEK, inputRecord_6.PhotoFee, inputRecord_6.CitesFee, inputRecord_6.RecyclingFee, inputRecord_6.TransportFee, inputRecord_6.AdministrativeFee, Currency_round(msg.fields[0]), inputRecord_6.StartDate, inputRecord_6.EndDate)))(state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [withValidatedForm((inputRecord_7 = state.AgreementTerms, new Agreement_AgreementTerms(inputRecord_7.SalesProvision, inputRecord_7.SalesProvisionSEK, inputRecord_7.PhotoFee, inputRecord_7.CitesFee, inputRecord_7.RecyclingFee, inputRecord_7.TransportFee, Currency_round(msg.fields[0]), inputRecord_7.HammerFee, inputRecord_7.StartDate, inputRecord_7.EndDate)))(state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [withTerms((inputRecord_8 = state.AgreementTerms, new Agreement_AgreementTerms(inputRecord_8.SalesProvision, inputRecord_8.SalesProvisionSEK, inputRecord_8.PhotoFee, inputRecord_8.CitesFee, inputRecord_8.RecyclingFee, inputRecord_8.TransportFee, inputRecord_8.AdministrativeFee, inputRecord_8.HammerFee, msg.fields[0], inputRecord_8.EndDate)), state), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [withTerms((inputRecord_9 = state.AgreementTerms, new Agreement_AgreementTerms(inputRecord_9.SalesProvision, inputRecord_9.SalesProvisionSEK, inputRecord_9.PhotoFee, inputRecord_9.CitesFee, inputRecord_9.RecyclingFee, inputRecord_9.TransportFee, inputRecord_9.AdministrativeFee, inputRecord_9.HammerFee, inputRecord_9.StartDate, msg.fields[0])), state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 0) {
                return [new State(state.AgreementTerms, state.AgreementTerms, state.AgreementId, new Deferred$1(2, new FSharpResult$2(0, void 0)), state.FormErrors, state.ValidateOnChange, true), Cmd_none()];
            }
            else {
                return [new State(state.AgreementTerms, state.OriginalAgreementTerms, state.AgreementId, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.ValidateOnChange, state.IsNotEditTermsMode), Cmd_none()];
            }
        }
        else {
            const matchValue_1 = Agreement_validate(state.AgreementTerms);
            if (matchValue_1.tag === 1) {
                return [new State(state.AgreementTerms, state.OriginalAgreementTerms, state.AgreementId, new Deferred$1(0), matchValue_1.fields[0], true, state.IsNotEditTermsMode), Cmd_none()];
            }
            else {
                return [new State(state.AgreementTerms, state.OriginalAgreementTerms, state.AgreementId, new Deferred$1(1), empty(), false, state.IsNotEditTermsMode), Cmd_fromAsync(saveTerms(state.AgreementId, state.AgreementTerms))];
            }
        }
    }
    else {
        return [new State(state.AgreementTerms, state.OriginalAgreementTerms, state.AgreementId, state.SaveFormOperation, state.FormErrors, state.ValidateOnChange, false), Cmd_none()];
    }
}

export function Terms(termsInputProps) {
    let props, props_115, elms, elms_3, elms_6, elms_9, elms_14, props_102, elms_11, props_98, props_96, elms_10, children_14, props_111, elms_13, props_107, props_105, elms_12, props_125;
    const agreementId = termsInputProps.agreementId;
    const terms = termsInputProps.terms;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(terms, agreementId), (msg, state) => update(msg, state), [terms, agreementId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isDisabled = state_1.IsNotEditTermsMode ? true : equals(state_1.SaveFormOperation, new Deferred$1(1));
    const props_127 = ofArray([dividedTopLight, ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-weight-bold"], ["className", "mb-3"], ["children", t("users.submissionTerms")]]), createElement("p", createObj(Helpers_combineClasses("", props)))), (props_115 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms = ofArray([createElement("div", createObj(toList(delay(() => {
        const errors = Agreement_getFieldError(state_1.FormErrors, "SalesProvision");
        return append(isEmpty(errors) ? singleton_1(["className", "pb-5"]) : singleton_1(["className", "pb-2"]), delay(() => {
            let props_2, props_11, props_5, props_4, props_9;
            return singleton_1(["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.salesCommission")]]), createElement("label", createObj(Helpers_combineClasses("label", props_2)))), (props_11 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(singleton_1(["ref", (_arg1) => {
            }]), delay(() => append((!equals(errors, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["disabled", isDisabled]), delay(() => append(singleton_1(["placeholder", "25"]), delay(() => append(singleton_1(["value", toNumber(state_1.AgreementTerms.SalesProvision)]), delay(() => singleton_1(["onValueChange", (x) => {
                dispatch(new Msg(2, new Decimal(defaultArg(x.floatValue, 0))));
            }]))))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_4, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_5)))), (props_9 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["children", "%"]]))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_11)))), ErrorsHelpBlock(errors)])]);
        }));
    })))), createElement("div", createObj(toList(delay(() => {
        let props_14, props_23, props_17, props_16, props_21, props_19;
        const errors_1 = Agreement_getFieldError(state_1.FormErrors, "SalesProvisionSEK");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.minimumSalesCommission")]]), createElement("label", createObj(Helpers_combineClasses("label", props_14)))), (props_23 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_17 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_16 = toList(delay(() => append(singleton_1(["ref", (_arg2) => {
        }]), delay(() => append((!equals(errors_1, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["disabled", isDisabled]), delay(() => append(singleton_1(["decimalScale", Currency_decimalDigits()]), delay(() => append(singleton_1(["value", toNumber(CurrencyModule_toDecimal(state_1.AgreementTerms.SalesProvisionSEK))]), delay(() => singleton_1(["onValueChange", (x_2) => {
            dispatch(new Msg(3, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_2.floatValue, 0)))));
        }]))))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_16, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_17)))), (props_21 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_19))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_23)))), ErrorsHelpBlock(errors_1)])]);
    }))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_3 = ofArray([createElement("div", createObj(toList(delay(() => {
        const errors_2 = Agreement_getFieldError(state_1.FormErrors, "TransportFee");
        return append(isEmpty(errors_2) ? singleton_1(["className", "pb-5"]) : singleton_1(["className", "pb-2"]), delay(() => {
            let props_27, props_35, props_30, props_29, elms_1, props_32;
            return singleton_1(["children", Interop_reactApi.Children.toArray([(props_27 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.transport")]]), createElement("label", createObj(Helpers_combineClasses("label", props_27)))), (props_35 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_30 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_29 = toList(delay(() => append((!equals(errors_2, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["disabled", isDisabled]), delay(() => append(singleton_1(["decimalScale", Currency_decimalDigits()]), delay(() => append(singleton_1(["value", toNumber(CurrencyModule_toDecimal(state_1.AgreementTerms.TransportFee))]), delay(() => singleton_1(["onValueChange", (x_4) => {
                dispatch(new Msg(7, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_4.floatValue, 0)))));
            }]))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_29, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_30)))), (elms_1 = singleton_2((props_32 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_32))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_35)))), ErrorsHelpBlock(errors_2)])]);
        }));
    })))), createElement("div", createObj(toList(delay(() => {
        let props_38, props_46, props_41, props_40, elms_2, props_43;
        const errors_3 = Agreement_getFieldError(state_1.FormErrors, "HammerFee");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_38 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.hammerFee")]]), createElement("label", createObj(Helpers_combineClasses("label", props_38)))), (props_46 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_41 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_40 = toList(delay(() => append(singleton_1(["ref", (_arg3) => {
        }]), delay(() => append((!equals(errors_3, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["disabled", isDisabled]), delay(() => append(singleton_1(["placeholder", "0"]), delay(() => append(singleton_1(["decimalScale", Currency_decimalDigits()]), delay(() => append(singleton_1(["value", toNumber(CurrencyModule_toDecimal(state_1.AgreementTerms.HammerFee))]), delay(() => singleton_1(["onValueChange", (x_6) => {
            dispatch(new Msg(9, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_6.floatValue, 0)))));
        }]))))))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_40, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_41)))), (elms_2 = singleton_2((props_43 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_43))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_46)))), ErrorsHelpBlock(errors_3)])]);
    }))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_6 = ofArray([createElement("div", createObj(toList(delay(() => {
        const errors_4 = Agreement_getFieldError(state_1.FormErrors, "PhotoFee");
        return append(isEmpty(errors_4) ? singleton_1(["className", "pb-5"]) : singleton_1(["className", "pb-2"]), delay(() => {
            let props_50, props_58, props_53, props_52, elms_4, props_55;
            return singleton_1(["children", Interop_reactApi.Children.toArray([(props_50 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.photoFee")]]), createElement("label", createObj(Helpers_combineClasses("label", props_50)))), (props_58 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_53 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_52 = toList(delay(() => append(singleton_1(["ref", (_arg4) => {
            }]), delay(() => append((!equals(errors_4, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["disabled", isDisabled]), delay(() => append(singleton_1(["placeholder", "28"]), delay(() => append(singleton_1(["decimalScale", Currency_decimalDigits()]), delay(() => append(singleton_1(["value", toNumber(CurrencyModule_toDecimal(state_1.AgreementTerms.PhotoFee))]), delay(() => singleton_1(["onValueChange", (x_8) => {
                dispatch(new Msg(4, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_8.floatValue, 0)))));
            }]))))))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_52, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_53)))), (elms_4 = singleton_2((props_55 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_55))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_58)))), ErrorsHelpBlock(errors_4)])]);
        }));
    })))), createElement("div", createObj(toList(delay(() => {
        let props_61, props_69, props_64, props_63, elms_5, props_66;
        const errors_5 = Agreement_getFieldError(state_1.FormErrors, "AdministrativeFee");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_61 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.administrativeFee")]]), createElement("label", createObj(Helpers_combineClasses("label", props_61)))), (props_69 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_64 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_63 = toList(delay(() => append(singleton_1(["ref", (_arg5) => {
        }]), delay(() => append((!equals(errors_5, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["disabled", isDisabled]), delay(() => append(singleton_1(["placeholder", "29"]), delay(() => append(singleton_1(["decimalScale", Currency_decimalDigits()]), delay(() => append(singleton_1(["value", toNumber(CurrencyModule_toDecimal(state_1.AgreementTerms.AdministrativeFee))]), delay(() => singleton_1(["onValueChange", (x_10) => {
            dispatch(new Msg(8, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_10.floatValue, 0)))));
        }]))))))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_63, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_64)))), (elms_5 = singleton_2((props_66 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_66))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_69)))), ErrorsHelpBlock(errors_5)])]);
    }))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    })), (elms_9 = ofArray([createElement("div", createObj(toList(delay(() => {
        const errors_6 = Agreement_getFieldError(state_1.FormErrors, "CitesFee");
        return append(isEmpty(errors_6) ? singleton_1(["className", "pb-5"]) : singleton_1(["className", "pb-2"]), delay(() => {
            let props_73, props_81, props_76, props_75, elms_7, props_78;
            return singleton_1(["children", Interop_reactApi.Children.toArray([(props_73 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.citesFee")]]), createElement("label", createObj(Helpers_combineClasses("label", props_73)))), (props_81 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_76 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_75 = toList(delay(() => append(singleton_1(["ref", (_arg6) => {
            }]), delay(() => append((!equals(errors_6, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["disabled", isDisabled]), delay(() => append(singleton_1(["placeholder", "0"]), delay(() => append(singleton_1(["decimalScale", Currency_decimalDigits()]), delay(() => append(singleton_1(["onValueChange", (x_12) => {
                dispatch(new Msg(5, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_12.floatValue, 0)))));
            }]), delay(() => singleton_1(["value", toNumber(CurrencyModule_toDecimal(state_1.AgreementTerms.CitesFee))]))))))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_75, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_76)))), (elms_7 = singleton_2((props_78 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_78))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_81)))), ErrorsHelpBlock(errors_6)])]);
        }));
    })))), createElement("div", createObj(toList(delay(() => {
        let props_84, props_92, props_87, props_86, elms_8, props_89;
        const errors_7 = Agreement_getFieldError(state_1.FormErrors, "RecyclingFee");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_84 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.recyclingFee")]]), createElement("label", createObj(Helpers_combineClasses("label", props_84)))), (props_92 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_87 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_86 = toList(delay(() => append(singleton_1(["ref", (_arg7) => {
        }]), delay(() => append((!equals(errors_7, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["disabled", isDisabled]), delay(() => append(singleton_1(["placeholder", "0"]), delay(() => append(singleton_1(["decimalScale", Currency_decimalDigits()]), delay(() => append(singleton_1(["value", toNumber(CurrencyModule_toDecimal(state_1.AgreementTerms.RecyclingFee))]), delay(() => singleton_1(["onValueChange", (x_14) => {
            dispatch(new Msg(6, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_14.floatValue, 0)))));
        }]))))))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_86, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_87)))), (elms_8 = singleton_2((props_89 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_89))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_92)))), ErrorsHelpBlock(errors_7)])]);
    }))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    })), (elms_14 = ofArray([createElement("div", {
        className: "pb-5",
        children: Interop_reactApi.Children.toArray([(props_102 = ofArray([["ref", (_arg8) => {
        }], ["children", Interop_reactApi.Children.toArray([(elms_11 = ofArray([(props_98 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_96 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.startDate")]]), createElement("label", createObj(Helpers_combineClasses("label", props_96))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_98)))), (elms_10 = singleton_2(flatpickr(ofArray([Value(fromDateTimeOffset(state_1.AgreementTerms.StartDate, 0)), DateFormat("d M Y"), custom("locale", {
            firstDayOfWeek: 1,
        }, true), OnChange((x_15) => {
            dispatch(new Msg(10, fromDate(x_15)));
        }), ClassName("input"), Placeholder("12 May 2021"), Disabled(isDisabled)]))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_102)))), ErrorsHelpBlock(empty_1())]),
    }), (children_14 = singleton_2((props_111 = ofArray([["ref", (_arg9) => {
    }], ["children", Interop_reactApi.Children.toArray([(elms_13 = ofArray([(props_107 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_105 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.endDate")]]), createElement("label", createObj(Helpers_combineClasses("label", props_105))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_107)))), (elms_12 = singleton_2(flatpickr(ofArray([Value(fromDateTimeOffset(state_1.AgreementTerms.EndDate, 0)), DateFormat("d M Y"), custom("locale", {
        firstDayOfWeek: 1,
    }, true), OnChange((x_16) => {
        dispatch(new Msg(11, fromDate(x_16)));
    }), ClassName("input"), Placeholder("2 November 2021"), Disabled(isDisabled)]))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
    }))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_111))))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_14)),
    }))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_115)))), (props_125 = ofArray([["className", "has-text-right"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_117, props_123, props_119, props_121;
        return state_1.IsNotEditTermsMode ? singleton_1((props_117 = ofArray([["className", "has-background-grey-lighter"], ["children", t("users.editTerms")], ["onClick", (_arg10) => {
            dispatch(new Msg(0));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_117))))) : singleton_1((props_123 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_119 = toList(delay(() => append(singleton_1(["className", "mb-3"]), delay(() => append(singleton_1(["children", t("common.cancel")]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-static"]) : empty_2(), delay(() => singleton_1(["onClick", (_arg11) => {
            dispatch(new Msg(1));
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_119)))), (props_121 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => append(singleton_1(["disabled", equals(state_1.AgreementTerms, state_1.OriginalAgreementTerms) ? true : (!isEmpty_1(state_1.FormErrors))]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_2(), delay(() => singleton_1(["onClick", (x_17) => {
            x_17.preventDefault();
            dispatch(new Msg(12, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_121))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_123)))));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_125))))])]]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_127)));
}

