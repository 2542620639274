import { Autocomplete, Props, TextTranslations, FoundResult, Props_get_Default } from "./Autocomplete.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { getPrimaryLanguage } from "../Localization.js";
import { KeyValuePair_create, Remoting_handleNonAuth } from "../Extensions.js";
import { itemApi } from "../Communication.js";
import { sort, map } from "../fable_modules/fable-library.3.6.1/List.js";
import { LangModule_toString } from "../bidflow/src/infrastructure/Localization.js";
import { comparePrimitives } from "../fable_modules/fable-library.3.6.1/Util.js";
import { toInt } from "../fable_modules/fable-library.3.6.1/Long.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { iterate } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { defaultArg, toArray } from "../fable_modules/fable-library.3.6.1/Option.js";
import { createElement } from "react";

export function SearchItemName(searchItemNameInputProps) {
    let inputRecord_1;
    const isStartSearchOnInit = searchItemNameInputProps.isStartSearchOnInit;
    const isAutofocus = searchItemNameInputProps.isAutofocus;
    const dependencies = searchItemNameInputProps.dependencies;
    const onInputRef = searchItemNameInputProps.onInputRef;
    const onChange = searchItemNameInputProps.onChange;
    const onSelect = searchItemNameInputProps.onSelect;
    const selected = searchItemNameInputProps.selected;
    let ps;
    const inputRecord = Props_get_Default();
    ps = (new Props(500, (s) => singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const lang = getPrimaryLanguage();
        return singleton.Bind(Remoting_handleNonAuth(itemApi().search([lang, s])), (_arg1) => {
            const result = _arg1;
            const items = map((title) => KeyValuePair_create([title, LangModule_toString(lang), ""], title), sort(result.Result, {
                Compare: (x, y) => comparePrimitives(x, y),
            }));
            return singleton.Return(new FSharpResult$2(0, new FoundResult(s, ~(~toInt(result.Total)), items)));
        });
    }), (_arg2) => singleton.Return(new FSharpResult$2(1, _arg2.message)))), (option_1) => {
        iterate((tupledArg) => {
            onSelect(tupledArg[0]);
        }, toArray(option_1));
    }, onChange, false, defaultArg(selected, ""), isAutofocus, onInputRef, (inputRecord_1 = Props_get_Default().Localization, new TextTranslations("", inputRecord_1.StartTypeToSearch, inputRecord_1.NoItemsFound, inputRecord_1.FoundItems)), isStartSearchOnInit, 3, false));
    return createElement(Autocomplete, {
        props: ps,
        dependencies: dependencies,
    });
}

