import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { Agreement_PayoutAccountType, Settings_Submission_AgreementTerms, Agreement_PayoutAccountType$reflection, Settings_Submission_AgreementTerms$reflection } from "./Shared/Shared.js";
import { record_type, union_type, unit_type, bool_type, class_type, list_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_handleNonAuth, Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi } from "./Communication.js";
import { FSharpMap__get_IsEmpty, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Settings_Submission_AgreementTerms_validate } from "./Validation.js";
import { int32ToString, createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { createElement } from "react";
import { empty as empty_2, append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Currency_currencySymbol, Currency_decimalDigits, Form_getFieldError } from "./Common.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { cons, map, fold, ofArray, append as append_1, empty as empty_1, singleton as singleton_2 } from "./fable_modules/fable-library.3.6.1/List.js";
import { toNumber } from "./fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.6.1/Decimal.js";
import { defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { NumberFormatModule_numberFormat } from "./Components/InputDecimal.js";
import { t } from "./Localization.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { CurrencyModule_fromDecimal, CurrencyModule_toDecimal, PercentModule_fromDecimal, PercentModule_toDecimal } from "./bidflow/src/infrastructure/Auction.js";
import { isCurrentUserAdmin, isCurrentUserManager } from "./LocalStorage.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";

export class Types_State extends Record {
    constructor(AgreementTerms, OriginalAgreementTerms, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.AgreementTerms = AgreementTerms;
        this.OriginalAgreementTerms = OriginalAgreementTerms;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function Types_State$reflection() {
    return record_type("Client.SettingsSubmission.Types.State", [], Types_State, () => [["AgreementTerms", Settings_Submission_AgreementTerms$reflection()], ["OriginalAgreementTerms", Settings_Submission_AgreementTerms$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SalesProvisionChanged", "SalesProvisionSEKChanged", "PhotoFeeChanged", "CitesFeeChanged", "RecyclingFeeChanged", "TransportFeeChanged", "AdministrativeFeeChanged", "HammerFeeChanged", "IsCorporateTaxFreeChanged", "PayoutAccountTypeChanged", "CancelEdit", "SaveForm"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.SettingsSubmission.Types.Msg", [], Types_Msg, () => [[["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", bool_type]], [["Item", Agreement_PayoutAccountType$reflection()]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_saveForm(agreementTerms) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(settingsApi().saveSubmissionAgreementTerms(agreementTerms), () => singleton.Return(new Types_Msg(11, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))), (_arg2) => singleton.Return(new Types_Msg(11, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(agreementTerms) {
    return [new Types_State(agreementTerms, agreementTerms, empty(), true, new Deferred$1(0)), Cmd_none()];
}

export function State_withFormAndErrors(form, errors, state) {
    return new Types_State(form, state.OriginalAgreementTerms, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function State_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord;
    const withValidatedForm = (arg) => {
        let tupledArg;
        const terms_1 = arg;
        if (state.NeedToValidate) {
            const matchValue = Settings_Submission_AgreementTerms_validate(terms_1);
            tupledArg = ((matchValue.tag === 1) ? [terms_1, matchValue.fields[0]] : [matchValue.fields[0], empty()]);
        }
        else {
            tupledArg = [terms_1, empty()];
        }
        return (state_1) => State_withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 1) {
        return [withValidatedForm((inputRecord_1 = state.AgreementTerms, new Settings_Submission_AgreementTerms(inputRecord_1.SalesProvision, msg.fields[0], inputRecord_1.PhotoFee, inputRecord_1.CitesFee, inputRecord_1.RecyclingFee, inputRecord_1.TransportFee, inputRecord_1.AdministrativeFee, inputRecord_1.HammerFee, inputRecord_1.PayoutAccount, inputRecord_1.IsCorporateTaxFree)))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm((inputRecord_2 = state.AgreementTerms, new Settings_Submission_AgreementTerms(inputRecord_2.SalesProvision, inputRecord_2.SalesProvisionSEK, msg.fields[0], inputRecord_2.CitesFee, inputRecord_2.RecyclingFee, inputRecord_2.TransportFee, inputRecord_2.AdministrativeFee, inputRecord_2.HammerFee, inputRecord_2.PayoutAccount, inputRecord_2.IsCorporateTaxFree)))(state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm((inputRecord_3 = state.AgreementTerms, new Settings_Submission_AgreementTerms(inputRecord_3.SalesProvision, inputRecord_3.SalesProvisionSEK, inputRecord_3.PhotoFee, msg.fields[0], inputRecord_3.RecyclingFee, inputRecord_3.TransportFee, inputRecord_3.AdministrativeFee, inputRecord_3.HammerFee, inputRecord_3.PayoutAccount, inputRecord_3.IsCorporateTaxFree)))(state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [withValidatedForm((inputRecord_4 = state.AgreementTerms, new Settings_Submission_AgreementTerms(inputRecord_4.SalesProvision, inputRecord_4.SalesProvisionSEK, inputRecord_4.PhotoFee, inputRecord_4.CitesFee, inputRecord_4.RecyclingFee, msg.fields[0], inputRecord_4.AdministrativeFee, inputRecord_4.HammerFee, inputRecord_4.PayoutAccount, inputRecord_4.IsCorporateTaxFree)))(state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [withValidatedForm((inputRecord_5 = state.AgreementTerms, new Settings_Submission_AgreementTerms(inputRecord_5.SalesProvision, inputRecord_5.SalesProvisionSEK, inputRecord_5.PhotoFee, inputRecord_5.CitesFee, inputRecord_5.RecyclingFee, inputRecord_5.TransportFee, msg.fields[0], inputRecord_5.HammerFee, inputRecord_5.PayoutAccount, inputRecord_5.IsCorporateTaxFree)))(state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [withValidatedForm((inputRecord_6 = state.AgreementTerms, new Settings_Submission_AgreementTerms(inputRecord_6.SalesProvision, inputRecord_6.SalesProvisionSEK, inputRecord_6.PhotoFee, inputRecord_6.CitesFee, msg.fields[0], inputRecord_6.TransportFee, inputRecord_6.AdministrativeFee, inputRecord_6.HammerFee, inputRecord_6.PayoutAccount, inputRecord_6.IsCorporateTaxFree)))(state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [withValidatedForm((inputRecord_7 = state.AgreementTerms, new Settings_Submission_AgreementTerms(inputRecord_7.SalesProvision, inputRecord_7.SalesProvisionSEK, inputRecord_7.PhotoFee, inputRecord_7.CitesFee, inputRecord_7.RecyclingFee, inputRecord_7.TransportFee, inputRecord_7.AdministrativeFee, msg.fields[0], inputRecord_7.PayoutAccount, inputRecord_7.IsCorporateTaxFree)))(state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withValidatedForm((inputRecord_8 = state.AgreementTerms, new Settings_Submission_AgreementTerms(inputRecord_8.SalesProvision, inputRecord_8.SalesProvisionSEK, inputRecord_8.PhotoFee, inputRecord_8.CitesFee, inputRecord_8.RecyclingFee, inputRecord_8.TransportFee, inputRecord_8.AdministrativeFee, inputRecord_8.HammerFee, msg.fields[0], inputRecord_8.IsCorporateTaxFree)))(state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [withValidatedForm((inputRecord_9 = state.AgreementTerms, new Settings_Submission_AgreementTerms(inputRecord_9.SalesProvision, inputRecord_9.SalesProvisionSEK, inputRecord_9.PhotoFee, inputRecord_9.CitesFee, inputRecord_9.RecyclingFee, inputRecord_9.TransportFee, inputRecord_9.AdministrativeFee, inputRecord_9.HammerFee, inputRecord_9.PayoutAccount, msg.fields[0])))(state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [new Types_State(state.OriginalAgreementTerms, state.OriginalAgreementTerms, empty(), false, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 11) {
        if (msg.fields[0].tag === 1) {
            return [new Types_State(state.AgreementTerms, state.AgreementTerms, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.AgreementTerms, state.OriginalAgreementTerms)) {
            const matchValue_1 = Settings_Submission_AgreementTerms_validate(state.AgreementTerms);
            if (matchValue_1.tag === 1) {
                return [new Types_State(state.AgreementTerms, state.OriginalAgreementTerms, matchValue_1.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new Types_State(state.AgreementTerms, state.OriginalAgreementTerms, empty(), true, new Deferred$1(1)), Cmd_fromAsync(Cmd_saveForm(state.AgreementTerms))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [withValidatedForm((inputRecord = state.AgreementTerms, new Settings_Submission_AgreementTerms(msg.fields[0], inputRecord.SalesProvisionSEK, inputRecord.PhotoFee, inputRecord.CitesFee, inputRecord.RecyclingFee, inputRecord.TransportFee, inputRecord.AdministrativeFee, inputRecord.HammerFee, inputRecord.PayoutAccount, inputRecord.IsCorporateTaxFree)))(state), Cmd_none()];
    }
}

export function View_renderField(decimalDigits, prefix, label, value, onChange, state, formErrors) {
    return createElement("div", createObj(toList(delay(() => {
        let props_9, props_3, props_2, props_7, props_11;
        const errors = Form_getFieldError(state.FormErrors, formErrors);
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_2(["children", label])))), (props_9 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => append((!equals(errors, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["placeholder", label]), delay(() => append(singleton_1(["value", toNumber(value)]), delay(() => append(singleton_1(["onValueChange", (x) => {
            onChange(new Decimal(defaultArg(x.floatValue, 0)));
        }]), delay(() => {
            if (decimalDigits == null) {
                return empty_2();
            }
            else {
                return singleton_1(["decimalScale", decimalDigits]);
            }
        })))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_2, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_3)))), (props_7 = ofArray([["className", "pr-2"], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["children", prefix]]))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_9)))), (props_11 = ofArray([["className", "is-danger"], ["children", fold((s, x_2) => (`${s} ${x_2}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_11))))])]);
    }))));
}

export function View_renderPayoutAccount(state, dispatch) {
    let elms_1, props_2, props, elms, patternInput, value_17;
    const toValue = (_arg1) => {
        if (_arg1.tag === 1) {
            return 2;
        }
        else {
            return 1;
        }
    };
    const props_11 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.payoutAccountType")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_2((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], (value_17 = (toValue(state.AgreementTerms.PayoutAccount) | 0), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_17)) : false) {
            e.value = (value_17 | 0);
        }
    }]), ["onChange", (ev) => {
        let _arg2;
        dispatch(new Types_Msg(9, (_arg2 = ev.target.value, (_arg2 === "1") ? (new Agreement_PayoutAccountType(0)) : ((_arg2 === "2") ? (new Agreement_PayoutAccountType(1)) : (() => {
            throw (new Error("Can\u0027t find payout account type"));
        })()))));
    }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x) => {
        let copyOfStruct;
        return createElement("option", {
            value: (copyOfStruct = toValue(x), int32ToString(copyOfStruct)),
            children: (x.tag === 1) ? t("users.IBAN") : t("users.residentBank"),
        });
    }, ofArray([new Agreement_PayoutAccountType(0), new Agreement_PayoutAccountType(1)]))))]])), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_11)));
}

export function View_render(state, dispatch) {
    let props_1, s_3, props_19, props_16, props_14;
    const isFormSaving = equals(state.SaveFormOperation, new Deferred$1(1));
    const needDisableSaving = (isFormSaving ? true : (!(!equals(state.AgreementTerms, state.OriginalAgreementTerms)))) ? true : (!FSharpMap__get_IsEmpty(state.FormErrors));
    const elms = ofArray([(props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s_3 = t("common.submission"), createElement("h1", {
        className: "title",
        children: s_3,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1)))), createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_19 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
            disabled: equals(state.SaveFormOperation, new Deferred$1(1)),
            children: Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-half"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let value_14;
                let renderFieldCurrency;
                const decimalDigits_1 = Currency_decimalDigits();
                const prefix_1 = Currency_currencySymbol();
                renderFieldCurrency = ((label_1) => ((value_15) => ((onChange_1) => ((state_2) => ((formErrors_1) => View_renderField(decimalDigits_1, prefix_1, label_1, value_15, onChange_1, state_2, formErrors_1))))));
                return append(singleton_1((value_14 = PercentModule_toDecimal(state.AgreementTerms.SalesProvision), View_renderField(void 0, "%", t("common.salesCommission"), value_14, (arg_1) => {
                    dispatch(new Types_Msg(0, PercentModule_fromDecimal(arg_1)));
                }, state, "SalesProvision"))), delay(() => append(singleton_1(renderFieldCurrency(t("common.minimumSalesCommission"))(CurrencyModule_toDecimal(state.AgreementTerms.SalesProvisionSEK))((arg_3) => {
                    dispatch(new Types_Msg(1, CurrencyModule_fromDecimal(arg_3)));
                })(state)("SalesProvisionSEK")), delay(() => append(singleton_1(renderFieldCurrency(t("common.photoFee"))(CurrencyModule_toDecimal(state.AgreementTerms.PhotoFee))((arg_5) => {
                    dispatch(new Types_Msg(2, CurrencyModule_fromDecimal(arg_5)));
                })(state)("PhotoFee")), delay(() => append(singleton_1(renderFieldCurrency(t("common.citesFee"))(CurrencyModule_toDecimal(state.AgreementTerms.CitesFee))((arg_7) => {
                    dispatch(new Types_Msg(3, CurrencyModule_fromDecimal(arg_7)));
                })(state)("CitesFee")), delay(() => append(singleton_1(renderFieldCurrency(t("common.transport"))(CurrencyModule_toDecimal(state.AgreementTerms.TransportFee))((arg_9) => {
                    dispatch(new Types_Msg(5, CurrencyModule_fromDecimal(arg_9)));
                })(state)("TransportFee")), delay(() => append(singleton_1(renderFieldCurrency(t("common.administrativeFee"))(CurrencyModule_toDecimal(state.AgreementTerms.AdministrativeFee))((arg_11) => {
                    dispatch(new Types_Msg(6, CurrencyModule_fromDecimal(arg_11)));
                })(state)("AdministrativeFee")), delay(() => append(singleton_1(renderFieldCurrency(t("common.recyclingFee"))(CurrencyModule_toDecimal(state.AgreementTerms.RecyclingFee))((arg_13) => {
                    dispatch(new Types_Msg(4, CurrencyModule_fromDecimal(arg_13)));
                })(state)("RecyclingFee")), delay(() => append(singleton_1(renderFieldCurrency(t("common.hammerFee"))(CurrencyModule_toDecimal(state.AgreementTerms.HammerFee))((arg_15) => {
                    dispatch(new Types_Msg(7, CurrencyModule_fromDecimal(arg_15)));
                })(state)("HammerFee")), delay(() => append(singleton_1(View_renderPayoutAccount(state, dispatch)), delay(() => {
                    let children, props_5;
                    return append(singleton_1((children = singleton_2((props_5 = ofArray([["className", "pb-3"], ["style", {
                        justifyContent: "center",
                    }], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                        disabled: equals(state.SaveFormOperation, new Deferred$1(1)),
                        className: join(" ", ["is-checkradio"]),
                        id: "checkboxCorporateTaxFree",
                        type: "checkbox",
                        name: "checkboxCorporateTaxFree",
                        onChange: (ev) => {
                            const _arg1 = ev.target.checked;
                            dispatch(new Types_Msg(8, !state.AgreementTerms.IsCorporateTaxFree));
                        },
                        checked: state.AgreementTerms.IsCorporateTaxFree,
                    }), createElement("label", {
                        htmlFor: "checkboxCorporateTaxFree",
                        children: t("auction.disableCorporateTax"),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("field", props_5))))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))), delay(() => {
                        let props_12, props_8, props_10;
                        return singleton_1((props_12 = ofArray([["className", "mt-6"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_8 = toList(delay(() => append(singleton_1(["className", "is-dark"]), delay(() => append(isFormSaving ? singleton_1(["disabled", true]) : empty_2(), delay(() => append(singleton_1(["children", t("common.cancel")]), delay(() => singleton_1(["onClick", (x) => {
                            x.preventDefault();
                            dispatch(new Types_Msg(10));
                        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_8)))), (props_10 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(needDisableSaving ? singleton_1(["disabled", true]) : empty_2(), delay(() => append(isFormSaving ? singleton_1(["className", "is-loading"]) : empty_2(), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (x_1) => {
                            x_1.preventDefault();
                            dispatch(new Types_Msg(11, new AsyncOperationStatus$1(0)));
                        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_12)))));
                    }));
                }))))))))))))))))));
            }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_16))))]),
        })])]), createElement("section", createObj(Helpers_combineClasses("section", props_19))))]),
    })]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function Component_InstanceWith(agreementTerms) {
    if (isCurrentUserManager() ? true : isCurrentUserAdmin()) {
        const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(agreementTerms), (msg, state) => State_update(msg, state), []);
        return View_render(patternInput[0], patternInput[1]);
    }
    else {
        return null;
    }
}

export function Component_Instance() {
    if (isCurrentUserAdmin()) {
        const getAgreementTermsDeferred = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getSubmissionAgreementTerms()), []);
        switch (getAgreementTermsDeferred.tag) {
            case 1: {
                return loader("big");
            }
            case 3: {
                return getAgreementTermsDeferred.fields[0].message;
            }
            case 2: {
                return createElement(Component_InstanceWith, getAgreementTermsDeferred.fields[0]);
            }
            default: {
                return loader("big");
            }
        }
    }
    else {
        return null;
    }
}

