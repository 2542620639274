import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { submissionsApi } from "../Communication.js";
import { interpolate, toText } from "../fable_modules/fable-library.3.6.1/String.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { singleton as singleton_2, cons, ofArray, empty as empty_1 } from "../fable_modules/fable-library.3.6.1/List.js";
import { SubmissionIdModule_tryParse } from "../bidflow/src/infrastructure/Auction.js";
import { t } from "../Localization.js";

export function CreateSubmissionWithId(createSubmissionWithIdInputProps) {
    const onCreated = createSubmissionWithIdInputProps.onCreated;
    const onClose = createSubmissionWithIdInputProps.onClose;
    const sellerId = createSubmissionWithIdInputProps.sellerId;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const toggleState = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const submissionId = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_2[0];
    const createSubmission = singleton.Delay(() => {
        let submissionId_1;
        return (submissionId == null) ? singleton.Return() : singleton.Bind((submissionId_1 = submissionId, singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(submissionsApi().createAgreementAndSubmissionWithId([sellerId, submissionId_1])), (_arg3) => {
            let message, _arg2;
            return (_arg3.tag === 1) ? singleton.Return((message = ((_arg2 = _arg3.fields[0], (_arg2.tag === 0) ? toText(interpolate("Submission with given Id = %A%P() already created.", [_arg2.fields[0]])) : (`Unknown error happened. Id = ${_arg2.fields[0]}`))), (() => {
                throw (new Error(message));
            })())) : singleton.Return(_arg3.fields[0]);
        }))), (_arg4) => {
            toggleState(false);
            onClose();
            return singleton.Return(onCreated(_arg4));
        });
    });
    const startCreateSubmission = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => createSubmission, patternInput_2[1]);
    const isLoading = Deferred_inProgress(data);
    const canNotCreate = submissionId == null;
    const props_19 = toList(delay(() => append(patternInput[0] ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
        let elms_3, elms_2, elms_1, elms, props, props_13, props_9, props_11;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (elms_3 = singleton_2((elms_2 = ofArray([(elms_1 = ofArray([createElement("label", {
            className: "label",
            children: "Inlämning #",
        }), (elms = singleton_2(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Inlämning #"], ["onChange", (ev) => {
            patternInput_1[1](SubmissionIdModule_tryParse(ev.target.value));
        }], ["autoFocus", true]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (data.tag === 3) ? ((props = ofArray([["className", "is-danger"], ["children", data.fields[0].message]]), createElement("p", createObj(Helpers_combineClasses("help", props))))) : null, (props_13 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["children", t("common.cancel")], ["onClick", (_arg1_2) => {
            toggleState(false);
            onClose();
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_9)))), (props_11 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(isLoading ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Ny Inlämning"]), delay(() => append(singleton_1(["disabled", canNotCreate]), delay(() => singleton_1(["onClick", (_arg1_3) => {
            startCreateSubmission();
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_11))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_13))))]), createElement("div", {
            className: "box",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), createElement("div", {
            className: "modal-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), createElement("button", createObj(Helpers_combineClasses("modal-close", singleton_2(["onClick", (_arg1_4) => {
            toggleState(false);
            onClose();
        }]))))])]);
    }))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_19)));
}

