import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { User_SystemUser, Errors_ServerErrorModule_explain, Errors_ServerError, User_RegisterManager, Errors_ServerError$reflection, User_RegisterManager$reflection } from "../Shared/Shared.js";
import { record_type, union_type, bool_type, class_type, list_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { EmailModule_toString, EmailModule_ofString, EmailModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi } from "../Communication.js";
import { RegisterManager_validate } from "../Validation.js";
import { toPath, Route, navigateTo } from "../Router.js";
import { singleton as singleton_2, cons, isEmpty, ofArray, fold } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "../Localization.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Form_getFieldError } from "../Common.js";
import { dividedTop } from "../Components/BorderDividedLine.js";

export class State extends Record {
    constructor(Form, FormErrors, NeedToValidate, Registration) {
        super();
        this.Form = Form;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.Registration = Registration;
    }
}

export function State$reflection() {
    return record_type("Bidflow.BO.Client.Users.Manager.Register.State", [], State, () => [["Form", User_RegisterManager$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["Registration", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", Errors_ServerError$reflection()]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailChanged", "PasswordChanged", "PasswordConfirmationChanged", "FirstNameChanged", "LastNameChanged", "RegisterAccount"];
    }
}

export function Msg$reflection() {
    return union_type("Bidflow.BO.Client.Users.Manager.Register.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", Errors_ServerError$reflection()]]]))]]]);
}

export function init() {
    return [new State(new User_RegisterManager(EmailModule_empty, "", "", "", ""), empty(), false, new Deferred$1(0)), Cmd_none()];
}

export function register(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(usersApi().registerManager(state.Form)), (_arg1) => {
        const result = _arg1;
        return (result.tag === 1) ? singleton.Return(new Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))) : singleton.Return(new Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(0, result.fields[0]))));
    })), (_arg2) => singleton.Return(new Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message)))))));
}

export function withForm(form, state) {
    return new State(form, state.FormErrors, state.NeedToValidate, state.Registration);
}

export function withFormAndErrors(form, errors, state) {
    return new State(form, errors, state.NeedToValidate, state.Registration);
}

export function update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord;
    const withValidatedForm = (arg) => {
        let tupledArg;
        const admin_1 = arg;
        if (state.NeedToValidate) {
            const matchValue = RegisterManager_validate(admin_1);
            tupledArg = ((matchValue.tag === 1) ? [admin_1, matchValue.fields[0]] : [matchValue.fields[0], empty()]);
        }
        else {
            tupledArg = [admin_1, empty()];
        }
        return (state_1) => withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 1) {
        return [withValidatedForm((inputRecord_1 = state.Form, new User_RegisterManager(inputRecord_1.Email, msg.fields[0], inputRecord_1.PasswordConfirmation, inputRecord_1.FirstName, inputRecord_1.LastName)))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm((inputRecord_2 = state.Form, new User_RegisterManager(inputRecord_2.Email, inputRecord_2.Password, msg.fields[0], inputRecord_2.FirstName, inputRecord_2.LastName)))(state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm((inputRecord_3 = state.Form, new User_RegisterManager(inputRecord_3.Email, inputRecord_3.Password, inputRecord_3.PasswordConfirmation, msg.fields[0], inputRecord_3.LastName)))(state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [withValidatedForm((inputRecord_4 = state.Form, new User_RegisterManager(inputRecord_4.Email, inputRecord_4.Password, inputRecord_4.PasswordConfirmation, inputRecord_4.FirstName, msg.fields[0])))(state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.Form, state.FormErrors, state.NeedToValidate, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new State(state.Form, state.FormErrors, state.NeedToValidate, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), navigateTo(new Route(10, msg.fields[0].fields[0].fields[0]))];
            }
        }
        else {
            const matchValue_1 = RegisterManager_validate(state.Form);
            if (matchValue_1.tag === 1) {
                return [new State(state.Form, matchValue_1.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new State(state.Form, empty(), true, new Deferred$1(1)), Cmd_fromAsync(register(state))];
            }
        }
    }
    else {
        return [withValidatedForm((inputRecord = state.Form, new User_RegisterManager(msg.fields[0], inputRecord.Password, inputRecord.PasswordConfirmation, inputRecord.FirstName, inputRecord.LastName)))(state), Cmd_none()];
    }
}

export function ErrorsHelpBlock(errors) {
    const props = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]);
    return createElement("p", createObj(Helpers_combineClasses("help", props)));
}

export function renderError(state) {
    const matchValue = state.Registration;
    let pattern_matching_result, error;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = ofArray([["className", "has-text-centered"], ["className", "has-background-danger"], ["className", "has-text-white"], ["children", Errors_ServerErrorModule_explain(error)]]);
            return createElement("div", createObj(Helpers_combineClasses("box", props)));
        }
        case 1: {
            return null;
        }
    }
}

export function Render() {
    let elms_15, elms_14, props_4, props, props_2, props_68, props_18, props_16, props_14, props_42, elms_4, props_28, elms_7, props_39, props_66, elms_10, props_52, elms_13, props_63, props_84, props_82, elms_16, props_79, props_75, props_77;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(init, (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const inProgress = equals(state_1.Registration, new Deferred$1(1));
    const children_17 = ofArray([createElement("fieldset", {
        disabled: inProgress,
        children: Interop_reactApi.Children.toArray([(elms_15 = singleton_2((elms_14 = ofArray([(props_4 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.general")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props)))), (props_2 = ofArray([["style", {
            fontSize: 0.7 + "rem",
        }], ["className", "has-text-grey-light"], ["children", t("users.generalInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_68 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(props_18 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-half"], ["children", Interop_reactApi.Children.toArray([(props_14 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_1, props_8, props_6, elms, props_10;
            const errors = Form_getFieldError(state_1.FormErrors, "Email");
            return singleton_1((elms_1 = ofArray([(props_8 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.email")]]), createElement("label", createObj(Helpers_combineClasses("label", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_8)))), (elms = singleton_2((props_10 = toList(delay(() => append((!isEmpty(errors)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev) => {
                dispatch(new Msg(0, EmailModule_ofString(ev.target.value)));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterEmail")]), delay(() => {
                let value_44;
                return singleton_1((value_44 = EmailModule_toString(state_1.Form.Email), ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_44)) : false) {
                        e.value = value_44;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_10)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), ErrorsHelpBlock(errors)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_18)))), (props_42 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_4 = singleton_2((props_28 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_3, props_22, props_20, elms_2, props_24;
            const errors_1 = Form_getFieldError(state_1.FormErrors, "Password");
            return singleton_1((elms_3 = ofArray([(props_22 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.password")]]), createElement("label", createObj(Helpers_combineClasses("label", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_22)))), (elms_2 = singleton_2((props_24 = toList(delay(() => append((!isEmpty(errors_1)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_1) => {
                dispatch(new Msg(1, ev_1.target.value));
            }]), delay(() => {
                let value_71;
                return singleton_1((value_71 = state_1.Form.Password, ["ref", (e_1) => {
                    if ((!(e_1 == null)) ? (!equals(e_1.value, value_71)) : false) {
                        e_1.value = value_71;
                    }
                }]));
            })))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_24)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            })), ErrorsHelpBlock(errors_1)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_28))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        })), (elms_7 = singleton_2((props_39 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_6, props_33, props_31, elms_5, props_35;
            const errors_2 = Form_getFieldError(state_1.FormErrors, "PasswordConfirmation");
            return singleton_1((elms_6 = ofArray([(props_33 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.confirmPassword")]]), createElement("label", createObj(Helpers_combineClasses("label", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_33)))), (elms_5 = singleton_2((props_35 = toList(delay(() => append((!isEmpty(errors_2)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_2) => {
                dispatch(new Msg(2, ev_2.target.value));
            }]), delay(() => {
                let value_99;
                return singleton_1((value_99 = state_1.Form.PasswordConfirmation, ["ref", (e_2) => {
                    if ((!(e_2 == null)) ? (!equals(e_2.value, value_99)) : false) {
                        e_2.value = value_99;
                    }
                }]));
            })))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_35)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            })), ErrorsHelpBlock(errors_2)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_39))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_42)))), (props_66 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_10 = singleton_2((props_52 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_9, props_46, props_44, elms_8, props_48;
            const errors_3 = Form_getFieldError(state_1.FormErrors, "FirstName");
            return singleton_1((elms_9 = ofArray([(props_46 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_44 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.firstName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_44))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_46)))), (elms_8 = singleton_2((props_48 = toList(delay(() => append((!isEmpty(errors_3)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_3) => {
                dispatch(new Msg(3, ev_3.target.value));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterFirstName")]), delay(() => {
                let value_130;
                return singleton_1((value_130 = state_1.Form.FirstName, ["ref", (e_3) => {
                    if ((!(e_3 == null)) ? (!equals(e_3.value, value_130)) : false) {
                        e_3.value = value_130;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_48)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
            })), ErrorsHelpBlock(errors_3)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_52))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
        })), (elms_13 = singleton_2((props_63 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_12, props_57, props_55, elms_11, props_59;
            const errors_4 = Form_getFieldError(state_1.FormErrors, "LastName");
            return singleton_1((elms_12 = ofArray([(props_57 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_55 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.lastName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_55))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_57)))), (elms_11 = singleton_2((props_59 = toList(delay(() => append((!isEmpty(errors_4)) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_4) => {
                dispatch(new Msg(4, ev_4.target.value));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterLastName")]), delay(() => {
                let value_160;
                return singleton_1((value_160 = state_1.Form.LastName, ["ref", (e_4) => {
                    if ((!(e_4 == null)) ? (!equals(e_4.value, value_160)) : false) {
                        e_4.value = value_160;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_59)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
            })), ErrorsHelpBlock(errors_4)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_63))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_66))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_68))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
        }))), createElement("section", {
            className: "section",
            children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
        }))]),
    }), (props_84 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(props_82 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-one-quarter"])))), (elms_16 = singleton_2((props_79 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_75 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["href", toPath(new Route(7, 1, new User_SystemUser(0), void 0))]]), createElement("a", createObj(Helpers_combineClasses("button", props_75)))), (props_77 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", t("common.create")]), delay(() => append(inProgress ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => singleton_1(["onClick", (_arg1) => {
        dispatch(new Msg(5, new AsyncOperationStatus$1(0)));
    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_77))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_79))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_82)))), renderError(state_1)])]]), createElement("section", createObj(Helpers_combineClasses("section", props_84))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_17)),
    });
}

