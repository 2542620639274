import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { usersApi } from "./Communication.js";
import { loader } from "./Loader.js";
import { createElement } from "react";
import { Render as Render_1 } from "./Users/EditPerson.js";
import { Render as Render_2 } from "./Users/EditCompany.js";
import { Render as Render_3 } from "./Users/EditAdmin.js";
import { Render as Render_4 } from "./Users/EditManager.js";

export function Render(renderInputProps) {
    const userId = renderInputProps.userId;
    const user = useFeliz_React__React_useDeferred_Static_2344FC52(usersApi().getUser(userId), [userId]);
    let pattern_matching_result, ex, user_1, company, admin, manager;
    if (user.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (user.tag === 3) {
        pattern_matching_result = 1;
        ex = user.fields[0];
    }
    else if (user.tag === 2) {
        if (user.fields[0].tag === 1) {
            pattern_matching_result = 3;
            company = user.fields[0].fields[0];
        }
        else if (user.fields[0].tag === 2) {
            pattern_matching_result = 4;
            admin = user.fields[0].fields[0];
        }
        else if (user.fields[0].tag === 3) {
            pattern_matching_result = 5;
            manager = user.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
            user_1 = user.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return loader("big");
        }
        case 1: {
            return ex.message;
        }
        case 2: {
            return createElement(Render_1, user_1);
        }
        case 3: {
            return createElement(Render_2, company);
        }
        case 4: {
            return createElement(Render_3, admin);
        }
        case 5: {
            return createElement(Render_4, manager);
        }
    }
}

