import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { Settings_Email_Settings, Settings_Email_Settings$reflection } from "./Shared/Shared.js";
import { int32_type, record_type, union_type, unit_type, bool_type, class_type, list_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_handleNonAuth, Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi } from "./Communication.js";
import { FSharpMap__get_IsEmpty, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Settings_Email_validate } from "./Validation.js";
import { int32ToString, createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { createElement } from "react";
import { empty as empty_2, append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Form_getFieldError } from "./Common.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { fold, ofArray, cons, empty as empty_1, singleton as singleton_2 } from "./fable_modules/fable-library.3.6.1/List.js";
import { join, isNullOrWhiteSpace } from "./fable_modules/fable-library.3.6.1/String.js";
import { parse } from "./fable_modules/fable-library.3.6.1/Int32.js";
import { t } from "./Localization.js";
import { isCurrentUserAdmin, isCurrentUserManager } from "./LocalStorage.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";

export class Types_State extends Record {
    constructor(Settings, OriginalSettings, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.Settings = Settings;
        this.OriginalSettings = OriginalSettings;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function Types_State$reflection() {
    return record_type("Client.SettingsEmail.Types.State", [], Types_State, () => [["Settings", Settings_Email_Settings$reflection()], ["OriginalSettings", Settings_Email_Settings$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PresaleReportDelayChanged", "IsPresaleReportEnabledChanged", "PreliminaryOrderResultReportDelayChanged", "IsPreliminaryOrderResultReportEnabledChanged", "IsFinalOrderResultReportEnabledChanged", "IsAuctionStartSoonNotificationEnabledChanged", "AuctionStartSoonNotificationDelayChanged", "IsUserAccountVerifiedNotificationEnabledChanged", "IsUnpaidOrderNotificationEnabledChanged", "UnpaidOrderNotificationDelayChanged", "IsNewUserRegisteredNotificationEnabledChanged", "IsAuctionWinnerEmailNotificationEnabledChanged", "CancelEdit", "SaveForm"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.SettingsEmail.Types.Msg", [], Types_Msg, () => [[["Item", int32_type]], [["Item", bool_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_saveForm(settings) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(settingsApi().saveEmailSettings(settings), () => singleton.Return(new Types_Msg(13, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))), (_arg2) => singleton.Return(new Types_Msg(13, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(settings) {
    return [new Types_State(settings, settings, empty(), true, new Deferred$1(0)), Cmd_none()];
}

export function State_withFormAndErrors(form, errors, state) {
    return new Types_State(form, state.OriginalSettings, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function State_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord;
    const withValidatedForm = (arg) => {
        let tupledArg;
        const settings_1 = arg;
        if (state.NeedToValidate) {
            const matchValue = Settings_Email_validate(settings_1);
            tupledArg = ((matchValue.tag === 1) ? [settings_1, matchValue.fields[0]] : [matchValue.fields[0], empty()]);
        }
        else {
            tupledArg = [settings_1, empty()];
        }
        return (state_1) => State_withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 1) {
        return [withValidatedForm((inputRecord_1 = state.Settings, new Settings_Email_Settings(msg.fields[0], inputRecord_1.PresaleReportDelayInHours, inputRecord_1.IsPreliminaryOrderResultReportEnabled, inputRecord_1.PreliminaryOrderResultReportDelayInHours, inputRecord_1.IsFinalOrderResultReportEnabled, inputRecord_1.IsAuctionStartSoonNotificationEnabled, inputRecord_1.AuctionStartSoonNotificationDelayInMinutes, inputRecord_1.IsUserAccountVerifiedNotificationEnabled, inputRecord_1.IsUnpaidOrderNotificationEnabled, inputRecord_1.UnpaidOrderNotificationDelayInDays, inputRecord_1.IsNewUserRegisteredNotificationEnabled, inputRecord_1.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm((inputRecord_2 = state.Settings, new Settings_Email_Settings(inputRecord_2.IsPresaleReportEnabled, inputRecord_2.PresaleReportDelayInHours, inputRecord_2.IsPreliminaryOrderResultReportEnabled, msg.fields[0], inputRecord_2.IsFinalOrderResultReportEnabled, inputRecord_2.IsAuctionStartSoonNotificationEnabled, inputRecord_2.AuctionStartSoonNotificationDelayInMinutes, inputRecord_2.IsUserAccountVerifiedNotificationEnabled, inputRecord_2.IsUnpaidOrderNotificationEnabled, inputRecord_2.UnpaidOrderNotificationDelayInDays, inputRecord_2.IsNewUserRegisteredNotificationEnabled, inputRecord_2.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm((inputRecord_3 = state.Settings, new Settings_Email_Settings(inputRecord_3.IsPresaleReportEnabled, inputRecord_3.PresaleReportDelayInHours, msg.fields[0], inputRecord_3.PreliminaryOrderResultReportDelayInHours, inputRecord_3.IsFinalOrderResultReportEnabled, inputRecord_3.IsAuctionStartSoonNotificationEnabled, inputRecord_3.AuctionStartSoonNotificationDelayInMinutes, inputRecord_3.IsUserAccountVerifiedNotificationEnabled, inputRecord_3.IsUnpaidOrderNotificationEnabled, inputRecord_3.UnpaidOrderNotificationDelayInDays, inputRecord_3.IsNewUserRegisteredNotificationEnabled, inputRecord_3.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [withValidatedForm((inputRecord_4 = state.Settings, new Settings_Email_Settings(inputRecord_4.IsPresaleReportEnabled, inputRecord_4.PresaleReportDelayInHours, inputRecord_4.IsPreliminaryOrderResultReportEnabled, inputRecord_4.PreliminaryOrderResultReportDelayInHours, msg.fields[0], inputRecord_4.IsAuctionStartSoonNotificationEnabled, inputRecord_4.AuctionStartSoonNotificationDelayInMinutes, inputRecord_4.IsUserAccountVerifiedNotificationEnabled, inputRecord_4.IsUnpaidOrderNotificationEnabled, inputRecord_4.UnpaidOrderNotificationDelayInDays, inputRecord_4.IsNewUserRegisteredNotificationEnabled, inputRecord_4.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [withValidatedForm((inputRecord_5 = state.Settings, new Settings_Email_Settings(inputRecord_5.IsPresaleReportEnabled, inputRecord_5.PresaleReportDelayInHours, inputRecord_5.IsPreliminaryOrderResultReportEnabled, inputRecord_5.PreliminaryOrderResultReportDelayInHours, inputRecord_5.IsFinalOrderResultReportEnabled, inputRecord_5.IsAuctionStartSoonNotificationEnabled, msg.fields[0], inputRecord_5.IsUserAccountVerifiedNotificationEnabled, inputRecord_5.IsUnpaidOrderNotificationEnabled, inputRecord_5.UnpaidOrderNotificationDelayInDays, inputRecord_5.IsNewUserRegisteredNotificationEnabled, inputRecord_5.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [withValidatedForm((inputRecord_6 = state.Settings, new Settings_Email_Settings(inputRecord_6.IsPresaleReportEnabled, inputRecord_6.PresaleReportDelayInHours, inputRecord_6.IsPreliminaryOrderResultReportEnabled, inputRecord_6.PreliminaryOrderResultReportDelayInHours, inputRecord_6.IsFinalOrderResultReportEnabled, msg.fields[0], inputRecord_6.AuctionStartSoonNotificationDelayInMinutes, inputRecord_6.IsUserAccountVerifiedNotificationEnabled, inputRecord_6.IsUnpaidOrderNotificationEnabled, inputRecord_6.UnpaidOrderNotificationDelayInDays, inputRecord_6.IsNewUserRegisteredNotificationEnabled, inputRecord_6.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [withValidatedForm((inputRecord_7 = state.Settings, new Settings_Email_Settings(inputRecord_7.IsPresaleReportEnabled, inputRecord_7.PresaleReportDelayInHours, inputRecord_7.IsPreliminaryOrderResultReportEnabled, inputRecord_7.PreliminaryOrderResultReportDelayInHours, inputRecord_7.IsFinalOrderResultReportEnabled, inputRecord_7.IsAuctionStartSoonNotificationEnabled, inputRecord_7.AuctionStartSoonNotificationDelayInMinutes, msg.fields[0], inputRecord_7.IsUnpaidOrderNotificationEnabled, inputRecord_7.UnpaidOrderNotificationDelayInDays, inputRecord_7.IsNewUserRegisteredNotificationEnabled, inputRecord_7.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [withValidatedForm((inputRecord_8 = state.Settings, new Settings_Email_Settings(inputRecord_8.IsPresaleReportEnabled, inputRecord_8.PresaleReportDelayInHours, inputRecord_8.IsPreliminaryOrderResultReportEnabled, inputRecord_8.PreliminaryOrderResultReportDelayInHours, inputRecord_8.IsFinalOrderResultReportEnabled, inputRecord_8.IsAuctionStartSoonNotificationEnabled, inputRecord_8.AuctionStartSoonNotificationDelayInMinutes, inputRecord_8.IsUserAccountVerifiedNotificationEnabled, msg.fields[0], inputRecord_8.UnpaidOrderNotificationDelayInDays, inputRecord_8.IsNewUserRegisteredNotificationEnabled, inputRecord_8.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withValidatedForm((inputRecord_9 = state.Settings, new Settings_Email_Settings(inputRecord_9.IsPresaleReportEnabled, inputRecord_9.PresaleReportDelayInHours, inputRecord_9.IsPreliminaryOrderResultReportEnabled, inputRecord_9.PreliminaryOrderResultReportDelayInHours, inputRecord_9.IsFinalOrderResultReportEnabled, inputRecord_9.IsAuctionStartSoonNotificationEnabled, inputRecord_9.AuctionStartSoonNotificationDelayInMinutes, inputRecord_9.IsUserAccountVerifiedNotificationEnabled, inputRecord_9.IsUnpaidOrderNotificationEnabled, msg.fields[0], inputRecord_9.IsNewUserRegisteredNotificationEnabled, inputRecord_9.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [new Types_State(state.OriginalSettings, state.OriginalSettings, empty(), false, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [withValidatedForm((inputRecord_10 = state.Settings, new Settings_Email_Settings(inputRecord_10.IsPresaleReportEnabled, inputRecord_10.PresaleReportDelayInHours, inputRecord_10.IsPreliminaryOrderResultReportEnabled, inputRecord_10.PreliminaryOrderResultReportDelayInHours, inputRecord_10.IsFinalOrderResultReportEnabled, inputRecord_10.IsAuctionStartSoonNotificationEnabled, inputRecord_10.AuctionStartSoonNotificationDelayInMinutes, inputRecord_10.IsUserAccountVerifiedNotificationEnabled, inputRecord_10.IsUnpaidOrderNotificationEnabled, inputRecord_10.UnpaidOrderNotificationDelayInDays, msg.fields[0], inputRecord_10.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [withValidatedForm((inputRecord_11 = state.Settings, new Settings_Email_Settings(inputRecord_11.IsPresaleReportEnabled, inputRecord_11.PresaleReportDelayInHours, inputRecord_11.IsPreliminaryOrderResultReportEnabled, inputRecord_11.PreliminaryOrderResultReportDelayInHours, inputRecord_11.IsFinalOrderResultReportEnabled, inputRecord_11.IsAuctionStartSoonNotificationEnabled, inputRecord_11.AuctionStartSoonNotificationDelayInMinutes, inputRecord_11.IsUserAccountVerifiedNotificationEnabled, inputRecord_11.IsUnpaidOrderNotificationEnabled, inputRecord_11.UnpaidOrderNotificationDelayInDays, inputRecord_11.IsNewUserRegisteredNotificationEnabled, msg.fields[0])))(state), Cmd_none()];
    }
    else if (msg.tag === 13) {
        if (msg.fields[0].tag === 1) {
            return [new Types_State(state.Settings, state.Settings, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.Settings, state.OriginalSettings)) {
            const matchValue_1 = Settings_Email_validate(state.Settings);
            if (matchValue_1.tag === 1) {
                return [new Types_State(state.Settings, state.OriginalSettings, matchValue_1.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new Types_State(state.Settings, state.OriginalSettings, empty(), true, new Deferred$1(1)), Cmd_fromAsync(Cmd_saveForm(state.Settings))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [withValidatedForm((inputRecord = state.Settings, new Settings_Email_Settings(inputRecord.IsPresaleReportEnabled, msg.fields[0], inputRecord.IsPreliminaryOrderResultReportEnabled, inputRecord.PreliminaryOrderResultReportDelayInHours, inputRecord.IsFinalOrderResultReportEnabled, inputRecord.IsAuctionStartSoonNotificationEnabled, inputRecord.AuctionStartSoonNotificationDelayInMinutes, inputRecord.IsUserAccountVerifiedNotificationEnabled, inputRecord.IsUnpaidOrderNotificationEnabled, inputRecord.UnpaidOrderNotificationDelayInDays, inputRecord.IsNewUserRegisteredNotificationEnabled, inputRecord.IsAuctionWinnerEmailNotificationEnabled)))(state), Cmd_none()];
    }
}

function View_renderInputField(label, unit, value, onChange, state, formErrors) {
    return createElement("div", createObj(toList(delay(() => {
        let props_10, props_4, props_2, props_8, props_12;
        const errors = Form_getFieldError(state.FormErrors, formErrors);
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_2(["children", label])))), (props_10 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => append((!equals(errors, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev) => {
            onChange(ev.target.value);
        }]), delay(() => {
            let value_10;
            return append(singleton_1((value_10 = value, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_10)) : false) {
                    e.value = value_10;
                }
            }])), delay(() => (equals(state.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["disabled", true]) : empty_2())));
        })))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_2)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_4)))), (props_8 = ofArray([["className", "pr-2"], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["children", unit]]))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_10)))), (props_12 = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_12))))])]);
    }))));
}

function View_tryIntOrDefault(defaultValue, str) {
    if (isNullOrWhiteSpace(str)) {
        return 0;
    }
    else {
        try {
            return parse(str, 511, false, 32) | 0;
        }
        catch (matchValue) {
            return defaultValue | 0;
        }
    }
}

function View_renderCheckboxField(fieldId, label, isChecked, onChecked) {
    const props_3 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("input", {
        className: join(" ", ["is-checkradio"]),
        id: fieldId,
        type: "checkbox",
        name: fieldId,
        onChange: (ev) => {
            const _arg1 = ev.target.checked;
            onChecked(!isChecked);
        },
        checked: isChecked,
    }), createElement("label", createObj(Helpers_combineClasses("label", ofArray([["htmlFor", fieldId], ["children", label]]))))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_3)));
}

export function View_renderSettingsSectionTitle(title) {
    const props_2 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-weight-bold"], ["children", title]]))))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_2)));
}

function View_renderPresaleReportSettings(state, dispatch) {
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([View_renderSettingsSectionTitle(t("settings.emails.presaleReport.title")), View_renderCheckboxField("checkboxPresaleReportEnabled", t("settings.enabled"), state.Settings.IsPresaleReportEnabled, (arg) => {
            dispatch(new Types_Msg(1, arg));
        }), View_renderInputField(t("settings.emails.presaleReport.delay"), t("settings.timeSpans.hours"), int32ToString(state.Settings.PresaleReportDelayInHours), (arg_2) => {
            dispatch(new Types_Msg(0, View_tryIntOrDefault(state.Settings.PresaleReportDelayInHours, arg_2)));
        }, state, "PresaleReportDelay")]),
    });
}

function View_renderPreliminaryOrderResultReportSettings(state, dispatch) {
    return createElement("div", {
        className: "mt-5",
        children: Interop_reactApi.Children.toArray([View_renderSettingsSectionTitle(t("settings.emails.preliminaryOrderResultReport.title")), View_renderCheckboxField("checkboxPreliminaryOrderResultReportEnabled", t("settings.enabled"), state.Settings.IsPreliminaryOrderResultReportEnabled, (arg) => {
            dispatch(new Types_Msg(3, arg));
        }), View_renderInputField(t("settings.emails.preliminaryOrderResultReport.delay"), t("settings.timeSpans.hours"), int32ToString(state.Settings.PreliminaryOrderResultReportDelayInHours), (arg_2) => {
            dispatch(new Types_Msg(2, View_tryIntOrDefault(state.Settings.PreliminaryOrderResultReportDelayInHours, arg_2)));
        }, state, "PreliminaryOrderResultReportDelay")]),
    });
}

function View_renderAuctionStartSoonNotificationSettings(state, dispatch) {
    return createElement("div", {
        className: "mt-5",
        children: Interop_reactApi.Children.toArray([View_renderSettingsSectionTitle(t("settings.emails.auctionStartSoonNotification.title")), View_renderCheckboxField("checkboxAuctionStartSoonNotificationEnabled", t("settings.enabled"), state.Settings.IsAuctionStartSoonNotificationEnabled, (arg) => {
            dispatch(new Types_Msg(5, arg));
        }), View_renderInputField(t("settings.emails.auctionStartSoonNotification.delay"), t("settings.timeSpans.minutes"), int32ToString(state.Settings.AuctionStartSoonNotificationDelayInMinutes), (arg_2) => {
            dispatch(new Types_Msg(6, View_tryIntOrDefault(state.Settings.AuctionStartSoonNotificationDelayInMinutes, arg_2)));
        }, state, "AuctionStartSoonNotificationDelay")]),
    });
}

function View_renderUnpaidOrderNotificationSettings(state, dispatch) {
    return createElement("div", {
        className: "mt-5",
        children: Interop_reactApi.Children.toArray([View_renderSettingsSectionTitle(t("settings.emails.unpaidOrderNotification.title")), View_renderCheckboxField("checkboxUnpaidOrderNotificationEnabled", t("settings.enabled"), state.Settings.IsUnpaidOrderNotificationEnabled, (arg) => {
            dispatch(new Types_Msg(8, arg));
        }), View_renderInputField(t("settings.emails.unpaidOrderNotification.delay"), t("settings.timeSpans.days"), int32ToString(state.Settings.UnpaidOrderNotificationDelayInDays), (arg_2) => {
            dispatch(new Types_Msg(9, View_tryIntOrDefault(state.Settings.UnpaidOrderNotificationDelayInDays, arg_2)));
        }, state, "UnpaidOrderNotificationDelay")]),
    });
}

function View_renderFinalOrderResultReportSettings(state, dispatch) {
    return createElement("div", {
        className: "mt-5",
        children: Interop_reactApi.Children.toArray([View_renderSettingsSectionTitle(t("settings.emails.finalOrderResultReport.title")), View_renderCheckboxField("checkboxFinalOrderResultReportEnabled", t("settings.enabled"), state.Settings.IsFinalOrderResultReportEnabled, (arg) => {
            dispatch(new Types_Msg(4, arg));
        })]),
    });
}

function View_renderNewUserRegisteredNotificationSettings(state, dispatch) {
    return createElement("div", {
        className: "mt-5",
        children: Interop_reactApi.Children.toArray([View_renderSettingsSectionTitle(t("settings.emails.newUserRegisteredNotification.title")), View_renderCheckboxField("checkboxNewUserRegisteredNotificationEnabled", t("settings.enabled"), state.Settings.IsNewUserRegisteredNotificationEnabled, (arg) => {
            dispatch(new Types_Msg(10, arg));
        })]),
    });
}

function View_renderUserAccountVerifiedNotificationSettings(state, dispatch) {
    return createElement("div", {
        className: "mt-5",
        children: Interop_reactApi.Children.toArray([View_renderSettingsSectionTitle(t("settings.emails.userAccountVerifiedNotification.title")), View_renderCheckboxField("checkboxUserAccountVerifiedNotificationEnabled", t("settings.enabled"), state.Settings.IsUserAccountVerifiedNotificationEnabled, (arg) => {
            dispatch(new Types_Msg(7, arg));
        })]),
    });
}

function View_renderAuctionWinnerEmailNotificationSettings(state, dispatch) {
    return createElement("div", {
        className: "mt-5",
        children: Interop_reactApi.Children.toArray([View_renderSettingsSectionTitle(t("settings.emails.auctionWinnerEmailNotification.title")), View_renderCheckboxField("checkboxAuctionWinnerEmailNotificationEnabled", t("settings.enabled"), state.Settings.IsAuctionWinnerEmailNotificationEnabled, (arg) => {
            dispatch(new Types_Msg(11, arg));
        })]),
    });
}

export function View_render(state, dispatch) {
    let props_1, s, props_13, props_11, props_9, props_7, props_3, props_5;
    const isFormSaving = equals(state.SaveFormOperation, new Deferred$1(1));
    const needDisableSaving = (isFormSaving ? true : (!(!equals(state.Settings, state.OriginalSettings)))) ? true : (!FSharpMap__get_IsEmpty(state.FormErrors));
    const elms = ofArray([(props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("settings.emails.title"), createElement("h1", {
        className: "title",
        children: s,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1)))), createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_13 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-half"], ["children", Interop_reactApi.Children.toArray([View_renderPresaleReportSettings(state, dispatch), View_renderPreliminaryOrderResultReportSettings(state, dispatch), View_renderFinalOrderResultReportSettings(state, dispatch), View_renderAuctionStartSoonNotificationSettings(state, dispatch), View_renderNewUserRegisteredNotificationSettings(state, dispatch), View_renderUserAccountVerifiedNotificationSettings(state, dispatch), View_renderUnpaidOrderNotificationSettings(state, dispatch), View_renderAuctionWinnerEmailNotificationSettings(state, dispatch), (props_7 = ofArray([["className", "mt-6"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append(singleton_1(["className", "is-dark"]), delay(() => append(isFormSaving ? singleton_1(["disabled", true]) : empty_2(), delay(() => append(singleton_1(["children", t("common.cancel")]), delay(() => singleton_1(["onClick", (x) => {
            x.preventDefault();
            dispatch(new Types_Msg(12));
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_3)))), (props_5 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(needDisableSaving ? singleton_1(["disabled", true]) : empty_2(), delay(() => append(isFormSaving ? singleton_1(["className", "is-loading"]) : empty_2(), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new Types_Msg(13, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_5))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_11))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_13))))]),
    })]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function Component_InstanceWith(settings) {
    if (isCurrentUserManager() ? true : isCurrentUserAdmin()) {
        const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(settings), (msg, state) => State_update(msg, state), []);
        return View_render(patternInput[0], patternInput[1]);
    }
    else {
        return null;
    }
}

export function Component_Instance() {
    if (isCurrentUserAdmin()) {
        const getSettingsDeferred = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getEmailSettings()), []);
        switch (getSettingsDeferred.tag) {
            case 1: {
                return loader("big");
            }
            case 3: {
                return getSettingsDeferred.fields[0].message;
            }
            case 2: {
                return createElement(Component_InstanceWith, getSettingsDeferred.fields[0]);
            }
            default: {
                return loader("big");
            }
        }
    }
    else {
        return null;
    }
}

