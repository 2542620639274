import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { IncludedInPayout_SubmissionsByPayoutResult, IncludedInPayout_Submission__get_HasResidentBankInfoFormat, IncludedInPayout_Submission__get_OneTimeFee, IncludedInPayout_Submission$reflection, IncludedInPayout_SubmissionsByPayoutResult$reflection } from "../Shared/Payout.js";
import { SettingsPayout_ISOVersion, PayOuts_MarkAsPaidError, PayOuts_PayoutStatus, PayOuts_RemoveSubmissionFromPayoutRequest, SettingsPayout_SettingsPayoutParameters$reflection } from "../Shared/Shared.js";
import { unit_type, int32_type, class_type, union_type, record_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1, Deferred$1$reflection } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi, payOutApi } from "../Communication.js";
import { Cmd_OfFunc_result, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { format as format_1 } from "date-fns";
import { toLocalTime } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { tWithArgs, getPrimaryLanguage, t } from "../Localization.js";
import { createElement } from "react";
import * as react from "react";
import { filter as filter_1, sortBy, map, empty as empty_1, length, sumBy, singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { comparePrimitives, equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { LocalizedStringModule_getTranslationOrEmpty } from "../bidflow/src/infrastructure/Localization.js";
import { Currency_format, Currency_formatN } from "../Common.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { map as map_1, filter as filter_2, length as length_1, fold, empty, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Component_ConfirmationWithComment, Component_Props_get_Default, Component_Props, Feliz_ConfirmationWithCommentProperty } from "../Components/Confirmation.js";
import { op_Addition } from "../fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "../fable_modules/fable-library.3.6.1/Decimal.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { CurrencyModule_zero } from "../bidflow/src/infrastructure/Auction.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { ByteArrayExtensions_SaveFileAs_72B26259 } from "../fable_modules/Fable.Remoting.Client.7.10.0/Extensions.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loaderBig } from "../Loader.js";
import { backButtonHref } from "../Components/BackButton.js";
import { Route, toPath } from "../Router.js";

export class Types_PayoutInfo extends Record {
    constructor(Submissions, Settings) {
        super();
        this.Submissions = Submissions;
        this.Settings = Settings;
    }
}

export function Types_PayoutInfo$reflection() {
    return record_type("Client.Payout.Types.PayoutInfo", [], Types_PayoutInfo, () => [["Submissions", IncludedInPayout_SubmissionsByPayoutResult$reflection()], ["Settings", SettingsPayout_SettingsPayoutParameters$reflection()]]);
}

export class Types_Filter extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasResidentBankAccounts", "All"];
    }
}

export function Types_Filter$reflection() {
    return union_type("Client.Payout.Types.Filter", [], Types_Filter, () => [[], []]);
}

export class Types_State extends Record {
    constructor(PayoutId, Filter, Page, Content, RemoveFromPayoutStatus) {
        super();
        this.PayoutId = PayoutId;
        this.Filter = Filter;
        this.Page = (Page | 0);
        this.Content = Content;
        this.RemoveFromPayoutStatus = RemoveFromPayoutStatus;
    }
}

export function Types_State$reflection() {
    return record_type("Client.Payout.Types.State", [], Types_State, () => [["PayoutId", class_type("System.Int64")], ["Filter", Types_Filter$reflection()], ["Page", int32_type], ["Content", Deferred$1$reflection(Types_PayoutInfo$reflection())], ["RemoveFromPayoutStatus", Deferred$1$reflection(unit_type)]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PageChanged", "FilterChanged", "SubmissionLoaded", "SubmissionRemoved", "MarkedAsDone"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.Payout.Types.Msg", [], Types_Msg, () => [[["Item", int32_type]], [["Item", Types_Filter$reflection()]], [["Item", AsyncOperationStatus$1$reflection(Deferred$1$reflection(Types_PayoutInfo$reflection()))]], [["Item", IncludedInPayout_Submission$reflection()]], []]);
}

export function State_loadSubmissions(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(payOutApi().getSubmissionsByPayoutId(state.PayoutId)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getPayoutSettings()), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new Deferred$1(2, new Types_PayoutInfo(_arg1, _arg2)))))))), (_arg3) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new Deferred$1(3, _arg3))))));
}

export function State_init(payoutId) {
    const state = new Types_State(payoutId, new Types_Filter(1), 1, new Deferred$1(0), new Deferred$1(0));
    return [state, Cmd_fromAsync(State_loadSubmissions(state))];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        return [new Types_State(state.PayoutId, msg.fields[0], state.Page, state.Content, state.RemoveFromPayoutStatus), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 0) {
            return [new Types_State(state.PayoutId, state.Filter, state.Page, new Deferred$1(1), state.RemoveFromPayoutStatus), Cmd_fromAsync(State_loadSubmissions(state))];
        }
        else {
            return [new Types_State(state.PayoutId, state.Filter, state.Page, msg.fields[0].fields[0], state.RemoveFromPayoutStatus), Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        return [state, Cmd_OfFunc_result(new Types_Msg(2, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 4) {
        return [state, Cmd_OfFunc_result(new Types_Msg(2, new AsyncOperationStatus$1(0)))];
    }
    else {
        return [new Types_State(state.PayoutId, state.Filter, msg.fields[0], state.Content, state.RemoveFromPayoutStatus), Cmd_OfFunc_result(new Types_Msg(2, new AsyncOperationStatus$1(0)))];
    }
}

export function View_localDateAndTime(x) {
    return format_1(toLocalTime(x), "dd MMM yyyy HH:MM");
}

export const View_payoutLotHeader = (() => {
    let props_1, value_4, props_4, value_10, props_6, props_8, props_10, props_12, props_14, props_16;
    const elms = ofArray([(props_1 = ofArray([["className", "is-1"], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([(value_4 = t("common.lotNumber"), createElement("span", {
        children: [value_4],
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (props_4 = ofArray([["className", "is-1"], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([(value_10 = t("common.itemId"), createElement("span", {
        children: [value_10],
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-uppercase"], ["children", t("common.title")]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-1"], ["className", "is-uppercase"], ["className", "has-text-right"], ["children", t("orders.price")]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-1"], ["className", "is-uppercase"], ["className", "has-text-right"], ["children", t("orders.comission")]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-1"], ["className", "is-uppercase"], ["className", "has-text-right"], ["children", t("common.hammerFee")]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-1"], ["className", "is-uppercase"], ["className", "has-text-right"], ["children", t("common.photoFee")]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-1"], ["className", "is-uppercase"], ["className", "has-text-right"], ["children", t("reports.payout")]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))]);
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
})();

export function View_RenderPayoutLot(x) {
    let props_1, value_2, props_4, value_6, props_6, props_8, props_10, props_12, props_14, props_16;
    const elms = ofArray([(props_1 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([(value_2 = (`${x.LotNumber}`), createElement("span", {
        children: [value_2],
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (props_4 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([(value_6 = (`${x.ItemId}`), createElement("span", {
        children: [value_6],
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = singleton_1(["children", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), x.Name)]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", `${Currency_formatN(x.PriceIncludedCorporateTax)}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", `${Currency_formatN(x.Commission)}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", `${Currency_formatN(x.HammerFee)}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", `${Currency_formatN(x.PhotoCommission)}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-1"], ["className", "has-text-right"], ["children", `${Currency_formatN(x.ToPayout)}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))]);
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function View_remove(payoutId, submissionId, comment) {
    return singleton.Delay(() => {
        const request = new PayOuts_RemoveSubmissionFromPayoutRequest(payoutId, submissionId, comment);
        return singleton.ReturnFrom(Remoting_handleNonAuth(payOutApi().removeSubmissionFromPayout(request)));
    });
}

export function View_RemoveSubmissionBtn(view_RemoveSubmissionBtnInputProps) {
    const onSubmissionRemoved = view_RemoveSubmissionBtnInputProps.onSubmissionRemoved;
    const payoutStatus = view_RemoveSubmissionBtnInputProps.payoutStatus;
    const payoutId = view_RemoveSubmissionBtnInputProps.payoutId;
    const submissionId = view_RemoveSubmissionBtnInputProps.submissionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props;
        return append(singleton_2((props = toList(delay(() => append(singleton_2(["className", "is-danger"]), delay(() => append(singleton_2(["children", t("reports.excludeFromPayouts")]), delay(() => append(equals(payoutStatus, new PayOuts_PayoutStatus(0)) ? singleton_2(["disabled", true]) : empty(), delay(() => singleton_2(["onClick", (_arg1_1) => {
            setVisible(true);
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2, props_4;
            return patternInput[0] ? singleton_2((props_2 = [new Feliz_ConfirmationWithCommentProperty(0, t("reports.excludeFromPayouts")), new Feliz_ConfirmationWithCommentProperty(1, tWithArgs("reports.excludeFromPayoutConfirmationWithArg", {
                payoutId: payoutId,
                submissionId: submissionId,
            })), new Feliz_ConfirmationWithCommentProperty(6, (comment) => singleton.Delay(() => singleton.Bind(View_remove(payoutId, submissionId, comment), () => {
                onSubmissionRemoved();
                setVisible(false);
                return singleton.Zero();
            }))), new Feliz_ConfirmationWithCommentProperty(2, t("reports.exclude")), new Feliz_ConfirmationWithCommentProperty(7, () => {
                setVisible(false);
            })], (props_4 = fold((props_3, oneProp) => {
                switch (oneProp.tag) {
                    case 1: {
                        return new Component_Props(props_3.DialogCaption, oneProp.fields[0], props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 2: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, oneProp.fields[0], props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 3: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, oneProp.fields[0], props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 4: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, oneProp.fields[0], props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 5: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, oneProp.fields[0], props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 7: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, oneProp.fields[0], props_3.Key, props_3.IsVisible);
                    }
                    case 6: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, oneProp.fields[0], props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 8: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, oneProp.fields[0], props_3.IsVisible);
                    }
                    case 9: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, oneProp.fields[0]);
                    }
                    default: {
                        return new Component_Props(oneProp.fields[0], props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                }
            }, Component_Props_get_Default(), props_2), createElement(Component_ConfirmationWithComment, (($value) => { $value.key = $value.Key; return $value; })(props_4))))) : empty();
        }));
    })));
}

export function View_RenderSubmission(view_RenderSubmissionInputProps) {
    const onSubmissionRemoved = view_RenderSubmissionInputProps.onSubmissionRemoved;
    const payoutStatus = view_RenderSubmissionInputProps.payoutStatus;
    const submission = view_RenderSubmissionInputProps.submission;
    const payoutId = view_RenderSubmissionInputProps.payoutId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const expanded = patternInput[0];
    const totalPrice = sumBy((x) => x.PriceIncludedCorporateTax, submission.Lots, {
        GetZero: () => (new Decimal(0)),
        Add: (x_1, y) => op_Addition(x_1, y),
    });
    const oneTimeFee = IncludedInPayout_Submission__get_OneTimeFee(submission);
    return createElement("div", {
        className: "is-size-6",
        style: {
            borderRadius: 0,
            backgroundColor: "#FFFFFF",
        },
        className: "mb-2",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_21, props_19, props_17, props, props_2, props_4, props_6, props_8, props_10, props_15, props_13;
            return append(singleton_2((props_21 = ofArray([["className", "is-size-6"], ["className", "px-0"], ["className", "py-0"], ["className", "my-0"], ["className", "mx-0"], ["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["onClick", (x_2) => {
                if (length(submission.Lots) > 0) {
                    patternInput[1](!expanded);
                }
                x_2.preventDefault();
            }], ["style", {
                cursor: "pointer",
            }], ["children", Interop_reactApi.Children.toArray([(props_17 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-1"], ["children", `${submission.Id}`]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-3"], ["className", "has-text-weight-bold"], ["children", `${submission.SellerName}`]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "is-2"], ["children", tWithArgs("reports.amountWithArg", {
                totalPrice: Currency_format(totalPrice),
            })]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-2"], ["children", tWithArgs("reports.totalPayoutWithArg", {
                totalPayout: Currency_format(submission.TotalPayout),
            })]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-2"], ["children", tWithArgs("reports.oneTimeFeeWithArg", {
                oneTimeFee: Currency_format(oneTimeFee),
            })]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-1"], ["children", tWithArgs("reports.amountOfItemsWithArg", {
                amount: length(submission.Lots),
            })]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_15 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", toList(delay(() => append(singleton_2("fa"), delay(() => (expanded ? singleton_2("fa-chevron-up") : singleton_2("fa-chevron-down"))))))),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_19))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_21))))), delay(() => {
                let children_1, props_39, props_28, value_72, props_31, value_76, props_34, value_80, props_37, value_84, props_42, props_44;
                if (expanded) {
                    let oneTime;
                    const matchValue = submission.Fees.OneTime;
                    if (matchValue == null) {
                        oneTime = {
                            Administrative: CurrencyModule_zero,
                            Cites: CurrencyModule_zero,
                            Recycling: CurrencyModule_zero,
                            Transport: CurrencyModule_zero,
                        };
                    }
                    else {
                        const x_3 = matchValue;
                        oneTime = {
                            Administrative: x_3.Administrative,
                            Cites: x_3.Cites,
                            Recycling: x_3.Recycling,
                            Transport: x_3.Transport,
                        };
                    }
                    return singleton_2((children_1 = ofArray([createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", singleton_1(["className", "mt-0"])))), (props_39 = ofArray([["className", "px-0"], ["className", "py-0"], ["className", "my-0"], ["className", "mx-0"], ["className", "is-size-6"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", empty_1()))), (props_28 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(value_72 = tWithArgs("reports.administrativeFeeWithArg", {
                        administrativeFee: Currency_format(oneTime.Administrative),
                    }), createElement("span", {
                        children: [value_72],
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_28)))), (props_31 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(value_76 = tWithArgs("reports.citesFeeWithArg", {
                        citesFee: Currency_format(oneTime.Cites),
                    }), createElement("span", {
                        children: [value_76],
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_31)))), (props_34 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(value_80 = tWithArgs("reports.recyclingFeeWithArg", {
                        recyclingFee: Currency_format(oneTime.Recycling),
                    }), createElement("span", {
                        children: [value_80],
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_34)))), (props_37 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(value_84 = tWithArgs("reports.transportFeeWithArg", {
                        transportFee: Currency_format(oneTime.Transport),
                    }), createElement("span", {
                        children: [value_84],
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_37))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_39)))), (props_42 = ofArray([["className", "my-3"], ["className", "mx-3"], ["className", "is-size-6"], ["className", "has-background-light"], ["children", Interop_reactApi.Children.toArray([View_payoutLotHeader, react.createElement(react.Fragment, {}, ...map((x_6) => View_RenderPayoutLot(x_6), sortBy((x_4) => x_4.LotNumber, submission.Lots, {
                        Compare: (x_5, y_1) => comparePrimitives(x_5, y_1),
                    })))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_42)))), (props_44 = ofArray([["className", "mx-2"], ["className", "my-4"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(View_RemoveSubmissionBtn, {
                        submissionId: submission.Id,
                        payoutId: payoutId,
                        payoutStatus: payoutStatus,
                        onSubmissionRemoved: () => {
                            onSubmissionRemoved(submission);
                        },
                    })])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_44))))]), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    })));
                }
                else {
                    return empty();
                }
            }));
        })))),
    });
}

export function View_MarkAsPaidBtn(view_MarkAsPaidBtnInputProps) {
    const status = view_MarkAsPaidBtnInputProps.status;
    const onMarkedAsPaid = view_MarkAsPaidBtnInputProps.onMarkedAsPaid;
    const payoutId = view_MarkAsPaidBtnInputProps.payoutId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props;
        return append(singleton_2((props = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(equals(status, new PayOuts_PayoutStatus(0)) ? singleton_2(["disabled", true]) : empty(), delay(() => append(singleton_2(["children", t("reports.markAsPaid")]), delay(() => singleton_2(["onClick", (_arg1_1) => {
            setVisible(true);
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props))))), delay(() => {
            let props_2, props_4;
            return patternInput[0] ? singleton_2((props_2 = [new Feliz_ConfirmationWithCommentProperty(0, t("reports.markPayoutAsFinished")), new Feliz_ConfirmationWithCommentProperty(1, t("reports.markPayoutAsFinishedConfirmation")), new Feliz_ConfirmationWithCommentProperty(6, (_arg1) => singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(payOutApi().markAsPaid(payoutId)), (_arg2) => {
                const res = _arg2;
                if (res.tag === 1) {
                    if (equals(res.fields[0], new PayOuts_MarkAsPaidError(0))) {
                        throw (new Error(t("errors.fromServer.payoutContainsNegativePayment")));
                        return singleton.Zero();
                    }
                    else {
                        return singleton.Zero();
                    }
                }
                else {
                    onMarkedAsPaid();
                    setVisible(false);
                    return singleton.Zero();
                }
            }))), new Feliz_ConfirmationWithCommentProperty(2, t("reports.markPayoutAsFinished")), new Feliz_ConfirmationWithCommentProperty(7, () => {
                setVisible(false);
            })], (props_4 = fold((props_3, oneProp) => {
                switch (oneProp.tag) {
                    case 1: {
                        return new Component_Props(props_3.DialogCaption, oneProp.fields[0], props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 2: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, oneProp.fields[0], props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 3: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, oneProp.fields[0], props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 4: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, oneProp.fields[0], props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 5: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, oneProp.fields[0], props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 7: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, oneProp.fields[0], props_3.Key, props_3.IsVisible);
                    }
                    case 6: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, oneProp.fields[0], props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                    case 8: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, oneProp.fields[0], props_3.IsVisible);
                    }
                    case 9: {
                        return new Component_Props(props_3.DialogCaption, props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, oneProp.fields[0]);
                    }
                    default: {
                        return new Component_Props(oneProp.fields[0], props_3.Title, props_3.SuccessText, props_3.CancelText, props_3.CommentText, props_3.Comment, props_3.OnSuccess, props_3.OnCancel, props_3.Key, props_3.IsVisible);
                    }
                }
            }, Component_Props_get_Default(), props_2), createElement(Component_ConfirmationWithComment, (($value) => { $value.key = $value.Key; return $value; })(props_4))))) : empty();
        }));
    })));
}

export function View_DownloadSusFile(view_DownloadSusFileInputProps) {
    const setErrors = view_DownloadSusFileInputProps.setErrors;
    const payoutId = view_DownloadSusFileInputProps.payoutId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const downloading = patternInput[0];
    const startLoadingData = useFeliz_React__React_useDeferredCallback_Static_7088D81D((payoutId_1) => singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(payOutApi().getSusFile(payoutId_1)), (_arg1) => {
        const downloadedFile = _arg1;
        if (downloadedFile.tag === 1) {
            return singleton.Return(new FSharpResult$2(1, downloadedFile.fields[0]));
        }
        else {
            ByteArrayExtensions_SaveFileAs_72B26259(downloadedFile.fields[0], `payout_${payoutId_1}.sus`);
            return singleton.Return(new FSharpResult$2(0, void 0));
        }
    })), patternInput[1]);
    const isLoading = Deferred_inProgress(downloading);
    let pattern_matching_result, error, er_1;
    if (downloading.tag === 2) {
        if (downloading.fields[0].tag === 0) {
            pattern_matching_result = 3;
        }
        else if (downloading.fields[0].fields[0].tag === 0) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 2;
            er_1 = downloading.fields[0].fields[0];
        }
    }
    else if (downloading.tag === 0) {
        pattern_matching_result = 3;
    }
    else if (downloading.tag === 1) {
        pattern_matching_result = 3;
    }
    else {
        pattern_matching_result = 0;
        error = downloading.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            setErrors(error.message);
            break;
        }
        case 1: {
            setErrors(t("errors.fromServer.payoutContainsNegativePayment"));
            break;
        }
        case 2: {
            setErrors(`${er_1}`);
            break;
        }
        case 3: {
            setErrors("");
            break;
        }
    }
    const props = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["children", t("reports.susFile")]), delay(() => singleton_2(["onClick", (_arg1_2) => {
        startLoadingData(payoutId);
    }])))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export function View_DownloadXmlFile(view_DownloadXmlFileInputProps) {
    const setErrors = view_DownloadXmlFileInputProps.setErrors;
    const payoutId = view_DownloadXmlFileInputProps.payoutId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const downloading = patternInput[0];
    const startLoadingData = useFeliz_React__React_useDeferredCallback_Static_7088D81D((payoutId_1) => singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(payOutApi().getXmlFile(payoutId_1)), (_arg1) => {
        const downloadedFile = _arg1;
        if (downloadedFile.tag === 1) {
            return singleton.Return(new FSharpResult$2(1, downloadedFile.fields[0]));
        }
        else {
            ByteArrayExtensions_SaveFileAs_72B26259(downloadedFile.fields[0], `payout_${payoutId_1}.xml`);
            return singleton.Return(new FSharpResult$2(0, void 0));
        }
    })), patternInput[1]);
    const isLoading = Deferred_inProgress(downloading);
    let pattern_matching_result, error, er_1;
    if (downloading.tag === 2) {
        if (downloading.fields[0].tag === 0) {
            pattern_matching_result = 4;
        }
        else if (downloading.fields[0].fields[0].tag === 0) {
            pattern_matching_result = 1;
        }
        else if (downloading.fields[0].fields[0].tag === 1) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 3;
            er_1 = downloading.fields[0].fields[0];
        }
    }
    else if (downloading.tag === 0) {
        pattern_matching_result = 4;
    }
    else if (downloading.tag === 1) {
        pattern_matching_result = 4;
    }
    else {
        pattern_matching_result = 0;
        error = downloading.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            setErrors(error.message);
            break;
        }
        case 1: {
            setErrors(t("errors.fromServer.payoutContainsNegativePayment"));
            break;
        }
        case 2: {
            setErrors(t("errors.fromServer.payoutContainsCreditorsWithResidentBankAccountFormat"));
            break;
        }
        case 3: {
            setErrors(`${er_1}`);
            break;
        }
        case 4: {
            setErrors("");
            break;
        }
    }
    const props = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty(), delay(() => append(singleton_2(["children", t("reports.xmlFile")]), delay(() => singleton_2(["onClick", (_arg1_2) => {
        startLoadingData(payoutId);
    }])))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export function View_View(view_ViewInputProps) {
    const payoutStatus = view_ViewInputProps.payoutStatus;
    const payoutId = view_ViewInputProps.payoutId;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(payoutId), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const setErrors = patternInput_1[1];
    const errors = patternInput_1[0];
    const renderError = (errors === "") ? null : createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "is-danger"], ["children", errors]]))));
    const matchValue = state_1.Content;
    switch (matchValue.tag) {
        case 1: {
            return loaderBig();
        }
        case 2: {
            const content = matchValue.fields[0];
            let submissions;
            if (state_1.Filter.tag === 0) {
                const filteredSubmissions = filter_1((x) => IncludedInPayout_Submission__get_HasResidentBankInfoFormat(x), content.Submissions.Submissions);
                submissions = (new IncludedInPayout_SubmissionsByPayoutResult(sumBy((x_1) => x_1.TotalPayout, filteredSubmissions, {
                    GetZero: () => (new Decimal(0)),
                    Add: (x_2, y) => op_Addition(x_2, y),
                }), filteredSubmissions));
            }
            else {
                submissions = content.Submissions;
            }
            return createElement("div", createObj(toList(delay(() => append(equals(payoutStatus, new PayOuts_PayoutStatus(0)) ? singleton_2(["className", "py-6"]) : empty(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let elms, props_4, value_8, value_9, props_16, children, props_6, props_12;
                return append(singleton_2((elms = ofArray([(props_4 = ofArray([["className", "is-size-6"], ["children", Interop_reactApi.Children.toArray([(value_8 = tWithArgs("reports.totalWithArg", {
                    total: Currency_format(submissions.Total),
                }), createElement("div", {
                    children: [value_8],
                })), (value_9 = tWithArgs("reports.amountSubmissionsWithArg", {
                    amount: length(submissions.Submissions),
                }), createElement("div", {
                    children: [value_9],
                }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_16 = ofArray([["className", "is-size-6"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_6 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (equals(payoutStatus, new PayOuts_PayoutStatus(0)) ? singleton_2(backButtonHref(toPath(new Route(43, 1, new PayOuts_PayoutStatus(1))), t("reports.backToPayouts"))) : empty())))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_6)))), (props_12 = ofArray([["style", {
                    justifyContent: "flex-end",
                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_8;
                    return append((equals(content.Settings.PayoutXML.ISOVersion, new SettingsPayout_ISOVersion(0)) ? equals(payoutStatus, new PayOuts_PayoutStatus(1)) : false) ? append(singleton_2((props_8 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append((!equals(state_1.Filter, new Types_Filter(1))) ? singleton_2(["className", "is-light"]) : empty(), delay(() => append(singleton_2(["children", t("common.all")]), delay(() => singleton_2(["onClick", (_arg1) => {
                        dispatch(new Types_Msg(1, new Types_Filter(1)));
                    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_8))))), delay(() => {
                        let props_10;
                        return singleton_2((props_10 = toList(delay(() => append(singleton_2(["className", "is-danger"]), delay(() => append((!equals(state_1.Filter, new Types_Filter(0))) ? singleton_2(["className", "is-light"]) : empty(), delay(() => append(singleton_2(["children", tWithArgs("reports.hasResidentBankInfoPayoutAccountsWithArg", {
                            amount: length_1(filter_2((x_3) => IncludedInPayout_Submission__get_HasResidentBankInfoFormat(x_3), submissions.Submissions)),
                        })]), delay(() => singleton_2(["onClick", (_arg2) => {
                            dispatch(new Types_Msg(1, new Types_Filter(0)));
                        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_10)))));
                    })) : empty(), delay(() => append(singleton_2(createElement(View_MarkAsPaidBtn, {
                        payoutId: payoutId,
                        onMarkedAsPaid: () => {
                            dispatch(new Types_Msg(4));
                        },
                        status: payoutStatus,
                    })), delay(() => append(content.Settings.PayoutSUS.IsExportEnabled ? singleton_2(createElement(View_DownloadSusFile, {
                        payoutId: payoutId,
                        setErrors: setErrors,
                    })) : empty(), delay(() => (content.Settings.PayoutXML.IsExportEnabled ? singleton_2(createElement(View_DownloadXmlFile, {
                        payoutId: payoutId,
                        setErrors: setErrors,
                    })) : empty())))))));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_12))))]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                })), createElement("div", {
                    style: {
                        textAlign: "right",
                    },
                    children: Interop_reactApi.Children.toArray([renderError]),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))), delay(() => map_1((s) => createElement(View_RenderSubmission, {
                    payoutId: state_1.PayoutId,
                    submission: s,
                    payoutStatus: payoutStatus,
                    onSubmissionRemoved: (arg) => {
                        dispatch(new Types_Msg(3, arg));
                    },
                }), submissions.Submissions)));
            }))))])))))));
        }
        case 3: {
            const value_41 = tWithArgs("errors.fromServer.failedWithArg", {
                error: matchValue.fields[0].message,
            });
            return createElement("div", {
                children: [value_41],
            });
        }
        default: {
            return loaderBig();
        }
    }
}

