import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { User_BankInfo, User_Address, User_PersonalInfo, User_SellerPerson, CommonQueries_CompanyBasicSettings, CommonQueries_CompanyBasicSettings$reflection, User_SellerPerson$reflection } from "../Shared/Shared.js";
import { record_type, bool_type, class_type, list_type, union_type, string_type, unit_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Remoting_handleNonAuth, AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi, commonApi } from "../Communication.js";
import { t, tWithArgs } from "../Localization.js";
import { SellerPerson_validate, SellerPerson_validateIf } from "../Validation.js";
import { toPath, Route_AccountDetailsSubmissions_7639071A, navigateTo } from "../Router.js";
import { PhoneModule_toString, PhoneModule_ofString, EmailModule_toString, EmailModule_ofString, CountryCodeModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { CountryModule_shortCode } from "../bidflow/src/infrastructure/Countries.js";
import { append as append_1, map, cons, empty as empty_2, singleton as singleton_2, ofArray, fold } from "../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { dividedTop } from "../Components/BorderDividedLine.js";
import { CountriesInput_getSortedList, Form_getFieldError } from "../Common.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";

export class State extends Record {
    constructor(Form, SaveFormOperation, FormErrors, NeedToValidate, BasicCompanySettings) {
        super();
        this.Form = Form;
        this.SaveFormOperation = SaveFormOperation;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.BasicCompanySettings = BasicCompanySettings;
    }
}

export function State$reflection() {
    return record_type("PrivateProfile.State", [], State, () => [["Form", User_SellerPerson$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["BasicCompanySettings", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CommonQueries_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CommonQueries_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadBasicSettings", "EmailChanged", "NonExistentEmailChanged", "AvoidWarehouseFeeChanged", "NotifyAboutOverbidChanged", "NotifyNewsletterChanged", "FirstNameChanged", "LastNameChanged", "PhoneChanged", "AddressChanged", "PersonalNumberChanged", "ZipCodeChanged", "CityChanged", "CountryChanged", "ClearingNumberChanged", "BankNumberChanged", "BankChanged", "IbanCountryChanged", "IbanChanged", "SaveForm"];
    }
}

export function Msg$reflection() {
    return union_type("PrivateProfile.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CommonQueries_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CommonQueries_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function init(user) {
    return [new State(user, new Deferred$1(0), empty(), false, new Deferred$1(0)), Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0)))];
}

export function loadResidentCountry() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg1) => {
        const settings = _arg1;
        return singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, new CommonQueries_CompanyBasicSettings(settings.ResidentCountry, settings.CollectPersonalNumber)))));
    })), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function saveProfile(x) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(usersApi().updateSellerPerson(x)), (_arg1) => ((_arg1.tag === 1) ? ((_arg1.fields[0].tag === 1) ? singleton.Return(new Msg(19, new AsyncOperationStatus$1(1, new FSharpResult$2(1, tWithArgs("errors.fromServer.userWithIdNotFoundWithArg", {
        userId: x.UserId,
    }))))) : ((_arg1.fields[0].tag === 2) ? singleton.Return(new Msg(19, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.agreementDoesNotExist"))))) : singleton.Return(new Msg(19, new AsyncOperationStatus$1(1, new FSharpResult$2(1, tWithArgs("errors.fromServer.userAlreadyExistWithArg", {
        email: _arg1.fields[0].fields[0],
    }))))))) : singleton.Return(new Msg(19, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Msg(19, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function withForm(seller, state) {
    return new State(seller, state.SaveFormOperation, state.FormErrors, state.NeedToValidate, state.BasicCompanySettings);
}

export function withAddress(address, person) {
    return new User_SellerPerson(person.UserId, person.Email, person.NonExistentEmail, person.NeedNotifyAboutOverbid, person.NeedNotifyNewsletter, person.NeedAvoidWarehouseFee, person.PersonalInfo, address, person.BankInfo);
}

export function withBankInfo(bankInfo, person) {
    return new User_SellerPerson(person.UserId, person.Email, person.NonExistentEmail, person.NeedNotifyAboutOverbid, person.NeedNotifyNewsletter, person.NeedAvoidWarehouseFee, person.PersonalInfo, person.Address, bankInfo);
}

export function withPersonalInfo(x, person) {
    return new User_SellerPerson(person.UserId, person.Email, person.NonExistentEmail, person.NeedNotifyAboutOverbid, person.NeedNotifyNewsletter, person.NeedAvoidWarehouseFee, x, person.Address, person.BankInfo);
}

export function withFormAndErrors(form, errors, state) {
    return new State(form, state.SaveFormOperation, errors, state.NeedToValidate, state.BasicCompanySettings);
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12, inputRecord_13, inputRecord_14, inputRecord_15, inputRecord_16, inputRecord_17;
    const withValidatedForm = (s) => ((info) => {
        const tupledArg = SellerPerson_validateIf(state.NeedToValidate, s, info);
        return (state_1) => withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    });
    const basicSettings_1 = (settings) => {
        let pattern_matching_result, s_1;
        if (settings.tag === 2) {
            if (settings.fields[0].tag === 0) {
                pattern_matching_result = 0;
                s_1 = settings.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return s_1;
            }
            case 1: {
                return void 0;
            }
        }
    };
    if (msg.tag === 1) {
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))((inputRecord = state.Form, new User_SellerPerson(inputRecord.UserId, msg.fields[0], inputRecord.NonExistentEmail, inputRecord.NeedNotifyAboutOverbid, inputRecord.NeedNotifyNewsletter, inputRecord.NeedAvoidWarehouseFee, inputRecord.PersonalInfo, inputRecord.Address, inputRecord.BankInfo)))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new State((inputRecord_1 = state.Form, new User_SellerPerson(inputRecord_1.UserId, inputRecord_1.Email, msg.fields[0], inputRecord_1.NeedNotifyAboutOverbid, inputRecord_1.NeedNotifyNewsletter, inputRecord_1.NeedAvoidWarehouseFee, inputRecord_1.PersonalInfo, inputRecord_1.Address, inputRecord_1.BankInfo)), state.SaveFormOperation, state.FormErrors, state.NeedToValidate, state.BasicCompanySettings), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new State((inputRecord_2 = state.Form, new User_SellerPerson(inputRecord_2.UserId, inputRecord_2.Email, inputRecord_2.NonExistentEmail, inputRecord_2.NeedNotifyAboutOverbid, inputRecord_2.NeedNotifyNewsletter, msg.fields[0], inputRecord_2.PersonalInfo, inputRecord_2.Address, inputRecord_2.BankInfo)), state.SaveFormOperation, state.FormErrors, state.NeedToValidate, state.BasicCompanySettings), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new State((inputRecord_3 = state.Form, new User_SellerPerson(inputRecord_3.UserId, inputRecord_3.Email, inputRecord_3.NonExistentEmail, msg.fields[0], inputRecord_3.NeedNotifyNewsletter, inputRecord_3.NeedAvoidWarehouseFee, inputRecord_3.PersonalInfo, inputRecord_3.Address, inputRecord_3.BankInfo)), state.SaveFormOperation, state.FormErrors, state.NeedToValidate, state.BasicCompanySettings), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new State((inputRecord_4 = state.Form, new User_SellerPerson(inputRecord_4.UserId, inputRecord_4.Email, inputRecord_4.NonExistentEmail, inputRecord_4.NeedNotifyAboutOverbid, msg.fields[0], inputRecord_4.NeedAvoidWarehouseFee, inputRecord_4.PersonalInfo, inputRecord_4.Address, inputRecord_4.BankInfo)), state.SaveFormOperation, state.FormErrors, state.NeedToValidate, state.BasicCompanySettings), Cmd_none()];
    }
    else if (msg.tag === 6) {
        const form_1 = withPersonalInfo((inputRecord_5 = state.Form.PersonalInfo, new User_PersonalInfo(msg.fields[0], inputRecord_5.LastName, inputRecord_5.Phone, inputRecord_5.PersonalNumber)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_1)(state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        const form_2 = withPersonalInfo((inputRecord_6 = state.Form.PersonalInfo, new User_PersonalInfo(inputRecord_6.FirstName, msg.fields[0], inputRecord_6.Phone, inputRecord_6.PersonalNumber)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_2)(state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        const form_3 = withPersonalInfo((inputRecord_7 = state.Form.PersonalInfo, new User_PersonalInfo(inputRecord_7.FirstName, inputRecord_7.LastName, msg.fields[0], inputRecord_7.PersonalNumber)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_3)(state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        const form_4 = withPersonalInfo((inputRecord_8 = state.Form.PersonalInfo, new User_PersonalInfo(inputRecord_8.FirstName, inputRecord_8.LastName, inputRecord_8.Phone, msg.fields[0])), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_4)(state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        const form_5 = withAddress((inputRecord_9 = state.Form.Address, new User_Address(msg.fields[0], inputRecord_9.ZipCode, inputRecord_9.City, inputRecord_9.Country)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_5)(state), Cmd_none()];
    }
    else if (msg.tag === 11) {
        const form_6 = withAddress((inputRecord_10 = state.Form.Address, new User_Address(inputRecord_10.Address, msg.fields[0], inputRecord_10.City, inputRecord_10.Country)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_6)(state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        const form_7 = withAddress((inputRecord_11 = state.Form.Address, new User_Address(inputRecord_11.Address, inputRecord_11.ZipCode, msg.fields[0], inputRecord_11.Country)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_7)(state), Cmd_none()];
    }
    else if (msg.tag === 13) {
        const form_8 = withAddress((inputRecord_12 = state.Form.Address, new User_Address(inputRecord_12.Address, inputRecord_12.ZipCode, inputRecord_12.City, msg.fields[0])), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_8)(state), Cmd_none()];
    }
    else if (msg.tag === 14) {
        const form_9 = withBankInfo((inputRecord_13 = state.Form.BankInfo, new User_BankInfo(msg.fields[0], inputRecord_13.BankNumber, inputRecord_13.Bank, inputRecord_13.Comment, inputRecord_13.IbanCountry, inputRecord_13.Iban)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_9)(state), Cmd_none()];
    }
    else if (msg.tag === 15) {
        const form_10 = withBankInfo((inputRecord_14 = state.Form.BankInfo, new User_BankInfo(inputRecord_14.ClearingNumber, msg.fields[0], inputRecord_14.Bank, inputRecord_14.Comment, inputRecord_14.IbanCountry, inputRecord_14.Iban)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_10)(state), Cmd_none()];
    }
    else if (msg.tag === 16) {
        const form_11 = withBankInfo((inputRecord_15 = state.Form.BankInfo, new User_BankInfo(inputRecord_15.ClearingNumber, inputRecord_15.BankNumber, msg.fields[0], inputRecord_15.Comment, inputRecord_15.IbanCountry, inputRecord_15.Iban)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_11)(state), Cmd_none()];
    }
    else if (msg.tag === 17) {
        const form_12 = withBankInfo((inputRecord_16 = state.Form.BankInfo, new User_BankInfo(inputRecord_16.ClearingNumber, inputRecord_16.BankNumber, inputRecord_16.Bank, inputRecord_16.Comment, msg.fields[0], inputRecord_16.Iban)), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_12)(state), Cmd_none()];
    }
    else if (msg.tag === 18) {
        const form_13 = withBankInfo((inputRecord_17 = state.Form.BankInfo, new User_BankInfo(inputRecord_17.ClearingNumber, inputRecord_17.BankNumber, inputRecord_17.Bank, inputRecord_17.Comment, inputRecord_17.IbanCountry, msg.fields[0])), state.Form);
        return [withValidatedForm(basicSettings_1(state.BasicCompanySettings))(form_13)(state), Cmd_none()];
    }
    else if (msg.tag === 19) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.Form, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.NeedToValidate, state.BasicCompanySettings), Cmd_none()];
            }
            else {
                return [new State(state.Form, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.NeedToValidate, state.BasicCompanySettings), navigateTo(Route_AccountDetailsSubmissions_7639071A(state.Form.UserId))];
            }
        }
        else {
            const matchValue = SellerPerson_validate(basicSettings_1(state.BasicCompanySettings), state.Form);
            if (matchValue.tag === 1) {
                return [new State(state.Form, new Deferred$1(0), matchValue.fields[0], true, state.BasicCompanySettings), Cmd_none()];
            }
            else {
                return [new State(state.Form, new Deferred$1(1), empty(), true, state.BasicCompanySettings), Cmd_fromAsync(saveProfile(matchValue.fields[0]))];
            }
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new State(state.Form, state.SaveFormOperation, state.FormErrors, state.NeedToValidate, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
        else {
            return [new State(state.Form, state.SaveFormOperation, state.FormErrors, state.NeedToValidate, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
    }
    else {
        return [new State(state.Form, state.SaveFormOperation, state.FormErrors, state.NeedToValidate, new Deferred$1(1)), Cmd_fromAsync(loadResidentCountry())];
    }
}

export function CountryCodeToString(c) {
    if (c === CountryCodeModule_empty) {
        return "-";
    }
    else {
        return CountryModule_shortCode(c);
    }
}

export function ErrorsHelpBlock(errors) {
    const props = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]);
    return createElement("p", createObj(Helpers_combineClasses("help", props)));
}

export function Render(user) {
    let props_281, props_294, props_292, elms_40, props_289, props_285, props_287;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(user), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    let error;
    const matchValue = state_1.SaveFormOperation;
    let pattern_matching_result, x;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            x = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            error = x;
            break;
        }
        case 1: {
            error = "";
            break;
        }
    }
    const children_43 = ofArray([(props_281 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_2, props;
        return append(singleton_1((props_2 = singleton_2(["children", Interop_reactApi.Children.toArray([(props = singleton_2(["children", t("users.editPerson")]), createElement("h3", createObj(Helpers_combineClasses("title is-3", props))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_2))))), delay(() => append((error !== "") ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", error]]))))) : empty_1(), delay(() => {
            let props_32, elms_4, props_10, props_6, props_8, elms_3, props_28;
            return append(singleton_1((props_32 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_4 = ofArray([(props_10 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.basicInformation")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_6)))), (props_8 = ofArray([["style", {
                fontSize: 0.7 + "rem",
            }], ["className", "has-text-grey-light"], ["children", t("users.basicInformationHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (elms_3 = singleton_2((props_28 = toList(delay(() => {
                let elms_1, props_14, props_12, elms, props_16, props_26, elms_2, props_23, props_21;
                const errors = Form_getFieldError(state_1.FormErrors, "Email");
                return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_14 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.email")]]), createElement("label", createObj(Helpers_combineClasses("label", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_14)))), (elms = singleton_2((props_16 = toList(delay(() => append((!equals(errors, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev) => {
                    dispatch(new Msg(1, EmailModule_ofString(ev.target.value)));
                }]), delay(() => append(singleton_1(["placeholder", t("users.enterEmail")]), delay(() => {
                    let value_44;
                    return singleton_1((value_44 = EmailModule_toString(state_1.Form.Email), ["ref", (e) => {
                        if ((!(e == null)) ? (!equals(e.value, value_44)) : false) {
                            e.value = value_44;
                        }
                    }]));
                })))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_16)))))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), ErrorsHelpBlock(errors)]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                })), (props_26 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_23 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                    className: join(" ", ["is-checkradio"]),
                    id: "NonExistentEmail",
                    type: "checkbox",
                    name: "NonExistentEmail",
                    onChange: (ev_1) => {
                        dispatch(new Msg(2, ev_1.target.checked));
                    },
                    checked: state_1.Form.NonExistentEmail,
                }), (props_21 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.nonExistentEmail")], ["htmlFor", "NonExistentEmail"]]), createElement("label", createObj(Helpers_combineClasses("label", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_23))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_26))))])]);
            })), createElement("div", createObj(Helpers_combineClasses("field", props_28))))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))]), createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_32))))), delay(() => {
                let props_149, elms_22, props_38, props_34, props_36, elms_21, props_64, props_50, props_48, props_62, props_60, props_90, props_76, props_74, props_88, props_116, props_102, props_100, props_114, props_112, props_145, props_128, props_126, props_143, props_141;
                return append(singleton_1((props_149 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_22 = ofArray([(props_38 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.profileInfo")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_34)))), (props_36 = ofArray([["style", {
                    fontSize: 0.7 + "rem",
                }], ["className", "has-text-grey-light"], ["children", t("users.profileInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_36))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_38)))), (elms_21 = ofArray([(props_64 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_50 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_48 = toList(delay(() => {
                    let elms_6, props_42, props_40, elms_5, props_44;
                    const errors_1 = Form_getFieldError(state_1.FormErrors, "FirstName");
                    return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_6 = ofArray([(props_42 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.firstName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_40))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_42)))), (elms_5 = singleton_2((props_44 = toList(delay(() => append((!equals(errors_1, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_2) => {
                        dispatch(new Msg(6, ev_2.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterFirstName")]), delay(() => {
                        let value_127;
                        return singleton_1((value_127 = state_1.Form.PersonalInfo.FirstName, ["ref", (e_1) => {
                            if ((!(e_1 == null)) ? (!equals(e_1.value, value_127)) : false) {
                                e_1.value = value_127;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_44)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                    })), ErrorsHelpBlock(errors_1)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                    }))])]);
                })), createElement("div", createObj(Helpers_combineClasses("field", props_48))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_50)))), (props_62 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_60 = toList(delay(() => {
                    let elms_8, props_54, props_52, elms_7, props_56;
                    const errors_2 = Form_getFieldError(state_1.FormErrors, "LastName");
                    return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_8 = ofArray([(props_54 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_52 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.lastName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_52))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_54)))), (elms_7 = singleton_2((props_56 = toList(delay(() => append((!equals(errors_2, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_3) => {
                        dispatch(new Msg(7, ev_3.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterLastName")]), delay(() => {
                        let value_156;
                        return singleton_1((value_156 = state_1.Form.PersonalInfo.LastName, ["ref", (e_2) => {
                            if ((!(e_2 == null)) ? (!equals(e_2.value, value_156)) : false) {
                                e_2.value = value_156;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_56)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                    })), ErrorsHelpBlock(errors_2)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                    }))])]);
                })), createElement("div", createObj(Helpers_combineClasses("field", props_60))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_62))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_64)))), (props_90 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_76 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_74 = toList(delay(() => {
                    let elms_10, props_68, props_66, elms_9, props_70;
                    const errors_3 = Form_getFieldError(state_1.FormErrors, "Phone");
                    return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_10 = ofArray([(props_68 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_66 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.phoneNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_66))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_68)))), (elms_9 = singleton_2((props_70 = toList(delay(() => append((!equals(errors_3, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_4) => {
                        dispatch(new Msg(8, PhoneModule_ofString(ev_4.target.value)));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterPhoneNumber")]), delay(() => {
                        let value_187;
                        return singleton_1((value_187 = PhoneModule_toString(state_1.Form.PersonalInfo.Phone), ["ref", (e_3) => {
                            if ((!(e_3 == null)) ? (!equals(e_3.value, value_187)) : false) {
                                e_3.value = value_187;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_70)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                    })), ErrorsHelpBlock(errors_3)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                    }))])]);
                })), createElement("div", createObj(Helpers_combineClasses("field", props_74))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_76)))), (props_88 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_86;
                    const matchValue_1 = state_1.BasicCompanySettings;
                    let pattern_matching_result_2, set$;
                    if (matchValue_1.tag === 2) {
                        if (matchValue_1.fields[0].tag === 0) {
                            pattern_matching_result_2 = 0;
                            set$ = matchValue_1.fields[0].fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return set$.CollectPersonalNumber ? singleton_1((props_86 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let elms_12, props_80, props_78, elms_11, props_82;
                                const errors_4 = Form_getFieldError(state_1.FormErrors, "PersonalNumber");
                                return singleton_1((elms_12 = ofArray([(props_80 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_78 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.personalNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_78))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_80)))), (elms_11 = singleton_2((props_82 = toList(delay(() => append((!equals(errors_4, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_5) => {
                                    dispatch(new Msg(10, ev_5.target.value));
                                }]), delay(() => append(singleton_1(["placeholder", t("users.enterPersonalNumber")]), delay(() => {
                                    let value_216;
                                    return singleton_1((value_216 = state_1.Form.PersonalInfo.PersonalNumber, ["ref", (e_4) => {
                                        if ((!(e_4 == null)) ? (!equals(e_4.value, value_216)) : false) {
                                            e_4.value = value_216;
                                        }
                                    }]));
                                })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_82)))))), createElement("div", {
                                    className: "field-body",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                                })), ErrorsHelpBlock(errors_4)]), createElement("div", {
                                    className: "control",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                                })));
                            }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_86))))) : empty_1();
                        }
                        case 1: {
                            return singleton_1(null);
                        }
                    }
                }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_88))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_90)))), (props_116 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_102 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_100 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_14, props_94, props_92, elms_13, props_96;
                    const errors_5 = Form_getFieldError(state_1.FormErrors, "Address");
                    return singleton_1((elms_14 = ofArray([(props_94 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_92 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props_92))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_94)))), (elms_13 = singleton_2((props_96 = toList(delay(() => append((!equals(errors_5, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_6) => {
                        dispatch(new Msg(9, ev_6.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterAddress")]), delay(() => {
                        let value_246;
                        return singleton_1((value_246 = state_1.Form.Address.Address, ["ref", (e_5) => {
                            if ((!(e_5 == null)) ? (!equals(e_5.value, value_246)) : false) {
                                e_5.value = value_246;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_96)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
                    })), ErrorsHelpBlock(errors_5)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_100))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_102)))), (props_114 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_112 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_16, props_106, props_104, elms_15, props_108;
                    const errors_6 = Form_getFieldError(state_1.FormErrors, "ZipCode");
                    return singleton_1((elms_16 = ofArray([(props_106 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_104 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.zipCode")]]), createElement("label", createObj(Helpers_combineClasses("label", props_104))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_106)))), (elms_15 = singleton_2((props_108 = toList(delay(() => append((!equals(errors_6, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_7) => {
                        dispatch(new Msg(11, ev_7.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterZipCode")]), delay(() => {
                        let value_275;
                        return singleton_1((value_275 = state_1.Form.Address.ZipCode, ["ref", (e_6) => {
                            if ((!(e_6 == null)) ? (!equals(e_6.value, value_275)) : false) {
                                e_6.value = value_275;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_108)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
                    })), ErrorsHelpBlock(errors_6)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_112))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_114))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_116)))), (props_145 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_128 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_126 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_18, props_120, props_118, elms_17, props_122;
                    const errors_7 = Form_getFieldError(state_1.FormErrors, "City");
                    return singleton_1((elms_18 = ofArray([(props_120 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_118 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.city")]]), createElement("label", createObj(Helpers_combineClasses("label", props_118))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_120)))), (elms_17 = singleton_2((props_122 = toList(delay(() => append((!equals(errors_7, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_8) => {
                        dispatch(new Msg(12, ev_8.target.value));
                    }]), delay(() => append(singleton_1(["placeholder", t("users.enterCity")]), delay(() => {
                        let value_305;
                        return singleton_1((value_305 = state_1.Form.Address.City, ["ref", (e_7) => {
                            if ((!(e_7 == null)) ? (!equals(e_7.value, value_305)) : false) {
                                e_7.value = value_305;
                            }
                        }]));
                    })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_122)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
                    })), ErrorsHelpBlock(errors_7)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_126))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_128)))), (props_143 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_141 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_20, props_132, props_130, elms_19, patternInput_1;
                    const errors_8 = Form_getFieldError(state_1.FormErrors, "Country");
                    return singleton_1((elms_20 = ofArray([(props_132 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_130 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.country")]]), createElement("label", createObj(Helpers_combineClasses("label", props_130))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_132)))), (elms_19 = singleton_2((patternInput_1 = Helpers_extractClasses(toList(delay(() => append((!equals(errors_8, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(singleton_1(["className", "has-text-left"]), delay(() => append(singleton_1(["className", "mb-1"]), delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["onChange", (ev_9) => {
                        dispatch(new Msg(13, ev_9.target.value));
                    }]), delay(() => append(singleton_1(["value", state_1.Form.Address.Country]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => createElement("option", {
                        value: tupledArg[0],
                        children: tupledArg[1],
                    }), CountriesInput_getSortedList())))])))))))))))))))))), createElement("div", {
                        className: join(" ", cons("select", patternInput_1[0])),
                        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
                    }))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
                    })), ErrorsHelpBlock(errors_8)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_141))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_143))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_145))))]), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
                }))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
                }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_149))))), delay(() => {
                    let props_229, elms_32, props_155, props_151, props_153, elms_31, props_165, props_191, props_177, props_175, props_189, props_187, props_225, props_206, props_204, props_223, children_31;
                    return append(singleton_1((props_229 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_32 = ofArray([(props_155 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_151 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.bankInfo")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_151)))), (props_153 = ofArray([["style", {
                        fontSize: 0.7 + "rem",
                    }], ["className", "has-text-grey-light"], ["children", t("users.bankInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_153))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_155)))), (elms_31 = ofArray([(props_165 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let elms_24, props_159, props_157, elms_23, props_161;
                        const errors_9 = Form_getFieldError(state_1.FormErrors, "ClearingNumber");
                        return singleton_1((elms_24 = ofArray([(props_159 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_157 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.clearingNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_157))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_159)))), (elms_23 = singleton_2((props_161 = toList(delay(() => append((!equals(errors_9, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_10) => {
                            dispatch(new Msg(14, ev_10.target.value));
                        }]), delay(() => append(singleton_1(["placeholder", t("users.enterClearingNumber")]), delay(() => {
                            let value_401;
                            return singleton_1((value_401 = state_1.Form.BankInfo.ClearingNumber, ["ref", (e_8) => {
                                if ((!(e_8 == null)) ? (!equals(e_8.value, value_401)) : false) {
                                    e_8.value = value_401;
                                }
                            }]));
                        })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_161)))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
                        })), ErrorsHelpBlock(errors_9)]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_24)),
                        })));
                    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_165)))), (props_191 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_177 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_175 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let elms_26, props_169, props_167, elms_25, props_171;
                        const errors_10 = Form_getFieldError(state_1.FormErrors, "BankNumber");
                        return singleton_1((elms_26 = ofArray([(props_169 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_167 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.accountNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_167))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_169)))), (elms_25 = singleton_2((props_171 = toList(delay(() => append((!equals(errors_10, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_11) => {
                            dispatch(new Msg(15, ev_11.target.value));
                        }]), delay(() => append(singleton_1(["placeholder", t("users.enterAccountNumber")]), delay(() => {
                            let value_429;
                            return singleton_1((value_429 = state_1.Form.BankInfo.BankNumber, ["ref", (e_9) => {
                                if ((!(e_9 == null)) ? (!equals(e_9.value, value_429)) : false) {
                                    e_9.value = value_429;
                                }
                            }]));
                        })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_171)))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_25)),
                        })), ErrorsHelpBlock(errors_10)]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_26)),
                        })));
                    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_175))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_177)))), (props_189 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_187 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let elms_28, props_181, props_179, elms_27, props_183;
                        const errors_11 = Form_getFieldError(state_1.FormErrors, "Bank");
                        return singleton_1((elms_28 = ofArray([(props_181 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_179 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.bank")]]), createElement("label", createObj(Helpers_combineClasses("label", props_179))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_181)))), (elms_27 = singleton_2((props_183 = toList(delay(() => append((!equals(errors_11, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_12) => {
                            dispatch(new Msg(16, ev_12.target.value));
                        }]), delay(() => append(singleton_1(["placeholder", t("users.enterBank")]), delay(() => {
                            let value_458;
                            return singleton_1((value_458 = state_1.Form.BankInfo.Bank, ["ref", (e_10) => {
                                if ((!(e_10 == null)) ? (!equals(e_10.value, value_458)) : false) {
                                    e_10.value = value_458;
                                }
                            }]));
                        })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_183)))))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_27)),
                        })), ErrorsHelpBlock(errors_11)]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_28)),
                        })));
                    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_187))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_189))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_191)))), (props_225 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_206 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_204 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let elms_30, props_195, props_193, elms_29, patternInput_2;
                        const errors_12 = Form_getFieldError(state_1.FormErrors, "IbanCountry");
                        return singleton_1((elms_30 = ofArray([(props_195 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_193 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.ibanCountry")]]), createElement("label", createObj(Helpers_combineClasses("label", props_193))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_195)))), (elms_29 = singleton_2((patternInput_2 = Helpers_extractClasses(toList(delay(() => append((!equals(errors_12, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(singleton_1(["className", "has-text-left"]), delay(() => append(singleton_1(["className", "mb-1"]), delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["onChange", (ev_13) => {
                            dispatch(new Msg(17, ev_13.target.value));
                        }]), delay(() => append(singleton_1(["value", state_1.Form.BankInfo.IbanCountry]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg_1) => createElement("option", {
                            value: tupledArg_1[0],
                            children: tupledArg_1[1],
                        }), append_1(singleton_2([CountryCodeModule_empty, "Select country"]), CountriesInput_getSortedList()))))])))))))))))))))))), createElement("div", {
                            className: join(" ", cons("select", patternInput_2[0])),
                            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_2[1]))]),
                        }))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_29)),
                        })), ErrorsHelpBlock(errors_12)]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_30)),
                        })));
                    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_204))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_206)))), (props_223 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(children_31 = toList(delay(() => {
                        let props_210, props_208;
                        const errors_13 = Form_getFieldError(state_1.FormErrors, "IBAN");
                        return append(singleton_1((props_210 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_208 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.IBAN")]]), createElement("label", createObj(Helpers_combineClasses("label", props_208))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_210))))), delay(() => {
                            let props_220, props_214, props_212, props_218, props_216;
                            return append(singleton_1((props_220 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_214 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_212 = ofArray([["className", "is-static"], ["children", CountryCodeToString(state_1.Form.BankInfo.IbanCountry)]]), createElement("a", createObj(Helpers_combineClasses("button", props_212))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_214)))), (props_218 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_216 = toList(delay(() => append((!equals(errors_13, empty_2())) ? singleton_1(["className", "is-danger"]) : empty_1(), delay(() => append(singleton_1(["onChange", (ev_14) => {
                                dispatch(new Msg(18, ev_14.target.value));
                            }]), delay(() => append(singleton_1(["placeholder", t("users.enterIBAN")]), delay(() => {
                                let value_536;
                                return singleton_1((value_536 = state_1.Form.BankInfo.Iban, ["ref", (e_11) => {
                                    if ((!(e_11 == null)) ? (!equals(e_11.value, value_536)) : false) {
                                        e_11.value = value_536;
                                    }
                                }]));
                            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_216)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_218))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_220))))), delay(() => singleton_1(ErrorsHelpBlock(errors_13))));
                        }));
                    })), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_31)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_223))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_225))))]), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_31)),
                    }))]), createElement("div", {
                        className: "columns",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_32)),
                    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_229))))), delay(() => {
                        let props_249, elms_35, props_235, props_231, props_233, elms_34, props_245, props_243, elms_33, props_240, props_238;
                        return append(singleton_1((props_249 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_35 = ofArray([(props_235 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_231 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.userSettings")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_231)))), (props_233 = ofArray([["style", {
                            fontSize: 0.7 + "rem",
                        }], ["className", "has-text-grey-light"], ["children", t("users.userSettingsHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_233))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_235)))), (elms_34 = singleton_2((props_245 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_243 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_33 = singleton_2((props_240 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                            className: join(" ", ["is-checkradio"]),
                            id: "AvoidWarehouseFee",
                            type: "checkbox",
                            name: "AvoidWarehouseFee",
                            onChange: (ev_15) => {
                                dispatch(new Msg(3, ev_15.target.checked));
                            },
                            checked: state_1.Form.NeedAvoidWarehouseFee,
                        }), (props_238 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.avoidWarehouseFee")], ["htmlFor", "AvoidWarehouseFee"]]), createElement("label", createObj(Helpers_combineClasses("label", props_238))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_240))))), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_33)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_243))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_245))))), createElement("div", {
                            className: "column",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_34)),
                        }))]), createElement("div", {
                            className: "columns",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_35)),
                        }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_249))))), delay(() => {
                            let props_279, elms_39, props_255, props_251, props_253, elms_38, props_265, props_263, elms_36, props_260, props_258, props_275, props_273, elms_37, props_270, props_268;
                            return singleton_1((props_279 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_39 = ofArray([(props_255 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_251 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.notifications")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_251)))), (props_253 = ofArray([["style", {
                                fontSize: 0.7 + "rem",
                            }], ["className", "has-text-grey-light"], ["children", t("users.notificationsHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_253))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_255)))), (elms_38 = ofArray([(props_265 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_263 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_36 = singleton_2((props_260 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                                className: join(" ", ["is-checkradio"]),
                                id: "NeedNotifyOutbid",
                                type: "checkbox",
                                name: "NeedNotifyOutbid",
                                onChange: (ev_16) => {
                                    dispatch(new Msg(4, ev_16.target.checked));
                                },
                                checked: state_1.Form.NeedNotifyAboutOverbid,
                            }), (props_258 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.whenOutbided")], ["htmlFor", "NeedNotifyOutbid"]]), createElement("label", createObj(Helpers_combineClasses("label", props_258))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_260))))), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_36)),
                            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_263))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_265)))), (props_275 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_273 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_37 = singleton_2((props_270 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                                className: join(" ", ["is-checkradio"]),
                                id: "NeedNotifyNewsletter",
                                type: "checkbox",
                                name: "NeedNotifyNewsletter",
                                onChange: (ev_17) => {
                                    dispatch(new Msg(5, ev_17.target.checked));
                                },
                                checked: state_1.Form.NeedNotifyNewsletter,
                            }), (props_268 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.newsletter")], ["htmlFor", "NeedNotifyNewsletter"]]), createElement("label", createObj(Helpers_combineClasses("label", props_268))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_270))))), createElement("div", {
                                className: "control",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_37)),
                            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_273))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_275))))]), createElement("div", {
                                className: "column",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_38)),
                            }))]), createElement("div", {
                                className: "columns",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_39)),
                            }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_279)))));
                        }));
                    }));
                }));
            }));
        }))));
    }))))]), createElement("div", createObj(Helpers_combineClasses("container", props_281)))), (props_294 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(props_292 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-one-quarter"])))), (elms_40 = singleton_2((props_289 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_285 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["href", toPath(Route_AccountDetailsSubmissions_7639071A(user.UserId))]]), createElement("a", createObj(Helpers_combineClasses("button", props_285)))), (props_287 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))]), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (_arg1_1) => {
        dispatch(new Msg(19, new AsyncOperationStatus$1(0)));
    }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_287))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_289))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_40)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_292))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_294))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_43)),
    });
}

