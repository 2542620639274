import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { cons, ofArray, singleton } from "./fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useReact_useCallback_93353E, React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { getSupportedLanguages, setCurrentLanguage, getCurrentLanguage, t } from "./Localization.js";
import { Route, toPath, navigate as navigate_1 } from "./Router.js";
import { useVisible } from "react-hooks-visible";
import { map as map_1, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "./fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { getUser, removeUser } from "./LocalStorage.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "./Extensions.js";
import { settingsApi } from "./Communication.js";
import { map, empty, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Breadcrumbs } from "./Breadcrumb.js";
import { LangModule_toString } from "./bidflow/src/infrastructure/Localization.js";
import { AuctionModeType, User_SystemUser } from "./Shared/Shared.js";

export function Element$(element$InputProps) {
    let props_3, props_1;
    const unitVar3 = element$InputProps.unitVar3;
    const colorIcon = element$InputProps.colorIcon;
    const route = element$InputProps.route;
    const icon = element$InputProps.icon;
    return createElement("a", {
        href: route,
        children: Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", join(" ", ["hoverablePie"])], ["style", {
            borderBottom: (((1 + "px ") + "solid") + " ") + "#D3D3D3",
        }], ["className", "has-text-centered"], ["className", "py-4"], ["className", "px-0"], colorIcon, ["children", Interop_reactApi.Children.toArray([(props_1 = singleton(["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fal", icon, "fa-lg"]),
        })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_1))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3))))]),
    });
}

export function SearchFieldHeader() {
    let props_7, props_5, props, value_9, props_3;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setSearchString = patternInput[1];
    const searchString = patternInput[0];
    const elms = singleton((props_7 = ofArray([["style", {
        width: 300,
    }], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "searchInput"], ["placeholder", t("common.search")], (value_9 = searchString, ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_9)) : false) {
            e.value = value_9;
        }
    }]), ["onChange", (ev) => {
        setSearchString(ev.target.value);
    }], ["onKeyUp", (ev_1) => {
        const key = ev_1.key;
        switch (key) {
            case "Enter": {
                if (searchString.trim() !== "") {
                    navigate_1(toPath(new Route(53, searchString)));
                    setSearchString("");
                    window.location.reload(true);
                }
                break;
            }
            case "Escape": {
                if (searchString !== "") {
                    setSearchString("");
                }
                break;
            }
            default: {
            }
        }
    }]]), createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", props))))), (props_3 = ofArray([["className", "is-left"], ["style", {
        height: 100 + "%",
    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-search", "fa-lg"]),
    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_5))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_7)))));
    return createElement("div", {
        className: "navbar-item",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function RenderHeader(renderHeaderInputProps) {
    const unitVar1 = renderHeaderInputProps.unitVar1;
    const listForBreadcrumb = renderHeaderInputProps.listForBreadcrumb;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const navRef = useVisible()[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setLogout = patternInput_2[1];
    const isLogout = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setShowLang = patternInput_3[1];
    const isShowLang = patternInput_3[0];
    const elemRef = navRef;
    const callback = (_arg1) => {
        patternInput[1](false);
    };
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = matchValue, copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            document.addEventListener(eventType, fn, addOptions);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                document.removeEventListener(eventType, fn, removeOptions);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = matchValue_1, copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_7 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_7), [options_7]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_7), [options_7]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            document.addEventListener(eventType_1, fn_1, addOptions_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                document.removeEventListener(eventType_1, fn_1, removeOptions_1);
            }
        });
    }));
    const logoutCmd = useReact_useCallback_93353E(() => {
        removeUser();
        document.location.replace(toPath(new Route(1)));
    });
    const companyName = useFeliz_React__React_useDeferred_Static_2344FC52(singleton_1.Delay(() => singleton_1.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettings()), (_arg2) => singleton_1.Return(_arg2.CompanyName))), []);
    return createElement("div", createObj(toList(delay(() => append(isLogout ? singleton_2(["onClick", (_arg1_1) => {
        setLogout(false);
    }]) : empty(), delay(() => append(singleton_2(["ref", navRef]), delay(() => append(singleton_2(["className", "has-background-success"]), delay(() => {
        let props_43, props_40, props_9, props_7, props_38;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(props_43 = singleton(["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["className", "is-fixed-top"], ["aria-label", "main navigation"], ["role", join(" ", ["navigation"])], ["children", Interop_reactApi.Children.toArray([(props_9 = singleton(["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["href", "/#/home"], ["className", "home"], ["className", "pl-5"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_1;
            return append(singleton_2((props_1 = ofArray([["className", "home_icon"], ["className", "pl-3"], ["className", "pr-5"], ["className", "has-text-white"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-home", "fa-lg"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_1))))), delay(() => ((companyName.tag === 1) ? singleton_2(createElement("button", {
                style: {
                    borderStyle: "none",
                },
                className: "is-size-5",
                className: "is-white",
                className: "is-loading",
                className: "is-inverted",
            })) : ((companyName.tag === 3) ? singleton_2(createElement("div", {
                children: ["Home"],
            })) : ((companyName.tag === 2) ? singleton_2(createElement("p", createObj(Helpers_combineClasses("", ofArray([["children", companyName.fields[0]], ["className", "home_text"]]))))) : singleton_2(createElement("button", {
                style: {
                    borderStyle: "none",
                },
                className: "is-size-5",
                className: "is-white",
                className: "is-loading",
                className: "is-inverted",
            })))))));
        }))))]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_7))))])]), createElement("div", createObj(Helpers_combineClasses("navbar-brand", props_9)))), createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement(Breadcrumbs, {
                list: listForBreadcrumb,
            })]),
        }), (props_38 = toList(delay(() => append(patternInput[0] ? singleton_2(["className", "is-active"]) : empty(), delay(() => {
            let props_36, props_22, props_34;
            return singleton_2(["children", Interop_reactApi.Children.toArray([(props_36 = ofArray([["style", {
                paddingRight: 30,
            }], ["aria-label", "dropdown navigation"], ["role", join(" ", ["navigator"])], ["children", Interop_reactApi.Children.toArray([createElement(SearchFieldHeader, null), (props_22 = toList(delay(() => append(singleton_2(["className", "mr-3"]), delay(() => append(singleton_2(["className", "has-dropdown"]), delay(() => append(singleton_2(["className", "is-hoverable"]), delay(() => append(singleton_2(["style", {
                alignItems: "center",
            }]), delay(() => append(isShowLang ? singleton_2(["className", "is-active"]) : empty(), delay(() => append(singleton_2(["onMouseLeave", (_arg2_1) => {
                setShowLang(false);
            }]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props_13, elms;
                return append(singleton_2(createElement("button", {
                    className: join(" ", ["userButton"]),
                    onClick: (_arg3) => {
                        setShowLang(!isLogout);
                    },
                    children: Interop_reactApi.Children.toArray([(props_13 = ofArray([["style", {
                        height: 1.3 + "rem",
                        marginRight: 5,
                    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fas", "fa-globe"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_13)))), createElement("span", {
                        children: LangModule_toString(getCurrentLanguage()),
                        className: "is-uppercase",
                    }), (elms = singleton(createElement("i", {
                        style: {
                            marginLeft: 5,
                        },
                        className: join(" ", ["fa", "fa-chevron-down"]),
                    })), createElement("span", {
                        className: "icon",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))]),
                })), delay(() => {
                    let elms_1;
                    return isShowLang ? singleton_2((elms_1 = toList(delay(() => map((lang_1) => {
                        const props_19 = ofArray([["className", "is-uppercase"], ["children", LangModule_toString(lang_1)], ["onClick", (_arg4) => {
                            setShowLang(false);
                            setCurrentLanguage(lang_1);
                            document.location.reload();
                        }]]);
                        return createElement("a", createObj(Helpers_combineClasses("navbar-item", props_19)));
                    }, getSupportedLanguages()))), createElement("div", {
                        className: "navbar-dropdown",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    }))) : empty();
                }));
            }))))]))))))))))))))), createElement("div", createObj(Helpers_combineClasses("navbar-item", props_22)))), (props_34 = toList(delay(() => append(singleton_2(["className", "has-dropdown"]), delay(() => append(singleton_2(["style", {
                alignItems: "center",
            }]), delay(() => append(isLogout ? singleton_2(["className", "is-active"]) : empty(), delay(() => append(singleton_2(["onMouseLeave", (_arg5) => {
                setLogout(false);
            }]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement("button", {
                className: join(" ", ["userButton"]),
                onClick: (_arg6) => {
                    setLogout(!isLogout);
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_25;
                    return append(singleton_2((props_25 = ofArray([["style", {
                        height: 1.3 + "rem",
                        marginRight: 5,
                    }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["far", "fa-user"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_25))))), delay(() => {
                        const userName = defaultArg(map_1((x_3) => (`${x_3.Name}`), getUser()), "");
                        return append(singleton_2(createElement("span", {
                            children: [userName],
                        })), delay(() => {
                            let elms_2;
                            return singleton_2((elms_2 = singleton(createElement("i", {
                                style: {
                                    marginLeft: 5,
                                },
                                className: join(" ", ["fa", "fa-chevron-down"]),
                            })), createElement("span", {
                                className: "icon",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                            })));
                        }));
                    }));
                })))),
            })), delay(() => {
                let elms_3, props_31;
                return isLogout ? singleton_2((elms_3 = singleton((props_31 = ofArray([["children", t("common.logOut")], ["onClick", (_arg7) => {
                    logoutCmd();
                }]]), createElement("div", createObj(Helpers_combineClasses("navbar-item", props_31))))), createElement("a", {
                    className: "navbar-dropdown",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))) : empty();
            }))))))]))))))))))), createElement("div", createObj(Helpers_combineClasses("navbar-item", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("navbar-end", props_36))))])]);
        })))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_38))))])]]), createElement("nav", createObj(Helpers_combineClasses("navbar", props_40)))), createElement("div", {
            className: "has-background-white",
            style: {
                position: "fixed",
                left: 0,
                zIndex: 10,
                height: 100 + "%",
                width: 70,
                top: 3.3 + "rem",
            },
            children: Interop_reactApi.Children.toArray([createElement(Element$, {
                icon: "fa-users",
                route: toPath(new Route(7, 1, new User_SystemUser(0), void 0)),
                colorIcon: ["className", "has-text-info"],
            }), createElement(Element$, {
                icon: "fa-gavel",
                route: toPath(new Route(3, new AuctionModeType(0), 1)),
                colorIcon: ["className", "has-text-primary"],
            }), createElement(Element$, {
                icon: "fa-shopping-cart",
                route: toPath(new Route(13)),
                colorIcon: ["className", "has-text-danger"],
            }), createElement(Element$, {
                icon: "fa-truck",
                route: toPath(new Route(23, 1)),
                colorIcon: ["className", "has-text-warning"],
            }), createElement(Element$, {
                icon: "fa-chart-pie",
                route: toPath(new Route(27)),
                colorIcon: ["className", "has-text-grey-dark"],
            }), createElement(Element$, {
                icon: "fa-tools",
                route: toPath(new Route(31)),
                colorIcon: ["className", "has-text-grey-dark"],
            }), createElement(Element$, {
                icon: "fa-inbox",
                route: toPath(new Route(14, 1)),
                colorIcon: ["className", "has-text-grey-dark"],
            })]),
        })])]), createElement("div", createObj(Helpers_combineClasses("container", props_43))))])]);
    }))))))))));
}

