import { Union, Record } from "../../fable_modules/fable-library.3.6.1/Types.js";
import { option_type, record_type, bool_type, list_type, union_type, string_type, unit_type, class_type } from "../../fable_modules/fable-library.3.6.1/Reflection.js";
import { Submissions_TransportInfo, Submissions_TransportPeriod, Submissions_AgreementTransportInfo, Submissions_ContactPerson, User_Address, Submissions_TransportInfo$reflection, Submissions_AgreementTransportInfo$reflection } from "../../Shared/Shared.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../../Extensions.js";
import { PhoneModule_toString, PhoneModule_ofString, PhoneModule_empty } from "../../bidflow/src/infrastructure/Auction.js";
import { fromDateTimeOffset } from "../../fable_modules/fable-library.3.6.1/Date.js";
import { empty } from "../../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { fromDate } from "../../fable_modules/fable-library.3.6.1/DateOffset.js";
import { createObj, equals } from "../../fable_modules/fable-library.3.6.1/Util.js";
import { submissionsApi } from "../../Communication.js";
import { t } from "../../Localization.js";
import { Transport_validateTransportInfo } from "../../Validation.js";
import { map, cons, empty as empty_1, singleton as singleton_1, ofArray, fold } from "../../fable_modules/fable-library.3.6.1/List.js";
import { createElement } from "react";
import * as react from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "../../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../../fable_modules/fable-library.3.6.1/String.js";
import { Locale, OnChange, Disabled, ClassName, custom, DateFormat, Id, flatpickr } from "../../fable_modules/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import { React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67 } from "../../fable_modules/Feliz.1.45.0/React.fs.js";
import { Impl_createRemoveOptions, Impl_adjustPassive } from "../../fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { empty as empty_2, singleton as singleton_2, append, delay, toList } from "../../fable_modules/fable-library.3.6.1/Seq.js";
import { CountriesInput_getSortedList, Form_getFieldError } from "../../Common.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { dividedTop } from "../BorderDividedLine.js";

export class State extends Record {
    constructor(SubmissionId, Info, OriginalInfo, SaveFormOperation, FormErrors, IsEditMode) {
        super();
        this.SubmissionId = SubmissionId;
        this.Info = Info;
        this.OriginalInfo = OriginalInfo;
        this.SaveFormOperation = SaveFormOperation;
        this.FormErrors = FormErrors;
        this.IsEditMode = IsEditMode;
    }
}

export function State$reflection() {
    return record_type("Components.AgreementTransport.State", [], State, () => [["SubmissionId", class_type("System.Int64")], ["Info", Submissions_AgreementTransportInfo$reflection()], ["OriginalInfo", Submissions_AgreementTransportInfo$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsEditMode", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SwitchEditMode", "CancelChangesProfile", "UpdateSelectedStartDate", "UpdateSelectedEndDate", "HasRoyaltiesChanged", "FirstNameChanged", "LastNameChanged", "PhoneChanged", "AddressChanged", "ZipCodeChanged", "CityChanged", "CountryChanged", "ModifyPersonalInfo"];
    }
}

export function Msg$reflection() {
    return union_type("Components.AgreementTransport.Msg", [], Msg, () => [[], [], [["Item", option_type(class_type("System.DateTime"))]], [["Item", option_type(class_type("System.DateTime"))]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item1", option_type(Submissions_TransportInfo$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function emptyTransportInfo(residentCountry) {
    return new Submissions_AgreementTransportInfo(void 0, void 0, false, new User_Address("", "", "", residentCountry), new Submissions_ContactPerson("", "", PhoneModule_empty));
}

export function withTransportInfo(info, state, errors) {
    return new State(state.SubmissionId, info, state.OriginalInfo, state.SaveFormOperation, errors, state.IsEditMode);
}

export function init(transportInfo, residentCountry, submissionId) {
    let matchValue, matchValue_1;
    let transport;
    if (transportInfo == null) {
        transport = emptyTransportInfo(residentCountry);
    }
    else {
        const info = transportInfo;
        transport = (new Submissions_AgreementTransportInfo((matchValue = info.Period, (matchValue == null) ? (void 0) : fromDateTimeOffset(matchValue.StartDate, 0)), (matchValue_1 = info.Period, (matchValue_1 == null) ? (void 0) : fromDateTimeOffset(matchValue_1.EndDate, 0)), info.IsCleaningNeeded, new User_Address(info.Address.Address, info.Address.ZipCode, info.Address.City, info.Address.Country), new Submissions_ContactPerson(info.ContactPerson.FirstName, info.ContactPerson.LastName, info.ContactPerson.Phone)));
    }
    return [new State(submissionId, transport, transport, new Deferred$1(0), empty(), false), Cmd_none()];
}

export function saveTransportInfo(residentCountry, submissionId, info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let endDate, startDate;
        let period;
        const matchValue = [info.StartDate, info.EndDate];
        period = ((matchValue[0] == null) ? ((matchValue[1] == null) ? (void 0) : (void 0)) : ((matchValue[1] == null) ? (void 0) : ((endDate = matchValue[1], (startDate = matchValue[0], new Submissions_TransportPeriod(fromDate(startDate), fromDate(endDate)))))));
        const arg = equals(info, emptyTransportInfo(residentCountry)) ? (void 0) : (new Submissions_TransportInfo(period, info.IsCleaningNeeded, new User_Address(info.Address.Address, info.Address.ZipCode, info.Address.City, info.Address.Country), new Submissions_ContactPerson(info.ContactPerson.FirstName, info.ContactPerson.LastName, info.ContactPerson.Phone)));
        return singleton.Bind(submissionsApi().modifyTransportInfo([submissionId, arg]), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Msg(12, arg, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.fromServer.agreementDoesNotExist"))))) : singleton.Return(new Msg(12, arg, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))));
    }), (_arg2) => singleton.Return(new Msg(12, void 0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function update(residentCountry, msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12, inputRecord_13, inputRecord_14, inputRecord_15, inputRecord_16, inputRecord_17, inputRecord_18;
    if (msg.tag === 1) {
        return [new State(state.SubmissionId, state.OriginalInfo, state.OriginalInfo, state.SaveFormOperation, state.FormErrors, false), Cmd_none()];
    }
    else if (msg.tag === 2) {
        const patternInput = Transport_validateTransportInfo((inputRecord = state.Info, new Submissions_AgreementTransportInfo(msg.fields[0], inputRecord.EndDate, inputRecord.IsCleaningNeeded, inputRecord.Address, inputRecord.ContactPerson)));
        return [withTransportInfo(patternInput[0], state, patternInput[1]), Cmd_none()];
    }
    else if (msg.tag === 3) {
        const patternInput_1 = Transport_validateTransportInfo((inputRecord_1 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_1.StartDate, msg.fields[0], inputRecord_1.IsCleaningNeeded, inputRecord_1.Address, inputRecord_1.ContactPerson)));
        return [withTransportInfo(patternInput_1[0], state, patternInput_1[1]), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new State(state.SubmissionId, (inputRecord_2 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_2.StartDate, inputRecord_2.EndDate, msg.fields[0], inputRecord_2.Address, inputRecord_2.ContactPerson)), state.OriginalInfo, state.SaveFormOperation, state.FormErrors, state.IsEditMode), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new State(state.SubmissionId, (inputRecord_3 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_3.StartDate, inputRecord_3.EndDate, inputRecord_3.IsCleaningNeeded, inputRecord_3.Address, (inputRecord_4 = state.Info.ContactPerson, new Submissions_ContactPerson(msg.fields[0], inputRecord_4.LastName, inputRecord_4.Phone)))), state.OriginalInfo, state.SaveFormOperation, state.FormErrors, state.IsEditMode), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new State(state.SubmissionId, (inputRecord_5 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_5.StartDate, inputRecord_5.EndDate, inputRecord_5.IsCleaningNeeded, inputRecord_5.Address, (inputRecord_6 = state.Info.ContactPerson, new Submissions_ContactPerson(inputRecord_6.FirstName, msg.fields[0], inputRecord_6.Phone)))), state.OriginalInfo, state.SaveFormOperation, state.FormErrors, state.IsEditMode), Cmd_none()];
    }
    else if (msg.tag === 7) {
        const patternInput_2 = (msg.fields[0] === PhoneModule_empty) ? [(inputRecord_7 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_7.StartDate, inputRecord_7.EndDate, inputRecord_7.IsCleaningNeeded, inputRecord_7.Address, (inputRecord_8 = state.Info.ContactPerson, new Submissions_ContactPerson(inputRecord_8.FirstName, inputRecord_8.LastName, msg.fields[0])))), empty()] : Transport_validateTransportInfo((inputRecord_9 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_9.StartDate, inputRecord_9.EndDate, inputRecord_9.IsCleaningNeeded, inputRecord_9.Address, (inputRecord_10 = state.Info.ContactPerson, new Submissions_ContactPerson(inputRecord_10.FirstName, inputRecord_10.LastName, msg.fields[0])))));
        return [withTransportInfo(patternInput_2[0], state, patternInput_2[1]), Cmd_none()];
    }
    else if (msg.tag === 9) {
        const patternInput_3 = Transport_validateTransportInfo((inputRecord_11 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_11.StartDate, inputRecord_11.EndDate, inputRecord_11.IsCleaningNeeded, (inputRecord_12 = state.Info.Address, new User_Address(inputRecord_12.Address, msg.fields[0], inputRecord_12.City, inputRecord_12.Country)), inputRecord_11.ContactPerson)));
        return [withTransportInfo(patternInput_3[0], state, patternInput_3[1]), Cmd_none()];
    }
    else if (msg.tag === 8) {
        const patternInput_4 = Transport_validateTransportInfo((inputRecord_13 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_13.StartDate, inputRecord_13.EndDate, inputRecord_13.IsCleaningNeeded, (inputRecord_14 = state.Info.Address, new User_Address(msg.fields[0], inputRecord_14.ZipCode, inputRecord_14.City, inputRecord_14.Country)), inputRecord_13.ContactPerson)));
        return [withTransportInfo(patternInput_4[0], state, patternInput_4[1]), Cmd_none()];
    }
    else if (msg.tag === 10) {
        const patternInput_5 = Transport_validateTransportInfo((inputRecord_15 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_15.StartDate, inputRecord_15.EndDate, inputRecord_15.IsCleaningNeeded, (inputRecord_16 = state.Info.Address, new User_Address(inputRecord_16.Address, inputRecord_16.ZipCode, msg.fields[0], inputRecord_16.Country)), inputRecord_15.ContactPerson)));
        return [withTransportInfo(patternInput_5[0], state, patternInput_5[1]), Cmd_none()];
    }
    else if (msg.tag === 11) {
        const patternInput_6 = Transport_validateTransportInfo((inputRecord_17 = state.Info, new Submissions_AgreementTransportInfo(inputRecord_17.StartDate, inputRecord_17.EndDate, inputRecord_17.IsCleaningNeeded, (inputRecord_18 = state.Info.Address, new User_Address(inputRecord_18.Address, inputRecord_18.ZipCode, inputRecord_18.City, msg.fields[0])), inputRecord_17.ContactPerson)));
        return [withTransportInfo(patternInput_6[0], state, patternInput_6[1]), Cmd_none()];
    }
    else if (msg.tag === 12) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 0) {
                return init(msg.fields[0], residentCountry, state.SubmissionId);
            }
            else {
                return [new State(state.SubmissionId, state.Info, state.OriginalInfo, new Deferred$1(2, new FSharpResult$2(1, msg.fields[1].fields[0].fields[0])), state.FormErrors, state.IsEditMode), Cmd_none()];
            }
        }
        else {
            return [new State(state.SubmissionId, state.Info, state.OriginalInfo, new Deferred$1(1), state.FormErrors, state.IsEditMode), Cmd_fromAsync(saveTransportInfo(residentCountry, state.SubmissionId, state.Info))];
        }
    }
    else {
        return [new State(state.SubmissionId, state.Info, state.OriginalInfo, state.SaveFormOperation, state.FormErrors, true), Cmd_none()];
    }
}

export function ErrorsHelpBlock(errors) {
    const props = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]);
    return createElement("p", createObj(Helpers_combineClasses("help", props)));
}

export function transportSection(state, dispatch) {
    let elms_2, props_9, props, props_7, props_2, elms, props_4, props_20, props_25, props_23, elms_11, props_37, props_47, props_57, props_70, elms_18, props_82, props_92, props_102, props_114;
    return react.createElement(react.Fragment, {}, (elms_2 = singleton_1(createElement("fieldset", {
        disabled: !state.IsEditMode,
        children: Interop_reactApi.Children.toArray([(props_9 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.startDate")]]), createElement("label", createObj(Helpers_combineClasses("label", props)))), (props_7 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", join(" ", ["is-expanded"])], ["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([Id(`TransportStartDate${state.SubmissionId}`), DateFormat("d M Y"), custom("value", state.Info.StartDate, false), custom("locale", {
            firstDayOfWeek: 1,
        }, true), ClassName("input"), Disabled(false), OnChange((x) => {
            dispatch(new Msg(2, x));
        }), Locale(Swedish)]))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_2)))), (elms = singleton_1((props_4 = ofArray([["children", t("common.clear")], ["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new Msg(2, void 0));
            const eventType = "DOMContentLoaded";
            const action_1 = (_arg1) => {
                document.getElementById(`TransportStartDate${state.SubmissionId}`).flatpickr().clear();
            };
            const options_1 = void 0;
            const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_1), [options_1]);
            const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_1), [options_1]);
            const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
                action_1(arg);
            }), [action_1]);
            useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
                if (addOptions == null) {
                    document.addEventListener(eventType, fn);
                }
                else {
                    document.addEventListener(eventType, fn, addOptions);
                }
                return React_createDisposable_3A5B6456(() => {
                    if (removeOptions == null) {
                        document.removeEventListener(eventType, fn);
                    }
                    else {
                        document.removeEventListener(eventType, fn, removeOptions);
                    }
                });
            }));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_7))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_9)))), (props_20 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_11;
            const errors = Form_getFieldError(state.FormErrors, "Period");
            return append(singleton_2((props_11 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.endDate")]]), createElement("label", createObj(Helpers_combineClasses("label", props_11))))), delay(() => {
                let props_18, props_13, elms_1, props_15;
                return append(singleton_2((props_18 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", join(" ", ["is-expanded"])], ["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([Id(`TransportEndDate${state.SubmissionId}`), DateFormat("d M Y"), custom("value", state.Info.EndDate, false), custom("locale", {
                    firstDayOfWeek: 1,
                }, true), ClassName("input"), Disabled(false), OnChange((x_2) => {
                    dispatch(new Msg(3, x_2));
                })]))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_13)))), (elms_1 = singleton_1((props_15 = ofArray([["children", t("common.clear")], ["onClick", (x_3) => {
                    x_3.preventDefault();
                    dispatch(new Msg(3, void 0));
                    const eventType_1 = "DOMContentLoaded";
                    const action_3 = (_arg2) => {
                        document.getElementById(`TransportEndDate${state.SubmissionId}`).flatpickr().clear();
                    };
                    const options_5 = void 0;
                    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_5), [options_5]);
                    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_5), [options_5]);
                    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
                        action_3(arg_1);
                    }), [action_3]);
                    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
                        if (addOptions_1 == null) {
                            document.addEventListener(eventType_1, fn_1);
                        }
                        else {
                            document.addEventListener(eventType_1, fn_1, addOptions_1);
                        }
                        return React_createDisposable_3A5B6456(() => {
                            if (removeOptions_1 == null) {
                                document.removeEventListener(eventType_1, fn_1);
                            }
                            else {
                                document.removeEventListener(eventType_1, fn_1, removeOptions_1);
                            }
                        });
                    }));
                }]]), createElement("button", createObj(Helpers_combineClasses("button", props_15))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_18))))), delay(() => singleton_2(ErrorsHelpBlock(errors))));
            }));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_20)))), (props_25 = ofArray([["className", "pt-5"], ["className", "mt-1"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
            className: join(" ", ["is-checkradio"]),
            id: "exampleCheckboxDefault",
            type: "checkbox",
            name: "exampleCheckboxDefault",
            onChange: (ev) => {
                dispatch(new Msg(4, ev.target.checked));
            },
            checked: state.Info.IsCleaningNeeded,
        }), (props_23 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.movingSubmission")], ["htmlFor", "exampleCheckboxDefault"]]), createElement("label", createObj(Helpers_combineClasses("label", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_25))))]),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (elms_11 = singleton_1(createElement("fieldset", {
        disabled: !state.IsEditMode,
        children: Interop_reactApi.Children.toArray([(props_37 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_4, props_31, props_29, elms_3, props_33;
            const errors_1 = Form_getFieldError(state.FormErrors, "Address");
            return singleton_2((elms_4 = ofArray([(props_31 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_29 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props_29))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_31)))), (elms_3 = singleton_1((props_33 = toList(delay(() => append((!equals(errors_1, empty_1())) ? singleton_2(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_2(["onChange", (ev_1) => {
                dispatch(new Msg(8, ev_1.target.value));
            }]), delay(() => append(singleton_2(["placeholder", t("users.enterAddress")]), delay(() => {
                let value_86;
                return singleton_2((value_86 = state.Info.Address.Address, ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_86)) : false) {
                        e.value = value_86;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_33)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            })), ErrorsHelpBlock(errors_1)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_37)))), (props_47 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_6, props_41, props_39, elms_5, props_43;
            const errors_2 = Form_getFieldError(state.FormErrors, "ZipCode");
            return singleton_2((elms_6 = ofArray([(props_41 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_39 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.zipCode")]]), createElement("label", createObj(Helpers_combineClasses("label", props_39))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_41)))), (elms_5 = singleton_1((props_43 = toList(delay(() => append((!equals(errors_2, empty_1())) ? singleton_2(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_2(["onChange", (ev_2) => {
                dispatch(new Msg(9, ev_2.target.value));
            }]), delay(() => append(singleton_2(["placeholder", t("users.enterZipCode")]), delay(() => {
                let value_112;
                return singleton_2((value_112 = state.Info.Address.ZipCode, ["ref", (e_1) => {
                    if ((!(e_1 == null)) ? (!equals(e_1.value, value_112)) : false) {
                        e_1.value = value_112;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_43)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            })), ErrorsHelpBlock(errors_2)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_47)))), (props_57 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_8, props_51, props_49, elms_7, props_53;
            const errors_3 = Form_getFieldError(state.FormErrors, "City");
            return singleton_2((elms_8 = ofArray([(props_51 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_49 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.city")]]), createElement("label", createObj(Helpers_combineClasses("label", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_51)))), (elms_7 = singleton_1((props_53 = toList(delay(() => append((!equals(errors_3, empty_1())) ? singleton_2(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_2(["onChange", (ev_3) => {
                dispatch(new Msg(10, ev_3.target.value));
            }]), delay(() => append(singleton_2(["placeholder", t("users.enterCity")]), delay(() => {
                let value_138;
                return singleton_2((value_138 = state.Info.Address.City, ["ref", (e_2) => {
                    if ((!(e_2 == null)) ? (!equals(e_2.value, value_138)) : false) {
                        e_2.value = value_138;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_53)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
            })), ErrorsHelpBlock(errors_3)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_57)))), (props_70 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_10, props_61, props_59, elms_9, patternInput;
            const errors_4 = Form_getFieldError(state.FormErrors, "Country");
            return singleton_2((elms_10 = ofArray([(props_61 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_59 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.country")]]), createElement("label", createObj(Helpers_combineClasses("label", props_59))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_61)))), (elms_9 = singleton_1((patternInput = Helpers_extractClasses(toList(delay(() => append((!equals(errors_4, empty_1())) ? singleton_2(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_2(["className", "is-fullwidth"]), delay(() => append(singleton_2(["className", "has-text-left"]), delay(() => append(singleton_2(["className", "mb-1"]), delay(() => append(singleton_2(["className", "has-text-grey"]), delay(() => append(singleton_2(["onChange", (ev_4) => {
                dispatch(new Msg(11, ev_4.target.value));
            }]), delay(() => append(singleton_2(["value", state.Info.Address.Country]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => createElement("option", {
                value: tupledArg[0],
                children: tupledArg[1],
            }), CountriesInput_getSortedList())))])))))))))))))))))), createElement("div", {
                className: join(" ", cons("select", patternInput[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
            }))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
            })), ErrorsHelpBlock(errors_4)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_70))))]),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
    })), (elms_18 = singleton_1(createElement("fieldset", {
        disabled: !state.IsEditMode,
        children: Interop_reactApi.Children.toArray([(props_82 = toList(delay(() => {
            let elms_13, props_76, props_74, elms_12, props_78;
            const errors_5 = Form_getFieldError(state.FormErrors, "FirstName");
            return singleton_2(["children", Interop_reactApi.Children.toArray([(elms_13 = ofArray([(props_76 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_74 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.firstName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_74))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_76)))), (elms_12 = singleton_1((props_78 = toList(delay(() => append((!equals(errors_5, empty_1())) ? singleton_2(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_2(["onChange", (ev_5) => {
                dispatch(new Msg(5, ev_5.target.value));
            }]), delay(() => append(singleton_2(["placeholder", t("users.enterFirstName")]), delay(() => {
                let value_206;
                return singleton_2((value_206 = state.Info.ContactPerson.FirstName, ["ref", (e_3) => {
                    if ((!(e_3 == null)) ? (!equals(e_3.value, value_206)) : false) {
                        e_3.value = value_206;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_78)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
            })), ErrorsHelpBlock(errors_5)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
            }))])]);
        })), createElement("div", createObj(Helpers_combineClasses("field", props_82)))), (props_92 = toList(delay(() => {
            let elms_15, props_86, props_84, elms_14, props_88;
            const errors_6 = Form_getFieldError(state.FormErrors, "LastName");
            return singleton_2(["children", Interop_reactApi.Children.toArray([(elms_15 = ofArray([(props_86 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_84 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.lastName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_84))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_86)))), (elms_14 = singleton_1((props_88 = toList(delay(() => append((!equals(errors_6, empty_1())) ? singleton_2(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_2(["onChange", (ev_6) => {
                dispatch(new Msg(6, ev_6.target.value));
            }]), delay(() => append(singleton_2(["placeholder", t("users.enterLastName")]), delay(() => {
                let value_232;
                return singleton_2((value_232 = state.Info.ContactPerson.LastName, ["ref", (e_4) => {
                    if ((!(e_4 == null)) ? (!equals(e_4.value, value_232)) : false) {
                        e_4.value = value_232;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_88)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
            })), ErrorsHelpBlock(errors_6)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
            }))])]);
        })), createElement("div", createObj(Helpers_combineClasses("field", props_92)))), (props_102 = toList(delay(() => {
            let elms_17, props_96, props_94, elms_16, props_98;
            const errors_7 = Form_getFieldError(state.FormErrors, "Phone");
            return singleton_2(["children", Interop_reactApi.Children.toArray([(elms_17 = ofArray([(props_96 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_94 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.phoneNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_94))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_96)))), (elms_16 = singleton_1((props_98 = toList(delay(() => append((!equals(errors_7, empty_1())) ? singleton_2(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_2(["onChange", (ev_7) => {
                dispatch(new Msg(7, PhoneModule_ofString(ev_7.target.value)));
            }]), delay(() => append(singleton_2(["placeholder", "+46000000000"]), delay(() => {
                let value_259;
                return singleton_2((value_259 = PhoneModule_toString(state.Info.ContactPerson.Phone), ["ref", (e_5) => {
                    if ((!(e_5 == null)) ? (!equals(e_5.value, value_259)) : false) {
                        e_5.value = value_259;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_98)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
            })), ErrorsHelpBlock(errors_7)]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
            }))])]);
        })), createElement("div", createObj(Helpers_combineClasses("field", props_102))))]),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
    })), (props_114 = ofArray([["className", "has-text-right"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_106, children_19, props_108, props_111;
        return (!state.IsEditMode) ? singleton_2((props_106 = ofArray([["className", "has-background-grey-lighter"], ["children", t("users.editTransport")], ["onClick", (_arg3) => {
            dispatch(new Msg(0));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_106))))) : singleton_2(createElement("div", {
            children: Interop_reactApi.Children.toArray([(children_19 = singleton_1((props_108 = toList(delay(() => append(singleton_2(["className", "mb-3"]), delay(() => append(singleton_2(["children", t("common.cancel")]), delay(() => append(equals(state.SaveFormOperation, new Deferred$1(1)) ? singleton_2(["className", "is-static"]) : empty_2(), delay(() => singleton_2(["onClick", (_arg4) => {
                dispatch(new Msg(1));
            }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_108))))), createElement("p", {
                children: Interop_reactApi.Children.toArray(Array.from(children_19)),
            })), (props_111 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["children", t("common.save")]), delay(() => append(singleton_2(["disabled", equals(state.Info, state.OriginalInfo) ? true : (!state.FormErrors.Equals(empty()))]), delay(() => append(equals(state.SaveFormOperation, new Deferred$1(1)) ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => singleton_2(["onClick", (x_9) => {
                x_9.preventDefault();
                dispatch(new Msg(12, void 0, new AsyncOperationStatus$1(0)));
            }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_111))))]),
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_114)))));
}

export function TransportComponent(transportComponentInputProps) {
    let elms, props_2, props;
    const residentCountry = transportComponentInputProps.residentCountry;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(transportComponentInputProps.info, residentCountry, transportComponentInputProps.submissionId), (msg, state) => update(residentCountry, msg, state), []);
    const props_5 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_2 = ofArray([["className", "is-one-fifth"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-weight-bold"], ["className", "mb-3"], ["children", t("users.transportInformation")]]), createElement("p", createObj(Helpers_combineClasses("", props))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), transportSection(patternInput[0], patternInput[1])]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_5)));
}

