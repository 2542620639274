import { Record, Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { AccountDetailsArgModule_extractUserId, Route_AccountDetailsSubmissions_7639071A, AuctionArg, AuctionState, toPath, navigateTo, Route, modifyUrl, PayOutArg$reflection, EditLotArg$reflection, AccountDetailsArg$reflection, AuctionArg$reflection } from "./Router.js";
import { PayOuts_PayoutStatus, Orders_PickUpStatus, User_SystemUser, AuctionModeType, BreadcrumbList, CurrencyFormatSettings$reflection, SignIn_SignedInUser$reflection, Orders_TransactionsFilter$reflection, Orders_CashReportType$reflection, PayOuts_PayoutStatus$reflection, Orders_PickUpStatus$reflection, Submissions_PageType$reflection, User_SystemUser$reflection, GpsPosition$reflection, AuctionModeType$reflection } from "./Shared/Shared.js";
import { record_type, union_type, string_type, option_type, class_type, int32_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi } from "./Communication.js";
import { Currency_Settings_settings, Currency_Settings_Cache__Update_Z7FCDC21D } from "./Common.js";
import { map, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { getUser } from "./LocalStorage.js";
import { Cmd_OfFunc_result, Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { ofArray, singleton as singleton_2, empty } from "./fable_modules/fable-library.3.6.1/List.js";
import { tWithArgs, t } from "./Localization.js";
import { equals } from "./fable_modules/fable-library.3.6.1/Long.js";
import { AuctionIdModule_zero } from "./bidflow/src/infrastructure/Auction.js";
import { createElement } from "react";
import * as react from "react";
import { loaderBig } from "./Loader.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { RenderHeader } from "./Header.js";
import { loginPage } from "./Login.js";
import { Auctions } from "./Auctions.js";
import { Render } from "./Auction.js";
import { View_Render } from "./EditAuction.js";
import { Render as Render_1 } from "./CreateLotAndItem.js";
import { renderPage } from "./Agreements.js";
import { renderPage as renderPage_1 } from "./Users.js";
import { SignUpComponent } from "./SingUp.js";
import { Render as Render_2 } from "./Profile.js";
import { Component } from "./Users/AccountDetails.js";
import { Render as Render_3 } from "./Agreement.js";
import { render } from "./Orders.js";
import { Render as Render_4 } from "./Submissions.js";
import { Render as Render_5 } from "./CreateItem.js";
import { Render as Render_6 } from "./EditItem.js";
import { Render as Render_7, renderSettings } from "./GlobalCategories.js";
import { LoadCatalogAndEdit } from "./EditWarehouseFees.js";
import { Render as Render_8 } from "./Items.js";
import { Render as Render_9 } from "./EditLot.js";
import { view as view_1 } from "./DeveloperPage.js";
import { Render as Render_10 } from "./Submission.js";
import { Render as Render_11 } from "./Transport.js";
import { Render as Render_12 } from "./PickUp.js";
import { Render as Render_13 } from "./CheckOut.js";
import { Render as Render_14 } from "./Shipping.js";
import { renderAccounting, render as render_1 } from "./Reports/Economy.js";
import { Component_Instance } from "./SettingsSubmission.js";
import { Render as Render_15 } from "./SettingsSystem.js";
import { Render as Render_16 } from "./SettingsPayout.js";
import { Render as Render_17 } from "./SettingsAuction.js";
import { View_Render as View_Render_1 } from "./SettingsPayment.js";
import { RenderFreightSettings } from "./SettingsFreight.js";
import { render as render_2 } from "./SettingsOverview.js";
import { Main_Render } from "./SettingsLot.js";
import { Component_Instance as Component_Instance_1 } from "./SettingsUser.js";
import { Component_Instance as Component_Instance_2 } from "./SettingsEmail.js";
import { render as render_3 } from "./Reports/AuctionReports.js";
import { Render as Render_18 } from "./Reports/TenderReport.js";
import { Render as Render_19 } from "./Reports/FinalPriceReport.js";
import { Render as Render_20 } from "./PayOuts.js";
import { Render as Render_21 } from "./Reports/CashReport.js";
import { Render as Render_22 } from "./CalendarShipping.js";
import { Render as Render_23 } from "./Reports/ControlReport.js";
import { Render as Render_24 } from "./Reports/ResultsReport.js";
import { Render as Render_25 } from "./Reports/ResultsReportV1.js";
import { Render as Render_26 } from "./Reports/ResaleRightsReport.js";
import { Render as Render_27 } from "./Reports/PayOutReport.js";
import { Render as Render_28 } from "./Reports/PresaleReport.js";
import { Render as Render_29 } from "./Reports/VATFreeReport.js";
import { View_RenderView } from "./Payout/PayoutAndSelectSubmission.js";
import { Render as Render_30 } from "./AggregatedSearch.js";
import { Render as Render_31 } from "./ContentManagement.js";
import { Render as Render_32 } from "./Reports/ClaimsReport.js";
import { Render as Render_33 } from "./Reports/KU91Report.js";
import { Render as Render_34 } from "./Reports/ReviseReport.js";
import { render as render_4 } from "./Home.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Home", "Login", "Auction", "Auctions", "EditAuction", "AddAuctionLot", "Users", "SingUp", "Agreements", "Profile", "AccountDetails", "Agreement", "Orders", "Submissions", "AddItem", "EditItem", "CategoriesSettings", "Categories", "EditWarehouseFees", "Items", "EditLot", "DeveloperPage", "Submission", "Transport", "PickUp", "CheckOut", "Shipping", "Economy", "SettingsSystem", "SettingsPayout", "SettingsFreight", "SettingsOverview", "SettingsLot", "SettingsAuction", "SettingsPayment", "SettingsEmail", "AuctionReports", "TenderReport", "FinalPriceReport", "Payments", "CashReport", "CalendarShipping", "ControlReport", "ResultsReport", "ResultsReportV1", "ResaleRightsReport", "PayOutReport", "PresaleReport", "VATFreeReport", "Payout", "AggregatedSearch", "SettingsSubmission", "SettingsUser", "ContentManagement", "ClaimsReport", "ClaimsReportWithPaid", "Accounting", "KU91Report", "ReviseReport"];
    }
}

export function Page$reflection() {
    return union_type("Index.Page", [], Page, () => [[], [], [["Item", AuctionArg$reflection()]], [["Item1", AuctionModeType$reflection()], ["Item2", int32_type]], [["Item", class_type("System.Int64")]], [["Item1", class_type("System.Int64")], ["Item2", option_type(int32_type)], ["Item3", option_type(class_type("System.Int64"))], ["Item4", option_type(GpsPosition$reflection())]], [["Item1", int32_type], ["Item2", User_SystemUser$reflection()], ["Item3", option_type(string_type)]], [], [], [["Item", class_type("System.Int64")]], [["Item", AccountDetailsArg$reflection()]], [["Item", class_type("System.Int64")]], [], [["Item", int32_type]], [["Item1", option_type(int32_type)], ["Item2", option_type(class_type("System.Int64"))]], [["Item", class_type("System.Int64")]], [], [["Item", class_type("System.Int64")]], [["Item", class_type("System.Int64")]], [["Item", int32_type]], [["Item", EditLotArg$reflection()]], [], [["Item1", class_type("System.Int64")], ["Item2", class_type("System.Int64")], ["Item3", Submissions_PageType$reflection()]], [["Item", int32_type]], [["Item1", int32_type], ["Item2", Orders_PickUpStatus$reflection()], ["Item3", option_type(string_type)]], [["Item", class_type("System.Int64")]], [["Item", int32_type]], [], [], [], [], [], [], [], [], [], [], [], [], [["Item1", int32_type], ["Item2", PayOuts_PayoutStatus$reflection()]], [["Item1", int32_type], ["Item2", Orders_CashReportType$reflection()], ["Item3", option_type(Orders_TransactionsFilter$reflection())]], [], [["Item", int32_type]], [], [], [], [], [], [], [["Item", PayOutArg$reflection()]], [["Item", option_type(string_type)]], [], [], [], [], [], [], [], []]);
}

export class User extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Anonymous", "Logged"];
    }
}

export function User$reflection() {
    return union_type("Index.User", [], User, () => [[], [["Item", SignIn_SignedInUser$reflection()]]]);
}

export class State extends Record {
    constructor(Settings, CurrentPage, User) {
        super();
        this.Settings = Settings;
        this.CurrentPage = CurrentPage;
        this.User = User;
    }
}

export function State$reflection() {
    return record_type("Index.State", [], State, () => [["Settings", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CurrencyFormatSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CurrencyFormatSettings$reflection()]], [["ErrorValue", string_type]]]))], ["CurrentPage", Page$reflection()], ["User", User$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoggedInApp", "Logout", "Unauthorized", "SettingsLoaded"];
    }
}

export function Msg$reflection() {
    return union_type("Index.Msg", [], Msg, () => [[["Item", SignIn_SignedInUser$reflection()]], [], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CurrencyFormatSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CurrencyFormatSettings$reflection()]], [["ErrorValue", string_type]]]))]]]);
}

export const Cmd_loadSettings = singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCurrencySettings()), (_arg1) => {
    const settings = _arg1;
    Currency_Settings_Cache__Update_Z7FCDC21D(Currency_Settings_settings, settings);
    return singleton.Return(new Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, settings))));
}));

export function urlUpdate(nextPage, state) {
    const user = defaultArg(map((arg0) => (new User(1, arg0)), getUser()), new User(0));
    if (user.tag === 1) {
        if (nextPage == null) {
            return [new State(state.Settings, new Page(0), state.User), modifyUrl(new Route(0))];
        }
        else if (nextPage.tag === 1) {
            return [new State(state.Settings, new Page(1), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 3) {
            const page_1 = nextPage.fields[1] | 0;
            const pageType = nextPage.fields[0];
            return [new State(state.Settings, new Page(3, pageType, page_1), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 2) {
            const auctionState = nextPage.fields[0];
            return [new State(state.Settings, new Page(2, auctionState), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 4) {
            const id = nextPage.fields[0];
            return [new State(state.Settings, new Page(4, id), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 5) {
            const auctionId = nextPage.fields[0];
            const categoryId = nextPage.fields[1];
            const gpsPosition = nextPage.fields[3];
            const submissionId = nextPage.fields[2];
            return [new State(state.Settings, new Page(5, auctionId, categoryId, submissionId, gpsPosition), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 7) {
            const page_2 = nextPage.fields[0] | 0;
            const search = nextPage.fields[2];
            const usersType = nextPage.fields[1];
            return [new State(state.Settings, new Page(6, page_2, usersType, search), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 8) {
            return [new State(state.Settings, new Page(7), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 9) {
            return [new State(state.Settings, new Page(8), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 10) {
            const userState = nextPage.fields[0];
            return [new State(state.Settings, new Page(9, userState), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 11) {
            const arg = nextPage.fields[0];
            return [new State(state.Settings, new Page(10, arg), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 12) {
            const agreementId = nextPage.fields[0];
            return [new State(state.Settings, new Page(11, agreementId), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 13) {
            return [new State(state.Settings, new Page(12), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 14) {
            const pageNumber = nextPage.fields[0] | 0;
            return [new State(state.Settings, new Page(13, pageNumber), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 6) {
            const categoryId_1 = nextPage.fields[0];
            const submissionId_1 = nextPage.fields[1];
            return [new State(state.Settings, new Page(14, categoryId_1, submissionId_1), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 15) {
            const id_1 = nextPage.fields[0];
            return [new State(state.Settings, new Page(15, id_1), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 16) {
            return [new State(state.Settings, new Page(16), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 32) {
            return [new State(state.Settings, new Page(51), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 33) {
            return [new State(state.Settings, new Page(52), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 17) {
            const id_2 = nextPage.fields[0];
            return [new State(state.Settings, new Page(17, id_2), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 18) {
            const id_3 = nextPage.fields[0];
            return [new State(state.Settings, new Page(18, id_3), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 19) {
            const page_3 = nextPage.fields[0] | 0;
            return [new State(state.Settings, new Page(19, page_3), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 20) {
            const arg_1 = nextPage.fields[0];
            return [new State(state.Settings, new Page(20, arg_1), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 21) {
            return [new State(state.Settings, new Page(21), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 22) {
            const pageType_1 = nextPage.fields[2];
            const sbm = nextPage.fields[1];
            const userId = nextPage.fields[0];
            return [new State(state.Settings, new Page(22, userId, sbm, pageType_1), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 23) {
            const p = nextPage.fields[0] | 0;
            return [new State(state.Settings, new Page(23, p), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 24) {
            const p_1 = nextPage.fields[0] | 0;
            const search_1 = nextPage.fields[2];
            const status = nextPage.fields[1];
            return [new State(state.Settings, new Page(24, p_1, status, search_1), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 25) {
            const userId_1 = nextPage.fields[0];
            return [new State(state.Settings, new Page(25, userId_1), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 26) {
            const p_2 = nextPage.fields[0] | 0;
            return [new State(state.Settings, new Page(26, p_2), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 27) {
            return [new State(state.Settings, new Page(27), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 29) {
            return [new State(state.Settings, new Page(29), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 38) {
            return [new State(state.Settings, new Page(35), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 30) {
            return [new State(state.Settings, new Page(30), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 28) {
            return [new State(state.Settings, new Page(28), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 35) {
            return [new State(state.Settings, new Page(33), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 36) {
            return [new State(state.Settings, new Page(34), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 31) {
            return [new State(state.Settings, new Page(31), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 34) {
            return [new State(state.Settings, new Page(32), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 39) {
            return [new State(state.Settings, new Page(36), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 40) {
            return [new State(state.Settings, new Page(37), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 42) {
            return [new State(state.Settings, new Page(38), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 43) {
            const p_3 = nextPage.fields[0] | 0;
            const status_1 = nextPage.fields[1];
            return [new State(state.Settings, new Page(39, p_3, status_1), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 44) {
            const filters = nextPage.fields[2];
            const p_4 = nextPage.fields[0] | 0;
            const status_2 = nextPage.fields[1];
            return [new State(state.Settings, new Page(40, p_4, status_2, filters), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 45) {
            return [new State(state.Settings, new Page(41), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 46) {
            const p_5 = nextPage.fields[0] | 0;
            return [new State(state.Settings, new Page(42, p_5), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 47) {
            return [new State(state.Settings, new Page(43), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 48) {
            return [new State(state.Settings, new Page(44), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 49) {
            return [new State(state.Settings, new Page(45), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 50) {
            return [new State(state.Settings, new Page(46), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 51) {
            return [new State(state.Settings, new Page(47), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 41) {
            return [new State(state.Settings, new Page(48), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 53) {
            const search_2 = nextPage.fields[0];
            return [new State(state.Settings, new Page(50, search_2), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 52) {
            const payoutState = nextPage.fields[0];
            return [new State(state.Settings, new Page(49, payoutState), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 37) {
            return [new State(state.Settings, new Page(53), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 54) {
            return [new State(state.Settings, new Page(54), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 55) {
            return [new State(state.Settings, new Page(55), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 56) {
            return [new State(state.Settings, new Page(56), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 57) {
            return [new State(state.Settings, new Page(57), state.User), Cmd_none()];
        }
        else if (nextPage.tag === 58) {
            return [new State(state.Settings, new Page(58), state.User), Cmd_none()];
        }
        else {
            return [new State(state.Settings, new Page(0), state.User), Cmd_none()];
        }
    }
    else {
        return [new State(state.Settings, new Page(1), state.User), modifyUrl(new Route(1))];
    }
}

export function init(page_1) {
    const user = defaultArg(map((arg0) => (new User(1, arg0)), getUser()), new User(0));
    const patternInput = urlUpdate(page_1, new State(new Deferred$1(0), new Page(0), user));
    return [patternInput[0], Cmd_batch(toList(delay(() => append(singleton_1(patternInput[1]), delay(() => ((user.tag === 1) ? singleton_1(Cmd_OfFunc_result(new Msg(3, new AsyncOperationStatus$1(0)))) : singleton_1(Cmd_none())))))))];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.Settings, state.CurrentPage, new User(0)), navigateTo(new Route(1))];
    }
    else if (msg.tag === 2) {
        return init(new Route(1));
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [new State(new Deferred$1(2, msg.fields[0].fields[0]), state.CurrentPage, state.User), Cmd_none()];
        }
        else {
            return [new State(new Deferred$1(1), state.CurrentPage, state.User), Cmd_fromAsync(Cmd_loadSettings)];
        }
    }
    else {
        return [new State(state.Settings, state.CurrentPage, new User(1, msg.fields[0])), Cmd_OfFunc_result(new Msg(3, new AsyncOperationStatus$1(0)))];
    }
}

export function breadcrumbsList(page_1) {
    switch (page_1.tag) {
        case 1: {
            return empty();
        }
        case 21: {
            return empty();
        }
        case 11: {
            return empty();
        }
        case 3: {
            return singleton_2(new BreadcrumbList("", t("common.auctions")));
        }
        case 2: {
            const auctionState = page_1.fields[0];
            const auctionId = (auctionState.tag === 1) ? auctionState.fields[0] : auctionState.fields[0];
            return ofArray([new BreadcrumbList(toPath(new Route(3, new AuctionModeType(0), 1)), t("common.auctions")), new BreadcrumbList("", tWithArgs("auction.auctionId", {
                auctionId: auctionId,
            }))]);
        }
        case 4: {
            const id_2 = page_1.fields[0];
            return ofArray([new BreadcrumbList(toPath(new Route(3, new AuctionModeType(0), 1)), t("common.auctions")), new BreadcrumbList(toPath(new Route(2, new AuctionArg(0, id_2, new AuctionState(false, false, 1)))), tWithArgs("auction.auctionId", {
                auctionId: id_2,
            })), new BreadcrumbList("", t("auction.editAuction"))]);
        }
        case 5: {
            const auctionId_1 = page_1.fields[0];
            return ofArray([new BreadcrumbList(toPath(new Route(3, new AuctionModeType(0), 1)), t("common.auctions")), new BreadcrumbList(toPath(new Route(2, new AuctionArg(0, auctionId_1, new AuctionState(false, false, 1)))), tWithArgs("auction.auctionId", {
                auctionId: auctionId_1,
            })), new BreadcrumbList("", t("auction.addLot"))]);
        }
        case 8: {
            return singleton_2(new BreadcrumbList("", t("users.agreements")));
        }
        case 6: {
            return singleton_2(new BreadcrumbList("", t("common.users")));
        }
        case 7: {
            return ofArray([new BreadcrumbList(toPath(new Route(7, 1, new User_SystemUser(0), void 0)), t("common.users")), new BreadcrumbList("", t("users.createAccount"))]);
        }
        case 9: {
            const profile = page_1.fields[0];
            return ofArray([new BreadcrumbList(toPath(new Route(7, 1, new User_SystemUser(0), void 0)), t("common.users")), new BreadcrumbList(toPath(Route_AccountDetailsSubmissions_7639071A(profile)), tWithArgs("users.userId", {
                userId: profile,
            })), new BreadcrumbList("", t("users.editProfile"))]);
        }
        case 10: {
            return ofArray([new BreadcrumbList(toPath(new Route(7, 1, new User_SystemUser(0), void 0)), t("common.users")), new BreadcrumbList("", tWithArgs("users.userId", {
                userId: AccountDetailsArgModule_extractUserId(page_1.fields[0]),
            }))]);
        }
        case 14: {
            return ofArray([new BreadcrumbList(toPath(new Route(3, new AuctionModeType(2), 1)), t("common.auctions")), new BreadcrumbList("", tWithArgs("auction.addItem", {
                itemId: (x) => x,
            }))]);
        }
        case 15: {
            return ofArray([new BreadcrumbList(toPath(new Route(3, new AuctionModeType(2), 1)), t("common.auctions")), new BreadcrumbList("", tWithArgs("auction.editItemId", {
                itemId: page_1.fields[0],
            }))]);
        }
        case 51: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("common.submission"))]);
        }
        case 52: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("common.users"))]);
        }
        case 16: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("settings.globalCategories"))]);
        }
        case 17: {
            const id_4 = page_1.fields[0];
            return ofArray([new BreadcrumbList(toPath(new Route(3, new AuctionModeType(0), 1)), t("common.auctions")), new BreadcrumbList(toPath(new Route(2, new AuctionArg(0, id_4, new AuctionState(false, false, 1)))), tWithArgs("auction.auctionId", {
                auctionId: id_4,
            })), new BreadcrumbList("", t("auction.editCategories"))]);
        }
        case 18: {
            const id_5 = page_1.fields[0];
            if (equals(id_5, AuctionIdModule_zero)) {
                return ofArray([new BreadcrumbList(toPath(new Route(16)), t("settings.globalCategories")), new BreadcrumbList("", t("auction.editWarehouseFee"))]);
            }
            else {
                return ofArray([new BreadcrumbList(toPath(new Route(3, new AuctionModeType(0), 1)), t("common.auctions")), new BreadcrumbList(toPath(new Route(2, new AuctionArg(0, id_5, new AuctionState(false, false, 1)))), tWithArgs("auction.auctionId", {
                    auctionId: id_5,
                })), new BreadcrumbList(toPath(new Route(17, id_5)), t("auction.editCategories")), new BreadcrumbList("", t("auction.editWarehouseFee"))]);
            }
        }
        case 19: {
            return singleton_2(new BreadcrumbList("", t("common.items")));
        }
        case 20: {
            const info = page_1.fields[0];
            const id_8 = (info.tag === 1) ? info.fields[0] : info.fields[0];
            return ofArray([new BreadcrumbList(toPath(new Route(3, new AuctionModeType(0), 1)), t("common.auctions")), new BreadcrumbList(toPath(new Route(2, new AuctionArg(0, id_8, new AuctionState(false, false, 1)))), tWithArgs("auction.auctionId", {
                auctionId: id_8,
            })), new BreadcrumbList("", t("auction.editLot"))]);
        }
        case 22: {
            const userId = page_1.fields[0];
            return ofArray([new BreadcrumbList(toPath(new Route(7, 1, new User_SystemUser(0), void 0)), t("common.users")), new BreadcrumbList(toPath(Route_AccountDetailsSubmissions_7639071A(userId)), tWithArgs("users.userId", {
                userId: userId,
            })), new BreadcrumbList("", tWithArgs("common.submissionWithId", {
                submissionId: page_1.fields[1],
            }))]);
        }
        case 13: {
            return singleton_2(new BreadcrumbList("", t("common.warehouse")));
        }
        case 23: {
            return singleton_2(new BreadcrumbList(toPath(new Route(23, page_1.fields[0])), t("common.transport")));
        }
        case 12: {
            return singleton_2(new BreadcrumbList("", t("home.cart")));
        }
        case 24: {
            return ofArray([new BreadcrumbList(toPath(new Route(13)), t("home.cart")), new BreadcrumbList("", t("orders.extradition"))]);
        }
        case 25: {
            return ofArray([new BreadcrumbList(toPath(new Route(13)), t("home.cart")), new BreadcrumbList(toPath(new Route(24, 1, new Orders_PickUpStatus(1), void 0)), t("orders.extradition")), new BreadcrumbList("", t("orders.checkOut"))]);
        }
        case 26: {
            return ofArray([new BreadcrumbList(toPath(new Route(13)), t("home.cart")), new BreadcrumbList("", t("orders.freightOverview"))]);
        }
        case 41: {
            return ofArray([new BreadcrumbList(toPath(new Route(13)), t("home.cart")), new BreadcrumbList(toPath(new Route(26, 1)), t("orders.freightOverview")), new BreadcrumbList("", t("orders.shippingCalendar"))]);
        }
        case 27: {
            return singleton_2(new BreadcrumbList("", t("reports.financialOverviewTitle")));
        }
        case 31: {
            return singleton_2(new BreadcrumbList("", t("settings.allSettings")));
        }
        case 32: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("settings.lotSettings"))]);
        }
        case 29: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("reports.payouts"))]);
        }
        case 30: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("settings.freightSettings"))]);
        }
        case 28: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("settings.settings"))]);
        }
        case 33: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("common.auctions"))]);
        }
        case 34: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("settings.payments"))]);
        }
        case 35: {
            return ofArray([new BreadcrumbList(toPath(new Route(31)), t("settings.allSettings")), new BreadcrumbList("", t("settings.emails.title"))]);
        }
        case 36: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList("", t("reports.auctionReportsTitle"))]);
        }
        case 56: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList("", t("reports.accounting"))]);
        }
        case 37: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(39)), t("reports.auctionReportsTitle")), new BreadcrumbList("", t("reports.tenderReport"))]);
        }
        case 38: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(39)), t("reports.auctionReportsTitle")), new BreadcrumbList("", t("reports.finalPriceReport"))]);
        }
        case 48: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(56)), t("reports.accounting")), new BreadcrumbList("", t("reports.VATWithinEU"))]);
        }
        case 39: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList("", t("reports.payouts"))]);
        }
        case 49: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(43, 1, new PayOuts_PayoutStatus(1))), t("reports.payouts")), new BreadcrumbList("", t("reports.payout"))]);
        }
        case 40: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(56)), t("reports.accounting")), new BreadcrumbList("", t("reports.cashReport"))]);
        }
        case 54: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(56)), t("reports.accounting")), new BreadcrumbList("", t("reports.refundsClaimsReport"))]);
        }
        case 55: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(56)), t("reports.accounting")), new BreadcrumbList("", t("reports.refundsClaimsReport"))]);
        }
        case 57: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(56)), t("reports.accounting")), new BreadcrumbList("", "KU91")]);
        }
        case 58: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(56)), t("reports.accounting")), new BreadcrumbList("", t("reports.revise"))]);
        }
        case 42: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList("", t("reports.controlReport"))]);
        }
        case 43: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(56)), t("reports.accounting")), new BreadcrumbList("", t("reports.resultsReport"))]);
        }
        case 44: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(56)), t("reports.accounting")), new BreadcrumbList("", t("reports.resultsReport"))]);
        }
        case 45: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(39)), t("reports.auctionReportsTitle")), new BreadcrumbList("", t("reports.resaleRightsReport"))]);
        }
        case 46: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(39)), t("reports.auctionReportsTitle")), new BreadcrumbList("", t("reports.payoutReport"))]);
        }
        case 50: {
            return singleton_2(new BreadcrumbList("", t("aggregatedSearch.aggregatedSearch")));
        }
        case 47: {
            return ofArray([new BreadcrumbList(toPath(new Route(27)), t("reports.financialOverviewTitle")), new BreadcrumbList(toPath(new Route(39)), t("reports.auctionReportsTitle")), new BreadcrumbList("", t("reports.presaleReport"))]);
        }
        case 53: {
            return singleton_2(new BreadcrumbList("", t("home.content")));
        }
        default: {
            return empty();
        }
    }
}

export function page(state, dispatch, content) {
    const currentPage = breadcrumbsList(state.CurrentPage);
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let elms;
        const matchValue = state.Settings;
        let pattern_matching_result;
        if (matchValue.tag === 1) {
            pattern_matching_result = 0;
        }
        else if (matchValue.tag === 2) {
            if (matchValue.fields[0].tag === 0) {
                pattern_matching_result = 1;
            }
            else {
                pattern_matching_result = 0;
            }
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton_1((elms = singleton_2(loaderBig()), createElement("div", {
                    className: "container",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })));
            }
            case 1: {
                return append(singleton_1(createElement(RenderHeader, {
                    listForBreadcrumb: currentPage,
                })), delay(() => singleton_1(createElement("div", {
                    style: {
                        paddingRight: 22,
                        paddingLeft: 100,
                        paddingTop: 3.3 + "rem",
                    },
                    className: "no-height-section-to-print",
                    className: "has-background-light",
                    children: Interop_reactApi.Children.toArray([content]),
                }))));
            }
        }
    })));
}

export function view(state, dispatch) {
    const matchValue = state.CurrentPage;
    switch (matchValue.tag) {
        case 1: {
            return loginPage((arg) => {
                dispatch(new Msg(0, arg));
            });
        }
        case 3: {
            return page(state, dispatch, createElement(Auctions, {
                pageType: matchValue.fields[0],
                page: matchValue.fields[1],
            }));
        }
        case 2: {
            return page(state, dispatch, createElement(Render, {
                auctionState: matchValue.fields[0],
            }));
        }
        case 4: {
            return page(state, dispatch, createElement(View_Render, {
                id: matchValue.fields[0],
            }));
        }
        case 5: {
            return page(state, dispatch, createElement(Render_1, {
                auctionId: matchValue.fields[0],
                categoryId: matchValue.fields[1],
                submissionId: matchValue.fields[2],
                gpsPosition: matchValue.fields[3],
            }));
        }
        case 8: {
            return page(state, dispatch, renderPage);
        }
        case 6: {
            return page(state, dispatch, renderPage_1(matchValue.fields[0], matchValue.fields[1], matchValue.fields[2]));
        }
        case 7: {
            return page(state, dispatch, createElement(SignUpComponent, null));
        }
        case 9: {
            return page(state, dispatch, createElement(Render_2, {
                userId: matchValue.fields[0],
            }));
        }
        case 10: {
            return page(state, dispatch, createElement(Component, {
                routeInfo: matchValue.fields[0],
            }));
        }
        case 11: {
            return page(state, dispatch, createElement(Render_3, {
                agreementId: matchValue.fields[0],
            }));
        }
        case 12: {
            return page(state, dispatch, render);
        }
        case 13: {
            return page(state, dispatch, createElement(Render_4, {
                page: matchValue.fields[0],
            }));
        }
        case 14: {
            return page(state, dispatch, createElement(Render_5, {
                categoryId: matchValue.fields[0],
                submissionId: matchValue.fields[1],
            }));
        }
        case 15: {
            return page(state, dispatch, createElement(Render_6, {
                itemId: matchValue.fields[0],
            }));
        }
        case 16: {
            return page(state, dispatch, renderSettings());
        }
        case 17: {
            return page(state, dispatch, createElement(Render_7, {
                auctionId: matchValue.fields[0],
            }));
        }
        case 18: {
            return page(state, dispatch, createElement(LoadCatalogAndEdit, {
                auctionId: matchValue.fields[0],
            }));
        }
        case 19: {
            return page(state, dispatch, createElement(Render_8, {
                page: matchValue.fields[0],
            }));
        }
        case 20: {
            return page(state, dispatch, createElement(Render_9, {
                arg: matchValue.fields[0],
            }));
        }
        case 21: {
            return page(state, dispatch, view_1());
        }
        case 22: {
            return page(state, dispatch, createElement(Render_10, {
                submissionId: matchValue.fields[1],
                pageType: matchValue.fields[2],
            }));
        }
        case 23: {
            return page(state, dispatch, createElement(Render_11, {
                page: matchValue.fields[0],
            }));
        }
        case 24: {
            return page(state, dispatch, createElement(Render_12, {
                page: matchValue.fields[0],
                status: matchValue.fields[1],
                searchString: matchValue.fields[2],
            }));
        }
        case 25: {
            return page(state, dispatch, createElement(Render_13, {
                userId: matchValue.fields[0],
            }));
        }
        case 26: {
            return page(state, dispatch, createElement(Render_14, {
                page: matchValue.fields[0],
            }));
        }
        case 27: {
            return page(state, dispatch, render_1);
        }
        case 56: {
            return page(state, dispatch, renderAccounting);
        }
        case 51: {
            return page(state, dispatch, createElement(Component_Instance, null));
        }
        case 28: {
            return page(state, dispatch, createElement(Render_15, null));
        }
        case 29: {
            return page(state, dispatch, createElement(Render_16, null));
        }
        case 33: {
            return page(state, dispatch, createElement(Render_17, null));
        }
        case 34: {
            return page(state, dispatch, createElement(View_Render_1, null));
        }
        case 30: {
            return page(state, dispatch, createElement(RenderFreightSettings, null));
        }
        case 31: {
            return page(state, dispatch, render_2);
        }
        case 32: {
            return page(state, dispatch, createElement(Main_Render, null));
        }
        case 52: {
            return page(state, dispatch, createElement(Component_Instance_1, null));
        }
        case 35: {
            return page(state, dispatch, createElement(Component_Instance_2, null));
        }
        case 36: {
            return page(state, dispatch, render_3);
        }
        case 37: {
            return page(state, dispatch, createElement(Render_18, {
                page: 1,
            }));
        }
        case 38: {
            return page(state, dispatch, createElement(Render_19, null));
        }
        case 39: {
            return page(state, dispatch, createElement(Render_20, {
                p: matchValue.fields[0],
                status: matchValue.fields[1],
            }));
        }
        case 40: {
            return page(state, dispatch, createElement(Render_21, {
                page: matchValue.fields[0],
                pageType: matchValue.fields[1],
                filters: matchValue.fields[2],
            }));
        }
        case 41: {
            return page(state, dispatch, createElement(Render_22, null));
        }
        case 42: {
            return page(state, dispatch, createElement(Render_23, {
                page: matchValue.fields[0],
            }));
        }
        case 43: {
            return page(state, dispatch, createElement(Render_24, null));
        }
        case 44: {
            return page(state, dispatch, createElement(Render_25, null));
        }
        case 45: {
            return page(state, dispatch, createElement(Render_26, null));
        }
        case 46: {
            return page(state, dispatch, createElement(Render_27, null));
        }
        case 47: {
            return page(state, dispatch, createElement(Render_28, null));
        }
        case 48: {
            return page(state, dispatch, createElement(Render_29, null));
        }
        case 49: {
            return page(state, dispatch, createElement(View_RenderView, {
                payoutState: matchValue.fields[0],
            }));
        }
        case 50: {
            return page(state, dispatch, createElement(Render_30, {
                searchString: matchValue.fields[0],
            }));
        }
        case 53: {
            return page(state, dispatch, createElement(Render_31, null));
        }
        case 54: {
            return page(state, dispatch, createElement(Render_32, {
                isPaidReport: false,
            }));
        }
        case 55: {
            return page(state, dispatch, createElement(Render_32, {
                isPaidReport: true,
            }));
        }
        case 57: {
            return page(state, dispatch, createElement(Render_33, null));
        }
        case 58: {
            return page(state, dispatch, createElement(Render_34, null));
        }
        default: {
            return page(state, dispatch, render_4());
        }
    }
}

