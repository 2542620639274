import { Record } from "../fable-library.3.6.1/Types.js";
import { record_type, class_type, tuple_type, list_type, lambda_type, unit_type } from "../fable-library.3.6.1/Reflection.js";

export class ElmishComponentProps$2 extends Record {
    constructor(Initial, Update, Render) {
        super();
        this.Initial = Initial;
        this.Update = Update;
        this.Render = Render;
    }
}

export function ElmishComponentProps$2$reflection(gen0, gen1) {
    return record_type("Feliz.ElmishComponents.ElmishComponentProps`2", [gen0, gen1], ElmishComponentProps$2, () => [["Initial", tuple_type(gen0, list_type(lambda_type(lambda_type(gen1, unit_type), unit_type)))], ["Update", lambda_type(gen1, lambda_type(gen0, tuple_type(gen0, list_type(lambda_type(lambda_type(gen1, unit_type), unit_type)))))], ["Render", lambda_type(gen0, lambda_type(lambda_type(gen1, unit_type), class_type("Fable.React.ReactElement")))]]);
}

