import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Browser_Types_File__File_ReadAsByteArray } from "../fable_modules/Fable.Remoting.Client.7.10.0/Extensions.fs.js";
import { imagesApi } from "../Communication.js";
import { Image$reflection, Images_UploadImageCommitInfo } from "../Shared/Shared.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { t } from "../Localization.js";
import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, record_type, option_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { map, defaultArg, value as value_23 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { ImageIdModule_toString } from "../bidflow/src/infrastructure/Auction.js";
import { newGuid } from "../fable_modules/fable-library.3.6.1/Guid.js";
import { RenderBigImagePng, RenderBigImage } from "./Image.js";

export function UploadImage(uploadImageInputProps) {
    const needDeleteButton = uploadImageInputProps.needDeleteButton;
    const onImageAdded = uploadImageInputProps.onImageAdded;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setError = patternInput[1];
    const error = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setInProgress = patternInput_1[1];
    const inProgress = patternInput_1[0];
    const uploadFileCallback = useReact_useCallback_93353E((arg) => {
        let file;
        startImmediate((file = arg, singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            setError("");
            setInProgress(true);
            return singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file), (_arg1) => singleton.Bind(imagesApi().uploadImage(_arg1), (_arg2) => {
                const args = new Images_UploadImageCommitInfo(_arg2, file.name);
                return singleton.Bind(Remoting_handleNonAuth(imagesApi().uploadImageCommit(args)), (_arg3) => {
                    if (_arg3.tag === 1) {
                        setError(_arg3.fields[0]);
                        setInProgress(false);
                        return singleton.Zero();
                    }
                    else {
                        setInProgress(false);
                        onImageAdded(_arg3.fields[0]);
                        return singleton.Zero();
                    }
                });
            }));
        }), (_arg4) => {
            setError(_arg4.message);
            setInProgress(false);
            return singleton.Zero();
        }))));
    }, []);
    const props_3 = toList(delay(() => append((!needDeleteButton) ? singleton_1(["className", "is-fullwidth"]) : empty(), delay(() => append(singleton_1(["className", "is-outlined"]), delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["className", "has-text-centered"]), delay(() => append(singleton_1(["disabled", inProgress]), delay(() => append(inProgress ? singleton_1(["className", "is-loading"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["tabIndex", -1], ["disabled", inProgress], ["onChange", (ev) => {
        const files = ev.target.files;
        if ((!(files == null)) ? (files.length > 0) : false) {
            uploadFileCallback(files.item(0));
        }
    }]]))))), createElement("span", {
        style: {
            alignItems: "center",
        },
        children: inProgress ? "" : ((error !== "") ? error : t("auction.updateImage")),
    })])])))))))))))))));
    return createElement("span", createObj(Helpers_combineClasses("button", props_3)));
}

export class State extends Record {
    constructor(Image) {
        super();
        this.Image = Image;
    }
}

export function State$reflection() {
    return record_type("Components.DownloadBackgroundImage.State", [], State, () => [["Image", option_type(Image$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnImageAdded", "ImageDeleted"];
    }
}

export function Msg$reflection() {
    return union_type("Components.DownloadBackgroundImage.Msg", [], Msg, () => [[["Item", Image$reflection()]], []]);
}

export function init(image, unitVar1) {
    return [new State(image), Cmd_none()];
}

export function update(onImagesChanged, onDeleteImage, msg, state) {
    if (msg.tag === 1) {
        onDeleteImage(void 0);
        return [new State(void 0), Cmd_none()];
    }
    else {
        const newState = new State(msg.fields[0]);
        onImagesChanged(value_23(newState.Image));
        return [newState, Cmd_none()];
    }
}

export function EditImages(editImagesInputProps) {
    const flag = editImagesInputProps.flag;
    const needDeleteButton = editImagesInputProps.needDeleteButton;
    const onDeleteImage = editImagesInputProps.onDeleteImage;
    const onImagesChanged = editImagesInputProps.onImagesChanged;
    const image = editImagesInputProps.image;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => init(image, void 0), (msg, state) => update(onImagesChanged, onDeleteImage, msg, state), editImagesInputProps.dependencies);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(toList(delay(() => {
        let copyOfStruct;
        return append(singleton_1(["key", defaultArg(map((x) => ImageIdModule_toString(x.Id), state_1.Image), (copyOfStruct = newGuid(), copyOfStruct))]), delay(() => {
            let elms;
            return singleton_1(["children", Interop_reactApi.Children.toArray([createElement(RenderBigImage, {
                image: state_1.Image,
                flag: flag,
            }), (elms = toList(delay(() => append(singleton_1(createElement(UploadImage, {
                onImageAdded: (arg) => {
                    dispatch(new Msg(0, arg));
                },
                needDeleteButton: needDeleteButton,
            })), delay(() => {
                let props;
                return needDeleteButton ? singleton_1((props = ofArray([["className", "is-danger"], ["children", t("common.delete")], ["onClick", (_arg1) => {
                    dispatch(new Msg(1));
                }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))) : empty();
            })))), createElement("div", {
                className: "buttons",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]);
        }));
    }))));
}

export class Png_State extends Record {
    constructor(Image) {
        super();
        this.Image = Image;
    }
}

export function Png_State$reflection() {
    return record_type("Components.DownloadBackgroundImage.Png.State", [], Png_State, () => [["Image", option_type(Image$reflection())]]);
}

export class Png_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnImageAdded", "ImageDeleted"];
    }
}

export function Png_Msg$reflection() {
    return union_type("Components.DownloadBackgroundImage.Png.Msg", [], Png_Msg, () => [[["Item", Image$reflection()]], []]);
}

export function Png_init(image, unitVar1) {
    return [new Png_State(image), Cmd_none()];
}

export function Png_update(onImagesChanged, onDeleteImage, msg, state) {
    if (msg.tag === 1) {
        onDeleteImage(void 0);
        return [new Png_State(void 0), Cmd_none()];
    }
    else {
        const newState = new Png_State(msg.fields[0]);
        onImagesChanged(value_23(newState.Image));
        return [newState, Cmd_none()];
    }
}

export function Png_EditImages(png_EditImagesInputProps) {
    const flag = png_EditImagesInputProps.flag;
    const needDeleteButton = png_EditImagesInputProps.needDeleteButton;
    const onDeleteImage = png_EditImagesInputProps.onDeleteImage;
    const onImagesChanged = png_EditImagesInputProps.onImagesChanged;
    const image = png_EditImagesInputProps.image;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => Png_init(image, void 0), (msg, state) => Png_update(onImagesChanged, onDeleteImage, msg, state), png_EditImagesInputProps.dependencies);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(toList(delay(() => {
        let copyOfStruct;
        return append(singleton_1(["key", defaultArg(map((x) => ImageIdModule_toString(x.Id), state_1.Image), (copyOfStruct = newGuid(), copyOfStruct))]), delay(() => {
            let elms;
            return singleton_1(["children", Interop_reactApi.Children.toArray([createElement(RenderBigImagePng, {
                image: state_1.Image,
                flag: flag,
            }), (elms = toList(delay(() => append(singleton_1(createElement(UploadImage, {
                onImageAdded: (arg) => {
                    dispatch(new Png_Msg(0, arg));
                },
                needDeleteButton: needDeleteButton,
            })), delay(() => (needDeleteButton ? singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-danger"], ["children", "Radera"], ["onClick", (_arg1) => {
                dispatch(new Png_Msg(1));
            }]]))))) : empty()))))), createElement("div", {
                className: "buttons",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]);
        }));
    }))));
}

