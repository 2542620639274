import { Record, Union } from "../fable_modules/fable-library.3.6.1/Types.js";
import { bool_type, list_type, option_type, int32_type, class_type, record_type, string_type, union_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { CurrencyModule_round, CurrencyModule_zero, CountryCodeModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { op_Subtraction, op_Addition } from "../fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "../fable_modules/fable-library.3.6.1/Decimal.js";
import { equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { exists, sumBy, length, filter } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Long.js";

export class PayoutAccountType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ResidentBank", "Iban"];
    }
}

export function PayoutAccountType$reflection() {
    return union_type("Shared.Payout.PayoutAccountType", [], PayoutAccountType, () => [[], []]);
}

export class BankInfo extends Record {
    constructor(ClearingNumber, BankNumber, Bank, PayoutAccountType, IbanCountry, Iban) {
        super();
        this.ClearingNumber = ClearingNumber;
        this.BankNumber = BankNumber;
        this.Bank = Bank;
        this.PayoutAccountType = PayoutAccountType;
        this.IbanCountry = IbanCountry;
        this.Iban = Iban;
    }
}

export function BankInfo$reflection() {
    return record_type("Shared.Payout.BankInfo", [], BankInfo, () => [["ClearingNumber", string_type], ["BankNumber", string_type], ["Bank", string_type], ["PayoutAccountType", PayoutAccountType$reflection()], ["IbanCountry", string_type], ["Iban", string_type]]);
}

export function BankInfo_get_Zero() {
    return new BankInfo("", "", "", new PayoutAccountType(0), CountryCodeModule_empty, "");
}

export function BankInfo__get_IsFilled(this$) {
    if (this$.PayoutAccountType.tag === 1) {
        if (this$.Iban.trim() !== "") {
            return this$.IbanCountry !== CountryCodeModule_empty;
        }
        else {
            return false;
        }
    }
    else if (this$.BankNumber.trim() !== "") {
        return this$.ClearingNumber.trim() !== "";
    }
    else {
        return false;
    }
}

export class IncludedInPayout_Lot extends Record {
    constructor(LotId, ItemId, AuctionId, PriceIncludedCorporateTax, CommissionPercent, Commission, PhotoCommission, HammerFee, Name, LotNumber, ToPayout) {
        super();
        this.LotId = LotId;
        this.ItemId = ItemId;
        this.AuctionId = AuctionId;
        this.PriceIncludedCorporateTax = PriceIncludedCorporateTax;
        this.CommissionPercent = CommissionPercent;
        this.Commission = Commission;
        this.PhotoCommission = PhotoCommission;
        this.HammerFee = HammerFee;
        this.Name = Name;
        this.LotNumber = (LotNumber | 0);
        this.ToPayout = ToPayout;
    }
}

export function IncludedInPayout_Lot$reflection() {
    return record_type("Shared.Payout.IncludedInPayout.Lot", [], IncludedInPayout_Lot, () => [["LotId", class_type("System.Int64")], ["ItemId", class_type("System.Int64")], ["AuctionId", class_type("System.Int64")], ["PriceIncludedCorporateTax", class_type("System.Decimal")], ["CommissionPercent", class_type("System.Decimal")], ["Commission", class_type("System.Decimal")], ["PhotoCommission", class_type("System.Decimal")], ["HammerFee", class_type("System.Decimal")], ["Name", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["LotNumber", int32_type], ["ToPayout", class_type("System.Decimal")]]);
}

export class IncludedInPayout_OneTimeFees extends Record {
    constructor(Transport, Administrative, Cites, Recycling) {
        super();
        this.Transport = Transport;
        this.Administrative = Administrative;
        this.Cites = Cites;
        this.Recycling = Recycling;
    }
}

export function IncludedInPayout_OneTimeFees$reflection() {
    return record_type("Shared.Payout.IncludedInPayout.OneTimeFees", [], IncludedInPayout_OneTimeFees, () => [["Transport", class_type("System.Decimal")], ["Administrative", class_type("System.Decimal")], ["Cites", class_type("System.Decimal")], ["Recycling", class_type("System.Decimal")]]);
}

export class IncludedInPayout_Fees extends Record {
    constructor(CommissionPercent, Photo, OneTime) {
        super();
        this.CommissionPercent = CommissionPercent;
        this.Photo = Photo;
        this.OneTime = OneTime;
    }
}

export function IncludedInPayout_Fees$reflection() {
    return record_type("Shared.Payout.IncludedInPayout.Fees", [], IncludedInPayout_Fees, () => [["CommissionPercent", class_type("System.Decimal")], ["Photo", class_type("System.Decimal")], ["OneTime", option_type(IncludedInPayout_OneTimeFees$reflection())]]);
}

export class IncludedInPayout_Submission extends Record {
    constructor(Id, SellerId, SellerName, Fees, Lots, PayoutAccount, TotalPayout) {
        super();
        this.Id = Id;
        this.SellerId = SellerId;
        this.SellerName = SellerName;
        this.Fees = Fees;
        this.Lots = Lots;
        this.PayoutAccount = PayoutAccount;
        this.TotalPayout = TotalPayout;
    }
}

export function IncludedInPayout_Submission$reflection() {
    return record_type("Shared.Payout.IncludedInPayout.Submission", [], IncludedInPayout_Submission, () => [["Id", class_type("System.Int64")], ["SellerId", class_type("System.Int64")], ["SellerName", string_type], ["Fees", IncludedInPayout_Fees$reflection()], ["Lots", list_type(IncludedInPayout_Lot$reflection())], ["PayoutAccount", PayoutAccountType$reflection()], ["TotalPayout", class_type("System.Decimal")]]);
}

export function IncludedInPayout_Submission__get_OneTimeFee(this$) {
    const matchValue = this$.Fees.OneTime;
    if (matchValue == null) {
        return CurrencyModule_zero;
    }
    else {
        const x = matchValue;
        return op_Addition(op_Addition(op_Addition(x.Administrative, x.Cites), x.Recycling), x.Transport);
    }
}

export function IncludedInPayout_Submission__get_HasResidentBankInfoFormat(this$) {
    if (this$.PayoutAccount.tag === 1) {
        return false;
    }
    else {
        return true;
    }
}

export class IncludedInPayout_SubmissionsByPayoutResult extends Record {
    constructor(Total, Submissions) {
        super();
        this.Total = Total;
        this.Submissions = Submissions;
    }
}

export function IncludedInPayout_SubmissionsByPayoutResult$reflection() {
    return record_type("Shared.Payout.IncludedInPayout.SubmissionsByPayoutResult", [], IncludedInPayout_SubmissionsByPayoutResult, () => [["Total", class_type("System.Decimal")], ["Submissions", list_type(IncludedInPayout_Submission$reflection())]]);
}

export class PerAuction_LotStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Sold", "Unsold", "Withdrawn", "Claimed", "Refunded", "Returned", "Recycled"];
    }
}

export function PerAuction_LotStatus$reflection() {
    return union_type("Shared.Payout.PerAuction.LotStatus", [], PerAuction_LotStatus, () => [[], [], [], [], [], [], []]);
}

export class PerAuction_Lot extends Record {
    constructor(LotId, ItemId, AuctionId, OrderId, BuyerId, PriceWithSellerVAT, CommissionPercent, Commission, PhotoCommission, HammerFee, Name, Status, LotNumber, HasOrderFullyPaid, IncludedInPayout, ReceiptVersion) {
        super();
        this.LotId = LotId;
        this.ItemId = ItemId;
        this.AuctionId = AuctionId;
        this.OrderId = OrderId;
        this.BuyerId = BuyerId;
        this.PriceWithSellerVAT = PriceWithSellerVAT;
        this.CommissionPercent = CommissionPercent;
        this.Commission = Commission;
        this.PhotoCommission = PhotoCommission;
        this.HammerFee = HammerFee;
        this.Name = Name;
        this.Status = Status;
        this.LotNumber = (LotNumber | 0);
        this.HasOrderFullyPaid = HasOrderFullyPaid;
        this.IncludedInPayout = IncludedInPayout;
        this.ReceiptVersion = (ReceiptVersion | 0);
    }
}

export function PerAuction_Lot$reflection() {
    return record_type("Shared.Payout.PerAuction.Lot", [], PerAuction_Lot, () => [["LotId", class_type("System.Int64")], ["ItemId", class_type("System.Int64")], ["AuctionId", class_type("System.Int64")], ["OrderId", option_type(class_type("System.Int64"))], ["BuyerId", option_type(class_type("System.Int64"))], ["PriceWithSellerVAT", class_type("System.Decimal")], ["CommissionPercent", class_type("System.Decimal")], ["Commission", class_type("System.Decimal")], ["PhotoCommission", class_type("System.Decimal")], ["HammerFee", class_type("System.Decimal")], ["Name", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["Status", PerAuction_LotStatus$reflection()], ["LotNumber", int32_type], ["HasOrderFullyPaid", bool_type], ["IncludedInPayout", option_type(class_type("System.Int64"))], ["ReceiptVersion", int32_type]]);
}

export function PerAuction_Lot__get_IsSold(this$) {
    return equals(this$.Status, new PerAuction_LotStatus(0));
}

export function PerAuction_Lot__get_ToPayout(this$) {
    return op_Subtraction(op_Subtraction(op_Subtraction(this$.PriceWithSellerVAT, this$.Commission), this$.PhotoCommission), this$.HammerFee);
}

export class PerAuction_OneTimeFees extends Record {
    constructor(Transport, Administrative, Cites, Recycling, IncludedInAuction, IncludedInPayout) {
        super();
        this.Transport = Transport;
        this.Administrative = Administrative;
        this.Cites = Cites;
        this.Recycling = Recycling;
        this.IncludedInAuction = IncludedInAuction;
        this.IncludedInPayout = IncludedInPayout;
    }
}

export function PerAuction_OneTimeFees$reflection() {
    return record_type("Shared.Payout.PerAuction.OneTimeFees", [], PerAuction_OneTimeFees, () => [["Transport", class_type("System.Decimal")], ["Administrative", class_type("System.Decimal")], ["Cites", class_type("System.Decimal")], ["Recycling", class_type("System.Decimal")], ["IncludedInAuction", option_type(class_type("System.Int64"))], ["IncludedInPayout", option_type(class_type("System.Int64"))]]);
}

export class PerAuction_Fees extends Record {
    constructor(CommissionPercent, Photo, OneTime) {
        super();
        this.CommissionPercent = CommissionPercent;
        this.Photo = Photo;
        this.OneTime = OneTime;
    }
}

export function PerAuction_Fees$reflection() {
    return record_type("Shared.Payout.PerAuction.Fees", [], PerAuction_Fees, () => [["CommissionPercent", class_type("System.Decimal")], ["Photo", class_type("System.Decimal")], ["OneTime", PerAuction_OneTimeFees$reflection()]]);
}

export class PerAuction_Submission extends Record {
    constructor(Id, SellerId, SellerName, BankInfo, Fees, Lots) {
        super();
        this.Id = Id;
        this.SellerId = SellerId;
        this.SellerName = SellerName;
        this.BankInfo = BankInfo;
        this.Fees = Fees;
        this.Lots = Lots;
    }
}

export function PerAuction_Submission$reflection() {
    return record_type("Shared.Payout.PerAuction.Submission", [], PerAuction_Submission, () => [["Id", class_type("System.Int64")], ["SellerId", class_type("System.Int64")], ["SellerName", string_type], ["BankInfo", BankInfo$reflection()], ["Fees", PerAuction_Fees$reflection()], ["Lots", list_type(PerAuction_Lot$reflection())]]);
}

export function PerAuction_Submission__get_SoldLots(this$) {
    return filter((x) => PerAuction_Lot__get_IsSold(x), this$.Lots);
}

export function PerAuction_Submission__get_HasSoldLots(this$) {
    return length(PerAuction_Submission__get_SoldLots(this$)) > 0;
}

export function PerAuction_Submission__get_SoldTotal(this$) {
    return sumBy((x) => x.PriceWithSellerVAT, PerAuction_Submission__get_SoldLots(this$), {
        GetZero: () => (new Decimal(0)),
        Add: (x_1, y) => op_Addition(x_1, y),
    });
}

export function PerAuction_Submission__get_HasUnpaidOrders(this$) {
    return exists((x) => (!x.HasOrderFullyPaid), PerAuction_Submission__get_SoldLots(this$));
}

export function PerAuction_Submission__get_IsIbanPayoutAccount(this$) {
    return equals(this$.BankInfo.PayoutAccountType, new PayoutAccountType(1));
}

export function PerAuction_Submission__get_HasMissingBankInfo(this$) {
    if (this$.BankInfo.PayoutAccountType.tag === 1) {
        if (this$.BankInfo.Iban.trim() === "") {
            return true;
        }
        else {
            return this$.BankInfo.IbanCountry === CountryCodeModule_empty;
        }
    }
    else if (this$.BankInfo.BankNumber.trim() === "") {
        return true;
    }
    else {
        return this$.BankInfo.ClearingNumber.trim() === "";
    }
}

export function PerAuction_SubmissionModule_oneTimeFeeByAuctionId(auctionId, submission) {
    const matchValue = submission.Fees.OneTime.IncludedInAuction;
    let pattern_matching_result;
    if (matchValue != null) {
        if (equals_1(matchValue, auctionId)) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return op_Addition(op_Addition(op_Addition(submission.Fees.OneTime.Administrative, submission.Fees.OneTime.Cites), submission.Fees.OneTime.Recycling), submission.Fees.OneTime.Transport);
        }
        case 1: {
            return CurrencyModule_zero;
        }
    }
}

export function PerAuction_SubmissionModule_payoutByAuctionId(roundDecimalDigits, auctionId, s) {
    return op_Subtraction(CurrencyModule_round(roundDecimalDigits, sumBy((x_1) => op_Subtraction(op_Subtraction(op_Subtraction(x_1.PriceWithSellerVAT, x_1.Commission), x_1.PhotoCommission), x_1.HammerFee), filter((x) => equals_1(x.AuctionId, auctionId), PerAuction_Submission__get_SoldLots(s)), {
        GetZero: () => (new Decimal(0)),
        Add: (x_2, y) => op_Addition(x_2, y),
    })), PerAuction_SubmissionModule_oneTimeFeeByAuctionId(auctionId, s));
}

export class PerAuction_SubmissionWithPayout extends Record {
    constructor(Submission, PayoutTotal) {
        super();
        this.Submission = Submission;
        this.PayoutTotal = PayoutTotal;
    }
}

export function PerAuction_SubmissionWithPayout$reflection() {
    return record_type("Shared.Payout.PerAuction.SubmissionWithPayout", [], PerAuction_SubmissionWithPayout, () => [["Submission", PerAuction_Submission$reflection()], ["PayoutTotal", class_type("System.Decimal")]]);
}

export class Filter extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MissingBankInfo", "HasUnpaidOrders", "All"];
    }
}

export function Filter$reflection() {
    return union_type("Shared.Payout.Filter", [], Filter, () => [[], [], []]);
}

export class CountSubmissionsByAuction extends Record {
    constructor(Id, NumberOfLots) {
        super();
        this.Id = Id;
        this.NumberOfLots = (NumberOfLots | 0);
    }
}

export function CountSubmissionsByAuction$reflection() {
    return record_type("Shared.Payout.CountSubmissionsByAuction", [], CountSubmissionsByAuction, () => [["Id", class_type("System.Int64")], ["NumberOfLots", int32_type]]);
}

