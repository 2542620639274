import { toString, Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { RoyaltyReport_RoyaltyFilter, SortAuction, AuctionStatus, AuctionsEntity$reflection, SortAuction$reflection, RoyaltyReport_RoyaltyFilter$reflection, RoyaltyReport_Royalty$reflection, RoyaltyReport_RoyaltyStatus, RoyaltyReport_RoyaltyStatus$reflection } from "../Shared/Shared.js";
import { class_type, unit_type, list_type, int32_type, union_type, string_type, record_type, bool_type, option_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { length, tail, isEmpty, toArray, cons, item, head, map, append, tryFind, empty, singleton as singleton_1, filter, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingQuery$1$reflection, Common_PagingResult$1$reflection } from "../Shared/Common.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { auctionApi, royaltyApi } from "../Communication.js";
import { createObj, safeHash, equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { UserIdModule_toString, SubmissionIdModule_toString, ItemIdModule_toString, OrderNumberModule_toString, AuctionIdModule_toString, AuctionIdModule_fromString, AuctionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { tWithArgs, getLocalizationContext, Localized_translate, t } from "../Localization.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { List_distinct } from "../fable_modules/fable-library.3.6.1/Seq2.js";
import { defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { sv } from "date-fns/locale";
import { format as format_2 } from "date-fns";
import { toLocalTime, day, month, year, create, fromDate } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map as map_1, empty as empty_1, append as append_1, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Dates_endDateTimeSpanZero, Dates_startDateTimeSpanZero, Currency_format } from "../Common.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Locale, Disabled, OnManyChanged, ClassName, DateFormat, SelectionMode, custom, flatpickr } from "../fable_modules/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import { RoyaltiesToPrint_toPrintSection } from "../Print/PrintButtons.js";
import { loader } from "../Loader.js";
import { renderPagination } from "../Components/Pagination.js";

export class ToggleStatus extends Record {
    constructor(Status, IsCheck) {
        super();
        this.Status = Status;
        this.IsCheck = IsCheck;
    }
}

export function ToggleStatus$reflection() {
    return record_type("ResaleRightsReport.ToggleStatus", [], ToggleStatus, () => [["Status", option_type(RoyaltyReport_RoyaltyStatus$reflection())], ["IsCheck", bool_type]]);
}

export const toggleButtonStatus = ofArray([new ToggleStatus(void 0, true), new ToggleStatus(new RoyaltyReport_RoyaltyStatus(0), false), new ToggleStatus(new RoyaltyReport_RoyaltyStatus(1), false)]);

export class State extends Record {
    constructor(Royalties, CurrentPage, Query, Auction, AllAuction, FilterStatusButton, CheckedRoyalties, IsSave, HavePaidItems, Processing) {
        super();
        this.Royalties = Royalties;
        this.CurrentPage = (CurrentPage | 0);
        this.Query = Query;
        this.Auction = Auction;
        this.AllAuction = AllAuction;
        this.FilterStatusButton = FilterStatusButton;
        this.CheckedRoyalties = CheckedRoyalties;
        this.IsSave = IsSave;
        this.HavePaidItems = HavePaidItems;
        this.Processing = Processing;
    }
}

export function State$reflection() {
    return record_type("ResaleRightsReport.State", [], State, () => [["Royalties", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(RoyaltyReport_Royalty$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(RoyaltyReport_Royalty$reflection())]], [["ErrorValue", string_type]]]))], ["CurrentPage", int32_type], ["Query", Common_PagingQuery$1$reflection(RoyaltyReport_RoyaltyFilter$reflection())], ["Auction", SortAuction$reflection()], ["AllAuction", list_type(SortAuction$reflection())], ["FilterStatusButton", list_type(ToggleStatus$reflection())], ["CheckedRoyalties", list_type(RoyaltyReport_Royalty$reflection())], ["IsSave", bool_type], ["HavePaidItems", bool_type], ["Processing", Deferred$1$reflection(unit_type)]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadRoyalties", "LoadAuctions", "PageChanged", "OnColumnClicked", "ChangeAuction", "StatusChange", "CheckedRoyaltiesAdded", "CheckedRoyaltiesRemoved", "MarkAsPaid", "CheckThatAreNotPaid", "ChangeIsSave", "ChangeHavePaidItems", "DatePeriodChanged"];
    }
}

export function Msg$reflection() {
    return union_type("ResaleRightsReport.Msg", [], Msg, () => [[["Item1", Common_PagingQuery$1$reflection(RoyaltyReport_RoyaltyFilter$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(RoyaltyReport_Royalty$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(RoyaltyReport_Royalty$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AuctionsEntity$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(AuctionsEntity$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item", ToggleStatus$reflection()]], [["Item", RoyaltyReport_Royalty$reflection()]], [["Item", RoyaltyReport_Royalty$reflection()]], [["Item1", list_type(RoyaltyReport_Royalty$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item", list_type(RoyaltyReport_Royalty$reflection())]], [], [], [["Item", list_type(class_type("System.DateTime"))]]]);
}

export function loadRoyalties(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(royaltyApi().getRoyalties(query)), (_arg1) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export const loadAuctions = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAllAuctions()), (_arg1) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, filter((x) => equals(x.Status, new AuctionStatus(0)), _arg1))))))), (_arg2) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function markAsPaid(list) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(royaltyApi().markAsPaid(list)), () => singleton.Return(new Msg(8, list, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)))))), (_arg2) => singleton.Return(new Msg(8, list, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export function init(query) {
    return [new State(new Deferred$1(0), 1, query, new SortAuction(AuctionIdModule_empty, t("common.all")), singleton_1(new SortAuction(AuctionIdModule_empty, t("common.all"))), toggleButtonStatus, empty(), false, false, new Deferred$1(0)), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(0, query, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Msg(1, new AsyncOperationStatus$1(0)))]))];
}

export function update(msg, state) {
    let s, inputRecord_3, inputRecord_4, matchValue_1, inputRecord_6, inputRecord_5, inputRecord_7;
    if (msg.tag === 7) {
        return [new State(state.Royalties, state.CurrentPage, state.Query, state.Auction, state.AllAuction, state.FilterStatusButton, List_distinct(filter((rId) => (!equals(rId, msg.fields[0])), state.CheckedRoyalties), {
            Equals: (x_3, y_1) => equals(x_3, y_1),
            GetHashCode: (x_3) => safeHash(x_3),
        }), state.IsSave, state.HavePaidItems, state.Processing), Cmd_none()];
    }
    else if (msg.tag === 0) {
        if (msg.fields[1].tag === 1) {
            if (!equals(msg.fields[0], state.Query)) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(new Deferred$1(2, msg.fields[1].fields[0]), state.CurrentPage, state.Query, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_none()];
            }
        }
        else {
            return [new State(new Deferred$1(1), state.CurrentPage, state.Query, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_fromAsync(loadRoyalties(msg.fields[0]))];
        }
    }
    else if (msg.tag === 2) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Common_PagingQuery$1(inputRecord.Payload, msg.fields[0], inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(state.Royalties, msg.fields[0], newQuery, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 3) {
        let newQuery_1;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_1 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, inputRecord_1.Page, inputRecord_1.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_1.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_2 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, inputRecord_2.Page, inputRecord_2.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_2.LocalizationContext));
                break;
            }
        }
        return [new State(state.Royalties, state.CurrentPage, newQuery_1, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 4) {
        let newAuction;
        const value = new SortAuction(AuctionIdModule_empty, "All");
        newAuction = defaultArg(tryFind((x_5) => equals_1(x_5.Id, AuctionIdModule_fromString(msg.fields[0])), state.AllAuction), value);
        let newQuery_2;
        const query_2 = state.Query;
        newQuery_2 = (new Common_PagingQuery$1(equals_1(newAuction.Id, AuctionIdModule_empty) ? ((inputRecord_3 = state.Query.Payload, new RoyaltyReport_RoyaltyFilter(void 0, inputRecord_3.ByStatus, inputRecord_3.StartDate, inputRecord_3.EndDate))) : ((inputRecord_4 = state.Query.Payload, new RoyaltyReport_RoyaltyFilter(newAuction.Id, inputRecord_4.ByStatus, inputRecord_4.StartDate, inputRecord_4.EndDate))), 1, query_2.PageSize, query_2.SortBy, query_2.LocalizationContext));
        return [new State(state.Royalties, state.CurrentPage, newQuery_2, newAuction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery_2, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                const newList = append(state.AllAuction, map((x_8) => {
                    let locale, formatOptions;
                    return new SortAuction(x_8.Id, (Localized_translate(x_8.Name) + ", ") + ((locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_2(x_8.Date, "dd MMMM yyyy", formatOptions))))));
                }, filter((x_7) => equals(x_7.Status, new AuctionStatus(0)), msg.fields[0].fields[0].fields[0])));
                return [new State(state.Royalties, state.CurrentPage, state.Query, head(newList), newList, state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(4, AuctionIdModule_toString(head(newList).Id)))];
            }
        }
        else {
            return [new State(state.Royalties, state.CurrentPage, state.Query, state.Auction, singleton_1(new SortAuction(AuctionIdModule_empty, t("common.all"))), state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_fromAsync(loadAuctions)];
        }
    }
    else if (msg.tag === 5) {
        let newQuery_3;
        const query_3 = state.Query;
        newQuery_3 = (new Common_PagingQuery$1((matchValue_1 = msg.fields[0].Status, (matchValue_1 != null) ? ((inputRecord_6 = state.Query.Payload, new RoyaltyReport_RoyaltyFilter(inputRecord_6.AuctionId, matchValue_1, inputRecord_6.StartDate, inputRecord_6.EndDate))) : ((inputRecord_5 = state.Query.Payload, new RoyaltyReport_RoyaltyFilter(inputRecord_5.AuctionId, void 0, inputRecord_5.StartDate, inputRecord_5.EndDate)))), 1, query_3.PageSize, query_3.SortBy, query_3.LocalizationContext));
        if (msg.fields[0].Status != null) {
            return [new State(state.Royalties, state.CurrentPage, newQuery_3, state.Auction, state.AllAuction, map((x_10) => {
                if (equals(x_10.Status, msg.fields[0].Status)) {
                    return new ToggleStatus(msg.fields[0].Status, !msg.fields[0].IsCheck);
                }
                else if (x_10.Status == null) {
                    return new ToggleStatus(void 0, false);
                }
                else if (!equals(x_10.Status, msg.fields[0].Status)) {
                    return new ToggleStatus(x_10.Status, false);
                }
                else {
                    return x_10;
                }
            }, state.FilterStatusButton), state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery_3, new AsyncOperationStatus$1(0)))];
        }
        else {
            return [new State(state.Royalties, state.CurrentPage, newQuery_3, state.Auction, state.AllAuction, toggleButtonStatus, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery_3, new AsyncOperationStatus$1(0)))];
        }
    }
    else if (msg.tag === 8) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 0) {
                return init(state.Query);
            }
            else {
                return [state, Cmd_none()];
            }
        }
        else {
            return [new State(state.Royalties, state.CurrentPage, state.Query, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, new Deferred$1(1)), Cmd_fromAsync(markAsPaid(msg.fields[0]))];
        }
    }
    else if (msg.tag === 10) {
        return [new State(state.Royalties, state.CurrentPage, state.Query, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, !state.IsSave, state.HavePaidItems, state.Processing), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [new State(state.Royalties, state.CurrentPage, state.Query, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, !state.HavePaidItems, state.Processing), Cmd_none()];
    }
    else if (msg.tag === 9) {
        const result_2 = tryFind((x_11) => equals(x_11.Status, new RoyaltyReport_RoyaltyStatus(0)), msg.fields[0]);
        if (result_2 != null) {
            return [new State(state.Royalties, state.CurrentPage, state.Query, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, false, true, state.Processing), Cmd_none()];
        }
        else {
            return [new State(state.Royalties, state.CurrentPage, state.Query, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, true, state.HavePaidItems, state.Processing), Cmd_none()];
        }
    }
    else if (msg.tag === 12) {
        const startDate = fromDate(item(0, msg.fields[0]));
        const endDate = fromDate(item(1, msg.fields[0]));
        let newQuery_4;
        const inputRecord_8 = state.Query;
        newQuery_4 = (new Common_PagingQuery$1((inputRecord_7 = state.Query.Payload, new RoyaltyReport_RoyaltyFilter(inputRecord_7.AuctionId, inputRecord_7.ByStatus, create(year(startDate), month(startDate), day(startDate), 0, 0, 0, 0), create(year(endDate), month(endDate), day(endDate), 23, 59, 59, 0))), inputRecord_8.Page, inputRecord_8.PageSize, inputRecord_8.SortBy, inputRecord_8.LocalizationContext));
        return [new State(state.Royalties, state.CurrentPage, newQuery_4, state.Auction, state.AllAuction, state.FilterStatusButton, state.CheckedRoyalties, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery_4, new AsyncOperationStatus$1(0)))];
    }
    else {
        return [new State(state.Royalties, state.CurrentPage, state.Query, state.Auction, state.AllAuction, state.FilterStatusButton, List_distinct(append(singleton_1(msg.fields[0]), state.CheckedRoyalties), {
            Equals: (x_1, y) => equals(x_1, y),
            GetHashCode: (x_1) => safeHash(x_1),
        }), state.IsSave, state.HavePaidItems, state.Processing), Cmd_none()];
    }
}

export function tableHeader(state, dispatch) {
    let props_10;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(3, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_2(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_2(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    const props_24 = ofArray([["className", "is-fluid"], ["className", "mb-2"], ["className", "px-2"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: join(" ", ["royaltyLine", "tableHeader"]),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "is-narrow",
            className: "px-0",
            children: Interop_reactApi.Children.toArray([(props_10 = ofArray([["style", {
                visibility: "hidden",
            }], ["children", Interop_reactApi.Children.toArray([createElement("input", {
                className: join(" ", ["is-checkradio"]),
                type: "checkbox",
                disabled: true,
            }), createElement("label", {})])]]), createElement("div", createObj(Helpers_combineClasses("field", props_10))))]),
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.lotNumber"), "id")]),
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.itemId"), "id")]),
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.title"), "title")]),
        }), createElement("div", {
            className: "has-text-left",
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.auctionDate"), "auctionDate")]),
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.submissionId"), "submission")]),
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("orders.paymentDate"), "transactionDate")]),
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("reports.finalPrice"), "price")]),
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.customerId"), "buyerId")]),
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.resaleRights"), "royalty")]),
        }), createElement("div", {
            className: "has-text-right",
            children: Interop_reactApi.Children.toArray([sortingColumnButton(t("common.status"), "status")]),
        })]),
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_24)));
}

export function TableLine(tableLineInputProps) {
    let props_2, date, locale, formatOptions;
    const dispatch = tableLineInputProps.dispatch;
    const lot = tableLineInputProps.lot;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isChecked = patternInput[0];
    const props_16 = ofArray([["className", "mb-2"], ["className", "py-3"], ["className", "px-2"], ["style", {
        borderRadius: 4,
    }], ["className", "is-size-6"], ["className", "is-fluid"], ["className", "has-background-white"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: "royaltyLine",
        children: Interop_reactApi.Children.toArray([createElement("div", {
            children: Interop_reactApi.Children.toArray([(props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("input", {
                className: join(" ", ["is-checkradio"]),
                id: `exampleCheckboxDefault ${lot.ItemId}`,
                type: "checkbox",
                name: `exampleCheckboxDefault ${lot.ItemId}`,
                onChange: (ev) => {
                    const _arg1 = ev.target.checked;
                    if (isChecked) {
                        dispatch(new Msg(7, lot));
                    }
                    else {
                        dispatch(new Msg(6, lot));
                    }
                    patternInput[1](!isChecked);
                },
                checked: isChecked,
            }), createElement("label", {
                htmlFor: `exampleCheckboxDefault ${lot.ItemId}`,
            })])]), createElement("div", createObj(Helpers_combineClasses("field", props_2))))]),
        }), createElement("div", {
            children: OrderNumberModule_toString(lot.LotNumber),
        }), createElement("div", {
            children: ItemIdModule_toString(lot.ItemId),
        }), createElement("div", {
            children: Localized_translate(lot.Title),
        }), createElement("div", {
            className: "has-text-left",
            children: (date = toLocalTime(lot.AuctionDate), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_2(date, "dd MMMM yyyy", formatOptions))))),
        }), createElement("div", {
            children: SubmissionIdModule_toString(lot.SubmissionId),
        }), createElement("div", createObj(toList(delay(() => {
            let date_2, locale_1, formatOptions_1;
            const matchValue = lot.DateOfPaid;
            return (matchValue != null) ? singleton_2(["children", (date_2 = toLocalTime(matchValue), (locale_1 = sv, (formatOptions_1 = {}, (formatOptions_1.locale = locale_1, format_2(date_2, "dd MMMM yyyy", formatOptions_1)))))]) : singleton_2(["children", "-"]);
        })))), createElement("div", {
            children: Currency_format(lot.FinalPrice),
        }), createElement("div", {
            children: UserIdModule_toString(lot.BuyerId),
        }), createElement("div", {
            children: Currency_format(lot.Royalty),
        }), createElement("div", createObj(toList(delay(() => append_1(singleton_2(["style", {
            textAlign: "right",
        }]), delay(() => ((lot.Status.tag === 1) ? append_1(singleton_2(["children", t("orders.unpaid")]), delay(() => singleton_2(["className", "has-text-danger"]))) : append_1(singleton_2(["children", t("orders.paid")]), delay(() => singleton_2(["className", "has-text-primary"]))))))))))]),
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_16)));
}

export function ButtonFilterStatus(buttonFilterStatusInputProps) {
    const unitVar2 = buttonFilterStatusInputProps.unitVar2;
    const dispatch = buttonFilterStatusInputProps.dispatch;
    const status = buttonFilterStatusInputProps.status;
    const props = toList(delay(() => append_1((!status.IsCheck) ? append_1(singleton_2(["className", "is-primary"]), delay(() => singleton_2(["className", "is-light"]))) : empty_1(), delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => {
        let matchValue;
        return append_1((matchValue = status.Status, (matchValue != null) ? singleton_2(["children", toString(matchValue)]) : singleton_2(["children", t("common.all")])), delay(() => singleton_2(["onClick", (_arg1) => {
            dispatch(new Msg(5, status));
        }])));
    }))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export function Render() {
    let props_1, s, props_32, props_57, props_78;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(new Common_PagingQuery$1(new RoyaltyReport_RoyaltyFilter(void 0, void 0, Dates_startDateTimeSpanZero, Dates_endDateTimeSpanZero), 1, 20, void 0, getLocalizationContext())), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_80 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("reports.resaleRightsReport"), createElement("h1", {
        className: "title",
        children: s,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1)))), (props_32 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_17;
        return append_1(singleton_2((props_17 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_10, props_8, patternInput_1;
            return append_1(singleton_2((props_10 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "is-size-6"], ["className", "has-text-grey"], ["onChange", (ev) => {
                dispatch(new Msg(4, ev.target.value));
            }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x) => createElement("option", {
                value: AuctionIdModule_toString(x.Id),
                children: x.AuctionName,
            }), state_1.AllAuction)))]])), createElement("div", {
                className: join(" ", cons("select", patternInput_1[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))), delay(() => {
                let props_12;
                return append_1(equals_1(state_1.Auction.Id, AuctionIdModule_empty) ? singleton_2((props_12 = singleton_1(["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([custom("value", toArray(map((x_2) => toLocalTime(x_2), ofArray([state_1.Query.Payload.StartDate, state_1.Query.Payload.EndDate]))), false), SelectionMode("range"), DateFormat("d M Y"), custom("locale", {
                    firstDayOfWeek: 1,
                }, true), ClassName("input"), OnManyChanged((period) => {
                    let pattern_matching_result;
                    if (!isEmpty(period)) {
                        if (isEmpty(tail(period))) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 0;
                    }
                    switch (pattern_matching_result) {
                        case 0: {
                            break;
                        }
                        case 1: {
                            dispatch(new Msg(12, period));
                            break;
                        }
                    }
                }), Disabled(false), Locale(Swedish)]))])]), createElement("div", createObj(Helpers_combineClasses("column", props_12))))) : empty_1(), delay(() => {
                    let props_15, elms;
                    return singleton_2((props_15 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => map_1((status) => createElement(ButtonFilterStatus, {
                        status: status,
                        dispatch: dispatch,
                    }), state_1.FilterStatusButton))), createElement("div", {
                        className: "buttons",
                        children: Interop_reactApi.Children.toArray(Array.from(elms)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15)))));
                }));
            }));
        }))))]), createElement("div", createObj(Helpers_combineClasses("columns", props_17))))), delay(() => {
            let elms_2, elms_1, props_25, props_23, props_20, s_1;
            return append_1(singleton_2((elms_2 = ofArray([(elms_1 = singleton_1((props_25 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "is-primary"], ["onClick", (_arg1) => {
                dispatch(new Msg(9, state_1.CheckedRoyalties));
            }], ["children", Interop_reactApi.Children.toArray([(props_20 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-dollar-sign"]),
            })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_20)))), (s_1 = tWithArgs("reports.markAsPaidWithArg", {
                amount: length(state_1.CheckedRoyalties),
            }), createElement("p", {
                className: "",
                children: s_1,
            }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_23))))])]), createElement("div", createObj(Helpers_combineClasses("buttons", props_25))))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            })), createElement(RoyaltiesToPrint_toPrintSection, {
                royaltiesToPrint: state_1.CheckedRoyalties,
                query: state_1.Query,
            })]), createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))), delay(() => append_1(singleton_2(tableHeader(state_1, dispatch)), delay(() => {
                let props_30;
                const matchValue = state_1.Royalties;
                let pattern_matching_result_1, ex, royalties;
                if (matchValue.tag === 1) {
                    pattern_matching_result_1 = 0;
                }
                else if (matchValue.tag === 2) {
                    if (matchValue.fields[0].tag === 0) {
                        pattern_matching_result_1 = 2;
                        royalties = matchValue.fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                        ex = matchValue.fields[0].fields[0];
                    }
                }
                else {
                    pattern_matching_result_1 = 0;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return singleton_2(loader("small"));
                    }
                    case 1: {
                        return singleton_2(createElement("h1", {
                            children: [ex],
                        }));
                    }
                    case 2: {
                        return append_1(isEmpty(royalties.Result) ? singleton_2((props_30 = ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", t("reports.noRoyalties")]]), createElement("p", createObj(Helpers_combineClasses("", props_30))))) : empty_1(), delay(() => append_1(map_1((royalty) => createElement(TableLine, {
                            lot: royalty,
                            dispatch: dispatch,
                        }), royalties.Result), delay(() => singleton_2(renderPagination(royalties.Page, royalties.Total, royalties.PageSize, (arg_1) => {
                            dispatch(new Msg(2, arg_1));
                        }))))));
                    }
                }
            }))));
        }));
    }))))]), createElement("section", createObj(Helpers_combineClasses("section", props_32)))), (props_57 = toList(delay(() => append_1(state_1.IsSave ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => {
        let props_55, props_53, elms_4, elms_3, props_36, props_41, s_2, props_51, props_49, props_45, props_47;
        return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg2) => {
            dispatch(new Msg(10));
        }])))), (props_55 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_53 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_4 = ofArray([(elms_3 = singleton_1((props_36 = singleton_1(["children", t("common.update")]), createElement("span", createObj(Helpers_combineClasses("", props_36))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (props_41 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg3) => {
            dispatch(new Msg(10));
        }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_41))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        })), (s_2 = t("reports.markAsPaidConfirmation"), createElement("p", {
            className: "",
            children: s_2,
        })), (props_51 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_49 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_45 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["onClick", (_arg4) => {
            dispatch(new Msg(10));
        }]]), createElement("a", createObj(Helpers_combineClasses("button", props_45)))), (props_47 = ofArray([["className", "is-primary"], ["children", t("common.update")], ["onClick", (_arg5) => {
            dispatch(new Msg(10));
            dispatch(new Msg(8, state_1.CheckedRoyalties, new AsyncOperationStatus$1(0)));
        }]]), createElement("a", createObj(Helpers_combineClasses("button", props_47))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_49))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_51))))])]), createElement("div", createObj(Helpers_combineClasses("box", props_53))))])]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_55))))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("modal", props_57)))), (props_78 = toList(delay(() => append_1(state_1.HavePaidItems ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => {
        let props_76, props_74, props_63, props_72, elms_5, props_65, props_70, props_68;
        return singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg6) => {
            dispatch(new Msg(11));
        }])))), (props_76 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_74 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_63 = ofArray([["style", {
            textAlign: "right",
        }], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg7) => {
            dispatch(new Msg(11));
        }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_63)))), (props_72 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_1((props_65 = singleton_1(["children", t("reports.youHaveItemsWithPaidStatus")]), createElement("span", createObj(Helpers_combineClasses("", props_65))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        })), (props_70 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_68 = ofArray([["className", "is-dark"], ["children", t("common.ok")], ["onClick", (_arg8) => {
            dispatch(new Msg(11));
        }]]), createElement("a", createObj(Helpers_combineClasses("button", props_68))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_70))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_72))))])]), createElement("div", createObj(Helpers_combineClasses("box", props_74))))])]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_76))))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("modal", props_78))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_80)));
}

