import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { RoyaltyIntervals_TypeInterval, RoyaltyIntervals_Interval_get_Initial, RoyaltyIntervals_Interval, RoyaltyIntervals_Errors$reflection, RoyaltyIntervals_TypeInterval$reflection, RoyaltyIntervals_Interval$reflection, Settings_DisplayIdType, Settings_Order_OrderCommissions, Settings_LotSettings, Settings_DisplayIdType$reflection, Settings_LotSettings$reflection, Settings_Order_OrderCommissions$reflection } from "./Shared/Shared.js";
import { int32_type, record_type, union_type, unit_type, bool_type, class_type, list_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_handleNonAuth, Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi } from "./Communication.js";
import { FSharpMap__get_IsEmpty, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { RoyaltyIntervals_validate, RoyaltyIntervals_validateIf, Settings_OrderCommissions_validate } from "./Validation.js";
import { Decimal_tryParse, Currency_currencySymbol, Currency_decimalDigits, Form_getFieldError, Currency_round } from "./Common.js";
import { int32ToString, createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { createElement } from "react";
import * as react from "react";
import { map as map_2, empty as empty_2, append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { indexed, tail, reverse, tryLast, cons, map, fold, singleton as singleton_2, append as append_1, empty as empty_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { op_Addition, toNumber } from "./fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.6.1/Decimal.js";
import { map as map_1, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { NumberFormatModule_numberFormat } from "./Components/InputDecimal.js";
import { tWithArgs, t } from "./Localization.js";
import { CurrencyModule_fromString, PercentModule_zero, CurrencyModule_fromInt, CurrencyModule_fromDecimal, CurrencyModule_toDecimal, PercentModule_fromDecimal, PercentModule_toDecimal } from "./bidflow/src/infrastructure/Auction.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D, useFeliz_React__React_useElmish_Static_645B1FB7 } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { isCurrentUserAdmin } from "./LocalStorage.js";

export class Types_State extends Record {
    constructor(Commissions, OriginalCommissions, Settings, OriginalSettings, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.Commissions = Commissions;
        this.OriginalCommissions = OriginalCommissions;
        this.Settings = Settings;
        this.OriginalSettings = OriginalSettings;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function Types_State$reflection() {
    return record_type("Client.SettingsOrder.Types.State", [], Types_State, () => [["Commissions", Settings_Order_OrderCommissions$reflection()], ["OriginalCommissions", Settings_Order_OrderCommissions$reflection()], ["Settings", Settings_LotSettings$reflection()], ["OriginalSettings", Settings_LotSettings$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BuyerCommissionChanged", "AdditionalBuyerCommissionChanged", "DisplayTypeChanged", "IsShowCommissionBoxChanged", "CancelEdit", "SaveForm"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.SettingsOrder.Types.Msg", [], Types_Msg, () => [[["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["Item", Settings_DisplayIdType$reflection()]], [["Item", bool_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_saveForm(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(settingsApi().saveOrderCommissions(state.Commissions), () => singleton.Bind(settingsApi().updateCompanySettingsForLot(state.Settings), (_arg2) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, _arg2)))))), (_arg3) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg3.message))))));
}

export function State_init(commissions, settings) {
    return [new Types_State(commissions, commissions, settings, settings, empty(), true, new Deferred$1(0)), Cmd_none()];
}

export function State_withFormAndErrors(form, errors, state) {
    return new Types_State(form, state.OriginalCommissions, state.Settings, state.OriginalSettings, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function State_update(msg, state) {
    const withValidatedForm = (arg) => {
        let tupledArg;
        const terms_1 = arg;
        if (state.NeedToValidate) {
            const matchValue = Settings_OrderCommissions_validate(terms_1);
            tupledArg = ((matchValue.tag === 1) ? [terms_1, matchValue.fields[0]] : [matchValue.fields[0], empty()]);
        }
        else {
            tupledArg = [terms_1, empty()];
        }
        return (state_1) => State_withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 3) {
        return [new Types_State(state.Commissions, state.OriginalCommissions, new Settings_LotSettings(state.Settings.DisplayIdType, msg.fields[0]), state.OriginalSettings, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 0) {
        return [withValidatedForm(new Settings_Order_OrderCommissions(msg.fields[0], state.Commissions.HammerFee))(state), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [withValidatedForm(new Settings_Order_OrderCommissions(state.Commissions.BuyerCommission, Currency_round(msg.fields[0])))(state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Types_State(state.OriginalCommissions, state.OriginalCommissions, state.Settings, state.OriginalSettings, empty(), false, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            return [new Types_State(state.Commissions, state.Commissions, state.Settings, state.Settings, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if ((!equals(state.Commissions, state.OriginalCommissions)) ? true : (!equals(state.Settings, state.OriginalSettings))) {
            const matchValue_1 = Settings_OrderCommissions_validate(state.Commissions);
            if (matchValue_1.tag === 1) {
                return [new Types_State(state.Commissions, state.OriginalCommissions, state.Settings, state.OriginalSettings, matchValue_1.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new Types_State(state.Commissions, state.OriginalCommissions, state.Settings, state.OriginalSettings, empty(), true, new Deferred$1(1)), Cmd_fromAsync(Cmd_saveForm(state))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new Types_State(state.Commissions, state.OriginalCommissions, new Settings_LotSettings(msg.fields[0], state.Settings.IsShowCommissionBox), state.OriginalSettings, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
    }
}

export function View_renderField(decimalDigits, label, prefix, value, placeholder, onChange, state, formErrors) {
    return createElement("div", createObj(toList(delay(() => {
        let props_2, props_10, props_5, props_4, elms, props_12;
        const errors = Form_getFieldError(state.FormErrors, formErrors);
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_2)))), (props_10 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append((!equals(errors, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onValueChange", (x) => {
            onChange(new Decimal(defaultArg(x.floatValue, 0)));
        }]), delay(() => append(singleton_1(["placeholder", placeholder]), delay(() => append(singleton_1(["value", toNumber(value)]), delay(() => append((decimalDigits == null) ? ((empty_2())) : singleton_1(["decimalScale", decimalDigits]), delay(() => (equals(state.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["disabled", true]) : empty_2()))))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_4, singleton_2(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_5)))), (elms = singleton_2(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["children", prefix]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_10)))), (props_12 = ofArray([["className", "is-danger"], ["children", fold((s, x_2) => (`${s} ${x_2}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_12))))])]);
    }))));
}

export function View_render(state, dispatch) {
    let props_36, props_34, props_32, props_11, elms_1, props_2, props, elms, patternInput, value_25, props_24, elms_3, props_15, props_13, elms_2, patternInput_1, value_61, props_30, props_26, props_28;
    const isFormSaving = equals(state.SaveFormOperation, new Deferred$1(1));
    const needDisableSaving = (isFormSaving ? true : (!((!equals(state.Commissions, state.OriginalCommissions)) ? true : (!equals(state.Settings, state.OriginalSettings))))) ? true : (!FSharpMap__get_IsEmpty(state.FormErrors));
    const toValue = (_arg3) => {
        if (_arg3.tag === 1) {
            return 2;
        }
        else {
            return 1;
        }
    };
    const toValueIsShow = (_arg6) => {
        if (_arg6) {
            return 1;
        }
        else {
            return 2;
        }
    };
    const elms_4 = singleton_2(createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_36 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_32 = ofArray([["className", "is-half"], ["children", Interop_reactApi.Children.toArray([View_renderField(void 0, t("orders.buyerCommission"), "%", PercentModule_toDecimal(state.Commissions.BuyerCommission), t("orders.buyerCommission"), (arg_1) => {
            dispatch(new Types_Msg(0, PercentModule_fromDecimal(arg_1)));
        }, state, "BuyerCommission"), View_renderField(Currency_decimalDigits(), t("orders.hammerFee"), Currency_currencySymbol(), CurrencyModule_toDecimal(state.Commissions.HammerFee), t("orders.hammerFee"), (arg_3) => {
            dispatch(new Types_Msg(1, CurrencyModule_fromDecimal(arg_3)));
        }, state, "HammerFee"), (props_11 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.displayNumberSettings")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_2((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], (value_25 = (toValue(state.Settings.DisplayIdType) | 0), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_25)) : false) {
                e.value = (value_25 | 0);
            }
        }]), ["onChange", (ev) => {
            let _arg1;
            dispatch(new Types_Msg(2, (_arg1 = ev.target.value, (_arg1 === "1") ? (new Settings_DisplayIdType(0)) : ((_arg1 === "2") ? (new Settings_DisplayIdType(1)) : (() => {
                throw (new Error("Can\u0027t find payment provider"));
            })()))));
        }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x) => {
            let copyOfStruct;
            return createElement("option", {
                value: (copyOfStruct = toValue(x), int32ToString(copyOfStruct)),
                children: (x.tag === 1) ? t("common.itemId") : t("common.lotNumber"),
            });
        }, ofArray([new Settings_DisplayIdType(0), new Settings_DisplayIdType(1)]))))]])), createElement("div", {
            className: join(" ", cons("select", patternInput[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_11)))), (props_24 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_15 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.showCommissionBoxSettings")]]), createElement("label", createObj(Helpers_combineClasses("label", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_15)))), (elms_2 = singleton_2((patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], (value_61 = (toValueIsShow(state.Settings.IsShowCommissionBox) | 0), ["ref", (e_1) => {
            if ((!(e_1 == null)) ? (!equals(e_1.value, value_61)) : false) {
                e_1.value = (value_61 | 0);
            }
        }]), ["onChange", (ev_1) => {
            let _arg4;
            dispatch(new Types_Msg(3, (_arg4 = ev_1.target.value, (_arg4 === "1") ? true : ((_arg4 === "2") ? false : (() => {
                throw (new Error("Can\u0027t find payment provider"));
            })()))));
        }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x_1) => {
            let copyOfStruct_1;
            return createElement("option", {
                value: (copyOfStruct_1 = toValueIsShow(x_1), int32ToString(copyOfStruct_1)),
                children: x_1 ? t("common.show") : t("common.hide"),
            });
        }, ofArray([true, false]))))]])), createElement("div", {
            className: join(" ", cons("select", patternInput_1[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_24)))), (props_30 = ofArray([["className", "mt-6"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_26 = toList(delay(() => append(singleton_1(["className", "is-dark"]), delay(() => append(isFormSaving ? singleton_1(["disabled", true]) : empty_2(), delay(() => append(singleton_1(["children", t("common.cancel")]), delay(() => singleton_1(["onClick", (x_2) => {
            x_2.preventDefault();
            dispatch(new Types_Msg(4));
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_26)))), (props_28 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(needDisableSaving ? singleton_1(["disabled", true]) : empty_2(), delay(() => append(isFormSaving ? singleton_1(["className", "is-loading"]) : empty_2(), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (x_3) => {
            x_3.preventDefault();
            dispatch(new Types_Msg(5, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_28))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_30))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_32))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_34))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_36))))]),
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    });
}

export class RenderRoyaltyIntervals_State extends Record {
    constructor(Intervals, OriginalIntervals, EditMode, FormErrors, SaveError, SaveFormOperation) {
        super();
        this.Intervals = Intervals;
        this.OriginalIntervals = OriginalIntervals;
        this.EditMode = EditMode;
        this.FormErrors = FormErrors;
        this.SaveError = SaveError;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function RenderRoyaltyIntervals_State$reflection() {
    return record_type("Client.SettingsOrder.RenderRoyaltyIntervals.State", [], RenderRoyaltyIntervals_State, () => [["Intervals", list_type(RoyaltyIntervals_Interval$reflection())], ["OriginalIntervals", list_type(RoyaltyIntervals_Interval$reflection())], ["EditMode", bool_type], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["SaveError", string_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class RenderRoyaltyIntervals_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AddInterval", "DeleteInterval", "UpdateInterval", "SwitchEditMode", "SaveForm"];
    }
}

export function RenderRoyaltyIntervals_Msg$reflection() {
    return union_type("Client.SettingsOrder.RenderRoyaltyIntervals.Msg", [], RenderRoyaltyIntervals_Msg, () => [[], [], [["Item1", int32_type], ["Item2", RoyaltyIntervals_TypeInterval$reflection()], ["Item3", string_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, RoyaltyIntervals_Errors$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", RoyaltyIntervals_Errors$reflection()]]]))]]]);
}

export function RenderRoyaltyIntervals_Cmd_updateIntervals(intervals) {
    return singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().updateRoyaltyIntervals(intervals)), (_arg1) => singleton.Return(new RenderRoyaltyIntervals_Msg(4, new AsyncOperationStatus$1(1, _arg1)))));
}

export function RenderRoyaltyIntervals_init(intervals, unitVar1) {
    return [new RenderRoyaltyIntervals_State(intervals, intervals, false, empty(), "", new Deferred$1(0)), Cmd_none()];
}

export function RenderRoyaltyIntervals_update(msg, state) {
    let tupledArg_1;
    if (msg.tag === 0) {
        return [new RenderRoyaltyIntervals_State(append_1(state.Intervals, singleton_2(defaultArg(map_1((i) => (new RoyaltyIntervals_Interval(op_Addition(i.StartPoint, CurrencyModule_fromInt(1)), i.Percent)), tryLast(state.Intervals)), RoyaltyIntervals_Interval_get_Initial()))), state.OriginalIntervals, state.EditMode, state.FormErrors, state.SaveError, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new RenderRoyaltyIntervals_State(reverse(tail(reverse(state.Intervals))), state.OriginalIntervals, state.EditMode, state.FormErrors, state.SaveError, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [(tupledArg_1 = RoyaltyIntervals_validateIf(map((tupledArg) => {
            const old = tupledArg[1];
            if (tupledArg[0] === msg.fields[0]) {
                const value_4 = msg.fields[2];
                const biddingInterval = old;
                if (msg.fields[1].tag === 1) {
                    return new RoyaltyIntervals_Interval(biddingInterval.StartPoint, defaultArg(map_1((x) => PercentModule_fromDecimal(x), Decimal_tryParse(value_4.trim())), PercentModule_zero));
                }
                else {
                    return new RoyaltyIntervals_Interval(CurrencyModule_fromString(value_4.trim()), biddingInterval.Percent);
                }
            }
            else {
                return old;
            }
        }, indexed(state.Intervals))), new RenderRoyaltyIntervals_State(tupledArg_1[0], state.OriginalIntervals, state.EditMode, tupledArg_1[1], state.SaveError, state.SaveFormOperation)), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                const er = msg.fields[0].fields[0].fields[0];
                return [new RenderRoyaltyIntervals_State(state.Intervals, state.OriginalIntervals, state.EditMode, state.FormErrors, (er.tag === 0) ? t("errors.fromServer.intervalsIsNotValid") : tWithArgs("errors.fromServer.unknownError ", {
                    error: er,
                }), new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new RenderRoyaltyIntervals_State(state.Intervals, state.OriginalIntervals, false, state.FormErrors, state.SaveError, new Deferred$1(0)), Cmd_none()];
            }
        }
        else if (!equals(state.Intervals, state.OriginalIntervals)) {
            const matchValue = RoyaltyIntervals_validate(state.Intervals);
            if (matchValue.tag === 1) {
                return [new RenderRoyaltyIntervals_State(state.Intervals, state.OriginalIntervals, state.EditMode, matchValue.fields[0], state.SaveError, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new RenderRoyaltyIntervals_State(state.Intervals, state.OriginalIntervals, state.EditMode, empty(), state.SaveError, new Deferred$1(1)), Cmd_fromAsync(RenderRoyaltyIntervals_Cmd_updateIntervals(state.Intervals))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [state.EditMode ? (new RenderRoyaltyIntervals_State(state.OriginalIntervals, state.OriginalIntervals, !state.EditMode, empty(), state.SaveError, state.SaveFormOperation)) : (new RenderRoyaltyIntervals_State(state.Intervals, state.OriginalIntervals, !state.EditMode, state.FormErrors, state.SaveError, state.SaveFormOperation)), Cmd_none()];
    }
}

export const RenderRoyaltyIntervals_header = createElement("thead", {
    className: "small-font-size",
    children: Interop_reactApi.Children.toArray([(() => {
        let children, children_2;
        const children_4 = ofArray([(children = singleton_2(createElement("span", {
            children: ["Royalty"],
        })), createElement("th", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), (children_2 = singleton_2(createElement("span", {
            children: ["Percent"],
        })), createElement("th", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))]);
        return createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        });
    })()]),
});

export function RenderRoyaltyIntervals_row(isNotEditTermsMode, ind, interval, dispatch) {
    let children_4, children, props, children_2, props_3;
    return react.createElement(react.Fragment, {}, (children_4 = ofArray([(children = singleton_2((props = ofArray([["disabled", isNotEditTermsMode], ["onChange", (ev) => {
        dispatch(new RenderRoyaltyIntervals_Msg(2, ind, new RoyaltyIntervals_TypeInterval(0), ev.target.value));
    }], ["placeholder", "0"], ["defaultValue", `${interval.StartPoint}`]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props)))))), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = singleton_2((props_3 = ofArray([["disabled", isNotEditTermsMode], ["onChange", (ev_1) => {
        dispatch(new RenderRoyaltyIntervals_Msg(2, ind, new RoyaltyIntervals_TypeInterval(1), ev_1.target.value));
    }], ["placeholder", "0"], ["defaultValue", `${interval.Percent}`]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_3)))))), createElement("td", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    }))]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    })));
}

export function RenderRoyaltyIntervals_rows(isNotEditTermsMode, matrix, addRow, removeRow, dispatch) {
    const children_2 = toList(delay(() => append(map_2((biddingInterval) => RenderRoyaltyIntervals_row(isNotEditTermsMode, biddingInterval[0], biddingInterval[1], dispatch), indexed(matrix)), delay(() => {
        let children, props, props_2;
        return singleton_1((children = singleton_2(createElement("td", {
            style: {
                whiteSpace: "nowrap",
            },
            children: Interop_reactApi.Children.toArray([(props = toList(delay(() => append(singleton_1(["style", {
                color: "#D3D3D3",
            }]), delay(() => append(singleton_1(["className", join(" ", ["fas", "fa-plus-circle"])]), delay(() => ((!isNotEditTermsMode) ? singleton_1(["onClick", (x) => {
                x.preventDefault();
                addRow();
            }]) : empty_2()))))))), createElement("span", createObj(Helpers_combineClasses("icon", props)))), (props_2 = toList(delay(() => append(singleton_1(["style", {
                color: "#D3D3D3",
            }]), delay(() => append(singleton_1(["className", join(" ", ["fas", "fa-minus-circle"])]), delay(() => ((!isNotEditTermsMode) ? singleton_1(["onClick", (x_1) => {
                x_1.preventDefault();
                removeRow();
            }]) : empty_2()))))))), createElement("span", createObj(Helpers_combineClasses("icon", props_2))))]),
        })), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })));
    }))));
    return createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function RenderRoyaltyIntervals_RenderComponent(renderRoyaltyIntervals_RenderComponentInputProps) {
    const unitVar1 = renderRoyaltyIntervals_RenderComponentInputProps.unitVar1;
    const intervals = renderRoyaltyIntervals_RenderComponentInputProps.intervals;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => RenderRoyaltyIntervals_init(intervals, void 0), (msg, state) => RenderRoyaltyIntervals_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", {
        className: join(" ", ["pt-6", "mt-5", "has-background-white"]),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_6;
            return append(singleton_1((props_6 = toList(delay(() => {
                let elms_1, elms, props, props_3;
                const errors = Form_getFieldError(state_1.FormErrors, "Intervals");
                return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(elms = singleton_2((props = ofArray([["style", {
                    marginLeft: "auto",
                    marginRight: "auto",
                }], ["children", Interop_reactApi.Children.toArray([RenderRoyaltyIntervals_header, RenderRoyaltyIntervals_rows(!state_1.EditMode, state_1.Intervals, () => {
                    dispatch(new RenderRoyaltyIntervals_Msg(0));
                }, () => {
                    dispatch(new RenderRoyaltyIntervals_Msg(1));
                }, dispatch)])]]), createElement("table", createObj(Helpers_combineClasses("table", props))))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (props_3 = ofArray([["className", "has-text-centered"], ["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_3))))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))])]);
            })), createElement("div", createObj(Helpers_combineClasses("field", props_6))))), delay(() => append((state_1.SaveError !== "") ? singleton_1(createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "has-text-centered"], ["className", "is-danger"], ["children", state_1.SaveError]]))))) : empty_2(), delay(() => {
                let props_16;
                return singleton_1((props_16 = ofArray([["className", "pr-6"], ["className", "pb-6"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_10, props_14;
                    return state_1.EditMode ? append(singleton_1((props_10 = ofArray([["className", "is-dark"], ["onClick", (_arg3) => {
                        dispatch(new RenderRoyaltyIntervals_Msg(3));
                    }], ["children", t("common.cancel")]]), createElement("button", createObj(Helpers_combineClasses("button", props_10))))), delay(() => {
                        let props_12;
                        return singleton_1((props_12 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_2(), delay(() => append((equals(state_1.Intervals, state_1.OriginalIntervals) ? true : (!FSharpMap__get_IsEmpty(state_1.FormErrors))) ? singleton_1(["disabled", true]) : empty_2(), delay(() => append(singleton_1(["onClick", (_arg4) => {
                            dispatch(new RenderRoyaltyIntervals_Msg(4, new AsyncOperationStatus$1(0)));
                        }]), delay(() => singleton_1(["children", t("common.save")]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12)))));
                    })) : singleton_1((props_14 = ofArray([["className", "has-background-grey-lighter"], ["onClick", (_arg5) => {
                        dispatch(new RenderRoyaltyIntervals_Msg(3));
                    }], ["children", t("common.edit")]]), createElement("button", createObj(Helpers_combineClasses("button", props_14)))));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_16)))));
            }))));
        })))),
    });
}

export function Component_Render(res) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(res.Order, res.Lot), (msg, state) => State_update(msg, state), []);
    return View_render(patternInput[0], patternInput[1]);
}

export function Component_Instance() {
    const getAgreementTermsDeferred = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getOrderCommissions()), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getCompanySettingsForLot()), (_arg2) => singleton.Return({
        Lot: _arg2,
        Order: _arg1,
    })))), []);
    switch (getAgreementTermsDeferred.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            return getAgreementTermsDeferred.fields[0].message;
        }
        case 2: {
            return createElement(Component_Render, getAgreementTermsDeferred.fields[0]);
        }
        default: {
            return loader("big");
        }
    }
}

export function Component_RoyaltyIntervals() {
    const getAgreementTermsDeferred = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getRoyaltyIntervals()), []);
    switch (getAgreementTermsDeferred.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            return getAgreementTermsDeferred.fields[0].message;
        }
        case 2: {
            return createElement(RenderRoyaltyIntervals_RenderComponent, {
                intervals: getAgreementTermsDeferred.fields[0],
            });
        }
        default: {
            return loader("big");
        }
    }
}

export class Main_EditMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Lot", "RoyaltyIntervals"];
    }
}

export function Main_EditMode$reflection() {
    return union_type("Client.SettingsOrder.Main.EditMode", [], Main_EditMode, () => [[], []]);
}

export function Main_Render() {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Main_EditMode(0));
    const setEditMode = patternInput[1];
    const editMode = patternInput[0];
    if (isCurrentUserAdmin()) {
        const elms = toList(delay(() => {
            let props_1, s;
            return append(singleton_1((props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("settings.lotSettings"), createElement("h1", {
                className: "title",
                children: s,
            }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1))))), delay(() => {
                let props_12, props_10, children, props_4, props_7;
                return append(singleton_1((props_12 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-toggle"], ["className", "is-centered"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_4 = toList(delay(() => append(equals(editMode, new Main_EditMode(0)) ? singleton_1(["className", "is-active"]) : empty_2(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "is-normal-padding",
                    children: t("common.lot"),
                    onClick: (_arg1) => {
                        setEditMode(new Main_EditMode(0));
                    },
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_7 = toList(delay(() => append(equals(editMode, new Main_EditMode(1)) ? singleton_1(["className", "is-active"]) : empty_2(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "is-normal-padding",
                    children: t("settings.royaltyIntervals"),
                    onClick: (_arg2) => {
                        setEditMode(new Main_EditMode(1));
                    },
                })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7))))]), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_10))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_12))))), delay(() => ((editMode.tag === 1) ? singleton_1(createElement(Component_RoyaltyIntervals, null)) : singleton_1(createElement(Component_Instance, null)))));
            }));
        }));
        return createElement("div", {
            className: "container",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        });
    }
    else {
        return null;
    }
}

