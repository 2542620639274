import { LotNumberReservationIdModule_fromString, LotNumberReservationIdModule_toString, LotNumberReservationIdModule_newId } from "./bidflow/src/infrastructure/Auction.js";
import { map, defaultArgWith } from "./fable_modules/fable-library.3.6.1/Option.js";

export function setItem(key, value) {
    sessionStorage.setItem(key, value);
}

export function tryGetItem(key) {
    const value = sessionStorage.getItem(key);
    if (value == null) {
        return void 0;
    }
    else {
        return value;
    }
}

export function generateReservationId() {
    return LotNumberReservationIdModule_newId();
}

export function getReservationId() {
    return tryGetItem("reservationId");
}

export function setReservationId(reservationId) {
    setItem("reservationId", LotNumberReservationIdModule_toString(reservationId));
    return reservationId;
}

export function getOrCreateNewReservationId() {
    return defaultArgWith(map((x) => LotNumberReservationIdModule_fromString(x), tryGetItem("reservationId")), () => setReservationId(generateReservationId()));
}

