import { map as map_1, choose, tryFind as tryFind_1, contains, ofArray } from "../../../fable_modules/fable-library.3.6.1/List.js";
import { FSharpChoice$3 } from "../../../fable_modules/fable-library.3.6.1/Choice.js";
import { equals, stringHash } from "../../../fable_modules/fable-library.3.6.1/Util.js";
import { toString, Union, Record } from "../../../fable_modules/fable-library.3.6.1/Types.js";
import { union_type, record_type, list_type, string_type } from "../../../fable_modules/fable-library.3.6.1/Reflection.js";
import { fold, add, tryFind, toList, ofList, empty } from "../../../fable_modules/fable-library.3.6.1/Map.js";
import { map as map_2, defaultArg } from "../../../fable_modules/fable-library.3.6.1/Option.js";
import { isNullOrEmpty } from "../../../fable_modules/fable-library.3.6.1/String.js";
import { List_distinct } from "../../../fable_modules/fable-library.3.6.1/Seq2.js";
import { CountryModule_englishName, CountryModule_swedishName, CountryModule_finnishName } from "./Countries.js";

export const LangModule_swedish = "se";

export const LangModule_english = "en";

export const LangModule_finnish = "fi";

export const LangModule_allLanguages = ofArray([LangModule_swedish, LangModule_english, LangModule_finnish]);

export function LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(x) {
    if (x === LangModule_english) {
        return new FSharpChoice$3(1, void 0);
    }
    else if (x === LangModule_finnish) {
        return new FSharpChoice$3(2, void 0);
    }
    else {
        return new FSharpChoice$3(0, void 0);
    }
}

export function LangModule_toString(x) {
    return x;
}

export function LangModule_fromString(str) {
    const targetLanguage = str;
    if (contains(targetLanguage, LangModule_allLanguages, {
        Equals: (x_1, y) => (x_1 === y),
        GetHashCode: (x_1) => stringHash(x_1),
    })) {
        return targetLanguage;
    }
    else {
        throw (new Error(`The given language is unknown: ${targetLanguage}`));
    }
}

export class LocalizationContext extends Record {
    constructor(SupportedLanguages, PrimaryLanguage, CurrentLanguage) {
        super();
        this.SupportedLanguages = SupportedLanguages;
        this.PrimaryLanguage = PrimaryLanguage;
        this.CurrentLanguage = CurrentLanguage;
    }
}

export function LocalizationContext$reflection() {
    return record_type("Bidflow.Infrastructure.Localization.LocalizationContext", [], LocalizationContext, () => [["SupportedLanguages", list_type(string_type)], ["PrimaryLanguage", string_type], ["CurrentLanguage", string_type]]);
}

export const LocalizedStringModule_empty = empty();

export function LocalizedStringModule_from(translations) {
    return ofList(translations);
}

export function LocalizedStringModule_toTranslations(ls) {
    return toList(ls);
}

export function LocalizedStringModule_findTranslation(lang, ls) {
    return tryFind(lang, ls);
}

export function LocalizedStringModule_getTranslationOrEmpty(lang, ls) {
    return defaultArg(LocalizedStringModule_findTranslation(lang, ls), "");
}

export function LocalizedStringModule_setTranslation(lang, text, ls) {
    return add(lang, text, ls);
}

export function LocalizedStringModule_copyTranslationsFrom(source, dest) {
    return fold((acc, key, value) => add(key, value, acc), dest, source);
}

export function LocalizedStringModule_findFirstNotEmptyTranslation(langs, ls) {
    return tryFind_1((t) => (!isNullOrEmpty(t)), choose((l) => LocalizedStringModule_findTranslation(l, ls), List_distinct(langs, {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })));
}

export function LocalizedStringModule_languages(ls) {
    return map_1((tuple) => tuple[0], toList(ls));
}

export function LocalizedStringModule_findFirstLangWithNotEmptyTranslation(langs, ls) {
    return tryFind_1((tupledArg) => (!isNullOrEmpty(tupledArg[1])), choose((l) => map_2((ls_2) => [l, ls_2], LocalizedStringModule_findTranslation(l, ls)), List_distinct(langs, {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })));
}

export function LocalizedStringModule_translations(ls) {
    return map_1((tuple) => tuple[1], toList(ls));
}

export function LocalizedStringModule_copyTranslationFromSwedenToFinnish(ls) {
    return LocalizedStringModule_setTranslation(LangModule_finnish, LocalizedStringModule_getTranslationOrEmpty(LangModule_swedish, ls), ls);
}

export function LocalizedMap_empty() {
    return empty();
}

export function LocalizedMap_getValueOrEmpty(key, lm) {
    return defaultArg(tryFind(key, lm), LocalizedStringModule_empty);
}

export function LocalizedMap_add(field, lang, value, x) {
    return add(field, add(lang, value, LocalizedMap_getValueOrEmpty(field, x)), x);
}

export function LocalizedMap_from(fields) {
    return ofList(fields);
}

export function LocalizedMap_copyTranslationsFrom(source, dest) {
    return fold((acc, key, value) => add(key, LocalizedStringModule_copyTranslationsFrom(value, defaultArg(tryFind(key, acc), LocalizedStringModule_empty)), acc), dest, source);
}

export function LocalizedMap_mapField(mapper, x) {
    return ofList(map_1((tupledArg) => [mapper(tupledArg[0]), tupledArg[1]], toList(x)));
}

export function LocalizedMap_copyTranslationsFromSwedenToFinnish(x) {
    return ofList(map_1((tupledArg) => [tupledArg[0], LocalizedStringModule_copyTranslationFromSwedenToFinnish(tupledArg[1])], toList(x)));
}

export function LocalizedMap_findFirstNotEmptyTranslation(field, langs, map) {
    return defaultArg(LocalizedStringModule_findFirstNotEmptyTranslation(langs, LocalizedMap_getValueOrEmpty(field, map)), "");
}

export function LocalizedMap_getTranslationOrEmpty(field, lang, map) {
    return LocalizedStringModule_getTranslationOrEmpty(lang, LocalizedMap_getValueOrEmpty(field, map));
}

export function LocalizedMap_translations(field, map) {
    return LocalizedStringModule_translations(LocalizedMap_getValueOrEmpty(field, map));
}

export function LocalizedStringOption_copyTranslationsFrom(source, dest) {
    const matchValue = [source, dest];
    if (matchValue[0] == null) {
        if (matchValue[1] != null) {
            const y_1 = matchValue[1];
            return y_1;
        }
        else {
            return void 0;
        }
    }
    else if (matchValue[1] == null) {
        const x_1 = matchValue[0];
        return x_1;
    }
    else {
        const x = matchValue[0];
        const y = matchValue[1];
        return LocalizedStringModule_copyTranslationsFrom(x, y);
    }
}

export class Localized_PaymentType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Swish", "Card", "Cash", "Invoice", "Bankgiro", "Nets", "Svea", "SveaCard", "SveaInvoice", "SveaTrustly", "SveaAccount", "SveaOthers", "SveaSwish"];
    }
}

export function Localized_PaymentType$reflection() {
    return union_type("Bidflow.Infrastructure.Localization.Localized.PaymentType", [], Localized_PaymentType, () => [[], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Localized_PaymentMethod extends Record {
    constructor(PaymentType, NameSwedish, NameEnglish, NameFinnish) {
        super();
        this.PaymentType = PaymentType;
        this.NameSwedish = NameSwedish;
        this.NameEnglish = NameEnglish;
        this.NameFinnish = NameFinnish;
    }
}

export function Localized_PaymentMethod$reflection() {
    return record_type("Bidflow.Infrastructure.Localization.Localized.PaymentMethod", [], Localized_PaymentMethod, () => [["PaymentType", Localized_PaymentType$reflection()], ["NameSwedish", string_type], ["NameEnglish", string_type], ["NameFinnish", string_type]]);
}

export const Localized_paymentMethods = map_1((tupledArg) => (new Localized_PaymentMethod(tupledArg[0], tupledArg[1], tupledArg[2], tupledArg[3])), ofArray([[new Localized_PaymentType(0), "Swish", "Swish", "Swish"], [new Localized_PaymentType(1), "Kortbetalning", "Card payment", "Korttimaksu"], [new Localized_PaymentType(2), "Kontant", "Cash", "Käteinen"], [new Localized_PaymentType(3), "Faktura", "Invoice", "Lasku"], [new Localized_PaymentType(4), "Bankgiro", "Bank transfer", "Pankkisiirto"], [new Localized_PaymentType(5), "Nets", "Nets", "Nets"], [new Localized_PaymentType(6), "Svea", "Svea", "Svea"], [new Localized_PaymentType(7), "Svea/Kort", "Svea/Card", "Svea/kortti"], [new Localized_PaymentType(8), "Svea/Faktura", "Svea/Invoice", "Svea/lasku"], [new Localized_PaymentType(9), "Svea/Trustly", "Svea/Trustly", "Svea/Trustly"], [new Localized_PaymentType(10), "Svea/Konto", "Svea/Account", "Svea/Tili"], [new Localized_PaymentType(11), "Svea", "Svea", "Svea"], [new Localized_PaymentType(12), "Svea/Swish", "Svea/Swish", "Svea/Swish"]]));

export function Localized_paymentTypeName(f, pT) {
    return defaultArg(map_2(f, tryFind_1((x) => equals(x.PaymentType, pT), Localized_paymentMethods)), toString(pT));
}

export function Localized_getPaymentMethod(lang) {
    const activePatternResult22577 = LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(lang);
    if (activePatternResult22577.tag === 1) {
        return (pT_1) => Localized_paymentTypeName((x_1) => x_1.NameEnglish, pT_1);
    }
    else if (activePatternResult22577.tag === 2) {
        return (pT_2) => Localized_paymentTypeName((x_2) => x_2.NameFinnish, pT_2);
    }
    else {
        return (pT) => Localized_paymentTypeName((x) => x.NameSwedish, pT);
    }
}

export function Localized_getCountryName(lang) {
    const activePatternResult22579 = LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(lang);
    if (activePatternResult22579.tag === 2) {
        return CountryModule_finnishName;
    }
    else if (activePatternResult22579.tag === 0) {
        return CountryModule_swedishName;
    }
    else {
        return CountryModule_englishName;
    }
}

