import { empty as empty_2, fold, cons, truncate, head, length as length_1, append, isEmpty, indexed, sortDescending, tryHead, filter, sortBy, singleton as singleton_1, ofArray, map } from "./fable_modules/fable-library.3.6.1/List.js";
import { FreightClassModule_fromInt, CurrencyModule_zero, DeliveryCostZoneIdModule_fromString, DeliveryCostZoneIdModule_toString, CurrencyModule_fromInt } from "./bidflow/src/infrastructure/Auction.js";
import { SettingsFreight_FreightCostSetting, SettingsFreight_FreightCostBasicSetting, SettingsFreight_CalculationFormula$reflection, SettingsFreight_DeliveryServiceType$reflection, SettingsFreight_FreightCostMatrixSetting, SettingsFreight_CalculationFormula } from "./Shared/Shared.js";
import { empty as empty_1, append as append_1, map as map_2, singleton, collect, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { rangeDouble } from "./fable_modules/fable-library.3.6.1/Range.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { int32_type, unit_type, union_type, record_type, list_type, bool_type, class_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, Deferred$1$reflection, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { parse } from "./fable_modules/fable-library.3.6.1/Int32.js";
import { join, substring } from "./fable_modules/fable-library.3.6.1/String.js";
import { stringHash, createObj, equals, comparePrimitives } from "./fable_modules/fable-library.3.6.1/Util.js";
import { map as map_1, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { singleton as singleton_2 } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi } from "./Communication.js";
import { empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Localized_translate, t, tWithArgs, getSupportedLanguages, getPrimaryLanguage } from "./Localization.js";
import { Cmd_ofSub, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { SettingsFreight_validate, SettingsFreight_validateIf, SettingsFreight_SettingsFreight } from "./Validation.js";
import { LocalizedStringModule_findTranslation, LangModule_toString, LocalizedStringModule_setTranslation } from "./bidflow/src/infrastructure/Localization.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { List_distinct } from "./fable_modules/fable-library.3.6.1/Seq2.js";
import { Form_getFieldError } from "./Common.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";

export const CostMatrix_DefaultCostMatrix = map((list) => map((value) => CurrencyModule_fromInt(value), list), ofArray([ofArray([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]), ofArray([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]), ofArray([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]), ofArray([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]), ofArray([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])]));

export const CostMatrix_getDefaultMatrixSettings = new SettingsFreight_FreightCostMatrixSetting(CostMatrix_DefaultCostMatrix, new SettingsFreight_CalculationFormula(0));

export function CostMatrix_DefaultOneFreightClassMatrix(length) {
    return map((list) => map((value) => CurrencyModule_fromInt(value), list), singleton_1(toList(delay(() => collect((matchValue) => singleton(0), toList(rangeDouble(1, 1, length)))))));
}

export const CostMatrix_matrix1To1 = map((list) => map((value) => CurrencyModule_fromInt(value), list), singleton_1(singleton_1(0)));

export class CostMatrix_FreightCostZone extends Record {
    constructor(Id, GuidId, Name, Description, DeliveryServiceType, IsArchived, IsNewAdded, CostMatrix, CalculationFormula) {
        super();
        this.Id = Id;
        this.GuidId = GuidId;
        this.Name = Name;
        this.Description = Description;
        this.DeliveryServiceType = DeliveryServiceType;
        this.IsArchived = IsArchived;
        this.IsNewAdded = IsNewAdded;
        this.CostMatrix = CostMatrix;
        this.CalculationFormula = CalculationFormula;
    }
}

export function CostMatrix_FreightCostZone$reflection() {
    return record_type("SettingsFreightCostZone.CostMatrix.FreightCostZone", [], CostMatrix_FreightCostZone, () => [["Id", string_type], ["GuidId", class_type("System.Guid")], ["Name", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["Description", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["DeliveryServiceType", SettingsFreight_DeliveryServiceType$reflection()], ["IsArchived", bool_type], ["IsNewAdded", bool_type], ["CostMatrix", list_type(list_type(class_type("System.Decimal")))], ["CalculationFormula", SettingsFreight_CalculationFormula$reflection()]]);
}

export function CostMatrix_toSharedStructure(zone) {
    return new SettingsFreight_FreightCostSetting(new SettingsFreight_FreightCostBasicSetting(zone.Id, zone.Name, zone.Description, zone.DeliveryServiceType, zone.IsArchived), new SettingsFreight_FreightCostMatrixSetting(zone.CostMatrix, zone.CalculationFormula));
}

export class CostMatrix_ExternalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FreightZoneChanged", "ArchiveFreightZone"];
    }
}

export function CostMatrix_ExternalMsg$reflection() {
    return union_type("SettingsFreightCostZone.CostMatrix.ExternalMsg", [], CostMatrix_ExternalMsg, () => [[["Item1", class_type("System.Guid")], ["Item2", CostMatrix_FreightCostZone$reflection()]], [["Item", class_type("System.Guid")]]]);
}

export class CostMatrix_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SwitchToEditMode", "CancelEdit", "NameChanged", "ArchiveStatusChanged", "OnDescriptionChanged", "OnContentLanguageChanged", "CostMatrixChanged", "CalculationFormulaChanged", "AddCostMatrixFreightClassRow", "RemoveCostMatrixFreightClassRow", "AddCostMatrixItemColumn", "RemoveCostMatrixItemColumn", "SaveForm"];
    }
}

export function CostMatrix_Msg$reflection() {
    return union_type("SettingsFreightCostZone.CostMatrix.Msg", [], CostMatrix_Msg, () => [[], [], [["Item1", string_type], ["Item2", string_type]], [["Item1", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["Item2", class_type("System.Guid")]], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [["Item1", int32_type], ["Item2", int32_type], ["Item3", class_type("System.Decimal")]], [["Item", SettingsFreight_CalculationFormula$reflection()]], [], [], [], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function CostMatrix_Cmd_onlyZoneFreightSorted(zones) {
    return sortBy((x_1) => parse(substring(DeliveryCostZoneIdModule_toString(x_1.Id), 3), 511, false, 32), filter((x) => (DeliveryCostZoneIdModule_toString(x.Id).indexOf("zone") >= 0), zones), {
        Compare: (x_2, y) => comparePrimitives(x_2, y),
    });
}

export function CostMatrix_Cmd_getNextZoneId(zones) {
    return defaultArg(map_1((x_3) => DeliveryCostZoneIdModule_fromString(`zone${(x_3 + 1)}`), tryHead(sortDescending(map((x_1) => parse(substring(DeliveryCostZoneIdModule_toString(x_1.Id), 3), 511, false, 32), filter((x) => (DeliveryCostZoneIdModule_toString(x.Id).indexOf("zone") >= 0), zones)), {
        Compare: (x_2, y) => comparePrimitives(x_2, y),
    }))), DeliveryCostZoneIdModule_fromString("zone1"));
}

export function CostMatrix_Cmd_updateOrCreateFreightSettings(info) {
    return singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => (info.IsNewAdded ? singleton_2.Bind(Remoting_handleNonAuth(settingsApi().createFreightType(CostMatrix_toSharedStructure(info))), (_arg1) => singleton_2.Return(new CostMatrix_Msg(12, new AsyncOperationStatus$1(1, _arg1)))) : singleton_2.Bind(Remoting_handleNonAuth(settingsApi().updateFreightType(CostMatrix_toSharedStructure(info))), (_arg2) => singleton_2.Return(new CostMatrix_Msg(12, new AsyncOperationStatus$1(1, _arg2)))))), (_arg3) => singleton_2.Return(new CostMatrix_Msg(12, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg3.message))))));
}

export function CostMatrix_Cmd_changeArchiveStatus(id, info) {
    return singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => (info.IsNewAdded ? singleton_2.Return(new CostMatrix_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0)), id)) : singleton_2.Bind(Remoting_handleNonAuth(settingsApi().updateFreightType(CostMatrix_toSharedStructure(info))), (_arg1) => singleton_2.Return(new CostMatrix_Msg(3, new AsyncOperationStatus$1(1, _arg1), id))))), (_arg2) => singleton_2.Return(new CostMatrix_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message)), id))));
}

export class CostMatrix_State extends Record {
    constructor(FreightZone, OriginalFreightZones, FreightZonesLength, ChangeArchiveStatusOperation, SaveFormOperation, IsNotEditTermsMode, FormErrors, NeedToValidate, ContentLanguage) {
        super();
        this.FreightZone = FreightZone;
        this.OriginalFreightZones = OriginalFreightZones;
        this.FreightZonesLength = (FreightZonesLength | 0);
        this.ChangeArchiveStatusOperation = ChangeArchiveStatusOperation;
        this.SaveFormOperation = SaveFormOperation;
        this.IsNotEditTermsMode = IsNotEditTermsMode;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.ContentLanguage = ContentLanguage;
    }
}

export function CostMatrix_State$reflection() {
    return record_type("SettingsFreightCostZone.CostMatrix.State", [], CostMatrix_State, () => [["FreightZone", CostMatrix_FreightCostZone$reflection()], ["OriginalFreightZones", CostMatrix_FreightCostZone$reflection()], ["FreightZonesLength", int32_type], ["ChangeArchiveStatusOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["IsNotEditTermsMode", bool_type], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["ContentLanguage", string_type]]);
}

export function CostMatrix_init(settingsListLength, settings) {
    return [new CostMatrix_State(settings, settings, settingsListLength, new Deferred$1(0), new Deferred$1(0), !settings.IsNewAdded, empty(), false, getPrimaryLanguage()), Cmd_none()];
}

export function CostMatrix_toValidationType(freight) {
    return new SettingsFreight_SettingsFreight(freight.Name, freight.CostMatrix);
}

export function CostMatrix_withFreightZone(zone, state) {
    return new CostMatrix_State(zone, state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, state.SaveFormOperation, state.IsNotEditTermsMode, state.FormErrors, state.NeedToValidate, state.ContentLanguage);
}

export function CostMatrix_withFormAndErrors(newZone, state, _form, errors) {
    return new CostMatrix_State(newZone, state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, state.SaveFormOperation, state.IsNotEditTermsMode, errors, state.NeedToValidate, state.ContentLanguage);
}

export function CostMatrix_update(updateFreightSetting, setShowConfirmFalse, msg, state) {
    let inputRecord, inputRecord_1, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, oldCostMatrix, inputRecord_7, oldCostMatrix_1, inputRecord_8;
    const withValidatedForm = (zone_1, state_1) => {
        const tupledArg = SettingsFreight_validateIf(state.NeedToValidate, CostMatrix_toValidationType(zone_1));
        return CostMatrix_withFormAndErrors(zone_1, state_1, tupledArg[0], tupledArg[1]);
    };
    if (msg.tag === 1) {
        return [new CostMatrix_State(state.OriginalFreightZones, state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, state.SaveFormOperation, true, state.FormErrors, state.NeedToValidate, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm((inputRecord = state.FreightZone, new CostMatrix_FreightCostZone(inputRecord.Id, inputRecord.GuidId, LocalizedStringModule_setTranslation(msg.fields[1], msg.fields[0], state.FreightZone.Name), inputRecord.Description, inputRecord.DeliveryServiceType, inputRecord.IsArchived, inputRecord.IsNewAdded, inputRecord.CostMatrix, inputRecord.CalculationFormula)), state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new CostMatrix_State(state.FreightZone, state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, state.SaveFormOperation, state.IsNotEditTermsMode, state.FormErrors, state.NeedToValidate, msg.fields[0]), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new CostMatrix_State((inputRecord_1 = state.FreightZone, new CostMatrix_FreightCostZone(inputRecord_1.Id, inputRecord_1.GuidId, inputRecord_1.Name, LocalizedStringModule_setTranslation(msg.fields[1], msg.fields[0], state.FreightZone.Description), inputRecord_1.DeliveryServiceType, inputRecord_1.IsArchived, inputRecord_1.IsNewAdded, inputRecord_1.CostMatrix, inputRecord_1.CalculationFormula)), state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, state.SaveFormOperation, state.IsNotEditTermsMode, state.FormErrors, state.NeedToValidate, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            return [new CostMatrix_State(state.FreightZone, state.FreightZone, state.FreightZonesLength, new Deferred$1(2, msg.fields[0].fields[0]), state.SaveFormOperation, true, state.FormErrors, state.NeedToValidate, state.ContentLanguage), Cmd_ofSub((_arg1) => {
                setShowConfirmFalse();
                updateFreightSetting(new CostMatrix_ExternalMsg(1, msg.fields[1]));
            })];
        }
        else {
            let zone_3;
            const inputRecord_2 = state.FreightZone;
            zone_3 = (new CostMatrix_FreightCostZone(inputRecord_2.Id, inputRecord_2.GuidId, inputRecord_2.Name, inputRecord_2.Description, inputRecord_2.DeliveryServiceType, !state.FreightZone.IsArchived, inputRecord_2.IsNewAdded, inputRecord_2.CostMatrix, inputRecord_2.CalculationFormula));
            return [new CostMatrix_State(zone_3, state.OriginalFreightZones, state.FreightZonesLength, new Deferred$1(1), state.SaveFormOperation, state.IsNotEditTermsMode, state.FormErrors, state.NeedToValidate, state.ContentLanguage), Cmd_fromAsync(CostMatrix_Cmd_changeArchiveStatus(msg.fields[1], zone_3))];
        }
    }
    else if (msg.tag === 6) {
        return [new CostMatrix_State((inputRecord_3 = state.FreightZone, new CostMatrix_FreightCostZone(inputRecord_3.Id, inputRecord_3.GuidId, inputRecord_3.Name, inputRecord_3.Description, inputRecord_3.DeliveryServiceType, inputRecord_3.IsArchived, inputRecord_3.IsNewAdded, map((tupledArg_1) => {
            const costs = tupledArg_1[1];
            if (tupledArg_1[0] === (msg.fields[0] - 1)) {
                return map((tupledArg_2) => {
                    if (tupledArg_2[0] === (msg.fields[1] - 1)) {
                        return msg.fields[2];
                    }
                    else {
                        return tupledArg_2[1];
                    }
                }, indexed(costs));
            }
            else {
                return costs;
            }
        }, indexed(state.FreightZone.CostMatrix)), inputRecord_3.CalculationFormula)), state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, state.SaveFormOperation, state.IsNotEditTermsMode, state.FormErrors, state.NeedToValidate, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new CostMatrix_State((inputRecord_4 = state.FreightZone, new CostMatrix_FreightCostZone(inputRecord_4.Id, inputRecord_4.GuidId, inputRecord_4.Name, inputRecord_4.Description, inputRecord_4.DeliveryServiceType, inputRecord_4.IsArchived, inputRecord_4.IsNewAdded, inputRecord_4.CostMatrix, msg.fields[0])), state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, state.SaveFormOperation, state.IsNotEditTermsMode, state.FormErrors, state.NeedToValidate, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [withValidatedForm((inputRecord_5 = state.FreightZone, new CostMatrix_FreightCostZone(inputRecord_5.Id, inputRecord_5.GuidId, inputRecord_5.Name, inputRecord_5.Description, inputRecord_5.DeliveryServiceType, inputRecord_5.IsArchived, inputRecord_5.IsNewAdded, isEmpty(state.FreightZone.CostMatrix) ? CostMatrix_matrix1To1 : append(state.FreightZone.CostMatrix, CostMatrix_DefaultOneFreightClassMatrix(length_1(head(state.FreightZone.CostMatrix)))), inputRecord_5.CalculationFormula)), state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withValidatedForm((inputRecord_6 = state.FreightZone, new CostMatrix_FreightCostZone(inputRecord_6.Id, inputRecord_6.GuidId, inputRecord_6.Name, inputRecord_6.Description, inputRecord_6.DeliveryServiceType, inputRecord_6.IsArchived, inputRecord_6.IsNewAdded, (oldCostMatrix = state.FreightZone.CostMatrix, truncate(length_1(oldCostMatrix) - 1, oldCostMatrix)), inputRecord_6.CalculationFormula)), state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [withValidatedForm((inputRecord_7 = state.FreightZone, new CostMatrix_FreightCostZone(inputRecord_7.Id, inputRecord_7.GuidId, inputRecord_7.Name, inputRecord_7.Description, inputRecord_7.DeliveryServiceType, inputRecord_7.IsArchived, inputRecord_7.IsNewAdded, (oldCostMatrix_1 = state.FreightZone.CostMatrix, (length_1(oldCostMatrix_1) === 0) ? CostMatrix_matrix1To1 : map((row) => append(row, singleton_1(CurrencyModule_zero)), oldCostMatrix_1)), inputRecord_7.CalculationFormula)), state), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [withValidatedForm((inputRecord_8 = state.FreightZone, new CostMatrix_FreightCostZone(inputRecord_8.Id, inputRecord_8.GuidId, inputRecord_8.Name, inputRecord_8.Description, inputRecord_8.DeliveryServiceType, inputRecord_8.IsArchived, inputRecord_8.IsNewAdded, map((rows) => truncate(length_1(rows) - 1, rows), state.FreightZone.CostMatrix), inputRecord_8.CalculationFormula)), state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].tag === 1) {
            let freightZone;
            if (msg.fields[0].fields[0].tag === 1) {
                const inputRecord_10 = state.FreightZone;
                freightZone = (new CostMatrix_FreightCostZone(inputRecord_10.Id, inputRecord_10.GuidId, inputRecord_10.Name, inputRecord_10.Description, inputRecord_10.DeliveryServiceType, inputRecord_10.IsArchived, state.FreightZone.IsNewAdded, inputRecord_10.CostMatrix, inputRecord_10.CalculationFormula));
            }
            else {
                const inputRecord_9 = state.FreightZone;
                freightZone = (new CostMatrix_FreightCostZone(inputRecord_9.Id, inputRecord_9.GuidId, inputRecord_9.Name, inputRecord_9.Description, inputRecord_9.DeliveryServiceType, inputRecord_9.IsArchived, false, inputRecord_9.CostMatrix, inputRecord_9.CalculationFormula));
            }
            return [new CostMatrix_State(freightZone, freightZone, state.FreightZonesLength, state.ChangeArchiveStatusOperation, new Deferred$1(2, msg.fields[0].fields[0]), true, state.FormErrors, false, state.ContentLanguage), Cmd_ofSub((_arg2) => {
                updateFreightSetting(new CostMatrix_ExternalMsg(0, state.FreightZone.GuidId, freightZone));
            })];
        }
        else if (!equals(state.FreightZone, state.OriginalFreightZones)) {
            const matchValue = SettingsFreight_validate(CostMatrix_toValidationType(state.FreightZone));
            if (matchValue.tag === 1) {
                return [new CostMatrix_State(state.FreightZone, state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, new Deferred$1(0), state.IsNotEditTermsMode, matchValue.fields[0], true, state.ContentLanguage), Cmd_none()];
            }
            else {
                return [new CostMatrix_State(state.FreightZone, state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, new Deferred$1(1), state.IsNotEditTermsMode, empty(), true, state.ContentLanguage), Cmd_fromAsync(CostMatrix_Cmd_updateOrCreateFreightSettings(state.FreightZone))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new CostMatrix_State(state.FreightZone, state.OriginalFreightZones, state.FreightZonesLength, state.ChangeArchiveStatusOperation, state.SaveFormOperation, false, state.FormErrors, state.NeedToValidate, state.ContentLanguage), Cmd_none()];
    }
}

export function CostMatrix_renderContentLanguageSwitcher(state, dispatch) {
    let children;
    const props_4 = ofArray([["className", "is-uppercase"], ["className", "is-toggle"], ["className", "pb-0"], ["className", "mb-1"], ["children", Interop_reactApi.Children.toArray([(children = toList(delay(() => map_2((lang) => {
        const props_1 = toList(delay(() => append_1((lang === state.ContentLanguage) ? singleton(["className", "is-active"]) : empty_1(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: "is-small-padding",
            onClick: (_arg1) => {
                dispatch(new CostMatrix_Msg(5, lang));
            },
            children: LangModule_toString(lang),
        })])])))));
        return createElement("li", createObj(Helpers_combineClasses("", props_1)));
    }, List_distinct(cons(getPrimaryLanguage(), getSupportedLanguages()), {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => stringHash(x),
    })))), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("tabs", props_4)));
}

export function CostMatrix_renderFreightMatrix(isNotEditTermsMode, costMatrix, dispatch) {
    let itemsCount, matchValue_2, children_2, isNotEditTermsMode_2, children_11;
    const props_23 = ofArray([["className", "is-fullwidth"], ["className", "my-3"], ["style", {
        display: "block",
        overflow: "auto",
    }], ["children", Interop_reactApi.Children.toArray([(itemsCount = (((matchValue_2 = tryHead(costMatrix), (matchValue_2 != null) ? length_1(matchValue_2) : 0)) | 0), createElement("thead", {
        className: "small-font-size",
        className: "has-text-right",
        children: Interop_reactApi.Children.toArray([(children_2 = toList(delay(() => append_1(singleton(createElement("th", {})), delay(() => append_1(map_2((count) => {
            let value_4;
            const children = singleton_1((value_4 = (`${count}`), createElement("span", {
                children: [value_4],
            })));
            return createElement("th", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
        }, toList(rangeDouble(1, 1, itemsCount))), delay(() => {
            let props_3, props_5;
            return singleton(createElement("th", {
                style: {
                    whiteSpace: "nowrap",
                },
                children: Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append_1(singleton(["style", {
                    color: "#D3D3D3",
                }]), delay(() => append_1(singleton(["className", join(" ", ["fas", "fa-plus-circle"])]), delay(() => ((!isNotEditTermsMode) ? singleton(["onClick", (x) => {
                    x.preventDefault();
                    (() => {
                        dispatch(new CostMatrix_Msg(10));
                    })();
                }]) : empty_1()))))))), createElement("span", createObj(Helpers_combineClasses("icon", props_3)))), (props_5 = toList(delay(() => append_1(singleton(["style", {
                    color: "#D3D3D3",
                }]), delay(() => append_1(singleton(["className", join(" ", ["fas", "fa-minus-circle"])]), delay(() => ((!isNotEditTermsMode) ? singleton(["onClick", (x_1) => {
                    x_1.preventDefault();
                    (() => {
                        dispatch(new CostMatrix_Msg(11));
                    })();
                }]) : empty_1()))))))), createElement("span", createObj(Helpers_combineClasses("icon", props_5))))]),
            }));
        })))))), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))]),
    })), (isNotEditTermsMode_2 = isNotEditTermsMode, (children_11 = toList(delay(() => append_1(collect((matchValue_1) => {
        let freightClass, children_6;
        return singleton((freightClass = (matchValue_1[0] | 0), react.createElement(react.Fragment, {}, (children_6 = toList(delay(() => append_1(singleton(createElement("td", {
            style: {
                whiteSpace: "nowrap",
            },
            children: tWithArgs("settings.freightClassWithArg", {
                freightClass: FreightClassModule_fromInt(freightClass),
            }),
        })), delay(() => collect((matchValue) => {
            let children_4, props_11, value_29;
            return singleton((children_4 = singleton_1((props_11 = ofArray([["style", {
                whiteSpace: "nowrap",
            }], ["disabled", isNotEditTermsMode_2], ["onChange", (ev) => {
                dispatch(new CostMatrix_Msg(6, freightClass, matchValue[0], CurrencyModule_fromInt(parse(ev.target.value, 511, false, 32))));
            }], ["placeholder", "0"], (value_29 = (`${matchValue[1]}`), ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_29)) : false) {
                    e.value = value_29;
                }
            }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_11)))))), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })));
        }, map((tupledArg) => [tupledArg[0] + 1, tupledArg[1]], indexed(matchValue_1[1]))))))), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        })))));
    }, map((tupledArg_1) => [tupledArg_1[0] + 1, tupledArg_1[1]], indexed(costMatrix))), delay(() => {
        let children_9, props_16, props_18;
        return singleton((children_9 = singleton_1(createElement("td", {
            style: {
                whiteSpace: "nowrap",
            },
            children: Interop_reactApi.Children.toArray([(props_16 = toList(delay(() => append_1(singleton(["style", {
                color: "#D3D3D3",
            }]), delay(() => append_1(singleton(["className", join(" ", ["fas", "fa-plus-circle"])]), delay(() => ((!isNotEditTermsMode_2) ? singleton(["onClick", (x_4) => {
                x_4.preventDefault();
                (() => {
                    dispatch(new CostMatrix_Msg(8));
                })();
            }]) : empty_1()))))))), createElement("span", createObj(Helpers_combineClasses("icon", props_16)))), (props_18 = toList(delay(() => append_1(singleton(["style", {
                color: "#D3D3D3",
            }]), delay(() => append_1(singleton(["className", join(" ", ["fas", "fa-minus-circle"])]), delay(() => ((!isNotEditTermsMode_2) ? singleton(["onClick", (x_5) => {
                x_5.preventDefault();
                (() => {
                    dispatch(new CostMatrix_Msg(9));
                })();
            }]) : empty_1()))))))), createElement("span", createObj(Helpers_combineClasses("icon", props_18))))]),
        })), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_9)),
        })));
    })))), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_11)),
    })))])]]);
    return createElement("table", createObj(Helpers_combineClasses("table", props_23)));
}

export function CostMatrix_renderCalculationFormula(state, dispatch) {
    let props_11, elms_1, props_2, props, elms, patternInput, value_22;
    const fromMethod = (m_1) => {
        switch (m_1.tag) {
            case 0: {
                return "Total = Sum(Class cost)";
            }
            case 2: {
                return "Max(Class cost) + 0.5*Sum(Other classes cost)";
            }
            case 3: {
                return "Max(Class cost) + 0.6*Sum(Other classes cost)";
            }
            default: {
                return "Total = Max(Class cost)";
            }
        }
    };
    const props_13 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(props_11 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.formula")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "has-text-left"], ["className", "is-size-6"], ["className", "mb-1"], ["className", "has-text-grey"], ["disabled", state.IsNotEditTermsMode], ["onChange", (ev) => {
        let m;
        dispatch(new CostMatrix_Msg(7, (m = ev.target.value, (m === "Total = Max(Class cost)") ? (new SettingsFreight_CalculationFormula(1)) : ((m === "Total = Sum(Class cost)") ? (new SettingsFreight_CalculationFormula(0)) : ((m === "Max(Class cost) + 0.5*Sum(Other classes cost)") ? (new SettingsFreight_CalculationFormula(2)) : ((m === "Max(Class cost) + 0.6*Sum(Other classes cost)") ? (new SettingsFreight_CalculationFormula(3)) : (() => {
            throw (new Error("Unexpected value"));
        })()))))));
    }], (value_22 = fromMethod(state.FreightZone.CalculationFormula), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_22)) : false) {
            e.value = value_22;
        }
    }]), ["children", Interop_reactApi.Children.toArray(Array.from(map((x_1) => createElement("option", {
        children: fromMethod(x_1),
    }), ofArray([new SettingsFreight_CalculationFormula(0), new SettingsFreight_CalculationFormula(1), new SettingsFreight_CalculationFormula(2), new SettingsFreight_CalculationFormula(3)]))))]])), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_11))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("column", props_13)));
}

export function CostMatrix_renderDescription(state, dispatch) {
    let elms_3, elms_2;
    const props_12 = ofArray([["className", "is-variable"], ["className", "is-vcentered"], ["className", "is-multiline"], ["className", "px-5"], ["className", "mx-0"], ["className", "my-2"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_1((elms_2 = toList(delay(() => {
        let elms_1, props_2, props, elms, props_4, value_29, props_7;
        const errors = Form_getFieldError(state.FormErrors, "ZoneName");
        return singleton((elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.description")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1((props_4 = ofArray([["disabled", state.IsNotEditTermsMode], ["onChange", (ev) => {
            dispatch(new CostMatrix_Msg(4, ev.target.value, state.ContentLanguage));
        }], ["placeholder", t("settings.description")], (value_29 = defaultArg(LocalizedStringModule_findTranslation(state.ContentLanguage, state.FreightZone.Description), ""), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_29)) : false) {
                e.value = value_29;
            }
        }])]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_4))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_7 = ofArray([["className", "is-danger"], ["children", fold((s, x_1) => (`${s} ${x_1}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_7))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_12)));
}

export function CostMatrix_renderMatrix(state, dispatch) {
    let props_10, props_8;
    return createElement("div", {
        className: "px-5",
        style: {
            display: "flex",
        },
        children: Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "has-text-grey-dark"], ["className", "is-12"], ["children", Interop_reactApi.Children.toArray([(props_8 = toList(delay(() => {
            let elms_1, props_2, props, elms, props_5;
            const errors = Form_getFieldError(state.FormErrors, "CostMatrix");
            return singleton(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.freightCostMatrix")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1(CostMatrix_renderFreightMatrix(state.IsNotEditTermsMode, state.FreightZone.CostMatrix, dispatch)), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (props_5 = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_5))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]);
        })), createElement("div", createObj(Helpers_combineClasses("field", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))]),
    });
}

export function CostMatrix_Render(costMatrix_RenderInputProps) {
    let props_37, elms_3, props_53, props_51, props_49, dispatch_1, state_2, props_2, props;
    const archiveFreightZone = costMatrix_RenderInputProps.archiveFreightZone;
    const settings = costMatrix_RenderInputProps.settings;
    const settingsListLength = costMatrix_RenderInputProps.settingsListLength;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const showConfirm = patternInput[0];
    const setShowConfirm = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useElmish_Static_17DC4F1D(CostMatrix_init(settingsListLength, settings), (msg, state) => CostMatrix_update(archiveFreightZone, () => {
        setShowConfirm(false);
    }, msg, state), [settings.GuidId]);
    const state_1 = patternInput_1[0];
    const dispatch = patternInput_1[1];
    let renderConfirmationDialog;
    const props_34 = toList(delay(() => append_1(showConfirm ? singleton(["className", "is-active"]) : empty_1(), delay(() => {
        let elms_2, elms, s, elms_1, s_1, props_31, props_29, props_25, props_27;
        return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", empty_2()))), (elms_2 = ofArray([(elms = ofArray([(s = t("settings.archiveZone"), createElement("p", {
            className: "modal-card-title",
            children: s,
        })), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg3) => {
            setShowConfirm(false);
        }]))))]), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton_1((s_1 = t("settings.archiveZoneConfirmation"), createElement("p", {
            className: "",
            children: s_1,
        }))), createElement("section", {
            className: "modal-card-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (props_31 = ofArray([["style", {
            display: "block",
        }], ["children", Interop_reactApi.Children.toArray([(props_29 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_25 = toList(delay(() => append_1(equals(state_1.ChangeArchiveStatusOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append_1(singleton(["children", t("common.cancel")]), delay(() => singleton(["onClick", (_arg4) => {
            setShowConfirm(false);
        }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_25)))), (props_27 = toList(delay(() => append_1(equals(state_1.ChangeArchiveStatusOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append_1(singleton(["className", "is-danger"]), delay(() => append_1(singleton(["children", t("settings.archive")]), delay(() => singleton(["onClick", (x_4) => {
            x_4.preventDefault();
            dispatch(new CostMatrix_Msg(3, new AsyncOperationStatus$1(0), state_1.FreightZone.GuidId));
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_27))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_29))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_31))))]), createElement("div", {
            className: "modal-card",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))])]);
    }))));
    renderConfirmationDialog = createElement("div", createObj(Helpers_combineClasses("modal", props_34)));
    return createElement("div", {
        key: `${state_1.FreightZone.GuidId}`,
        children: Interop_reactApi.Children.toArray([(props_37 = ofArray([["className", "is-variable"], ["className", "is-vcentered"], ["className", "is-multiline"], ["className", "px-5"], ["className", "mx-0"], ["className", "my-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_1(CostMatrix_renderContentLanguageSwitcher(state_1, dispatch)), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_37)))), (props_53 = ofArray([["className", "is-variable"], ["className", "is-vcentered"], ["className", "is-multiline"], ["className", "px-5"], ["className", "mx-0"], ["className", "my-0"], ["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(props_51 = ofArray([["className", "has-text-grey-dark"], ["className", "is-5"], ["children", Interop_reactApi.Children.toArray([(props_49 = toList(delay(() => {
            let elms_5, props_41, props_39, elms_4, props_43, value_170, props_46;
            const errors = Form_getFieldError(state_1.FormErrors, "ZoneName");
            return singleton(["children", Interop_reactApi.Children.toArray([(elms_5 = ofArray([(props_41 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_39 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.title")]]), createElement("label", createObj(Helpers_combineClasses("label", props_39))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_41)))), (elms_4 = singleton_1((props_43 = ofArray([["disabled", state_1.IsNotEditTermsMode], ["onChange", (ev) => {
                let tupledArg;
                dispatch((tupledArg = [ev.target.value, state_1.ContentLanguage], new CostMatrix_Msg(2, tupledArg[0], tupledArg[1])));
            }], ["placeholder", t("common.title")], (value_170 = defaultArg(LocalizedStringModule_findTranslation(state_1.ContentLanguage, state_1.FreightZone.Name), ""), ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_170)) : false) {
                    e.value = value_170;
                }
            }])]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_43)))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            })), (props_46 = ofArray([["className", "is-danger"], ["children", fold((s_2, x_6) => (`${s_2} ${x_6}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_46))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            }))])]);
        })), createElement("div", createObj(Helpers_combineClasses("field", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_51)))), CostMatrix_renderCalculationFormula(state_1, dispatch)])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_53)))), CostMatrix_renderDescription(state_1, dispatch), CostMatrix_renderMatrix(state_1, dispatch), (dispatch_1 = dispatch, (state_2 = state_1, state_2.IsNotEditTermsMode ? ((props_2 = ofArray([["className", "is-right"], ["className", "px-5"], ["className", "pb-5"], ["className", "mb-4"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "has-background-grey-lighter"], ["children", t("common.edit")], ["onClick", (x) => {
            x.preventDefault();
            dispatch_1(new CostMatrix_Msg(0));
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_2))))) : react.createElement(react.Fragment, {}, ...toList(delay(() => {
            let props_8;
            return append_1((state_2.FreightZonesLength === 1) ? singleton(null) : singleton((props_8 = ofArray([["className", "is-right"], ["className", "px-5"], ["className", "mb-1"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props_4, props_6;
                return state_2.FreightZone.IsArchived ? singleton((props_4 = toList(delay(() => append_1(singleton(["className", "is-dark"]), delay(() => append_1(singleton(["className", "mr-4"]), delay(() => append_1(singleton(["disabled", equals(state_2.SaveFormOperation, new Deferred$1(1))]), delay(() => append_1(equals(state_2.ChangeArchiveStatusOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append_1(singleton(["className", "is-outlined"]), delay(() => append_1(singleton(["children", t("settings.activateZone")]), delay(() => singleton(["onClick", (x_1) => {
                    x_1.preventDefault();
                    dispatch_1(new CostMatrix_Msg(3, new AsyncOperationStatus$1(0), state_2.FreightZone.GuidId));
                }]))))))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_4))))) : singleton((props_6 = toList(delay(() => append_1(singleton(["className", "is-dark"]), delay(() => append_1(singleton(["className", "mr-4"]), delay(() => append_1(singleton(["disabled", equals(state_2.SaveFormOperation, new Deferred$1(1))]), delay(() => append_1(equals(state_2.ChangeArchiveStatusOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append_1(singleton(["className", "is-outlined"]), delay(() => append_1(singleton(["children", t("settings.archiveZone")]), delay(() => singleton(["onClick", (x_2) => {
                    x_2.preventDefault();
                    setShowConfirm(!showConfirm);
                }]))))))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_6)))));
            }))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_8))))), delay(() => {
                let props_14, props_10, props_12;
                return singleton((props_14 = ofArray([["className", "is-right"], ["className", "mb-4"], ["className", "px-5"], ["className", "pb-5"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-dark"], ["disabled", equals(state_2.SaveFormOperation, new Deferred$1(1)) ? true : equals(state_2.ChangeArchiveStatusOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (_arg2) => {
                    dispatch_1(new CostMatrix_Msg(1));
                }]]), createElement("button", createObj(Helpers_combineClasses("button", props_10)))), (props_12 = toList(delay(() => append_1(singleton(["className", "is-primary"]), delay(() => append_1(singleton(["className", "mr-4"]), delay(() => append_1((equals(state_2.FreightZone, state_2.OriginalFreightZones) ? true : equals(state_2.ChangeArchiveStatusOperation, new Deferred$1(1))) ? singleton(["disabled", true]) : empty_1(), delay(() => append_1(equals(state_2.SaveFormOperation, new Deferred$1(1)) ? singleton(["className", "is-loading"]) : empty_1(), delay(() => append_1(settings.IsNewAdded ? singleton(["children", t("common.create")]) : singleton(["children", t("common.save")]), delay(() => singleton(["onClick", (x_3) => {
                    x_3.preventDefault();
                    dispatch_1(new CostMatrix_Msg(12, new AsyncOperationStatus$1(0)));
                }]))))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_14)))));
            }));
        }))))), renderConfirmationDialog]),
    });
}

export function CostMatrix_RenderMatrix(costMatrix_RenderMatrixInputProps) {
    let s;
    const archiveFreightZone = costMatrix_RenderMatrixInputProps.archiveFreightZone;
    const settings = costMatrix_RenderMatrixInputProps.settings;
    const settingsList = costMatrix_RenderMatrixInputProps.settingsList;
    const getMatrix = useFeliz_React__React_useDeferred_Static_2344FC52(singleton_2.Delay(() => (settings.IsNewAdded ? singleton_2.Return(CostMatrix_getDefaultMatrixSettings) : singleton_2.ReturnFrom(Remoting_handleNonAuth(settingsApi().getFreightCostMatrix(settings.Id))))), [settings.GuidId]);
    switch (getMatrix.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            const value = getMatrix.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const matrix = getMatrix.fields[0];
            return createElement(CostMatrix_Render, {
                settingsListLength: length_1(settingsList),
                settings: (s = settings, new CostMatrix_FreightCostZone(s.Id, s.GuidId, s.Name, s.Description, s.DeliveryServiceType, s.IsArchived, s.IsNewAdded, matrix.CostMatrix, matrix.CalculationFormula)),
                archiveFreightZone: archiveFreightZone,
            });
        }
        default: {
            return null;
        }
    }
}

export function BasicSettings_RenderFreightZone(basicSettings_RenderFreightZoneInputProps) {
    const archiveFreightZone = basicSettings_RenderFreightZoneInputProps.archiveFreightZone;
    const settings = basicSettings_RenderFreightZoneInputProps.settings;
    const settingsList = basicSettings_RenderFreightZoneInputProps.settingsList;
    const patternInput = useFeliz_React__React_useState_Static_1505(settings.IsNewAdded);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    let basicFreightZoneInfo;
    const props_18 = toList(delay(() => append_1(singleton(["className", "px-5"]), delay(() => append_1(singleton(["className", "mx-0"]), delay(() => append_1(singleton(["className", "my-0"]), delay(() => append_1(singleton(["className", "py-0"]), delay(() => append_1(singleton(["className", "is-size-6"]), delay(() => append_1(isVisible ? singleton(["className", "has-background-white"]) : singleton(["className", "has-background-grey-lighter"]), delay(() => append_1(singleton(["className", "is-vcentered"]), delay(() => {
        let props_2, props, props_6, props_4, props_11, props_16, props_14;
        return singleton(["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-5"], ["style", {
            cursor: "pointer",
        }], ["onClick", (x) => {
            x.preventDefault();
            setVisible(!isVisible);
        }], ["children", Interop_reactApi.Children.toArray([(props = singleton_1(["children", DeliveryCostZoneIdModule_toString(settings.Id)]), createElement("p", createObj(Helpers_combineClasses("", props))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_6 = ofArray([["className", "is-5"], ["style", {
            cursor: "pointer",
        }], ["onClick", (x_1) => {
            x_1.preventDefault();
            setVisible(!isVisible);
        }], ["children", Interop_reactApi.Children.toArray([(props_4 = singleton_1(["children", Localized_translate(settings.Name)]), createElement("p", createObj(Helpers_combineClasses("", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_11 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms, props_8;
            return settings.IsArchived ? singleton((elms = singleton_1((props_8 = ofArray([["className", "is-dark"], ["children", t("settings.archived")]]), createElement("span", createObj(Helpers_combineClasses("tag", props_8))))), createElement("div", {
                className: "tags",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))) : empty_1();
        }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_11)))), (props_16 = ofArray([["style", {
            cursor: "pointer",
        }], ["onClick", (x_2) => {
            x_2.preventDefault();
            setVisible(!isVisible);
        }], ["className", "hoverablePie"], ["className", "is-1"], ["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (isVisible ? singleton(["className", join(" ", ["fas", "fa-angle-up", "fa-lg"])]) : singleton(["className", join(" ", ["fas", "fa-angle-down", "fa-lg"])]))))))])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]);
    }))))))))))))))));
    basicFreightZoneInfo = createElement("div", createObj(Helpers_combineClasses("columns", props_18)));
    return createElement("div", {
        style: {
            marginBottom: 0.5 + "rem",
        },
        key: `${settings.GuidId}`,
        className: "has-background-white",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(basicFreightZoneInfo), delay(() => {
            let props_20;
            return isVisible ? append_1(singleton((props_20 = singleton_1(["style", {
                margin: 0,
            }]), createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", props_20))))), delay(() => singleton(createElement(CostMatrix_RenderMatrix, {
                settingsList: settingsList,
                settings: settings,
                archiveFreightZone: archiveFreightZone,
            })))) : empty_1();
        })))))),
    });
}

