import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { ItemIdModule_fromString, ItemIdModule_toString, AuctionIdModule_fromString, AuctionIdModule_toString, ItemIdModule_empty, AuctionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { itemApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { filter, singleton as singleton_1, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export function Component() {
    let elms_8, elms_3, props_2, props_17, elms_7, props_21, props_39;
    const patternInput = useFeliz_React__React_useState_Static_1505(AuctionIdModule_empty);
    const auctionId = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(ItemIdModule_empty);
    const itemId = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const reservedItems = patternInput_2[0];
    const getReserved_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => Remoting_handleNonAuth(itemApi().getReservedAndHasNoLots(auctionId)), patternInput_2[1]);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const cancelReservationResult = patternInput_3[0];
    const cancelReservation_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => Remoting_handleNonAuth(itemApi().cancelReservedAndHasNoLots([auctionId, itemId])), patternInput_3[1]);
    const elms_9 = singleton_1((elms_8 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Remove auction reservation from Item without lot",
    }), (elms_3 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Get items reserved and without lot per auction",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_17 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, s_2, elms, props_5, value_24;
        return append(singleton((elms_1 = ofArray([(s_2 = (`Auction id = ${auctionId}`), createElement("label", {
            className: "label",
            children: s_2,
        })), (elms = singleton_1((props_5 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_24 = AuctionIdModule_toString(auctionId), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_24)) : false) {
                e.value = value_24;
            }
        }]), ["onChange", (ev) => {
            patternInput[1](AuctionIdModule_fromString(ev.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_5)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let result_1, value_36, props_10;
            return append((reservedItems.tag === 1) ? singleton(null) : ((reservedItems.tag === 2) ? ((result_1 = filter((x_1) => {
                if (!x_1.CreatedInCatalog) {
                    return true;
                }
                else {
                    return !x_1.CreatedInDomainLot;
                }
            }, reservedItems.fields[0]), singleton((value_36 = (`${result_1}`), createElement("div", {
                children: [value_36],
            }))))) : ((reservedItems.tag === 3) ? singleton((props_10 = ofArray([["className", "is-danger"], ["children", reservedItems.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_10))))) : singleton(null))), delay(() => {
                let props_15, elms_2, props_12;
                return singleton((props_15 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_1((props_12 = toList(delay(() => append(Deferred_inProgress(reservedItems) ? singleton(["className", "is-loading"]) : empty(), delay(() => append(singleton(["children", "Get items"]), delay(() => singleton(["onClick", (_arg1) => {
                    getReserved_1();
                }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_15)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_17))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_7 = ofArray([(props_21 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Remove auction reservation from Item without lot",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_21)))), (props_39 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_5, s_4, elms_4, props_24, value_77;
        return append(singleton((elms_5 = ofArray([(s_4 = (`ItemId id = ${itemId}, AuctionId = ${auctionId}`), createElement("label", {
            className: "label",
            children: s_4,
        })), (elms_4 = singleton_1((props_24 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_77 = ItemIdModule_toString(itemId), ["ref", (e_1) => {
            if ((!(e_1 == null)) ? (!equals(e_1.value, value_77)) : false) {
                e_1.value = value_77;
            }
        }]), ["onChange", (ev_1) => {
            patternInput_1[1](ItemIdModule_fromString(ev_1.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_24)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        }))), delay(() => {
            let props_30, props_32;
            return append((cancelReservationResult.tag === 1) ? singleton(null) : ((cancelReservationResult.tag === 2) ? ((cancelReservationResult.fields[0].tag === 1) ? singleton((props_30 = ofArray([["className", "is-warning"], ["children", `Failed to cancel reservation with reason: ${cancelReservationResult.fields[0].fields[0]}`]]), createElement("div", createObj(Helpers_combineClasses("notification", props_30))))) : singleton(createElement("div", createObj(Helpers_combineClasses("notification", singleton_1(["children", "Cancelled reservation successfully"])))))) : ((cancelReservationResult.tag === 3) ? singleton((props_32 = ofArray([["className", "is-danger"], ["children", cancelReservationResult.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_32))))) : singleton(null))), delay(() => {
                let props_37, elms_6, props_34;
                return singleton((props_37 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_6 = singleton_1((props_34 = toList(delay(() => append(singleton(["className", "is-danger"]), delay(() => append(Deferred_inProgress(cancelReservationResult) ? singleton(["className", "is-loading"]) : empty(), delay(() => append(singleton(["children", "Cancel reservation"]), delay(() => singleton(["onClick", (_arg2) => {
                    cancelReservation_1();
                }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_34))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_37)))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_39))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    });
}

