import { createElement } from "react";
import { Route, toPath } from "./Router.js";
import { singleton, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Component } from "./Components/TransactionCreating.js";
import { Component as Component_1 } from "./Components/ChangeOrderStatus.js";
import { Excluded, Included } from "./Components/IncludeWarehouseFee.js";
import { Component as Component_2 } from "./Components/RemoveItemFromPayout.js";
import { Component as Component_3 } from "./Components/CleanupPayout.js";
import { Component as Component_4 } from "./Components/FixClaimedItemsInSubmission.js";
import { Component as Component_5 } from "./Components/FixRefundedItemsInSubmission.js";
import { Component as Component_6 } from "./Components/FixReturnedItemsInSubmission.js";
import { Component as Component_7 } from "./Components/FixRecycledItemsInSubmission.js";
import { View_Component } from "./Components/LiveBiddingPause.js";
import { RemoveItemWithDeletedLot_Component, RemoveItemWithoutLots_Component } from "./Components/RemoveItemWithoutLots.js";
import { Component as Component_8 } from "./Components/AuctionCatalog.js";
import { Component as Component_9 } from "./Components/FixClaimAfterReturn.js";
import { Component as Component_10 } from "./Components/FixHammerFee.js";
import { Component as Component_11 } from "./Components/FixRemoveSellerVatFromOrders.js";
import { Component as Component_12 } from "./Components/FixRemoveSellerVatFromSubmission.js";
import { Component as Component_13 } from "./Components/FixResetCommissionInSubmissionsAndLots.js";
import { Component as Component_14 } from "./Components/ChangeUserType.js";
import { Component as Component_15 } from "./Components/FixItemsWithoutLot.js";
import { Component as Component_16 } from "./Components/GetOverpaidLots.js";
import { Component as Component_17 } from "./Components/CopyContentTranslationsFromSwedishToFinnish.js";
import { Component as Component_18 } from "./Components/ChangeReservedPriceAfterBiddingStarted.js";
import { Component as Component_19 } from "./Components/FixOrderResultReportOfUser.js";
import { Component as Component_20 } from "./Components/FixClaimInPaidOrders.js";

export const claimReportWithPaidClaims = (() => {
    let elms, props_1;
    const elms_1 = singleton((elms = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Link to claim report witch have paid",
    }), (props_1 = ofArray([["children", "Link"], ["href", toPath(new Route(55))]]), createElement("a", createObj(Helpers_combineClasses("button", props_1))))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
})();

export function view() {
    const children = ofArray([createElement(Component, null), createElement(Component_1, null), createElement(Included, null), createElement(Excluded, null), createElement(Component_2, null), createElement(Component_3, null), createElement(Component_4, null), createElement(Component_5, null), createElement(Component_6, null), createElement(Component_7, null), createElement(View_Component, null), createElement(RemoveItemWithoutLots_Component, null), createElement(RemoveItemWithDeletedLot_Component, null), createElement(Component_8, null), createElement(Component_9, null), createElement(Component_10, null), createElement(Component_11, null), createElement(Component_12, null), createElement(Component_13, null), createElement(Component_14, null), createElement(Component_15, null), createElement(Component_16, null), createElement(Component_17, null), createElement(Component_18, null), createElement(Component_19, null), createElement(Component_20, null), claimReportWithPaidClaims]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

