import { CurrencyModule_zero, PercentModule_toDecimal, PercentModule_fromDecimal, CurrencyModule_fromInt, PercentModule_fromInt, CurrencyModule_toDecimal, CurrencyModule_fromDecimal, CountryCodeModule_$007CEU$007CNonEU$007C, CountryCodeModule_empty, EmailModule_toString, EmailModule_ofString, PhoneModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { create, match } from "./fable_modules/fable-library.3.6.1/RegExp.js";
import { Validator$1__Lt, Validator$1__Gte, Validator$1__Gt, validateSync, Validator$1__NotBlank_2B595, Validator$1__IsValid_Z4CF01147, Validator$1__IsMail, Validator$1__Test, Validator$1__End_Z5E18B1E2 } from "./fable_modules/Fable.Validation.0.2.1/Validation.fs.js";
import { t as t_1 } from "./Localization.js";
import { isNullOrWhiteSpace } from "./fable_modules/fable-library.3.6.1/String.js";
import { length, exists } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { isWhiteSpace, isLetterOrDigit, isPunctuation, isSeparator, isSymbol, isLetter, isDigit } from "./fable_modules/fable-library.3.6.1/Char.js";
import { Settings_TimerSettings, Settings_Order_OrderCommissions, Settings_Email_Settings, Settings_User_Settings, Settings_Submission_AgreementTerms, Orders_SetCustomDelivery_DeliveryAddress, Orders_SetCustomDelivery_ContactPerson, Orders_SetCustomDelivery_DeliveryAddress$reflection, Orders_SetCustomDelivery_ContactPerson$reflection, SettingsFreight_DeliveryInfo, SettingsFreight_PickUpInfo, SettingsFreight_DeliveryAddress, SettingsPayout_PayoutSettingsXML, SettingsPayout_DebtorXMLCredentials, SettingsPayout_PayoutSettingsSUS, SettingsPayout_DebtorSUSBankCredentials, Settings_CompanySettings, Agreement_AgreementTerms, User_Manager, User_Admin, User_RegisterManager, User_RegisterAdmin, Agreement_InfoCompany, User_SellerCompany, User_ContactPersonInfo, User_SellerCompanyInfo, User_CompanyInfo, Agreement_InfoPerson, User_SellerPerson, User_PersonalInfo, User_BankInfo, User_Address } from "./Shared/Shared.js";
import { compare } from "./fable_modules/fable-library.3.6.1/Date.js";
import { FSharpMap__get_Item, tryFind, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { head, pairwise, forAll, isEmpty, empty as empty_1 } from "./fable_modules/fable-library.3.6.1/List.js";
import { equals, compare as compare_1, fromParts } from "./fable_modules/fable-library.3.6.1/Decimal.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { union_type, option_type, record_type, list_type, class_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";

export function Common_testerPhone(phone) {
    const phone_1 = PhoneModule_toString(phone);
    if (match(create("(([(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\\s*[)]?[-\\s\\.]?[(]?[0-9]{1,3}[)]?([-\\s\\.]?[0-9]{3})([-\\s\\.]?[0-9]{3,4})"), phone_1) != null) {
        return true;
    }
    else {
        return false;
    }
}

export function Common_email(v) {
    return (arg_3) => {
        let arg10_1;
        return EmailModule_ofString(Validator$1__End_Z5E18B1E2(v, (arg10_1 = Validator$1__Test(v, "Email", EmailModule_toString(arg_3)), Validator$1__IsMail(v, t_1("errors.validation.emailIsInvalid"), arg10_1))));
    };
}

export function Common_emailPersonal(v) {
    return (arg_3) => {
        let arg10_1;
        return EmailModule_ofString(Validator$1__End_Z5E18B1E2(v, (arg10_1 = Validator$1__Test(v, "PersonalEmail", EmailModule_toString(arg_3)), Validator$1__IsMail(v, t_1("errors.validation.emailIsInvalid"), arg10_1))));
    };
}

export function Common_phone(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (phone) => Common_testerPhone(phone))(t_1("errors.validation.phoneIsInvalid"))(Validator$1__Test(v, "Phone", arg_1)));
}

export function Common_firstName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.firstNameIsRequired"))(Validator$1__Test(v, "FirstName", arg_1)));
}

export function Common_lastName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.lastNameIsRequired"))(Validator$1__Test(v, "LastName", arg_1)));
}

export function Common_password(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => {
        if (((!isNullOrWhiteSpace(value)) ? (value.length >= 8) : false) ? exists((arg00) => isDigit(arg00), value.split("")) : false) {
            return exists((arg00_1) => isLetter(arg00_1), value.split(""));
        }
        else {
            return false;
        }
    })(t_1("errors.validation.passwordIsRequired"))(Validator$1__Test(v, "Password", arg_1)));
}

export function Common_passwordConfirmation(password, v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (confirmPassword) => (password === confirmPassword))(t_1("errors.validation.passwordDoesNotMatch"))(Validator$1__Test(v, "PasswordConfirmation", arg_1)));
}

export function Address_address(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.addressIsRequired"))(Validator$1__Test(v, "Address", arg_1)));
}

export function Address_zipCode(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => (value.length <= 10))(t_1("errors.validation.zipCodeIsRequired"))(Validator$1__Test(v, "ZipCode", arg_1)));
}

export function Address_city(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.cityIsRequired"))(Validator$1__Test(v, "City", arg_1)));
}

export function Address_validate(v, x) {
    return new User_Address(Address_address(v)(x.Address), Address_zipCode(v)(x.ZipCode), Address_city(v)(x.City), x.Country);
}

export function Transport_Period_validatePeriod(v) {
    return (arg_2) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (tupledArg_1) => {
        const matchValue_1 = [tupledArg_1[0], tupledArg_1[1]];
        if (matchValue_1[0] == null) {
            if (matchValue_1[1] == null) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (matchValue_1[1] == null) {
            return false;
        }
        else {
            return true;
        }
    })(t_1("errors.validation.datesMustBeEitherFilledOrEmpty"))(Validator$1__IsValid_Z4CF01147(v, (tupledArg) => {
        const matchValue = [tupledArg[0], tupledArg[1]];
        if (matchValue[0] == null) {
            if (matchValue[1] == null) {
                return true;
            }
            else {
                return true;
            }
        }
        else if (matchValue[1] == null) {
            return true;
        }
        else {
            const endDate = matchValue[1];
            const startDate = matchValue[0];
            if (compare(startDate, endDate) <= 0) {
                return true;
            }
            else {
                return false;
            }
        }
    })(t_1("errors.validation.startDateCoparedToEndDate"))(Validator$1__Test(v, "Period", arg_2))));
}

export function Transport_validate(info) {
    return validateSync(true, (t) => {
        const Period = Transport_Period_validatePeriod(t)([info.StartDate, info.EndDate]);
        const ContactPerson = Common_phone(t)(info.ContactPerson.Phone);
        return {
            Address: Address_validate(t, info.Address),
            ContactPerson: ContactPerson,
            Period: Period,
        };
    });
}

export function Transport_validateTransportInfo(transportInfo) {
    const matchValue = Transport_validate(transportInfo);
    if (matchValue.tag === 1) {
        return [transportInfo, matchValue.fields[0]];
    }
    else {
        return [transportInfo, empty()];
    }
}

export function BankInfo_testerBank(value) {
    if ((((exists((arg00) => isDigit(arg00), value) ? (!exists((arg00_1) => isLetter(arg00_1), value)) : false) ? (!exists((arg00_2) => isSymbol(arg00_2), value)) : false) ? (!exists((arg00_3) => isSeparator(arg00_3), value)) : false) ? (!exists((arg00_4) => isPunctuation(arg00_4), value)) : false) {
        return true;
    }
    else {
        return length(value) <= 0;
    }
}

export function BankInfo_testerIban(value) {
    if ((((exists((arg00) => isLetterOrDigit(arg00), value) ? (!exists((arg00_1) => isSymbol(arg00_1), value)) : false) ? (!exists((arg00_2) => isSeparator(arg00_2), value)) : false) ? (!exists((arg00_3) => isWhiteSpace(arg00_3), value)) : false) ? (!exists((arg00_4) => isPunctuation(arg00_4), value)) : false) {
        return true;
    }
    else {
        return length(value) <= 0;
    }
}

export function BankInfo_clearingNumber(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => BankInfo_testerBank(value))(t_1("errors.validation.clearingNumberMustContainOnlyNumber"))(Validator$1__Test(v, "ClearingNumber", arg_1)));
}

export function BankInfo_bankNumber(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => BankInfo_testerBank(value))(t_1("errors.validation.accountNumberMustContainOnlyNumber"))(Validator$1__Test(v, "BankNumber", arg_1)));
}

export function BankInfo_bank(v) {
    return (arg) => Validator$1__End_Z5E18B1E2(v, Validator$1__Test(v, "Bank", arg));
}

export function BankInfo_ibanCountry(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (countryCode) => (countryCode !== CountryCodeModule_empty))(t_1("errors.validation.ibanCountryIsRequired"))(Validator$1__Test(v, "IbanCountry", arg_1)));
}

export function BankInfo_iban(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => BankInfo_testerIban(value))(t_1("errors.validation.ibanMustContainOnlyNumberAndLetters"))(Validator$1__Test(v, "IBAN", arg_1)));
}

export function BankInfo_testIban(iban, country) {
    if (!isNullOrWhiteSpace(iban)) {
        return country !== CountryCodeModule_empty;
    }
    else {
        return true;
    }
}

export function BankInfo_testedIsEmptyIban(iban, v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (country) => BankInfo_testIban(iban, country))(t_1("errors.validation.ibanCountryIsRequired"))(Validator$1__Test(v, "IbanCountry", arg_1)));
}

export function BankInfo_validate(v, x) {
    return new User_BankInfo(BankInfo_clearingNumber(v)(x.ClearingNumber), BankInfo_bankNumber(v)(x.BankNumber), BankInfo_bank(v)(x.Bank), x.Comment, BankInfo_testedIsEmptyIban(x.Iban, v)(x.IbanCountry), BankInfo_iban(v)(x.Iban));
}

export function SellerPerson_PersonalInfo_testPersonalNumber(basicSettings, number) {
    if (basicSettings == null) {
        return true;
    }
    else if (basicSettings.CollectPersonalNumber) {
        return !isNullOrWhiteSpace(number);
    }
    else {
        return true;
    }
}

export function SellerPerson_PersonalInfo_personalNumber(basicSettings, v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (number) => SellerPerson_PersonalInfo_testPersonalNumber(basicSettings, number))(t_1("errors.validation.personalNumberIsRequired"))(Validator$1__Test(v, "PersonalNumber", arg_1)));
}

export function SellerPerson_PersonalInfo_validate(basicSettings, userCountry, v, info) {
    return new User_PersonalInfo(Common_firstName(v)(info.FirstName), Common_lastName(v)(info.LastName), Common_phone(v)(info.Phone), SellerPerson_PersonalInfo_personalNumber(basicSettings, v)(info.PersonalNumber));
}

export function SellerPerson_validate(basicSettings, person) {
    return validateSync(true, (t) => (new User_SellerPerson(person.UserId, Common_email(t)(person.Email), person.NonExistentEmail, person.NeedNotifyAboutOverbid, person.NeedNotifyNewsletter, person.NeedAvoidWarehouseFee, SellerPerson_PersonalInfo_validate(basicSettings, person.Address.Country, t, person.PersonalInfo), Address_validate(t, person.Address), BankInfo_validate(t, person.BankInfo))));
}

export function SellerPerson_validateIf(condition, basicSettings, person) {
    if (condition) {
        const matchValue = SellerPerson_validate(basicSettings, person);
        if (matchValue.tag === 1) {
            return [person, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [person, empty()];
    }
}

export function SellerPerson_validateAgreement(basicSettings, person) {
    return validateSync(true, (t) => (new Agreement_InfoPerson(Common_email(t)(person.Email), SellerPerson_PersonalInfo_validate(basicSettings, person.Address.Country, t, person.PersonalInfo), Address_validate(t, person.Address), BankInfo_validate(t, person.BankInfo), person.PayoutAccount)));
}

export function SellerPerson_validateAgreementIf(basicSettings, condition, person) {
    if (condition) {
        const matchValue = SellerPerson_validateAgreement(basicSettings, person);
        if (matchValue.tag === 1) {
            return [person, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [person, empty()];
    }
}

export function SellerCompany_CompanyInfo_companyName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.companyNameIsRequired"))(Validator$1__Test(v, "CompanyName", arg_1)));
}

export function SellerCompany_CompanyInfo_organizationNumber(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.orgNumberIsRequired"))(Validator$1__Test(v, "OrganizationNumber", arg_1)));
}

export function SellerCompany_CompanyInfo_testVatNumber(residentCountry, userCountry, vatNumber) {
    let pattern_matching_result;
    if (CountryCodeModule_$007CEU$007CNonEU$007C(userCountry).tag === 0) {
        if (userCountry !== residentCountry) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return !isNullOrWhiteSpace(vatNumber);
        }
        case 1: {
            return true;
        }
    }
}

export function SellerCompany_CompanyInfo_vatNumber(residentCountry, userCountry, v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (vatNumber) => SellerCompany_CompanyInfo_testVatNumber(residentCountry, userCountry, vatNumber))(t_1("errors.validation.vatNumberIsRequired"))(Validator$1__Test(v, "VatNumber", arg_1)));
}

export function SellerCompany_CompanyInfo_validate(residentCountry, userCountry, v, info) {
    return new User_CompanyInfo(SellerCompany_CompanyInfo_companyName(v)(info.Name), SellerCompany_CompanyInfo_organizationNumber(v)(info.OrganizationNumber), SellerCompany_CompanyInfo_vatNumber(residentCountry, userCountry, v)(info.VatNumber), info.IsCorporateTaxFree);
}

export function SellerCompany_CompanyInfo_validateWithVAT(v, residentCountry, userCountry, info) {
    return new User_SellerCompanyInfo(SellerCompany_CompanyInfo_companyName(v)(info.Name), SellerCompany_CompanyInfo_organizationNumber(v)(info.OrganizationNumber), info.IsVATDisabled, SellerCompany_CompanyInfo_vatNumber(residentCountry, userCountry, v)(info.VatNumber));
}

export function SellerCompany_ContactPerson_validate(v, info) {
    return new User_ContactPersonInfo(Common_firstName(v)(info.FirstName), Common_lastName(v)(info.LastName), Common_phone(v)(info.Phone), Common_emailPersonal(v)(info.Email));
}

export function SellerCompany_validate(residentCountry, company) {
    return validateSync(true, (v) => {
        const Email = Common_email(v)(company.Email);
        const CompanyInfo = SellerCompany_CompanyInfo_validateWithVAT(v, residentCountry, company.Address.Country, company.CompanyInfo);
        const Address = Address_validate(v, company.Address);
        return new User_SellerCompany(company.UserId, Email, company.NonExistentEmail, company.NeedNotifyAboutOverbid, company.NeedNotifyNewsletter, company.NeedAvoidWarehouseFee, CompanyInfo, BankInfo_validate(v, company.BankInfo), Address, SellerCompany_ContactPerson_validate(v, company.ContactPerson));
    });
}

export function SellerCompany_validateIf(residentCountry, condition, company) {
    if (condition) {
        const matchValue = SellerCompany_validate(residentCountry, company);
        if (matchValue.tag === 1) {
            return [company, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [company, empty()];
    }
}

export function SellerCompany_validateAgreement(residentCountry, person) {
    return validateSync(true, (v) => {
        const Email = Common_email(v)(person.Email);
        const CompanyInfo = SellerCompany_CompanyInfo_validate(residentCountry, person.Address.Country, v, person.CompanyInfo);
        const Address = Address_validate(v, person.Address);
        return new Agreement_InfoCompany(Email, CompanyInfo, BankInfo_validate(v, person.BankInfo), Address, SellerCompany_ContactPerson_validate(v, person.ContactPerson), person.PayoutAccount);
    });
}

export function SellerCompany_validateAgreementIf(residentCountry, condition, company) {
    if (condition) {
        const matchValue = SellerCompany_validateAgreement(residentCountry, company);
        if (matchValue.tag === 1) {
            return [company, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [company, empty()];
    }
}

export function RegisterAdmin_validate(admin) {
    return validateSync(true, (v) => (new User_RegisterAdmin(Common_email(v)(admin.Email), Common_password(v)(admin.Password), Common_passwordConfirmation(admin.Password, v)(admin.PasswordConfirmation), Common_firstName(v)(admin.FirstName), Common_lastName(v)(admin.LastName))));
}

export function RegisterManager_validate(admin) {
    return validateSync(true, (v) => (new User_RegisterManager(Common_email(v)(admin.Email), Common_password(v)(admin.Password), Common_passwordConfirmation(admin.Password, v)(admin.PasswordConfirmation), Common_firstName(v)(admin.FirstName), Common_lastName(v)(admin.LastName))));
}

export function Admin_validate(admin) {
    return validateSync(true, (v) => (new User_Admin(admin.UserId, Common_email(v)(admin.Email), Common_firstName(v)(admin.FirstName), Common_lastName(v)(admin.LastName), admin.NeedAutoPrintLotLabel)));
}

export function Manager_validate(manager) {
    return validateSync(true, (v) => (new User_Manager(manager.UserId, Common_email(v)(manager.Email), Common_firstName(v)(manager.FirstName), Common_lastName(v)(manager.LastName), manager.NeedAutoPrintLotLabel)));
}

export function Agreement_getFieldError(errors, field) {
    return defaultArg(tryFind(field, errors), empty_1());
}

export function Agreement_salesProvision(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gt(v, fromParts(1, 0, 0, true, 0), arg10_1)))(Validator$1__Test(v, "SalesProvision", arg_1)));
    };
}

export function Agreement_salesProvisionSEK(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gt(v, fromParts(1, 0, 0, true, 0), arg10_1)))(Validator$1__Test(v, "SalesProvisionSEK", arg_1)));
    };
}

export function Agreement_photoFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gt(v, fromParts(1, 0, 0, true, 0), arg10_1)))(Validator$1__Test(v, "PhotoFee", arg_1)));
    };
}

export function Agreement_citesFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gt(v, fromParts(1, 0, 0, true, 0), arg10_1)))(Validator$1__Test(v, "CitesFee", arg_1)));
    };
}

export function Agreement_recyclingFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gt(v, fromParts(1, 0, 0, true, 0), arg10_1)))(Validator$1__Test(v, "RecyclingFee", arg_1)));
    };
}

export function Agreement_transportFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gt(v, fromParts(1, 0, 0, true, 0), arg10_1)))(Validator$1__Test(v, "TransportFee", arg_1)));
    };
}

export function Agreement_administrativeFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gt(v, fromParts(1, 0, 0, true, 0), arg10_1)))(Validator$1__Test(v, "AdministrativeFee", arg_1)));
    };
}

export function Agreement_hammerFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, fromParts(1, 0, 0, true, 0), arg10_1)))(Validator$1__Test(v, "HammerFee", arg_1)));
    };
}

export function Agreement_validate(terms) {
    return validateSync(true, (v) => (new Agreement_AgreementTerms(Agreement_salesProvision(v)(terms.SalesProvision), CurrencyModule_fromDecimal(Agreement_salesProvisionSEK(v)(CurrencyModule_toDecimal(terms.SalesProvisionSEK))), CurrencyModule_fromDecimal(Agreement_photoFee(v)(CurrencyModule_toDecimal(terms.PhotoFee))), CurrencyModule_fromDecimal(Agreement_citesFee(v)(CurrencyModule_toDecimal(terms.CitesFee))), CurrencyModule_fromDecimal(Agreement_recyclingFee(v)(CurrencyModule_toDecimal(terms.RecyclingFee))), CurrencyModule_fromDecimal(Agreement_transportFee(v)(CurrencyModule_toDecimal(terms.TransportFee))), CurrencyModule_fromDecimal(Agreement_administrativeFee(v)(CurrencyModule_toDecimal(terms.AdministrativeFee))), CurrencyModule_fromDecimal(Agreement_hammerFee(v)(CurrencyModule_toDecimal(terms.HammerFee))), terms.StartDate, terms.EndDate)));
}

export function GPSMapLink_linkTestExpression(link) {
    return match(create("[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?\u0026//=]*)"), link) != null;
}

export function GPSMapLink_link(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (link) => GPSMapLink_linkTestExpression(link))(t_1("errors.validation.linkIsInvalid"))(Validator$1__Test(v, "GpsMapLink", arg_1)));
}

export function GPSMapLink_linkTest(link) {
    if (link == null) {
        return true;
    }
    else {
        return GPSMapLink_linkTestExpression(link);
    }
}

export function GPSMapLink_linkOption(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (link) => GPSMapLink_linkTest(link))(t_1("errors.validation.linkIsInvalid"))(Validator$1__Test(v, "GpsMapLink", arg_1)));
}

export function CompanySettings_companyName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.companyNameIsRequired"))(Validator$1__Test(v, "CompanyName", arg_1)));
}

export function CompanySettings_address(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.addressIsRequired"))(Validator$1__Test(v, "Address", arg_1)));
}

export function CompanySettings_orgNumber(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.orgNumberIsRequired"))(Validator$1__Test(v, "OrgNumber", arg_1)));
}

export function CompanySettings_facebookLink(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.facebookLinkIsRequired"))(Validator$1__Test(v, "FacebookLink", arg_1)));
}

export function CompanySettings_instagramLink(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.instagramLinkIsRequired"))(Validator$1__Test(v, "InstagramLink", arg_1)));
}

export function CompanySettings_validate(settings) {
    return validateSync(true, (t) => (new Settings_CompanySettings(CompanySettings_companyName(t)(settings.CompanyName), Common_email(t)(settings.Email), Common_phone(t)(settings.Phone), Address_validate(t, settings.Address), GPSMapLink_link(t)(settings.GoogleLinkAddress), CompanySettings_orgNumber(t)(settings.OrgNumber), CompanySettings_facebookLink(t)(settings.FacebookLink), CompanySettings_instagramLink(t)(settings.InstagramLink), settings.LogoImage)));
}

export function CompanySettings_validateIf(condition, settings) {
    if (condition) {
        const matchValue = CompanySettings_validate(settings);
        if (matchValue.tag === 1) {
            return [settings, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [settings, empty()];
    }
}

export function PayoutSettings_SUS_isLessOrEqualThan(value, checkingValue) {
    return checkingValue.length <= value;
}

export function PayoutSettings_SUS_agreementNumber(v) {
    return (arg_2) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (checkingValue) => PayoutSettings_SUS_isLessOrEqualThan(6, checkingValue))(t_1("errors.validation.agreementNumberShouldBeLessOrEqualThanSix"))(Validator$1__NotBlank_2B595(v, t_1("errors.validation.agreementNumberIsRequired"))(Validator$1__Test(v, "AgreementNumber", arg_2))));
}

export function PayoutSettings_SUS_regNumber(v) {
    return (arg_2) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (checkingValue) => PayoutSettings_SUS_isLessOrEqualThan(44, checkingValue))(t_1("errors.validation.registrationNumberShouldBeLessOrEqualThan44"))(Validator$1__NotBlank_2B595(v, t_1("errors.validation.registrationNumberIsRequired"))(Validator$1__Test(v, "RegisterNumber", arg_2))));
}

export function PayoutSettings_SUS_debtorValidation(v, x) {
    return new SettingsPayout_DebtorSUSBankCredentials(PayoutSettings_SUS_agreementNumber(v)(x.AgreementNumber), PayoutSettings_SUS_regNumber(v)(x.RegNumber));
}

export function PayoutSettings_SUS_descriptionValidation(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (checkingValue) => PayoutSettings_SUS_isLessOrEqualThan(768, checkingValue))(t_1("errors.validation.descriptionShouldBeShorter"))(Validator$1__Test(v, "Description", arg_1)));
}

export function PayoutSettings_SUS_validate(settings) {
    return validateSync(true, (t) => (new SettingsPayout_PayoutSettingsSUS(PayoutSettings_SUS_debtorValidation(t, settings.Debtor), PayoutSettings_SUS_descriptionValidation(t)(settings.Description), settings.IsExportEnabled)));
}

export function PayoutSettings_SUS_validateIf(condition, settings) {
    if (condition) {
        const matchValue = PayoutSettings_SUS_validate(settings);
        if (matchValue.tag === 1) {
            return [settings, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [settings, empty()];
    }
}

export function PayoutSettings_XML_isLessOrEqualThan(value, checkingValue) {
    return checkingValue.length <= value;
}

export function PayoutSettings_XML_organisationId(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.organizationIdIsRequired"))(Validator$1__Test(v, "OrganisationId", arg_1)));
}

export function PayoutSettings_XML_debtorName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.debtorNameIsRequired"))(Validator$1__Test(v, "DebtorName", arg_1)));
}

export function PayoutSettings_XML_debtorIban(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.debtorIBANIsRequired"))(Validator$1__Test(v, "DebtorIban", arg_1)));
}

export function PayoutSettings_XML_debtorBIC(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.debtorBICIsRequireed"))(Validator$1__Test(v, "DebtorBIC", arg_1)));
}

export function PayoutSettings_XML_debtorValidation(v, x) {
    return new SettingsPayout_DebtorXMLCredentials(PayoutSettings_XML_organisationId(v)(x.OrganisationId), PayoutSettings_XML_debtorName(v)(x.DebtorName), PayoutSettings_XML_debtorIban(v)(x.DebtorIban), PayoutSettings_XML_debtorBIC(v)(x.DebtorBIC));
}

export function PayoutSettings_XML_descriptionValidation(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (checkingValue) => PayoutSettings_XML_isLessOrEqualThan(70, checkingValue))(t_1("errors.validation.descriptionShouldBeShorter"))(Validator$1__Test(v, "Description", arg_1)));
}

export function PayoutSettings_XML_validate(settings) {
    return validateSync(true, (t) => (new SettingsPayout_PayoutSettingsXML(PayoutSettings_XML_debtorValidation(t, settings.Debtor), PayoutSettings_XML_descriptionValidation(t)(settings.Description), settings.ISOVersion, settings.IsExportEnabled)));
}

export function PayoutSettings_XML_validateIf(condition, settings) {
    if (condition) {
        const matchValue = PayoutSettings_XML_validate(settings);
        if (matchValue.tag === 1) {
            return [settings, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [settings, empty()];
    }
}

export function CommonDeliverySettings_validateName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.companyNameIsRequired"))(Validator$1__Test(v, "CompanyName", arg_1)));
}

export function CommonDeliverySettings_infoTextValidation(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.companyInfoIsRequired"))(Validator$1__Test(v, "CompanyInfoText", arg_1)));
}

export function CommonDeliverySettings_CompanyPickUp_addressValidation(v, x) {
    return new SettingsFreight_DeliveryAddress(Address_address(v)(x.Address), Address_zipCode(v)(x.ZipCode), Address_city(v)(x.City), x.Country);
}

export function CommonDeliverySettings_CompanyPickUp_validate(settings) {
    return validateSync(true, (t) => (new SettingsFreight_PickUpInfo(settings.Content, CommonDeliverySettings_CompanyPickUp_addressValidation(t, settings.Address))));
}

export function CommonDeliverySettings_CompanyPickUp_validateIf(condition, settings) {
    if (condition) {
        const matchValue = CommonDeliverySettings_CompanyPickUp_validate(settings);
        if (matchValue.tag === 1) {
            return [settings, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [settings, empty()];
    }
}

export function CommonDeliverySettings_CompanyDelivery_validate(settings) {
    return validateSync(true, (_arg1) => (new SettingsFreight_DeliveryInfo(settings.Content)));
}

export function CommonDeliverySettings_CompanyDelivery_validateIf(condition, settings) {
    if (condition) {
        const matchValue = CommonDeliverySettings_CompanyDelivery_validate(settings);
        if (matchValue.tag === 1) {
            return [settings, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [settings, empty()];
    }
}

export class SettingsFreight_SettingsFreight extends Record {
    constructor(Name, CostMatrix) {
        super();
        this.Name = Name;
        this.CostMatrix = CostMatrix;
    }
}

export function SettingsFreight_SettingsFreight$reflection() {
    return record_type("Client.Users.Validation.SettingsFreight.SettingsFreight", [], SettingsFreight_SettingsFreight, () => [["Name", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["CostMatrix", list_type(list_type(class_type("System.Decimal")))]]);
}

export function SettingsFreight_validateName(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.zoneNameIsRequired"))(Validator$1__Test(v, "ZoneName", arg_1)));
}

export function SettingsFreight_costMatrixValidation(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (matrix) => (!isEmpty(matrix)))(t_1("errors.validation.freightCostMatrixIsRequired"))(Validator$1__Test(v, "CostMatrix", arg_1)));
}

export function SettingsFreight_validate(settings) {
    return validateSync(true, (t) => (new SettingsFreight_SettingsFreight(settings.Name, SettingsFreight_costMatrixValidation(t)(settings.CostMatrix))));
}

export function SettingsFreight_validateIf(condition, settings) {
    if (condition) {
        const matchValue = SettingsFreight_validate(settings);
        if (matchValue.tag === 1) {
            return [settings, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [settings, empty()];
    }
}

export class CustomDelivery_PickPointForm extends Record {
    constructor(ContactPerson, Cost) {
        super();
        this.ContactPerson = ContactPerson;
        this.Cost = Cost;
    }
}

export function CustomDelivery_PickPointForm$reflection() {
    return record_type("Client.Users.Validation.CustomDelivery.PickPointForm", [], CustomDelivery_PickPointForm, () => [["ContactPerson", Orders_SetCustomDelivery_ContactPerson$reflection()], ["Cost", option_type(class_type("System.Decimal"))]]);
}

export class CustomDelivery_DeliveryForm extends Record {
    constructor(Address, ContactPerson, Cost) {
        super();
        this.Address = Address;
        this.ContactPerson = ContactPerson;
        this.Cost = Cost;
    }
}

export function CustomDelivery_DeliveryForm$reflection() {
    return record_type("Client.Users.Validation.CustomDelivery.DeliveryForm", [], CustomDelivery_DeliveryForm, () => [["Address", Orders_SetCustomDelivery_DeliveryAddress$reflection()], ["ContactPerson", Orders_SetCustomDelivery_ContactPerson$reflection()], ["Cost", option_type(class_type("System.Decimal"))]]);
}

export class CustomDelivery_Form extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PickPoint", "Delivery"];
    }
}

export function CustomDelivery_Form$reflection() {
    return union_type("Client.Users.Validation.CustomDelivery.Form", [], CustomDelivery_Form, () => [[["Item", CustomDelivery_PickPointForm$reflection()]], [["Item", CustomDelivery_DeliveryForm$reflection()]]]);
}

export function CustomDelivery_name(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, t_1("errors.validation.nameIsRequired"))(Validator$1__Test(v, "Name", arg_1)));
}

export function CustomDelivery_cost(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (cost) => (cost != null))(t_1("errors.validation.costIsRequired"))(Validator$1__Test(v, "Cost", arg_1)));
}

export function CustomDelivery_contactPerson(v, info) {
    const Name = CustomDelivery_name(v)(info.Name);
    const Phone = Common_phone(v)(info.Phone);
    return new Orders_SetCustomDelivery_ContactPerson(Common_email(v)(info.Email), Name, Phone);
}

export function CustomDelivery_address(v, info) {
    return new Orders_SetCustomDelivery_DeliveryAddress(Address_address(v)(info.Address), Address_zipCode(v)(info.ZipCode), Address_city(v)(info.City), info.Country);
}

export function CustomDelivery_validatePickPointForm(settings) {
    return validateSync(true, (t) => (new CustomDelivery_PickPointForm(CustomDelivery_contactPerson(t, settings.ContactPerson), CustomDelivery_cost(t)(settings.Cost))));
}

export function CustomDelivery_validateDeliveryForm(settings) {
    return validateSync(true, (t) => (new CustomDelivery_DeliveryForm(CustomDelivery_address(t, settings.Address), CustomDelivery_contactPerson(t, settings.ContactPerson), CustomDelivery_cost(t)(settings.Cost))));
}

export function CustomDelivery_validateIf(condition, settings) {
    if (condition) {
        if (settings.tag === 1) {
            const settings_2 = settings.fields[0];
            const matchValue_1 = CustomDelivery_validateDeliveryForm(settings_2);
            if (matchValue_1.tag === 1) {
                return [new CustomDelivery_Form(1, settings_2), matchValue_1.fields[0]];
            }
            else {
                return [new CustomDelivery_Form(1, matchValue_1.fields[0]), empty()];
            }
        }
        else {
            const settings_1 = settings.fields[0];
            const matchValue = CustomDelivery_validatePickPointForm(settings_1);
            if (matchValue.tag === 1) {
                return [new CustomDelivery_Form(0, settings_1), matchValue.fields[0]];
            }
            else {
                return [new CustomDelivery_Form(0, matchValue.fields[0]), empty()];
            }
        }
    }
    else {
        return [settings, empty()];
    }
}

export function Settings_Submission_AgreementTerms_getFieldError(errors, field) {
    return defaultArg(tryFind(field, errors), empty_1());
}

export function Settings_Submission_AgreementTerms_salesProvision(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, PercentModule_fromInt(0), arg10_1)))(Validator$1__Test(v, "SalesProvision", arg_1)));
    };
}

export function Settings_Submission_AgreementTerms_salesProvisionSEK(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, CurrencyModule_fromInt(0), arg10_1)))(Validator$1__Test(v, "SalesProvisionSEK", arg_1)));
    };
}

export function Settings_Submission_AgreementTerms_photoFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, CurrencyModule_fromInt(0), arg10_1)))(Validator$1__Test(v, "PhotoFee", arg_1)));
    };
}

export function Settings_Submission_AgreementTerms_citesFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, CurrencyModule_fromInt(0), arg10_1)))(Validator$1__Test(v, "CitesFee", arg_1)));
    };
}

export function Settings_Submission_AgreementTerms_recyclingFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, CurrencyModule_fromInt(0), arg10_1)))(Validator$1__Test(v, "RecyclingFee", arg_1)));
    };
}

export function Settings_Submission_AgreementTerms_transportFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, CurrencyModule_fromInt(0), arg10_1)))(Validator$1__Test(v, "TransportFee", arg_1)));
    };
}

export function Settings_Submission_AgreementTerms_administrativeFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, CurrencyModule_fromInt(0), arg10_1)))(Validator$1__Test(v, "AdministrativeFee", arg_1)));
    };
}

export function Settings_Submission_AgreementTerms_hammerFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, CurrencyModule_fromInt(0), arg10_1)))(Validator$1__Test(v, "HammerFee", arg_1)));
    };
}

export function Settings_Submission_AgreementTerms_validate(terms) {
    return validateSync(true, (v) => (new Settings_Submission_AgreementTerms(Settings_Submission_AgreementTerms_salesProvision(v)(terms.SalesProvision), Settings_Submission_AgreementTerms_salesProvisionSEK(v)(terms.SalesProvisionSEK), Settings_Submission_AgreementTerms_photoFee(v)(terms.PhotoFee), Settings_Submission_AgreementTerms_citesFee(v)(terms.CitesFee), Settings_Submission_AgreementTerms_recyclingFee(v)(terms.RecyclingFee), Settings_Submission_AgreementTerms_transportFee(v)(terms.TransportFee), Settings_Submission_AgreementTerms_administrativeFee(v)(terms.AdministrativeFee), Settings_Submission_AgreementTerms_hammerFee(v)(terms.HammerFee), terms.PayoutAccount, terms.IsCorporateTaxFree)));
}

export function Settings_User_validate(settings) {
    return validateSync(true, (_arg1) => (new Settings_User_Settings(settings.VerificationNeededFor)));
}

const Settings_Email_maxNumber = 2147483647;

export function Settings_Email_reportDelay(v, name) {
    return (arg_2) => Validator$1__End_Z5E18B1E2(v, Validator$1__Lt(v, Settings_Email_maxNumber, t_1("errors.validation.numberTooBig"))(Validator$1__Gte(v, 0, t_1("errors.validation.numberMustBeGreaterOrEqualToZero"))(Validator$1__Test(v, name, arg_2))));
}

export function Settings_Email_validate(settings) {
    return validateSync(true, (v) => (new Settings_Email_Settings(settings.IsPresaleReportEnabled, Settings_Email_reportDelay(v, "PresaleReportDelay")(settings.PresaleReportDelayInHours), settings.IsPreliminaryOrderResultReportEnabled, Settings_Email_reportDelay(v, "PreliminaryOrderResultReportDelay")(settings.PreliminaryOrderResultReportDelayInHours), settings.IsFinalOrderResultReportEnabled, settings.IsAuctionStartSoonNotificationEnabled, Settings_Email_reportDelay(v, "AuctionStartSoonNotificationDelay")(settings.AuctionStartSoonNotificationDelayInMinutes), settings.IsUserAccountVerifiedNotificationEnabled, settings.IsUnpaidOrderNotificationEnabled, Settings_Email_reportDelay(v, "UnpaidOrderNotificationDelay")(settings.UnpaidOrderNotificationDelayInDays), settings.IsNewUserRegisteredNotificationEnabled, settings.IsAuctionWinnerEmailNotificationEnabled)));
}

export function Settings_OrderCommissions_getFieldError(errors, field) {
    return defaultArg(tryFind(field, errors), empty_1());
}

export function Settings_OrderCommissions_buyerCommission(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, fromParts(0, 0, 0, false, 0), arg10_1)))(Validator$1__Test(v, "BuyerCommission", arg_1)));
    };
}

export function Settings_OrderCommissions_hammerFee(v) {
    return (arg_1) => {
        let arg10_1;
        return Validator$1__End_Z5E18B1E2(v, ((arg10_1 = t_1("errors.validation.numberMustBeMoreThanZero"), Validator$1__Gte(v, fromParts(1, 0, 0, true, 0), arg10_1)))(Validator$1__Test(v, "HammerFee", arg_1)));
    };
}

export function Settings_OrderCommissions_validate(terms) {
    return validateSync(true, (v) => (new Settings_Order_OrderCommissions(PercentModule_fromDecimal(Settings_OrderCommissions_buyerCommission(v)(PercentModule_toDecimal(terms.BuyerCommission))), CurrencyModule_fromDecimal(Settings_OrderCommissions_hammerFee(v)(CurrencyModule_toDecimal(terms.HammerFee))))));
}

export function TimerSettings_defaultTimer(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__Gt(v, 4, t_1("errors.validation.numberMustBeMoreThanFive"))(Validator$1__Test(v, "DefaultTimer", arg_1)));
}

export function TimerSettings_afterBidTimer(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__Gt(v, 4, t_1("errors.validation.numberMustBeMoreThanFive"))(Validator$1__Test(v, "AfterBidTimer", arg_1)));
}

export function TimerSettings_validate(settings) {
    return validateSync(true, (t) => (new Settings_TimerSettings(TimerSettings_defaultTimer(t)(settings.DefaultTimer), TimerSettings_afterBidTimer(t)(settings.AfterBidTimer))));
}

export function TimerSettings_validateIf(condition, settings) {
    if (condition) {
        const matchValue = TimerSettings_validate(settings);
        if (matchValue.tag === 1) {
            return [settings, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [settings, empty()];
    }
}

export function YouTubeLink_youtubeLinkTest(link) {
    return match(create("http(?:s?):\\/\\/(?:www\\.)?youtu(?:be\\.com\\?v=|\\.be\\/)([\\w\\-\\_]*)(\u0026(amp;)?[\\w\\?=]*)?"), link) != null;
}

export function YouTubeLink_youtubeLinkConfirmation(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (link) => YouTubeLink_youtubeLinkTest(link))(t_1("errors.validation.linkIsInvalid"))(Validator$1__Test(v, "YouTubeLink", arg_1)));
}

export function YouTubeLink_youTubeOptionLinkTest(link) {
    if (link == null) {
        return true;
    }
    else {
        return YouTubeLink_youtubeLinkTest(link);
    }
}

export function YouTubeLink_youtubeLinkOption(v) {
    return (arg_1) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (link) => YouTubeLink_youTubeOptionLinkTest(link))(t_1("errors.validation.linkIsInvalid"))(Validator$1__Test(v, "YouTubeLink", arg_1)));
}

export function YouTubeLink_validate(youTubeLink) {
    return validateSync(true, (t) => YouTubeLink_youtubeLinkConfirmation(t)(youTubeLink));
}

export function YouTubeLink_validateIf(condition, youTubeLink) {
    if (youTubeLink == null) {
        return [youTubeLink, empty_1()];
    }
    else {
        const link = youTubeLink;
        if (condition) {
            const matchValue = YouTubeLink_validate(link);
            if (matchValue.tag === 1) {
                return [link, FSharpMap__get_Item(matchValue.fields[0], "YouTubeLink")];
            }
            else {
                return [matchValue.fields[0], empty_1()];
            }
        }
        else {
            return [link, empty_1()];
        }
    }
}

export class Lot_Create_CreateLotValidation extends Record {
    constructor(GPSLink, YouTubeLink, PurchaseCommission) {
        super();
        this.GPSLink = GPSLink;
        this.YouTubeLink = YouTubeLink;
        this.PurchaseCommission = PurchaseCommission;
    }
}

export function Lot_Create_CreateLotValidation$reflection() {
    return record_type("Client.Users.Validation.Lot.Create.CreateLotValidation", [], Lot_Create_CreateLotValidation, () => [["GPSLink", option_type(string_type)], ["YouTubeLink", option_type(string_type)], ["PurchaseCommission", class_type("System.Decimal")]]);
}

export function Lot_Create_validate(form) {
    return validateSync(true, (v) => (new Lot_Create_CreateLotValidation(GPSMapLink_linkOption(v)(form.GPSLink), YouTubeLink_youtubeLinkOption(v)(form.YouTubeLink), PercentModule_fromDecimal(Settings_OrderCommissions_buyerCommission(v)(PercentModule_toDecimal(form.PurchaseCommission))))));
}

export function Lot_Create_validateIf(condition, terms) {
    if (condition) {
        const matchValue = Lot_Create_validate(terms);
        if (matchValue.tag === 1) {
            return [terms, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [terms, empty()];
    }
}

export class Lot_Edit_EditLotValidation extends Record {
    constructor(GPSLink, PurchaseCommission, HammerFee) {
        super();
        this.GPSLink = GPSLink;
        this.PurchaseCommission = PurchaseCommission;
        this.HammerFee = HammerFee;
    }
}

export function Lot_Edit_EditLotValidation$reflection() {
    return record_type("Client.Users.Validation.Lot.Edit.EditLotValidation", [], Lot_Edit_EditLotValidation, () => [["GPSLink", option_type(string_type)], ["PurchaseCommission", class_type("System.Decimal")], ["HammerFee", class_type("System.Decimal")]]);
}

export function Lot_Edit_validate(form) {
    return validateSync(true, (v) => (new Lot_Edit_EditLotValidation(GPSMapLink_linkOption(v)(form.GPSLink), PercentModule_fromDecimal(Settings_OrderCommissions_buyerCommission(v)(PercentModule_toDecimal(form.PurchaseCommission))), CurrencyModule_fromDecimal(Settings_OrderCommissions_hammerFee(v)(CurrencyModule_toDecimal(form.HammerFee))))));
}

export function Lot_Edit_validateIf(condition, terms) {
    if (condition) {
        const matchValue = Lot_Edit_validate(terms);
        if (matchValue.tag === 1) {
            return [terms, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [terms, empty()];
    }
}

export function BiddingIntervals_validateBiddingInterval(v) {
    return (arg_4) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (intervals_3) => forAll((tupledArg_1) => {
        const x_2 = tupledArg_1[0];
        const y_1 = tupledArg_1[1];
        if (x_2.From < x_2.To) {
            return y_1.From < y_1.To;
        }
        else {
            return false;
        }
    }, pairwise(intervals_3)))(t_1("errors.validation.allElementsGrowing"))(Validator$1__IsValid_Z4CF01147(v, (intervals_1) => forAll((tupledArg) => (tupledArg[0].To === tupledArg[1].From), pairwise(intervals_1)))(t_1("errors.validation.biddingIntervalsShouldBeNotEqual"))(Validator$1__IsValid_Z4CF01147(v, (intervals_2) => forAll((x_1) => (((x_1.From - x_1.To) % x_1.BidSize) === 0), intervals_2))(t_1("errors.validation.bidSizeMultiple"))(Validator$1__IsValid_Z4CF01147(v, (intervals) => (!isEmpty(intervals)))(t_1("errors.validation.biddingIntervalsRequired"))(Validator$1__Test(v, "Intervals", arg_4))))));
}

export function BiddingIntervals_validate(settings) {
    return validateSync(true, (t) => BiddingIntervals_validateBiddingInterval(t)(settings));
}

export function BiddingIntervals_validateIf(settings) {
    const matchValue = BiddingIntervals_validate(settings);
    if (matchValue.tag === 1) {
        return [settings, matchValue.fields[0]];
    }
    else {
        return [matchValue.fields[0], empty()];
    }
}

export function RoyaltyIntervals_validateInterval(v) {
    return (arg_3) => Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (intervals_2) => forAll((tupledArg) => (compare_1(tupledArg[0].StartPoint, tupledArg[1].StartPoint) < 0), pairwise(intervals_2)))(t_1("errors.validation.allElementsGrowing"))(Validator$1__IsValid_Z4CF01147(v, (intervals_1) => equals(head(intervals_1).StartPoint, CurrencyModule_zero))(t_1("errors.validation.isStartWithZero"))(Validator$1__IsValid_Z4CF01147(v, (intervals) => (!isEmpty(intervals)))(t_1("errors.validation.biddingIntervalsRequired"))(Validator$1__Test(v, "Intervals", arg_3)))));
}

export function RoyaltyIntervals_validate(settings) {
    return validateSync(true, (t) => RoyaltyIntervals_validateInterval(t)(settings));
}

export function RoyaltyIntervals_validateIf(settings) {
    const matchValue = RoyaltyIntervals_validate(settings);
    if (matchValue.tag === 1) {
        return [settings, matchValue.fields[0]];
    }
    else {
        return [matchValue.fields[0], empty()];
    }
}

