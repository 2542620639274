import { toString, Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { SettingsPayment_PaymentProvider, SettingsPayment_Settings, SettingsPayment_PaymentProvider$reflection, SettingsPayment_Settings$reflection } from "./Shared/Shared.js";
import { record_type, union_type, unit_type, bool_type, class_type, list_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_handleNonAuth, Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi } from "./Communication.js";
import { FSharpMap__get_IsEmpty, empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "./Localization.js";
import { createElement } from "react";
import { singleton as singleton_1, cons, map, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { isCurrentUserAdmin } from "./LocalStorage.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";

export class Types_State extends Record {
    constructor(Settings, OriginalSettings, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.Settings = Settings;
        this.OriginalSettings = OriginalSettings;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function Types_State$reflection() {
    return record_type("Client.SettingsPayment.Types.State", [], Types_State, () => [["Settings", SettingsPayment_Settings$reflection()], ["OriginalSettings", SettingsPayment_Settings$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PaymentProviderChanged", "CancelEdit", "SaveForm"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Client.SettingsPayment.Types.Msg", [], Types_Msg, () => [[["Item", SettingsPayment_PaymentProvider$reflection()]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_saveForm(settings) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(settingsApi().updatePaymentsSettings(settings), (_arg1) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(settings) {
    return [new Types_State(settings, settings, empty(), true, new Deferred$1(0)), Cmd_none()];
}

export function State_withFormAndErrors(form, errors, state) {
    return new Types_State(form, state.OriginalSettings, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        return [new Types_State(state.OriginalSettings, state.OriginalSettings, empty(), false, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            return [new Types_State(state.Settings, state.Settings, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.Settings, state.OriginalSettings)) {
            return [new Types_State(state.Settings, state.OriginalSettings, state.FormErrors, state.NeedToValidate, new Deferred$1(1)), Cmd_fromAsync(Cmd_saveForm(state.Settings))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new Types_State(new SettingsPayment_Settings(msg.fields[0]), state.OriginalSettings, state.FormErrors, state.NeedToValidate, state.SaveFormOperation), Cmd_none()];
    }
}

export function View_render(state, dispatch) {
    let props_1, s_1, props_26, props_24, props_22, props_14, elms_1, props_5, props_3, elms, patternInput, props_20, props_16, props_18;
    const isFormSaving = equals(state.SaveFormOperation, new Deferred$1(1));
    const needDisableSaving = (isFormSaving ? true : (!(!equals(state.Settings, state.OriginalSettings)))) ? true : (!FSharpMap__get_IsEmpty(state.FormErrors));
    const elms_2 = ofArray([(props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s_1 = t("settings.payments"), createElement("h1", {
        className: "title",
        children: s_1,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1)))), createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_26 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "has-text-grey-dark"], ["className", "is-half"], ["children", Interop_reactApi.Children.toArray([(props_14 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_5 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("settings.paymentProvider")]]), createElement("label", createObj(Helpers_combineClasses("label", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_5)))), (elms = singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["defaultValue", toString(state.Settings.PaymentProvider)], ["onChange", (ev) => {
            let s;
            dispatch(new Types_Msg(0, (s = ev.target.value, (s === "Svea") ? (new SettingsPayment_PaymentProvider(0)) : ((s === "Fortnox") ? (new SettingsPayment_PaymentProvider(1)) : (() => {
                throw (new Error("Can\u0027t find payment provider"));
            })()))));
        }], ["children", Interop_reactApi.Children.toArray(Array.from(map((x) => createElement("option", {
            value: toString(x),
            children: toString(x),
        }), ofArray([new SettingsPayment_PaymentProvider(0), new SettingsPayment_PaymentProvider(1)]))))]])), createElement("div", {
            className: join(" ", cons("select", patternInput[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_14)))), (props_20 = ofArray([["className", "mt-6"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_16 = toList(delay(() => append(singleton_2(["className", "is-dark"]), delay(() => append(isFormSaving ? singleton_2(["disabled", true]) : empty_1(), delay(() => append(singleton_2(["children", t("common.cancel")]), delay(() => singleton_2(["onClick", (x_1) => {
            x_1.preventDefault();
            dispatch(new Types_Msg(1));
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_16)))), (props_18 = toList(delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(needDisableSaving ? singleton_2(["disabled", true]) : empty_1(), delay(() => append(isFormSaving ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_2(["children", t("common.save")]), delay(() => singleton_2(["onClick", (x_2) => {
            x_2.preventDefault();
            dispatch(new Types_Msg(2, new AsyncOperationStatus$1(0)));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_24))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_26))))]),
    })]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function View_RenderSettings(settings) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(settings), (msg, state) => State_update(msg, state), []);
    return View_render(patternInput[0], patternInput[1]);
}

export function View_Render() {
    if (isCurrentUserAdmin()) {
        const getSettingsDeferred = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getPaymentsSettings()), []);
        switch (getSettingsDeferred.tag) {
            case 1: {
                return loader("big");
            }
            case 3: {
                return getSettingsDeferred.fields[0].message;
            }
            case 2: {
                return createElement(View_RenderSettings, getSettingsDeferred.fields[0]);
            }
            default: {
                return loader("big");
            }
        }
    }
    else {
        return null;
    }
}

