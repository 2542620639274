import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { record_type, bool_type, union_type, unit_type, option_type, list_type, string_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Submissions_UploadImageInfo, Submissions_Submission, Image$reflection, WarehouseCategory$reflection, Submissions_Submission$reflection } from "../Shared/Shared.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { submissionsApi } from "../Communication.js";
import { value as value_185 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { ImageIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { map, singleton as singleton_2, ofArray, contains, empty, cons, tail, head, isEmpty } from "../fable_modules/fable-library.3.6.1/List.js";
import { List_distinctBy } from "../fable_modules/fable-library.3.6.1/Seq2.js";
import { createObj, numberHash, safeHash, equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { Browser_Types_File__File_ReadAsByteArray } from "../fable_modules/Fable.Remoting.Client.7.10.0/Extensions.fs.js";
import { createElement } from "react";
import * as react from "react";
import { map as map_1, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { dividedTopLight } from "./BorderDividedLine.js";
import { Localized_translate, t as t_3 } from "../Localization.js";
import { SizeSubmission_list } from "../Common.js";
import { Image_lotMedium800ImagePath } from "../Css.js";

export class State extends Record {
    constructor(SubmissionId, Submission, OriginalSubmission, ImageId, TemplateCategories, Error$, EditInformation, IsEditMode) {
        super();
        this.SubmissionId = SubmissionId;
        this.Submission = Submission;
        this.OriginalSubmission = OriginalSubmission;
        this.ImageId = ImageId;
        this.TemplateCategories = TemplateCategories;
        this.Error = Error$;
        this.EditInformation = EditInformation;
        this.IsEditMode = IsEditMode;
    }
}

export function State$reflection() {
    return record_type("Components.Submission.State", [], State, () => [["SubmissionId", class_type("System.Int64")], ["Submission", Submissions_Submission$reflection()], ["OriginalSubmission", Submissions_Submission$reflection()], ["ImageId", string_type], ["TemplateCategories", list_type(WarehouseCategory$reflection())], ["Error", option_type(string_type)], ["EditInformation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["IsEditMode", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["WarehouseChange", "SizeChange", "TagsChange", "DescriptionChange", "EditSubmissionInfo", "ImageUpdated", "ImageDelete", "SwitchEditMode", "CancelEditMode"];
    }
}

export function Msg$reflection() {
    return union_type("Components.Submission.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", WarehouseCategory$reflection()]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", Image$reflection()]], [], [], []]);
}

export function editSubmissionInfo(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(submissionsApi().editSubmissionInfo([state.SubmissionId, state.ImageId, state.Submission])), (_arg1) => singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(id, submission, categories) {
    return [new State(id, submission, submission, (submission.OverviewPhoto != null) ? value_185(submission.OverviewPhoto).Id : ImageIdModule_empty, categories, void 0, new Deferred$1(0), false), Cmd_none()];
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5;
    if (msg.tag === 8) {
        return [new State(state.SubmissionId, state.OriginalSubmission, state.OriginalSubmission, state.ImageId, state.TemplateCategories, state.Error, state.EditInformation, false), Cmd_none()];
    }
    else if (msg.tag === 0) {
        return [new State(state.SubmissionId, (inputRecord = state.Submission, new Submissions_Submission(inputRecord.AgreementId, inputRecord.Seller, inputRecord.SellerId, inputRecord.SubmittedDate, msg.fields[0], inputRecord.Description, inputRecord.Size, inputRecord.Receiver, inputRecord.OverviewPhoto, inputRecord.Tags, inputRecord.IsDeleted)), state.OriginalSubmission, state.ImageId, state.TemplateCategories, state.Error, state.EditInformation, state.IsEditMode), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [new State(state.SubmissionId, (inputRecord_1 = state.Submission, new Submissions_Submission(inputRecord_1.AgreementId, inputRecord_1.Seller, inputRecord_1.SellerId, inputRecord_1.SubmittedDate, inputRecord_1.Warehouse, inputRecord_1.Description, msg.fields[0], inputRecord_1.Receiver, inputRecord_1.OverviewPhoto, inputRecord_1.Tags, inputRecord_1.IsDeleted)), state.OriginalSubmission, state.ImageId, state.TemplateCategories, state.Error, state.EditInformation, state.IsEditMode), Cmd_none()];
    }
    else if (msg.tag === 2) {
        const remove_first = (pred, lst) => {
            let pattern_matching_result, h_1, t_1;
            if (!isEmpty(lst)) {
                if (pred(head(lst))) {
                    pattern_matching_result = 0;
                    h_1 = head(lst);
                    t_1 = tail(lst);
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return t_1;
                }
                case 1: {
                    if (!isEmpty(lst)) {
                        return cons(head(lst), remove_first(pred, tail(lst)));
                    }
                    else {
                        return empty();
                    }
                }
            }
        };
        return [new State(state.SubmissionId, (inputRecord_2 = state.Submission, new Submissions_Submission(inputRecord_2.AgreementId, inputRecord_2.Seller, inputRecord_2.SellerId, inputRecord_2.SubmittedDate, inputRecord_2.Warehouse, inputRecord_2.Description, inputRecord_2.Size, inputRecord_2.Receiver, inputRecord_2.OverviewPhoto, List_distinctBy((x_4) => x_4.Id, contains(msg.fields[0], state.Submission.Tags, {
            Equals: (x_3, y) => equals(x_3, y),
            GetHashCode: (x_3) => safeHash(x_3),
        }) ? remove_first((y_1) => equals(y_1, msg.fields[0]), state.Submission.Tags) : cons(msg.fields[0], state.Submission.Tags), {
            Equals: (x_5, y_2) => (x_5 === y_2),
            GetHashCode: (x_5) => numberHash(x_5),
        }), inputRecord_2.IsDeleted)), state.OriginalSubmission, state.ImageId, state.TemplateCategories, state.Error, state.EditInformation, state.IsEditMode), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new State(state.SubmissionId, (inputRecord_3 = state.Submission, new Submissions_Submission(inputRecord_3.AgreementId, inputRecord_3.Seller, inputRecord_3.SellerId, inputRecord_3.SubmittedDate, inputRecord_3.Warehouse, msg.fields[0], inputRecord_3.Size, inputRecord_3.Receiver, inputRecord_3.OverviewPhoto, inputRecord_3.Tags, inputRecord_3.IsDeleted)), state.OriginalSubmission, state.ImageId, state.TemplateCategories, state.Error, state.EditInformation, state.IsEditMode), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new State(state.SubmissionId, (inputRecord_4 = state.Submission, new Submissions_Submission(inputRecord_4.AgreementId, inputRecord_4.Seller, inputRecord_4.SellerId, inputRecord_4.SubmittedDate, inputRecord_4.Warehouse, inputRecord_4.Description, inputRecord_4.Size, inputRecord_4.Receiver, msg.fields[0], inputRecord_4.Tags, inputRecord_4.IsDeleted)), state.OriginalSubmission, state.ImageId, state.TemplateCategories, state.Error, state.EditInformation, state.IsEditMode), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new State(state.SubmissionId, (inputRecord_5 = state.Submission, new Submissions_Submission(inputRecord_5.AgreementId, inputRecord_5.Seller, inputRecord_5.SellerId, inputRecord_5.SubmittedDate, inputRecord_5.Warehouse, inputRecord_5.Description, inputRecord_5.Size, inputRecord_5.Receiver, void 0, inputRecord_5.Tags, inputRecord_5.IsDeleted)), state.OriginalSubmission, state.ImageId, state.TemplateCategories, state.Error, state.EditInformation, state.IsEditMode), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.SubmissionId, state.Submission, state.OriginalSubmission, state.ImageId, state.TemplateCategories, msg.fields[0].fields[0].fields[0], state.EditInformation, state.IsEditMode), Cmd_none()];
            }
            else {
                return [new State(state.SubmissionId, state.Submission, state.Submission, state.ImageId, state.TemplateCategories, void 0, new Deferred$1(0), false), Cmd_none()];
            }
        }
        else {
            return [new State(state.SubmissionId, state.Submission, state.OriginalSubmission, state.ImageId, state.TemplateCategories, state.Error, new Deferred$1(1), state.IsEditMode), Cmd_fromAsync(editSubmissionInfo(state))];
        }
    }
    else {
        return [new State(state.SubmissionId, state.Submission, state.OriginalSubmission, state.ImageId, state.TemplateCategories, state.Error, state.EditInformation, true), Cmd_none()];
    }
}

export function UploadImage(uploadImageInputProps) {
    const onUploaded = uploadImageInputProps.onUploaded;
    const isMode = uploadImageInputProps.isMode;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setError = patternInput[1];
    const error = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setInProgress = patternInput_1[1];
    const inProgress = patternInput_1[0];
    const uploadFileCallback = useReact_useCallback_93353E((arg) => {
        let file;
        startImmediate((file = arg, singleton.Delay(() => {
            setError("");
            setInProgress(true);
            return singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file), (_arg1) => {
                const args = new Submissions_UploadImageInfo(_arg1, file.name);
                return singleton.Combine(singleton.Bind(Remoting_handleNonAuth(submissionsApi().uploadImage(args)), (_arg2) => {
                    if (_arg2.tag === 1) {
                        setError(`${_arg2.fields[0]}`);
                        return singleton.Zero();
                    }
                    else {
                        onUploaded(_arg2.fields[0]);
                        return singleton.Zero();
                    }
                }), singleton.Delay(() => {
                    setInProgress(false);
                    return singleton.Zero();
                }));
            });
        })));
    });
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props_9, props_7;
        return append(singleton_1((props_9 = ofArray([["className", "is-right"], ["disabled", inProgress], ["children", Interop_reactApi.Children.toArray([(props_7 = toList(delay(() => append(singleton_1(["className", "mr-0"]), delay(() => append(singleton_1(["className", "mt-1"]), delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append((!isMode) ? singleton_1(["className", "is-static"]) : empty_1(), delay(() => append(singleton_1(["style", {
            cursor: "pointer",
        }]), delay(() => {
            let props_3, value_24;
            return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["disabled", inProgress], ["onChange", (ev) => {
                const files = ev.target.files;
                if ((!(files == null)) ? (files.length > 0) : false) {
                    uploadFileCallback(files.item(0));
                }
            }]]))))), createElement("span", {
                className: join(" ", ["icon-text"]),
                style: {
                    alignItems: "center",
                },
                children: Interop_reactApi.Children.toArray([(props_3 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (inProgress ? singleton_1(["className", join(" ", ["fas", "fa-spinner", "fa-spin"])]) : singleton_1(["className", join(" ", ["fa", "fa-plus"])]))))))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_3)))), (value_24 = (inProgress ? "" : "Lägg till bild"), createElement("span", {
                    children: [value_24],
                }))]),
            })])]);
        })))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_9))))), delay(() => ((error !== "") ? singleton_1(createElement("span", {
            children: [error],
        })) : empty_1())));
    })));
}

export function RenderContent(renderContentInputProps) {
    let props_75, props, props_59, props_46, props_57, props_50, props_48, props_55, props_73, props_71;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderContentInputProps.id, renderContentInputProps.submission, renderContentInputProps.categories), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_78 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
        disabled: equals(state_1.EditInformation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([(props_75 = ofArray([dividedTopLight, ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-weight-bold"], ["className", "mb-3"], ["children", t_3("users.submissionInfo")]]), createElement("p", createObj(Helpers_combineClasses("", props)))), (props_59 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_46 = ofArray([["className", "is-8"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_10, elms_1, props_4, props_2, elms, props_6, value_27;
            return append(singleton_1((props_10 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_4 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_3("settings.description")]]), createElement("label", createObj(Helpers_combineClasses("label", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_4)))), (elms = singleton_2((props_6 = ofArray([["rows", 20], ["disabled", !state_1.IsEditMode], (value_27 = state_1.Submission.Description, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_27)) : false) {
                    e.value = value_27;
                }
            }]), ["onChange", (ev) => {
                dispatch(new Msg(3, ev.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_6))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_10))))), delay(() => {
                let props_23, elms_3, props_14, props_12, elms_2, patternInput_1;
                return append(singleton_1((props_23 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_14 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_3("common.warehouse")]]), createElement("label", createObj(Helpers_combineClasses("label", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_14)))), (elms_2 = singleton_2((patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["disabled", !state_1.IsEditMode], ["onChange", (ev_1) => {
                    dispatch(new Msg(0, ev_1.target.value));
                }], ["value", state_1.Submission.Warehouse], ["children", Interop_reactApi.Children.toArray(Array.from(map((x_1) => createElement("option", {
                    children: x_1,
                }), ofArray(["Lager A", "Lager B", "Lager C", "Lager D", "Lager E", "Lager F", "Tibble"]))))]])), createElement("div", {
                    className: join(" ", cons("select", patternInput_1[0])),
                    children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
                }))), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_23))))), delay(() => {
                    let props_36, elms_5, props_27, props_25, elms_4, patternInput_2;
                    return append(singleton_1((props_36 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_5 = ofArray([(props_27 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_25 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t_3("common.size")]]), createElement("label", createObj(Helpers_combineClasses("label", props_25))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_27)))), (elms_4 = singleton_2((patternInput_2 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "mb-1"], ["className", "has-text-grey"], ["disabled", !state_1.IsEditMode], ["onChange", (ev_2) => {
                        dispatch(new Msg(1, ev_2.target.value));
                    }], ["value", state_1.Submission.Size], ["children", Interop_reactApi.Children.toArray(Array.from(map((x_3) => createElement("option", {
                        children: x_3,
                    }), SizeSubmission_list)))]])), createElement("div", {
                        className: join(" ", cons("select", patternInput_2[0])),
                        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_2[1]))]),
                    }))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                    }))]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_36))))), delay(() => {
                        let props_40, props_38;
                        return append(singleton_1((props_40 = ofArray([["className", "pt-3"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_38 = ofArray([["className", "mb-1"], ["className", "is-size-6"], ["className", "has-text-grey"], ["children", t_3("users.overviewImage")]]), createElement("label", createObj(Helpers_combineClasses("label", props_38))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_40))))), delay(() => {
                            let elms_6;
                            return append((state_1.Submission.OverviewPhoto != null) ? singleton_1((elms_6 = toList(delay(() => append(singleton_1(createElement("img", {
                                src: Image_lotMedium800ImagePath(value_185(state_1.Submission.OverviewPhoto)),
                            })), delay(() => {
                                let props_43;
                                return state_1.IsEditMode ? singleton_1((props_43 = ofArray([["style", {
                                    position: "absolute",
                                    right: 5,
                                    top: 5,
                                }], ["onClick", (x_4) => {
                                    x_4.preventDefault();
                                    dispatch(new Msg(6));
                                }]]), createElement("button", createObj(Helpers_combineClasses("delete", props_43))))) : empty_1();
                            })))), createElement("figure", {
                                className: "image",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                            }))) : empty_1(), delay(() => singleton_1(createElement(UploadImage, {
                                isMode: state_1.IsEditMode,
                                onUploaded: (arg_1) => {
                                    dispatch(new Msg(5, arg_1));
                                },
                            }))));
                        }));
                    }));
                }));
            }));
        }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_46)))), (props_57 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_50 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_48 = ofArray([["className", "mb-1"], ["className", "is-size-6"], ["className", "has-text-grey"], ["children", t_3("common.categories")]]), createElement("label", createObj(Helpers_combineClasses("label", props_48))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_50)))), (props_55 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map_1((tag) => {
            const props_53 = toList(delay(() => append(singleton_1(["disabled", !state_1.IsEditMode]), delay(() => {
                const props_52 = contains(tag.Id, map((x_5) => x_5.Id, state_1.Submission.Tags), {
                    Equals: (x_6, y) => (x_6 === y),
                    GetHashCode: (x_6) => numberHash(x_6),
                });
                return append(singleton_1(["onClick", (_arg1) => {
                    dispatch(new Msg(2, tag));
                }]), delay(() => append(props_52 ? singleton_1(["className", "is-primary"]) : append(singleton_1(["className", "is-primary"]), delay(() => singleton_1(["className", "is-light"]))), delay(() => singleton_1(["children", Localized_translate(tag.Name)])))));
            }))));
            return createElement("button", createObj(Helpers_combineClasses("button", props_53)));
        }, state_1.TemplateCategories)))))]), createElement("div", createObj(Helpers_combineClasses("buttons", props_55))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_57))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_59)))), (props_73 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", empty()))), (props_71 = ofArray([["className", "has-text-right"], ["style", {
            alignSelf: "flex-end",
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_63, children_9, children_7, props_65, props_68;
            return (!state_1.IsEditMode) ? singleton_1((props_63 = ofArray([["className", "has-background-grey-lighter"], ["children", t_3("common.edit")], ["onClick", (_arg2) => {
                dispatch(new Msg(7));
            }]]), createElement("button", createObj(Helpers_combineClasses("button", props_63))))) : singleton_1((children_9 = ofArray([(children_7 = singleton_2((props_65 = ofArray([["className", "mb-3"], ["children", t_3("common.cancel")], ["onClick", (_arg3) => {
                dispatch(new Msg(8));
            }]]), createElement("button", createObj(Helpers_combineClasses("button", props_65))))), createElement("p", {
                children: Interop_reactApi.Children.toArray(Array.from(children_7)),
            })), (props_68 = ofArray([["className", "is-primary"], ["children", t_3("common.save")], ["disabled", equals(state_1.Submission, state_1.OriginalSubmission)], ["onClick", (x_7) => {
                x_7.preventDefault();
                dispatch(new Msg(4, new AsyncOperationStatus$1(0)));
            }]]), createElement("button", createObj(Helpers_combineClasses("button", props_68))))]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_9)),
            })));
        }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_71))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_73))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_75))))]),
    })])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_78)));
}

