import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { Agreement_Agreement$reflection } from "./Shared/Shared.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingQuery$1$reflection, Common_PagingResult$1$reflection } from "./Shared/Common.js";
import { int32_type, record_type, unit_type, union_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { agreementApi } from "./Communication.js";
import { t, getLocalizationContext } from "./Localization.js";
import { Cmd_none, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { map as map_1, cons, ofArray, empty, singleton as singleton_1 } from "./fable_modules/fable-library.3.6.1/List.js";
import { round, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty as empty_1, singleton as singleton_2, append, map, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { rangeDouble } from "./fable_modules/fable-library.3.6.1/Range.js";
import { toNumber } from "./fable_modules/fable-library.3.6.1/Long.js";
import { dividedTop } from "./Components/BorderDividedLine.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { renderItems, tableHeader } from "./ForAgreement.js";
import { loader } from "./Loader.js";
import { React_memo_6648A89D } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { ElmishComponentProps$2 } from "./fable_modules/Feliz.ElmishComponents.1.2.0/Components.fs.js";

export class State extends Record {
    constructor(Paging, Query) {
        super();
        this.Paging = Paging;
        this.Query = Query;
    }
}

export function State$reflection() {
    return record_type("Agreements.State", [], State, () => [["Paging", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(Agreement_Agreement$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(Agreement_Agreement$reflection())]], [["ErrorValue", string_type]]]))], ["Query", Common_PagingQuery$1$reflection(unit_type)]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadAgreement", "PageChanged", "OnColumnClicked"];
    }
}

export function Msg$reflection() {
    return union_type("Agreements.Msg", [], Msg, () => [[["Item1", Common_PagingQuery$1$reflection(unit_type)], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(Agreement_Agreement$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(Agreement_Agreement$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]], [["Item", string_type]]]);
}

export function getAgreementsFromDB(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(agreementApi().getAgreements(query)), (_arg1) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export const init = (() => {
    const query = new Common_PagingQuery$1(void 0, 1, 20, void 0, getLocalizationContext());
    return [new State(new Deferred$1(0), query), Cmd_OfFunc_result(new Msg(0, query, new AsyncOperationStatus$1(0)))];
})();

export function update(msg, state) {
    let s;
    if (msg.tag === 1) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Common_PagingQuery$1(inputRecord.Payload, msg.fields[0], inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(state.Paging, newQuery), Cmd_fromAsync(getAgreementsFromDB(newQuery))];
    }
    else if (msg.tag === 2) {
        let newQuery_1;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_1 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, inputRecord_1.Page, inputRecord_1.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_1.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_2 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, inputRecord_2.Page, inputRecord_2.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_2.LocalizationContext));
                break;
            }
        }
        return [new State(state.Paging, newQuery_1), Cmd_fromAsync(getAgreementsFromDB(newQuery_1))];
    }
    else if (msg.fields[1].tag === 1) {
        return [new State(new Deferred$1(2, msg.fields[1].fields[0]), state.Query), Cmd_none()];
    }
    else {
        return [new State(new Deferred$1(1), state.Query), Cmd_fromAsync(getAgreementsFromDB(msg.fields[0]))];
    }
}

export const agreements = (() => {
    let elms_1, elms, s;
    const props_5 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(elms = singleton_1((s = t("users.agreements"), createElement("h1", {
        className: "title",
        children: s,
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("div", createObj(Helpers_combineClasses("column", empty())))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_5)));
})();

export function renderPagination(current, totalP, dispatch) {
    let props_6, elms;
    const elms_1 = singleton_1((props_6 = ofArray([["className", "is-small"], ["className", "is-right"], ["className", "my-5"], ["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => map((p) => {
        let props, children;
        const children_2 = singleton_1(createElement("li", {
            style: {
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray([(props = toList(delay(() => append((current === p) ? singleton_2(["className", "is-current"]) : empty_1(), delay(() => append(singleton_2(["onClick", (x) => {
                dispatch(new Msg(1, p));
                x.preventDefault();
            }]), delay(() => singleton_2(["children", p]))))))), (children = singleton_1(createElement("a", createObj(Helpers_combineClasses("pagination-link", props)))), createElement("li", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })))]),
        }));
        return createElement("ul", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
    }, rangeDouble(1, 1, totalP)))), createElement("ul", {
        className: "pagination-list",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("nav", createObj(Helpers_combineClasses("pagination", props_6)))));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

export function getTotalPages(total, pageSize) {
    return ~(~round(Math.ceil(toNumber(total) / pageSize)));
}

export function renderTable(state, dispatch) {
    const props_10 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms, props_5, props, props_3;
        return append(singleton_2((elms = singleton_1((props_5 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "searchInput"], ["placeholder", t("users.searchAgreement")]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props))))), (props_3 = ofArray([["className", "is-left"], ["style", {
            height: 100 + "%",
        }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-search", "fa-lg"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))])]]), createElement("p", createObj(Helpers_combineClasses("control", props_5))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), delay(() => append(singleton_2(tableHeader), delay(() => {
            let children_1;
            const matchValue = state.Paging;
            let pattern_matching_result, e, paging;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 2;
                    paging = matchValue.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    e = matchValue.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(loader("big"));
                }
                case 1: {
                    return singleton_2(createElement("div", {
                        children: [e],
                    }));
                }
                case 2: {
                    const render = map_1((agreement) => renderItems(agreement), paging.Result);
                    return singleton_2((children_1 = toList(delay(() => append(render, delay(() => singleton_2(renderPagination(paging.Page, getTotalPages(paging.Total, paging.PageSize), dispatch)))))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    })));
                }
            }
        }))));
    }))))]]);
    return createElement("section", createObj(Helpers_combineClasses("section", props_10)));
}

export function view(state, dispatch) {
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([agreements, renderTable(state, dispatch)]),
    });
}

export const renderPage = (() => {
    let name, key, fullKey;
    const elms = singleton_1((name = "Agreements", (key = (void 0), (fullKey = ((key != null) ? ((name + "-") + key) : name), React_memo_6648A89D(name, (input) => {
        const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(input.Initial, input.Update, []);
        return input.Render(patternInput[0], patternInput[1]);
    }, (_arg1) => fullKey)(new ElmishComponentProps$2(init, (msg, state) => update(msg, state), (state_1, dispatch) => view(state_1, dispatch)))))));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
})();

