import { Record, Union } from "./fable_modules/fable-library.3.6.1/Types.js";
import { record_type, bool_type, class_type, list_type, union_type, unit_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Images_UploadImageIntoFolderCommitInfo, User_Address, Settings_CompanySettings, Settings_CompanySettings$reflection, Image$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, Deferred$1$reflection, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { imagesApi, settingsApi } from "./Communication.js";
import { empty } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { CompanySettings_validate, CompanySettings_validateIf } from "./Validation.js";
import { PhoneModule_toString, EmailModule_ofString, EmailModule_toString, CountryCodeModule_fromString, PhoneModule_ofString } from "./bidflow/src/infrastructure/Auction.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { map as map_1, empty as empty_2, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { CountriesInput_getSortedList, Form_getFieldError } from "./Common.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { map, fold, singleton as singleton_2, cons, empty as empty_1, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { useReact_useCallback_93353E, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { traverseAsyncResultA } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/List.fs.js";
import { mapError } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/AsyncResult.fs.js";
import { t, tWithArgs } from "./Localization.js";
import { AsyncResultCE_asyncResult, AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/AsyncResultCE.fs.js";
import { Browser_Types_File__File_ReadAsByteArray } from "./fable_modules/Fable.Remoting.Client.7.10.0/Extensions.fs.js";
import { rangeDouble } from "./fable_modules/fable-library.3.6.1/Range.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Png_EditImages } from "./Components/DownloadBackgroundImage.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompanyNameChanged", "EmailChanged", "PhoneChanged", "AddressChanged", "ZipCodeChanged", "CityChanged", "CountryChanged", "GoogleLinkAddressChanged", "OrgNumberChanged", "FacebookLinkChanged", "InstagramLinkChanged", "ImagesChanged", "CancelEdit", "SaveForm"];
    }
}

export function Msg$reflection() {
    return union_type("CompanySettings.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", Image$reflection()]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_saveSettings(info) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().saveCompanySettings(info)), (_arg1) => singleton.Return(new Msg(13, new AsyncOperationStatus$1(1, _arg1))))), (_arg2) => singleton.Return(new Msg(13, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export class State extends Record {
    constructor(CompanySettings, OriginalSettings, FormErrors, NeedToValidate, SaveFormOperation) {
        super();
        this.CompanySettings = CompanySettings;
        this.OriginalSettings = OriginalSettings;
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
        this.SaveFormOperation = SaveFormOperation;
    }
}

export function State$reflection() {
    return record_type("CompanySettings.State", [], State, () => [["CompanySettings", Settings_CompanySettings$reflection()], ["OriginalSettings", Settings_CompanySettings$reflection()], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export function init(settings) {
    return [new State(settings, settings, empty(), true, new Deferred$1(0)), Cmd_none()];
}

export function withCompanySettings(x, state) {
    return new State(x, state.OriginalSettings, state.FormErrors, state.NeedToValidate, state.SaveFormOperation);
}

export function withFormAndErrors(form, errors, state) {
    return new State(form, state.OriginalSettings, errors, state.NeedToValidate, state.SaveFormOperation);
}

export function update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12, inputRecord_13, inputRecord_14, inputRecord_15, inputRecord;
    const withValidatedForm = (arg) => {
        const tupledArg = CompanySettings_validateIf(state.NeedToValidate, arg);
        return (state_1) => withFormAndErrors(tupledArg[0], tupledArg[1], state_1);
    };
    if (msg.tag === 1) {
        return [withValidatedForm((inputRecord_1 = state.CompanySettings, new Settings_CompanySettings(inputRecord_1.CompanyName, msg.fields[0], inputRecord_1.Phone, inputRecord_1.Address, inputRecord_1.GoogleLinkAddress, inputRecord_1.OrgNumber, inputRecord_1.FacebookLink, inputRecord_1.InstagramLink, inputRecord_1.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withValidatedForm((inputRecord_2 = state.CompanySettings, new Settings_CompanySettings(inputRecord_2.CompanyName, inputRecord_2.Email, PhoneModule_ofString(msg.fields[0]), inputRecord_2.Address, inputRecord_2.GoogleLinkAddress, inputRecord_2.OrgNumber, inputRecord_2.FacebookLink, inputRecord_2.InstagramLink, inputRecord_2.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withValidatedForm((inputRecord_3 = state.CompanySettings, new Settings_CompanySettings(inputRecord_3.CompanyName, inputRecord_3.Email, inputRecord_3.Phone, (inputRecord_4 = state.CompanySettings.Address, new User_Address(msg.fields[0], inputRecord_4.ZipCode, inputRecord_4.City, inputRecord_4.Country)), inputRecord_3.GoogleLinkAddress, inputRecord_3.OrgNumber, inputRecord_3.FacebookLink, inputRecord_3.InstagramLink, inputRecord_3.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [withValidatedForm((inputRecord_5 = state.CompanySettings, new Settings_CompanySettings(inputRecord_5.CompanyName, inputRecord_5.Email, inputRecord_5.Phone, (inputRecord_6 = state.CompanySettings.Address, new User_Address(inputRecord_6.Address, msg.fields[0], inputRecord_6.City, inputRecord_6.Country)), inputRecord_5.GoogleLinkAddress, inputRecord_5.OrgNumber, inputRecord_5.FacebookLink, inputRecord_5.InstagramLink, inputRecord_5.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [withValidatedForm((inputRecord_7 = state.CompanySettings, new Settings_CompanySettings(inputRecord_7.CompanyName, inputRecord_7.Email, inputRecord_7.Phone, (inputRecord_8 = state.CompanySettings.Address, new User_Address(inputRecord_8.Address, inputRecord_8.ZipCode, msg.fields[0], inputRecord_8.Country)), inputRecord_7.GoogleLinkAddress, inputRecord_7.OrgNumber, inputRecord_7.FacebookLink, inputRecord_7.InstagramLink, inputRecord_7.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [withValidatedForm((inputRecord_9 = state.CompanySettings, new Settings_CompanySettings(inputRecord_9.CompanyName, inputRecord_9.Email, inputRecord_9.Phone, (inputRecord_10 = state.CompanySettings.Address, new User_Address(inputRecord_10.Address, inputRecord_10.ZipCode, inputRecord_10.City, msg.fields[0])), inputRecord_9.GoogleLinkAddress, inputRecord_9.OrgNumber, inputRecord_9.FacebookLink, inputRecord_9.InstagramLink, inputRecord_9.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [withValidatedForm((inputRecord_11 = state.CompanySettings, new Settings_CompanySettings(inputRecord_11.CompanyName, inputRecord_11.Email, inputRecord_11.Phone, inputRecord_11.Address, msg.fields[0], inputRecord_11.OrgNumber, inputRecord_11.FacebookLink, inputRecord_11.InstagramLink, inputRecord_11.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [withValidatedForm((inputRecord_12 = state.CompanySettings, new Settings_CompanySettings(inputRecord_12.CompanyName, inputRecord_12.Email, inputRecord_12.Phone, inputRecord_12.Address, inputRecord_12.GoogleLinkAddress, msg.fields[0], inputRecord_12.FacebookLink, inputRecord_12.InstagramLink, inputRecord_12.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withValidatedForm((inputRecord_13 = state.CompanySettings, new Settings_CompanySettings(inputRecord_13.CompanyName, inputRecord_13.Email, inputRecord_13.Phone, inputRecord_13.Address, inputRecord_13.GoogleLinkAddress, inputRecord_13.OrgNumber, msg.fields[0], inputRecord_13.InstagramLink, inputRecord_13.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [withValidatedForm((inputRecord_14 = state.CompanySettings, new Settings_CompanySettings(inputRecord_14.CompanyName, inputRecord_14.Email, inputRecord_14.Phone, inputRecord_14.Address, inputRecord_14.GoogleLinkAddress, inputRecord_14.OrgNumber, inputRecord_14.FacebookLink, msg.fields[0], inputRecord_14.LogoImage)))(state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [new State(state.OriginalSettings, state.OriginalSettings, empty(), false, state.SaveFormOperation), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [withCompanySettings((inputRecord_15 = state.CompanySettings, new Settings_CompanySettings(inputRecord_15.CompanyName, inputRecord_15.Email, inputRecord_15.Phone, inputRecord_15.Address, inputRecord_15.GoogleLinkAddress, inputRecord_15.OrgNumber, inputRecord_15.FacebookLink, inputRecord_15.InstagramLink, msg.fields[0])), state), Cmd_none()];
    }
    else if (msg.tag === 13) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.CompanySettings, state.CompanySettings, state.FormErrors, false, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else if (!equals(state.CompanySettings, state.OriginalSettings)) {
            const matchValue = CompanySettings_validate(state.CompanySettings);
            if (matchValue.tag === 1) {
                return [new State(state.CompanySettings, state.OriginalSettings, matchValue.fields[0], true, new Deferred$1(0)), Cmd_none()];
            }
            else {
                return [new State(state.CompanySettings, state.OriginalSettings, empty(), true, new Deferred$1(1)), Cmd_fromAsync(Cmd_saveSettings(state.CompanySettings))];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [withValidatedForm((inputRecord = state.CompanySettings, new Settings_CompanySettings(msg.fields[0], inputRecord.Email, inputRecord.Phone, inputRecord.Address, inputRecord.GoogleLinkAddress, inputRecord.OrgNumber, inputRecord.FacebookLink, inputRecord.InstagramLink, inputRecord.LogoImage)))(state), Cmd_none()];
    }
}

export function renderFiled(label, value, placeholder, onChange, state, formErrors) {
    const props_10 = toList(delay(() => append(singleton_1(["className", "has-text-grey-dark"]), delay(() => {
        let elms_1, props_2, elms, props_4, props_7;
        const errors = Form_getFieldError(state.FormErrors, formErrors);
        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_2((props_4 = toList(delay(() => append((!equals(errors, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev) => {
            onChange(ev.target.value);
        }]), delay(() => append(singleton_1(["placeholder", placeholder]), delay(() => {
            let value_17;
            return singleton_1((value_17 = value, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_17)) : false) {
                    e.value = value_17;
                }
            }]));
        })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_7 = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_7))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    }))));
    return createElement("div", createObj(Helpers_combineClasses("field", props_10)));
}

export function renderEmailFiled(label, value, placeholder, onChange, state) {
    const props_10 = toList(delay(() => append(singleton_1(["className", "has-text-grey-dark"]), delay(() => {
        let elms_1, props_2, elms, props_4, props_7;
        const errors = Form_getFieldError(state.FormErrors, "Email");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_2((props_4 = toList(delay(() => append((!equals(errors, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev) => {
            onChange(ev.target.value);
        }]), delay(() => append(singleton_1(["placeholder", placeholder]), delay(() => {
            let value_17;
            return singleton_1((value_17 = value, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_17)) : false) {
                    e.value = value_17;
                }
            }]));
        })))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_7 = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_7))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    }))));
    return createElement("div", createObj(Helpers_combineClasses("field", props_10)));
}

export function renderCountryFiled(label, value, placeholder, onChange, state) {
    const props_13 = toList(delay(() => append(singleton_1(["className", "has-text-grey-dark"]), delay(() => {
        let elms_1, props_2, elms, patternInput, props_10;
        const errors = Form_getFieldError(state.FormErrors, "Country");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_2((patternInput = Helpers_extractClasses(toList(delay(() => append((!equals(errors, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(singleton_1(["className", "has-text-left"]), delay(() => append(singleton_1(["className", "mb-1"]), delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["onChange", (ev) => {
            onChange(ev.target.value);
        }]), delay(() => append(singleton_1(["value", value]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => createElement("option", {
            value: tupledArg[0],
            children: tupledArg[1],
        }), CountriesInput_getSortedList())))])))))))))))))))))), createElement("div", {
            className: join(" ", cons("select", patternInput[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_10 = ofArray([["className", "is-danger"], ["children", fold((s, x) => (`${s} ${x}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_10))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    }))));
    return createElement("div", createObj(Helpers_combineClasses("field", props_13)));
}

export function UploadImage(uploadImageInputProps) {
    const setInProgress = uploadImageInputProps.setInProgress;
    const inProgress = uploadImageInputProps.inProgress;
    const folder = uploadImageInputProps.folder;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const error = patternInput[0];
    const uploadFileCallback = useReact_useCallback_93353E((arg_3) => {
        startImmediate(singleton.Delay(() => {
            patternInput[1]("");
            setInProgress(true);
            return singleton.Bind(traverseAsyncResultA((file) => mapError((x_16) => tWithArgs("errors.fromServer.uploadImagesWithError", {
                error: x_16,
            }), AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD(AsyncResultCE_asyncResult, () => {
                const asyncResult_2 = singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file), (arg) => singleton.Return(new FSharpResult$2(0, arg)));
                return singleton.Delay(() => singleton.Bind(asyncResult_2, (_arg1_2) => {
                    let asyncResult_1;
                    const result_1 = _arg1_2;
                    return (result_1.tag === 1) ? singleton.Return(new FSharpResult$2(1, result_1.fields[0])) : singleton.ReturnFrom((asyncResult_1 = singleton.Bind(Remoting_handleNonAuth(imagesApi().uploadImage(result_1.fields[0])), (arg_1) => singleton.Return(new FSharpResult$2(0, arg_1))), singleton.Delay(() => singleton.Bind(asyncResult_1, (_arg1_1) => {
                        let args;
                        const result = _arg1_1;
                        return (result.tag === 1) ? singleton.Return(new FSharpResult$2(1, result.fields[0])) : singleton.ReturnFrom((args = (new Images_UploadImageIntoFolderCommitInfo(result.fields[0], folder, file.name)), singleton.Bind(Remoting_handleNonAuth(imagesApi().uploadImageCommitToFolder(args)), (arg_2) => singleton.Return(new FSharpResult$2(0, void 0)))));
                    }))));
                }));
            })), arg_3), (_arg3) => {
                setInProgress(false);
                return singleton.Zero();
            });
        }));
    }, [folder]);
    const props_8 = ofArray([["className", "is-fullwidth"], ["className", "is-primary"], ["className", "has-text-centered"], ["disabled", inProgress], ["style", {
        borderStyle: "solid",
        minHeight: 70 + "px",
    }], ["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", ofArray([["multiple", true], ["tabIndex", -1], ["disabled", inProgress], ["onChange", (ev) => {
        const fileList = ev.target.files;
        if (!(fileList == null)) {
            uploadFileCallback(toList(delay(() => map_1((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1)))));
        }
    }]]))))), createElement("span", {
        className: join(" ", ["icon-text", "is-text-overflow-hidden"]),
        style: {
            alignItems: "center",
            padding: 5,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_3;
            return append(singleton_1((props_3 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (inProgress ? singleton_1(["className", join(" ", ["fas", "fa-spinner", "fa-spin"])]) : singleton_1(["className", join(" ", ["fa", "fa-plus"])]))))))])]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))), delay(() => {
                let value_34;
                return append(singleton_1((value_34 = (inProgress ? "" : "Ny bild"), createElement("span", {
                    children: [value_34],
                }))), delay(() => ((error !== "") ? singleton_1(createElement("span", {
                    children: [error],
                })) : empty_2())));
            }));
        })))),
    })])]]);
    return createElement("span", createObj(Helpers_combineClasses("button", props_8)));
}

export function RenderSetting(settings) {
    let props_10, props_8, props, props_6, props_2, props_4;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(settings), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const elms = singleton_2((props_10 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-half"], ["children", Interop_reactApi.Children.toArray([renderFiled(t("common.companyName"), state_1.CompanySettings.CompanyName, t("common.companyName"), (arg) => {
        dispatch(new Msg(0, arg));
    }, state_1, "CompanyName"), renderFiled(t("common.address"), state_1.CompanySettings.Address.Address, t("common.address"), (arg_1) => {
        dispatch(new Msg(3, arg_1));
    }, state_1, "Address"), renderFiled(t("users.zipCode"), state_1.CompanySettings.Address.ZipCode, t("users.zipCode"), (arg_2) => {
        dispatch(new Msg(4, arg_2));
    }, state_1, "ZipCode"), renderFiled(t("users.city"), state_1.CompanySettings.Address.City, t("users.city"), (arg_3) => {
        dispatch(new Msg(5, arg_3));
    }, state_1, "City"), renderCountryFiled(t("users.country"), state_1.CompanySettings.Address.Country, t("users.country"), (arg_5) => {
        dispatch(new Msg(6, CountryCodeModule_fromString(arg_5)));
    }, state_1), renderFiled(t("settings.googleLink"), state_1.CompanySettings.GoogleLinkAddress, t("settings.googleLink"), (arg_6) => {
        dispatch(new Msg(7, arg_6));
    }, state_1, "GpsMapLink"), renderFiled(t("settings.facebookLink"), state_1.CompanySettings.FacebookLink, t("settings.facebookLink"), (arg_7) => {
        dispatch(new Msg(9, arg_7));
    }, state_1, "FacebookLink"), renderEmailFiled(t("common.email"), EmailModule_toString(state_1.CompanySettings.Email), t("common.email"), (arg_9) => {
        dispatch(new Msg(1, EmailModule_ofString(arg_9)));
    }, state_1), renderFiled(t("settings.instagramLink"), state_1.CompanySettings.InstagramLink, t("settings.instagramLink"), (arg_10) => {
        dispatch(new Msg(10, arg_10));
    }, state_1, "InstagramLink"), renderFiled(t("common.phoneNumber"), PhoneModule_toString(state_1.CompanySettings.Phone), t("common.phoneNumber"), (arg_11) => {
        dispatch(new Msg(2, arg_11));
    }, state_1, "Phone"), renderFiled(t("settings.organizationNumber"), state_1.CompanySettings.OrgNumber, t("settings.organizationNumber"), (arg_12) => {
        dispatch(new Msg(8, arg_12));
    }, state_1, "OrgNumber"), (props = ofArray([["className", "mb-5"], ["children", Interop_reactApi.Children.toArray([createElement(Png_EditImages, {
        image: state_1.CompanySettings.LogoImage,
        onImagesChanged: (arg_13) => {
            dispatch(new Msg(11, arg_13));
        },
        onDeleteImage: (_arg1) => {
        },
        needDeleteButton: false,
        flag: true,
        dependencies: [],
    })])]]), createElement("section", createObj(Helpers_combineClasses("section", props)))), (props_6 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.SaveFormOperation, new Deferred$1(1))], ["children", t("common.cancel")], ["onClick", (x_1) => {
        x_1.preventDefault();
        dispatch(new Msg(12));
    }]]), createElement("button", createObj(Helpers_combineClasses("button", props_2)))), (props_4 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(equals(state_1.CompanySettings, state_1.OriginalSettings) ? singleton_1(["disabled", true]) : empty_2(), delay(() => append(equals(state_1.SaveFormOperation, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_2(), delay(() => append(singleton_1(["children", t("common.save")]), delay(() => singleton_1(["onClick", (x_2) => {
        x_2.preventDefault();
        dispatch(new Msg(13, new AsyncOperationStatus$1(0)));
    }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_10)))));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function Render() {
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getCompanySettings()), []);
    switch (settings.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            return settings.fields[0].message;
        }
        case 2: {
            return createElement(RenderSetting, settings.fields[0]);
        }
        default: {
            return loader("big");
        }
    }
}

