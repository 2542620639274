import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { User_SystemUser, Errors_ServerErrorModule_explain, Errors_ServerError, CommonQueries_CompanyBasicSettings, User_RegisterSellerPersonal, User_BankInfo, User_Address, User_PersonalInfo, Errors_ServerError$reflection, CommonQueries_CompanyBasicSettings$reflection, User_RegisterSellerPersonal$reflection } from "../Shared/Shared.js";
import { record_type, bool_type, class_type, list_type, union_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Remoting_handleNonAuth, AsyncOperationStatus$1, Deferred$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { PhoneModule_ofString, EmailModule_ofString, CountryCodeModule_empty, PhoneModule_empty, EmailModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { empty } from "../fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { usersApi, commonApi } from "../Communication.js";
import { value as value_627 } from "../fable_modules/fable-library.3.6.1/Option.js";
import { CountriesInput_getSortedList, Form_getFieldError, Form_validationErrorsPrivate } from "../Common.js";
import { Route, toPath, Route_AccountDetailsSubmissions_7639071A, navigateTo } from "../Router.js";
import { CountryModule_shortCode } from "../bidflow/src/infrastructure/Countries.js";
import { append as append_1, map, singleton as singleton_2, cons, empty as empty_1, ofArray, fold } from "../fable_modules/fable-library.3.6.1/List.js";
import { join, printf, toText } from "../fable_modules/fable-library.3.6.1/String.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { React_functionComponent_2F9D7239 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "../Localization.js";
import { empty as empty_2, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { dividedTop } from "../Components/BorderDividedLine.js";

export class State extends Record {
    constructor(User, BasicCompanySettings, FormErrors, FormValidation, Registration) {
        super();
        this.User = User;
        this.BasicCompanySettings = BasicCompanySettings;
        this.FormErrors = FormErrors;
        this.FormValidation = FormValidation;
        this.Registration = Registration;
    }
}

export function State$reflection() {
    return record_type("PrivateCustomer.State", [], State, () => [["User", User_RegisterSellerPersonal$reflection()], ["BasicCompanySettings", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CommonQueries_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CommonQueries_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["FormValidation", bool_type], ["Registration", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", Errors_ServerError$reflection()]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadBasicSettings", "EmailChanged", "NonExistentEmailChanged", "AvoidWarehouseFeeChanged", "PasswordChanged", "PasswordConfirmationChanged", "FirstNameChanged", "LastNameChanged", "PhoneChanged", "AddressChanged", "PersonalNumberChanged", "ZipCodeChanged", "CityChanged", "CountryChanged", "ClearingNumberChanged", "BankNumberChanged", "BankChanged", "IbanCountryChanged", "IbanChanged", "RegisterAccount"];
    }
}

export function Msg$reflection() {
    return union_type("PrivateCustomer.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [CommonQueries_CompanyBasicSettings$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", CommonQueries_CompanyBasicSettings$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", Errors_ServerError$reflection()]]]))]]]);
}

export function init() {
    return [new State(new User_RegisterSellerPersonal(EmailModule_empty, false, false, "", "", new User_PersonalInfo("", "", PhoneModule_empty, ""), new User_Address("", "", "", CountryCodeModule_empty), new User_BankInfo("", "", "", "", CountryCodeModule_empty, "")), new Deferred$1(0), empty(), false, new Deferred$1(0)), Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0)))];
}

export function loadResidentCountry() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg1) => {
        const settings = _arg1;
        return singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, new CommonQueries_CompanyBasicSettings(settings.ResidentCountry, settings.CollectPersonalNumber)))));
    })), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function register(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(usersApi().registerSellerPersonal(state.User)), (_arg1) => {
        const result = _arg1;
        return (result.tag === 1) ? singleton.Return(new Msg(19, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))) : singleton.Return(new Msg(19, new AsyncOperationStatus$1(1, new FSharpResult$2(0, result.fields[0]))));
    })), (_arg2) => singleton.Return(new Msg(19, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message)))))));
}

export function update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_6, inputRecord_8, inputRecord_10, inputRecord_12, inputRecord_14, inputRecord_16, inputRecord_18, inputRecord_20, inputRecord_22, inputRecord_24, inputRecord_26, inputRecord_28, inputRecord_30;
    const basicSettings = (settings) => {
        let pattern_matching_result, s;
        if (settings.tag === 1) {
            pattern_matching_result = 0;
        }
        else if (settings.tag === 2) {
            if (settings.fields[0].tag === 0) {
                pattern_matching_result = 2;
                s = settings.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return void 0;
            }
            case 1: {
                return void 0;
            }
            case 2: {
                return s;
            }
        }
    };
    if (msg.tag === 1) {
        let newState;
        const inputRecord = state.User;
        newState = (new User_RegisterSellerPersonal(msg.fields[0], inputRecord.NonExistentEmail, inputRecord.NeedAvoidWarehouseFee, inputRecord.Password, inputRecord.PasswordConfirmation, inputRecord.PersonalInfo, inputRecord.Address, inputRecord.BankInfo));
        return [new State(newState, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new State((inputRecord_1 = state.User, new User_RegisterSellerPersonal(inputRecord_1.Email, msg.fields[0], inputRecord_1.NeedAvoidWarehouseFee, inputRecord_1.Password, inputRecord_1.PasswordConfirmation, inputRecord_1.PersonalInfo, inputRecord_1.Address, inputRecord_1.BankInfo)), state.BasicCompanySettings, state.FormErrors, state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new State((inputRecord_2 = state.User, new User_RegisterSellerPersonal(inputRecord_2.Email, inputRecord_2.NonExistentEmail, msg.fields[0], inputRecord_2.Password, inputRecord_2.PasswordConfirmation, inputRecord_2.PersonalInfo, inputRecord_2.Address, inputRecord_2.BankInfo)), state.BasicCompanySettings, state.FormErrors, state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 4) {
        let newState_3;
        const inputRecord_3 = state.User;
        newState_3 = (new User_RegisterSellerPersonal(inputRecord_3.Email, inputRecord_3.NonExistentEmail, inputRecord_3.NeedAvoidWarehouseFee, msg.fields[0], inputRecord_3.PasswordConfirmation, inputRecord_3.PersonalInfo, inputRecord_3.Address, inputRecord_3.BankInfo));
        return [new State(newState_3, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_3, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 5) {
        let newState_4;
        const inputRecord_4 = state.User;
        newState_4 = (new User_RegisterSellerPersonal(inputRecord_4.Email, inputRecord_4.NonExistentEmail, inputRecord_4.NeedAvoidWarehouseFee, inputRecord_4.Password, msg.fields[0], inputRecord_4.PersonalInfo, inputRecord_4.Address, inputRecord_4.BankInfo));
        return [new State(newState_4, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_4, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 6) {
        let newState_5;
        const inputRecord_5 = state.User;
        newState_5 = (new User_RegisterSellerPersonal(inputRecord_5.Email, inputRecord_5.NonExistentEmail, inputRecord_5.NeedAvoidWarehouseFee, inputRecord_5.Password, inputRecord_5.PasswordConfirmation, (inputRecord_6 = state.User.PersonalInfo, new User_PersonalInfo(msg.fields[0], inputRecord_6.LastName, inputRecord_6.Phone, inputRecord_6.PersonalNumber)), inputRecord_5.Address, inputRecord_5.BankInfo));
        return [new State(newState_5, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_5, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 7) {
        let newState_6;
        const inputRecord_7 = state.User;
        newState_6 = (new User_RegisterSellerPersonal(inputRecord_7.Email, inputRecord_7.NonExistentEmail, inputRecord_7.NeedAvoidWarehouseFee, inputRecord_7.Password, inputRecord_7.PasswordConfirmation, (inputRecord_8 = state.User.PersonalInfo, new User_PersonalInfo(inputRecord_8.FirstName, msg.fields[0], inputRecord_8.Phone, inputRecord_8.PersonalNumber)), inputRecord_7.Address, inputRecord_7.BankInfo));
        return [new State(newState_6, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_6, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 8) {
        let newState_7;
        const inputRecord_9 = state.User;
        newState_7 = (new User_RegisterSellerPersonal(inputRecord_9.Email, inputRecord_9.NonExistentEmail, inputRecord_9.NeedAvoidWarehouseFee, inputRecord_9.Password, inputRecord_9.PasswordConfirmation, (inputRecord_10 = state.User.PersonalInfo, new User_PersonalInfo(inputRecord_10.FirstName, inputRecord_10.LastName, msg.fields[0], inputRecord_10.PersonalNumber)), inputRecord_9.Address, inputRecord_9.BankInfo));
        return [new State(newState_7, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_7, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 10) {
        let newState_8;
        const inputRecord_11 = state.User;
        newState_8 = (new User_RegisterSellerPersonal(inputRecord_11.Email, inputRecord_11.NonExistentEmail, inputRecord_11.NeedAvoidWarehouseFee, inputRecord_11.Password, inputRecord_11.PasswordConfirmation, (inputRecord_12 = state.User.PersonalInfo, new User_PersonalInfo(inputRecord_12.FirstName, inputRecord_12.LastName, inputRecord_12.Phone, msg.fields[0])), inputRecord_11.Address, inputRecord_11.BankInfo));
        return [new State(newState_8, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_8, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 9) {
        let newState_9;
        const inputRecord_13 = state.User;
        newState_9 = (new User_RegisterSellerPersonal(inputRecord_13.Email, inputRecord_13.NonExistentEmail, inputRecord_13.NeedAvoidWarehouseFee, inputRecord_13.Password, inputRecord_13.PasswordConfirmation, inputRecord_13.PersonalInfo, (inputRecord_14 = state.User.Address, new User_Address(msg.fields[0], inputRecord_14.ZipCode, inputRecord_14.City, inputRecord_14.Country)), inputRecord_13.BankInfo));
        return [new State(newState_9, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_9, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 11) {
        let newState_10;
        const inputRecord_15 = state.User;
        newState_10 = (new User_RegisterSellerPersonal(inputRecord_15.Email, inputRecord_15.NonExistentEmail, inputRecord_15.NeedAvoidWarehouseFee, inputRecord_15.Password, inputRecord_15.PasswordConfirmation, inputRecord_15.PersonalInfo, (inputRecord_16 = state.User.Address, new User_Address(inputRecord_16.Address, msg.fields[0], inputRecord_16.City, inputRecord_16.Country)), inputRecord_15.BankInfo));
        return [new State(newState_10, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_10, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 12) {
        let newState_11;
        const inputRecord_17 = state.User;
        newState_11 = (new User_RegisterSellerPersonal(inputRecord_17.Email, inputRecord_17.NonExistentEmail, inputRecord_17.NeedAvoidWarehouseFee, inputRecord_17.Password, inputRecord_17.PasswordConfirmation, inputRecord_17.PersonalInfo, (inputRecord_18 = state.User.Address, new User_Address(inputRecord_18.Address, inputRecord_18.ZipCode, msg.fields[0], inputRecord_18.Country)), inputRecord_17.BankInfo));
        return [new State(newState_11, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_11, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 13) {
        let newState_12;
        const inputRecord_19 = state.User;
        newState_12 = (new User_RegisterSellerPersonal(inputRecord_19.Email, inputRecord_19.NonExistentEmail, inputRecord_19.NeedAvoidWarehouseFee, inputRecord_19.Password, inputRecord_19.PasswordConfirmation, inputRecord_19.PersonalInfo, (inputRecord_20 = state.User.Address, new User_Address(inputRecord_20.Address, inputRecord_20.ZipCode, inputRecord_20.City, msg.fields[0])), inputRecord_19.BankInfo));
        return [new State(newState_12, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_12, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 14) {
        let newState_13;
        const inputRecord_21 = state.User;
        newState_13 = (new User_RegisterSellerPersonal(inputRecord_21.Email, inputRecord_21.NonExistentEmail, inputRecord_21.NeedAvoidWarehouseFee, inputRecord_21.Password, inputRecord_21.PasswordConfirmation, inputRecord_21.PersonalInfo, inputRecord_21.Address, (inputRecord_22 = state.User.BankInfo, new User_BankInfo(msg.fields[0], inputRecord_22.BankNumber, inputRecord_22.Bank, inputRecord_22.Comment, inputRecord_22.IbanCountry, inputRecord_22.Iban))));
        return [new State(newState_13, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_13, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 15) {
        let newState_14;
        const inputRecord_23 = state.User;
        newState_14 = (new User_RegisterSellerPersonal(inputRecord_23.Email, inputRecord_23.NonExistentEmail, inputRecord_23.NeedAvoidWarehouseFee, inputRecord_23.Password, inputRecord_23.PasswordConfirmation, inputRecord_23.PersonalInfo, inputRecord_23.Address, (inputRecord_24 = state.User.BankInfo, new User_BankInfo(inputRecord_24.ClearingNumber, msg.fields[0], inputRecord_24.Bank, inputRecord_24.Comment, inputRecord_24.IbanCountry, inputRecord_24.Iban))));
        return [new State(newState_14, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_14, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 16) {
        let newState_15;
        const inputRecord_25 = state.User;
        newState_15 = (new User_RegisterSellerPersonal(inputRecord_25.Email, inputRecord_25.NonExistentEmail, inputRecord_25.NeedAvoidWarehouseFee, inputRecord_25.Password, inputRecord_25.PasswordConfirmation, inputRecord_25.PersonalInfo, inputRecord_25.Address, (inputRecord_26 = state.User.BankInfo, new User_BankInfo(inputRecord_26.ClearingNumber, inputRecord_26.BankNumber, msg.fields[0], inputRecord_26.Comment, inputRecord_26.IbanCountry, inputRecord_26.Iban))));
        return [new State(newState_15, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_15, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 17) {
        let newState_16;
        const inputRecord_27 = state.User;
        newState_16 = (new User_RegisterSellerPersonal(inputRecord_27.Email, inputRecord_27.NonExistentEmail, inputRecord_27.NeedAvoidWarehouseFee, inputRecord_27.Password, inputRecord_27.PasswordConfirmation, inputRecord_27.PersonalInfo, inputRecord_27.Address, (inputRecord_28 = state.User.BankInfo, new User_BankInfo(inputRecord_28.ClearingNumber, inputRecord_28.BankNumber, inputRecord_28.Bank, inputRecord_28.Comment, msg.fields[0], inputRecord_28.Iban))));
        return [new State(newState_16, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_16, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 18) {
        let newState_17;
        const inputRecord_29 = state.User;
        newState_17 = (new User_RegisterSellerPersonal(inputRecord_29.Email, inputRecord_29.NonExistentEmail, inputRecord_29.NeedAvoidWarehouseFee, inputRecord_29.Password, inputRecord_29.PasswordConfirmation, inputRecord_29.PersonalInfo, inputRecord_29.Address, (inputRecord_30 = state.User.BankInfo, new User_BankInfo(inputRecord_30.ClearingNumber, inputRecord_30.BankNumber, inputRecord_30.Bank, inputRecord_30.Comment, inputRecord_30.IbanCountry, msg.fields[0]))));
        return [new State(newState_17, state.BasicCompanySettings, value_627(Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), newState_17, state.FormValidation)), state.FormValidation, state.Registration), Cmd_none()];
    }
    else if (msg.tag === 19) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.User, state.BasicCompanySettings, state.FormErrors, state.FormValidation, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new State(state.User, state.BasicCompanySettings, state.FormErrors, state.FormValidation, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), navigateTo(Route_AccountDetailsSubmissions_7639071A(msg.fields[0].fields[0].fields[0]))];
            }
        }
        else {
            const newState_18 = new State(state.User, state.BasicCompanySettings, state.FormErrors, true, state.Registration);
            const errors_16 = Form_validationErrorsPrivate(basicSettings(state.BasicCompanySettings), state.User, newState_18.FormValidation);
            if (value_627(errors_16).Equals(empty())) {
                return [new State(state.User, state.BasicCompanySettings, value_627(errors_16), true, new Deferred$1(1)), Cmd_fromAsync(register(state))];
            }
            else {
                return [new State(state.User, state.BasicCompanySettings, value_627(errors_16), true, new Deferred$1(0)), Cmd_none()];
            }
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new State(state.User, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation, state.Registration), Cmd_none()];
        }
        else {
            return [new State(state.User, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation, state.Registration), Cmd_OfFunc_result(new Msg(13, msg.fields[0].fields[0].fields[0].ResidentCountry))];
        }
    }
    else {
        return [new State(state.User, new Deferred$1(1), state.FormErrors, state.FormValidation, state.Registration), Cmd_fromAsync(loadResidentCountry())];
    }
}

export function CountryCodeToString(c) {
    if (c === CountryCodeModule_empty) {
        return "-";
    }
    else {
        return CountryModule_shortCode(c);
    }
}

export function ErrorsHelpBlock(errors) {
    const props = ofArray([["className", "is-danger"], ["children", fold((s, x) => toText(printf("%s %s"))(s)(x), "", errors)]]);
    return createElement("p", createObj(Helpers_combineClasses("help", props)));
}

export function renderError(state) {
    const matchValue = state.Registration;
    let pattern_matching_result, error;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = ofArray([["className", "has-text-centered"], ["className", "has-background-danger"], ["className", "has-text-white"], ["children", Errors_ServerErrorModule_explain(error)]]);
            return createElement("div", createObj(Helpers_combineClasses("box", props)));
        }
        case 1: {
            return null;
        }
    }
}

export const render = React_functionComponent_2F9D7239(() => {
    let props_265, props_46, elms_8, props_4, props, props_2, elms_7, props_14, props_22, elms_2, props_19, props_17, props_32, props_42, props_163, elms_26, props_52, props_48, props_50, elms_25, props_78, props_64, props_62, props_76, props_74, props_104, props_90, props_88, props_102, props_130, props_116, props_114, props_128, props_126, props_159, props_142, props_140, props_157, props_155, props_243, elms_36, props_169, props_165, props_167, elms_35, props_179, props_205, props_191, props_189, props_203, props_201, props_239, props_220, props_218, props_237, children_35, props_263, elms_39, props_249, props_245, props_247, elms_38, props_259, props_257, elms_37, props_254, props_252, props_278, props_276, elms_40, props_273, props_269, props_271;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(init, (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const children_43 = ofArray([(props_265 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_46 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_8 = ofArray([(props_4 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.general")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props)))), (props_2 = ofArray([["style", {
        fontSize: 0.7 + "rem",
    }], ["className", "has-text-grey-light"], ["children", t("users.generalInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (elms_7 = ofArray([(props_14 = toList(delay(() => {
        let elms_1, props_8, props_6, elms, props_10;
        const errors = Form_getFieldError(state_1.FormErrors, "Email");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_8 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.email")]]), createElement("label", createObj(Helpers_combineClasses("label", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_8)))), (elms = singleton_2((props_10 = toList(delay(() => append((!equals(errors, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev) => {
            dispatch(new Msg(1, EmailModule_ofString(ev.target.value)));
        }]), delay(() => singleton_1(["placeholder", t("users.enterEmail")]))))))), createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", props_10)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), ErrorsHelpBlock(errors)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    })), createElement("div", createObj(Helpers_combineClasses("field", props_14)))), (props_22 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_19 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
        className: join(" ", ["is-checkradio"]),
        id: "NonExistentEmail",
        type: "checkbox",
        name: "NonExistentEmail",
        onChange: (ev_1) => {
            dispatch(new Msg(2, ev_1.target.checked));
        },
        checked: state_1.User.NonExistentEmail,
    }), (props_17 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.nonExistentEmail")]]), createElement("label", createObj(Helpers_combineClasses("label", props_17))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_19))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_22)))), (props_32 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_4, props_26, props_24, elms_3, props_28;
        const errors_1 = Form_getFieldError(state_1.FormErrors, "Password");
        return singleton_1((elms_4 = ofArray([(props_26 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.password")]]), createElement("label", createObj(Helpers_combineClasses("label", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_26)))), (elms_3 = singleton_2((props_28 = toList(delay(() => append((!equals(errors_1, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => singleton_1(["onChange", (ev_2) => {
            dispatch(new Msg(4, ev_2.target.value));
        }]))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_28)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), ErrorsHelpBlock(errors_1)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_32)))), (props_42 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_6, props_36, props_34, elms_5, props_38;
        const errors_2 = Form_getFieldError(state_1.FormErrors, "PasswordConfirmation");
        return singleton_1((elms_6 = ofArray([(props_36 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.confirmPassword")]]), createElement("label", createObj(Helpers_combineClasses("label", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_36)))), (elms_5 = singleton_2((props_38 = toList(delay(() => append((!equals(errors_2, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => singleton_1(["onChange", (ev_3) => {
            dispatch(new Msg(5, ev_3.target.value));
        }]))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_38)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        })), ErrorsHelpBlock(errors_2)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_42))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    }))])]), createElement("section", createObj(Helpers_combineClasses("section", props_46)))), (props_163 = ofArray([["style", {
        borderTopStyle: "solid",
        borderTopWidth: 1 + "px",
        borderTopColor: "#c4c4c4",
    }], ["children", Interop_reactApi.Children.toArray([(elms_26 = ofArray([(props_52 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_48 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.profile")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_48)))), (props_50 = ofArray([["style", {
        fontSize: 0.7 + "rem",
    }], ["className", "has-text-grey-light"], ["children", t("users.profileHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_50))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_52)))), (elms_25 = ofArray([(props_78 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_64 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_62 = toList(delay(() => {
        let elms_10, props_56, props_54, elms_9, props_58;
        const errors_3 = Form_getFieldError(state_1.FormErrors, "FirstName");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_10 = ofArray([(props_56 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_54 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.firstName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_54))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_56)))), (elms_9 = singleton_2((props_58 = toList(delay(() => append((!equals(errors_3, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_4) => {
            dispatch(new Msg(6, ev_4.target.value));
        }]), delay(() => singleton_1(["placeholder", t("users.enterFirstName")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_58)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
        })), ErrorsHelpBlock(errors_3)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
        }))])]);
    })), createElement("div", createObj(Helpers_combineClasses("field", props_62))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_64)))), (props_76 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_74 = toList(delay(() => {
        let elms_12, props_68, props_66, elms_11, props_70;
        const errors_4 = Form_getFieldError(state_1.FormErrors, "LastName");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_12 = ofArray([(props_68 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_66 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.lastName")]]), createElement("label", createObj(Helpers_combineClasses("label", props_66))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_68)))), (elms_11 = singleton_2((props_70 = toList(delay(() => append((!equals(errors_4, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_5) => {
            dispatch(new Msg(7, ev_5.target.value));
        }]), delay(() => singleton_1(["placeholder", t("users.enterLastName")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_70)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
        })), ErrorsHelpBlock(errors_4)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
        }))])]);
    })), createElement("div", createObj(Helpers_combineClasses("field", props_74))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_76))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_78)))), (props_104 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_90 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_88 = toList(delay(() => {
        let elms_14, props_82, props_80, elms_13, props_84;
        const errors_5 = Form_getFieldError(state_1.FormErrors, "Phone");
        return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_14 = ofArray([(props_82 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_80 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.phoneNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_80))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_82)))), (elms_13 = singleton_2((props_84 = toList(delay(() => append((!equals(errors_5, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_6) => {
            dispatch(new Msg(8, PhoneModule_ofString(ev_6.target.value)));
        }]), delay(() => singleton_1(["placeholder", t("users.enterPhoneNumber")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_84)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
        })), ErrorsHelpBlock(errors_5)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
        }))])]);
    })), createElement("div", createObj(Helpers_combineClasses("field", props_88))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_90)))), (props_102 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_100;
        const matchValue = state_1.BasicCompanySettings;
        let pattern_matching_result_1, set$;
        if (matchValue.tag === 2) {
            if (matchValue.fields[0].tag === 0) {
                pattern_matching_result_1 = 0;
                set$ = matchValue.fields[0].fields[0];
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return set$.CollectPersonalNumber ? singleton_1((props_100 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let elms_16, props_94, props_92, elms_15, props_96;
                    const errors_6 = Form_getFieldError(state_1.FormErrors, "PersonalNumber");
                    return singleton_1((elms_16 = ofArray([(props_94 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_92 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.personalNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_92))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_94)))), (elms_15 = singleton_2((props_96 = toList(delay(() => append((!equals(errors_6, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_7) => {
                        dispatch(new Msg(10, ev_7.target.value));
                    }]), delay(() => singleton_1(["placeholder", t("users.enterPersonalNumber")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_96)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
                    })), ErrorsHelpBlock(errors_6)]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
                    })));
                }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_100))))) : empty_2();
            }
            case 1: {
                return singleton_1(null);
            }
        }
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_102))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_104)))), (props_130 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_116 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_114 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_18, props_108, props_106, elms_17, props_110;
        const errors_7 = Form_getFieldError(state_1.FormErrors, "Address");
        return singleton_1((elms_18 = ofArray([(props_108 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_106 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props_106))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_108)))), (elms_17 = singleton_2((props_110 = toList(delay(() => append((!equals(errors_7, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_8) => {
            dispatch(new Msg(9, ev_8.target.value));
        }]), delay(() => singleton_1(["placeholder", t("users.enterAddress")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_110)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
        })), ErrorsHelpBlock(errors_7)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_114))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_116)))), (props_128 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_126 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_20, props_120, props_118, elms_19, props_122;
        const errors_8 = Form_getFieldError(state_1.FormErrors, "ZipCode");
        return singleton_1((elms_20 = ofArray([(props_120 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_118 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.zipCode")]]), createElement("label", createObj(Helpers_combineClasses("label", props_118))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_120)))), (elms_19 = singleton_2((props_122 = toList(delay(() => append((!equals(errors_8, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_9) => {
            dispatch(new Msg(11, ev_9.target.value));
        }]), delay(() => singleton_1(["placeholder", t("users.enterZipCode")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_122)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
        })), ErrorsHelpBlock(errors_8)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_126))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_128))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_130)))), (props_159 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_142 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_140 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_22, props_134, props_132, elms_21, props_136;
        const errors_9 = Form_getFieldError(state_1.FormErrors, "City");
        return singleton_1((elms_22 = ofArray([(props_134 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_132 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.city")]]), createElement("label", createObj(Helpers_combineClasses("label", props_132))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_134)))), (elms_21 = singleton_2((props_136 = toList(delay(() => append((!equals(errors_9, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_10) => {
            dispatch(new Msg(12, ev_10.target.value));
        }]), delay(() => singleton_1(["placeholder", t("users.enterCity")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_136)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
        })), ErrorsHelpBlock(errors_9)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_140))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_142)))), (props_157 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_155 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_24, props_146, props_144, elms_23, patternInput_1;
        const errors_10 = Form_getFieldError(state_1.FormErrors, "Country");
        return singleton_1((elms_24 = ofArray([(props_146 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_144 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.country")]]), createElement("label", createObj(Helpers_combineClasses("label", props_144))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_146)))), (elms_23 = singleton_2((patternInput_1 = Helpers_extractClasses(toList(delay(() => append((!equals(errors_10, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(singleton_1(["className", "has-text-left"]), delay(() => append(singleton_1(["className", "mb-1"]), delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["onChange", (ev_11) => {
            dispatch(new Msg(13, ev_11.target.value));
        }]), delay(() => append(singleton_1(["value", state_1.User.Address.Country]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg) => createElement("option", {
            value: tupledArg[0],
            children: tupledArg[1],
        }), CountriesInput_getSortedList())))])))))))))))))))))), createElement("div", {
            className: join(" ", cons("select", patternInput_1[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
        })), ErrorsHelpBlock(errors_10)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_24)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_155))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_157))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_159))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_25)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_26)),
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_163)))), (props_243 = ofArray([["style", {
        borderTopStyle: "solid",
        borderTopWidth: 1 + "px",
        borderTopColor: "#c4c4c4",
    }], ["children", Interop_reactApi.Children.toArray([(elms_36 = ofArray([(props_169 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_165 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.bank")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_165)))), (props_167 = ofArray([["style", {
        fontSize: 0.7 + "rem",
    }], ["className", "has-text-grey-light"], ["children", t("users.bankInfoHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_167))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_169)))), (elms_35 = ofArray([(props_179 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_28, props_173, props_171, elms_27, props_175;
        const errors_11 = Form_getFieldError(state_1.FormErrors, "ClearingNumber");
        return singleton_1((elms_28 = ofArray([(props_173 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_171 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.clearingNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_171))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_173)))), (elms_27 = singleton_2((props_175 = toList(delay(() => append((!equals(errors_11, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_12) => {
            dispatch(new Msg(14, ev_12.target.value));
        }]), delay(() => singleton_1(["placeholder", t("users.enterClearingNumber")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_175)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_27)),
        })), ErrorsHelpBlock(errors_11)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_28)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_179)))), (props_205 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_191 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_189 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_30, props_183, props_181, elms_29, props_185;
        const errors_12 = Form_getFieldError(state_1.FormErrors, "BankNumber");
        return singleton_1((elms_30 = ofArray([(props_183 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_181 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.accountNumber")]]), createElement("label", createObj(Helpers_combineClasses("label", props_181))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_183)))), (elms_29 = singleton_2((props_185 = toList(delay(() => append((!equals(errors_12, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_13) => {
            dispatch(new Msg(15, ev_13.target.value));
        }]), delay(() => singleton_1(["placeholder", t("users.enterAccountNumber")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_185)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_29)),
        })), ErrorsHelpBlock(errors_12)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_30)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_189))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_191)))), (props_203 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_201 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_32, props_195, props_193, elms_31, props_197;
        const errors_13 = Form_getFieldError(state_1.FormErrors, "Bank");
        return singleton_1((elms_32 = ofArray([(props_195 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_193 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.bank")]]), createElement("label", createObj(Helpers_combineClasses("label", props_193))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_195)))), (elms_31 = singleton_2((props_197 = toList(delay(() => append((!equals(errors_13, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_14) => {
            dispatch(new Msg(16, ev_14.target.value));
        }]), delay(() => singleton_1(["placeholder", t("users.enterBank")]))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_197)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_31)),
        })), ErrorsHelpBlock(errors_13)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_32)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_201))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_203))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_205)))), (props_239 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_220 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(props_218 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_34, props_209, props_207, elms_33, patternInput_2;
        const errors_14 = Form_getFieldError(state_1.FormErrors, "IbanCountry");
        return singleton_1((elms_34 = ofArray([(props_209 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_207 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.ibanCountry")]]), createElement("label", createObj(Helpers_combineClasses("label", props_207))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_209)))), (elms_33 = singleton_2((patternInput_2 = Helpers_extractClasses(toList(delay(() => append((!equals(errors_14, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(singleton_1(["className", "has-text-left"]), delay(() => append(singleton_1(["className", "mb-1"]), delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => append(singleton_1(["onChange", (ev_15) => {
            dispatch(new Msg(17, ev_15.target.value));
        }]), delay(() => append(singleton_1(["value", state_1.User.BankInfo.IbanCountry]), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(map((tupledArg_1) => createElement("option", {
            value: tupledArg_1[0],
            children: tupledArg_1[1],
        }), append_1(singleton_2([CountryCodeModule_empty, "Select country"]), CountriesInput_getSortedList()))))])))))))))))))))))), createElement("div", {
            className: join(" ", cons("select", patternInput_2[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_2[1]))]),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_33)),
        })), ErrorsHelpBlock(errors_14)]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_34)),
        })));
    }))))]), createElement("div", createObj(Helpers_combineClasses("field", props_218))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_220)))), (props_237 = ofArray([["className", "pb-0"], ["children", Interop_reactApi.Children.toArray([(children_35 = toList(delay(() => {
        let props_224, props_222;
        const errors_15 = Form_getFieldError(state_1.FormErrors, "IBAN");
        return append(singleton_1((props_224 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_222 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.IBAN")]]), createElement("label", createObj(Helpers_combineClasses("label", props_222))))])]]), createElement("label", createObj(Helpers_combineClasses("label", props_224))))), delay(() => {
            let props_234, props_228, props_226, props_232, props_230;
            return append(singleton_1((props_234 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_228 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_226 = ofArray([["className", "is-static"], ["children", CountryCodeToString(state_1.User.BankInfo.IbanCountry)]]), createElement("a", createObj(Helpers_combineClasses("button", props_226))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_228)))), (props_232 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_230 = toList(delay(() => append((!equals(errors_15, empty_1())) ? singleton_1(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_1(["onChange", (ev_16) => {
                dispatch(new Msg(18, ev_16.target.value));
            }]), delay(() => append(singleton_1(["placeholder", t("users.enterIBAN")]), delay(() => {
                let value_527;
                return singleton_1((value_527 = state_1.User.BankInfo.Iban, ["ref", (e) => {
                    if ((!(e == null)) ? (!equals(e.value, value_527)) : false) {
                        e.value = value_527;
                    }
                }]));
            })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_230)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_232))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_234))))), delay(() => singleton_1(ErrorsHelpBlock(errors_15))));
        }));
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_35)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_237))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_239))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_35)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_36)),
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_243)))), (props_263 = ofArray([dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_39 = ofArray([(props_249 = ofArray([["className", "pr-6"], ["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(props_245 = ofArray([["className", "mb-2"], ["className", "is-size-6"], ["className", "has-text-primary"], ["className", "is-uppercase"], ["children", t("users.userSettings")]]), createElement("h2", createObj(Helpers_combineClasses("title is-2", props_245)))), (props_247 = ofArray([["style", {
        fontSize: 0.7 + "rem",
    }], ["className", "has-text-grey-light"], ["children", t("users.userSettingsHint")]]), createElement("span", createObj(Helpers_combineClasses("", props_247))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_249)))), (elms_38 = singleton_2((props_259 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_257 = ofArray([["className", "pt-3"], ["children", Interop_reactApi.Children.toArray([(elms_37 = singleton_2((props_254 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("input", {
        className: join(" ", ["is-checkradio"]),
        id: "AvoidWarehouseFee",
        type: "checkbox",
        name: "AvoidWarehouseFee",
        onChange: (ev_17) => {
            dispatch(new Msg(3, ev_17.target.checked));
        },
        checked: state_1.User.NeedAvoidWarehouseFee,
    }), (props_252 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("users.avoidWarehouseFee")], ["htmlFor", "AvoidWarehouseFee"]]), createElement("label", createObj(Helpers_combineClasses("label", props_252))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_254))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_37)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_257))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_259))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_38)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_39)),
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_263))))])]), createElement("div", createObj(Helpers_combineClasses("container", props_265)))), (props_278 = ofArray([["style", {
        borderTopStyle: "solid",
        borderTopWidth: 1 + "px",
        borderTopColor: "#c4c4c4",
    }], ["children", Interop_reactApi.Children.toArray([(props_276 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-one-quarter"])))), (elms_40 = singleton_2((props_273 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_269 = ofArray([["className", "is-dark"], ["children", t("common.cancel")], ["href", toPath(new Route(7, 1, new User_SystemUser(0), void 0))]]), createElement("a", createObj(Helpers_combineClasses("button", props_269)))), (props_271 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", t("common.create")]), delay(() => append(equals(state_1.Registration, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty_2(), delay(() => singleton_1(["onClick", (_arg1) => {
        dispatch(new Msg(19, new AsyncOperationStatus$1(0)));
    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_271))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_273))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_40)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_276)))), renderError(state_1)])]]), createElement("section", createObj(Helpers_combineClasses("section", props_278))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_43)),
    });
});

