import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { VatFreeReport_VatFreeFilter, VatFreeReport_VatFreeFilter$reflection, VatFreeReport_User$reflection } from "../Shared/Shared.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_PagingQuery$1, Common_PagingQuery$1$reflection, Common_PagingResult$1$reflection } from "../Shared/Common.js";
import { list_type, class_type, record_type, unit_type, bool_type, int32_type, option_type, union_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { vatFreeReportApi } from "../Communication.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { tail, map as map_1, toArray, isEmpty, ofArray, item, singleton as singleton_1 } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj, equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { toLocalTime, day, month, year, create, fromDate } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { getLocalizationContext, Localized_formatDateTime, t } from "../Localization.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { map, empty, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { UserIdModule_toString, UserIdModule_systemRobot } from "../bidflow/src/infrastructure/Auction.js";
import { Dates_endDateTimeSpanZero, Dates_monthAgoTimeSpanZero, Currency_formatN, Currency_format } from "../Common.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { Locale, Disabled, OnManyChanged, ClassName, DateFormat, SelectionMode, custom, flatpickr } from "../fable_modules/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import { map as map_2, defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { loader } from "../Loader.js";
import { renderPagination } from "../Components/Pagination.js";

export class State extends Record {
    constructor(Users, NonEUTotal, CurrentPage, Query, IsSave, HavePaidItems, Processing) {
        super();
        this.Users = Users;
        this.NonEUTotal = NonEUTotal;
        this.CurrentPage = (CurrentPage | 0);
        this.Query = Query;
        this.IsSave = IsSave;
        this.HavePaidItems = HavePaidItems;
        this.Processing = Processing;
    }
}

export function State$reflection() {
    return record_type("VATFreeReport.State", [], State, () => [["Users", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(VatFreeReport_User$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(VatFreeReport_User$reflection())]], [["ErrorValue", string_type]]]))], ["NonEUTotal", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(VatFreeReport_User$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", option_type(VatFreeReport_User$reflection())]], [["ErrorValue", string_type]]]))], ["CurrentPage", int32_type], ["Query", Common_PagingQuery$1$reflection(VatFreeReport_VatFreeFilter$reflection())], ["IsSave", bool_type], ["HavePaidItems", bool_type], ["Processing", Deferred$1$reflection(unit_type)]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadReport", "LoadNonEUTotal", "PageChanged", "OnColumnClicked", "DatePeriodChanged"];
    }
}

export function Msg$reflection() {
    return union_type("VATFreeReport.Msg", [], Msg, () => [[["Item1", Common_PagingQuery$1$reflection(VatFreeReport_VatFreeFilter$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(VatFreeReport_User$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(VatFreeReport_User$reflection())]], [["ErrorValue", string_type]]]))]], [["Item1", Common_PagingQuery$1$reflection(VatFreeReport_VatFreeFilter$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(VatFreeReport_User$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", option_type(VatFreeReport_User$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]], [["Item", string_type]], [["Item", list_type(class_type("System.DateTime"))]]]);
}

export function loadReport(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(vatFreeReportApi().get(query)), (_arg1) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(0, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function loadNonEUTotal(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(vatFreeReportApi().getNonEUTotal(query)), (_arg1) => singleton.Return(new Msg(1, query, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(1, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(query) {
    return [new State(new Deferred$1(0), new Deferred$1(0), 1, query, false, false, new Deferred$1(0)), Cmd_batch(singleton_1(Cmd_OfFunc_result(new Msg(0, query, new AsyncOperationStatus$1(0)))))];
}

export function update(msg, state) {
    let s;
    if (msg.tag === 1) {
        if (msg.fields[1].tag === 1) {
            if (!equals(msg.fields[0], state.Query)) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.Users, new Deferred$1(2, msg.fields[1].fields[0]), state.CurrentPage, state.Query, state.IsSave, state.HavePaidItems, state.Processing), Cmd_none()];
            }
        }
        else {
            return [new State(state.Users, new Deferred$1(1), state.CurrentPage, state.Query, state.IsSave, state.HavePaidItems, state.Processing), Cmd_fromAsync(loadNonEUTotal(msg.fields[0]))];
        }
    }
    else if (msg.tag === 2) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Common_PagingQuery$1(inputRecord.Payload, msg.fields[0], inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(state.Users, state.NonEUTotal, msg.fields[0], newQuery, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 3) {
        let newQuery_1;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_1 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, inputRecord_1.Page, inputRecord_1.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_1.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_2 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, inputRecord_2.Page, inputRecord_2.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_2.LocalizationContext));
                break;
            }
        }
        return [new State(state.Users, state.NonEUTotal, state.CurrentPage, newQuery_1, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 4) {
        const startDate = fromDate(item(0, msg.fields[0]));
        const endDate = fromDate(item(1, msg.fields[0]));
        let newQuery_2;
        const inputRecord_4 = state.Query;
        newQuery_2 = (new Common_PagingQuery$1(new VatFreeReport_VatFreeFilter(create(year(startDate), month(startDate), day(startDate), 0, 0, 0, 0), create(year(endDate), month(endDate), day(endDate), 23, 59, 59, 0)), inputRecord_4.Page, inputRecord_4.PageSize, inputRecord_4.SortBy, inputRecord_4.LocalizationContext));
        return [new State(state.Users, state.NonEUTotal, state.CurrentPage, newQuery_2, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(0, newQuery_2, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.fields[1].tag === 1) {
        if (!equals(msg.fields[0], state.Query)) {
            return [state, Cmd_none()];
        }
        else {
            return [new State(new Deferred$1(2, msg.fields[1].fields[0]), state.NonEUTotal, state.CurrentPage, state.Query, state.IsSave, state.HavePaidItems, state.Processing), Cmd_OfFunc_result(new Msg(1, msg.fields[0], new AsyncOperationStatus$1(0)))];
        }
    }
    else {
        return [new State(new Deferred$1(1), state.NonEUTotal, state.CurrentPage, state.Query, state.IsSave, state.HavePaidItems, state.Processing), Cmd_fromAsync(loadReport(msg.fields[0]))];
    }
}

export const tableHeader = (() => {
    let props_16, props, props_2, props_4, props_6, props_8, props_10, props_12;
    const props_18 = ofArray([["className", "is-fluid"], ["className", "mb-2"], ["className", "px-3"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-vcentered"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-1"], ["children", t("common.id")]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-1"], ["children", t("users.vatNumber")]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = singleton_1(["children", t("common.name")]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-2"], ["children", t("common.address")]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-2"], ["children", t("orders.sumEx")]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-2"], ["children", t("orders.sumInc")]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-2"], ["children", t("orders.total")]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "lastColumn"]))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_16))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_18)));
})();

export function ClipboardText(clipboardTextInputProps) {
    const text = clipboardTextInputProps.text;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisibleTooltip = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setVisibleTooltipSuccess = patternInput_1[1];
    return createElement("span", createObj(toList(delay(() => append(singleton_2(["style", {
        position: "relative",
        wordBreak: "break-all",
    }]), delay(() => append(singleton_2(["onClick", (e) => {
        e.stopPropagation();
        setVisibleTooltipSuccess(false);
        const textField = document.createElement("textarea");
        textField.innerText = text;
        document.body.appendChild(textField);
        const textField_1 = textField;
        textField_1.select();
        if (document.execCommand("copy")) {
            setVisibleTooltipSuccess(true);
        }
        else {
            setVisibleTooltipSuccess(false);
        }
        textField_1.remove();
    }]), delay(() => append(singleton_2(["onMouseEnter", (_arg1) => {
        setVisibleTooltip(true);
    }]), delay(() => append(singleton_2(["onMouseLeave", (_arg2) => {
        setVisibleTooltip(false);
        setVisibleTooltipSuccess(false);
    }]), delay(() => append(singleton_2(["children", text]), delay(() => append(patternInput[0] ? append(singleton_2(["style", {
        borderBottom: (((1 + "px ") + "dashed") + " ") + "#808080",
    }]), delay(() => singleton_2(["data-tooltip", t("common.copyToClipboard")]))) : empty(), delay(() => (patternInput_1[0] ? append(singleton_2(["data-tooltip", t("common.copyToClipboardSuccess")]), delay(() => singleton_2(["className", "has-tooltip-primary"]))) : empty())))))))))))))))));
}

export function TableLine(tableLineInputProps) {
    let props, props_2, props_4, props_6, props_8;
    const user = tableLineInputProps.user;
    const euFlag = tableLineInputProps.euFlag;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const isVisible = patternInput[0];
    let userDetailsLineHeader;
    const props_10 = ofArray([["className", "is-vcentered"], ["className", "has-text-grey"], ["style", {
        fontSize: 10 + "px",
    }], ["className", "is-uppercase"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-1"], ["className", "has-text-left"], ["children", t("common.orderNumber")]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "is-2"], ["className", "has-text-left"], ["children", t("common.date")]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "is-2"], ["className", "has-text-left"], ["children", t("orders.sumEx")]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_6 = ofArray([["className", "is-2"], ["className", "has-text-left"], ["children", t("orders.sumInc")]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = ofArray([["className", "is-2"], ["className", "has-text-left"], ["children", t("orders.total")]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]);
    userDetailsLineHeader = createElement("div", createObj(Helpers_combineClasses("columns", props_10)));
    const props_50 = toList(delay(() => append(singleton_2(["className", "py-3"]), delay(() => append(singleton_2(["className", "px-3"]), delay(() => append(isVisible ? singleton_2(["className", "pb-5"]) : empty(), delay(() => append(singleton_2(["className", "mb-2"]), delay(() => append(singleton_2(["style", {
        borderRadius: 4,
    }]), delay(() => append(singleton_2(["className", "is-size-6"]), delay(() => append(singleton_2(["className", "is-fluid"]), delay(() => append(singleton_2(["className", "has-background-white"]), delay(() => append(euFlag ? singleton_2(["onClick", (_arg1) => {
        patternInput[1](!isVisible);
    }]) : empty(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_46, props_24, props_26, props_28, props_30, props_32, props_34, props_36, props_44;
        return append(singleton_2((props_46 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "is-1"], ["children", equals_1(user.Id, UserIdModule_systemRobot) ? "" : UserIdModule_toString(user.Id)]]), createElement("div", createObj(Helpers_combineClasses("column", props_24)))), (props_26 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
            text: user.VatNumber,
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_26)))), (props_28 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
            text: user.Name,
        })])]), createElement("div", createObj(Helpers_combineClasses("column", props_28)))), (props_30 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
            text: user.Address,
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_30)))), (props_32 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
            text: Currency_format(user.SumEx),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_32)))), (props_34 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
            text: Currency_format(user.SumInc),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_34)))), (props_36 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
            text: Currency_format(user.Total),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_36)))), (props_44 = toList(delay(() => append(singleton_2(["className", "is-narrow"]), delay(() => {
            let props_42;
            return euFlag ? append(singleton_2(["style", {
                cursor: "pointer",
            }]), delay(() => {
                let props_39;
                return singleton_2(["children", Interop_reactApi.Children.toArray([(props_39 = ofArray([["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", toList(delay(() => append(singleton_2("fa"), delay(() => (isVisible ? singleton_2("fa-angle-up") : singleton_2("fa-angle-down"))))))),
                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_39))))])]);
            })) : singleton_2(["children", Interop_reactApi.Children.toArray([(props_42 = ofArray([["className", "has-text-white"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", toList(delay(() => append(singleton_2("fa"), delay(() => (isVisible ? singleton_2("fa-angle-up") : singleton_2("fa-angle-down"))))))),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_42))))])]);
        })))), createElement("div", createObj(Helpers_combineClasses("column", props_44))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_46))))), delay(() => {
            let props_48;
            return isVisible ? singleton_2((props_48 = ofArray([["className", "has-background-light"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(userDetailsLineHeader), delay(() => map((order_1) => {
                let props_12, props_14, props_16, props_18, props_20;
                const order = order_1;
                const props_22 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
                    text: (order.OrderType.tag === 1) ? t("common.sell") : t("common.buy"),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
                    text: Localized_formatDateTime("d MMM yyyy", order.CreationDate),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
                    text: Currency_formatN(order.SumEx),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_18 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
                    text: Currency_formatN(order.SumInc),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), (props_20 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([createElement(ClipboardText, {
                    text: Currency_formatN(order.Total),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))])]);
                return createElement("div", createObj(Helpers_combineClasses("columns", props_22)));
            }, user.Orders)))))))]]), createElement("div", createObj(Helpers_combineClasses("box", props_48))))) : empty();
        }));
    }))))])))))))))))))))))))));
    return createElement("div", createObj(Helpers_combineClasses("container", props_50)));
}

export function PrintPresale(printPresaleInputProps) {
    let props_3;
    const users = printPresaleInputProps.users;
    const query = printPresaleInputProps.query;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput[1];
    const generateReport = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setIsLoading(true);
        return singleton.Bind(vatFreeReportApi().generateVatFreeReport(query), (_arg1) => {
            window.open(_arg1);
            setIsLoading(false);
            return singleton.Return();
        });
    }), (_arg2) => {
        setIsLoading(false);
        return singleton.Zero();
    }));
    const props_5 = ofArray([["className", "has-text-right"], ["className", "is-narrow"], ["style", {
        alignSelf: "flex-end",
    }], ["className", "no-height-section-to-print"], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => ((users.tag === 1) ? singleton_2(["className", "is-loading"]) : ((users.tag === 2) ? ((users.fields[0].tag === 0) ? append(isEmpty(users.fields[0].fields[0].Result) ? singleton_2(["disabled", true]) : singleton_2(["onClick", (_arg1_1) => {
        startImmediate(generateReport);
    }]), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(patternInput[0] ? singleton_2(["className", "is-loading"]) : empty(), delay(() => {
        let elms, s;
        return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-print"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (s = t("common.print"), createElement("span", {
            className: "",
            children: s,
        }))])]);
    })))))) : singleton_2(["className", "is-loading"])) : singleton_2(["className", "is-loading"]))))), createElement("a", createObj(Helpers_combineClasses("button", props_3))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("column", props_5)));
}

export function Render() {
    let props_1, s, props_11;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(new Common_PagingQuery$1(new VatFreeReport_VatFreeFilter(Dates_monthAgoTimeSpanZero, Dates_endDateTimeSpanZero), 1, 20, void 0, getLocalizationContext())), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_13 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("reports.VATWithinEU"), createElement("h1", {
        className: "title",
        children: s,
    }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_1)))), (props_11 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_5, props_3;
        return append(singleton_2((props_5 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_3 = singleton_1(["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([custom("value", toArray(map_1((x) => toLocalTime(x), ofArray([state_1.Query.Payload.StartDate, state_1.Query.Payload.EndDate]))), false), SelectionMode("range"), DateFormat("d M Y"), custom("locale", {
            firstDayOfWeek: 1,
        }, true), ClassName("input"), OnManyChanged((period) => {
            let pattern_matching_result;
            if (!isEmpty(period)) {
                if (isEmpty(tail(period))) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    break;
                }
                case 1: {
                    dispatch(new Msg(4, period));
                    break;
                }
            }
        }), Disabled(false), Locale(Swedish)]))])]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), createElement(PrintPresale, {
            query: state_1.Query,
            users: state_1.Users,
        })])]), createElement("div", createObj(Helpers_combineClasses("columns", props_5))))), delay(() => append(singleton_2(tableHeader), delay(() => {
            let matchValue;
            return append((matchValue = state_1.NonEUTotal, (matchValue.tag === 1) ? singleton_2(null) : ((matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? singleton_2(defaultArg(map_2((user) => createElement(TableLine, {
                euFlag: false,
                user: user,
            }), matchValue.fields[0].fields[0]), null)) : singleton_2(createElement("h1", {
                children: [matchValue.fields[0].fields[0]],
            }))) : singleton_2(null))), delay(() => {
                let props_9;
                const matchValue_1 = state_1.Users;
                let pattern_matching_result_1, ex_1, users;
                if (matchValue_1.tag === 1) {
                    pattern_matching_result_1 = 0;
                }
                else if (matchValue_1.tag === 2) {
                    if (matchValue_1.fields[0].tag === 0) {
                        pattern_matching_result_1 = 2;
                        users = matchValue_1.fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                        ex_1 = matchValue_1.fields[0].fields[0];
                    }
                }
                else {
                    pattern_matching_result_1 = 0;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return singleton_2(loader("small"));
                    }
                    case 1: {
                        return singleton_2(createElement("h1", {
                            children: [ex_1],
                        }));
                    }
                    case 2: {
                        return append(isEmpty(users.Result) ? singleton_2((props_9 = ofArray([["className", "py-5"], ["className", "is-size-6"], ["className", "is-uppercase"], ["className", "has-text-centered"], ["children", t("reports.noInformation")]]), createElement("p", createObj(Helpers_combineClasses("", props_9))))) : empty(), delay(() => append(map((user_1) => createElement(TableLine, {
                            euFlag: true,
                            user: user_1,
                        }), users.Result), delay(() => singleton_2(renderPagination(users.Page, users.Total, users.PageSize, (arg) => {
                            dispatch(new Msg(2, arg));
                        }))))));
                    }
                }
            }));
        }))));
    }))))]), createElement("section", createObj(Helpers_combineClasses("section", props_11))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_13)));
}

