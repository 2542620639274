import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { AuctionModeType, BasicInformation_name, AuctionQueryFilters, CreateAuctionInfo, AuctionQueryFilters$reflection, AuctionsEntity$reflection } from "./Shared/Shared.js";
import { Common_Sorting, Common_SortingModule_toggle, Common_Auction_findNextAuctionDate, Common_PagingQuery$1, Common_PagingQuery$1$reflection, Common_PagingResult$1$reflection } from "./Shared/Common.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52, Deferred$1, Deferred$1$reflection } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { lambda_type, unit_type, record_type, int32_type, option_type, union_type, string_type, class_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { toLocalTime, now, fromDate } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { date as date_4, fromDateTimeOffset, second, minute, hour, day, month, year as year_1, create } from "./fable_modules/fable-library.3.6.1/Date.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi, auctionApi } from "./Communication.js";
import { t, Localized_translate, getLocalizationContext } from "./Localization.js";
import { Cmd_none, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { createObj, equals } from "./fable_modules/fable-library.3.6.1/Util.js";
import { AuctionResellLotState, toPath, Route, AuctionArg, AuctionState, navigateTo } from "./Router.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map, empty, append, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { cons, head, singleton as singleton_2, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { startImmediate, sleep } from "./fable_modules/fable-library.3.6.1/Async.js";
import { sv } from "date-fns/locale";
import { format as format_3 } from "date-fns";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { AuctionIdModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { Render } from "./WorkDepot.js";
import { loader } from "./Loader.js";
import { renderPagination } from "./Components/Pagination.js";
import { TimeTwentyFour, EnableTimePicker, HideCalendar, Placeholder, Locale, ClassName, OnChange, custom, DateFormat, Value, flatpickr } from "./fable_modules/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import { value as value_195 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Render as Render_1 } from "./AuctionResellLots.js";

export class State extends Record {
    constructor(LoadedAuctions, CreatedAuction, SelectedDate, SelectedTime, Name, Error$, CurrentPage, SearchString, Query) {
        super();
        this.LoadedAuctions = LoadedAuctions;
        this.CreatedAuction = CreatedAuction;
        this.SelectedDate = SelectedDate;
        this.SelectedTime = SelectedTime;
        this.Name = Name;
        this.Error = Error$;
        this.CurrentPage = (CurrentPage | 0);
        this.SearchString = SearchString;
        this.Query = Query;
    }
}

export function State$reflection() {
    return record_type("Auctions.State", [], State, () => [["LoadedAuctions", Deferred$1$reflection(Common_PagingResult$1$reflection(AuctionsEntity$reflection()))], ["CreatedAuction", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", string_type]]]))], ["SelectedDate", class_type("System.DateTime")], ["SelectedTime", class_type("System.DateTime")], ["Name", string_type], ["Error", option_type(string_type)], ["CurrentPage", int32_type], ["SearchString", string_type], ["Query", Common_PagingQuery$1$reflection(AuctionQueryFilters$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateSelectedDate", "UpdateSelectedTime", "AuctionNameChanged", "LoadAuctions", "CreateAuction", "PagingGoto", "OnColumnClicked", "SearchNameAuctionChange", "KeyboardEvent", "SearchEnter"];
    }
}

export function Msg$reflection() {
    return union_type("Auctions.Msg", [], Msg, () => [[["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", string_type]], [["Item1", Common_PagingQuery$1$reflection(AuctionQueryFilters$reflection())], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Common_PagingResult$1$reflection(AuctionsEntity$reflection()), class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", Common_PagingResult$1$reflection(AuctionsEntity$reflection())]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item1", lambda_type(unit_type, unit_type)], ["Item2", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", Common_PagingQuery$1$reflection(AuctionQueryFilters$reflection())]]]);
}

export function Cmd_createAuction(close, state) {
    let copyOfStruct;
    const info = new CreateAuctionInfo(state.Name, fromDate(create(year_1(state.SelectedDate), month(state.SelectedDate), day(state.SelectedDate), hour(state.SelectedTime), minute(state.SelectedTime), second(state.SelectedTime)), (copyOfStruct = now(), copyOfStruct.offset)));
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().createAuction(info)), (_arg1) => singleton.Return(new Msg(4, close, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(4, close, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_loadData(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAuctions(query)), (_arg1) => singleton.Return(new Msg(3, query, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(3, query, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export function init(page, settings) {
    let Name;
    const query = new Common_PagingQuery$1(new AuctionQueryFilters(""), 1, 20, void 0, getLocalizationContext());
    const date = Common_Auction_findNextAuctionDate(now(), settings.InfoAuctionSettings.AuctionStartDay, settings.InfoAuctionSettings.AuctionStartTime);
    return [(Name = Localized_translate(BasicInformation_name(settings.InfoAuctionSettings.BasicInformation)), new State(new Deferred$1(0), new Deferred$1(0), fromDateTimeOffset(date, 0), fromDateTimeOffset(date, 0), Name, void 0, page, "", query)), Cmd_OfFunc_result(new Msg(3, query, new AsyncOperationStatus$1(0)))];
}

export function update(msg, state) {
    let s;
    if (msg.tag === 1) {
        return [new State(state.LoadedAuctions, state.CreatedAuction, state.SelectedDate, msg.fields[0], state.Name, state.Error, state.CurrentPage, state.SearchString, state.Query), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new State(state.LoadedAuctions, state.CreatedAuction, state.SelectedDate, state.SelectedTime, msg.fields[0], state.Error, state.CurrentPage, state.SearchString, state.Query), Cmd_none()];
    }
    else if (msg.tag === 3) {
        if (msg.fields[1].tag === 1) {
            if (!equals(msg.fields[0], state.Query)) {
                return [state, Cmd_none()];
            }
            else if (msg.fields[1].fields[0].tag === 1) {
                return [new State(new Deferred$1(3, msg.fields[1].fields[0].fields[0]), state.CreatedAuction, state.SelectedDate, state.SelectedTime, state.Name, state.Error, state.CurrentPage, state.SearchString, state.Query), Cmd_none()];
            }
            else {
                return [new State(new Deferred$1(2, msg.fields[1].fields[0].fields[0]), state.CreatedAuction, state.SelectedDate, state.SelectedTime, state.Name, state.Error, state.CurrentPage, state.SearchString, state.Query), Cmd_none()];
            }
        }
        else {
            return [new State(new Deferred$1(1), state.CreatedAuction, state.SelectedDate, state.SelectedTime, state.Name, state.Error, state.CurrentPage, state.SearchString, state.Query), Cmd_fromAsync(Cmd_loadData(msg.fields[0]))];
        }
    }
    else if (msg.tag === 4) {
        if (msg.fields[1].tag === 1) {
            if (msg.fields[1].fields[0].tag === 1) {
                return [new State(state.LoadedAuctions, new Deferred$1(2, msg.fields[1].fields[0]), state.SelectedDate, state.SelectedTime, state.Name, msg.fields[1].fields[0].fields[0], state.CurrentPage, state.SearchString, state.Query), Cmd_none()];
            }
            else {
                msg.fields[0]();
                return [new State(state.LoadedAuctions, new Deferred$1(2, msg.fields[1].fields[0]), state.SelectedDate, state.SelectedTime, state.Name, void 0, state.CurrentPage, state.SearchString, state.Query), navigateTo(new Route(2, new AuctionArg(0, msg.fields[1].fields[0].fields[0], new AuctionState(false, false, 1))))];
            }
        }
        else {
            return [new State(state.LoadedAuctions, new Deferred$1(1), state.SelectedDate, state.SelectedTime, state.Name, state.Error, state.CurrentPage, state.SearchString, state.Query), Cmd_fromAsync(Cmd_createAuction(msg.fields[0], state))];
        }
    }
    else if (msg.tag === 5) {
        let newQuery;
        const inputRecord = state.Query;
        newQuery = (new Common_PagingQuery$1(inputRecord.Payload, msg.fields[0], inputRecord.PageSize, inputRecord.SortBy, inputRecord.LocalizationContext));
        return [new State(state.LoadedAuctions, state.CreatedAuction, state.SelectedDate, state.SelectedTime, state.Name, state.Error, msg.fields[0], state.SearchString, newQuery), Cmd_OfFunc_result(new Msg(3, newQuery, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 6) {
        let newQuery_1;
        const matchValue = state.Query.SortBy;
        let pattern_matching_result, newX_1, s_1;
        if (matchValue != null) {
            if ((s = matchValue[1], msg.fields[0] === matchValue[0])) {
                pattern_matching_result = 0;
                newX_1 = matchValue[0];
                s_1 = matchValue[1];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const inputRecord_1 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_1.Payload, inputRecord_1.Page, inputRecord_1.PageSize, [msg.fields[0], Common_SortingModule_toggle(s_1)], inputRecord_1.LocalizationContext));
                break;
            }
            case 1: {
                const inputRecord_2 = state.Query;
                newQuery_1 = (new Common_PagingQuery$1(inputRecord_2.Payload, inputRecord_2.Page, inputRecord_2.PageSize, [msg.fields[0], new Common_Sorting(0)], inputRecord_2.LocalizationContext));
                break;
            }
        }
        return [new State(state.LoadedAuctions, state.CreatedAuction, state.SelectedDate, state.SelectedTime, state.Name, state.Error, state.CurrentPage, state.SearchString, newQuery_1), Cmd_OfFunc_result(new Msg(3, newQuery_1, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 7) {
        if (msg.fields[0] === "") {
            return [new State(state.LoadedAuctions, state.CreatedAuction, state.SelectedDate, state.SelectedTime, state.Name, state.Error, state.CurrentPage, msg.fields[0], state.Query), Cmd_OfFunc_result(new Msg(8, "Enter"))];
        }
        else {
            return [new State(state.LoadedAuctions, state.CreatedAuction, state.SelectedDate, state.SelectedTime, state.Name, state.Error, state.CurrentPage, msg.fields[0], state.Query), Cmd_none()];
        }
    }
    else if (msg.tag === 9) {
        return [new State(state.LoadedAuctions, state.CreatedAuction, state.SelectedDate, state.SelectedTime, state.Name, state.Error, 1, state.SearchString, state.Query), Cmd_OfFunc_result(new Msg(3, state.Query, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0] === "Enter") {
            let newQuery_2;
            const query_2 = state.Query;
            newQuery_2 = (new Common_PagingQuery$1(new AuctionQueryFilters(state.SearchString.toLowerCase().trim()), 1, query_2.PageSize, query_2.SortBy, query_2.LocalizationContext));
            return [new State(state.LoadedAuctions, state.CreatedAuction, state.SelectedDate, state.SelectedTime, state.Name, state.Error, state.CurrentPage, state.SearchString, newQuery_2), Cmd_OfFunc_result(new Msg(9, newQuery_2))];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new State(state.LoadedAuctions, state.CreatedAuction, msg.fields[0], state.SelectedTime, state.Name, state.Error, state.CurrentPage, state.SearchString, state.Query), Cmd_none()];
    }
}

export function tableHeader(state, dispatch) {
    let props_22, props_8, props_10, props_12, props_14, props_16, props_18;
    const sortingColumnButton = (columnCaption, columnName) => createElement("a", {
        onClick: (x) => {
            dispatch(new Msg(6, columnName));
            x.preventDefault();
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = state.Query.SortBy;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue[1].tag === 0) {
                    if (columnName === matchValue[0]) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_1(createElement("div", {
                        className: "has-text-primary",
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: columnCaption,
                        }), createElement("i", {
                            className: join(" ", ["fas", "fa-chevron-down", "ml-1"]),
                        })]),
                    }));
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue != null) {
                        if (matchValue[1].tag === 1) {
                            if (columnName === matchValue[0]) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            return singleton_1(createElement("div", {
                                className: "has-text-primary",
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: columnCaption,
                                }), createElement("i", {
                                    className: join(" ", ["fas", "fa-chevron-up", "ml-1"]),
                                })]),
                            }));
                        }
                        case 1: {
                            return singleton_1(createElement("span", {
                                children: columnCaption,
                            }));
                        }
                    }
                }
            }
        })))),
    });
    return createElement("div", {
        style: {
            paddingLeft: 1 + "rem",
        },
        children: Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "px-0"], ["className", "mx-0"], ["className", "my-0"], ["className", "py-0"], ["className", "is-vcentered"], ["className", "is-mobile"], ["className", "tableHeader"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.auctionName"), "Name")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), (props_10 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.auctionDate"), "Date")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10)))), (props_12 = ofArray([["className", "is-1"], ["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.auctionTime"), "Date")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_14 = ofArray([["className", "is-1"], ["className", "px-0"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("auction.lotsAmount"), "NumberOfLots")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_16 = ofArray([["className", "is-2"], ["className", "has-text-centered"], ["children", t("auction.published")]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_18 = ofArray([["className", "is-1"], ["className", "px-0"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([sortingColumnButton(t("common.status"), "Status")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_18)))), createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-2"]))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_22))))]),
    });
}

export function RestartAuction(restartAuctionInputProps) {
    const auctionId = restartAuctionInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setStartLiveBiddingInProgress = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const startLiveBiddingError = patternInput_1[0];
    const setStartLiveBiddingError = patternInput_1[1];
    const startLiveBidding = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        setStartLiveBiddingInProgress(true);
        setStartLiveBiddingError("");
        return singleton.Bind(sleep(1000), () => singleton.Bind(auctionApi().startLiveBidding(auctionId), () => {
            setStartLiveBiddingInProgress(false);
            return singleton.Zero();
        }));
    }), (_arg3) => {
        setStartLiveBiddingError(_arg3.message);
        setStartLiveBiddingInProgress(false);
        return singleton.Zero();
    }));
    const props_2 = toList(delay(() => append(patternInput[0] ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["onClick", (_arg1_1) => {
        startImmediate(startLiveBidding);
    }]), delay(() => append((startLiveBiddingError !== "") ? append(singleton_1(["data-tooltip", startLiveBiddingError]), delay(() => append(singleton_1(["className", "has-tooltip-warning"]), delay(() => singleton_1(["className", "is-warning"]))))) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("span", {
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fas", "fa-play"]),
        })]),
    })])])))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props_2)));
}

export function renderItems(auction) {
    let props_24, props, props_6, props_2, props_4, props_8, props_13, props_16, props_22, props_19;
    return createElement("div", {
        className: "is-size-6",
        style: {
            marginBottom: 0.5 + "rem",
            paddingLeft: 1 + "rem",
        },
        className: "has-background-white",
        children: Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "px-0"], ["className", "mx-0"], ["className", "my-0"], ["className", "py-0"], ["className", "is-size-6"], ["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-text-overflow-hidden"], ["className", "is-3"], ["className", "is-uppercase"], ["children", Localized_translate(auction.Name)]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_6 = ofArray([["className", "is-2"], ["children", Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => {
            let date, locale, formatOptions;
            return singleton_1(["children", (date = toLocalTime(auction.Date), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_3(date, "dd MMMM ", formatOptions)))))]);
        })), createElement("span", createObj(Helpers_combineClasses("", props_2)))), (props_4 = toList(delay(() => append(singleton_1(["className", "has-text-grey"]), delay(() => {
            let date_2, locale_1, formatOptions_1;
            return singleton_1(["children", (date_2 = toLocalTime(auction.Date), (locale_1 = sv, (formatOptions_1 = {}, (formatOptions_1.locale = locale_1, format_3(date_2, "yyyy", formatOptions_1)))))]);
        })))), createElement("span", createObj(Helpers_combineClasses("", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_8 = toList(delay(() => append(singleton_1(["className", "is-1"]), delay(() => append(singleton_1(["className", "has-text-centered"]), delay(() => {
            let date_3, locale_2, formatOptions_2;
            return singleton_1(["children", (date_3 = toLocalTime(auction.Date), (locale_2 = sv, (formatOptions_2 = {}, (formatOptions_2.locale = locale_2, format_3(date_3, "HH:mm", formatOptions_2)))))]);
        })))))), createElement("div", createObj(Helpers_combineClasses("column", props_8)))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-1"], ["className", "px-0"], ["className", "has-text-right"], ["children", auction.NumberOfLots]])))), (props_13 = ofArray([["className", "is-2"], ["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(toList(delay(() => {
            const matchValue = auction.Status;
            switch (matchValue.tag) {
                case 3:
                case 0:
                case 1: {
                    return singleton_1(["className", join(" ", ["fa", "fa-check", "has-text-primary"])]);
                }
                default: {
                    return singleton_1(["className", join(" ", ["fa", "fa-times", "has-text-danger"])]);
                }
            }
        }))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_16 = ofArray([["className", "is-1"], ["className", "has-text-left"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([createElement("p", createObj(toList(delay(() => {
            const matchValue_1 = auction.Status;
            switch (matchValue_1.tag) {
                case 2: {
                    return append(singleton_1(["className", "has-text-primary"]), delay(() => singleton_1(["children", t("auction.auctionStatus.planned")])));
                }
                case 1: {
                    return append(singleton_1(["className", "has-text-primary"]), delay(() => singleton_1(["children", t("auction.published")])));
                }
                case 3: {
                    return append(singleton_1(["className", "has-text-primary"]), delay(() => singleton_1(["children", t("auction.auctionStatus.liveAuction")])));
                }
                default: {
                    return append(singleton_1(["children", t("auction.auctionStatus.finished")]), delay(() => singleton_1(["className", "has-text-grey"])));
                }
            }
        }))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))), (props_22 = ofArray([["className", "is-2"], ["className", "has-background-grey-lighter"], ["className", "py-0"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: "edit_button_href",
            href: toPath(new Route(2, new AuctionArg(0, auction.Id, new AuctionState(false, false, 1)))),
            children: Interop_reactApi.Children.toArray([(props_19 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-sign-in-alt", "fa-lg"]),
            })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_19))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_24))))]),
    });
}

export function RenderAuction(renderAuctionInputProps) {
    const page = renderAuctionInputProps.page;
    const pageType = renderAuctionInputProps.pageType;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(page, renderAuctionInputProps.settings), (msg, state) => update(msg, state), [page]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const setVisible = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(pageType);
    const typeMode = patternInput_2[0];
    const setTypeMode = patternInput_2[1];
    const elms_7 = toList(delay(() => {
        let props_2, s, s_1;
        return append(singleton_1((props_2 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("common.auctions"), createElement("h1", {
            className: "title",
            children: s,
        })), (s_1 = t("auction.auctionsPageSubtitle"), createElement("h2", {
            className: "subtitle",
            children: s_1,
        }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_2))))), delay(() => {
            let props_16, props_14, children, props_5, props_8, props_11;
            return append(singleton_1((props_16 = ofArray([["className", "pt-0"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-uppercase"], ["className", "is-centered"], ["className", "is-toggle"], ["className", "my-0"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_5 = toList(delay(() => append(equals(typeMode, new AuctionModeType(0)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("common.auctions"),
                onClick: (_arg1) => {
                    setTypeMode(new AuctionModeType(0));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_5)))), (props_8 = toList(delay(() => append(equals(typeMode, new AuctionModeType(1)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("auction.resale"),
                onClick: (_arg2) => {
                    setTypeMode(new AuctionModeType(1));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_8)))), (props_11 = toList(delay(() => append(equals(typeMode, new AuctionModeType(2)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("auction.workDepot"),
                onClick: (_arg3) => {
                    setTypeMode(new AuctionModeType(2));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_11))))]), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_14))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_16))))), delay(() => {
                let props_39, elms_1, props_26, elms, props_23, props_18, props_21, props_36, props_34, props_32, props_29, s_2;
                switch (typeMode.tag) {
                    case 2: {
                        let auctionId_1;
                        const matchValue_1 = state_1.LoadedAuctions;
                        switch (matchValue_1.tag) {
                            case 1: {
                                auctionId_1 = AuctionIdModule_empty;
                                break;
                            }
                            case 3: {
                                auctionId_1 = AuctionIdModule_empty;
                                break;
                            }
                            case 2: {
                                auctionId_1 = head(matchValue_1.fields[0].Result).Id;
                                break;
                            }
                            default: {
                                auctionId_1 = AuctionIdModule_empty;
                            }
                        }
                        return singleton_1(createElement(Render, {
                            auctionId: auctionId_1,
                            page: 1,
                        }));
                    }
                    case 0: {
                        return append(singleton_1((props_39 = ofArray([["className", "py-0"], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_26 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_2((props_23 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "searchInput"], ["placeholder", t("auction.auctionName")], ["onChange", (ev) => {
                            dispatch(new Msg(7, ev.target.value));
                        }], ["onKeyUp", (ev_1) => {
                            dispatch(new Msg(8, ev_1.key));
                        }]]), createElement("input", createObj(cons(["type", "search"], Helpers_combineClasses("input", props_18))))), (props_21 = ofArray([["className", "is-left"], ["style", {
                            height: 100 + "%",
                        }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-search", "fa-lg"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_21))))])]]), createElement("p", createObj(Helpers_combineClasses("control", props_23))))), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms)),
                        }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_26)))), (props_36 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_32 = ofArray([["className", "is-primary"], ["onClick", (_arg4) => {
                            setVisible("is-active");
                        }], ["children", Interop_reactApi.Children.toArray([(props_29 = ofArray([["className", "has-text-white"], ["className", "is-medium"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-plus"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_29)))), (s_2 = t("auction.createNewAuction"), createElement("span", {
                            className: "",
                            children: s_2,
                        }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_32))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_36))))]), createElement("div", {
                            className: "columns",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_39))))), delay(() => {
                            let props_43;
                            return append(singleton_1((props_43 = singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let value_98;
                                const matchValue_2 = state_1.LoadedAuctions;
                                switch (matchValue_2.tag) {
                                    case 1: {
                                        return singleton_1(loader("big"));
                                    }
                                    case 3: {
                                        return singleton_1((value_98 = matchValue_2.fields[0].message, createElement("div", {
                                            children: [value_98],
                                        })));
                                    }
                                    case 2: {
                                        const content_2 = matchValue_2.fields[0];
                                        return append(singleton_1(tableHeader(state_1, dispatch)), delay(() => {
                                            let children_4;
                                            return append(singleton_1((children_4 = toList(delay(() => map((auction) => renderItems(auction), content_2.Result))), createElement("div", {
                                                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                                            }))), delay(() => singleton_1(renderPagination(content_2.Page, content_2.Total, content_2.PageSize, (arg_1) => {
                                                dispatch(new Msg(5, arg_1));
                                            }))));
                                        }));
                                    }
                                    default: {
                                        return singleton_1(null);
                                    }
                                }
                            }))))]), createElement("section", createObj(Helpers_combineClasses("section", props_43))))), delay(() => {
                                let props_91, elms_6, elms_2, props_47, props_82, props_88, props_84, props_86;
                                return singleton_1((props_91 = ofArray([["className", patternInput_1[0]], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_2(["onClick", (_arg5) => {
                                    setVisible("");
                                }])))), (elms_6 = ofArray([(elms_2 = ofArray([(props_47 = ofArray([["children", t("auction.createNewAuction")], ["className", "is-size-4"], ["className", "my-2"]]), createElement("p", createObj(Helpers_combineClasses("modal-card-title", props_47)))), createElement("button", createObj(Helpers_combineClasses("delete", singleton_2(["onClick", (_arg6) => {
                                    setVisible("");
                                }]))))]), createElement("header", {
                                    className: "modal-card-head",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                                })), (props_82 = ofArray([["className", "has-background-light"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                    let props_78, props_76, props_57, props_52, elms_3, props_54, value_123, props_65, props_59, props_63, elms_4, props_73, props_67, props_71, elms_5;
                                    return append(singleton_1((props_78 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_76 = ofArray([["className", "is-two-thirds"], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
                                        disabled: equals(state_1.CreatedAuction, new Deferred$1(1)),
                                        children: Interop_reactApi.Children.toArray([(props_57 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_52 = singleton_2(["children", t("auction.auctionName")]), createElement("label", createObj(Helpers_combineClasses("label", props_52)))), (elms_3 = singleton_2((props_54 = ofArray([(value_123 = state_1.Name, ["ref", (e) => {
                                            if ((!(e == null)) ? (!equals(e.value, value_123)) : false) {
                                                e.value = value_123;
                                            }
                                        }]), ["className", "mr-5"], ["onChange", (ev_2) => {
                                            dispatch(new Msg(2, ev_2.target.value));
                                        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_54)))))), createElement("div", {
                                            className: "control",
                                            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                                        }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_57)))), (props_65 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_59 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["children", t("auction.auctionDate")]]), createElement("label", createObj(Helpers_combineClasses("label", props_59)))), (props_63 = ofArray([["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray([(elms_4 = singleton_2(createElement("i", {
                                            className: join(" ", ["fa", "fa-calendar"]),
                                        })), createElement("span", {
                                            className: "icon",
                                            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                                        })), flatpickr(ofArray([Value(date_4(state_1.SelectedDate)), DateFormat("d M Y"), custom("locale", {
                                            firstDayOfWeek: 1,
                                        }, true), OnChange((arg_3) => {
                                            dispatch(new Msg(0, arg_3));
                                        }), ClassName("input"), Locale(Swedish)]))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_63))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_65)))), (props_73 = ofArray([["className", "pb-4"], ["children", Interop_reactApi.Children.toArray([(props_67 = ofArray([["className", "has-text-left"], ["className", "mb-1"], ["children", t("auction.auctionTime")]]), createElement("label", createObj(Helpers_combineClasses("label", props_67)))), (props_71 = ofArray([["className", join(" ", ["has-icons-left"])], ["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_2(createElement("i", {
                                            className: join(" ", ["fa", "fa-clock"]),
                                        })), createElement("span", {
                                            className: "icon",
                                            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                                        })), flatpickr(ofArray([Value(state_1.SelectedTime), Placeholder(""), HideCalendar(true), EnableTimePicker(true), TimeTwentyFour(true), OnChange((arg_4) => {
                                            dispatch(new Msg(1, arg_4));
                                        }), ClassName("input"), Locale(Swedish)]))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_71))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_73))))]),
                                    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_76))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_78))))), delay(() => {
                                        let props_80;
                                        return (state_1.Error != null) ? singleton_1((props_80 = ofArray([["style", {
                                            color: "#FF0000",
                                        }], ["children", value_195(state_1.Error)]]), createElement("div", createObj(Helpers_combineClasses("", props_80))))) : empty();
                                    }));
                                }))))]]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_82)))), (props_88 = ofArray([["style", {
                                    justifyContent: "flex-end",
                                }], ["children", Interop_reactApi.Children.toArray([(props_84 = ofArray([["className", "is-dark"], ["disabled", equals(state_1.CreatedAuction, new Deferred$1(1))], ["onClick", (_arg7) => {
                                    setVisible("");
                                }], ["children", t("common.cancel")]]), createElement("button", createObj(Helpers_combineClasses("button", props_84)))), (props_86 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["onClick", (x) => {
                                    x.preventDefault();
                                    dispatch(new Msg(4, () => {
                                        setVisible("");
                                    }, new AsyncOperationStatus$1(0)));
                                }]), delay(() => (equals(state_1.CreatedAuction, new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : singleton_1(["children", t("common.create")])))))))), createElement("button", createObj(Helpers_combineClasses("button", props_86))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_88))))]), createElement("div", {
                                    className: "modal-card",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                                }))])]]), createElement("div", createObj(Helpers_combineClasses("modal", props_91)))));
                            }));
                        }));
                    }
                    default: {
                        let auctionId;
                        const matchValue = state_1.LoadedAuctions;
                        switch (matchValue.tag) {
                            case 1: {
                                auctionId = AuctionIdModule_empty;
                                break;
                            }
                            case 3: {
                                auctionId = AuctionIdModule_empty;
                                break;
                            }
                            case 2: {
                                auctionId = head(matchValue.fields[0].Result).Id;
                                break;
                            }
                            default: {
                                auctionId = AuctionIdModule_empty;
                            }
                        }
                        return singleton_1(createElement(Render_1, {
                            auctionId: auctionId,
                            auctionState: new AuctionResellLotState(false, false, false, 1),
                        }));
                    }
                }
            }));
        }));
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    });
}

export function Auctions(auctionsInputProps) {
    const page = auctionsInputProps.page;
    const pageType = auctionsInputProps.pageType;
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(settingsApi().getAuctionSettings()), []);
    switch (settings.tag) {
        case 1: {
            return loader("big");
        }
        case 3: {
            return settings.fields[0].message;
        }
        case 2: {
            return createElement(RenderAuction, {
                pageType: pageType,
                page: page,
                settings: settings.fields[0][0],
            });
        }
        default: {
            return loader("big");
        }
    }
}

