import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { ReservedPriceModule_fromString, ReservedPriceModule_toString, LotIdModule_fromString, LotIdModule_toString, LotIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { lotApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { singleton as singleton_2, cons, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";

export function Component() {
    let elms_6, elms_5, props_2, props_26;
    const patternInput = useFeliz_React__React_useState_Static_1505(LotIdModule_empty);
    const lotId = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const reservedPrice = patternInput_1[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const data = patternInput_2[0];
    const changeReservedPrice_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(lotApi().changeLotReservedPrice([lotId, reservedPrice])))), patternInput_2[1]);
    const elms_7 = singleton_2((elms_6 = ofArray([createElement("h2", {
        className: "title is-2",
        children: "Change reserved price for lot when bidding already started.",
    }), (elms_5 = ofArray([(props_2 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement("h5", {
        className: "title is-5",
        children: "Change reserved price allowed only when lot has created.Not allowed to change when lot state is in \u0027ActiveBidding\u0027, \u0027Withdrawn\u0027 or \u0027Finished\u0027.",
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_26 = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, s_2, elms, props_5, value_24;
        return append(singleton_1((elms_1 = ofArray([(s_2 = (`LotId id = ${lotId}`), createElement("label", {
            className: "label",
            children: s_2,
        })), (elms = singleton_2((props_5 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_24 = LotIdModule_toString(lotId), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_24)) : false) {
                e.value = value_24;
            }
        }]), ["onChange", (ev) => {
            patternInput[1](LotIdModule_fromString(ev.target.value));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_5)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let elms_3, s_3, elms_2, props_10, value_45;
            return append(singleton_1((elms_3 = ofArray([(s_3 = (`Reserved price = ${reservedPrice}`), createElement("label", {
                className: "label",
                children: s_3,
            })), (elms_2 = singleton_2((props_10 = ofArray([["required", true], ["placeholder", "Placeholder"], (value_45 = ReservedPriceModule_toString(reservedPrice), ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals(e_1.value, value_45)) : false) {
                    e_1.value = value_45;
                }
            }]), ["onChange", (ev_1) => {
                patternInput_1[1](ReservedPriceModule_fromString(ev_1.target.value));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_10)))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))), delay(() => {
                let props_15, props_19;
                return append((data.tag === 1) ? singleton_1(null) : ((data.tag === 2) ? ((data.fields[0].tag === 1) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("notification", ofArray([["className", "is-danger"], ["children", data.fields[0].fields[0]]]))))) : singleton_1((props_15 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", {
                    children: ["Done"],
                })])]), createElement("div", createObj(Helpers_combineClasses("notification", props_15)))))) : ((data.tag === 3) ? singleton_1((props_19 = ofArray([["className", "is-danger"], ["children", data.fields[0].message]]), createElement("div", createObj(Helpers_combineClasses("notification", props_19))))) : singleton_1(null))), delay(() => {
                    let props_24, elms_4, props_21;
                    return singleton_1((props_24 = ofArray([["className", "is-grouped"], ["className", "is-grouped-centered"], ["children", Interop_reactApi.Children.toArray([(elms_4 = singleton_2((props_21 = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(data) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", "Change reserved price"]), delay(() => singleton_1(["onClick", (_arg1) => {
                        changeReservedPrice_1();
                    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_21))))), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_24)))));
                }));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_26))))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))]), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    })));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    });
}

