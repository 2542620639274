import { append, singleton as singleton_1, empty, reverse, tail, head, cons, isEmpty } from "../fable-library.3.6.1/List.js";
import { ResultCE_result, ResultCE_ResultBuilder__BindReturn_Z2499A5D, ResultCE_ResultBuilder__Delay_Z4709C901 } from "./ResultCE.fs.js";
import { Result_MapError, FSharpResult$2, Result_Map, Result_Bind } from "../fable-library.3.6.1/Choice.js";
import { singleton } from "../fable-library.3.6.1/AsyncBuilder.js";
import { AsyncResultCE_asyncResult, AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD } from "./AsyncResultCE.fs.js";
import { returnError, mapError, retn } from "./AsyncResult.fs.js";

function traverseResultM$0027(state_mut, f_mut, xs_mut) {
    traverseResultM$0027:
    while (true) {
        const state = state_mut, f = f_mut, xs = xs_mut;
        if (!isEmpty(xs)) {
            const r = ResultCE_ResultBuilder__Delay_Z4709C901(ResultCE_result, () => Result_Bind((_arg1) => ResultCE_ResultBuilder__BindReturn_Z2499A5D(ResultCE_result, state, (_arg2) => cons(_arg1, _arg2)), f(head(xs))))();
            if (r.tag === 1) {
                return r;
            }
            else {
                state_mut = r;
                f_mut = f;
                xs_mut = tail(xs);
                continue traverseResultM$0027;
            }
        }
        else {
            return Result_Map((list) => reverse(list), state);
        }
        break;
    }
}

function traverseAsyncResultM$0027(state, f, xs) {
    if (!isEmpty(xs)) {
        return singleton.Delay(() => singleton.Bind(AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD(AsyncResultCE_asyncResult, () => singleton.Delay(() => singleton.Bind(state, (_arg1_1) => {
            const result_4 = _arg1_1;
            return (result_4.tag === 1) ? singleton.Return(new FSharpResult$2(1, result_4.fields[0])) : singleton.ReturnFrom(singleton.Bind(f(head(xs)), (arg_1) => singleton.Return(Result_Map((_arg2) => cons(_arg2, result_4.fields[0]), arg_1))));
        }))), (_arg3) => {
            const r = _arg3;
            return (r.tag === 1) ? singleton.Return(r) : singleton.ReturnFrom(traverseAsyncResultM$0027(singleton.Return(r), f, tail(xs)));
        }));
    }
    else {
        return singleton.Bind(state, (arg) => singleton.Return(Result_Map((list) => reverse(list), arg)));
    }
}

export function traverseResultM(f, xs) {
    return traverseResultM$0027(new FSharpResult$2(0, empty()), f, xs);
}

export function sequenceResultM(xs) {
    return traverseResultM((x) => x, xs);
}

export function traverseAsyncResultM(f, xs) {
    return traverseAsyncResultM$0027(retn(empty()), f, xs);
}

export function sequenceAsyncResultM(xs) {
    return traverseAsyncResultM((x) => x, xs);
}

function traverseResultA$0027(state_mut, f_mut, xs_mut) {
    traverseResultA$0027:
    while (true) {
        const state = state_mut, f = f_mut, xs = xs_mut;
        if (!isEmpty(xs)) {
            const xs_1 = tail(xs);
            const matchValue = [state, Result_MapError((value) => singleton_1(value), f(head(xs)))];
            let pattern_matching_result, y, ys, e, errs, e_1;
            const copyOfStruct = matchValue[0];
            if (copyOfStruct.tag === 1) {
                const copyOfStruct_1 = matchValue[1];
                if (copyOfStruct_1.tag === 0) {
                    pattern_matching_result = 2;
                    e_1 = copyOfStruct.fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    e = copyOfStruct_1.fields[0];
                    errs = copyOfStruct.fields[0];
                }
            }
            else {
                const copyOfStruct_2 = matchValue[1];
                if (copyOfStruct_2.tag === 1) {
                    pattern_matching_result = 2;
                    e_1 = copyOfStruct_2.fields[0];
                }
                else {
                    pattern_matching_result = 0;
                    y = copyOfStruct_2.fields[0];
                    ys = copyOfStruct.fields[0];
                }
            }
            switch (pattern_matching_result) {
                case 0: {
                    state_mut = (new FSharpResult$2(0, cons(y, ys)));
                    f_mut = f;
                    xs_mut = xs_1;
                    continue traverseResultA$0027;
                }
                case 1: {
                    state_mut = (new FSharpResult$2(1, append(errs, e)));
                    f_mut = f;
                    xs_mut = xs_1;
                    continue traverseResultA$0027;
                }
                case 2: {
                    state_mut = (new FSharpResult$2(1, e_1));
                    f_mut = f;
                    xs_mut = xs_1;
                    continue traverseResultA$0027;
                }
            }
        }
        else {
            return Result_Map((list) => reverse(list), state);
        }
        break;
    }
}

function traverseAsyncResultA$0027(state, f, xs) {
    if (!isEmpty(xs)) {
        const xs_1 = tail(xs);
        return singleton.Delay(() => singleton.Bind(state, (_arg1) => singleton.Bind(mapError((value_2) => singleton_1(value_2), f(head(xs))), (_arg2) => {
            const matchValue = [_arg1, _arg2];
            let pattern_matching_result, y, ys, e, errs, e_1;
            const copyOfStruct = matchValue[0];
            if (copyOfStruct.tag === 1) {
                const copyOfStruct_1 = matchValue[1];
                if (copyOfStruct_1.tag === 0) {
                    pattern_matching_result = 2;
                    e_1 = copyOfStruct.fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    e = copyOfStruct_1.fields[0];
                    errs = copyOfStruct.fields[0];
                }
            }
            else {
                const copyOfStruct_2 = matchValue[1];
                if (copyOfStruct_2.tag === 1) {
                    pattern_matching_result = 2;
                    e_1 = copyOfStruct_2.fields[0];
                }
                else {
                    pattern_matching_result = 0;
                    y = copyOfStruct_2.fields[0];
                    ys = copyOfStruct.fields[0];
                }
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton.ReturnFrom(traverseAsyncResultA$0027(retn(cons(y, ys)), f, xs_1));
                }
                case 1: {
                    return singleton.ReturnFrom(traverseAsyncResultA$0027(returnError(append(errs, e)), f, xs_1));
                }
                case 2: {
                    return singleton.ReturnFrom(traverseAsyncResultA$0027(returnError(e_1), f, xs_1));
                }
            }
        })));
    }
    else {
        return singleton.Bind(state, (arg) => singleton.Return(Result_Map((list) => reverse(list), arg)));
    }
}

export function traverseResultA(f, xs) {
    return traverseResultA$0027(new FSharpResult$2(0, empty()), f, xs);
}

export function sequenceResultA(xs) {
    return traverseResultA((x) => x, xs);
}

export function traverseValidationA$0027(state_mut, f_mut, xs_mut) {
    traverseValidationA$0027:
    while (true) {
        const state = state_mut, f = f_mut, xs = xs_mut;
        if (!isEmpty(xs)) {
            const xs_1 = tail(xs);
            const matchValue = [state, f(head(xs))];
            let pattern_matching_result, y, ys, errs1, errs2, errs;
            const copyOfStruct = matchValue[0];
            if (copyOfStruct.tag === 1) {
                const copyOfStruct_1 = matchValue[1];
                if (copyOfStruct_1.tag === 0) {
                    pattern_matching_result = 2;
                    errs = copyOfStruct.fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    errs1 = copyOfStruct.fields[0];
                    errs2 = copyOfStruct_1.fields[0];
                }
            }
            else {
                const copyOfStruct_2 = matchValue[1];
                if (copyOfStruct_2.tag === 1) {
                    pattern_matching_result = 2;
                    errs = copyOfStruct_2.fields[0];
                }
                else {
                    pattern_matching_result = 0;
                    y = copyOfStruct_2.fields[0];
                    ys = copyOfStruct.fields[0];
                }
            }
            switch (pattern_matching_result) {
                case 0: {
                    state_mut = (new FSharpResult$2(0, cons(y, ys)));
                    f_mut = f;
                    xs_mut = xs_1;
                    continue traverseValidationA$0027;
                }
                case 1: {
                    state_mut = (new FSharpResult$2(1, append(errs2, errs1)));
                    f_mut = f;
                    xs_mut = xs_1;
                    continue traverseValidationA$0027;
                }
                case 2: {
                    state_mut = (new FSharpResult$2(1, errs));
                    f_mut = f;
                    xs_mut = xs_1;
                    continue traverseValidationA$0027;
                }
            }
        }
        else {
            return Result_Map((list) => reverse(list), state);
        }
        break;
    }
}

export function traverseValidationA(f, xs) {
    return traverseValidationA$0027(new FSharpResult$2(0, empty()), f, xs);
}

export function sequenceValidationA(xs) {
    return traverseValidationA((x) => x, xs);
}

export function traverseAsyncResultA(f, xs) {
    return traverseAsyncResultA$0027(retn(empty()), f, xs);
}

export function sequenceAsyncResultA(xs) {
    return traverseAsyncResultA((x) => x, xs);
}

