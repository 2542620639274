import { rgba, hsl } from "../fable_modules/Feliz.1.45.0/Colors.fs.js";

export const dividedTop = ["style", {
    borderTopStyle: "solid",
    borderTopWidth: 1 + "px",
    borderTopColor: "#c4c4c4",
}];

export const lightDivedTop = ["style", {
    borderTopStyle: "solid",
    borderTopWidth: 1 + "px",
    borderTopColor: "#e3e3e3",
}];

export const dividedTopMargin = ["style", {
    borderTopStyle: "solid",
    borderTopWidth: 1 + "px",
    borderTopColor: "#c4c4c4",
    marginTop: 10,
}];

export const dividedTopLight = ["style", {
    borderTopStyle: "solid",
    borderTopWidth: 1 + "px",
    borderTopColor: hsl(0, 0, 86),
}];

export const dividedBottom = ["style", {
    borderBottomStyle: "solid",
    borderBottomWidth: 1 + "px",
    borderBottomColor: "#c4c4c4",
}];

export const dividedTopGrayLight = ["style", {
    borderTopStyle: "solid",
    borderTopWidth: 1 + "px",
    borderTopColor: rgba(196, 196, 196, 0.3),
}];

