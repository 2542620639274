import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function buttonIcon(name, preicon, icon, href, colorIcon) {
    let props;
    return createElement("a", {
        className: "homeButton",
        href: href,
        children: Interop_reactApi.Children.toArray([createElement("div", {
            children: Interop_reactApi.Children.toArray([(props = ofArray([["style", {
                fontSize: 2.5 + "em",
            }], ["className", "transform"], ["className", join(" ", [preicon, icon, colorIcon])]]), createElement("span", createObj(Helpers_combineClasses("icon", props)))), createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "homeButton_text"], ["children", name]]))))]),
        })]),
    });
}

