import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "./Localization.js";
import { createElement } from "react";
import { singleton, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { buttonIcon } from "./Components/IconButton.js";
import { Route, toPath } from "./Router.js";
import { Orders_PickUpStatus } from "./Shared/Shared.js";

export const render = (() => {
    let props_2, s, props_6, props_4;
    const elms = ofArray([(props_2 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("home.cart"), createElement("h1", {
        className: "title",
        children: s,
    })), createElement("h2", {
        className: "subtitle",
        children: "",
    })])]]), createElement("section", createObj(Helpers_combineClasses("section", props_2)))), (props_6 = singleton(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([buttonIcon(t("orders.extradition"), "fal", "fa-shopping-bag", toPath(new Route(24, 1, new Orders_PickUpStatus(1), void 0)), "homeButton__grey"), buttonIcon(t("orders.freight"), "fal", "fa-paper-plane", toPath(new Route(26, 1)), "homeButton__grey")])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_4))))])]), createElement("section", createObj(Helpers_combineClasses("section", props_6))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
})();

