import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { Orders_CashReportType, ToggleFilters_DeliveryMethod, ToggleFilters_Zone, ToggleFilters_ToggleDeliveryMethod, SortAuction, Orders_TransactionsFilter, AuctionStatus, Orders_DeliveryMethod, AuctionsEntity$reflection, ToggleFilters_TogglePaymentMethod$reflection, ToggleFilters_ToggleDeliveryMethod$reflection, SortAuction$reflection, SettingsFreight_FreightCostBasicSetting$reflection, Orders_TransactionsFilter$reflection } from "../Shared/Shared.js";
import { union_type, class_type, record_type, list_type, string_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Deferred$1, Deferred$1$reflection } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "../Extensions.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { auctionApi, commonApi, settingsApi } from "../Communication.js";
import { map, singleton as singleton_1, append, tryFind, isEmpty, cons, item, ofArray, empty, filter as filter_1 } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj, equals } from "../fable_modules/fable-library.3.6.1/Util.js";
import { addDays, now } from "../fable_modules/fable-library.3.6.1/Date.js";
import { fromDate } from "../fable_modules/fable-library.3.6.1/DateOffset.js";
import { format as format_1, endOfDay, startOfDay } from "date-fns";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { AuctionIdModule_fromString, AuctionIdModule_empty } from "../bidflow/src/infrastructure/Auction.js";
import { defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { equals as equals_1 } from "../fable_modules/fable-library.3.6.1/Long.js";
import { Localized_translate, t } from "../Localization.js";
import { sv } from "date-fns/locale";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, singleton as singleton_2, append as append_1, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Route, toPath, navigate } from "../Router.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Render as Render_1 } from "./TransactionsReport.js";
import { Render as Render_2 } from "../OrderSummaryReport.js";
import { loader } from "../Loader.js";

export class State extends Record {
    constructor(Filters, ResidentCountry, DeliveryZones, AllAuction) {
        super();
        this.Filters = Filters;
        this.ResidentCountry = ResidentCountry;
        this.DeliveryZones = DeliveryZones;
        this.AllAuction = AllAuction;
    }
}

export function State$reflection() {
    return record_type("CashReport.State", [], State, () => [["Filters", Orders_TransactionsFilter$reflection()], ["ResidentCountry", Deferred$1$reflection(string_type)], ["DeliveryZones", Deferred$1$reflection(list_type(SettingsFreight_FreightCostBasicSetting$reflection()))], ["AllAuction", Deferred$1$reflection(list_type(SortAuction$reflection()))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadResidentCountry", "LoadDeliveryZones", "DatePeriodChanged", "ChangeDeliveryZone", "ChangePaymentMethod", "ChangeAuction", "LoadAuctions"];
    }
}

export function Msg$reflection() {
    return union_type("CashReport.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, class_type("System.Exception")], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", class_type("System.Exception")]]]))]], [["Item", AsyncOperationStatus$1$reflection(list_type(SettingsFreight_FreightCostBasicSetting$reflection()))]], [["Item", list_type(class_type("System.DateTime"))]], [["Item", ToggleFilters_ToggleDeliveryMethod$reflection()]], [["Item", ToggleFilters_TogglePaymentMethod$reflection()]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(AuctionsEntity$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(AuctionsEntity$reflection())]], [["ErrorValue", string_type]]]))]]]);
}

export function toSharedType(method) {
    if (method.tag === 1) {
        return new Orders_DeliveryMethod(1);
    }
    else {
        return new Orders_DeliveryMethod(0, method.fields[0].Id);
    }
}

export function loadDeliveryZones() {
    return singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().getActiveFreightZones()), (_arg1) => singleton.Return(new Msg(1, new AsyncOperationStatus$1(1, _arg1)))));
}

export function loadResidentCountry() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(commonApi().loadCompanyBasicSettings(), (_arg1) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.ResidentCountry)))))), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2))))));
}

export const loadAuctions = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAllAuctions()), (_arg1) => singleton.Return(new Msg(6, new AsyncOperationStatus$1(1, new FSharpResult$2(0, filter_1((x) => equals(x.Status, new AuctionStatus(0)), _arg1))))))), (_arg2) => singleton.Return(new Msg(6, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function init(filters) {
    let currentDate, filter;
    return [new State((filters == null) ? ((currentDate = now(), new Orders_TransactionsFilter(fromDate(startOfDay(addDays(currentDate, -1))), fromDate(endOfDay(addDays(currentDate, -1))), empty(), empty(), void 0))) : ((filter = filters, new Orders_TransactionsFilter(fromDate(startOfDay(filter.StartDate)), fromDate(endOfDay(filter.EndDate)), filter.ByDeliveryZone, filter.ByPaymentMethod, filter.AuctionId))), new Deferred$1(0), new Deferred$1(0), new Deferred$1(0)), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(1, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Msg(0, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Msg(6, new AsyncOperationStatus$1(0)))]))];
}

export function update(msg, state) {
    let inputRecord, inputRecord_7, inputRecord_8;
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.Filters, state.ResidentCountry, new Deferred$1(2, msg.fields[0].fields[0]), state.AllAuction), Cmd_none()];
        }
        else {
            return [new State(state.Filters, state.ResidentCountry, new Deferred$1(1), state.AllAuction), Cmd_fromAsync(loadDeliveryZones())];
        }
    }
    else if (msg.tag === 2) {
        return [new State((inputRecord = state.Filters, new Orders_TransactionsFilter(fromDate(item(0, msg.fields[0])), fromDate(item(1, msg.fields[0])), inputRecord.ByDeliveryZone, inputRecord.ByPaymentMethod, inputRecord.AuctionId)), state.ResidentCountry, state.DeliveryZones, state.AllAuction), Cmd_none()];
    }
    else if (msg.tag === 3) {
        let newPayload;
        const matchValue = msg.fields[0].DeliveryMethod;
        if (matchValue != null) {
            const z = matchValue;
            if (msg.fields[0].IsCheck) {
                const inputRecord_2 = state.Filters;
                newPayload = (new Orders_TransactionsFilter(inputRecord_2.StartDate, inputRecord_2.EndDate, filter_1((x) => (!equals(x, toSharedType(z))), state.Filters.ByDeliveryZone), inputRecord_2.ByPaymentMethod, inputRecord_2.AuctionId));
            }
            else {
                const inputRecord_3 = state.Filters;
                newPayload = (new Orders_TransactionsFilter(inputRecord_3.StartDate, inputRecord_3.EndDate, cons(toSharedType(z), state.Filters.ByDeliveryZone), inputRecord_3.ByPaymentMethod, inputRecord_3.AuctionId));
            }
        }
        else {
            const inputRecord_1 = state.Filters;
            newPayload = (new Orders_TransactionsFilter(inputRecord_1.StartDate, inputRecord_1.EndDate, empty(), inputRecord_1.ByPaymentMethod, inputRecord_1.AuctionId));
        }
        if (msg.fields[0].DeliveryMethod != null) {
            return [new State(newPayload, state.ResidentCountry, state.DeliveryZones, state.AllAuction), Cmd_none()];
        }
        else {
            return [new State(newPayload, state.ResidentCountry, state.DeliveryZones, state.AllAuction), Cmd_none()];
        }
    }
    else if (msg.tag === 4) {
        let newPayload_1;
        const matchValue_3 = msg.fields[0].Status;
        if (matchValue_3 != null) {
            const z_1 = matchValue_3;
            if (msg.fields[0].IsCheck) {
                const inputRecord_5 = state.Filters;
                newPayload_1 = (new Orders_TransactionsFilter(inputRecord_5.StartDate, inputRecord_5.EndDate, inputRecord_5.ByDeliveryZone, filter_1((x_1) => (!equals(x_1, z_1)), state.Filters.ByPaymentMethod), inputRecord_5.AuctionId));
            }
            else {
                const inputRecord_6 = state.Filters;
                newPayload_1 = (new Orders_TransactionsFilter(inputRecord_6.StartDate, inputRecord_6.EndDate, inputRecord_6.ByDeliveryZone, cons(z_1, state.Filters.ByPaymentMethod), inputRecord_6.AuctionId));
            }
        }
        else {
            const inputRecord_4 = state.Filters;
            newPayload_1 = (new Orders_TransactionsFilter(inputRecord_4.StartDate, inputRecord_4.EndDate, inputRecord_4.ByDeliveryZone, empty(), inputRecord_4.AuctionId));
        }
        if (isEmpty(newPayload_1.ByPaymentMethod)) {
            return [new State(newPayload_1, state.ResidentCountry, state.DeliveryZones, state.AllAuction), Cmd_none()];
        }
        else if (msg.fields[0].Status != null) {
            return [new State(newPayload_1, state.ResidentCountry, state.DeliveryZones, state.AllAuction), Cmd_none()];
        }
        else {
            return [new State(newPayload_1, state.ResidentCountry, state.DeliveryZones, state.AllAuction), Cmd_none()];
        }
    }
    else if (msg.tag === 5) {
        let newAuction;
        const matchValue_6 = state.AllAuction;
        if (matchValue_6.tag === 2) {
            const value = new SortAuction(AuctionIdModule_empty, "All");
            newAuction = defaultArg(tryFind((x_2) => equals_1(x_2.Id, AuctionIdModule_fromString(msg.fields[0])), matchValue_6.fields[0]), value);
        }
        else {
            newAuction = (new SortAuction(AuctionIdModule_empty, "All"));
        }
        return [new State(equals_1(newAuction.Id, AuctionIdModule_empty) ? ((inputRecord_7 = state.Filters, new Orders_TransactionsFilter(inputRecord_7.StartDate, inputRecord_7.EndDate, inputRecord_7.ByDeliveryZone, inputRecord_7.ByPaymentMethod, void 0))) : ((inputRecord_8 = state.Filters, new Orders_TransactionsFilter(inputRecord_8.StartDate, inputRecord_8.EndDate, inputRecord_8.ByDeliveryZone, inputRecord_8.ByPaymentMethod, newAuction.Id))), state.ResidentCountry, state.DeliveryZones, state.AllAuction), Cmd_none()];
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.Filters, state.ResidentCountry, state.DeliveryZones, new Deferred$1(2, append(singleton_1(new SortAuction(AuctionIdModule_empty, t("common.all"))), map((x_5) => {
                    let locale, formatOptions;
                    return new SortAuction(x_5.Id, (Localized_translate(x_5.Name) + ", ") + ((locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_1(x_5.Date, "dd MMMM yyyy", formatOptions))))));
                }, filter_1((x_4) => equals(x_4.Status, new AuctionStatus(0)), msg.fields[0].fields[0].fields[0]))))), Cmd_none()];
            }
        }
        else {
            return [new State(state.Filters, state.ResidentCountry, state.DeliveryZones, new Deferred$1(1)), Cmd_fromAsync(loadAuctions)];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new State(state.Filters, new Deferred$1(3, msg.fields[0].fields[0].fields[0]), state.DeliveryZones, state.AllAuction), Cmd_none()];
        }
        else {
            return [new State(state.Filters, new Deferred$1(2, msg.fields[0].fields[0].fields[0]), state.DeliveryZones, state.AllAuction), Cmd_none()];
        }
    }
    else {
        return [new State(state.Filters, new Deferred$1(1), state.DeliveryZones, state.AllAuction), Cmd_fromAsync(loadResidentCountry())];
    }
}

export function Render(renderInputProps) {
    let props_12, s, props_10, children, props_4, props_7, props_14;
    const pageType = renderInputProps.pageType;
    const page = renderInputProps.page;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderInputProps.filters), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const matchValue = [state_1.DeliveryZones, state_1.ResidentCountry, state_1.AllAuction];
    let pattern_matching_result, error, error_1, allAuctions, deliveryZones, residentCountry;
    if (matchValue[0].tag === 3) {
        pattern_matching_result = 0;
        error = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 3) {
            pattern_matching_result = 1;
            error_1 = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 2) {
            if (matchValue[2].tag === 2) {
                pattern_matching_result = 2;
                allAuctions = matchValue[2].fields[0];
                deliveryZones = matchValue[0].fields[0];
                residentCountry = matchValue[1].fields[0];
            }
            else {
                pattern_matching_result = 3;
            }
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (matchValue[1].tag === 3) {
        pattern_matching_result = 1;
        error_1 = matchValue[1].fields[0];
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            const value = error.message;
            return createElement("div", {
                children: [value],
            });
        }
        case 1: {
            const value_1 = error_1.message;
            return createElement("div", {
                children: [value_1],
            });
        }
        case 2: {
            const deliveryZones_1 = append(append(singleton_1(new ToggleFilters_ToggleDeliveryMethod(void 0, true)), map((x) => (new ToggleFilters_ToggleDeliveryMethod(new ToggleFilters_DeliveryMethod(0, new ToggleFilters_Zone(x.DeliveryCostZoneId, Localized_translate(x.Name))), false)), deliveryZones)), singleton_1(new ToggleFilters_ToggleDeliveryMethod(new ToggleFilters_DeliveryMethod(1), false)));
            const elms = ofArray([(props_12 = ofArray([["className", "mt-4"], ["className", "no-height-section-to-print"], ["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([(s = t("reports.cashReport"), createElement("h1", {
                className: "title",
                children: s,
            })), (props_10 = ofArray([["className", "is-uppercase"], ["className", "is-toggle"], ["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(children = ofArray([(props_4 = toList(delay(() => append_1(equals(pageType, new Orders_CashReportType(1)) ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("reports.transactions"),
                onClick: (_arg1) => {
                    navigate(toPath(new Route(44, 1, new Orders_CashReportType(1), state_1.Filters)));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_4)))), (props_7 = toList(delay(() => append_1(equals(pageType, new Orders_CashReportType(0)) ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", {
                className: "is-normal-padding",
                children: t("common.orders"),
                onClick: (_arg2) => {
                    navigate(toPath(new Route(44, 1, new Orders_CashReportType(0), state_1.Filters)));
                },
            })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_7))))]), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_10))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_12)))), (props_14 = ofArray([["className", "py-2"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((pageType.tag === 1) ? singleton_2(createElement(Render_1, {
                page: page,
                toggleButtonDeliveryZone: deliveryZones_1,
                residentCountry: residentCountry,
                allAuctions: allAuctions,
                selectedFilters: state_1.Filters,
                datePeriodChanged: (x_5) => {
                    dispatch(new Msg(2, x_5));
                },
                changeDeliveryZone: (x_6) => {
                    dispatch(new Msg(3, x_6));
                },
                changePaymentMethod: (x_7) => {
                    dispatch(new Msg(4, x_7));
                },
                changeAuctionIdMethod: (x_8) => {
                    dispatch(new Msg(5, x_8));
                },
            })) : singleton_2(createElement(Render_2, {
                page: page,
                toggleButtonDeliveryZone: deliveryZones_1,
                residentCountry: residentCountry,
                allAuctions: allAuctions,
                filters: state_1.Filters,
                datePeriodChanged: (x_1) => {
                    dispatch(new Msg(2, x_1));
                },
                changeDeliveryZone: (x_2) => {
                    dispatch(new Msg(3, x_2));
                },
                changePaymentMethod: (x_3) => {
                    dispatch(new Msg(4, x_3));
                },
                changeAuctionIdMethod: (x_4) => {
                    dispatch(new Msg(5, x_4));
                },
            })))))))]]), createElement("section", createObj(Helpers_combineClasses("section", props_14))))]);
            return createElement("div", {
                className: "container",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            });
        }
        case 3: {
            return loader("big");
        }
    }
}

