import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "../Extensions.js";
import { itemApi } from "../Communication.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { t } from "../Localization.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";

export function UndoReturn_ByLotId(undoReturn_ByLotIdInputProps) {
    const lotId = undoReturn_ByLotIdInputProps.lotId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const undoReturnLot_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(itemApi().undoReturnItemByLotId(lotId)))), patternInput[1]);
    const props = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(patternInput[0]) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", t("auction.undoReturning")]), delay(() => singleton_1(["onClick", (_arg1) => {
        undoReturnLot_1();
        document.location.reload();
    }])))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export function UndoReturn_ByItemId(undoReturn_ByItemIdInputProps) {
    const itemId = undoReturn_ByItemIdInputProps.itemId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const undoReturnLot_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(itemApi().undoReturnItem(itemId)))), patternInput[1]);
    const props = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(patternInput[0]) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", t("auction.undoReturning")]), delay(() => singleton_1(["onClick", (_arg1) => {
        undoReturnLot_1();
        document.location.reload();
    }])))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export function UndoRecycle_ByLotId(undoRecycle_ByLotIdInputProps) {
    const lotId = undoRecycle_ByLotIdInputProps.lotId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const undoRecycleLot_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(itemApi().undoRecycleItemByLotId(lotId)))), patternInput[1]);
    const props = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(patternInput[0]) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", t("auction.undoRecycling")]), delay(() => singleton_1(["onClick", (_arg1) => {
        undoRecycleLot_1();
        document.location.reload();
    }])))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

export function UndoRecycle_ByItemId(undoRecycle_ByItemIdInputProps) {
    const itemId = undoRecycle_ByItemIdInputProps.itemId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const undoRecycleLot_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => singleton.Delay(() => singleton.ReturnFrom(Remoting_handleNonAuth(itemApi().undoRecycleItem(itemId)))), patternInput[1]);
    const props = toList(delay(() => append(singleton_1(["className", "is-danger"]), delay(() => append(Deferred_inProgress(patternInput[0]) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["children", t("auction.undoRecycling")]), delay(() => singleton_1(["onClick", (_arg1) => {
        undoRecycleLot_1();
        document.location.reload();
    }])))))))));
    return createElement("button", createObj(Helpers_combineClasses("button", props)));
}

