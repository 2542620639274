import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { option_type, union_type, tuple_type, int32_type, record_type, bool_type, class_type, list_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { LotInformationModule_empty, Priority_fromES, BasicInformation_name, ForceReserveLotNumberRequest, CancelReservationLotNumberRequest, Priority_toES, Dimensions, ItemLotBasicInformationField, ReserveLotNumberRequest, PrintLabelInfo, CreateLotAndItemInfo, Fee as Fee_1, GpsPosition as GpsPosition_1, LotInformation, Priority_Priority$reflection, Category$reflection, LotNumberReservation$reflection, CategoryCatalog$reflection, LotInformation$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Deferred_map, Deferred_hasNotStartedYet, Deferred_inProgress, useFeliz_React__React_useDeferredCallback_Static_7088D81D, useFeliz_React__React_useDeferred_Static_2344FC52, Deferred$1, Deferred$1$reflection } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { EditImages, ImagesOnStage$reflection } from "./EditImages.js";
import { Remoting_handleNonAuth, Async_tee, Cmd_fromAsync, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { LocalizedStringModule_getTranslationOrEmpty, LocalizedMap_getValueOrEmpty, LocalizedStringModule_setTranslation, LocalizedMap_add } from "./bidflow/src/infrastructure/Localization.js";
import { Lot_Create_validate, Lot_Create_validateIf, Lot_Create_CreateLotValidation } from "./Validation.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi, auctionApi, usersApi, lotApi } from "./Communication.js";
import { Localized_translate, Localized_translateTo, getPrimaryLanguage, getCurrentLanguage, tWithArgs, t } from "./Localization.js";
import { CurrencyModule_fromDecimal, CurrencyModule_toDecimal, PercentModule_fromDecimal, PercentModule_toDecimal, CategoryIdModule_fromString, CategoryIdModule_toString, LotNumberReservationIdModule_fromString, SubmissionIdModule_isEmpty, SubmissionIdModule_empty, CategoryIdModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { ofNullable, toArray, map as map_1, value as value_488, defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { tryFind, map as map_3, append as append_1, filter, tryHead, fold, singleton as singleton_1, cons, ofArray, empty } from "./fable_modules/fable-library.3.6.1/List.js";
import { add, tryFind as tryFind_1, map, empty as empty_1 } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Currency_decimalDigits, ReservedPrice_tryParseOrDefault, ReservedPrice_toString, Currency_currencySymbol, Currency_tryParseOrDefault, Decimal_optionalToString, Form_getFieldError, Currency_round, Decimal_tryParse } from "./Common.js";
import { equals } from "./fable_modules/fable-library.3.6.1/Long.js";
import { AuctionArg, AuctionState, toPath, navigate, navigateTo, Route } from "./Router.js";
import { createElement } from "react";
import * as react from "react";
import { equals as equals_1, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { collect, iterate, map as map_2, empty as empty_2, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { isNullOrWhiteSpace, printf, toFail, join } from "./fable_modules/fable-library.3.6.1/String.js";
import { useReact_useEffect_Z101E1A95, useReact_useLayoutEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { foldResult, tee } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/AsyncResult.fs.js";
import { AsyncResultCE_asyncResult, AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/AsyncResultCE.fs.js";
import { toString, toNumber, fromParts } from "./fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.6.1/Decimal.js";
import { NumberFormatModule_numberFormat } from "./Components/InputDecimal.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { toLocalTime } from "./fable_modules/fable-library.3.6.1/DateOffset.js";
import { sv } from "date-fns/locale";
import { format as format_1 } from "date-fns";
import { SearchSubmission } from "./Components/SearchSubmissionComponent.js";
import { SelectPriority } from "./Components/CreateLot.js";
import { fold as fold_1 } from "./fable_modules/FsToolkit.ErrorHandling.2.5.0/Result.fs.js";
import { languageSwitcher } from "./Components/ContentLanguageSwitcher.js";
import { SearchItemName } from "./Components/SearchItemNameComponent.js";
import { edit as edit_1 } from "./Components/Freight.js";
import { dividedTop } from "./Components/BorderDividedLine.js";
import { RenderBigImages } from "./Components/Image.js";
import { loaderBig } from "./Loader.js";
import { getOrCreateNewReservationId } from "./SessionStorage.js";

export class ValidationState extends Record {
    constructor(FormErrors, NeedToValidate) {
        super();
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
    }
}

export function ValidationState$reflection() {
    return record_type("CreateLotAndItem.ValidationState", [], ValidationState, () => [["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type]]);
}

export class State extends Record {
    constructor(AuctionId, WarehouseCategoryId, SubmissionId, LotInfo, OriginalLotInfo, Catalog, ImagesToDelete, SaveOperation, ReserveLotNumberOperation, ReservationId, IsRequiredToSetFocusOnSubmission, ContentLanguage, Validation) {
        super();
        this.AuctionId = AuctionId;
        this.WarehouseCategoryId = (WarehouseCategoryId | 0);
        this.SubmissionId = SubmissionId;
        this.LotInfo = LotInfo;
        this.OriginalLotInfo = OriginalLotInfo;
        this.Catalog = Catalog;
        this.ImagesToDelete = ImagesToDelete;
        this.SaveOperation = SaveOperation;
        this.ReserveLotNumberOperation = ReserveLotNumberOperation;
        this.ReservationId = ReservationId;
        this.IsRequiredToSetFocusOnSubmission = IsRequiredToSetFocusOnSubmission;
        this.ContentLanguage = ContentLanguage;
        this.Validation = Validation;
    }
}

export function State$reflection() {
    return record_type("CreateLotAndItem.State", [], State, () => [["AuctionId", class_type("System.Int64")], ["WarehouseCategoryId", int32_type], ["SubmissionId", class_type("System.Int64")], ["LotInfo", LotInformation$reflection()], ["OriginalLotInfo", LotInformation$reflection()], ["Catalog", CategoryCatalog$reflection()], ["ImagesToDelete", list_type(string_type)], ["SaveOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(class_type("System.Int64"), class_type("System.Int64")), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(class_type("System.Int64"), class_type("System.Int64"))]], [["ErrorValue", string_type]]]))], ["ReserveLotNumberOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [LotNumberReservation$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", LotNumberReservation$reflection()]], [["ErrorValue", string_type]]]))], ["ReservationId", string_type], ["IsRequiredToSetFocusOnSubmission", bool_type], ["ContentLanguage", string_type], ["Validation", ValidationState$reflection()]]);
}

export function State__get_IsLotNumberReservationDone(this$) {
    const matchValue = this$.ReserveLotNumberOperation;
    let pattern_matching_result;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return true;
        }
        case 1: {
            return false;
        }
    }
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CategoryChange", "TitleChanged", "DescriptionChanged", "ConditionChanged", "HeightChanged", "WidthChanged", "LengthChanged", "DepthChanged", "DiameterChanged", "EstimatedPriceChanged", "ReservedPriceChanged", "HammerFeeChanged", "HasRoyaltiesChanged", "FreightZoneChanged", "GPSAddressChanged", "GPSMapLinkChanged", "PurchaseCommissionChanged", "ImagesChanged", "VideoLinkChanged", "SubmissionChanged", "FocusOnSubmissionSet", "LotNumberReservationChanged", "ChangePriority", "SaveChanges", "LotNumberReserved", "ContentLanguageChanged"];
    }
}

export function Msg$reflection() {
    return union_type("CreateLotAndItem.Msg", [], Msg, () => [[["Item", Category$reflection()]], [["Item", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("System.Decimal")]], [["Item", int32_type]], [["Item", class_type("System.Decimal")]], [["Item", bool_type]], [["Item1", string_type], ["Item2", option_type(int32_type)]], [["Item", option_type(string_type)]], [["Item", option_type(string_type)]], [["Item", class_type("System.Decimal")]], [["Item", ImagesOnStage$reflection()]], [["Item", option_type(string_type)]], [["Item1", class_type("System.Int64")], ["Item2", class_type("System.Int64")], ["Item3", string_type]], [], [["Item", LotNumberReservation$reflection()]], [["Item", Priority_Priority$reflection()]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(class_type("System.Int64"), class_type("System.Int64")), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(class_type("System.Int64"), class_type("System.Int64"))]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [LotNumberReservation$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", LotNumberReservation$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", string_type]]]);
}

export function withLotInfo(info, state) {
    return new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, info, state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, state.ReserveLotNumberOperation, state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, state.Validation);
}

export function withBasicInfoField(field, lang, value, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(LocalizedMap_add(field, lang, value, state.LotInfo.BasicInfo), inputRecord.Dimensions, inputRecord.Fee, inputRecord.Freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withDimension(dimension, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, dimension, inputRecord.Fee, inputRecord.Freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withFee(fee, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, inputRecord.Dimensions, fee, inputRecord.Freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withFreight(freight, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, inputRecord.Dimensions, inputRecord.Fee, freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withGPSPosition(gpsPosition, state) {
    let inputRecord;
    return withLotInfo((inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, inputRecord.Dimensions, inputRecord.Fee, inputRecord.Freight, gpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, inputRecord.SellerId, inputRecord.SellerName, inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state);
}

export function withCommissionsFormAndErrors(commissions, errors, state) {
    let inputRecord;
    const state_1 = withGPSPosition(new GpsPosition_1(state.LotInfo.GpsPosition.Address, commissions.GPSLink), withFee((inputRecord = state.LotInfo.Fee, new Fee_1(inputRecord.EstimatedPrice, inputRecord.ReservedPrice, commissions.PurchaseCommission, inputRecord.Hammer, inputRecord.HasRoyalties)), state));
    return new State(state_1.AuctionId, state_1.WarehouseCategoryId, state_1.SubmissionId, state_1.LotInfo, state_1.OriginalLotInfo, state_1.Catalog, state_1.ImagesToDelete, state_1.SaveOperation, state_1.ReserveLotNumberOperation, state_1.ReservationId, state_1.IsRequiredToSetFocusOnSubmission, state_1.ContentLanguage, new ValidationState(errors, state.Validation.NeedToValidate));
}

export function toValidationType(purchaseCommission, youtubeLink, link) {
    return new Lot_Create_CreateLotValidation(link, youtubeLink, purchaseCommission);
}

export function saveLot(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const lot = new CreateLotAndItemInfo(state.AuctionId, state.LotInfo, state.SubmissionId, state.WarehouseCategoryId, state.ImagesToDelete, state.ReservationId);
        return singleton.Bind(lotApi().createLotAndItem(lot), (_arg1) => {
            if (_arg1.tag === 1) {
                return (_arg1.fields[0].tag === 1) ? singleton.Return(new Msg(23, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.categoryIsNotSpecified"))))) : ((_arg1.fields[0].tag === 2) ? singleton.Return(new Msg(23, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.submissionCantBeEmpty"))))) : ((_arg1.fields[0].tag === 3) ? singleton.Return(new Msg(23, new AsyncOperationStatus$1(1, new FSharpResult$2(1, tWithArgs("errors.createLotUnknownError", {
                    error: _arg1.fields[0].fields[0],
                }))))) : singleton.Return(new Msg(23, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.categoryHasNoFreeSlots")))))));
            }
            else {
                const x = _arg1.fields[0][0];
                const info = new PrintLabelInfo(x[1], _arg1.fields[0][1], state.LotInfo.Fee.EstimatedPrice, getCurrentLanguage());
                return singleton.Combine(singleton.Bind(usersApi().getInfoAboutNeedAutoPrint(), (_arg2) => {
                    if (_arg2) {
                        return singleton.Bind(lotApi().generatePrintLabelWithQRCode(info), (_arg3) => {
                            window.open(_arg3);
                            return singleton.Zero();
                        });
                    }
                    else {
                        return singleton.Zero();
                    }
                }), singleton.Delay(() => singleton.Return(new Msg(23, new AsyncOperationStatus$1(1, new FSharpResult$2(0, x))))));
            }
        });
    }), (_arg4) => singleton.Return(new Msg(23, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg4.message))))));
}

export function reserveLotNumber(auctionId, reservationId, categoryId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        if (categoryId === CategoryIdModule_empty) {
            return singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.selectCategory")))));
        }
        else {
            const lot = new ReserveLotNumberRequest(auctionId, reservationId, categoryId);
            return singleton.Bind(auctionApi().reserveLotNumber(lot), (_arg1) => ((_arg1.tag === 1) ? ((_arg1.fields[0].tag === 2) ? singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.reservationDoesNotExist"))))) : ((_arg1.fields[0].tag === 1) ? singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, tWithArgs("errors.createLotUnknownError", {
                error: _arg1.fields[0].fields[0],
            }))))) : singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.selectCategory"))))))) : singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.fields[0]))))));
        }
    }), (_arg2) => singleton.Return(new Msg(24, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function init(auctionId, lot, categories, submissionId, reservationId) {
    return [new State(auctionId, CategoryIdModule_empty, defaultArg(submissionId, SubmissionIdModule_empty), lot, lot, categories, empty(), new Deferred$1(0), new Deferred$1(0), reservationId, true, getPrimaryLanguage(), new ValidationState(empty_1(), false)), Cmd_fromAsync(reserveLotNumber(auctionId, reservationId, lot.CategoryId))];
}

export function update(onSave, msg, state) {
    let inputRecord, inputRecord_1, tupledArg, tupledArg_1, inputRecord_3, tupledArg_2, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12, inputRecord_13, matchValue;
    const validate = (terms) => Lot_Create_validateIf(state.Validation.NeedToValidate, terms);
    if (msg.tag === 20) {
        return [new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, state.LotInfo, state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, state.ReserveLotNumberOperation, state.ReservationId, false, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 19) {
        return [new State(state.AuctionId, state.WarehouseCategoryId, msg.fields[0], (inputRecord = state.LotInfo, new LotInformation(inputRecord.BasicInfo, inputRecord.Dimensions, inputRecord.Fee, inputRecord.Freight, inputRecord.GpsPosition, inputRecord.Images, inputRecord.VideoLink, inputRecord.CategoryId, inputRecord.IsWithdraw, inputRecord.SubmissionId, msg.fields[1], msg.fields[2], inputRecord.IsUnSold, inputRecord.ItemReservedForAuction, inputRecord.IsReturned, inputRecord.IsRecycle, inputRecord.IsPublish, inputRecord.Priority)), state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, state.ReserveLotNumberOperation, state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 0) {
        return [new State(state.AuctionId, msg.fields[0].Id, state.SubmissionId, (inputRecord_1 = state.LotInfo, new LotInformation(inputRecord_1.BasicInfo, inputRecord_1.Dimensions, inputRecord_1.Fee, inputRecord_1.Freight, inputRecord_1.GpsPosition, inputRecord_1.Images, inputRecord_1.VideoLink, msg.fields[0].Id, inputRecord_1.IsWithdraw, inputRecord_1.SubmissionId, inputRecord_1.SellerId, inputRecord_1.SellerName, inputRecord_1.IsUnSold, inputRecord_1.ItemReservedForAuction, inputRecord_1.IsReturned, inputRecord_1.IsRecycle, inputRecord_1.IsPublish, inputRecord_1.Priority)), state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, state.ReserveLotNumberOperation, state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, state.Validation), Cmd_fromAsync(reserveLotNumber(state.AuctionId, state.ReservationId, msg.fields[0].Id))];
    }
    else if (msg.tag === 1) {
        return [withBasicInfoField(new ItemLotBasicInformationField(0), state.ContentLanguage, msg.fields[0], state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withBasicInfoField(new ItemLotBasicInformationField(1), msg.fields[0], msg.fields[1], state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withBasicInfoField(new ItemLotBasicInformationField(2), msg.fields[0], msg.fields[1], state), Cmd_none()];
    }
    else if (msg.tag === 14) {
        return [withGPSPosition(new GpsPosition_1(msg.fields[0], state.LotInfo.GpsPosition.MapLink), state), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [(tupledArg = validate(toValidationType(state.LotInfo.Fee.PurchaseCommission, state.LotInfo.VideoLink, msg.fields[0])), withCommissionsFormAndErrors(tupledArg[0], tupledArg[1], state)), Cmd_none()];
    }
    else if (msg.tag === 16) {
        return [(tupledArg_1 = validate(toValidationType(msg.fields[0], state.LotInfo.VideoLink, state.LotInfo.GpsPosition.MapLink)), withCommissionsFormAndErrors(tupledArg_1[0], tupledArg_1[1], state)), Cmd_none()];
    }
    else if (msg.tag === 17) {
        const newState = withLotInfo((inputRecord_3 = state.LotInfo, new LotInformation(inputRecord_3.BasicInfo, inputRecord_3.Dimensions, inputRecord_3.Fee, inputRecord_3.Freight, inputRecord_3.GpsPosition, msg.fields[0].Images, inputRecord_3.VideoLink, inputRecord_3.CategoryId, inputRecord_3.IsWithdraw, inputRecord_3.SubmissionId, inputRecord_3.SellerId, inputRecord_3.SellerName, inputRecord_3.IsUnSold, inputRecord_3.ItemReservedForAuction, inputRecord_3.IsReturned, inputRecord_3.IsRecycle, inputRecord_3.IsPublish, inputRecord_3.Priority)), state);
        return [new State(newState.AuctionId, newState.WarehouseCategoryId, newState.SubmissionId, newState.LotInfo, newState.OriginalLotInfo, newState.Catalog, msg.fields[0].ImagesToDelete, newState.SaveOperation, newState.ReserveLotNumberOperation, newState.ReservationId, newState.IsRequiredToSetFocusOnSubmission, newState.ContentLanguage, newState.Validation), Cmd_none()];
    }
    else if (msg.tag === 18) {
        return [(tupledArg_2 = validate(toValidationType(state.LotInfo.Fee.PurchaseCommission, msg.fields[0], state.LotInfo.GpsPosition.MapLink)), withCommissionsFormAndErrors(tupledArg_2[0], tupledArg_2[1], state)), Cmd_none()];
    }
    else if (msg.tag === 4) {
        const newHeightStr = msg.fields[0].trim();
        return [withDimension((inputRecord_4 = state.LotInfo.Dimensions, new Dimensions(Decimal_tryParse(newHeightStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newHeightStr, state.LotInfo.Dimensions.HeightInfo), inputRecord_4.Length, inputRecord_4.LengthInfo, inputRecord_4.Width, inputRecord_4.WidthInfo, inputRecord_4.Depth, inputRecord_4.DepthInfo, inputRecord_4.Diameter, inputRecord_4.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        const newWidthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_5 = state.LotInfo.Dimensions, new Dimensions(inputRecord_5.Height, inputRecord_5.HeightInfo, inputRecord_5.Length, inputRecord_5.LengthInfo, Decimal_tryParse(newWidthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newWidthStr, state.LotInfo.Dimensions.WidthInfo), inputRecord_5.Depth, inputRecord_5.DepthInfo, inputRecord_5.Diameter, inputRecord_5.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        const newLengthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_6 = state.LotInfo.Dimensions, new Dimensions(inputRecord_6.Height, inputRecord_6.HeightInfo, Decimal_tryParse(newLengthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newLengthStr, state.LotInfo.Dimensions.LengthInfo), inputRecord_6.Width, inputRecord_6.WidthInfo, inputRecord_6.Depth, inputRecord_6.DepthInfo, inputRecord_6.Diameter, inputRecord_6.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        const newDepthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_7 = state.LotInfo.Dimensions, new Dimensions(inputRecord_7.Height, inputRecord_7.HeightInfo, inputRecord_7.Length, inputRecord_7.LengthInfo, inputRecord_7.Width, inputRecord_7.WidthInfo, Decimal_tryParse(newDepthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newDepthStr, state.LotInfo.Dimensions.DepthInfo), inputRecord_7.Diameter, inputRecord_7.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        const newDiameterStr = msg.fields[0].trim();
        return [withDimension((inputRecord_8 = state.LotInfo.Dimensions, new Dimensions(inputRecord_8.Height, inputRecord_8.HeightInfo, inputRecord_8.Length, inputRecord_8.LengthInfo, inputRecord_8.Width, inputRecord_8.WidthInfo, inputRecord_8.Depth, inputRecord_8.DepthInfo, Decimal_tryParse(newDiameterStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newDiameterStr, state.LotInfo.Dimensions.DiameterInfo))), state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [withFee((inputRecord_9 = state.LotInfo.Fee, new Fee_1(inputRecord_9.EstimatedPrice, inputRecord_9.ReservedPrice, inputRecord_9.PurchaseCommission, inputRecord_9.Hammer, msg.fields[0])), state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withFee((inputRecord_10 = state.LotInfo.Fee, new Fee_1(msg.fields[0], inputRecord_10.ReservedPrice, inputRecord_10.PurchaseCommission, inputRecord_10.Hammer, inputRecord_10.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [withFee((inputRecord_11 = state.LotInfo.Fee, new Fee_1(inputRecord_11.EstimatedPrice, msg.fields[0], inputRecord_11.PurchaseCommission, inputRecord_11.Hammer, inputRecord_11.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [withFee((inputRecord_12 = state.LotInfo.Fee, new Fee_1(inputRecord_12.EstimatedPrice, inputRecord_12.ReservedPrice, inputRecord_12.PurchaseCommission, Currency_round(msg.fields[0]), inputRecord_12.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [withFreight(map((idx, v) => {
            if (idx === msg.fields[0]) {
                return msg.fields[1];
            }
            else {
                return v;
            }
        }, state.LotInfo.Freight), state), Cmd_none()];
    }
    else if (msg.tag === 22) {
        return [new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, (inputRecord_13 = state.LotInfo, new LotInformation(inputRecord_13.BasicInfo, inputRecord_13.Dimensions, inputRecord_13.Fee, inputRecord_13.Freight, inputRecord_13.GpsPosition, inputRecord_13.Images, inputRecord_13.VideoLink, inputRecord_13.CategoryId, inputRecord_13.IsWithdraw, inputRecord_13.SubmissionId, inputRecord_13.SellerId, inputRecord_13.SellerName, inputRecord_13.IsUnSold, inputRecord_13.ItemReservedForAuction, inputRecord_13.IsReturned, inputRecord_13.IsRecycle, inputRecord_13.IsPublish, Priority_toES(msg.fields[0]))), state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, state.ReserveLotNumberOperation, state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else if (msg.tag === 23) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, state.LotInfo, state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.ReserveLotNumberOperation, state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, state.Validation), Cmd_none()];
            }
            else {
                window.scroll({
                    behavior: "smooth",
                    left: 0,
                    top: 0,
                });
                const route = new Route(5, state.AuctionId, (state.LotInfo.CategoryId !== CategoryIdModule_empty) ? state.LotInfo.CategoryId : (void 0), (!equals(state.SubmissionId, SubmissionIdModule_empty)) ? state.SubmissionId : (void 0), (matchValue = [state.LotInfo.GpsPosition.Address, state.LotInfo.GpsPosition.MapLink], (matchValue[0] == null) ? ((matchValue[1] == null) ? (void 0) : state.LotInfo.GpsPosition) : state.LotInfo.GpsPosition));
                onSave(msg.fields[0].fields[0].fields[0]);
                return [state, navigateTo(route)];
            }
        }
        else {
            const formValidation = Lot_Create_validate(toValidationType(state.LotInfo.Fee.PurchaseCommission, state.LotInfo.VideoLink, state.LotInfo.GpsPosition.MapLink));
            if (formValidation.tag === 1) {
                return [new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, state.LotInfo, state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, new Deferred$1(0), state.ReserveLotNumberOperation, state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, new ValidationState(formValidation.fields[0], true)), Cmd_none()];
            }
            else {
                return [new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, state.LotInfo, state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, new Deferred$1(1), state.ReserveLotNumberOperation, state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, new ValidationState(empty_1(), true)), Cmd_fromAsync(saveLot(state))];
            }
        }
    }
    else if (msg.tag === 24) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, state.LotInfo, state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, new Deferred$1(2, msg.fields[0].fields[0]), state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, state.Validation), Cmd_none()];
        }
        else {
            return [new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, state.LotInfo, state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, new Deferred$1(1), state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, state.Validation), Cmd_none()];
        }
    }
    else if (msg.tag === 21) {
        return [new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, state.LotInfo, state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0])), state.ReservationId, state.IsRequiredToSetFocusOnSubmission, state.ContentLanguage, state.Validation), Cmd_none()];
    }
    else {
        return [new State(state.AuctionId, state.WarehouseCategoryId, state.SubmissionId, state.LotInfo, state.OriginalLotInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, state.ReserveLotNumberOperation, state.ReservationId, state.IsRequiredToSetFocusOnSubmission, msg.fields[0], state.Validation), Cmd_none()];
    }
}

export function renderSaveOperationError(state) {
    const matchValue = state.SaveOperation;
    let pattern_matching_result, ex, reason;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 1;
            reason = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue.tag === 0) {
        pattern_matching_result = 2;
    }
    else if (matchValue.tag === 1) {
        pattern_matching_result = 2;
    }
    else {
        pattern_matching_result = 0;
        ex = matchValue.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = ofArray([["className", "is-danger"], ["className", "is-light"], ["children", ex.message]]);
            return createElement("p", createObj(Helpers_combineClasses("help", props)));
        }
        case 1: {
            return createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "is-danger"], ["className", "is-light"], ["children", reason]]))));
        }
        case 2: {
            return null;
        }
    }
}

export function renderGPSPositionFields(state, dispatch) {
    let elms_1, elms, props_2, props, props_6, props_4, value_9, elms_3;
    const props_22 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([(value_9 = ((state.LotInfo.GpsPosition.Address != null) ? value_488(state.LotInfo.GpsPosition.Address) : ""), ["ref", (e) => {
        if ((!(e == null)) ? (!equals_1(e.value, value_9)) : false) {
            e.value = value_9;
        }
    }]), ["onChange", (ev) => {
        const x = ev.target.value;
        if (x === "") {
            dispatch(new Msg(14, void 0));
        }
        else {
            dispatch(new Msg(14, x));
        }
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_6))))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = toList(delay(() => {
        let elms_2, props_12, props_10, props_16, props_14, value_31;
        const errors = Form_getFieldError(state.Validation.FormErrors, "GpsMapLink");
        return append(singleton_2((elms_2 = ofArray([(props_12 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.linkToMaps")]]), createElement("label", createObj(Helpers_combineClasses("label", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_12)))), (props_16 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([(value_31 = ((state.LotInfo.GpsPosition.MapLink != null) ? value_488(state.LotInfo.GpsPosition.MapLink) : ""), ["ref", (e_1) => {
            if ((!(e_1 == null)) ? (!equals_1(e_1.value, value_31)) : false) {
                e_1.value = value_31;
            }
        }]), ["onChange", (ev_1) => {
            const x_1 = ev_1.target.value;
            if (x_1 === "") {
                dispatch(new Msg(15, void 0));
            }
            else {
                dispatch(new Msg(15, x_1));
            }
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_14)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_16))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), delay(() => {
            let props_19;
            return singleton_2((props_19 = ofArray([["className", "is-danger"], ["children", fold((s, x_2) => (`${s} ${x_2}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_19)))));
        }));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_22)));
}

export function RenderButtons(renderButtonsInputProps) {
    const isDuplicate = renderButtonsInputProps.isDuplicate;
    const dispatch = renderButtonsInputProps.dispatch;
    const state = renderButtonsInputProps.state;
    const cancelReservationLotNumber = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        if (state.LotInfo.CategoryId === CategoryIdModule_empty) {
            return singleton.Return();
        }
        else {
            const arg = new CancelReservationLotNumberRequest(state.AuctionId, state.ReservationId);
            return singleton.Bind(auctionApi().cancelReservationLotNumber(arg), (_arg1) => singleton.Return());
        }
    }), (_arg2) => singleton.Return()));
    const isLoading = equals_1(state.SaveOperation, new Deferred$1(1));
    const isSubmissionNotSelected = SubmissionIdModule_isEmpty(state.SubmissionId);
    const isLotNumberReservationHasNotDoneYet = !State__get_IsLotNumberReservationDone(state);
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props_3;
        return append((!isDuplicate) ? singleton_2((props_3 = toList(delay(() => append(singleton_2(["style", {
            marginBottom: 0,
        }]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => append(singleton_2(["tabIndex", -1]), delay(() => append(singleton_2(["onClick", (x_1) => {
            x_1.preventDefault();
            startImmediate(cancelReservationLotNumber);
            navigate(toPath(new Route(2, new AuctionArg(0, state.AuctionId, new AuctionState(false, false, 1)))));
        }]), delay(() => {
            let elms, s;
            return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                className: join(" ", ["fa", "fa-chevron-left"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (s = t("auction.backToAuction"), createElement("span", {
                className: "",
                children: s,
            }))])]);
        })))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_3))))) : empty_2(), delay(() => {
            let props_5;
            return singleton_2((props_5 = toList(delay(() => append(singleton_2(["style", {
                marginBottom: 0,
            }]), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["disabled", isSubmissionNotSelected ? true : isLotNumberReservationHasNotDoneYet]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => append(singleton_2(["children", t("auction.saveLot")]), delay(() => singleton_2(["onClick", (x_2) => {
                x_2.preventDefault();
                dispatch(new Msg(23, new AsyncOperationStatus$1(0)));
            }]))))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_5)))));
        }));
    })));
}

export function EditLotNumber(editLotNumberInputProps) {
    let props_12, elms, patternInput_2, elms_2, elms_1, props_6, props_8, props_14;
    const onCancel = editLotNumberInputProps.onCancel;
    const onOk = editLotNumberInputProps.onOk;
    const categoryId = editLotNumberInputProps.categoryId;
    const reservationId = editLotNumberInputProps.reservationId;
    const auctionId = editLotNumberInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setSelected = patternInput[1];
    const selected = patternInput[0];
    const reservations_1 = useFeliz_React__React_useDeferred_Static_2344FC52(Async_tee((arg_1) => {
        setSelected(map_1((x_1) => x_1.ReservationId, tryHead(arg_1)));
    }, singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(auctionApi().getReservations(auctionId)), (_arg1) => singleton.Return(filter((x) => {
        if (x.CategoryId === categoryId) {
            return x.ReservationId !== reservationId;
        }
        else {
            return false;
        }
    }, _arg1))))), []);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const takeReservationResult = patternInput_1[0];
    const forceTakeReservation_1 = useFeliz_React__React_useDeferredCallback_Static_7088D81D((reservationIdToCancel) => {
        let clo1;
        const ar = tee(onOk, AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD(AsyncResultCE_asyncResult, () => {
            const info = new ForceReserveLotNumberRequest(auctionId, reservationIdToCancel, reservationId, categoryId);
            return Remoting_handleNonAuth(auctionApi().forceReserveLotNumber(info));
        }));
        return foldResult((value) => {
        }, (clo1 = toFail(printf("%A")), (arg10) => {
            clo1(arg10);
        }), ar);
    }, patternInput_1[1]);
    const loadReservationsError = (reservations_1.tag === 3) ? reservations_1.fields[0].message : "";
    const isLoadingReservations = (reservations_1.tag === 0) ? true : ((reservations_1.tag === 1) ? true : false);
    const takeReservationError = (takeReservationResult.tag === 3) ? takeReservationResult.fields[0].message : "";
    const isTakeReservationInProgress = (takeReservationResult.tag === 1) ? true : false;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((patternInput_2 = Helpers_extractClasses(ofArray([["className", join(" ", toList(delay(() => ((isLoadingReservations ? true : isTakeReservationInProgress) ? singleton_2("is-loading") : empty_2()))))], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            switch (reservations_1.tag) {
                case 3:
                case 1: {
                    return empty_2();
                }
                case 2: {
                    return map_2((r) => createElement("option", {
                        value: `${r.ReservationId}`,
                        children: `Lot# ${r.LotNumber}`,
                    }), reservations_1.fields[0]);
                }
                default: {
                    return empty_2();
                }
            }
        }))))], ["value", defaultArg(map_1((x_4) => (`${x_4}`), selected), "")], ["onChange", (ev) => {
            setSelected(LotNumberReservationIdModule_fromString(ev.target.value));
        }]])), createElement("div", {
            className: join(" ", cons("select", patternInput_2[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_2[1]))]),
        }))), createElement("p", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_2 = singleton_1((elms_1 = ofArray([(props_6 = toList(delay(() => append(singleton_2(["children", t("common.ok")]), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["disabled", selected == null]), delay(() => append(isTakeReservationInProgress ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => singleton_2(["onClick", (_arg1_1) => {
            iterate(forceTakeReservation_1, toArray(selected));
        }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_6)))), (props_8 = toList(delay(() => append(singleton_2(["children", t("common.cancel")]), delay(() => append(singleton_2(["className", "is-dark"]), delay(() => append(isTakeReservationInProgress ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => singleton_2(["onClick", (_arg2) => {
            onCancel();
        }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_8))))]), createElement("div", {
            className: "buttons",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), createElement("p", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_12)))), (props_14 = ofArray([["children", loadReservationsError + takeReservationError], ["className", "is-danger"]]), createElement("p", createObj(Helpers_combineClasses("help", props_14))))]),
    });
}

export function ReservedLotNumber(reservedLotNumberInputProps) {
    let props, props_2;
    const x = reservedLotNumberInputProps.x;
    const onOk = reservedLotNumberInputProps.onOk;
    const reservationId = reservedLotNumberInputProps.reservationId;
    const categoryId = reservedLotNumberInputProps.categoryId;
    const auctionId = reservedLotNumberInputProps.auctionId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setEdit = patternInput[1];
    if (patternInput[0]) {
        return createElement(EditLotNumber, {
            auctionId: auctionId,
            reservationId: reservationId,
            categoryId: categoryId,
            onOk: (x_1) => {
                setEdit(false);
                onOk(x_1);
            },
            onCancel: () => {
                setEdit(false);
            },
        });
    }
    else {
        const isNotReady = Deferred_inProgress(x) ? true : Deferred_hasNotStartedYet(x);
        return react.createElement(react.Fragment, {}, (props = toList(delay(() => append(singleton_2(["className", "is-static"]), delay(() => append(singleton_2(["className", "has-background-black"]), delay(() => append(singleton_2(["className", "has-text-white"]), delay(() => append(isNotReady ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => {
            let lotNumber;
            return singleton_2(["children", (x.tag === 1) ? "" : ((x.tag === 2) ? ((x.fields[0] == null) ? "-" : ((lotNumber = (x.fields[0] | 0), `${lotNumber}`))) : ((x.tag === 3) ? "-" : ""))]);
        })))))))))), createElement("span", createObj(Helpers_combineClasses("button", props)))), (props_2 = ofArray([["className", "has-background-primary"], ["className", "has-text-white"], ["children", t("common.reset")], ["onClick", (_arg2) => {
            setEdit(true);
        }]]), createElement("button", createObj(Helpers_combineClasses("button", props_2)))));
    }
}

export function tryDecimalOrDefault(defaultValue, str) {
    if (isNullOrWhiteSpace(str)) {
        return fromParts(0, 0, 0, false, 0);
    }
    else {
        return defaultArg(Decimal_tryParse(str), defaultValue);
    }
}

export function renderField(label, prefix, value, placeholder, onChange, state, formErrors) {
    const elms_2 = toList(delay(() => {
        let elms_1, props_2, props_10, props_5, props_4, elms, props_12;
        const errors = Form_getFieldError(state.Validation.FormErrors, formErrors);
        return singleton_2((elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_10 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append((!equals_1(errors, empty())) ? singleton_2(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_2(["onValueChange", (x) => {
            onChange(new Decimal(defaultArg(x.floatValue, 0)));
        }]), delay(() => append(singleton_2(["placeholder", placeholder]), delay(() => append(singleton_2(["value", toNumber(value)]), delay(() => (equals_1(state.SaveOperation, new Deferred$1(1)) ? singleton_2(["disabled", true]) : empty_2()))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_4, singleton_1(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_5)))), (elms = singleton_1(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["children", prefix]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_10)))), (props_12 = ofArray([["className", "is-danger"], ["children", fold((s, x_1) => (`${s} ${x_1}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_12))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })));
    }));
    return createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function RenderWithLoadCategories(renderWithLoadCategoriesInputProps) {
    let props_14, props_12, props_5, children, props, props_2, props_10, elms, props_7, props_216, props_214, props_210, props_47, props_45, elms_2, elms_1, props_18, props_16, props_20, props_37, elms_3, props_26, props_24, props_34, props_39, props_43, props_41, props_132, props_130, props_128, props_56, elms_4, props_51, props_49, props_53, props_65, props_60, props_58, elms_5, props_62, value_145, props_74, props_69, props_67, elms_6, props_71, value_170, props_126, elms_8, elms_7, props_78, props_76, props_82, props_80, elms_10, elms_9, props_88, props_86, props_92, props_90, elms_12, elms_11, props_98, props_96, props_102, props_100, elms_14, elms_13, props_108, props_106, props_112, props_110, elms_16, elms_15, props_118, props_116, props_122, props_120, props_198, props_196, props_194, props_174, elms_19, elms_18, props_136, props_134, props_145, props_140, props_138, value_322, elms_17, props_142, elms_22, elms_21, props_151, props_149, props_160, props_155, props_153, value_358, elms_20, props_157, elms_24, props_171, props_166, props_164, elms_23, props_192, elms_27, elms_26, props_178, props_176, props_186, props_181, props_180, props$0027, elms_25, props_183, props_202, props_200, props_207, elms_28, props_204, props_212;
    const onSave = renderWithLoadCategoriesInputProps.onSave;
    const submissionId = renderWithLoadCategoriesInputProps.submissionId;
    const freightSettings = renderWithLoadCategoriesInputProps.freightSettings;
    const lot = renderWithLoadCategoriesInputProps.lot;
    const auction = renderWithLoadCategoriesInputProps.auction;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderWithLoadCategoriesInputProps.auctionId, lot, renderWithLoadCategoriesInputProps.catalog, submissionId, renderWithLoadCategoriesInputProps.reservationId), (msg, state) => update(onSave, msg, state), [lot.CategoryId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const selectSubmissionRef = patternInput_1[0];
    let isSavedSuccessfully;
    const matchValue = state_1.SaveOperation;
    let pattern_matching_result;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            isSavedSuccessfully = true;
            break;
        }
        case 1: {
            isSavedSuccessfully = false;
            break;
        }
    }
    const suppressPropagateEnterKey = (ev) => {
        if (ev.key === "Enter") {
            const this$ = ev;
            this$.stopPropagation();
            this$.nativeEvent.stopImmediatePropagation();
        }
    };
    const getBasicInfoValue = (field) => Localized_translateTo(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field, state_1.LotInfo.BasicInfo));
    useReact_useLayoutEffect_Z101E1A95(() => {
        if (state_1.IsRequiredToSetFocusOnSubmission) {
            if (selectSubmissionRef != null) {
                selectSubmissionRef.focus();
                dispatch(new Msg(20));
            }
        }
    }, [state_1.IsRequiredToSetFocusOnSubmission, selectSubmissionRef]);
    const props_219 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "mt-3"], ["children", Interop_reactApi.Children.toArray([(props_5 = singleton_1(["children", Interop_reactApi.Children.toArray([(children = ofArray([(props = ofArray([["className", "is-size-6"], ["className", "has-text-weight-light"], ["className", "has-text-grey-light"], ["children", t("auction.createLotFor")]]), createElement("h5", createObj(Helpers_combineClasses("subtitle is-5", props)))), (props_2 = toList(delay(() => append(singleton_2(["className", "has-text-grey-dark"]), delay(() => append(singleton_2(["className", "is-uppercase"]), delay(() => append(singleton_2(["className", "is-size-4"]), delay(() => {
        let date, locale, formatOptions;
        const auctionName = Localized_translate(BasicInformation_name(auction.BasicInformation));
        return singleton_2(["children", `${auctionName} ${((date = toLocalTime(auction.Date), (locale = sv, (formatOptions = {}, (formatOptions.locale = locale, format_1(date, "yyyy-MM-dd", formatOptions))))))}`]);
    })))))))), createElement("h5", createObj(Helpers_combineClasses("title is-5", props_2))))]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_10 = ofArray([["style", {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_7 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(RenderButtons, {
        state: state_1,
        dispatch: dispatch,
        isDuplicate: false,
    })])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_7))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_12)))), renderSaveOperationError(state_1)])]]), createElement("section", createObj(Helpers_combineClasses("section", props_14)))), createElement("fieldset", {
        disabled: equals_1(state_1.SaveOperation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([(props_216 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_214 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_210 = ofArray([["className", "is-8"], ["children", Interop_reactApi.Children.toArray([(props_47 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_45 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_1((elms_1 = ofArray([(props_18 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.submission")]]), createElement("label", createObj(Helpers_combineClasses("label", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_18)))), (props_20 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement(SearchSubmission, {
            selected: submissionId,
            onSelect: (arg) => {
                let tupledArg;
                dispatch((tupledArg = arg, new Msg(19, tupledArg[0], tupledArg[1], tupledArg[2])));
            },
            onInputRef: (el) => {
                patternInput_1[1](ofNullable(el));
            },
            dependencies: [state_1.IsRequiredToSetFocusOnSubmission],
            isAutofocus: true,
            isStartSearchOnInit: true,
        })])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_20))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (props_37 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_26 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.category")]]), createElement("label", createObj(Helpers_combineClasses("label", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_26)))), (props_34 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let patternInput_2, value_81;
            const options = toList(delay(() => append((state_1.LotInfo.CategoryId === CategoryIdModule_empty) ? singleton_2(createElement("option", {
                value: CategoryIdModule_toString(CategoryIdModule_empty),
                children: t("common.noCategory"),
            })) : empty_2(), delay(() => map_3((category) => createElement("option", {
                value: CategoryIdModule_toString(category.Id),
                children: Localized_translate(category.Name),
            }), state_1.Catalog.Categories)))));
            return singleton_2((patternInput_2 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["onChange", (ev_1) => {
                const categoryId = CategoryIdModule_fromString(ev_1.target.value) | 0;
                iterate((arg_1) => {
                    dispatch(new Msg(0, arg_1));
                }, toArray(tryFind((x_1) => (x_1.Id === categoryId), state_1.Catalog.Categories)));
            }], (value_81 = CategoryIdModule_toString(state_1.LotInfo.CategoryId), ["ref", (e) => {
                if ((!(e == null)) ? (!equals_1(e.value, value_81)) : false) {
                    e.value = value_81;
                }
            }]), ["children", Interop_reactApi.Children.toArray(Array.from(options))]])), createElement("div", {
                className: join(" ", cons("select", patternInput_2[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_2[1]))]),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_34))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_37)))), (props_39 = ofArray([["className", "is-3"], ["children", Interop_reactApi.Children.toArray([createElement(SelectPriority, {
            lotId: void 0,
            setPriority: (arg_3) => {
                dispatch(new Msg(22, arg_3));
            },
            priority: Priority_fromES(state_1.LotInfo.Priority),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_39)))), (props_43 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_41 = ofArray([["style", {
            justifyContent: "flex-end",
        }], ["className", "mt-5"], ["className", "has-addons"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const lotNumber = Deferred_map((r) => fold_1((x_5) => x_5.LotNumber, (_arg1) => (void 0), r), state_1.ReserveLotNumberOperation);
            return singleton_2(createElement(ReservedLotNumber, {
                auctionId: state_1.AuctionId,
                categoryId: state_1.LotInfo.CategoryId,
                reservationId: state_1.ReservationId,
                onOk: (arg_4) => {
                    dispatch(new Msg(21, arg_4));
                },
                x: lotNumber,
            }));
        }))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_41))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_43))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_45))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_47)))), createElement("form", {
            disabled: equals_1(state_1.SaveOperation, new Deferred$1(1)),
            onSubmit: (x_6) => {
                x_6.preventDefault();
                dispatch(new Msg(23, new AsyncOperationStatus$1(0)));
            },
            children: Interop_reactApi.Children.toArray([(props_132 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_130 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_128 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([languageSwitcher(state_1.ContentLanguage, (arg_5) => {
                dispatch(new Msg(25, arg_5));
            }), (props_56 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_4 = ofArray([(props_51 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_49 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.lotTitle")]]), createElement("label", createObj(Helpers_combineClasses("label", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_51)))), (props_53 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const name_60 = getBasicInfoValue(new ItemLotBasicInformationField(0));
                return singleton_2(createElement(SearchItemName, {
                    selected: name_60,
                    onSelect: (arg_6) => {
                        dispatch(new Msg(1, arg_6));
                    },
                    onChange: (arg_7) => {
                        dispatch(new Msg(1, arg_7));
                    },
                    onInputRef: (_arg2) => {
                    },
                    dependencies: [state_1.ContentLanguage],
                    isAutofocus: false,
                    isStartSearchOnInit: false,
                }));
            }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_53))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_56)))), (props_65 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_60 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_58 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.lotDescription")]]), createElement("label", createObj(Helpers_combineClasses("label", props_58))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_60)))), (elms_5 = singleton_1((props_62 = ofArray([["rows", 2], ["onKeyDown", suppressPropagateEnterKey], ["onKeyUp", suppressPropagateEnterKey], ["onKeyPress", suppressPropagateEnterKey], (value_145 = getBasicInfoValue(new ItemLotBasicInformationField(1)), ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals_1(e_1.value, value_145)) : false) {
                    e_1.value = value_145;
                }
            }]), ["onChange", (ev_2) => {
                dispatch(new Msg(2, state_1.ContentLanguage, ev_2.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_62))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_65)))), (props_74 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_69 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_67 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.lotCondition")]]), createElement("label", createObj(Helpers_combineClasses("label", props_67))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_69)))), (elms_6 = singleton_1((props_71 = ofArray([["rows", 1], ["onKeyDown", suppressPropagateEnterKey], ["onKeyUp", suppressPropagateEnterKey], ["onKeyPress", suppressPropagateEnterKey], (value_170 = getBasicInfoValue(new ItemLotBasicInformationField(2)), ["ref", (e_2) => {
                if ((!(e_2 == null)) ? (!equals_1(e_2.value, value_170)) : false) {
                    e_2.value = value_170;
                }
            }]), ["onChange", (ev_3) => {
                dispatch(new Msg(3, state_1.ContentLanguage, ev_3.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_71))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_74)))), (props_126 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_8 = singleton_1((elms_7 = ofArray([(props_78 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_76 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.height")]]), createElement("label", createObj(Helpers_combineClasses("label", props_76))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_78)))), (props_82 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_80 = toList(delay(() => append((state_1.LotInfo.Dimensions.Height != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Height)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.HeightInfo)]), delay(() => singleton_2(["onChange", (ev_4) => {
                dispatch(new Msg(4, ev_4.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_80)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_82))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
            })), (elms_10 = singleton_1((elms_9 = ofArray([(props_88 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_86 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.length")]]), createElement("label", createObj(Helpers_combineClasses("label", props_86))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_88)))), (props_92 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_90 = toList(delay(() => append((state_1.LotInfo.Dimensions.Length != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Length)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.LengthInfo)]), delay(() => singleton_2(["onChange", (ev_5) => {
                dispatch(new Msg(6, ev_5.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_90)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_92))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
            })), (elms_12 = singleton_1((elms_11 = ofArray([(props_98 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_96 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.width")]]), createElement("label", createObj(Helpers_combineClasses("label", props_96))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_98)))), (props_102 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_100 = toList(delay(() => append((state_1.LotInfo.Dimensions.Width != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Width)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.WidthInfo)]), delay(() => singleton_2(["onChange", (ev_6) => {
                dispatch(new Msg(5, ev_6.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_100)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_102))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
            })), (elms_14 = singleton_1((elms_13 = ofArray([(props_108 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_106 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.depth")]]), createElement("label", createObj(Helpers_combineClasses("label", props_106))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_108)))), (props_112 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_110 = toList(delay(() => append((state_1.LotInfo.Dimensions.Depth != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Depth)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.DepthInfo)]), delay(() => singleton_2(["onChange", (ev_7) => {
                dispatch(new Msg(7, ev_7.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_110)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_112))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
            })), (elms_16 = singleton_1((elms_15 = ofArray([(props_118 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_116 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.diameter")]]), createElement("label", createObj(Helpers_combineClasses("label", props_116))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_118)))), (props_122 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_120 = toList(delay(() => append((state_1.LotInfo.Dimensions.Diameter != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.LotInfo.Dimensions.Diameter)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.LotInfo.Dimensions.DiameterInfo)]), delay(() => singleton_2(["onChange", (ev_8) => {
                dispatch(new Msg(8, ev_8.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_120)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_122))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_126)))), renderGPSPositionFields(state_1, dispatch)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_128))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_130))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_132)))), (props_198 = ofArray([["className", "py-1"], ["className", "px-0"], ["className", "has-background-grey-lighter"], ["style", {
                borderRadius: 4,
            }], ["children", Interop_reactApi.Children.toArray([(props_196 = ofArray([["style", {
                margin: 0,
            }], ["children", Interop_reactApi.Children.toArray([(props_194 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(props_174 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_19 = singleton_1((elms_18 = ofArray([(props_136 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_134 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.estimatedPrice")]]), createElement("label", createObj(Helpers_combineClasses("label", props_134))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_136)))), (props_145 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_140 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_138 = ofArray([["placeholder", "200"], (value_322 = toString(state_1.LotInfo.Fee.EstimatedPrice), ["ref", (e_3) => {
                if ((!(e_3 == null)) ? (!equals_1(e_3.value, value_322)) : false) {
                    e_3.value = value_322;
                }
            }]), ["onChange", (ev_9) => {
                dispatch(new Msg(9, Currency_tryParseOrDefault(ev_9.target.value)));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_138)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_140)))), (elms_17 = singleton_1((props_142 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_142))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_145))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
            })), (elms_22 = singleton_1((elms_21 = ofArray([(props_151 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_149 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.reservedPrice")]]), createElement("label", createObj(Helpers_combineClasses("label", props_149))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_151)))), (props_160 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_155 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_153 = ofArray([["placeholder", "200"], (value_358 = ReservedPrice_toString(state_1.LotInfo.Fee.ReservedPrice), ["ref", (e_4) => {
                if ((!(e_4 == null)) ? (!equals_1(e_4.value, value_358)) : false) {
                    e_4.value = value_358;
                }
            }]), ["onChange", (ev_10) => {
                dispatch(new Msg(10, ReservedPrice_tryParseOrDefault(ev_10.target.value)));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_153)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_155)))), (elms_20 = singleton_1((props_157 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_157))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_160))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
            })), (elms_24 = singleton_1((props_171 = ofArray([["style", {
                display: "inline-table",
            }], ["children", Interop_reactApi.Children.toArray([(props_166 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_164 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.resaleRights")]]), createElement("label", createObj(Helpers_combineClasses("label", props_164))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_166)))), (elms_23 = ofArray([createElement("input", {
                disabled: equals_1(state_1.SaveOperation, new Deferred$1(1)),
                className: "is-checkradio is-large has-background-color is-white",
                id: "exampleCheckboxMedium",
                type: "checkbox",
                name: "exampleCheckboxMedium",
                onChange: (ev_11) => {
                    dispatch(new Msg(12, ev_11.target.checked));
                },
                checked: state_1.LotInfo.Fee.HasRoyalties,
            }), createElement("label", {
                htmlFor: "exampleCheckboxMedium",
            })]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_171))))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_24)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_174)))), (props_192 = singleton_1(["children", Interop_reactApi.Children.toArray([renderField(t("orders.buyerCommission"), "%", PercentModule_toDecimal(state_1.LotInfo.Fee.PurchaseCommission), t("orders.buyerCommission"), (arg_19) => {
                dispatch(new Msg(16, PercentModule_fromDecimal(arg_19)));
            }, state_1, "BuyerCommission"), (elms_27 = singleton_1((elms_26 = ofArray([(props_178 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_176 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.hammerFee")]]), createElement("label", createObj(Helpers_combineClasses("label", props_176))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_178)))), (props_186 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_181 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_180 = ofArray([["value", toNumber(CurrencyModule_toDecimal(state_1.LotInfo.Fee.Hammer))], ["onValueChange", (x_15) => {
                dispatch(new Msg(11, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_15.floatValue, 0)))));
            }]]), (props$0027 = ofArray([["className", "input"], ["decimalScale", Currency_decimalDigits()]]), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_180, props$0027)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_181)))), (elms_25 = singleton_1((props_183 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_183))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_25)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_186))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_26)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_27)),
            })), createElement("div", createObj(Helpers_combineClasses("column", empty())))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_192))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_194))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_196))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_198)))), (props_202 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_200 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((zone) => {
                const freightClass = tryFind_1(zone.DeliveryCostZoneId, state_1.LotInfo.Freight);
                return (freightClass == null) ? singleton_2(edit_1(zone, void 0, (x_18) => {
                    dispatch(new Msg(13, zone.DeliveryCostZoneId, x_18));
                })) : singleton_2(edit_1(zone, value_488(freightClass), (x_17) => {
                    dispatch(new Msg(13, zone.DeliveryCostZoneId, x_17));
                }));
            }, freightSettings)))))]), createElement("div", createObj(Helpers_combineClasses("columns", props_200))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_202)))), (props_207 = ofArray([["className", "py-4"], ["className", "px-0"], dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_28 = singleton_1((props_204 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(RenderButtons, {
                state: state_1,
                dispatch: dispatch,
                isDuplicate: true,
            })])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_204))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_28)),
            }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_207))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_210)))), (props_212 = ofArray([["className", "has-background-white"], ["style", {
            borderRadius: 4,
            marginLeft: 10,
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement(RenderBigImages, {
            images: state_1.LotInfo.Images,
        })), delay(() => {
            const formErrors = Form_getFieldError(state_1.Validation.FormErrors, "YouTubeLink");
            return singleton_2(createElement(EditImages, {
                onChange: (_arg1_1) => {
                    if (_arg1_1.tag === 1) {
                        dispatch(new Msg(18, _arg1_1.fields[0]));
                    }
                    else {
                        dispatch(new Msg(17, _arg1_1.fields[0]));
                    }
                },
                videoLinkErrors: formErrors,
                dependencies: [isSavedSuccessfully],
            }));
        }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_212))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_214))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_216))))]),
    })])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_219)));
}

export function Render(renderInputProps) {
    let inputRecord;
    const gpsPosition = renderInputProps.gpsPosition;
    const submissionId = renderInputProps.submissionId;
    const auctionId = renderInputProps.auctionId;
    const categoryId_1 = defaultArg(renderInputProps.categoryId, CategoryIdModule_empty) | 0;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setItem = patternInput[1];
    const item = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            setItem(new Deferred$1(1));
            return singleton.Bind(Remoting_handleNonAuth(auctionApi().getCategories(auctionId)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getFreightSettings()), (_arg2) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getOrderCommissions()), (_arg3) => singleton.Bind(Remoting_handleNonAuth(auctionApi().getAuction(auctionId)), (_arg4) => {
                setItem(new Deferred$1(2, {
                    Auction: _arg4,
                    Catalog: _arg1,
                    FreightSettings: _arg2,
                    ["СommissionsSettings"]: _arg3,
                }));
                return singleton.Zero();
            }))));
        }), (_arg5) => {
            setItem(new Deferred$1(3, _arg5));
            return singleton.Zero();
        })));
    }, [auctionId, patternInput_1[0]]);
    switch (item.tag) {
        case 1: {
            return loaderBig();
        }
        case 3: {
            const value = item.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const result_1 = item.fields[0];
            const reservationId = getOrCreateNewReservationId();
            const lot = new LotInformation(LotInformationModule_empty.BasicInfo, LotInformationModule_empty.Dimensions, (inputRecord = LotInformationModule_empty.Fee, new Fee_1(inputRecord.EstimatedPrice, inputRecord.ReservedPrice, result_1["СommissionsSettings"].BuyerCommission, result_1["СommissionsSettings"].HammerFee, inputRecord.HasRoyalties)), fold((m, f) => add(f.DeliveryCostZoneId, void 0, m), empty_1(), result_1.FreightSettings), defaultArg(gpsPosition, new GpsPosition_1(void 0, void 0)), LotInformationModule_empty.Images, LotInformationModule_empty.VideoLink, categoryId_1, LotInformationModule_empty.IsWithdraw, LotInformationModule_empty.SubmissionId, LotInformationModule_empty.SellerId, LotInformationModule_empty.SellerName, LotInformationModule_empty.IsUnSold, LotInformationModule_empty.ItemReservedForAuction, LotInformationModule_empty.IsReturned, LotInformationModule_empty.IsRecycle, LotInformationModule_empty.IsPublish, LotInformationModule_empty.Priority);
            return createElement(RenderWithLoadCategories, {
                auctionId: auctionId,
                auction: result_1.Auction,
                lot: lot,
                catalog: result_1.Catalog,
                freightSettings: result_1.FreightSettings,
                submissionId: submissionId,
                reservationId: reservationId,
                onSave: (arg_1) => {
                    patternInput_1[1](arg_1);
                },
            });
        }
        default: {
            return null;
        }
    }
}

