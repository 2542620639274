import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { option_type, int32_type, union_type, record_type, bool_type, class_type, list_type, string_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { ItemWithFullDataInfoModule_empty, AuctionModeType, Dimensions, ItemLotBasicInformationField, PrintLabelInfo, CreateItemWithFullDataInfo, Fee as Fee_1, GpsPosition as GpsPosition_1, ItemWithFullDataInfo, Category$reflection, CategoryCatalog$reflection, ItemWithFullDataInfo$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Deferred$1, Deferred$1$reflection } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { EditImages, ImagesOnStage$reflection } from "./EditImages.js";
import { Remoting_handleNonAuth, Cmd_fromAsync, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection } from "./Extensions.js";
import { LocalizedStringModule_getTranslationOrEmpty, LocalizedMap_getValueOrEmpty, LocalizedStringModule_setTranslation, LocalizedMap_add } from "./bidflow/src/infrastructure/Localization.js";
import { Lot_Create_validate, Lot_Create_validateIf, Lot_Create_CreateLotValidation } from "./Validation.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { settingsApi, auctionApi, lotApi, usersApi, itemApi } from "./Communication.js";
import { Localized_translate, Localized_translateTo, getPrimaryLanguage, getCurrentLanguage, tWithArgs, t } from "./Localization.js";
import { tryFind, map as map_1, append as append_1, fold, singleton as singleton_1, cons, ofArray, empty } from "./fable_modules/fable-library.3.6.1/List.js";
import { add, tryFind as tryFind_1, map, empty as empty_1 } from "./fable_modules/fable-library.3.6.1/Map.js";
import { Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Currency_decimalDigits, ReservedPrice_tryParseOrDefault, ReservedPrice_toString, Currency_currencySymbol, Currency_tryParseOrDefault, Decimal_optionalToString, Form_getFieldError, Currency_round, Decimal_tryParse } from "./Common.js";
import { AuctionIdModule_zero, CurrencyModule_fromDecimal, CurrencyModule_toDecimal, PercentModule_fromDecimal, PercentModule_toDecimal, CategoryIdModule_fromString, CategoryIdModule_toString, SubmissionIdModule_isEmpty, SubmissionIdModule_empty, CategoryIdModule_empty } from "./bidflow/src/infrastructure/Auction.js";
import { equals } from "./fable_modules/fable-library.3.6.1/Long.js";
import { toPath, navigate, Route, navigateTo } from "./Router.js";
import { createElement } from "react";
import * as react from "react";
import { equals as equals_1, createObj } from "./fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { toArray, ofNullable, defaultArg, value as value_472 } from "./fable_modules/fable-library.3.6.1/Option.js";
import { collect, iterate, empty as empty_2, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { isNullOrWhiteSpace, join } from "./fable_modules/fable-library.3.6.1/String.js";
import { toString, toNumber, fromParts } from "./fable_modules/fable-library.3.6.1/Decimal.js";
import Decimal from "./fable_modules/fable-library.3.6.1/Decimal.js";
import { NumberFormatModule_numberFormat } from "./Components/InputDecimal.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useLayoutEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { SearchSubmission } from "./Components/SearchSubmissionComponent.js";
import { languageSwitcher } from "./Components/ContentLanguageSwitcher.js";
import { SearchItemName } from "./Components/SearchItemNameComponent.js";
import { edit } from "./Components/Freight.js";
import { dividedTop } from "./Components/BorderDividedLine.js";
import { RenderBigImages } from "./Components/Image.js";
import { startImmediate } from "./fable_modules/fable-library.3.6.1/Async.js";
import { loaderBig } from "./Loader.js";

export class ValidationState extends Record {
    constructor(FormErrors, NeedToValidate) {
        super();
        this.FormErrors = FormErrors;
        this.NeedToValidate = NeedToValidate;
    }
}

export function ValidationState$reflection() {
    return record_type("CreateItem.ValidationState", [], ValidationState, () => [["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedToValidate", bool_type]]);
}

export class State extends Record {
    constructor(ItemInfo, OriginalItemInfo, Catalog, ImagesToDelete, SaveOperation, IsRequiredToSetFocusOnSubmission, Validation, ContentLanguage) {
        super();
        this.ItemInfo = ItemInfo;
        this.OriginalItemInfo = OriginalItemInfo;
        this.Catalog = Catalog;
        this.ImagesToDelete = ImagesToDelete;
        this.SaveOperation = SaveOperation;
        this.IsRequiredToSetFocusOnSubmission = IsRequiredToSetFocusOnSubmission;
        this.Validation = Validation;
        this.ContentLanguage = ContentLanguage;
    }
}

export function State$reflection() {
    return record_type("CreateItem.State", [], State, () => [["ItemInfo", ItemWithFullDataInfo$reflection()], ["OriginalItemInfo", ItemWithFullDataInfo$reflection()], ["Catalog", CategoryCatalog$reflection()], ["ImagesToDelete", list_type(string_type)], ["SaveOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", string_type]]]))], ["IsRequiredToSetFocusOnSubmission", bool_type], ["Validation", ValidationState$reflection()], ["ContentLanguage", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CategoryChange", "TitleChanged", "DescriptionChanged", "ConditionChanged", "HeightChanged", "WidthChanged", "LengthChanged", "DepthChanged", "DiameterChanged", "EstimatedPriceChanged", "ReservedPriceChanged", "HammerFeeChanged", "HasRoyaltiesChanged", "FreightZoneChanged", "GPSAddressChanged", "GPSMapLinkChanged", "PurchaseCommissionChanged", "ImagesChanged", "VideoLinkChanged", "SubmissionChanged", "FocusOnSubmissionSet", "SaveChanges", "ContentLanguageChanged"];
    }
}

export function Msg$reflection() {
    return union_type("CreateItem.Msg", [], Msg, () => [[["Item", Category$reflection()]], [["Item", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("System.Decimal")]], [["Item", int32_type]], [["Item", class_type("System.Decimal")]], [["Item", bool_type]], [["Item1", string_type], ["Item2", option_type(int32_type)]], [["Item", option_type(string_type)]], [["Item", option_type(string_type)]], [["Item", class_type("System.Decimal")]], [["Item", ImagesOnStage$reflection()]], [["Item", option_type(string_type)]], [["Item1", class_type("System.Int64")], ["Item2", class_type("System.Int64")], ["Item3", string_type]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Int64"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Int64")]], [["ErrorValue", string_type]]]))]], [["Item", string_type]]]);
}

export function withItemInfo(info, state) {
    return new State(info, state.OriginalItemInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, state.IsRequiredToSetFocusOnSubmission, state.Validation, state.ContentLanguage);
}

export function withBasicInfoField(field, lang, value, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.ItemInfo, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, LocalizedMap_add(field, lang, value, state.ItemInfo.BasicInformation), inputRecord.Dimensions, inputRecord.Fee, inputRecord.Images, inputRecord.VideoLink, inputRecord.GpsPosition, inputRecord.Freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function withDimension(dimension, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.ItemInfo, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, inputRecord.BasicInformation, dimension, inputRecord.Fee, inputRecord.Images, inputRecord.VideoLink, inputRecord.GpsPosition, inputRecord.Freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function withFee(fee, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.ItemInfo, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, inputRecord.BasicInformation, inputRecord.Dimensions, fee, inputRecord.Images, inputRecord.VideoLink, inputRecord.GpsPosition, inputRecord.Freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function withFreight(freight, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.ItemInfo, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, inputRecord.BasicInformation, inputRecord.Dimensions, inputRecord.Fee, inputRecord.Images, inputRecord.VideoLink, inputRecord.GpsPosition, freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function withGPSPosition(gpsPosition, state) {
    let inputRecord;
    return withItemInfo((inputRecord = state.ItemInfo, new ItemWithFullDataInfo(inputRecord.CategoryId, inputRecord.SubmissionId, inputRecord.BasicInformation, inputRecord.Dimensions, inputRecord.Fee, inputRecord.Images, inputRecord.VideoLink, gpsPosition, inputRecord.Freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state);
}

export function withCommissionsFormAndErrors(commissions, errors, state) {
    let inputRecord;
    const state_1 = withGPSPosition(new GpsPosition_1(state.ItemInfo.GpsPosition.Address, commissions.GPSLink), withFee((inputRecord = state.ItemInfo.Fee, new Fee_1(inputRecord.EstimatedPrice, inputRecord.ReservedPrice, commissions.PurchaseCommission, inputRecord.Hammer, inputRecord.HasRoyalties)), state));
    return new State(state_1.ItemInfo, state_1.OriginalItemInfo, state_1.Catalog, state_1.ImagesToDelete, state_1.SaveOperation, state_1.IsRequiredToSetFocusOnSubmission, new ValidationState(errors, state.Validation.NeedToValidate), state_1.ContentLanguage);
}

export function toValidationType(purchaseCommission, youtubeLink, link) {
    return new Lot_Create_CreateLotValidation(link, youtubeLink, purchaseCommission);
}

export function saveItem(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const item = new CreateItemWithFullDataInfo(state.ItemInfo, state.ImagesToDelete);
        return singleton.Bind(itemApi().createItemWithFullInfo(item), (_arg1) => {
            if (_arg1.tag === 1) {
                return (_arg1.fields[0].tag === 1) ? singleton.Return(new Msg(21, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.categoryIsNotSpecified"))))) : ((_arg1.fields[0].tag === 2) ? singleton.Return(new Msg(21, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.submissionCantBeEmpty"))))) : ((_arg1.fields[0].tag === 3) ? singleton.Return(new Msg(21, new AsyncOperationStatus$1(1, new FSharpResult$2(1, tWithArgs("errors.createLotUnknownError", {
                    error: _arg1.fields[0].fields[0],
                }))))) : singleton.Return(new Msg(21, new AsyncOperationStatus$1(1, new FSharpResult$2(1, t("errors.categoryHasNoFreeSlots")))))));
            }
            else {
                const x = _arg1.fields[0];
                const info = new PrintLabelInfo(x, void 0, state.ItemInfo.Fee.EstimatedPrice, getCurrentLanguage());
                return singleton.Combine(singleton.Bind(usersApi().getInfoAboutNeedAutoPrint(), (_arg2) => {
                    if (_arg2) {
                        return singleton.Bind(lotApi().generatePrintLabelWithQRCode(info), (_arg3) => {
                            window.open(_arg3);
                            return singleton.Zero();
                        });
                    }
                    else {
                        return singleton.Zero();
                    }
                }), singleton.Delay(() => singleton.Return(new Msg(21, new AsyncOperationStatus$1(1, new FSharpResult$2(0, x))))));
            }
        });
    }), (_arg4) => singleton.Return(new Msg(21, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg4.message))))));
}

export function init(item, categories) {
    let ContentLanguage;
    return [(ContentLanguage = getPrimaryLanguage(), new State(item, item, categories, empty(), new Deferred$1(0), true, new ValidationState(empty_1(), false), ContentLanguage)), Cmd_none()];
}

export function update(onSave, msg, state) {
    let inputRecord, inputRecord_1, tupledArg, tupledArg_1, inputRecord_3, tupledArg_2, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12;
    const validate = (terms) => Lot_Create_validateIf(state.Validation.NeedToValidate, terms);
    if (msg.tag === 20) {
        return [new State(state.ItemInfo, state.OriginalItemInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, false, state.Validation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 19) {
        return [withItemInfo((inputRecord = state.ItemInfo, new ItemWithFullDataInfo(inputRecord.CategoryId, msg.fields[0], inputRecord.BasicInformation, inputRecord.Dimensions, inputRecord.Fee, inputRecord.Images, inputRecord.VideoLink, inputRecord.GpsPosition, inputRecord.Freight, inputRecord.IsDeleted, inputRecord.IsReturned, inputRecord.IsRecycled)), state), Cmd_none()];
    }
    else if (msg.tag === 0) {
        return [new State((inputRecord_1 = state.ItemInfo, new ItemWithFullDataInfo(msg.fields[0].Id, inputRecord_1.SubmissionId, inputRecord_1.BasicInformation, inputRecord_1.Dimensions, inputRecord_1.Fee, inputRecord_1.Images, inputRecord_1.VideoLink, inputRecord_1.GpsPosition, inputRecord_1.Freight, inputRecord_1.IsDeleted, inputRecord_1.IsReturned, inputRecord_1.IsRecycled)), state.OriginalItemInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, state.IsRequiredToSetFocusOnSubmission, state.Validation, state.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 1) {
        return [withBasicInfoField(new ItemLotBasicInformationField(0), state.ContentLanguage, msg.fields[0], state), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [withBasicInfoField(new ItemLotBasicInformationField(1), msg.fields[0], msg.fields[1], state), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [withBasicInfoField(new ItemLotBasicInformationField(2), msg.fields[0], msg.fields[1], state), Cmd_none()];
    }
    else if (msg.tag === 14) {
        return [withGPSPosition(new GpsPosition_1(msg.fields[0], state.ItemInfo.GpsPosition.MapLink), state), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [(tupledArg = validate(toValidationType(state.ItemInfo.Fee.PurchaseCommission, state.ItemInfo.VideoLink, msg.fields[0])), withCommissionsFormAndErrors(tupledArg[0], tupledArg[1], state)), Cmd_none()];
    }
    else if (msg.tag === 16) {
        return [(tupledArg_1 = validate(toValidationType(msg.fields[0], state.ItemInfo.VideoLink, state.ItemInfo.GpsPosition.MapLink)), withCommissionsFormAndErrors(tupledArg_1[0], tupledArg_1[1], state)), Cmd_none()];
    }
    else if (msg.tag === 17) {
        const newState = withItemInfo((inputRecord_3 = state.ItemInfo, new ItemWithFullDataInfo(inputRecord_3.CategoryId, inputRecord_3.SubmissionId, inputRecord_3.BasicInformation, inputRecord_3.Dimensions, inputRecord_3.Fee, msg.fields[0].Images, inputRecord_3.VideoLink, inputRecord_3.GpsPosition, inputRecord_3.Freight, inputRecord_3.IsDeleted, inputRecord_3.IsReturned, inputRecord_3.IsRecycled)), state);
        return [new State(newState.ItemInfo, newState.OriginalItemInfo, newState.Catalog, msg.fields[0].ImagesToDelete, newState.SaveOperation, newState.IsRequiredToSetFocusOnSubmission, newState.Validation, newState.ContentLanguage), Cmd_none()];
    }
    else if (msg.tag === 18) {
        return [(tupledArg_2 = validate(toValidationType(state.ItemInfo.Fee.PurchaseCommission, msg.fields[0], state.ItemInfo.GpsPosition.MapLink)), withCommissionsFormAndErrors(tupledArg_2[0], tupledArg_2[1], state)), Cmd_none()];
    }
    else if (msg.tag === 4) {
        const newHeightStr = msg.fields[0].trim();
        return [withDimension((inputRecord_4 = state.ItemInfo.Dimensions, new Dimensions(Decimal_tryParse(newHeightStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newHeightStr, state.ItemInfo.Dimensions.HeightInfo), inputRecord_4.Length, inputRecord_4.LengthInfo, inputRecord_4.Width, inputRecord_4.WidthInfo, inputRecord_4.Depth, inputRecord_4.DepthInfo, inputRecord_4.Diameter, inputRecord_4.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 5) {
        const newWidthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_5 = state.ItemInfo.Dimensions, new Dimensions(inputRecord_5.Height, inputRecord_5.HeightInfo, inputRecord_5.Length, inputRecord_5.LengthInfo, Decimal_tryParse(newWidthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newWidthStr, state.ItemInfo.Dimensions.WidthInfo), inputRecord_5.Depth, inputRecord_5.DepthInfo, inputRecord_5.Diameter, inputRecord_5.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 6) {
        const newLengthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_6 = state.ItemInfo.Dimensions, new Dimensions(inputRecord_6.Height, inputRecord_6.HeightInfo, Decimal_tryParse(newLengthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newLengthStr, state.ItemInfo.Dimensions.LengthInfo), inputRecord_6.Width, inputRecord_6.WidthInfo, inputRecord_6.Depth, inputRecord_6.DepthInfo, inputRecord_6.Diameter, inputRecord_6.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 7) {
        const newDepthStr = msg.fields[0].trim();
        return [withDimension((inputRecord_7 = state.ItemInfo.Dimensions, new Dimensions(inputRecord_7.Height, inputRecord_7.HeightInfo, inputRecord_7.Length, inputRecord_7.LengthInfo, inputRecord_7.Width, inputRecord_7.WidthInfo, Decimal_tryParse(newDepthStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newDepthStr, state.ItemInfo.Dimensions.DepthInfo), inputRecord_7.Diameter, inputRecord_7.DiameterInfo)), state), Cmd_none()];
    }
    else if (msg.tag === 8) {
        const newDiameterStr = msg.fields[0].trim();
        return [withDimension((inputRecord_8 = state.ItemInfo.Dimensions, new Dimensions(inputRecord_8.Height, inputRecord_8.HeightInfo, inputRecord_8.Length, inputRecord_8.LengthInfo, inputRecord_8.Width, inputRecord_8.WidthInfo, inputRecord_8.Depth, inputRecord_8.DepthInfo, Decimal_tryParse(newDiameterStr), LocalizedStringModule_setTranslation(getPrimaryLanguage(), newDiameterStr, state.ItemInfo.Dimensions.DiameterInfo))), state), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [withFee((inputRecord_9 = state.ItemInfo.Fee, new Fee_1(inputRecord_9.EstimatedPrice, inputRecord_9.ReservedPrice, inputRecord_9.PurchaseCommission, inputRecord_9.Hammer, msg.fields[0])), state), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [withFee((inputRecord_10 = state.ItemInfo.Fee, new Fee_1(msg.fields[0], inputRecord_10.ReservedPrice, inputRecord_10.PurchaseCommission, inputRecord_10.Hammer, inputRecord_10.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [withFee((inputRecord_11 = state.ItemInfo.Fee, new Fee_1(inputRecord_11.EstimatedPrice, msg.fields[0], inputRecord_11.PurchaseCommission, inputRecord_11.Hammer, inputRecord_11.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [withFee((inputRecord_12 = state.ItemInfo.Fee, new Fee_1(inputRecord_12.EstimatedPrice, inputRecord_12.ReservedPrice, inputRecord_12.PurchaseCommission, Currency_round(msg.fields[0]), inputRecord_12.HasRoyalties)), state), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [withFreight(map((idx, v) => {
            if (idx === msg.fields[0]) {
                return msg.fields[1];
            }
            else {
                return v;
            }
        }, state.ItemInfo.Freight), state), Cmd_none()];
    }
    else if (msg.tag === 21) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new State(state.ItemInfo, state.OriginalItemInfo, state.Catalog, state.ImagesToDelete, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsRequiredToSetFocusOnSubmission, state.Validation, state.ContentLanguage), Cmd_none()];
            }
            else {
                window.scroll({
                    behavior: "smooth",
                    left: 0,
                    top: 0,
                });
                const categoryId = (state.ItemInfo.CategoryId !== CategoryIdModule_empty) ? state.ItemInfo.CategoryId : (void 0);
                const submissionId = (!equals(state.ItemInfo.SubmissionId, SubmissionIdModule_empty)) ? state.ItemInfo.SubmissionId : (void 0);
                onSave(msg.fields[0].fields[0].fields[0]);
                return [state, navigateTo(new Route(6, categoryId, submissionId))];
            }
        }
        else {
            const formValidation = Lot_Create_validate(toValidationType(state.ItemInfo.Fee.PurchaseCommission, state.ItemInfo.VideoLink, state.ItemInfo.GpsPosition.MapLink));
            if (formValidation.tag === 1) {
                return [new State(state.ItemInfo, state.OriginalItemInfo, state.Catalog, state.ImagesToDelete, new Deferred$1(0), state.IsRequiredToSetFocusOnSubmission, new ValidationState(formValidation.fields[0], true), state.ContentLanguage), Cmd_none()];
            }
            else {
                return [new State(state.ItemInfo, state.OriginalItemInfo, state.Catalog, state.ImagesToDelete, new Deferred$1(1), state.IsRequiredToSetFocusOnSubmission, new ValidationState(empty_1(), true), state.ContentLanguage), Cmd_fromAsync(saveItem(state))];
            }
        }
    }
    else {
        return [new State(state.ItemInfo, state.OriginalItemInfo, state.Catalog, state.ImagesToDelete, state.SaveOperation, state.IsRequiredToSetFocusOnSubmission, state.Validation, msg.fields[0]), Cmd_none()];
    }
}

export function renderSaveOperationError(state) {
    const matchValue = state.SaveOperation;
    let pattern_matching_result, ex, reason;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 1;
            reason = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue.tag === 0) {
        pattern_matching_result = 2;
    }
    else if (matchValue.tag === 1) {
        pattern_matching_result = 2;
    }
    else {
        pattern_matching_result = 0;
        ex = matchValue.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = ofArray([["className", "is-danger"], ["className", "is-light"], ["children", ex.message]]);
            return createElement("p", createObj(Helpers_combineClasses("help", props)));
        }
        case 1: {
            return createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "is-danger"], ["className", "is-light"], ["children", reason]]))));
        }
        case 2: {
            return null;
        }
    }
}

export function renderGPSPositionFields(state, dispatch) {
    let elms_1, elms, props_2, props, props_6, props_4, value_9, elms_3;
    const props_22 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.address")]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([(value_9 = ((state.ItemInfo.GpsPosition.Address != null) ? value_472(state.ItemInfo.GpsPosition.Address) : ""), ["ref", (e) => {
        if ((!(e == null)) ? (!equals_1(e.value, value_9)) : false) {
            e.value = value_9;
        }
    }]), ["onChange", (ev) => {
        const x = ev.target.value;
        if (x === "") {
            dispatch(new Msg(14, void 0));
        }
        else {
            dispatch(new Msg(14, x));
        }
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_6))))]), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = toList(delay(() => {
        let elms_2, props_12, props_10, props_16, props_14, value_31;
        const errors = Form_getFieldError(state.Validation.FormErrors, "GpsMapLink");
        return append(singleton_2((elms_2 = ofArray([(props_12 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.linkToMaps")]]), createElement("label", createObj(Helpers_combineClasses("label", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_12)))), (props_16 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([(value_31 = ((state.ItemInfo.GpsPosition.MapLink != null) ? value_472(state.ItemInfo.GpsPosition.MapLink) : ""), ["ref", (e_1) => {
            if ((!(e_1 == null)) ? (!equals_1(e_1.value, value_31)) : false) {
                e_1.value = value_31;
            }
        }]), ["onChange", (ev_1) => {
            const x_1 = ev_1.target.value;
            if (x_1 === "") {
                dispatch(new Msg(15, void 0));
            }
            else {
                dispatch(new Msg(15, x_1));
            }
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_14)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_16))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), delay(() => {
            let props_19;
            return singleton_2((props_19 = ofArray([["className", "is-danger"], ["children", fold((s, x_2) => (`${s} ${x_2}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_19)))));
        }));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_22)));
}

export function RenderButtons(renderButtonsInputProps) {
    const isDuplicate = renderButtonsInputProps.isDuplicate;
    const dispatch = renderButtonsInputProps.dispatch;
    const state = renderButtonsInputProps.state;
    const isLoading = equals_1(state.SaveOperation, new Deferred$1(1));
    const isSubmissionNotSelected = SubmissionIdModule_isEmpty(state.ItemInfo.SubmissionId);
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props_3;
        return append((!isDuplicate) ? singleton_2((props_3 = toList(delay(() => append(singleton_2(["style", {
            marginBottom: 0,
        }]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => append(singleton_2(["tabIndex", -1]), delay(() => append(singleton_2(["onClick", (x_1) => {
            x_1.preventDefault();
            navigate(toPath(new Route(3, new AuctionModeType(2), 1)));
        }]), delay(() => {
            let elms, s;
            return singleton_2(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", {
                className: join(" ", ["fa", "fa-chevron-left"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (s = t("auction.backToAuctions"), createElement("span", {
                className: "",
                children: s,
            }))])]);
        })))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_3))))) : empty_2(), delay(() => {
            let props_5;
            return singleton_2((props_5 = toList(delay(() => append(singleton_2(["style", {
                marginBottom: 0,
            }]), delay(() => append(singleton_2(["className", "is-primary"]), delay(() => append(singleton_2(["disabled", isSubmissionNotSelected]), delay(() => append(isLoading ? singleton_2(["className", "is-loading"]) : empty_2(), delay(() => append(singleton_2(["children", t("auction.saveItem")]), delay(() => singleton_2(["onClick", (x_2) => {
                x_2.preventDefault();
                dispatch(new Msg(21, new AsyncOperationStatus$1(0)));
            }]))))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_5)))));
        }));
    })));
}

export function tryDecimalOrDefault(defaultValue, str) {
    if (isNullOrWhiteSpace(str)) {
        return fromParts(0, 0, 0, false, 0);
    }
    else {
        return defaultArg(Decimal_tryParse(str), defaultValue);
    }
}

export function renderField(label, prefix, value, placeholder, onChange, state, formErrors) {
    const elms_2 = toList(delay(() => {
        let elms_1, props_2, props_10, props_5, props_4, elms, props_12;
        const errors = Form_getFieldError(state.Validation.FormErrors, formErrors);
        return singleton_2((elms_1 = ofArray([(props_2 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (props_10 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append((!equals_1(errors, empty())) ? singleton_2(["className", "is-danger"]) : empty_2(), delay(() => append(singleton_2(["placeholder", placeholder]), delay(() => append(singleton_2(["value", toNumber(value)]), delay(() => append(singleton_2(["onValueChange", (x) => {
            onChange(new Decimal(defaultArg(x.floatValue, 0)));
        }]), delay(() => (equals_1(state.SaveOperation, new Deferred$1(1)) ? singleton_2(["disabled", true]) : empty_2()))))))))))), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_4, singleton_1(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_5)))), (elms = singleton_1(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["children", prefix]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_10)))), (props_12 = ofArray([["className", "is-danger"], ["children", fold((s, x_1) => (`${s} ${x_1}`), "", errors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_12))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })));
    }));
    return createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

export function RenderWithLoadCategories(renderWithLoadCategoriesInputProps) {
    let props_12, props_10, props_3, children, props, props_8, elms, props_5, props_208, props_206, props_202, props_39, props_37, elms_2, elms_1, props_16, props_14, props_18, props_35, elms_3, props_24, props_22, props_32, props_124, props_122, props_120, props_48, elms_4, props_43, props_41, props_45, props_57, props_52, props_50, elms_5, props_54, value_126, props_66, props_61, props_59, elms_6, props_63, value_151, props_118, elms_8, elms_7, props_70, props_68, props_74, props_72, elms_10, elms_9, props_80, props_78, props_84, props_82, elms_12, elms_11, props_90, props_88, props_94, props_92, elms_14, elms_13, props_100, props_98, props_104, props_102, elms_16, elms_15, props_110, props_108, props_114, props_112, props_190, props_188, props_186, props_166, elms_19, elms_18, props_128, props_126, props_137, props_132, props_130, value_303, elms_17, props_134, elms_22, elms_21, props_143, props_141, props_152, props_147, props_145, value_339, elms_20, props_149, elms_24, props_163, props_158, props_156, elms_23, props_184, elms_27, elms_26, props_170, props_168, props_178, props_173, props_172, elms_25, props_175, props_194, props_192, props_199, elms_28, props_196, props_204;
    const onSave = renderWithLoadCategoriesInputProps.onSave;
    const freightSettings = renderWithLoadCategoriesInputProps.freightSettings;
    const item = renderWithLoadCategoriesInputProps.item;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(item, renderWithLoadCategoriesInputProps.catalog), (msg, state) => update(onSave, msg, state), [item.CategoryId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const selectSubmissionRef = patternInput_1[0];
    let isSavedSuccessfully;
    const matchValue = state_1.SaveOperation;
    let pattern_matching_result;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            isSavedSuccessfully = true;
            break;
        }
        case 1: {
            isSavedSuccessfully = false;
            break;
        }
    }
    const suppressPropagateEnterKey = (ev) => {
        if (ev.key === "Enter") {
            const this$ = ev;
            this$.stopPropagation();
            this$.nativeEvent.stopImmediatePropagation();
        }
    };
    const getBasicInfoValue = (field) => Localized_translateTo(state_1.ContentLanguage, LocalizedMap_getValueOrEmpty(field, state_1.ItemInfo.BasicInformation));
    useReact_useLayoutEffect_Z101E1A95(() => {
        if (state_1.IsRequiredToSetFocusOnSubmission) {
            if (selectSubmissionRef != null) {
                selectSubmissionRef.focus();
                dispatch(new Msg(20));
            }
        }
    }, [state_1.IsRequiredToSetFocusOnSubmission, selectSubmissionRef]);
    const props_211 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "mt-3"], ["children", Interop_reactApi.Children.toArray([(props_3 = singleton_1(["children", Interop_reactApi.Children.toArray([(children = singleton_1((props = ofArray([["className", "has-text-grey-dark"], ["className", "is-uppercase"], ["className", "is-size-4"], ["children", t("auction.createItem")]]), createElement("h5", createObj(Helpers_combineClasses("title is-5", props))))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_8 = ofArray([["style", {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_5 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(RenderButtons, {
        state: state_1,
        dispatch: dispatch,
        isDuplicate: false,
    })])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_5))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_10)))), renderSaveOperationError(state_1)])]]), createElement("section", createObj(Helpers_combineClasses("section", props_12)))), createElement("fieldset", {
        disabled: equals_1(state_1.SaveOperation, new Deferred$1(1)),
        children: Interop_reactApi.Children.toArray([(props_208 = ofArray([["className", "py-4"], ["children", Interop_reactApi.Children.toArray([(props_206 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_202 = ofArray([["className", "is-8"], ["children", Interop_reactApi.Children.toArray([(props_39 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_37 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_1((elms_1 = ofArray([(props_16 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.submission")]]), createElement("label", createObj(Helpers_combineClasses("label", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_16)))), (props_18 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const submissionId = equals(state_1.ItemInfo.SubmissionId, SubmissionIdModule_empty) ? (void 0) : state_1.ItemInfo.SubmissionId;
            return singleton_2(createElement(SearchSubmission, {
                selected: submissionId,
                onSelect: (arg) => {
                    let tupledArg;
                    dispatch((tupledArg = arg, new Msg(19, tupledArg[0], tupledArg[1], tupledArg[2])));
                },
                onInputRef: (el) => {
                    patternInput_1[1](ofNullable(el));
                },
                dependencies: [state_1.IsRequiredToSetFocusOnSubmission],
                isAutofocus: true,
                isStartSearchOnInit: true,
            }));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_18))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (props_35 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_3 = ofArray([(props_24 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.category")]]), createElement("label", createObj(Helpers_combineClasses("label", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_24)))), (props_32 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let patternInput_2, value_73;
            const options = toList(delay(() => append((state_1.ItemInfo.CategoryId === CategoryIdModule_empty) ? singleton_2(createElement("option", {
                value: CategoryIdModule_toString(CategoryIdModule_empty),
                children: t("common.noCategory"),
            })) : empty_2(), delay(() => map_1((category) => createElement("option", {
                value: CategoryIdModule_toString(category.Id),
                children: Localized_translate(category.Name),
            }), state_1.Catalog.Categories)))));
            return singleton_2((patternInput_2 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["onChange", (ev_1) => {
                const categoryId = CategoryIdModule_fromString(ev_1.target.value) | 0;
                iterate((arg_1) => {
                    dispatch(new Msg(0, arg_1));
                }, toArray(tryFind((x_1) => (x_1.Id === categoryId), state_1.Catalog.Categories)));
            }], (value_73 = CategoryIdModule_toString(state_1.ItemInfo.CategoryId), ["ref", (e) => {
                if ((!(e == null)) ? (!equals_1(e.value, value_73)) : false) {
                    e.value = value_73;
                }
            }]), ["children", Interop_reactApi.Children.toArray(Array.from(options))]])), createElement("div", {
                className: join(" ", cons("select", patternInput_2[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_2[1]))]),
            })));
        }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_32))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_35))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_37))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_39)))), createElement("form", {
            disabled: equals_1(state_1.SaveOperation, new Deferred$1(1)),
            onSubmit: (x_5) => {
                x_5.preventDefault();
                dispatch(new Msg(21, new AsyncOperationStatus$1(0)));
            },
            children: Interop_reactApi.Children.toArray([(props_124 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_122 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_120 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([languageSwitcher(state_1.ContentLanguage, (arg_3) => {
                dispatch(new Msg(22, arg_3));
            }), (props_48 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_4 = ofArray([(props_43 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_41 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("common.lotTitle")]]), createElement("label", createObj(Helpers_combineClasses("label", props_41))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_43)))), (props_45 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const name_52 = getBasicInfoValue(new ItemLotBasicInformationField(0));
                return singleton_2(createElement(SearchItemName, {
                    selected: name_52,
                    onSelect: (arg_4) => {
                        dispatch(new Msg(1, arg_4));
                    },
                    onChange: (arg_5) => {
                        dispatch(new Msg(1, arg_5));
                    },
                    onInputRef: (_arg1) => {
                    },
                    dependencies: [state_1.ContentLanguage],
                    isAutofocus: false,
                    isStartSearchOnInit: false,
                }));
            }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_45))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_48)))), (props_57 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_52 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_50 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.lotDescription")]]), createElement("label", createObj(Helpers_combineClasses("label", props_50))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_52)))), (elms_5 = singleton_1((props_54 = ofArray([["rows", 2], ["onKeyDown", suppressPropagateEnterKey], ["onKeyUp", suppressPropagateEnterKey], ["onKeyPress", suppressPropagateEnterKey], (value_126 = getBasicInfoValue(new ItemLotBasicInformationField(1)), ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals_1(e_1.value, value_126)) : false) {
                    e_1.value = value_126;
                }
            }]), ["onChange", (ev_2) => {
                dispatch(new Msg(2, state_1.ContentLanguage, ev_2.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_54))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_57)))), (props_66 = ofArray([["className", "pb-3"], ["children", Interop_reactApi.Children.toArray([(props_61 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_59 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.lotCondition")]]), createElement("label", createObj(Helpers_combineClasses("label", props_59))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_61)))), (elms_6 = singleton_1((props_63 = ofArray([["rows", 1], ["onKeyDown", suppressPropagateEnterKey], ["onKeyUp", suppressPropagateEnterKey], ["onKeyPress", suppressPropagateEnterKey], (value_151 = getBasicInfoValue(new ItemLotBasicInformationField(2)), ["ref", (e_2) => {
                if ((!(e_2 == null)) ? (!equals_1(e_2.value, value_151)) : false) {
                    e_2.value = value_151;
                }
            }]), ["onChange", (ev_3) => {
                dispatch(new Msg(3, state_1.ContentLanguage, ev_3.target.value));
            }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_63))))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_66)))), (props_118 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_8 = singleton_1((elms_7 = ofArray([(props_70 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_68 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.height")]]), createElement("label", createObj(Helpers_combineClasses("label", props_68))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_70)))), (props_74 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_72 = toList(delay(() => append((state_1.ItemInfo.Dimensions.Height != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.ItemInfo.Dimensions.Height)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.ItemInfo.Dimensions.HeightInfo)]), delay(() => singleton_2(["onChange", (ev_4) => {
                dispatch(new Msg(4, ev_4.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_72)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_74))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
            })), (elms_10 = singleton_1((elms_9 = ofArray([(props_80 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_78 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.length")]]), createElement("label", createObj(Helpers_combineClasses("label", props_78))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_80)))), (props_84 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_82 = toList(delay(() => append((state_1.ItemInfo.Dimensions.Length != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.ItemInfo.Dimensions.Length)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.ItemInfo.Dimensions.LengthInfo)]), delay(() => singleton_2(["onChange", (ev_5) => {
                dispatch(new Msg(6, ev_5.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_82)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_84))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
            })), (elms_12 = singleton_1((elms_11 = ofArray([(props_90 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_88 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.width")]]), createElement("label", createObj(Helpers_combineClasses("label", props_88))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_90)))), (props_94 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_92 = toList(delay(() => append((state_1.ItemInfo.Dimensions.Width != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.ItemInfo.Dimensions.Width)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.ItemInfo.Dimensions.WidthInfo)]), delay(() => singleton_2(["onChange", (ev_6) => {
                dispatch(new Msg(5, ev_6.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_92)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_94))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
            })), (elms_14 = singleton_1((elms_13 = ofArray([(props_100 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_98 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.depth")]]), createElement("label", createObj(Helpers_combineClasses("label", props_98))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_100)))), (props_104 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_102 = toList(delay(() => append((state_1.ItemInfo.Dimensions.Depth != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.ItemInfo.Dimensions.Depth)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.ItemInfo.Dimensions.DepthInfo)]), delay(() => singleton_2(["onChange", (ev_7) => {
                dispatch(new Msg(7, ev_7.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_102)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_104))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
            })), (elms_16 = singleton_1((elms_15 = ofArray([(props_110 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_108 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.diameter")]]), createElement("label", createObj(Helpers_combineClasses("label", props_108))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_110)))), (props_114 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_112 = toList(delay(() => append((state_1.ItemInfo.Dimensions.Diameter != null) ? singleton_2(["defaultValue", Decimal_optionalToString(state_1.ItemInfo.Dimensions.Diameter)]) : singleton_2(["defaultValue", LocalizedStringModule_getTranslationOrEmpty(getPrimaryLanguage(), state_1.ItemInfo.Dimensions.DiameterInfo)]), delay(() => singleton_2(["onChange", (ev_8) => {
                dispatch(new Msg(8, ev_8.target.value));
            }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_112)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_114))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_118)))), renderGPSPositionFields(state_1, dispatch)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_120))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_122))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_124)))), (props_190 = ofArray([["className", "py-1"], ["className", "px-0"], ["className", "has-background-grey-lighter"], ["style", {
                borderRadius: 4,
            }], ["children", Interop_reactApi.Children.toArray([(props_188 = ofArray([["style", {
                margin: 0,
            }], ["children", Interop_reactApi.Children.toArray([(props_186 = ofArray([["className", "has-text-grey-dark"], ["children", Interop_reactApi.Children.toArray([(props_166 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_19 = singleton_1((elms_18 = ofArray([(props_128 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_126 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.estimatedPrice")]]), createElement("label", createObj(Helpers_combineClasses("label", props_126))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_128)))), (props_137 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_132 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_130 = ofArray([["placeholder", "200"], (value_303 = toString(state_1.ItemInfo.Fee.EstimatedPrice), ["ref", (e_3) => {
                if ((!(e_3 == null)) ? (!equals_1(e_3.value, value_303)) : false) {
                    e_3.value = value_303;
                }
            }]), ["onChange", (ev_9) => {
                dispatch(new Msg(9, Currency_tryParseOrDefault(ev_9.target.value)));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_130)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_132)))), (elms_17 = singleton_1((props_134 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_134))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_137))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_18)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_19)),
            })), (elms_22 = singleton_1((elms_21 = ofArray([(props_143 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_141 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.reservedPrice")]]), createElement("label", createObj(Helpers_combineClasses("label", props_141))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_143)))), (props_152 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_147 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_145 = ofArray([["placeholder", "200"], (value_339 = ReservedPrice_toString(state_1.ItemInfo.Fee.ReservedPrice), ["ref", (e_4) => {
                if ((!(e_4 == null)) ? (!equals_1(e_4.value, value_339)) : false) {
                    e_4.value = value_339;
                }
            }]), ["onChange", (ev_10) => {
                dispatch(new Msg(10, ReservedPrice_tryParseOrDefault(ev_10.target.value)));
            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_145)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_147)))), (elms_20 = singleton_1((props_149 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_149))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_20)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_152))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_21)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_22)),
            })), (elms_24 = singleton_1((props_163 = ofArray([["style", {
                display: "inline-table",
            }], ["children", Interop_reactApi.Children.toArray([(props_158 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_156 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.resaleRights")]]), createElement("label", createObj(Helpers_combineClasses("label", props_156))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_158)))), (elms_23 = ofArray([createElement("input", {
                disabled: equals_1(state_1.SaveOperation, new Deferred$1(1)),
                className: "is-checkradio is-large has-background-color is-white",
                id: "exampleCheckboxMedium",
                type: "checkbox",
                name: "exampleCheckboxMedium",
                onChange: (ev_11) => {
                    dispatch(new Msg(12, ev_11.target.checked));
                },
                checked: state_1.ItemInfo.Fee.HasRoyalties,
            }), createElement("label", {
                htmlFor: "exampleCheckboxMedium",
            })]), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_23)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_163))))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_24)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_166)))), (props_184 = singleton_1(["children", Interop_reactApi.Children.toArray([renderField(t("orders.buyerCommission"), "%", PercentModule_toDecimal(state_1.ItemInfo.Fee.PurchaseCommission), t("orders.buyerCommission"), (arg_17) => {
                dispatch(new Msg(16, PercentModule_fromDecimal(arg_17)));
            }, state_1, "BuyerCommission"), (elms_27 = singleton_1((elms_26 = ofArray([(props_170 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_168 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", t("auction.hammerFee")]]), createElement("label", createObj(Helpers_combineClasses("label", props_168))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_170)))), (props_178 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_173 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_172 = ofArray([["placeholder", "200"], ["decimalScale", Currency_decimalDigits()], ["value", toNumber(CurrencyModule_toDecimal(state_1.ItemInfo.Fee.Hammer))], ["onValueChange", (x_15) => {
                dispatch(new Msg(11, CurrencyModule_fromDecimal(new Decimal(defaultArg(x_15.floatValue, 0)))));
            }]]), Interop_reactApi.createElement(NumberFormatModule_numberFormat, createObj(append_1(props_172, singleton_1(["className", "input"])))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_173)))), (elms_25 = singleton_1((props_175 = ofArray([["className", "is-static"], ["children", Currency_currencySymbol()]]), createElement("a", createObj(Helpers_combineClasses("button", props_175))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_25)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_178))))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_26)),
            }))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_27)),
            })), createElement("div", createObj(Helpers_combineClasses("column", empty())))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_184))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_186))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_188))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_190)))), (props_194 = ofArray([["className", "py-4"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([(props_192 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((zone) => {
                const freightClass = tryFind_1(zone.DeliveryCostZoneId, state_1.ItemInfo.Freight);
                return (freightClass == null) ? singleton_2(edit(zone, void 0, (x_17) => {
                    dispatch(new Msg(13, zone.DeliveryCostZoneId, x_17));
                })) : singleton_2(edit(zone, value_472(freightClass), (x_16) => {
                    dispatch(new Msg(13, zone.DeliveryCostZoneId, x_16));
                }));
            }, freightSettings)))))]), createElement("div", createObj(Helpers_combineClasses("columns", props_192))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_194)))), (props_199 = ofArray([["className", "py-4"], ["className", "px-0"], dividedTop, ["children", Interop_reactApi.Children.toArray([(elms_28 = singleton_1((props_196 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement(RenderButtons, {
                state: state_1,
                dispatch: dispatch,
                isDuplicate: true,
            })])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_196))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_28)),
            }))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_199))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_202)))), (props_204 = ofArray([["className", "has-background-white"], ["style", {
            borderRadius: 4,
            marginLeft: 10,
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_2(createElement(RenderBigImages, {
            images: state_1.ItemInfo.Images,
        })), delay(() => {
            const formErrors = Form_getFieldError(state_1.Validation.FormErrors, "YouTubeLink");
            return singleton_2(createElement(EditImages, {
                onChange: (_arg1_1) => {
                    if (_arg1_1.tag === 1) {
                        dispatch(new Msg(18, _arg1_1.fields[0]));
                    }
                    else {
                        dispatch(new Msg(17, _arg1_1.fields[0]));
                    }
                },
                videoLinkErrors: formErrors,
                dependencies: [isSavedSuccessfully],
            }));
        }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_204))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_206))))])]]), createElement("section", createObj(Helpers_combineClasses("section", props_208))))]),
    })])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_211)));
}

export function Render(renderInputProps) {
    const submissionId = renderInputProps.submissionId;
    const categoryId = renderInputProps.categoryId;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const setItem = patternInput[1];
    const item = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            setItem(new Deferred$1(1));
            return singleton.Bind(Remoting_handleNonAuth(auctionApi().getCategories(AuctionIdModule_zero)), (_arg1) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getFreightSettings()), (_arg2) => singleton.Bind(Remoting_handleNonAuth(settingsApi().getOrderCommissions()), (_arg3) => {
                setItem(new Deferred$1(2, {
                    Catalog: _arg1,
                    FreightSettings: _arg2,
                    ["СommissionsSettings"]: _arg3,
                }));
                return singleton.Zero();
            })));
        }), (_arg4) => {
            setItem(new Deferred$1(3, _arg4));
            return singleton.Zero();
        })));
    }, [patternInput_1[0]]);
    switch (item.tag) {
        case 1: {
            return loaderBig();
        }
        case 3: {
            const value = item.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const result_1 = item.fields[0];
            const freightMap = fold((m, f) => add(f.DeliveryCostZoneId, void 0, m), empty_1(), result_1.FreightSettings);
            let item_1;
            let Fee;
            const inputRecord = ItemWithFullDataInfoModule_empty.Fee;
            Fee = (new Fee_1(inputRecord.EstimatedPrice, inputRecord.ReservedPrice, result_1["СommissionsSettings"].BuyerCommission, result_1["СommissionsSettings"].HammerFee, inputRecord.HasRoyalties));
            item_1 = (new ItemWithFullDataInfo(defaultArg(categoryId, CategoryIdModule_empty), defaultArg(submissionId, SubmissionIdModule_empty), ItemWithFullDataInfoModule_empty.BasicInformation, ItemWithFullDataInfoModule_empty.Dimensions, Fee, ItemWithFullDataInfoModule_empty.Images, ItemWithFullDataInfoModule_empty.VideoLink, new GpsPosition_1(void 0, void 0), freightMap, ItemWithFullDataInfoModule_empty.IsDeleted, ItemWithFullDataInfoModule_empty.IsReturned, ItemWithFullDataInfoModule_empty.IsRecycled));
            return createElement(RenderWithLoadCategories, {
                item: item_1,
                catalog: result_1.Catalog,
                freightSettings: result_1.FreightSettings,
                onSave: (arg_1) => {
                    patternInput_1[1](arg_1);
                },
            });
        }
        default: {
            return null;
        }
    }
}

